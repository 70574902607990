import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormGroup } from '@material-ui/core'
import { FieldProps } from 'formik'
import { useErrorStyle } from 'componentsHsc'
import { Option } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'

export const useStyles = makeStyles(theme => ({
  dispaly: {
    display: 'initial'
  },
}))

interface Props extends FieldProps {
  optionList: Option[]
  rootClassName?: string
  groupClassName?: string
  row?: boolean
  disabled?: boolean
  relatedName?: string
  index?: number
}

const GroupOptions = ({
  field,
  form,
  optionList,
  groupClassName,
  row,
  disabled,
  relatedName = '',
  index,
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || []

  const handleChange = (option: Option) => (e: any) => {
    form.setFieldValue(
      name,
      e.target.checked
        ? [...value, option]
        : value.filter((i: any) => i.label !== option.label)
    )
    if (relatedName) {
      if (e.target.checked) {
        if (
          undefined !== index &&
          form.values.conditionList[index].managementItemPresenceFlag !== '2'
        ) {
          form.setFieldValue(relatedName, '0')
        }
      }
    }
  }

  return (
    <FormControl
      className={classes.dispaly}
      error={Boolean(errors[name] && touched[name])}>
      <FormGroup className={groupClassName} row={row}>
        {optionList.map(i => (
          <label key={i.value} className='form__label_side'>
            <input
              type='checkbox'
              disabled={disabled}
              checked={value.map((x: any) => x.label).indexOf(i.label) !== -1}
              onChange={handleChange(i)}
              className='form__checkbox'
            />
            {disabled ? (
              <mark style={{ backgroundColor: '#f2f2f2', borderColor: '#8593a6',}}></mark>) : (<mark style={{ flexShrink:0, }}></mark>)}
            < span > {i.label}</span>
          </label>
        ))}
      </FormGroup>
      {
        errors[name] && touched[name] ? (
          <p className={errorClasses.helperText}>{errors[name]}</p>
        ) : null
      }
    </FormControl >
  )
}

export default GroupOptions
