import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as apiConfig from 'pages/MCAZS101/apiConfig'
import { MCAZS101InitRequest } from 'types/MCAZS101/MCAZS101InitRequest'
import { isNull } from 'util'
import { dateTimeToString } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import { MCAZS101NewRequest } from 'types/MCAZS101/MCAZS101NewRequest'
import { MCAZS101OrverWriteRequest } from 'types/MCAZS101/MCAZS101OrverWriteRequest'

const SELECTION_NUMBER_NOT_PRESENT = 0 //面接候補日を提示しない
const SELECTION_NUMBER_PRESENT = 1 //面接候補日を提示する
const SELECTION_NUMBER_FIX = 2 //応募者の希望日から確定する
const SELECTION_NUMBER_OTHER = 3 //他の面接候補日を提示する
const SELECTION_NUMBER_SEND = 4 //確定した面接日を送る
const DESTINATION_DIVISION_MYNAVI_ONLY = '0'
const DESTINATION_DIVISION_MYNAVI_PREFERRED = '1'
const DESTINATION_DIVISION_EMAIL_ONLY = '2'
const SEND_TIME_FLAG_IMMEDIATE = '0'
const SEND_TIME_FLAG_RESERVATION = '1'
const REPLY_PERMIT_FLAG_PERMIT = '0'
const REPLY_PERMIT_FLAG_DISALLOW = '1'
const BARCODE_DISPLAY_FLAG_SHOW = '0'
const BARCODE_DISPLAY_FLAG_NOT_SHOW = '1'
const MANIPULATION_KUBUN_CHANGE_POSSIBLE = 'change_possible_date'
const MANIPULATION_KUBUN_FIX_INTERVIEW = 'fix_interview_date'
const MANIPULATION_KUBUN_CHANGE_INTERVIEW = 'change_interview_date'
const MANIPULATION_KUBUN_WITHDRAW_INTERVIEW = 'withdraw_interview_date'
const MANIPULATION_KUBUN_READJUST_INTERVIEW = 'readjust_interview_date'
const replyPrefixSubject: string = 'Re: '
const replyPrefixBody: string = '> '

interface FilterDisplayDecisionInfo {
  decisionDivision: string //判定区分種別
  decisionName: string //判定名
}
const undetermined: FilterDisplayDecisionInfo = {
  decisionDivision: '0', //判定区分
  decisionName: '未判定', //判定名
}
const middle: FilterDisplayDecisionInfo = {
  decisionDivision: '1', //判定区分
  decisionName: '判定中', //判定名
}
const participate: FilterDisplayDecisionInfo = {
  decisionDivision: '2', //判定区分
  decisionName: '参加', //判定名
}
const pass: FilterDisplayDecisionInfo = {
  decisionDivision: '3', //判定区分
  decisionName: '合格', //判定名
}
const notParticipate: FilterDisplayDecisionInfo = {
  decisionDivision: '4', //判定区分
  decisionName: '不参加', //判定名
}
const fail: FilterDisplayDecisionInfo = {
  decisionDivision: '5', //判定区分
  decisionName: '不合格', //判定名
}
const other: FilterDisplayDecisionInfo = {
  decisionDivision: '6', //判定区分
  decisionName: 'その他', //判定名
}

const messageInitRequest: MCAZS101InitRequest = {
  messageTemplateSettingId: '',
  entryId: [],
  selectionManagementId: [],
  progressStatusSettingId: [],
  selectionFlowSettingId: [],
  sendTimeFlag: '',
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  messageTemplateSttingId: '',
  templateName: '',
  notAdoptedNotificationType: null,
}

interface templateNameOption {
  value: string
  label: string
  templateType: string
  sysVersionNumber: string
}

const initialState: {
  messageInitRequest: MCAZS101InitRequest
  initResult: apiConfig.InitResult
  newTempName: string
  templateNameOption: templateNameOption
  chkNotAdoptedNotification: string
} = {
  messageInitRequest: messageInitRequest,
  initResult: apiConfig.initResult,
  newTempName: '',
  templateNameOption: {
    value: '',
    label: '',
    templateType: '',
    sysVersionNumber: '',
  },
  chkNotAdoptedNotification: '',
}

const messageSendCompSlice = createSlice({
  name: 'messageSendComp',
  initialState,
  reducers: {
    setInitialState(state) {
      state.messageInitRequest = messageInitRequest
      state.initResult = apiConfig.initResult
      state.newTempName = ''
      state.templateNameOption.value = ''
      state.templateNameOption.label = ''
      state.templateNameOption.templateType = ''
      state.templateNameOption.sysVersionNumber = ''
      state.chkNotAdoptedNotification = ''
    },
    getInit(state, action: PayloadAction<MCAZS101InitRequest>) {
      console.log('Reducer getInit')
      const initRequest = action.payload
      return state
    },
    setInit(state, action: PayloadAction<apiConfig.InitResult>) {
      state.initResult = action.payload
      console.log(state.initResult)
      return state
    },
    inputTemp(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      state.newTempName = value
      return state
    },
    newTemplete(
      state,
      action: PayloadAction<{
        formData: MCAZS101NewRequest
      }>
    ) {
      return state
    },
    orverWrite(
      state,
      action: PayloadAction<{
        formData: MCAZS101OrverWriteRequest
      }>
    ) {
      return state
    },
    setTemplateNameSelect(state, action: PayloadAction<templateNameOption>) {
      state.templateNameOption = action.payload
      return state
    },
    /*
    setCode(state, action: PayloadAction<number>) {
      console.log('reducer処理(setCode)')
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    */
    chkNotAdoptedNotificationType(state, action: PayloadAction<string[]>) {
      return state
    },
    setMessage(state, action: PayloadAction<string>) {
      state.chkNotAdoptedNotification = action.payload
      return state
    },
    setChkNotAdoptedNotification(state, action: PayloadAction<string>) {
      state.chkNotAdoptedNotification = action.payload
      return state
    },
  },
})

export const {
  setInitialState,
  getInit,
  setInit,
  inputTemp,
  newTemplete,
  orverWrite,
  setTemplateNameSelect,
  chkNotAdoptedNotificationType,
  setMessage,
  setChkNotAdoptedNotification,
} = messageSendCompSlice.actions
export default messageSendCompSlice.reducer
