import React, { useState } from 'react'
import {
  Button,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingDialog, ModalDialog } from 'sp/components'
import { RootState } from 'reducers'
import {
  ActionPreListData,
  setCreateDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

interface Props {
  onClose: (
    entrySearchCriteriaSettingId: string[],
    mySearchCondition: number | null
  ) => void
}

const MCARM020 = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const tableData = useSelector(
    (state: RootState) => state.selectCriteriaManagement.ActionPreList
  )

  const createDialogOpen = useSelector(
    (state: RootState) => state.selectCriteriaManagement.createDialogOpen
  )
  const flag = useSelector(
    (state: RootState) => state.selectCriteriaManagement.flag
  )
  const handleCreate = () => {
    dispatch(setCreateDialogOpen(false))
    setCheckActionList([])
  }
  const onLink = (value: string) => {
    dispatch(setCreateDialogOpen(false))
    onClose([value], null)
    setCheckActionList([])
  }
  const [checkActionList, setCheckActionList] = useState([] as string[])

  return (
    <>
      {createDialogOpen ? <LoadingDialog className='loadingOverlay' /> : null}
      <ModalDialog isOpen={createDialogOpen} className='modal'>
        <div className='modal-content'>
          <div className='modal-title-wrap'>
            <h3 className='modal-title'>MY検索（応募管理）呼び出し</h3>
            <span
              className='close-btn'
              onClick={() => {
                handleCreate()
              }}>
              <svg width='25' height='25' viewBox='0 0 50 50'>
                <path
                  id='form__modal_close_white'
                  data-name='form__modal_close'
                  d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
                  transform='translate(0 0)'
                  fill='#FFF'
                />
              </svg>
            </span>
          </div>

          <div className='modal-section'>
            <div className='modal-text__16'>
              {flag === 1 ? (
                tableData.length !== 0 ? (
                  <>
                    保存した検索条件を選択してください。
                  </>
                ) : (
                  <>
                    {getMessage(magiContants.MESSAGECODE_MCARS020_001)}
                  </>
                )
              ) : (
                ''
              )}
            </div>
            {tableData.length !== 0 ?
              <ul className='modal-mysearch'>
                {tableData.map((item: ActionPreListData, index) => (
                  <li
                    className='modal-mysearch__item'
                    onClick={() => {
                      onLink(item.entrySearchCriteriaSettingId)
                    }}>
                    <label style={{ wordBreak: 'break-word' }}>
                      {item.searchCriteriaName}
                    </label>
                    <input
                      type='hidden'
                      value={item.entrySearchCriteriaSettingId}></input>
                  </li>
                ))}
              </ul>
              : ''
            }
          </div>
          <div className='modal-section-btn modal-section-btn'>
            <Button
              variant='contained'
              className='btn__cancel'
              onClick={handleCreate}
              tabIndex={tableData.length + 1}>
              キャンセルする
            </Button>
          </div>
        </div>
      </ModalDialog>
    </>
  )
}
export default MCARM020
