import {
  FormControlLabel,
  IconButton,
  Tooltip as MuiTooptip,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { withStyles } from '@material-ui/styles'
import { dateTimeToString, isEmpty, stringToDateTime } from 'common/generalUtil'
import { FastField } from 'formik'
import moment from 'moment-timezone'
import React, { useEffect, useState, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import {
  addInterviewLocation,
  copyCandidate,
  inputInterviewDate,
  inputInterviewDetail,
  inputInterviewDuration,
  inputInterviewStaff,
  updateCandidate,
  inputManipulationSelection,
  changeReplyAllow,
  clearCandidate,
} from 'reducers/messageSendReducer'
import { SendTarget } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { formatTemplate, magiContants, unitSet } from 'utils/contants'
import { Area } from 'utils/misc'
import Calendar, { CandidateDay, Reservation } from './Calendar'
import CandidateDateGroup from './CandidateDateGroup'
import MessageInputNoneCounter from './messageInputNoneCounter'
import { CandidateDate, InterviewLocation } from './viewConig'


const useStyles = makeStyles(theme => ({
  calendar: {
    fontSize: '12px',
    '& .fc-center div': {
      display: 'flex',
      '& button': {
        margin: theme.spacing(0, 2),
      },
    },
    '& th.fc-disabled-day': {
      backgroundColor: '#e0e0e0',
      opacity: 1,
    },
    '& .fc-bgevent': {
      backgroundColor: '#c4dae8',
      opacity: 1,
    },
    '& .fc-event': {
      backgroundColor: '#c4dae8',
      opacity: 1,
      border: 'none',
      borderRadius: 0,
      marginRight: '0 !important',
    },
    '& .fc-disallow': {
      backgroundColor: '#e6e6e6',
      pointerEvents: 'none',
    },
    '& .fc-content .fc-time': {
      display: 'none',
    },
    '& .fc-time-grid-event': {
      marginBottom: '0 !important',
      left: '0 !important',
    },
    '& .fc-time-grid-event-inset': {
      boxShadow: 'none',
    },
    '& .fc-ltr .fc-time-grid .fc-event-container': {
      margin: 0,
    },
    '& .fc-day-header span': {
      display: 'block',
    },
  },
  addGroup: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
  required: {
    flexBasis: '150px !important',
    '&:after': {
      content: '"必須"',
      backgroundColor: '#c93832',
      borderRadius: '12px',
      color: '#fff',
      display: 'inline-block',
      lineHeight: '24px',
      marginLeft: '8px',
      textAlign: 'center',
      width: '52px',
    },
    '& + div': {
      flexBasis: 'calc(100% - 150px) !important',
    },
  },
  interviewDetail: {
    maxWidth: '210px',
  },
  interviewDetailWrapper: {
    display: 'flex',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '5px',
    '& > h1': {
      position: 'relative',
      transform: 'translate(0,.25em)',
      flexBasis: '100px',
    },
    '& > h1::before': {
      backgroundColor: '#8593a6',
      borderRadius: '50%',
      content: '',
      display: 'block',
      height: '24px',
      left: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-54px ,-50%)',
      width: '24px',
    },
    '& > h1 + div': {
      flexBasis: 'calc(100% - 100px)',
    },
  },
  warning: {
    color: '#e50000',
    marginLeft: '1em',
  },
  select: {
    marginTop: '8px',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  Button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioGroup: {
    display: 'inlineBlock',
  },

}))

interface Props {
  allowedArea?: Area[]
  notAllowedArea?: Area[]
  reservationList?: Reservation[]
  setIsInitialized: (state: boolean) => void
  isInitialized: boolean
  isPermitOther?: boolean
  selectedInterviewLocationId: string
  selectInterviewLocationId: string
  selectInterviewLocationName: string
  candidateListId: number
  selectInterviewLocationAddress: string
  selectInterviewLocationMapUrl: string
  updateFormModal: boolean
  setCandidateListId: (state: number) => void
  setUpdateFormModal: (state: boolean) => void
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
  setSelectedInterviewLocationId: (state: string) => void
  sendTarget: SendTarget[]
  existsDesiredDate: boolean
  value: number
  setValue: (state: number) => void
  temporaryValue: number
}

const dayRange = 90

const TabItem = ({
  allowedArea = [],
  notAllowedArea = [],
  reservationList = [],
  setIsInitialized,
  isInitialized,
  isPermitOther = false,
  selectInterviewLocationId = '',
  candidateListId = 0,
  selectInterviewLocationAddress = '',
  selectInterviewLocationName = '',
  selectInterviewLocationMapUrl = '',
  setUpdateFormModal,
  setSelectInterviewLocationId,
  setCandidateListId,
  setSelectedInterviewLocationId,
  sendTarget,
  existsDesiredDate,
  value,
  setValue,
  temporaryValue,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    messageInitRequest,
    screenDisplayItems,
    initResult,
  } = useSelector((state: RootState) => state.messageSend)

  const { draftMessageSendId } = messageInitRequest
  const { interviewSchedule } = screenDisplayItems
  const { mapShowURL } = initResult
  const {
    interviewDurationSelect,
    candidateDate,
    manipulationSelection,
    interviewLocation,
  } = interviewSchedule

  let lengthLimit = 30
  if (
    manipulationSelection === magiContants.SELECTION_NUMBER_SEND ||
    manipulationSelection === magiContants.SELECTION_NUMBER_FIX
  ) {
    lengthLimit = 1
  }

  const [selectedDurationId, setSelectedDurationId] = useState(interviewSchedule.requiredTimeId === 'null' ? '' : interviewSchedule.requiredTimeId)
  const [state, setState] = useState([] as CandidateDay[])

  useEffect(() => {
    if (selectInterviewLocationId && !isEmpty(selectInterviewLocationName)) {
      const exists = interviewLocation.some(
        item => String(item.id) === selectInterviewLocationId
      )
      if (!exists) {
        let interviewLocation: InterviewLocation = {
          id: Number(selectInterviewLocationId),
          name: selectInterviewLocationName,
          address: selectInterviewLocationAddress,
          mapUrl: !isEmpty(selectInterviewLocationMapUrl) ? mapShowURL + "?BT2=" + selectInterviewLocationAddress + selectInterviewLocationMapUrl : '',
          type: '',
          deleteFlag: false,
        }
        dispatch(addInterviewLocation({ interviewLocation }))
      }
    }
  }, [selectInterviewLocationName])

  useEffect(() => {
    if (candidateDate && candidateDate.length > 0) {
      if (!isInitialized && draftMessageSendId) {
        let candidateDayList: CandidateDay[] = []
        candidateDate.map(item => {
          if (!isEmpty(item.interviewStartTime)) {
            let currentLocation = interviewLocation[0]
            if (interviewLocation && interviewLocation.length > 0) {
              interviewLocation.map(location => {
                if (String(location.id) === item.interviewLocationId) {
                  currentLocation = location
                }
              })
            }
            let candidateDay: CandidateDay = {
              interviewDate: stringToDateTime(item.interviewStartTime),
              interviewLocation: currentLocation ? currentLocation.name : '',
              interviewLocationId: currentLocation ? String(currentLocation.id) : '',
              charge: item.interviewStaffName,
              datePickerRef: React.createRef(),
              containerRef: React.createRef(),
              fromCalendar: false,
            }
            candidateDayList = [...candidateDayList, candidateDay]
          }
        })
        setState(candidateDayList)
        setIsInitialized(true)
        setSelectedDurationId(interviewSchedule.requiredTimeId)
        dispatch(inputInterviewDuration({ value: interviewSchedule.requiredTimeId }))
        const currentDuration = interviewDurationSelect.find(item => item.interviewDurationId === interviewSchedule.requiredTimeId)
      }
    }
  }, [candidateDate])

  useEffect(() => {
    if (state != null) {
      let candidateList: CandidateDate[] = []
      state.map(item => {
        let candidate: CandidateDate = {
          interviewLocationId: '',
          interviewStaffName: '',
          interviewStartTime: '',
        }
        candidate.interviewLocationId = item.interviewLocationId
        candidate.interviewStaffName = item.charge
        let stringInterviewDate: any = ''
        if (item.interviewDate !== null) {
          stringInterviewDate = dateTimeToString(item.interviewDate)
        }
        candidate.interviewStartTime = stringInterviewDate
        candidateList = [...candidateList, candidate]
      })
      dispatch(updateCandidate({ value: candidateList }))
      if (isEmpty(selectedDurationId)) {
        setSelectedDurationId(interviewDurationSelect[0].interviewDurationId)
        dispatch(inputInterviewDuration({ value: interviewDurationSelect[0].interviewDurationId }))
      }
    }
  }, [state])

  useEffect(() => {
    if (state != null) {
      if (state.length > 0) {
        if (selectInterviewLocationId) {
          let candidateDayList: CandidateDay[] = [...state]
          let candidateDay: CandidateDay = candidateDayList[candidateListId]
          candidateDay.interviewLocationId = selectInterviewLocationId
          candidateDay.interviewLocation = selectInterviewLocationName
          candidateDayList[candidateListId] = candidateDay
          setState(candidateDayList)
          setSelectInterviewLocationId('')
        }
      }
    }
  }, [selectInterviewLocationId])

  const handleInputInterviewDetail = (value: string) => {
    dispatch(inputInterviewDetail({ value }))
  }

  const handleChangeDuration = (value: string) => {
    setSelectedDurationId(value)
    dispatch(inputInterviewDuration({ value }))
  }

  const handleAdd = () => {
    setState([
      ...state,
      {
        interviewDate: null,
        interviewLocation:
          interviewLocation && interviewLocation.length > 0
            ? interviewLocation[0].name
            : '',
        interviewLocationId:
          interviewLocation && interviewLocation.length > 0
            ? String(interviewLocation[0].id)
            : '',
        charge: '',
        datePickerRef: React.createRef(),
        containerRef: React.createRef(),
      },
    ])
  }

  const handleOpenModal = (
    selectedInterviewLocationId: string,
    candidateListId: number
  ) => {
    setSelectedInterviewLocationId(selectedInterviewLocationId)
    setCandidateListId(candidateListId)
    setUpdateFormModal(true)
  }

  const handleDelete = (itemIndex: number) => () => {
    setState(state.filter((_, index) => index !== itemIndex))
  }

  const handleCopy = (item: CandidateDay, index: number) => () => {

    let sourceCandidateDayList: CandidateDay[] = [...state]
    let targetCandidateDayList: CandidateDay[] = []

    for (var i = 0; i < state.length; i++) {
      if (i == index) {

        targetCandidateDayList[i] = sourceCandidateDayList[i]
        targetCandidateDayList[i + 1] = sourceCandidateDayList[i]
      } else if (i < index) {
        targetCandidateDayList[i] = sourceCandidateDayList[i]
      } else {
        targetCandidateDayList[i + 1] = sourceCandidateDayList[i]
      }
    }

    setState(targetCandidateDayList)

    const sourceIndex: number = index
    const targetIndex: number = sourceIndex + 1
    dispatch(copyCandidate({ sourceIndex, targetIndex }))
  }

  const handleChargeChange = (itemIndex: number) => (e: any) => {
    setState(
      state.map((item, index) =>
        index === itemIndex && e.target.value.length <= 15
          ? {
            ...item,
            charge: e.target.value,
          }
          : item
      )
    )
    dispatch(inputInterviewStaff({ index: itemIndex, value: e.target.value }))
  }

  const handleDateChange = (
    itemIndex: number,
  ) => (date: Date | null, e: any) => {
    if (date) {
      setState(
        state.map((item, index) =>
          index === itemIndex
            ? {
              ...item,
              interviewDate: date,
            }
            : item
        )
      )
      dispatch(inputInterviewDate({ index: itemIndex, value: date }))
    }
  }

  const handleRawDateChange = (
    itemIndex: number,
  ) => (e: any) => {
    const newDate = new Date(e.target.value)
    if (!isNaN(newDate.getTime())) {
      setState(
        state.map((item, index) =>
          index === itemIndex
            ? {
              ...item,
              interviewDate: newDate,
            }
            : item
        )
      )
      dispatch(inputInterviewDate({ index: itemIndex, value: newDate }))
    }
  }

  const handleKeyDown = (ref: React.RefObject<DatePicker>) => (e: any) => {
    // detect the Enter key
    if (e.keyCode && e.keyCode === 13) {
      if (ref && ref.current) {
        ref.current.setOpen(false)
      }
    }
  }

  const handleIconClick = (ref: React.RefObject<DatePicker>) => () => {
    if (ref && ref.current) {
      ref.current.setFocus()
      ref.current.setOpen(true)
    }
  }

  const Tooltip = withStyles({
    tooltip: {
      fontSize: '1em',
      maxWidth: 'none',
    },
    popper: {
      whiteSpace: 'pre-line',
    },
  })(MuiTooptip)

  const [calenderDisplay, setCalenderDisplay] = React.useState(
    existsDesiredDate ? false : true
  )
  const handleCalenderDisplayChange = () => {
    setCalenderDisplay(!calenderDisplay)
    setValue(temporaryValue)
    if (temporaryValue) {
      dispatch(inputManipulationSelection(temporaryValue))
    }
    if (temporaryValue !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      dispatch(changeReplyAllow({ value: '1' }))
    } else {
      dispatch(clearCandidate())
    }
  }
  const handleSelectionNumberFix = (newValue: number) => {
    setValue(newValue)
    if (newValue) {
      dispatch(inputManipulationSelection(newValue))
    }
    if (newValue !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      dispatch(changeReplyAllow({ value: '1' }))
    } else {
      dispatch(clearCandidate())
    }
  }

  useEffect(() => {
    if (
      value === magiContants.SELECTION_NUMBER_PRESENT ||
      value === magiContants.SELECTION_NUMBER_SEND
    ) {
      setCalenderDisplay(true)
    }
  }, [value])

  //曜日を日本語で表示
  moment.updateLocale("ja", {
    weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
  });

  return (
    <div>
      <section className={classes.section}>
        <h1 className={classes.required}>面接所要時間</h1>
        <div>
          <select
            className={classes.select}
            onChange={e => handleChangeDuration(e.target.value)}
            id='jikan'>
            {interviewDurationSelect.map(duration => (
              <option key={duration.interviewDurationId} value={duration.interviewDurationId} selected={interviewSchedule && interviewSchedule.requiredTimeId === duration.interviewDurationId}>
                {duration.displayTime}
              </option>
            ))}
          </select>
        </div>
      </section>
      <section className={classes.section}>
        <h1 className={classes.required}>面接内容
          <Tooltip
            title={useMemo(() => {
              return (
                <span>
                  {'応募者に通知する面接内容（「一次面接」「最終面接」など）'}
                </span>)
            }, [])
            }
            placement='top'
            tabIndex={-1}>
            <IconButton className={classes.Button}>
              <HelpOutlineIcon fontSize='large' />
            </IconButton>
          </Tooltip>
        </h1>
        <div className={classes.interviewDetailWrapper}>
          <FastField
            className={classes.interviewDetail}
            name={'interviewDetail'}
            type='none'
            placeholder='応募者に通知する面接の内容'
            component={MessageInputNoneCounter}
            maxAmount={10}
            onChange={handleInputInterviewDetail}
          />
        </div>
      </section>
      <section>
        <section className={'makeStyles-calendar-day'}>
          <h1 className={classes.required}>日程選択
            <Tooltip
              title={
                useMemo(() => {
                  return (
                    <span>
                      {`面接日時は現在時刻から${dayRange}日後まで指定可能です。`}
                    </span>)
                }, [])
              }
              placement='top'
              tabIndex={-1}>
              <IconButton className={classes.Button}>
                <HelpOutlineIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </h1>
          <div>
            <RadioGroup row defaultValue={magiContants.DESTINATION_DIVISION_MYNAVI_ONLY} className={classes.radioGroup} >
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value={magiContants.DESTINATION_DIVISION_MYNAVI_ONLY}
                    checked={calenderDisplay}
                    disabled={false}
                    onChange={handleCalenderDisplayChange}
                  />
                }
                label={<span style={{ fontSize: '12px' }}>カレンダーを表示</span>}
              />
              {sendTarget.length <= 1 && existsDesiredDate ? (
                <FormControlLabel
                  control={
                    <Radio
                      color='primary'
                      value={magiContants.DESTINATION_DIVISION_EMAIL_ONLY}
                      checked={value === magiContants.SELECTION_NUMBER_FIX}
                      disabled={false}
                      onChange={() => handleSelectionNumberFix(magiContants.SELECTION_NUMBER_FIX)}
                    />
                  }
                  label={<span style={{ fontSize: '12px' }}>応募者の希望を表示</span>}
                />
              ) :
                <></>
              }
            </RadioGroup>

          </div>
        </section>
        <div className='box-interview-calendar'>
          {
            calenderDisplay ?
              <div className={classes.calendar} >
                <Calendar
                  {...{
                    allowedArea,
                    notAllowedArea,
                    reservationList,
                    isPermitOther,
                    lengthLimit,
                    interviewLocation
                  }}
                  selected={state}
                  setSelected={setState}
                />
              </div>
              :
              <div className='dispayCalender' style={{ marginLeft: '20px', marginBottom: '10px' }}>
                {allowedArea.map(item => (
                  <div>
                    <p>{moment().add(item.day, unitSet.days).format(formatTemplate.title)}({moment().add(item.day, unitSet.days).format('ddd')}) {item.startDate}~{item.endDate}</p>
                  </div>
                ))}
              </div>
          }

          <CandidateDateGroup {...{
            data: state,
            lengthLimit,
            handleDateChange,
            handleRawDateChange,
            handleKeyDown,
            handleChargeChange,
            handleCopy,
            handleIconClick,
            handleOpenModal,
            handleDelete,
            reservationList,
            notAllowedArea
          }} />
          <div className={classes.addGroup}>
            <button
              type='button'
              className='btn on-icon icon-candidateDate_add'
              // style={{ marginTop: '10px', height: '32px', backgroundColor: '#e0e0e0', color: 'black' }}
              disabled={state.length >= lengthLimit}
              onClick={handleAdd}>
              候補日を追加する
            </button>
            {state.length === lengthLimit && lengthLimit === 1 && (
              <span>
                確定日の送付は1日程しか指定できません。
              </span>
            )}
            {state.length === lengthLimit && lengthLimit === 30 && (
              <span>
                登録上限数は30件です。
              </span>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default TabItem
