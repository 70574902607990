import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  DialogContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { initialCreateValues, textMap } from './formConfig'
import { Formik, Form, FastField } from 'formik'
import MCAOS020CreateRequestValidation from 'validations/MCAOS020/MCAOS020CreateRequestValidation'
import {
  Title,
  SubTitle,
  InputWithCounter,
  MapTextField,
  Page,
  FieldGroup,
  FormField,
  LoadingDialog,
} from 'components'
import { RootState } from 'reducers'
import { setCreateDialogOpen, setInterviewCreate } from 'reducers/interviewReducer'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { onKeyDown } from 'common/generalUtil'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(3),
    paddingLeft: 0,
    marginRight: theme.spacing(48.9),
  },
  formContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  cancel: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 150,
  },
  confirmButton: {
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
    minWidth: 150,
  },
  mapURLButton: {
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
  },
  iframe: {
    border: '1px solid gray',
  },
  formGroup: {
    display: 'flex',
  },
  msg: {
  },
  formField: {
    margin: '0px',
  },
  fieldGroup: {
    marginTop: theme.spacing(4)
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      overflowY: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'auto' : 'hidden',
      display: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'block' : 'flex',
    },
    '& .MuiDialogContent-root': {
      minWidth: '960px',
      maxHeight: '100%',
    },
  },
}))

interface Props {
  onClose: () => void
  screenId: string
}

const MCAOS020 = ({ onClose, screenId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('register')
  const [interviewLocation, setInterviewLocation] = useState<any>(null)
  const [map, setMap] = useState('')

  const createDialogOpen = useSelector(
    (state: RootState) => state.interview.createDialogOpen
  )
  const recruitmentManagementDivision = useSelector((state: RootState) => state.globalMenu.recruitmentManagementDivision)

  const handleCancel = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }
  const handleSubmit = (values: any) => {
    setConfirmDialogOpen(true)
    setType('register')
    values.recruitmentManagementDivision = recruitmentManagementDivision
    setInterviewLocation(values)
  }
  const handleDialogClose = () => {
    setConfirmDialogOpen(false)
  }
  const handleCreate = () => {
    if (type === 'register') {
      dispatch(setInterviewCreate({ param: interviewLocation, screenId: screenId }))
    } else if (type === 'cancel') {
      dispatch(setCreateDialogOpen(false))
    }
    onClose()
    setConfirmDialogOpen(false)
  }

  const realUrl = useSelector(
    (state: RootState) => state.url
  )

  useEffect(() => {
    if (createDialogOpen === false) {
      setMap('')
    }
  }, [createDialogOpen])

  const handleClick = () => {
    const w = window.open('about:blank', '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width='
      + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
    if (w != null) {
      w.location.href = realUrl.MCAO_MAP_SERCH_URL_OPEN
    }
  }

  const onBlurMapUrl = realUrl.MCAO_MAP_IMAGE_URL
    + '&size=' + magiContants.MCAO_MAP_IMAGE_WIDTH + 'x' + magiContants.MCAO_MAP_IMAGE_HEIGHT
  const inputOnBlur = (event: any) => {
    let str = event.target.value || ''
    if (str != '') {
      setMap(onBlurMapUrl + str)
    } else {
      setMap('')
    }
  }

  return (
    <>
      {createDialogOpen ? <LoadingDialog className='overlay' /> : null}
      <Dialog
        fullWidth
        maxWidth='md'
        open={createDialogOpen} className={classes.dialog + ' mainDialog'}>
        <DialogContent>
          <SubTitle>面接場所登録</SubTitle>
          <Formik
            initialValues={initialCreateValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={MCAOS020CreateRequestValidation}
            render={() => (
              <Form onKeyDown={onKeyDown}>
                <FieldGroup className={classes.fieldGroup}>
                  <Grid>
                    <Typography variant='body1' gutterBottom>
                      <div className={classes.msg} dangerouslySetInnerHTML={{ __html: getMessage(magiContants.MESSAGECODE_MCAOS020_000) }} />
                    </Typography>
                  </Grid>

                  <FormField className={classes.formField}>
                    <Grid item xs={3} className={classes.formGroup}>
                      <Title title='面接場所名' required />
                    </Grid>
                    <Grid item xs={8} className={classes.formGroup}>
                      <FastField
                        name='interviewLocationName'
                        component={InputWithCounter}
                        maxAmount={30}
                      />
                    </Grid>
                  </FormField>
                  <FormField className={classes.formField}>
                    <Grid item xs={3} className={classes.formGroup}>
                      <Title title='住所' required />
                    </Grid>
                    <Grid item xs={8} className={classes.formGroup}>
                      <FastField
                        name='streetAddress'
                        component={InputWithCounter}
                        maxAmount={50}
                      />
                    </Grid>
                  </FormField>
                  <FormField className={classes.formField}>
                    <Grid item xs={3} className={classes.formGroup}>
                      <Title title='地図' />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid className={classes.formGroup}>
                        <Button
                          className={classes.mapURLButton}
                          onClick={handleClick}>
                          地図設定ウィンドウを開く
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.formGroup}>
                        <FastField
                          name='map'
                          component={MapTextField}
                          onBlur={inputOnBlur}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.formGroup}>
                        別ウインドウで地図設定画面が開きます。
                        <br />
                        ガイダンスに従って操作の上、表示される地図IDをコピー＆ペーストしてください。
                        <br />
                        地図の設定は最寄駅等、周辺の目標物を必ず地図中に含め、わかりやすいようにしてください。
                      </Grid>
                    </Grid>
                  </FormField>
                  <FormField className={classes.formField}>
                    <Grid item xs={3} className={classes.formGroup} />
                    <Grid item xs={9} className={classes.formGroup}>
                      {map ? (
                        <img
                          className={classes.iframe}
                          src={map}
                          width={magiContants.MCAO_MAP_IMAGE_WIDTH}
                          height={magiContants.MCAO_MAP_IMAGE_HEIGHT}
                        />
                      ) : (
                          ''
                        )}
                    </Grid>
                  </FormField>
                </FieldGroup>
                <DialogActions className={classes.buttonGroup}>
                  <Grid
                    container
                    direction='row'
                    justify='space-between'
                    className={classes.formContainer}>
                    <Grid item xs={3}>
                      <Button
                        variant='contained'
                        className={classes.cancel}
                        onClick={handleCancel}>
                        {textMap['cancel'].cancel}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        type='submit'
                        variant='contained'
                        className={classes.confirmButton}>
                        {textMap['register'].submit}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Form>
            )}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={confirmDialogOpen}>
        <DialogTitle>{textMap[type].message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>キャンセル</Button>
          <Button onClick={handleCreate} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default MCAOS020
