/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { FastField, Field } from 'formik'
import { ClickAwayListener } from '@material-ui/core'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { textDecoration, insertionCharacters } from 'utils/messageChangeUtil'
import { isEmpty, now, getDay, timeToString } from 'common/generalUtil'
import { RootState } from 'reducers'
import {
  addHomePage,
  changeLimitTime,
  changeTargetInput,
  deleteHomePage,
  getPreview,
  inputBody,
  inputHomePageTitle,
  inputHomePageUrl,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubject,
  setSubjectText,
  setMessageTemplateChanged,
} from 'reducers/messageSendReducer'
import { MCAZS080MessagePreviewRequest } from 'types/MCAZS080/MCAZS080MessagePreviewRequest'
import { FormHandler } from '.'
import { inputList, targetTextAreas } from 'pages/MCAZS080/formConfig'
import * as viewConfig from 'pages/MCAZS080/viewConig'
import HomepageInput from './homepageInput'
import MessageInputWithCounter from './messageInputWithCounter'
import MessageMultiTextWithCounter from './messageMultiTextWithCounter'
import { ModalDialog } from 'sp/components'

momentTz.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles(() => ({
  description: {
    marginLeft: '200px',
  },
  deleteButton: {
    width: '32px',
  },
  attachmentIdLabel: {
    display: 'inline-block',
    width: '375px',
    marginRight: '8px',
    cursor: 'pointer!important',
  },
  selectAttachmentButton: {
    cursor: 'pointer!important',
  },
  selectAttachmentButtonError: {
    cursor: 'pointer!important',
    borderColor: 'red',
  },
  exampleLinkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  mynaviAttachedOption: {
    '& span': {
      display: 'inline-block',
    },
    '& .em8': {
      width: '8em',
    },
    '& .em30': {
      width: '30em',
    },
    cursor: 'pointer!important',
  },
  checkbox: {
    width: '16px!important',
    height: '16px!important',
  },
  disableSubject: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  homepageInput: {
    '& > div': {
      width: '190px',
      marginTop: '0',
      marginRight: '8px',
    },
  },
  previewAlert: {
    color: 'red',
  },
  passwordFileTitle: {
    color: '#e53935',
  },
  previewSubject: {
    marginTop: '0',
    minHeight: '1em',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  previewBody: {
    minHeight: '1em',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  // 日付
  dateClass: {
    Width: '200px',
    'flex-basis': '200px',
  },
  checkboxRoot: {
    marginLeft: '2px',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  checkboxLabel: {
    '& .MuiIconButton-label>input': {
      width: 0,
    },
  },
  noCheck: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  checked: {
    backgroundColor: '#3f71bd',
    backgroundPosition: 'center center',
    borderColor: '#3f71bd',
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  disabledNoCheck: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  disabledCheched: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    backgroundColor: 'lightgray',
    backgroundPosition: 'center center',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  Button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  btnDisable: {
    background: '#e0e0e0',
    borderColor: '#ccc',
    boxShadow: 'none',
    color: '#999',
    pointerEvents: 'none',
  },
}))
// phase2 start
interface Props {
  form: FormHandler
  targetList: viewConfig.SendTarget[]
  isInitialized: boolean
  runConfirm: boolean
  setInitialValueSubject: (subject: string) => void
  setInitialValueBody: (body: string) => void
  changeFile: (event: any) => void
  deleteFile: (index: number) => void
  setIsFixedSubject: (state: boolean) => void
  forbiddenWords: string[]
}

const MessageInput = ({
  form,
  targetList,
  setInitialValueSubject,
  setInitialValueBody,
  isInitialized,
  runConfirm,
  setIsFixedSubject,
  forbiddenWords,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const destinationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .destinationSelection
  )
  const interviewScheduleAdjustmentAction = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.action
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const messageInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.messageInput
  )
  const adjustmentInterviewScheduleId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.hiddenItems
        .adjustmentInterviewScheduleId
  )
  const interviewSchedule = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewSchedule
  )
  const interviewDetail = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.interviewDetail
  )
  const requiredTimeId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.requiredTimeId
  )
  const interviewDurationSelect = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .interviewDurationSelect
  )
  const candidateDate = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.candidateDate
  )
  const shouldShowMessageInput = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.messageInput.shouldShowMessageInput
  )
  const recruitmentManagementFlagList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.recruitmentManagementFlag
  )
  const seminarScheduleList = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.seminarSchedule
  )
  const seminarList = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.seminar
  )
  const signatureList = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.signature
  )
  const interviewScheduleAdjustmentId = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.interviewScheduleAdjustmentId
  )
  const interviewScheduleIds = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.interviewScheduleId
  )
  const previewDataList = useSelector(
    (state: RootState) => state.messageSend.previewDataList
  )
  const subjectText = useSelector(
    (state: RootState) => state.messageSend.subjectText
  )

  const interviewWebUrlSendFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag
  )

  const destinationInfo = useSelector(
    (state: RootState) => state.messageSend.initResult.destinationInfo
  )

  const messageTemplateChanged = useSelector(
    (state: RootState) => state.messageSend.messageTemplateChanged
  )

  const [previewShow, setPreviewShow] = useState<string>('')
  const [hasDisplayPreview, setHasDisplayPreview] = useState(false)
  const [, setDisableAddAttachment] = useState<boolean>(false)
  const [disableAddHomePage, setDisableAddHomePage] = useState<boolean>(false)
  const [target, setTarget] = useState('subject')
  const [disabledSubject, setDisabledSubject] = useState(false)
  const [isFix, setIsFix] = useState(false)
  const [previewSubject, setPreviewSubject] = useState('')
  const [previewBody, setPreviewBody] = useState('')
  const [alertMessages, setAlertMessages] = useState<string[]>([])

  const insertionButtonList = [
    {
      label: '応募者氏名',
      value: 'user_name',
      title: '指定箇所に{@user_name} 応募者の氏名が挿入されます',
      show: true,
    },
    {
      label: '応募者姓',
      value: 'sei',
      title: '指定箇所に{@sei} 応募者の姓が挿入されます',
      show: true,
    },
    {
      label: '応募者名',
      value: 'mei',
      title: '指定箇所に{@mei} 応募者の名が挿入されます',
      show: true,
    },
    {
      label: '応募者管理ID',
      value: 'id',
      title: '指定箇所に{@id} 応募者管理IDが挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1,
    },
    {
      label: '利用者番号',
      value: 'user_id',
      title: '指定箇所に{@user_id} 利用者番号が挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2,
    },
    {
      label: '現在の日付',
      value: 'date',
      title: '指定箇所に{@date} 現在の日付が挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2,
    },
    {
      label: '掲載社名',
      value: 'company_name',
      title: '指定箇所に{@company_name} 掲載社名が挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY,
    },
    {
      label: '送信元会社名',
      value: 'company_name',
      title: '指定箇所に{@company_name} 送信元会社名が挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        destinationSelection !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY,
    },
    {
      label: '応募職種名',
      value: 'job',
      title: '指定箇所に{@job} 応募職種名が挿入されます',
      show:
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2,
    },
    {
      label: '選考ステップ',
      value: 'latest_progress',
      title: '指定箇所に{@latest_progress} 現在の選考ステップが挿入されます',
      show: true,
    },
    {
      label: '合否',
      value: 'latest_decision',
      title: '指定箇所に{@latest_decision} 現在の合否が挿入されます',
      show: true,
    },
    {
      label: '面接日',
      value: 'interview_date',
      title: '指定箇所に{@interview_date} 面接日が挿入されます',
      show:
        (interviewScheduleAdjustmentAction ===
          magiContants.ACTION_FIX_INTERVIEW ||
          interviewScheduleAdjustmentAction ===
          magiContants.ACTION_WITHDRAW_INTERVIEW ||
          interviewScheduleAdjustmentAction ===
          magiContants.ACTION_CHANGE_INTERVIEW ||
          manipulationSelection === magiContants.SELECTION_NUMBER_FIX ||
          manipulationSelection === magiContants.SELECTION_NUMBER_SEND) &&
        interviewWebUrlSendFlag != magiContants.INTERVIEW_WEB_URL_SEND_FLAG,
    },
    {
      label: '面接場所',
      value: 'interview_location',
      title: '指定箇所に{@interview_location} 面接場所が挿入されます',
      show:
        interviewScheduleAdjustmentAction ===
        magiContants.ACTION_FIX_INTERVIEW ||
        interviewScheduleAdjustmentAction ===
        magiContants.ACTION_WITHDRAW_INTERVIEW ||
        interviewScheduleAdjustmentAction ===
        magiContants.ACTION_CHANGE_INTERVIEW ||
        manipulationSelection === magiContants.SELECTION_NUMBER_FIX ||
        manipulationSelection === magiContants.SELECTION_NUMBER_SEND ||
        (interviewWebUrlSendFlag === '1' &&
          recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2),
    },
    {
      label: 'WEB面接URL',
      value: 'interviewWebUrlText',
      title: '指定箇所にWEB面接URL送付用の定型文が挿入されます',
      show:
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2,
    },
  ]

  const decorationButtonList = [
    {
      label: 'ボールド',
      value: 'char_bold',
      iconName: 'icon-bold',
      title: '太字',
    },
    {
      label: 'イタリック',
      value: 'char_itarics',
      iconName: 'icon-itarics',
      title: '斜体',
    },
    {
      label: '下線',
      value: 'char_underLine',
      iconName: 'icon-underLine',
      title: '下線',
    },
    { label: '赤', value: 'char_red', iconName: 'icon-char_red' },
    { label: 'オレンジ', value: 'char_orange', iconName: 'icon-char_orange' },
    { label: '黄緑', value: 'char_yg', iconName: 'icon-char_yg' },
    { label: '緑', value: 'char_green', iconName: 'icon-char_green' },
    { label: 'ライトブルー', value: 'char_lb', iconName: 'icon-char_lb' },
    { label: '青', value: 'char_blue', iconName: 'icon-char_blue' },
    { label: 'ピンク', value: 'char_pink', iconName: 'icon-char_pink' },
  ]

  useEffect(() => {
    if (isInitialized) {
      form.setFieldValue('subject', messageInput.subject)
    }
  }, [messageInput.subject])

  useEffect(() => {
    if (isInitialized) {
      form.setFieldValue('body', messageInput.body)
    }
  }, [messageInput.body])

  useEffect(() => {
    if (
      hasDisplayPreview &&
      isInitialized &&
      previewDataList &&
      previewDataList.length > 0
    ) {
      setPreview()
      setPreviewShow(' show')
      setHasDisplayPreview(false)
    }
  }, [previewDataList])

  useEffect(() => {
    if (isInitialized) {
      if (
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY &&
        (interviewScheduleAdjustmentAction ===
          magiContants.ACTION_FIX_INTERVIEW ||
          interviewScheduleAdjustmentAction ===
          magiContants.ACTION_CHANGE_INTERVIEW ||
          manipulationSelection === magiContants.SELECTION_NUMBER_FIX ||
          manipulationSelection === magiContants.SELECTION_NUMBER_SEND)
      ) {
        if (!isFix) {
          dispatch(setSubjectText(form.values.subject))
          setInitialValueSubject(form.values.subject)
          setInitialValueBody(form.values.body)
        }
        setInitialValueSubject(magiContants.INTERVIEW_FIX_SUBJECT)
        dispatch(
          inputSubject({ target, value: magiContants.INTERVIEW_FIX_SUBJECT })
        )
        setDisabledSubject(true)
        setIsFixedSubject(true)
        setIsFix(true)
      } else {
        if (isFix) {
          setInitialValueSubject(subjectText)
          setInitialValueBody(form.values.body)
          dispatch(inputSubject({ target, value: subjectText }))
        }
        setDisabledSubject(false)
        setIsFixedSubject(false)
        setIsFix(false)
      }
      checkDisableAddHomePage()
      checkDisableAddAttachment()
    }
  }, [
    isInitialized,
    manipulationSelection,
    interviewScheduleAdjustmentAction,
    destinationSelection,
  ])

  useEffect(() => {
    if (runConfirm) {
      getPreviewData()
    }
  }, [runConfirm])

  useEffect(() => {
    const value = form.values.repliesLimit
    dispatch(changeLimitTime({ value }))
  }, [form.values.repliesLimit])

  useEffect(() => {
    checkDisableAddHomePage()
    checkDisableAddAttachment()
    dispatch(setMessageTemplateChanged(false))
  }, [messageTemplateChanged])

  const dateToDateString = (
    target: Date | null,
    shouldUseTimezone: boolean
  ) => {
    if (shouldUseTimezone) {
      return target && momentTz(target).isValid()
        ? momentTz(target).format('YYYY/MM/DD')
        : null
    } else {
      return target && moment(target).isValid()
        ? moment(target).format('YYYY/MM/DD')
        : null
    }
  }

  const handleClickPreview = () => {
    setHasDisplayPreview(true)
    getPreviewData()
  }

  const getPreviewData = () => {
    let adjustmentId: string | null = null
    let id: string | null = null
    if (
      interviewScheduleAdjustmentAction === magiContants.ACTION_FIX_INTERVIEW ||
      interviewScheduleAdjustmentAction ===
      magiContants.ACTION_WITHDRAW_INTERVIEW ||
      interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_INTERVIEW
    ) {
      adjustmentId = interviewScheduleAdjustmentId
        ? String(interviewScheduleAdjustmentId)
        : null
      id =
        interviewScheduleIds && interviewScheduleIds.length > 0
          ? String(interviewScheduleIds[0])
          : null
    }
    const request: MCAZS080MessagePreviewRequest = {
      companyId: '',
      entryId: targetList.map(item => item.entryId),
      interviewScheduleAdjustmentId: adjustmentId,
      interviewScheduleId: id,
    }
    dispatch(getPreview({ formData: request }))
  }

  const handleChangeSenderCompanyName = (value: string) => {
    dispatch(inputSenderCompanyName({ value }))
  }

  const handleChangeSenderMailAddress = (value: string) => {
    dispatch(inputSenderMailAddress({ value }))
  }

  const handleClickSubject = () => {
    const target = targetTextAreas.subject
    setTarget(targetTextAreas.subject)
    dispatch(changeTargetInput({ target }))
  }

  const handleClickBody = () => {
    const target = targetTextAreas.body
    setTarget(targetTextAreas.body)
    dispatch(changeTargetInput({ target }))
  }

  const handleChangeSubject = (value: string) => {
    const target = targetTextAreas.subject
    setTarget(targetTextAreas.subject)
    dispatch(inputSubject({ target, value }))
  }

  const handleChangeBody = (value: string) => {
    const target = targetTextAreas.body
    setTarget(targetTextAreas.body)
    dispatch(inputBody({ target, value }))
  }

  const subjectRef = useRef<HTMLInputElement>(null)
  const bodyRef = useRef<HTMLTextAreaElement>(null)
  const handleBodyInsertion = (type: string) => {
    const value = null
    // 差し込みボタン「WEB面接URL」を押下した場合、件名には挿入しない
    if (target === 'body') {
      if (bodyRef.current && bodyInsertionModalOpen) {
        const pos = bodyRef.current.selectionStart || 0
        const [text, offset] = insertionString(type, value, pos)
        form.setFieldValue('body', text)
        dispatch(inputBody({ target, value: text }))
        const cursorPosition = pos + offset
        setTimeout(() => {
          if (bodyRef.current) {
            bodyRef.current.setSelectionRange(cursorPosition, cursorPosition)
          }
        }, 0)
      }
    }
    if (target === 'subject' && bodyInsertionModalOpen && bodyRef.current) {
      if (bodyRef.current) {
        bodyRef.current.focus()
        const length = bodyRef.current.value.length
        bodyRef.current.setSelectionRange(length, length)
      }
      if (bodyRef.current && bodyInsertionModalOpen) {
        const pos = bodyRef.current.selectionStart || 0
        const [text, offset] = insertionString(type, value, pos)

        form.setFieldValue('body', text)
        dispatch(inputBody({ target, value: text }))
        const cursorPosition = pos + offset

        setTimeout(() => {
          if (bodyRef.current) {
            bodyRef.current.setSelectionRange(cursorPosition, cursorPosition)
          }
        }, 0)
      }
    }
  }
  const handleSubjectInsertion = (type: string) => {
    const value = null
    // 差し込みボタン「WEB面接URL」を押下した場合、件名には挿入しない
    if (type !== 'interviewWebUrlText') {
      if (target === 'subject') {
        if (
          !disabledSubject &&
          subjectRef.current &&
          subjectInsertionModalOpen
        ) {
          const pos = subjectRef.current.selectionStart || 0
          const [text, offset] = insertionString(type, value, pos)
          form.setFieldValue('subject', text)
          dispatch(inputSubject({ target, value: text }))
          const cursorPosition = pos + offset
          setTimeout(() => {
            if (subjectRef.current) {
              subjectRef.current.setSelectionRange(
                cursorPosition,
                cursorPosition
              )
            }
          }, 0)
        }
      }
      if (
        target === 'body' &&
        subjectInsertionModalOpen &&
        subjectRef.current
      ) {
        if (subjectRef.current) {
          subjectRef.current.focus()
          const length = subjectRef.current.value.length
          subjectRef.current.setSelectionRange(length, length)
        }
        if (
          !disabledSubject &&
          subjectRef.current &&
          subjectInsertionModalOpen
        ) {
          const pos = subjectRef.current.selectionStart || 0
          const [text, offset] = insertionString(type, value, pos)
          form.setFieldValue('subject', text)
          dispatch(inputSubject({ target, value: text }))
          const cursorPosition = pos + offset
          setTimeout(() => {
            if (subjectRef.current) {
              subjectRef.current.setSelectionRange(
                cursorPosition,
                cursorPosition
              )
            }
          }, 0)
        }
      }
    }
  }

  const handleDecoration = (value: string) => {
    if (target == 'body') {
      const text = decorateCharacter(value)
      form.setFieldValue('body', text)
      dispatch(inputBody({ target, value: text }))
    }
  }

  const handleAddSignature = (value: string) => {
    let body = form.values.body + '\n' + value
    form.setFieldValue('body', body)
    dispatch(inputBody({ target, value: body }))
  }

  const handleChangeHomePageTitle1 = (value: string) => {
    dispatch(inputHomePageTitle({ index: 0, value }))
  }

  const handleChangeHomePageTitle2 = (value: string) => {
    dispatch(inputHomePageTitle({ index: 1, value }))
  }

  const handleChangeHomePageTitle3 = (value: string) => {
    dispatch(inputHomePageTitle({ index: 2, value }))
  }

  const handleChangeHomePageUrl1 = (value: string) => {
    dispatch(inputHomePageUrl({ index: 0, value }))
  }

  const handleChangeHomePageUrl2 = (value: string) => {
    dispatch(inputHomePageUrl({ index: 1, value }))
  }

  const handleChangeHomePageUrl3 = (value: string) => {
    dispatch(inputHomePageUrl({ index: 2, value }))
  }

  const handleDeleteHomePage = (index: number) => {
    dispatch(deleteHomePage({ index }))
    setDisableAddHomePage(false)
    if (index === 0) {
      form.setFieldValue('homepageTitle1', form.values.homepageTitle2)
      form.setFieldValue('homepageUrl1', form.values.homepageUrl2)
      form.setFieldValue('homepageTitle2', form.values.homepageTitle3)
      form.setFieldValue('homepageUrl2', form.values.homepageUrl3)
      form.setFieldValue('homepageTitle3', '')
      form.setFieldValue('homepageUrl3', '')
    } else if (index === 1) {
      form.setFieldValue('homepageTitle2', form.values.homepageTitle3)
      form.setFieldValue('homepageUrl2', form.values.homepageUrl3)
      form.setFieldValue('homepageTitle3', '')
      form.setFieldValue('homepageUrl3', '')
    } else {
      form.setFieldValue('homepageTitle3', '')
      form.setFieldValue('homepageUrl3', '')
    }
  }

  const handleAddHomePage = () => {
    dispatch(addHomePage())
    if (messageInput.homePage.length >= 2) {
      setDisableAddHomePage(true)
    }
  }

  const checkDisableAddHomePage = () => {
    if (messageInput.homePage.length >= 3) {
      setDisableAddHomePage(true)
    } else {
      setDisableAddHomePage(false)
    }
  }

  const checkDisableAddAttachment = () => {
    if (messageInput.attachment.length >= 5) {
      setDisableAddAttachment(true)
    } else {
      setDisableAddAttachment(false)
    }
  }

  const generateFixSubject = () => {
    let text = ''
    text = interviewDetail ? interviewDetail : ''
    text += `確定 `
    if (candidateDate && candidateDate.length > 0) {
      let scheduleDate = candidateDate[0].interviewStartTime
      let shouldUseTimezone = false
      if (
        interviewScheduleAdjustmentAction ===
        magiContants.ACTION_FIX_INTERVIEW ||
        interviewScheduleAdjustmentAction ===
        magiContants.ACTION_CHANGE_INTERVIEW
      ) {
        interviewSchedule.map(item => {
          if (item.interviewScheduleId === adjustmentInterviewScheduleId[0]) {
            scheduleDate = item.interviewStartTime
            shouldUseTimezone = true
          }
        })
      }
      const day = momentTz(scheduleDate)
      let dateString: string | null = 'yyyy/MM/dd'
      let dayString = '曜日'
      let timeString = 'hh:mm'
      if (scheduleDate) {
        dateString = dateToDateString(day.toDate(), shouldUseTimezone)
        dayString = getDay(day.toDate(), shouldUseTimezone)
        timeString = timeToString(day.toDate(), shouldUseTimezone)
      }
      text += `${dateString} (${dayString}) ${timeString}～`
    }
    let durationText = '約mm分'
    interviewDurationSelect.map(item => {
      if (item.interviewDurationId === requiredTimeId) {
        durationText = item.displayTime
      }
    })
    text += `（所要時間：${durationText}）`
    return text
  }

  const setPreview = () => {
    let subject = form.values.subject
    let body = form.values.body

    let alertMessageList: string[] = []

    subject = subject
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    body = body
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')

    // 件名の置換処理開始
    if (disabledSubject) {
      subject = generateFixSubject()
    } else {
      // 件名の文字装飾の置換
      textDecoration.map(item => {
        if (
          subject.indexOf(item.before) >= 0 ||
          subject.indexOf(item.beforeEnd) >= 0
        ) {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item.before]),
          ]
        }
      })

      // 件名の差し込み文字の置換
      const insertionResultSubject = replaceContent(subject)
      subject = insertionResultSubject.content
      alertMessageList = alertMessageList.concat(
        insertionResultSubject.alertMessageList
      )

      // 件名の管理項目のチェック
      const matchedFlagSubject = subject.match(/{@flagvalue[0-9]*}/g)
      if (matchedFlagSubject && matchedFlagSubject.length > 0) {
        matchedFlagSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }
      // phase2 start
      // 件名の管理項目(新)のチェック
      const matchedFlagValueSubject = subject.match(/{@flag[0-9]*}/g)
      if (matchedFlagValueSubject && matchedFlagValueSubject.length > 0) {
        matchedFlagValueSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }
      // phase2 end
      // 件名のセミナー日程のチェック
      const matchedSeminarScheduleSubject = subject.match(
        /{@seminar_schedule[0-9]*}/g
      )
      if (
        matchedSeminarScheduleSubject &&
        matchedSeminarScheduleSubject.length > 0
      ) {
        matchedSeminarScheduleSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名のセミナー画面のチェック
      const matchedSeminarScreenSubject = subject.match(/{@seminar[0-9]*}/g)
      if (
        matchedSeminarScreenSubject &&
        matchedSeminarScreenSubject.length > 0
      ) {
        matchedSeminarScreenSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名のセミナー画面(新)のチェック
      const matchedSeminarIdScreenSubject = subject.match(/{@seminarId[0-9]*}/g)
      if (
        matchedSeminarIdScreenSubject &&
        matchedSeminarIdScreenSubject.length > 0
      ) {
        matchedSeminarIdScreenSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名のWEB面接日時のチェック
      const matchedInterviewDatetimeSubject = subject.match(
        /{@interview_datetime}/g
      )
      if (
        matchedInterviewDatetimeSubject &&
        matchedInterviewDatetimeSubject.length > 0
      ) {
        matchedInterviewDatetimeSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名のWEB面接URLのチェック
      const matchedInterviewUrlSubject = subject.match(/{@interview_url}/g)
      if (matchedInterviewUrlSubject && matchedInterviewUrlSubject.length > 0) {
        matchedInterviewUrlSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名の返信元件名、返信元本文のチェック、置換
      let senderMessageId =
        destinationInfo && destinationInfo.length > 0
          ? destinationInfo[0].senderMessageId
          : null
      // 件名の返信元件名のチェック
      const matchedReplayTitleSubject = subject.match(/{@reply_title}/g)
      if (
        matchedReplayTitleSubject &&
        matchedReplayTitleSubject.length > 0 &&
        !senderMessageId
      ) {
        matchedReplayTitleSubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名の返信元本文のチェック
      const matchedReplayBodySubject = subject.match(/{@reply_body}/g)
      if (matchedReplayBodySubject && matchedReplayBodySubject.length > 0) {
        matchedReplayBodySubject.map(item => {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item]),
          ]
        })
      }

      // 件名の返信元件名の置換
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
      ) {
        if (
          matchedReplayTitleSubject &&
          matchedReplayTitleSubject.length > 0 &&
          senderMessageId
        ) {
          subject = subject.replace(
            /{@reply_title}/g,
            '<span style="font-weight: bold">[この部分には自動で返信元の件名が入ります]</span>'
          )
        }
      }
    }

    // 本文の置換処理開始
    body = body.replace(/\r?\n/g, '<br />')

    // 本文の文字装飾の置換
    const decorationResult = decorationContent(body)
    body = decorationResult.content
    alertMessageList = alertMessageList.concat(
      decorationResult.alertMessageList
    )

    // 本文の差し込み文字の置換
    const insertionResultBody = replaceContent(body)
    body = insertionResultBody.content
    alertMessageList = alertMessageList.concat(
      insertionResultBody.alertMessageList
    )
    // 掲載社名（{@COMPANY_NAME}）の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ||
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)
    ) {
      body = body.replace(
        /{@COMPANY_NAME}/g,
        '<span style="font-weight: bold">[この部分には自動で掲載社名が入ります]</span>'
      )
    }
    // phase2 start
    // 管理項目のチェック
    const matchedFlagValueBody = body.match(/{@flagvalue[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedFlagValueBody &&
      matchedFlagValueBody.length > 0
    ) {
      matchedFlagValueBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }
    // 管理項目(新)の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      if (matchedFlagValueBody && matchedFlagValueBody.length > 0) {
        const idSet: Set<string> = new Set<string>()
        const bodyArray = body.split('{@flagvalue')
        for (var i = 1; i < bodyArray.length; i++) {
          const itemArray = bodyArray[i].split('}')
          if (
            itemArray.length > 1 ||
            bodyArray[i].indexOf('}') == bodyArray[i].length - 1
          ) {
            idSet.add(itemArray[0])
          }
        }
        if (idSet.size > 0) {
          const flagNameList = Array.from(idSet)
          flagNameList.map(item => {
            recruitmentManagementFlagList.map(flag => {
              if (item == flag.recruitmentManagementFlagSettingId.toString()) {
                body = body
                  .split(`{@flagvalue${item}}`)
                  .join(
                    `<span style="font-weight: bold">[${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}の設定値]</span>`
                  )
              }
            })
          })
        }
      }
    }
    // phase2 end
    const matchedFlagBody = body.match(/{@flag[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedFlagBody &&
      matchedFlagBody.length > 0
    ) {
      matchedFlagBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }

    // 管理項目の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      if (body.search('{@flag') >= 0) {
        const idSet: Set<string> = new Set<string>()
        const bodyArray = body.split('{@flag')
        for (var i = 1; i < bodyArray.length; i++) {
          const itemArray = bodyArray[i].split('}')
          if (
            itemArray.length > 1 ||
            bodyArray[i].indexOf('}') == bodyArray[i].length - 1
          ) {
            idSet.add(itemArray[0])
          }
        }
        if (idSet.size > 0) {
          const flagNameList = Array.from(idSet)
          flagNameList.map(item => {
            recruitmentManagementFlagList.map(flag => {
              if (item == flag.recruitmentManagementFlagSettingId.toString()) {
                body = body
                  .split(`{@flag${item}}`)
                  .join(
                    `<span style="font-weight: bold">${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}:[${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}の設定値]</span>`
                  )
              }
            })
          })
        }
      }
    }

    //セミナー日程のチェック
    const matchedSeminarScheduleBody = body.match(/{@seminar_schedule[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarScheduleBody &&
      matchedSeminarScheduleBody.length > 0
    ) {
      matchedSeminarScheduleBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }

    //セミナー画面のチェック
    const matchedSeminarScreenBody = body.match(/{@seminar[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarScreenBody &&
      matchedSeminarScreenBody.length > 0
    ) {
      matchedSeminarScreenBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }

    //セミナー画面(新)のチェック
    const matchedSeminarIdScreenBody = body.match(/{@seminarId[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarIdScreenBody &&
      matchedSeminarIdScreenBody.length > 0
    ) {
      matchedSeminarIdScreenBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }

    // セミナー画面の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      // 置換文字チェック（表示期間外のセミナー画面）
      // 置換文字チェック（存在しないセミナー画面）
      if (
        (matchedSeminarScheduleBody && matchedSeminarScheduleBody.length > 0) ||
        (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) ||
        (matchedSeminarIdScreenBody && matchedSeminarIdScreenBody.length > 0)
      ) {
        let isErrorPeriod = false
        if (
          matchedSeminarScheduleBody &&
          matchedSeminarScheduleBody.length > 0
        ) {
          matchedSeminarScheduleBody.map(item => {
            const seminarScheduleId = item
              .replace('{@seminar_schedule', '')
              .replace('}', '')
            const current = seminarScheduleList.find(
              item => String(item.seminarScheduleId) === seminarScheduleId
            )
            if (!current) {
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
              }
              body = body
                .split(`{@seminar_schedule${seminarScheduleId}}`)
                .join(
                  `<span style="font-weight: bold">[この部分には自動でセミナー日程が入ります]</span>`
                )
            }
          })
        }
        if (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) {
          matchedSeminarScreenBody.map(item => {
            const seminarId = item.replace('{@seminar', '').replace('}', '')
            const current = seminarList.find(
              item => String(item.seminarId) === seminarId
            )
            if (!current) {
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
                if (targetList.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminar${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminar${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              } else {
                if (targetList.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminar${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS080-005', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminar${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              }
            }
          })
        }
        if (
          matchedSeminarIdScreenBody &&
          matchedSeminarIdScreenBody.length > 0
        ) {
          matchedSeminarIdScreenBody.map(item => {
            const seminarId = item.replace('{@seminarId', '').replace('}', '')
            const current = seminarList.find(
              item => String(item.coordinationFormerSeminarId) === seminarId
            )
            if (!current) {
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
                if (targetList.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminarId${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminarId${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              } else {
                if (targetList.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminarId${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS080-005', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminarId${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              }
            }
          })
        }
        if (isErrorPeriod) {
          alertMessageList = [...alertMessageList, getMessage('MCAZS080-008')]
        }
      }

      // 置換文字チェック（セミナー画面の複数指定）
      //if ((matchedSeminarScheduleBody && matchedSeminarScheduleBody.length > 1) || (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 1)) {
      if (
        (matchedSeminarScreenBody || matchedSeminarIdScreenBody) &&
        (matchedSeminarScreenBody ? matchedSeminarScreenBody.length : 0) +
        (matchedSeminarIdScreenBody ? matchedSeminarIdScreenBody.length : 0) >
        1
      ) {
        // 差込できないのを除く
        let count: number = 0
        let countSeminerId: number = 0
        if (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) {
          let matchedSeminarIdArray: string[] = matchedSeminarScreenBody.map(
            i =>
              i.match(/{@seminar(\S*)}/)
                ? (i.match(/{@seminar(\S*)}/) as any)[1]
                : i
          )
          count = matchedSeminarIdArray.length
          let seminarFlag: string[] = seminarList
            ? seminarList.map(i => i.seminarId.toString())
            : []
          let tempArray: string[] = matchedSeminarIdArray.filter(
            i => seminarFlag.indexOf(i) != -1
          )
          count =
            count -
            matchedSeminarIdArray.filter(i => tempArray.indexOf(i) == -1).length
        }
        if (
          matchedSeminarIdScreenBody &&
          matchedSeminarIdScreenBody.length > 0
        ) {
          let matchedSeminarIdArray: string[] = matchedSeminarIdScreenBody.map(
            i =>
              i.match(/{@seminarId(\S*)}/)
                ? (i.match(/{@seminarId(\S*)}/) as any)[1]
                : i
          )
          countSeminerId = matchedSeminarIdArray.length
          let seminarFlag: string[] = seminarList
            ? seminarList.map(i => i.coordinationFormerSeminarId.toString())
            : []
          let tempArray: string[] = matchedSeminarIdArray.filter(
            i => seminarFlag.indexOf(i) != -1
          )
          countSeminerId =
            countSeminerId -
            matchedSeminarIdArray.filter(i => tempArray.indexOf(i) == -1).length
        }
        if (count + countSeminerId > 1) {
          alertMessageList = [...alertMessageList, getMessage('MCAZS080-011')]
        }
      }
    }

    // WEB面接日時のチェック
    const matchedInterviewDatetimeBody = body.match(/{@interview_datetime}/g)
    if (
      interviewWebUrlSendFlag !== magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
      matchedInterviewDatetimeBody &&
      matchedInterviewDatetimeBody.length > 0
    ) {
      matchedInterviewDatetimeBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    } else {
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        body = body
          .split(`{@interview_datetime}`)
          .join(
            `<span style="font-weight: bold">[この部分には自動でWEB面接日時が入ります]</span>`
          )
      }
    }

    // WEB面接URLのチェック
    const matchedInterviewUrlBody = body.match(/{@interview_url}/g)
    if (
      interviewWebUrlSendFlag !== magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
      matchedInterviewUrlBody &&
      matchedInterviewUrlBody.length > 0
    ) {
      matchedInterviewUrlBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    } else {
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        body = body
          .split(`{@interview_url}`)
          .join(
            `<span style="font-weight: bold">[この部分には自動でWEB面接URLが入ります]</span>`
          )
      }
    }

    // 本文の返信元件名、返信元本文のチェック、置換
    let senderMessageId =
      destinationInfo && destinationInfo.length > 0
        ? destinationInfo[0].senderMessageId
        : null
    // 本文の返信元件名のチェック
    const matchedReplayTitleBody = body.match(/{@reply_title}/g)
    if (matchedReplayTitleBody && matchedReplayTitleBody.length > 0) {
      matchedReplayTitleBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }

    // 本文の返信元本文のチェック
    const matchedReplayBodyBody = body.match(/{@reply_body}/g)
    if (
      matchedReplayBodyBody &&
      matchedReplayBodyBody.length > 0 &&
      !senderMessageId
    ) {
      matchedReplayBodyBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS080-005', [item]),
        ]
      })
    }
    // 本文の返信元本文の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
    ) {
      if (
        matchedReplayBodyBody &&
        matchedReplayBodyBody.length > 0 &&
        senderMessageId
      ) {
        body = body.replace(
          /{@reply_body}/g,
          '<span style="font-weight: bold">[この部分には自動で返信元の本文が入ります]</span>'
        )
      }
    }

    // 禁止語チェック(置換前の値でチェック)
    if (forbiddenWords && forbiddenWords.length > 0) {
      let containsSubject = false
      let containsBody = false
      forbiddenWords.map(item => {
        if (
          form.values.subject.indexOf(item) >= 0 &&
          manipulationSelection !== magiContants.SELECTION_NUMBER_FIX &&
          manipulationSelection !== magiContants.SELECTION_NUMBER_SEND
        ) {
          containsSubject = true
        }
        if (form.values.body.indexOf(item) >= 0) {
          containsBody = true
        }
      })
      if (containsSubject) {
        alertMessageList = [...alertMessageList, getMessage('MCAZS080-013')]
      }
      if (containsBody) {
        alertMessageList = [...alertMessageList, getMessage('MCAZS080-014')]
      }
    }

    // 送信先が「マイナビ優先」「e-mailのみ」の場合、本文に固定文言を追加
    if (
      destinationSelection ===
      magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED ||
      destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY
    ) {
      body =
        body +
        '<br /><br />※このメールはマイナビが提供する応募者管理システムを利用して送信されています※'
    }

    setPreviewSubject(subject)
    setPreviewBody(body)
    setAlertMessages(alertMessageList)
  }

  const decorationContent = (content: string) => {
    let alertMessageList: string[] = []

    textDecoration.map(item => {
      if (
        content.indexOf(item.before) >= 0 ||
        content.indexOf(item.beforeEnd) >= 0
      ) {
        var arr =
          content.match(item.rule) != null ? content.match(item.rule) : []
        var arr1 =
          content.match(item.ruleEnd) != null ? content.match(item.ruleEnd) : []

        if (
          destinationSelection !== magiContants.DESTINATION_DIVISION_EMAIL_ONLY
        ) {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS080-005', [item.before]),
          ]
        } else {
          if (arr && arr1 && arr.length === arr1.length) {
            content = content.replace(item.rule, item.after)
            content = content.replace(item.ruleEnd, item.afterEnd)
          } else {
            alertMessageList = [
              ...alertMessageList,
              getMessage('MCAZS080-012', [
                item.before + 'xxxxx' + item.beforeEnd,
              ]),
            ]
          }
        }
      }
    })

    const result = { content, alertMessageList }
    return result
  }

  const replaceContent = (content: string) => {
    let alertMessageList: string[] = []

    insertionCharacters.map(item => {
      if (content.indexOf(item.before) >= 0) {
        let isEmptyData = false
        let isError = false
        switch (item.name) {
          case 'insertTextUserName':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(
                item => isEmpty(item.familyName) || isEmpty(item.name)
              )
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextUserSei':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.familyName))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextUserMei':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.name))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextId':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item =>
                  isEmpty(item.jobSeekerIdForDisplay)
                )
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextUserId':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item =>
                  isEmpty(item.jobSeekerIdForDisplay)
                )
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextDate':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            }
            break
          case 'insertTextCompanyName':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item => isEmpty(item.companyName))
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextJob':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item => isEmpty(item.entryJobCategoryName))
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextLatestProgress':
            if (
              previewDataList &&
              previewDataList.some(item => isEmpty(item.progressName))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextLatestDecision':
            if (
              previewDataList &&
              previewDataList.some(item => isEmpty(item.decisionDivision))
            ) {
              isEmptyData = true
            }
            break
          case 'insertInterviewDate':
            if (
              interviewWebUrlSendFlag ===
              magiContants.INTERVIEW_WEB_URL_SEND_FLAG
            ) {
              isError = true
            } else if (
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_FIX_INTERVIEW &&
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_WITHDRAW_INTERVIEW &&
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_CHANGE_INTERVIEW &&
              manipulationSelection !== magiContants.SELECTION_NUMBER_FIX &&
              manipulationSelection !== magiContants.SELECTION_NUMBER_SEND
            ) {
              isError = true
            } else {
              if (
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_FIX_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_WITHDRAW_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_CHANGE_INTERVIEW
              ) {
                if (
                  !previewDataList ||
                  previewDataList.length <= 0 ||
                  previewDataList.some(item => item.interviewStartTime === null)
                ) {
                  isEmptyData = true
                }
              }
            }
            break
          case 'insertInterviewLocation':
            if (
              interviewWebUrlSendFlag !==
              magiContants.INTERVIEW_WEB_URL_SEND_FLAG
            ) {
              if (
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_FIX_INTERVIEW &&
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_WITHDRAW_INTERVIEW &&
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_CHANGE_INTERVIEW &&
                manipulationSelection !== magiContants.SELECTION_NUMBER_FIX &&
                manipulationSelection !== magiContants.SELECTION_NUMBER_SEND
              ) {
                isError = true
              } else {
                if (
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_FIX_INTERVIEW ||
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_WITHDRAW_INTERVIEW ||
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_CHANGE_INTERVIEW
                ) {
                  if (
                    !previewDataList ||
                    previewDataList.length <= 0 ||
                    previewDataList.some(item =>
                      isEmpty(item.interviewLocationName)
                    )
                  ) {
                    isEmptyData = true
                  }
                }
              }
            }
            break
          case 'requestUrl':
            isError = true
            break
        }
        if (isError || isEmptyData) {
          if (isError) {
            alertMessageList = [
              ...alertMessageList,
              getMessage('MCAZS080-005', [item.before]),
            ]
          }
          if (isEmptyData) {
            if (targetList.length > 1) {
              alertMessageList = [
                ...alertMessageList,
                getMessage('MCAZS080-007', [item.before]),
              ]
            } else {
              alertMessageList = [
                ...alertMessageList,
                getMessage('MCAZS080-006', [item.before]),
              ]
            }
          }
        } else {
          if (item.name === 'insertTextCompanyName') {
            if (
              destinationSelection ===
              magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
            ) {
              content = content.replace(
                item.rule,
                '<span style="font-weight: bold">[この部分には自動で掲載社名が入ります]</span>'
              )
            } else if (
              destinationSelection ===
              magiContants.DESTINATION_DIVISION_EMAIL_ONLY
            ) {
              content = content.replace(
                item.rule,
                '<span style="font-weight: bold">[この部分には自動で送信元会社名が入ります]</span>'
              )
            }
          } else {
            content = content.replace(item.rule, item.after)
          }
        }
      }
    })
    // phase2 end
    const result = { content, alertMessageList }
    return result
  }

  const decorateCharacter = (value: string) => {
    const decorations: {
      [key: string]: any
    } = {
      char_bold: { start: '{@b}', end: '{@/b}' },
      char_itarics: { start: '{@i}', end: '{@/i}' },
      char_underLine: { start: '{@u}', end: '{@/u}' },
      char_red: { start: '{@red}', end: '{@/red}' },
      char_orange: { start: '{@orange}', end: '{@/orange}' },
      char_yg: { start: '{@yellowgreen}', end: '{@/yellowgreen}' },
      char_green: { start: '{@lime}', end: '{@/lime}' },
      char_lb: { start: '{@cyan}', end: '{@/cyan}' },
      char_blue: { start: '{@blue}', end: '{@/blue}' },
      char_pink: { start: '{@magenta}', end: '{@/magenta}' },
    }

    if (target == 'subject') {
      return
    }
    let decorateStart = value in decorations ? decorations[value].start : ''
    let decorateEnd = value in decorations ? decorations[value].end : ''

    let textArea: any = document.getElementsByName(target)[0]
    if (!textArea) {
      return
    }
    let start = textArea.selectionStart
    let end = textArea.selectionEnd
    let sentence = textArea.value
    let len = sentence ? sentence.length : 0
    let posStart = textArea ? start : 0
    let posEnd = textArea ? end : 0
    let before = sentence ? sentence.substring(0, posStart) : ''
    let content = sentence ? sentence.substring(posStart, posEnd) : ''
    let after = sentence ? sentence.substring(posEnd, len) : ''
    sentence = before + decorateStart + content + decorateEnd + after
    return sentence
  }

  const escapCharacter = (value: string) => {
    let res = ''
    if (!isEmpty(value)) {
      res = value
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\r?\n/g, '<br />')
    }
    return res
  }

  const insertionString = (
    type: string,
    value: string | number | null,
    pos = 0,
    value1?: number
  ) => {
    const insertionStrings: {
      [key: string]: string
    } = {
      user_name: '{@user_name}',
      sei: '{@sei}',
      mei: '{@mei}',
      id: '{@id}',
      user_id: '{@user_id}',
      date: '{@date}',
      company_name: '{@company_name}',
      job: '{@job}',
      latest_progress: '{@latest_progress}',
      latest_decision: '{@latest_decision}',
      interview_date: '{@interview_date}',
      interview_location: '{@interview_location}',
      recruitment_management_flag: '{@flagvalueNNNN}',
      seminar_schedule: '{@seminar_scheduleNNNN}',
      seminar: '{@seminarIdNNNNNN}',
      interviewWebUrlText:
        '【WEB面接日時】 {@interview_datetime}\n【WEB面接URL】 {@interview_url}\n\n【事前にご確認下さい】\nWEB面接機能環境のチェック\n面接実施前に以下URLよりご自身のWEB接続環境を必ずご確認ください。\nhttps://trial.face-peer.com/fhtest_v2\n',
    }

    let insertionString = type in insertionStrings ? insertionStrings[type] : ''

    switch (type) {
      case 'recruitment_management_flag':
        let nameValue: string = ''
        if (typeof value === 'string') {
          nameValue = value
        }
        let settingIdValue: string = ''
        if (typeof value1 === 'number') {
          settingIdValue = value1.toString()
        }
        insertionString =
          value && value1
            ? nameValue + ':' + insertionString.replace('NNNN', settingIdValue)
            : ''
        break
      case 'seminar_schedule':
        let stringValue: string = ''
        if (typeof value === 'number') {
          stringValue = value.toString()
        }
        if (typeof value === 'string') {
          stringValue = value
        }
        insertionString = value
          ? insertionString.replace('NNNN', stringValue)
          : ''
        break
      case 'seminar':
        let stringValueSeminar: string = ''
        if (typeof value === 'number') {
          stringValueSeminar = value.toString()
        }
        if (typeof value === 'string') {
          stringValueSeminar = value
        }
        insertionString = value
          ? insertionString.replace('NNNNNN', stringValueSeminar)
          : ''
        break
    }

    let sentence = (form.values as any)[target]
    if (target === 'subject') {
      switch (type) {
        case 'recruitment_management_flag':
        case 'seminar_schedule':
        case 'seminar':
          return sentence
      }
    }
    if (target === 'subject' && bodyInsertionModalOpen && bodyRef.current) {
      sentence = (form.values as any)['body']
    }
    if (target === 'body' && subjectInsertionModalOpen && subjectRef.current) {
      sentence = (form.values as any)['subject']
    }
    let len = sentence ? sentence.length : 0
    let before = sentence ? sentence.substr(0, pos) : ''
    let word = insertionString
    let after = sentence ? sentence.substr(pos, len) : ''

    sentence = before + word + after

    return [sentence, word.length]
  }

  const [subjectInsertionModalOpen, setSubjectInsertionModalOpen] = useState(
    false
  )
  const [bodyInsertionModalOpen, setBodyInsertionModalOpen] = useState(false)

  const handleSubjectInsertionModal = () => {
    setSubjectInsertionModalOpen(false)
  }

  const handleBodyInsertionModal = () => {
    setBodyInsertionModalOpen(false)
  }

  return (
    <div className='message-input-block' id='input-message'>
      <div className='message-input__head'>メッセージ</div>
      {shouldShowMessageInput.senderCompanyName &&
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
          <div className='message-input__body'>
            <div className={'message-input__ttl'}>
              送信元会社名の指定
              <i className='modal-flow__badge_required'>必須</i>
            </div>
            <div className='message-input__inner'>
              <FastField
                className={'form__input_100pw'}
                name={'senderCompanyName'}
                type='none'
                placeholder='メーラーに表示される送信者名を入力'
                component={MessageInputWithCounter}
                maxAmount={inputList.senderCompanyName.max}
                onChange={handleChangeSenderCompanyName}
              />
            </div>
          </div>
        )}
      {shouldShowMessageInput.senderMailAddress &&
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
          <div className='message-input__body'>
            <div className={'message-input__ttl'}>
              返信先メールアドレス
              <i className='modal-flow__badge_required'>必須</i>
            </div>
            <div className='message-input__inner'>
              <FastField
                name={'senderMailAddress'}
                type='none'
                placeholder='メーラーに表示するアドレスを入力'
                component={MessageInputWithCounter}
                maxAmount={inputList.senderMailAddress.max}
                onChange={handleChangeSenderMailAddress}
              />
              <p className='message-input__alert'>
                ※入力を誤ると送信対象者からの返信を受け取れませんのでご注意ください。
              </p>
            </div>
          </div>
        )}
      <div className='message-input__body'>
        <div className='message-input__ttl'>
          件名
          <i className='modal-flow__badge_required ml10'>必須</i>
          <button
            type='button'
            className='btn__message-insertion'
            style={{ color: disabledSubject ? '#999' : '' }}
            onClick={() => setSubjectInsertionModalOpen(true)}
            disabled={disabledSubject}>
            差し込み文字を挿入
          </button>
        </div>
        <div className={'message-input__inner'}>
          {disabledSubject ? (
            <FastField
              name={'subject'}
              className={`form__input_100pw ${classes.disableSubject}`}
              type='none'
              disabled={disabledSubject}
              component={MessageInputWithCounter}
              maxAmount={inputList.subject.max}
              onChange={handleChangeSubject}
              onBlur={handleClickSubject}
              innerRef={subjectRef}
            />
          ) : (
            <FastField
              name={'subject'}
              type='none'
              className={'form__input_100pw'}
              component={MessageInputWithCounter}
              maxAmount={inputList.subject.max}
              onChange={handleChangeSubject}
              onBlur={handleClickSubject}
              innerRef={subjectRef}
            />
          )}
        </div>
      </div>
      <div className='message-input__body'>
        <div className='message-input__ttl'>
          本文
          <i className='modal-flow__badge_required ml10'>必須</i>
          <button
            type='button'
            className='btn__message-insertion'
            onClick={() => {
              setBodyInsertionModalOpen(true)
            }}>
            {destinationSelection ===
              magiContants.DESTINATION_DIVISION_EMAIL_ONLY
              ? '差し込み文字・装飾'
              : '差し込み文字を挿入'}
          </button>
        </div>
        <div className={'message-input__inner'}>
          <Field
            name={'body'}
            type='none'
            component={MessageMultiTextWithCounter}
            maxAmount={
              destinationSelection ===
                magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
                ? inputList.body.max
                : 1959
            }
            onChange={handleChangeBody}
            onBlur={handleClickBody}
            innerRef={bodyRef}
            className={'form__input_100pw'}
          />
        </div>
      </div>

      <div className='message-preview'>
        <div className='message-preview__btn-block'>
          <button
            type='button'
            className='btn__message-preview'
            onClick={handleClickPreview}>
            プレビューの表示
          </button>
        </div>
        <div className='message-preview__wrap'>
          {!isEmpty(previewShow) &&
            alertMessages &&
            alertMessages.length > 0 && (
              <section className={'message-input__alert'}>
                {alertMessages.map(item => item)}
              </section>
            )}

          {!isEmpty(previewSubject) && !isEmpty(previewBody) && (
            <>
              <div
                className={'message-preview__title'}
                dangerouslySetInnerHTML={{ __html: previewSubject }}
              />
              <div
                className={'message-preview__body'}
                dangerouslySetInnerHTML={{ __html: previewBody }}
              />
            </>
          )}
        </div>
      </div>

      {shouldShowMessageInput.attachment &&
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY &&
        <div
          className='message-input__body'
          style={{ wordBreak: 'break-all' , display : messageInput.attachment[0].attachmentFileName === '' ? 'none' : '' }}>
          <div className='message-input__ttl'>添付ファイル</div>
          {messageInput.attachment.map(attachment => (
            <>
              {attachment.attachmentFileName && attachment.attachmentName && (
                <div className='message-input__inner' style={{ marginBottom: "10px" }}>
                  <ul className='message-input-files'>
                    <li className='message-input-files__item'>
                      <button
                        type='button'
                        className='message-input-files__btn'
                        style={{ textAlign: "left" }}
                      >
                        {attachment.attachmentFileName}
                      </button>
                      <dl className='message-input-files__info'>
                        <dt className='message-input-files__info_head'>
                          更新者
                        </dt>
                        <dd className='message-input-files__info_text'>
                          {attachment.attachmentName}
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
              )}
            </>))}
        </div>
      }

      {shouldShowMessageInput.homePage &&
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
          <div className='message-input__body'>
            <div className='message-input__ttl'>ホームページURL</div>
            <div className='message-input__inner'>
              <div className='message-hp'>
                {messageInput.homePage &&
                  messageInput.homePage.map((homePageValue, index) => (
                    <div
                      className='message-hp__body'
                      key={`homepage${index + 1}`}>
                      <div className='mb15'>
                        <FastField
                          className={'form__input_100pw'}
                          name={`homepageTitle${index + 1}`}
                          type='text'
                          placeholder='ホームページ名を入力'
                          component={HomepageInput}
                          maxAmount={inputList.homePageTitle.max}
                          onChange={
                            index === 0
                              ? handleChangeHomePageTitle1
                              : index === 1
                                ? handleChangeHomePageTitle2
                                : handleChangeHomePageTitle3
                          }
                        />
                      </div>
                      <div className='mb15'>
                        <FastField
                          className={'form__input_100pw'}
                          name={`homepageUrl${index + 1}`}
                          type='text'
                          placeholder='https://'
                          component={HomepageInput}
                          maxAmount={inputList.homePageUrl.max}
                          onChange={
                            index === 0
                              ? handleChangeHomePageUrl1
                              : index === 1
                                ? handleChangeHomePageUrl2
                                : handleChangeHomePageUrl3
                          }
                        />
                      </div>
                      <div className='message-hp__btn'>
                        <button
                          type='button'
                          className='btn__calendar-delete'
                          value={index}
                          title='このホームページを削除します'
                          onClick={() => handleDeleteHomePage(index)}>
                          削除
                        </button>
                      </div>
                    </div>
                  ))}
                <div className='btn__calendar-add-area'>
                  <button
                    type='button'
                    className={`btn__calendar-add ${disableAddHomePage ? classes.btnDisable : ''
                      }`}
                    data-action='add-url-input'
                    onClick={() => handleAddHomePage()}
                    disabled={disableAddHomePage}>
                    ホームページURLの追加
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      <ModalDialog isOpen={subjectInsertionModalOpen} className='modal'>
        <div className='modal-content-black'>
          <div className='modal-title-wrap-black'>
            <h3 className='modal-title'>差し込み文字を挿入</h3>
            <span
              className='close-btn'
              onClick={() => handleSubjectInsertionModal()}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use xlinkHref='#form__modal_close'></use>
              </svg>
            </span>
          </div>
          <div className='modal-section pt20'>
            <ul className='modal-insertion-list'>
              {insertionButtonList &&
                insertionButtonList.map(
                  insertionButton =>
                    insertionButton.show && (
                      <li className='modal-insertion-list__item'>
                        <button
                          key={insertionButton.value}
                          onClick={() => {
                            handleSubjectInsertion(insertionButton.value)
                            handleSubjectInsertionModal()
                          }}
                          type='button'
                          className='btn__modal-insertion'
                          title={insertionButton.title}>
                          {insertionButton.label}
                        </button>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </ModalDialog>

      <ModalDialog isOpen={bodyInsertionModalOpen} className='modal'>
        <div className='modal-content-black'>
          <div className='modal-title-wrap-black'>
            <h3 className='modal-title'>差し込み文字を挿入</h3>
            <span
              className='close-btn'
              onClick={() => handleBodyInsertionModal()}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use xlinkHref='#form__modal_close'></use>
              </svg>
            </span>
          </div>
          <div className='modal-section pt20'>
            <ul className='modal-insertion-list'>
              {insertionButtonList &&
                insertionButtonList.map(
                  insertionButton =>
                    insertionButton.show && (
                      <li className='modal-insertion-list__item'>
                        <button
                          key={insertionButton.value}
                          onClick={() => {
                            handleBodyInsertion(insertionButton.value)
                            handleBodyInsertionModal()
                          }}
                          type='button'
                          className='btn__modal-insertion'
                          title={insertionButton.title}>
                          {insertionButton.label}
                        </button>
                      </li>
                    )
                )}
            </ul>
            {bodyInsertionModalOpen && (
              <>
                <div className='modal-title_2'>署名</div>
                <ul className='modal-signature-list'>
                  {signatureList &&
                    signatureList.map(signature => (
                      <li className='modal-signature-list__item'>
                        <button
                          key={signature.signSettingId}
                          type='button'
                          className='btn__modal-signature'
                          onClick={() => {
                            handleAddSignature(signature.signature)
                            handleBodyInsertionModal()
                          }}>
                          {signature.registrationName}
                        </button>
                      </li>
                    ))}
                </ul>
              </>
            )}
            {destinationSelection ===
              magiContants.DESTINATION_DIVISION_EMAIL_ONLY &&
              bodyInsertionModalOpen && (
                <div className='modal-title_2'>文字装飾</div>
              )}
            <ul className='modal-insertion-list'>
              {destinationSelection ===
                magiContants.DESTINATION_DIVISION_EMAIL_ONLY &&
                bodyInsertionModalOpen &&
                decorationButtonList &&
                decorationButtonList.map(decorationButton => (
                  <li className='modal-insertion-list__item'>
                    <button
                      key={decorationButton.value}
                      onClick={() => {
                        handleDecoration(decorationButton.value)
                        handleBodyInsertionModal()
                      }}
                      type='button'
                      className={`btn insertion only-icon ${decorationButton.iconName}`}
                      style={{
                        minWidth: 'unset',
                        width: '100%',
                        backgroundColor: '#e0e0e0',
                      }}
                      title={decorationButton.title}>
                      {decorationButton.label}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </ModalDialog>

      {/* モーダルクローズ */}
      <svg
        width='25'
        height='25'
        viewBox='0 0 50 50'
        fill='none'
        display='none'>
        <defs>
          <path
            id='form__modal_close'
            data-name='form__modal_close'
            d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
            transform='translate(0 0)'></path>
        </defs>
        <use xlinkHref='#form__modal_close'></use>
      </svg>
    </div>
  )
}

export default React.memo(MessageInput)
