import palette from '../palette'

export default {
  root: {},
  input: {
    '&::placeholder': {
      // opacity: 1,
      // color: palette.text.secondary,
      opacity: 'inherit',
      color: 'inherit',
    },
  },
}
