import { getMessage } from "common/messageUtil";

const attachmentTypeList = [
  { name: 'attachmentExist', label: 'あり', id: '1' },
  { name: 'attachmentNot', label: 'なし', id: '2' },
];

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
];

const replyNecessaryCheckList = [
  { name: 'replyNecessaryCheckOn', label: '表示あり', id: '1' },
  { name: 'replyNecessaryCheckOff', label: '表示なし', id: '2' },
]

const initialValues = {
  searchTargetList: '0',
  searchWordText: '',
  updateFromDate: '',
  updateToDate: '',
  attachment: {
    attachmentExist: '0',
    attachmentNot: '0',
  },
  onlyBulkSend: '0',
  replyNecessaryFlag: {
    replyNecessaryCheckOn: '0',
    replyNecessaryCheckOff: '0',
  },
  replyLimitFromDate: '',
  replyLimitToDate: '',
};

const submitValue = {
  searchTargetList: '0',
  searchWordText: '',
  updateFromDate: '',
  updateToDate: '',
  attachmentExist: '0',
  attachmentNot: '0',
  onlyBulkSend: '0',
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
  recruitmentManagementDivision: '',
  replyNecessaryCheckOn: '0',
  replyNecessaryCheckOff: '0',
  replyLimitFromDate: '',
  replyLimitToDate: '',
};

const textMap = {
  '019': {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCAXS050-019'),
  }
}


export { initialValues, attachmentTypeList, submitValue, templateOptionList, replyNecessaryCheckList, textMap };
