import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { dateToString, isEmpty, stringToDate } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 650,
    marginTop: theme.spacing(1),
    padding: "0px",
    fontWeight: "normal",

    '& table': {
      marginTop: theme.spacing(1),
    },
  },
  interviewDate: {
    color: "#7f7f7f",
    marginRight: "1em",
    fontWeight: "bold",
  },
  mapButton: {
    alignItems: "center",
    background: "none no-repeat center center,/* 常態 */none no-repeat 100vw 100vw,/* マウスオーバー(:hover等) */none no-repeat 100vw 100vw,/* 選択時(.current等) */none no-repeat 100vw 100vw #e0e0e0",
    backgroundClip: "border-box",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: "#1a1a1a",
    cursor: "pointer",
    justifyContent: "center",
    lineHeight: 1,
    minHeight: "30px",
    textDecoration: "none",
    border: '1px solid',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
  },
  interviewScheduleDetail: {
    display: "flex",
    margin: '5px',
    marginLeft: '28px',

  },
  interviewScheduleTable: {
    width: '100%',
    marginLeft: '10px',
    margin: '5px 28px !important',
    padding: '10px',
    maxWidth: '-webkit-fill-available',
    border: '1px solid #dcdcdc',
    fontSize: '12px',
  },
  thText: {
    'text-align': ' center !important',
  }
}))

const dateToTimeString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('HH:mm')
    : null
}

const dateToWeekdayString = (value: Date | null) => {
  if (value) {
    const wDay = new Array(7)
    wDay[0] = '日'
    wDay[1] = '月'
    wDay[2] = '火'
    wDay[3] = '水'
    wDay[4] = '木'
    wDay[5] = '金'
    wDay[6] = '土'
    return Number(moment(value).format('d')) > -1 ? wDay[Number(moment(value).format('d'))] : ''
  }
  return ''
}

interface Props {
  targetList: viewConfig.CandidateDate[]
  interviewScheduleAdjustmentAction: string
  interviewScheduleAdjustmentStatus: string
  interviewScheduleAdjustmentSelection: string
  adjustmentInterviewScheduleId: number[]
  adjustmentInterviewDurationId: string | null
  adjustmentInterviewDetail: string
  interviewScheduleIdList: number[]
  interviewStartTimeList: Array<string | null>
  interviewLocationIdList: Array<number | null>
  interviewStaffNameList: string[]
  interviewDurationId: string | null
  interviewDurationList: viewConfig.InterviewDurationSelect[]
  interviewLocationList: viewConfig.InterviewLocation[]
  interviewScheduleStatusList: string[]
  interviewDetail: string
  newInterviewScheduleStatusList: string[]
  senderCompanyName: string
}

const InterViewTarget = ({
  targetList,
  interviewScheduleAdjustmentAction,
  adjustmentInterviewScheduleId,
  adjustmentInterviewDurationId,
  adjustmentInterviewDetail,
  interviewScheduleAdjustmentStatus,
  interviewScheduleAdjustmentSelection,
  interviewScheduleIdList,
  interviewStartTimeList,
  interviewLocationIdList,
  interviewStaffNameList,
  interviewDurationId,
  interviewDurationList,
  interviewLocationList,
  interviewScheduleStatusList,
  interviewDetail,
  newInterviewScheduleStatusList,
}: Props) => {

  const classes = useStyles();
  const [showInterviewSchedule, setShowInterviewSchedule] = useState(false)
  const [showInterviewCalendar, setShowInterviewCalendar] = useState(false)
  const [showFix, setShowFix] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const recruitmentManagementDivision = useSelector((state: RootState) => state.globalMenu.recruitmentManagementDivision)
  const interviewWebUrlSendFlag = useSelector((state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag)

  useEffect(() => {
    if ((interviewScheduleIdList && interviewScheduleIdList.length > 0) || (targetList && targetList.length > 0)) {
      let showFixStatus = false
      switch (interviewScheduleAdjustmentAction) {
        case magiContants.ACTION_FIX_INTERVIEW:
        case magiContants.ACTION_CHANGE_INTERVIEW:
          showFixStatus = true
          break
        case magiContants.ACTION_CHANGE_POSSIBLE:
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
        case magiContants.ACTION_READJUST_INTERVIEW:
          break
      }
      setShowFix(showFixStatus)

      let showCancelStatus = false
      switch (interviewScheduleAdjustmentAction) {
        case magiContants.ACTION_FIX_INTERVIEW:
          break
        case magiContants.ACTION_CHANGE_POSSIBLE:
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
        case magiContants.ACTION_CHANGE_INTERVIEW:
          if (interviewScheduleAdjustmentStatus === 'inquest') {
            if (interviewScheduleIdList.some((item, index) => adjustmentInterviewScheduleId[0] !== item && interviewScheduleStatusList[index] !== 'client_cancel' && interviewScheduleStatusList[index] !== 'cancel')) {
              showCancelStatus = true
            }
          } else {
            showCancelStatus = true
          }
          break
        case magiContants.ACTION_READJUST_INTERVIEW:
          if (interviewScheduleAdjustmentStatus === 'offer' || interviewScheduleAdjustmentStatus === 'inquest') {
            showCancelStatus = true
          }
          break
      }
      setShowCancel(showCancelStatus)

      switch (interviewScheduleAdjustmentAction) {
        case magiContants.ACTION_READJUST_INTERVIEW:
          if (interviewScheduleAdjustmentStatus === 'offer' || interviewScheduleAdjustmentStatus === 'inquest') {
            if (interviewScheduleIdList && interviewScheduleIdList.length > 0 && ((showCancelStatus && interviewScheduleIdList.some((item, index) => shouldShowResetScheduleRow(index) && interviewStartTimeList[index])) || showFixStatus)) {
              setShowInterviewSchedule(true)
            } else {
              setShowInterviewSchedule(false)
            }
          } else {
            setShowInterviewSchedule(false)
          }
          break
        case magiContants.ACTION_FIX_INTERVIEW:
        case magiContants.ACTION_CHANGE_INTERVIEW:
        case magiContants.ACTION_CHANGE_POSSIBLE:
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
          if (interviewScheduleIdList && interviewScheduleIdList.length > 0 && ((showCancelStatus && interviewScheduleIdList.some((item, index) => shouldShowResetScheduleRow(index) && interviewStartTimeList[index])) || showFixStatus)) {
            setShowInterviewSchedule(true)
          } else {
            setShowInterviewSchedule(false)
          }
          break
      }

      switch (interviewScheduleAdjustmentAction) {
        case magiContants.ACTION_FIX_INTERVIEW:
        case magiContants.ACTION_CHANGE_INTERVIEW:
          setShowInterviewCalendar(false)
          break
        case magiContants.ACTION_READJUST_INTERVIEW:
        case magiContants.ACTION_CHANGE_POSSIBLE:
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
        default:
          if (targetList && targetList.length > 0) {
            setShowInterviewCalendar(true)
          }
          break
      }
    }
  }, [targetList])

  const shouldShowResetScheduleRow = (index: number) => {
    const scheduleStatus: string = interviewScheduleStatusList[index]
    if ((scheduleStatus !== 'client_cancel' && scheduleStatus !== 'cancel')) {
      switch (interviewScheduleAdjustmentAction) {
        case magiContants.ACTION_CHANGE_POSSIBLE:
          return true
          break
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
          if (scheduleStatus === 'fixed') {
            return true
          }
          break
        case magiContants.ACTION_CHANGE_INTERVIEW:
          if (interviewScheduleAdjustmentStatus === 'fixed' && scheduleStatus === 'fixed') {
            return true
          }
          if (interviewScheduleAdjustmentStatus === 'inquest') {
            return false
          }
          break
        case magiContants.ACTION_READJUST_INTERVIEW:
          if (interviewScheduleAdjustmentStatus === 'inquest' || interviewScheduleAdjustmentStatus === 'offer') {
            return true
          }
          break
      }
      return false
    }
    return false
  }

  const mapClick = (mapUrl: string, startTime: string | null, durationTime: any, locationName: string, deleteFlag: boolean) => {

    let newMapUrl = ''
    if (deleteFlag) {
      newMapUrl = mapUrl
    } else {
      let tmpIndex1 = mapUrl.indexOf("?")
      let tmpIndex2 = mapUrl.indexOf("&lat")
      // mapUrlをドメイン部、BT2部、BT2以降部に分割
      let mapUrlDomain = mapUrl.substr(0, tmpIndex1 + 1)
      let mapUrlBt2 = mapUrl.substr(tmpIndex1 + 1, tmpIndex2 - tmpIndex1 - 1)
      let mapUrlBt2Parm = mapUrlBt2.substr(4)
      let mapUrlparam = mapUrl.substr(tmpIndex2 + 1)

      let companyName = '[企業名が入ります]'
      let btParam = encodeURIComponent(companyName);
      const bt = '&BT=' + btParam

      let bt1Param = encodeURIComponent(`${moment(startTime).format('YYYY年M月D日 HH:mm')}開始（${durationTime ? durationTime.displayTime : ''}）`);
      const bt1 = '&BT1=' + bt1Param

      let bt2Param = encodeURIComponent(mapUrlBt2Parm);
      const bt2 = '&BT2=' + bt2Param

      let bt3Param = encodeURIComponent(`${locationName}`);
      const bt3 = '&BT3=' + bt3Param

      newMapUrl = mapUrlDomain + mapUrlparam + bt + bt1 + bt2 + bt3
    }
    handleNewBrowser(newMapUrl, 10, 1280, 948, '')
  }
  const handleNewBrowser = (target: string, p: number, w: number, h: number, label: string) => {
    const params =
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      p +
      ',top=' +
      p +
      ''
    window.open(target, label, params)
    return false
  }

  return (
    <>
      {(showInterviewSchedule || showInterviewCalendar) && interviewWebUrlSendFlag != magiContants.INTERVIEW_WEB_URL_SEND_FLAG && (
        <section>
          <h2>面接日程調整</h2>
          <div style={{ margin: '10px' }}>
            {(interviewScheduleIdList && interviewScheduleIdList.length > 0) &&
              (interviewStartTimeList && interviewStartTimeList.length > 0) &&
              showFix && (
                <>
                  <span style={{ marginTop: "5px" }}>確定する面接日程</span>
                  <dl className={classes.interviewScheduleDetail}>
                    <dt>面接内容：</dt>
                    <dd>{adjustmentInterviewDetail}</dd>
                  </dl>
                  <table className={classes.interviewScheduleTable}>
                    <thead>
                      <tr>
                        <th className={classes.thText}>面接日時</th>
                        <th className={classes.thText}>場所</th>
                        <th className={classes.thText}>担当者</th>
                      </tr>
                    </thead>
                    <tbody>
                      {interviewScheduleIdList.map((item, index) => (
                        <>
                          {interviewStartTimeList[index] && newInterviewScheduleStatusList[index] === 'fixed' /*&& item === adjustmentInterviewScheduleId[0]*/ && (
                            <tr>
                              <td style={{ padding: '5px', width: '30%', fontSize: '12px', textAlign: 'center' }}>
                                {`${dateToString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}${' '}(${dateToWeekdayString(
                                  stringToDate(interviewStartTimeList[index])
                                )})${' '}${dateToTimeString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}～`}
                                {'　('}所要時間：
                                {interviewDurationList.map(item =>
                                  item.interviewDurationId === adjustmentInterviewDurationId
                                    ? item.interviewDurationId !== magiContants.INTERVIEW_DURATION_ID_OTHER
                                      ? item.displayTime
                                      : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                    : ''
                                )}
                                {')'}
                              </td>
                              <td style={{ padding: '5px', width: '50%', textAlign: 'center' }}>
                                {interviewLocationList.map(item => item.id === interviewLocationIdList[index]
                                  ? item.type !== magiContants.INTERVIEW_LOCATION_TYPE_SYSTEM_FIXED
                                    ? <>
                                      {item.name}:{item.address}
                                      {!isEmpty(item.mapUrl) && (
                                        <>
                                          <br />
                                          <Button title='この面接場所の地図を表示します' type='button' className={classes.mapButton} onClick={() => mapClick(item.mapUrl, interviewStartTimeList[index], interviewDurationList.find(item => item.interviewDurationId === adjustmentInterviewDurationId), item.name, item.deleteFlag)} tabIndex={4}>MAP</Button>
                                        </>
                                      )}
                                    </>
                                    : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                  : ''
                                )}
                              </td>
                              <td style={{ padding: '5px', width: '20%', textAlign: 'center' }}>
                                {interviewStaffNameList[index]}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            {(interviewScheduleIdList && interviewScheduleIdList.length > 0) &&
              (interviewStartTimeList && interviewStartTimeList.length > 0) &&
              showCancel && interviewScheduleIdList.some((item, index) => shouldShowResetScheduleRow(index)) && (
                <>
                  <span style={{ marginTop: "5px" }}>取り消す面接日程</span>
                  <dl className={classes.interviewScheduleDetail}>
                    <dt>面接内容：</dt>
                    <dd>{adjustmentInterviewDetail}</dd>
                  </dl>
                  <table className={classes.interviewScheduleTable}>
                    <thead>
                      <tr>
                        <th className={classes.thText}>面接日時</th>
                        <th className={classes.thText}>場所</th>
                        <th className={classes.thText}>担当者</th>
                      </tr>
                    </thead>
                    <tbody>
                      {interviewScheduleIdList.map((item, index) => (
                        <>
                          {shouldShowResetScheduleRow(index) && interviewStartTimeList[index] && (
                            <tr>
                              <td style={{ padding: '5px', width: '30%', textAlign: 'center' }}>
                                {`${dateToString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}${' '}(${dateToWeekdayString(stringToDate(
                                  interviewStartTimeList[index]
                                ))})${' '}${dateToTimeString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}～`}
                                {'　('}所要時間：
                                {interviewDurationList.map(item =>
                                  item.interviewDurationId === adjustmentInterviewDurationId
                                    ? item.interviewDurationId !== magiContants.INTERVIEW_DURATION_ID_OTHER
                                      ? item.displayTime
                                      : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                    : ''
                                )}
                                {')'}
                              </td>
                              <td style={{ padding: '5px', width: '50%', textAlign: 'center' }}>
                                {interviewLocationList.map(item => item.id === interviewLocationIdList[index]
                                  ? item.type !== magiContants.INTERVIEW_LOCATION_TYPE_SYSTEM_FIXED
                                    ? <>
                                      {item.name}:{item.address}
                                      {!isEmpty(item.mapUrl) && (
                                        <>
                                          <br />
                                          <Button title='この面接場所の地図を表示します' type='button' className={classes.mapButton} onClick={() => mapClick(item.mapUrl, interviewStartTimeList[index], interviewDurationList.find(item => item.interviewDurationId === adjustmentInterviewDurationId), item.name, item.deleteFlag)} tabIndex={4}>MAP</Button>
                                        </>
                                      )}
                                    </>
                                    : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                  : ''
                                )}
                              </td>
                              <td style={{ padding: '5px', width: '20%', textAlign: 'center' }}>
                                {interviewStaffNameList[index]}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            {(targetList && targetList.length > 0 && targetList[0].interviewStartTime) &&
              <>
                <span>
                  {(interviewScheduleAdjustmentSelection === '2' || interviewScheduleAdjustmentSelection === '4')
                    ? '確定する面接日程'
                    : '提示する面接日程'
                  }
                </span>
                <div className={classes.interviewScheduleDetail}>
                  <dt>面接内容：</dt>
                  <dd>{interviewDetail}</dd>
                </div>
                <table className={classes.interviewScheduleTable}>
                  <thead>
                    <tr>
                      <th className={classes.thText}>面接日時</th>
                      <th className={classes.thText}>場所</th>
                      <th className={classes.thText}>担当者</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetList.map(applicant => (
                      <>
                        {applicant.interviewStartTime && (
                          <tr>
                            <td style={{ padding: '5px', width: '30%', textAlign: 'center' }}>
                              {`${dateToString(stringToDate(
                                applicant.interviewStartTime
                              ))}${' '}(${dateToWeekdayString(stringToDate(
                                applicant.interviewStartTime
                              ))})${' '}${dateToTimeString(stringToDate(
                                applicant.interviewStartTime
                              ))}～`}
                              {'　('}所要時間：
                              {interviewDurationList.map(item =>
                                item.interviewDurationId === interviewDurationId
                                  ? item.interviewDurationId !== magiContants.INTERVIEW_DURATION_ID_OTHER
                                    ? item.displayTime
                                    : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                  : ''
                              )}
                              {')'}
                            </td>
                            <td style={{ padding: '5px', width: '50%', textAlign: 'center' }}>
                              {interviewLocationList.map(item => String(item.id) === applicant.interviewLocationId
                                ? item.type !== magiContants.INTERVIEW_LOCATION_TYPE_SYSTEM_FIXED
                                  ? <>
                                    {item.name}:{item.address}
                                    {!isEmpty(item.mapUrl) && (
                                      <>
                                        <br />
                                        <Button title='この面接場所の地図を表示します' type='button' className={classes.mapButton} onClick={() => mapClick(item.mapUrl, applicant.interviewStartTime, interviewDurationList.find(item => item.interviewDurationId === interviewDurationId), item.name, item.deleteFlag)} tabIndex={4}>MAP</Button>
                                      </>
                                    )}
                                  </>
                                  : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                : ''
                              )}
                            </td>
                            <td style={{ padding: '5px', width: '20%', textAlign: 'center' }}>
                              {applicant.interviewStaffName}
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            }
          </div>
        </section>
      )}
      {(interviewWebUrlSendFlag == magiContants.INTERVIEW_WEB_URL_SEND_FLAG
        && recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2) && (
          <section>
            <h2>WEB面接日時</h2>
            {(interviewScheduleIdList && interviewScheduleIdList.length > 0) &&
              (interviewStartTimeList && interviewStartTimeList.length > 0) && (
                <>
                  <dl className={classes.interviewScheduleDetail}>
                    <dt>面接内容：</dt>
                    <dd>{adjustmentInterviewDetail}</dd>
                  </dl>
                  <table className={classes.interviewScheduleTable}>
                    <thead>
                      <tr>
                        <th className={classes.thText}>面接日時</th>
                        <th className={classes.thText}>場所</th>
                        <th className={classes.thText}>担当者</th>
                      </tr>
                    </thead>
                    <tbody>
                      {interviewScheduleIdList.map((item, index) => (
                        <>
                          {interviewStartTimeList[index] && (
                            <tr>
                              <td style={{ padding: '5px', width: '30%', textAlign: 'center' }}>
                                {`${dateToString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}${' '}(${dateToWeekdayString(
                                  stringToDate(interviewStartTimeList[index])
                                )})${' '}${dateToTimeString(stringToDate(
                                  interviewStartTimeList[index]
                                ))}～`}
                                {'　('}所要時間：
                                {interviewDurationList.map(item =>
                                  item.interviewDurationId === adjustmentInterviewDurationId
                                    ? item.interviewDurationId !== magiContants.INTERVIEW_DURATION_ID_OTHER
                                      ? item.displayTime
                                      : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                    : ''
                                )}
                                {')'}
                              </td>
                              <td style={{ padding: '5px', width: '50%', textAlign: 'center' }}>
                                {interviewLocationList.map(item => item.id === interviewLocationIdList[index]
                                  ? item.type !== magiContants.INTERVIEW_LOCATION_TYPE_SYSTEM_FIXED
                                    ? <>
                                      {item.name}:{item.address}
                                      {!isEmpty(item.mapUrl) && (
                                        <>
                                          <br />
                                          <Button title='この面接場所の地図を表示します' type='button' className={classes.mapButton} onClick={() => mapClick(item.mapUrl, interviewStartTimeList[index], interviewDurationList.find(item => item.interviewDurationId === adjustmentInterviewDurationId), item.name, item.deleteFlag)} tabIndex={4}>MAP</Button>
                                        </>
                                      )}
                                    </>
                                    : magiContants.INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION
                                  : ''
                                )}
                              </td>
                              <td style={{ padding: '5px', width: '20%', textAlign: 'center' }}>
                                {interviewStaffNameList[index]}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}

          </section>
        )}
    </>
  )
}

export default InterViewTarget
