import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { MCAXS010GetMySearchRequest } from 'types/MCAXS010/MCAXS010GetMySearchRequest'
import { MCAXS020GetMySearchRequest } from 'types/MCAXS020/MCAXS020GetMySearchRequest'
import { getMySearch } from 'reducers/applicantListReducer'
import { setOpen as setOpenAdvancedSearchJobChange } from 'reducers/advancedSearchJobChangeReducer'
import {
  setOpen as setOpenAdvancedSearch,
  setCurrentTab
} from 'reducers/advancedSearchReducer'
import { getMySearch as getMySearchJobChange } from 'reducers/entryListReducer'
import MCARS020 from 'pages/MCARS020'
import MCAXS180 from 'pages/MCAXS180'
import {
  setCreateDialogOpen,
  getActionPreList,
  myInitFlag,
} from 'reducers/selectCriteriaManagementReducer'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { makeStyles } from '@material-ui/core/styles'
import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'
import { MCAXS020SearchRequest } from 'types/MCAXS020/MCAXS020SearchRequest'
import { magiContants } from 'utils/contants'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { ConfirmDialog, ConfirmDialogProps } from 'components/ConfirmDialog'
import { getMessage } from 'common/messageUtil'


const useStyles = makeStyles({
  btnAddJobApplicant: {
    width: '220px !important',
  },
})

interface Props {
  jobChange?: boolean
}

export default ({ jobChange = false }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const searchRequest = useSelector((state: RootState) => state.applicantList.searchRequest)
  const searchRequestJobChange = useSelector((state: RootState) => state.entryList.searchRequest)
  const globalMenu = useSelector((state: RootState) => state.globalMenu)

  // メッセージ入力ダイアログコントロール
  const { isMessageInputDialogOpen } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<ConfirmDialogProps | undefined>()
  const handleClickEntryInsEdit = async () => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    const path = {
      pathname: routeList.entryInsEdit,
      state: {},
    }
    history.push(path)
  }
  const [openApplicantInsEdit, setOpenApplicantInsEdit] = useState(false)

  const handleShowMySearch = () => {
    dispatch(myInitFlag())
    dispatch(setCreateDialogOpen(true))
    dispatch(getActionPreList())
  }

  // 次期開発9月#58497 start
  const handleMySearchClose = (
    entrySearchCriteriaSettingId: string[],
    mySearchCondition: number | null
  ) => {
    // 次期開発9月#58497 end
    //検索詳細条件クリア
    const formikResetButton = document.getElementById('searhConditionReset') as HTMLElement
    if (formikResetButton) {
      formikResetButton.click()
    }

    dispatch(setCurrentTab(0))

    const mySearchRequest: MCAXS010GetMySearchRequest = {
      entrySearchCriteriaSettingId: entrySearchCriteriaSettingId,
    }
    const newSearchRequest: MCAXS010SearchRequest = {
      ...searchRequest,
      searchCount: 1,
      sourceFunctionId: 'MCAXS351',
    }
    dispatch(
      getMySearch({
        mySearchRequest: mySearchRequest,
        searchRequest: newSearchRequest,
        mySearchCondition: mySearchCondition,
      })
    )
  }

  const handleMySearchCloseJobChange = (
    entrySearchCriteriaSettingId: string[]
  ) => {
    //検索詳細条件クリア
    const formikResetButton = document.getElementById('searhConditionReset') as HTMLElement
    if (formikResetButton) {
      formikResetButton.click()
    }

    const mySearchRequest: MCAXS020GetMySearchRequest = {
      entrySearchCriteriaSettingId: entrySearchCriteriaSettingId[0],
    }
    const newSearchRequest: MCAXS020SearchRequest = {
      ...searchRequestJobChange,
      searchCount: 1,
      sourceFunctionId: 'MCAXS401',
    }
    dispatch(
      getMySearchJobChange({
        mySearchRequest: mySearchRequest,
        searchRequest: newSearchRequest,
      })
    )
  }

  return (
    <div className='box-detailed-search'>
      {jobChange ? (
        <>
          {globalMenu.serviceMode !== '1' && (
            <>
              <button
                type='button'
                className='on-icon icon-searchCondition'
                data-action='open-search-condition'
                onClick={handleShowMySearch}>
                MY検索を利用する
              </button>
              <hr />
            </>
          )}
          {globalMenu.marchResponsibleCompanyFlag !== '1' &&
            globalMenu.serviceMode !== '1' && (
              <>
                <button
                  type='button'
                  className='btn on-icon'
                  style={{ display: 'none' }}>
                  モックレイアウト調整用ボタン
                </button>
                <button
                  type='button'
                  className='btn on-icon icon-addApplicant'
                  onClick={handleClickEntryInsEdit}>
                  応募者を登録する
                </button>
              </>
            )}
        </>
      ) : (
          <>
            <button
              type='button'
              className='on-icon icon-searchCondition'
              data-action='open-search-condition'
              onClick={handleShowMySearch}>
              MY検索を利用する
          </button>
            <hr />
            <button
              type='button'
              className={`btn on-icon icon-addApplicant ${classes.btnAddJobApplicant}`}
              onClick={() => setOpenApplicantInsEdit(true)}>
              応募者を追加・更新する
          </button>
            <button
              type='button'
              className='btn on-icon icon-applylistname'
              onClick={() => history.push(routeList.nameCheckInput)}>
              名寄せする
          </button>
          </>
        )}
      <MCARS020
        onClose={jobChange ? handleMySearchCloseJobChange : handleMySearchClose}
      />
      <MCAXS180
        openFlag={openApplicantInsEdit}
        setOpenFlag={setOpenApplicantInsEdit}
      />
      {/* メッセージ有無チェック */}
      {confirmDialogConfig && <ConfirmDialog {...confirmDialogConfig} />}

    </div>
  )
}
