import { Dialog, Button, DialogTitle, DialogActions } from '@material-ui/core'
import { ModalDialog } from 'sp/components/ModalDialog'
import { makeStyles } from '@material-ui/core/styles'
import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import { FastField, Field } from 'formik'
import { FormControl } from '..'
import TextField from '../TextField'
import { useDispatch } from 'react-redux'
import {
  getMcaxs441Init,
  getMcaxs451Init,
  getMcaxs461Init,
  getMcaxs581Init,
  setImagesSubmitFlag,
  setImagesModalObj,
  setGABSubmitFlag,
  setGABModalObj,
  setOPQSubmitFlag,
  setOPQModalObj,
  setCABSubmitFlag,
  setCABModalObj,
} from 'reducers/advancedSearchModalReducer'
import { Option, TestObj } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import LanguageSelect from '../LanguageSelect'
import DatePickerDialog from 'sp/componentsHsc/AdvancedSearchJobChange/DatePickerDialog'
import * as yup from 'yup'
import { validationMessageSet } from 'utils/contants'
import { miscRegexSet } from 'utils/regex'
import SubModal from './SubModal'

const dateYup = yup.object().shape({
  date: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),
})

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: '12px',
    color: 'red',
    whiteSpace: 'pre-wrap',
  },

  inputItem: {
    alignItems: 'baseline !important'
  },
  dateItem: {
    alignItems: 'inherit !important'
  },
}))

interface Errors {
  [key: string]: any
  errorFlag: boolean
  errorName: string
}

export interface ErrorsForCheck {
  from: boolean
  fromInfo: string
  formErrs: String[]
  to: boolean
  toInfo: string
  toErrs: String[]
}

interface Props {
  title: string
  subtitleList: { label: string; value: string }[]
  optionSet: { [key: string]: Option[] }
  form: FormControl
  open: boolean
  onClose: () => void
  name: string
  selectedSubtitleState: [Option, Dispatch<SetStateAction<Option>>]
}

const Modal = ({
  open,
  onClose,
  title,
  subtitleList,
  optionSet,
  form,
  name,
  selectedSubtitleState: [selectedSubtitle, setSelectedSubtitle],
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const scrollListRef = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ]
  const ref = useRef<HTMLDivElement>(null)

  const [confirm, setConfirm] = useState(true)
  const [imagesObj, setImagesObj] = useState(form.values.imagesObj)
  const [gabObj, setGABObj] = useState(form.values.gabObj)
  const [opqObj, setOPQObj] = useState(form.values.opqObj)
  const [cabObj, setCABObj] = useState(form.values.cabObj)
  const [errorsForCheck, setErrorsForCheck] = useState<ErrorsForCheck[]>([])
  const [errorsForCheckFlag, setErrorsForCheckFlag] = useState(false)
  const [errFlg, setErrFlg] = useState(true)
  const [errors, setErrors] = useState([] as Errors[])
  const [subObj, setSubObj] = useState([] as { label: string; value: string }[])
  const [locationSubtitle, setlocationSubtitle] = useState('')
  const [islocationSubtitleAll, setIslocationSubtitleAll] = useState(false)
  const [locationViewflg, setlocationViewflg] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const locationScroll = (index: number) => (viewflg: boolean) => {
    let flglist = locationViewflg
    flglist[index + 1] = viewflg
    setlocationViewflg(flglist)
    setIslocationSubtitleAll(false)

    if (flglist[0]) {
      setlocationSubtitle('')
    } else {
      for (let i = 0; i < locationViewflg.length; i++) {
        if (flglist[i + 1]) {
          setlocationSubtitle(subtitleList[i].value)
          break
        }
      }
    }
  }
  const locationScrollSetNull = () => (viewflg: boolean) => {
    let flglist = locationViewflg
    flglist[0] = viewflg
    setlocationViewflg(flglist)

    if (flglist[0]) {
      setlocationSubtitle('')
    } else {
      for (let i = 0; i < subtitleList.length; i++) {
        if (flglist[i + 1]) {
          setlocationSubtitle(subtitleList[i].value)
          break
        }
      }
    }
  }

  const anchorSectionRef = useRef<HTMLDivElement>(null)
  const targetScroll = (index: number) => {
    const scrollList: HTMLDivElement | null = scrollListRef[index].current
    if (scrollList) {
      if (anchorSectionRef.current) {
        anchorSectionRef.current.scrollTo({
          top: scrollList.offsetTop - anchorSectionRef.current.offsetTop + 27,
          behavior: 'smooth'
        })
      }
    }
  }

  useEffect(() => {
    if (open) {
      if (title === 'イメジス情報を指定') {
        dispatch(getMcaxs441Init())
      } else if (title === 'GAB情報を指定') {
        dispatch(getMcaxs451Init())
      } else if (title === 'OPQ情報を指定') {
        dispatch(getMcaxs581Init())
      } else if (title === 'CAB情報を指定') {
        dispatch(getMcaxs461Init())
      }
    }
    interface TestObj {
      logicalName: string
      physicalName: string
      valueFrom: string
      valueTo: string
    }
    let len: string[] = []
    subtitleList.forEach(subtitle => {
      optionSet[subtitle.value].forEach(option => {
        len.push(option.value)
      })
    })
    let errList: ErrorsForCheck[] = []
    for (let i = 0; i < len.length; i++) {
      const obj: ErrorsForCheck = {
        from: false,
        fromInfo: '',
        formErrs: [],
        to: false,
        toInfo: '',
        toErrs: [],
      }
      errList = [...errList, obj]
    }
    setErrorsForCheck(errList)
    setSubObj(subtitleList)
    if (
      open &&
      ((name === 'imagesObj' &&
        form.values.imagesObj &&
        form.values.imagesObj.length <= 0) ||
        (name === 'gabObj' &&
          form.values.gabObj &&
          form.values.gabObj.length <= 0) ||
        (name === 'opqObj' &&
          form.values.opqObj &&
          form.values.opqObj.length <= 0) ||
        (name === 'cabObj' &&
          form.values.cabObj &&
          form.values.cabObj.length <= 0))
    ) {
      let objList: TestObj[] = []
      for (var i = 0; i < len.length; i++) {
        const obj: TestObj = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
        objList = [...objList, obj]
      }
      form.setFieldValue(name, objList)
      //
    }
    setErrors([])
    setImagesObj(form.values.imagesObj)
    setGABObj(form.values.gabObj)
    setOPQObj(form.values.opqObj)
    setCABObj(form.values.cabObj)
    if (open) {
      if (title === 'イメジス情報を指定') {
        if (form.values.imagesObj && form.values.imagesObj.length > 0) {
          let imagesObj2: TestObj[] = []
          let imagesPhysicalNameList = form.values.imagesObj.map(imagesObj => {
            return imagesObj.physicalName
          })
          len.forEach(option => {
            const index = imagesPhysicalNameList.indexOf(option)
            if (index >= 0 && form.values.imagesObj) {
              imagesObj2.push(form.values.imagesObj[index])
            } else {
              imagesObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.imagesObj = imagesObj2
        }
        dispatch(setImagesModalObj(form.values.imagesObj))
        dispatch(setImagesSubmitFlag(false))
      } else if (title === 'GAB情報を指定') {
        if (form.values.gabObj && form.values.gabObj.length > 0) {
          let gABObj2: TestObj[] = []
          let gABPhysicalNameList = form.values.gabObj.map(gabObj => {
            return gabObj.physicalName
          })
          len.forEach(option => {
            const index = gABPhysicalNameList.indexOf(option)
            if (index >= 0 && form.values.gabObj) {
              gABObj2.push(form.values.gabObj[index])
            } else {
              gABObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.gabObj = gABObj2
        }
        dispatch(setGABModalObj(form.values.gabObj))
        dispatch(setGABSubmitFlag(false))
      } else if (title === 'OPQ情報を指定') {
        if (form.values.opqObj && form.values.opqObj.length > 0) {
          let oPQObj2: TestObj[] = []
          let oPQPhysicalNameList = form.values.opqObj.map(opqObj => {
            return opqObj.physicalName
          })
          len.forEach(option => {
            const index = oPQPhysicalNameList.indexOf(option)
            if (index >= 0 && form.values.opqObj) {
              oPQObj2.push(form.values.opqObj[index])
            } else {
              oPQObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.opqObj = oPQObj2
        }
        dispatch(setOPQModalObj(form.values.opqObj))
        dispatch(setOPQSubmitFlag(false))
      } else if (title === 'CAB情報を指定') {
        if (form.values.cabObj && form.values.cabObj.length > 0) {
          let cABObj2: TestObj[] = []
          let oPQPhysicalNameList = form.values.cabObj.map(cabObj => {
            return cabObj.physicalName
          })
          len.forEach(option => {
            const index = oPQPhysicalNameList.indexOf(option)
            if (index >= 0 && form.values.cabObj) {
              cABObj2.push(form.values.cabObj[index])
            } else {
              cABObj2.push({
                logicalName: '',
                physicalName: '',
                valueFrom: '',
                valueTo: '',
              })
            }
          })
          form.values.cabObj = cABObj2
        }
        dispatch(setCABModalObj(form.values.cabObj))
        dispatch(setCABSubmitFlag(false))
      }
    }
  }, [open])


  const handleCancelClose = () => {
    form.values.imagesObj = imagesObj
    form.values.gabObj = gabObj
    form.values.opqObj = opqObj
    form.values.cabObj = cabObj
    setConfirm(false)
    if (errorsForCheckFlag) {
      let len = 0
      subtitleList.forEach(item => {
        len += optionSet[item.value].length
      })
      let errList: ErrorsForCheck[] = []
      for (let i = 0; i < len; i++) {
        const obj: ErrorsForCheck = {
          from: false,
          fromInfo: '',
          formErrs: [],
          to: false,
          toInfo: '',
          toErrs: [],
        }
        errList = [...errList, obj]
      }
      setErrorsForCheck(errList)
    }
    if (title === 'イメジス情報を指定') {
      dispatch(setImagesSubmitFlag(true))
    } else if (title === 'GAB情報を指定') {
      dispatch(setGABSubmitFlag(true))
    } else if (title === 'OPQ情報を指定') {
      dispatch(setOPQSubmitFlag(true))
    } else if (title === 'CAB情報を指定') {
      dispatch(setCABSubmitFlag(true))
    }
    onClose()
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const handleSubmit = () => {
    let err: boolean = false
    errorsForCheck.forEach(i => {
      if (
        i.from ||
        i.to ||
        (null != i.formErrs && i.formErrs.length !== 0) ||
        (null != i.toErrs && i.toErrs.length !== 0)
      ) {
        err = true
      }
    })
    if (err) {
      return
    }
    let submitErrFlg: boolean = true
    let submitValueObj: TestObj[] = []
    let newSubmitValueObj: TestObj[] = []
    const errorS: Errors[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObj = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObj = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObj = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObj = form.values.cabObj
    }
    for (var i = 0; i < submitValueObj.length; i++) {
      const valueFrom = submitValueObj[i].valueFrom
      const valueTo = submitValueObj[i].valueTo
      const physicalName = submitValueObj[i].physicalName
      if (valueFrom || (valueTo && physicalName)) {
        if (physicalName.toLowerCase().includes('date')) {
          let isValidError = false
          if (valueFrom && physicalName) {
            try {
              dateYup.validateSync({
                date: valueFrom,
              })
            } catch (err) {
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: err.message,
                type: 'valueFrom',
              })
              isValidError = true
              submitErrFlg = false
            }
          }
          if (valueTo && physicalName) {
            try {
              dateYup.validateSync({
                date: valueTo,
              })
            } catch (err) {
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: err.message,
                type: 'valueTo',
              })
              isValidError = true
              submitErrFlg = false
            }
          }
          if (
            !isValidError &&
            valueTo != '' &&
            valueTo != null &&
            valueFrom > valueTo
          ) {
            submitErrFlg = false
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: '終了日が開始日よりも前です',
              type: 'valueTo',
            })
          } else if (!isValidError) {
            newSubmitValueObj[i] = submitValueObj[i]
          }
        } else if (physicalName.toLowerCase().includes('exam_language')) {
          newSubmitValueObj[i] = submitValueObj[i]
        } else if (
          valueFrom != '' &&
          valueTo != '' &&
          Number(valueFrom) > Number(valueTo)
        ) {
          submitErrFlg = false
          errorS.push({
            errorFlag: true,
            errorName: physicalName,
          })
        } else {
          newSubmitValueObj[i] = submitValueObj[i]
        }
      }
    }
    setErrFlg(submitErrFlg)
    setErrors(errorS)
    if (errFlg) {
      if (title === 'イメジス情報を指定') {
        form.values.imagesObj = newSubmitValueObj
        dispatch(setImagesSubmitFlag(true))
      } else if (title === 'GAB情報を指定') {
        form.values.gabObj = newSubmitValueObj
        dispatch(setGABSubmitFlag(true))
      } else if (title === 'OPQ情報を指定') {
        form.values.opqObj = newSubmitValueObj
        dispatch(setOPQSubmitFlag(true))
      } else if (title === 'CAB情報を指定') {
        form.values.cabObj = newSubmitValueObj
        dispatch(setCABSubmitFlag(true))
      }
      handleClose()
    }
  }
  const handleLink = (index: number) => () => {
    targetScroll(index)
    setIslocationSubtitleAll(false)
  }

  const range = optionSet
    ? Object.keys(optionSet).reduce((result, current, index) => {
      if (index === 0) {
        result[current] = 0
        return result
      }

      const lastElement = Object.keys(optionSet)[index - 1]
      result[current] = optionSet[lastElement].length + result[lastElement]
      return result
    }, {} as any)
    : null

  const getDateErrorMessage = (
    selectedSubtitle: any,
    errors: Errors[],
    index: number,
    type: string
  ) => {
    const labelValue = optionSet[selectedSubtitle][index].value
    const errorLabels = errors.map(i => {
      return i.errorName
    })
    if (errorLabels.includes(labelValue)) {
      const tartget = errors.find(
        i => i.errorName === labelValue && i.type === type
      )
      if (tartget) {
        return {
          type: tartget.type,
          message: tartget.message,
        }
      }
    }
    return undefined
  }

  const inputOnBlur = () => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        const valueFrom = submitValueObjs[i].valueFrom
        const valueTo = submitValueObjs[i].valueTo
        const physicalName = submitValueObjs[i].physicalName
        if (valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  const inputDateOnBlur = () => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        const valueFrom = submitValueObjs[i].valueFrom
        const valueTo = submitValueObjs[i].valueTo
        const physicalName = submitValueObjs[i].physicalName
        let isValidError = false
        if (
          valueFrom &&
          physicalName &&
          physicalName.toLowerCase().includes('date')
        ) {
          try {
            dateYup.validateSync({
              date: valueFrom,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueFrom',
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (
          valueTo &&
          physicalName &&
          physicalName.toLowerCase().includes('date')
        ) {
          try {
            dateYup.validateSync({
              date: valueTo,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueTo',
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (!isValidError && valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: '終了日が開始日よりも前です',
                type: 'valueTo',
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  const inputDateOnChange = (
    index: any,
    name: any,
    targetPhysicalName: any,
    value: any
  ) => {
    let blurErrFlg: boolean = true
    const errorS: Errors[] = []
    let submitValueObjs: TestObj[] = []
    if (title === 'イメジス情報を指定' && form.values.imagesObj != null) {
      submitValueObjs = form.values.imagesObj
    } else if (title === 'GAB情報を指定' && form.values.gabObj != null) {
      submitValueObjs = form.values.gabObj
    } else if (title === 'OPQ情報を指定' && form.values.opqObj != null) {
      submitValueObjs = form.values.opqObj
    } else if (title === 'CAB情報を指定' && form.values.cabObj != null) {
      submitValueObjs = form.values.cabObj
    }
    if (submitValueObjs && submitValueObjs.length > 0) {
      for (var i = 0; i < submitValueObjs.length; i++) {
        let valueFrom = submitValueObjs[i].valueFrom
        let valueTo = submitValueObjs[i].valueTo
        let physicalName = submitValueObjs[i].physicalName
        let isValidError = false
        if (index === i) {
          physicalName = targetPhysicalName
          if (name === 'valueFrom') {
            valueFrom = value
          } else {
            valueTo = value
          }
        }
        if (
          valueFrom &&
          physicalName &&
          physicalName.toLowerCase().includes('date')
        ) {
          try {
            dateYup.validateSync({
              date: valueFrom,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueFrom',
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (
          valueTo &&
          physicalName &&
          physicalName.toLowerCase().includes('date')
        ) {
          try {
            dateYup.validateSync({
              date: valueTo,
            })
          } catch (err) {
            errorS.push({
              errorFlag: true,
              errorName: physicalName,
              message: err.message,
              type: 'valueTo',
            })
            isValidError = true
            blurErrFlg = false
          }
        }
        if (!isValidError && valueFrom && valueTo && physicalName) {
          if (physicalName.toLowerCase().includes('date')) {
            if (valueFrom > valueTo) {
              blurErrFlg = false
              errorS.push({
                errorFlag: true,
                errorName: physicalName,
                message: '終了日が開始日よりも前です',
                type: 'valueTo',
              })
            }
          }
        }
      }
      setErrors(errorS)
      setErrFlg(blurErrFlg)
    }
  }

  const anchorItemRef = useRef<HTMLLIElement>(null)
  useEffect(() => {
    if (anchorItemRef.current) {
      anchorItemRef.current.classList.remove('is-modal-anchor-hidden')
      anchorItemRef.current.classList.add('is-modal-anchor-current')
    }
  }, [locationSubtitle])

  return (
    <>
      <ModalDialog
        isOpen={open} className={'modal'}>
        <div className={'modal-content'}>
          <div
            className={'modal-title-wrap'}>
            <h3 className={'modal-title'}>
              {title}
            </h3>
            <span className={'close-btn'} onClick={handleCancelClose}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use
                  xlinkHref='#form__modal_close'
                  onClick={handleCancelClose}></use>
              </svg>
            </span>
          </div>
          <div className={'modal-section__include-anchor'} ref={anchorSectionRef}>
            {!islocationSubtitleAll && (
              <div className={'modal-anchor'}>
                <ul>
                  <SubModal locationSubtitle={locationScrollSetNull()}>
                    {subtitleList.map((subtitle, index: number) => (
                      <li
                        className={'modal-anchor__item'}>
                        <a onClick={handleLink(index)}>{subtitle.label}</a>
                      </li>
                    ))}
                  </SubModal>
                </ul>
              </div>
            )}
            {subtitleList.map((subtitle, index: number) =>
              subtitle.value === locationSubtitle && !islocationSubtitleAll ? (
                <div className={'modal-anchor modal-anchor-fixed'} >
                  <ul>
                    <li className={'modal-anchor__item is-modal-anchor-hidden'} ref={anchorItemRef}>
                      <a onClick={handleLink(index)}>{subtitle.label}</a>
                    </li>
                  </ul>
                  <span className={'modal-anchor__arrow_below is-modal-anchor-active'}
                    onClick={() => (setIslocationSubtitleAll(true))}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='23.239'
                      height='13.639'
                      viewBox='0 0 46.478 27.278'>
                      <use xlinkHref='#modal-anchor__arrow_below'></use>
                    </svg>
                  </span>
                </div>
              ) : (
                  ''
                )
            )}
            {islocationSubtitleAll && (
              <div className={'modal-anchor modal-anchor-fixed'}>
                <ul>
                  <SubModal locationSubtitle={locationScrollSetNull()}>
                    {subtitleList.map((subtitle, index: number) => (
                      <li
                        className={'modal-anchor__item'}>
                        <a onClick={handleLink(index)}>{subtitle.label}</a>
                      </li>
                    ))}
                  </SubModal>
                </ul>
                <span className={'modal-anchor__arrow_top is-modal-anchor-active'}
                  onClick={() => (setIslocationSubtitleAll(false))}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='23.239'
                    height='13.639'
                    viewBox='0 0 46.478 27.278'>
                    <use xlinkHref='#modal-anchor__arrow_top'></use>
                  </svg>
                </span>
              </div>
            )}
            <div className={'form__body_modal_2'}>
              {subObj.map((suboption, index: number) =>
                optionSet[suboption.value] && range ? (
                  <>
                    <div
                      ref={scrollListRef[index]}
                      className={'modal-anchor__content'}>
                      <SubModal locationSubtitle={locationScroll(index)}>
                        <div className={'form__ttl_modal mb10'}>
                          {suboption.label}
                        </div>
                        <div className={'form__body_modal'}>
                          {optionSet[suboption.value].map((option, index) => (
                            <div key={option.value}>
                              <div className={'form__sttl_modal'}>
                                {option.label}
                              </div>
                              {option.value.toLowerCase().includes('date') ? (
                                (() => {
                                  const checkResultFrom = getDateErrorMessage(
                                    suboption.value,
                                    errors,
                                    index,
                                    'valueFrom'
                                  )
                                  const checkResultTo = getDateErrorMessage(
                                    suboption.value,
                                    errors,
                                    index,
                                    'valueTo'
                                  )
                                  return (
                                    <>
                                      {open && (
                                        <div className={`form__body_item_gap0 ml0 ${classes.dateItem}`}>
                                          <label >
                                            <Field
                                              name={`${name}[${index +
                                                range[suboption.value]}].valueFrom`}
                                              selectsStart
                                              component={DatePickerDialog}
                                              placeholder='YYYY/MM/DD'
                                              option={option}
                                              onBlur={inputDateOnBlur}
                                              onChange={inputDateOnChange}
                                              targetIndex={
                                                index + range[suboption.value]
                                              }
                                              dateYup={dateYup}
                                            />
                                            {checkResultFrom && (
                                              <p className={classes.helperText}>
                                                {checkResultFrom.message}
                                              </p>
                                            )}
                                          </label>
                                          <span className={'form__text_while_2'} style={{ padding: '8px 2px' }}>
                                            ～
                                        </span>
                                          <label>
                                            <Field
                                              name={`${name}[${index +
                                                range[suboption.value]}].valueTo`}
                                              selectsEnd
                                              component={DatePickerDialog}
                                              placeholder='YYYY/MM/DD'
                                              option={option}
                                              onBlur={inputDateOnBlur}
                                              onChange={inputDateOnChange}
                                              className={'form__input-calender'}
                                              targetIndex={
                                                index + range[suboption.value]
                                              }
                                              dateYup={dateYup}
                                            />
                                            {checkResultTo && (
                                              <p className={classes.helperText}>
                                                {checkResultTo.message}
                                              </p>
                                            )}
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )
                                })()
                              ) : option.value
                                .toLowerCase()
                                .includes('exam_language') ? (
                                    <div className={'form__body_item_gap0 ml0'}>
                                      <div className={'form__control'}
                                        style={{ display: 'block', border: 'none' }}>
                                        <Field
                                          name={`${name}[${index +
                                            range[suboption.value]}].valueFrom`}
                                          component={LanguageSelect}
                                          placeholder=''
                                          option={option}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className={`form__body_item_column ml0 ${classes.inputItem}`}>
                                      <Field
                                        name={`${name}[${index +
                                          range[suboption.value]}].valueFrom`}
                                        component={TextField}
                                        onBlur={() => inputOnBlur()}
                                        placeholder='半角数字'
                                        option={option}
                                        errorsForCheckState={[
                                          errorsForCheck,
                                          setErrorsForCheck,
                                        ]}
                                        className={'form__input_140w'}
                                        title={title}
                                      />
                                      <span className={'form__text_while'}>～</span>
                                      <Field
                                        name={`${name}[${index +
                                          range[suboption.value]}].valueTo`}
                                        component={TextField}
                                        onBlur={() => inputOnBlur()}
                                        placeholder='半角数字'
                                        option={option}
                                        errorsForCheckState={[
                                          errorsForCheck,
                                          setErrorsForCheck,
                                        ]}
                                        className={'form__input_140w'}
                                        title={title}
                                      />
                                    </div>
                                  )}
                            </div>
                          ))}
                        </div>
                      </SubModal>
                    </div>
                  </>
                ) : null
              )}
            </div>
          </div>
          <DialogActions className={'modal-section-btn'} >
            <button
              onClick={handleCancelClose}
              color='primary'
              className={'btn__cancel'}>
              キャンセルする
            </button>
            <button
              onClick={handleSubmit}
              color='primary'
              className={'btn__blue_ok'}>
              OK
            </button>
          </DialogActions>
        </div>
      </ModalDialog>
      <svg xmlns="http://www.w3.org/2000/svg" width="23.239" height="13.639" viewBox="0 0 46.478 27.278" fill="none" display="none">
        <defs>
          <path id="modal-anchor__arrow_below" data-name="modal-anchor__arrow_below" d="M42.438,0l-19.2,19.2-5.493-5.492L4.039,0,0,4.04l23.24,23.238L46.477,4.04Z" transform="translate(0)" fill="#595959" />
        </defs>
        <use xlinkHref="#modal-anchor__arrow_below"></use>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="23.239" height="13.639" viewBox="0 0 46.478 27.278" fill="none" display="none">
        <defs>
          <path id="modal-anchor__arrow_top" data-name="modal-anchor__arrow_top" d="M42.438,0l-19.2,19.2-5.493-5.492L4.039,0,0,4.04l23.24,23.238L46.477,4.04Z" transform="translate(46.477 27.278) rotate(180)" fill="#595959" />
        </defs>
        <use xlinkHref="#modal-anchor__arrow_top"></use>
      </svg>

    </>
  )
}

export default Modal
