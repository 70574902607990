import React from 'react'
const EntryDetailsStepIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='17'
      height='28'
      viewBox='0 0 34.425 56.38'
      fill='none'
      display='none'>
      <defs>
        <g id='entry-details-step__icon_flag' transform='translate(0 0)'>
          <g transform='translate(0 0)'>
            <path
              d='M.7.5C.045.957-.085,7.02.045,28.733.24,52.793.37,56.38,1.219,56.38s1.042-1.891,1.369-11.41c.2-6.261.39-11.477.457-11.542s2.086-1.109,4.562-2.348c2.413-1.237,7.043-3.65,10.238-5.347,3.26-1.694,8.02-4.237,10.693-5.672,7.238-3.717,7.173-3.587,3.2-6.2a66.745,66.745,0,0,0-7.368-4.042c-2.151-1.042-7.043-3.39-10.89-5.217A54.945,54.945,0,0,0,5.522,1.282,1.838,1.838,0,0,1,4.152.63C3.7-.152,1.871-.217.7.5'
              transform='translate(0 0)'
            />
          </g>
        </g>
      </defs>
      <use xlinkHref='#entry-details-step__icon_flag'></use>
    </svg>
  )
}
export default EntryDetailsStepIcon
