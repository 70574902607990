import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, SvgIcon, colors, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles(theme => ({
  mapLabel: {
    color: '#3380cc',
    border: '2px solid #3380cc',
    width: 45,
    textAlign: 'center',
    borderRadius: '5px',
    marginLeft: 70,
  },
  selectButton: {
    color: '#FFFFFF !important',
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    height: 30,
    fontSize: 12,
  },
  cursorAuto: {
    cursor: 'auto',
  },
}))

interface params {
  setUpdateFormModal: Function
  candidateListId: number
  id: string
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
}
const Results = ({
  setUpdateFormModal,
  candidateListId,
  id,
  setSelectInterviewLocationId,
  setSelectInterviewLocationName,
  setSelectInterviewLocationAddress,
  setSelectInterviewLocationMapUrl,
}: params) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const itemList = useSelector(
    (state: RootState) => state.interview.interviewLocationResultList
  )
  const setFrontData = (
    id: string,
    name: string,
    address: string,
    mapUrl: string
  ) => {
    setUpdateFormModal(false)
    setSelectInterviewLocationId(id)
    setSelectInterviewLocationName(name)
    setSelectInterviewLocationAddress(address)
    setSelectInterviewLocationMapUrl(mapUrl)
  }
  return (
    <div>
      {itemList.map((item, index) => (
        <div
          className='message-target-list__wrap'
          style={{ wordBreak: 'break-all' }}>
          <div
            className='message-target-list__inner'
            style={
              item.interviewLocationSettingId == Number.parseInt(id)
                ? { backgroundColor: '#f4cccc' }
                : {}
            }>
            {item.interviewLocationSettingId == Number.parseInt(id) ? (
              <label className={classes.cursorAuto}>選択中</label>
            ) : (
              <Button
                type='button'
                className={classes.selectButton}
                onClick={() => {
                  setFrontData(
                    item.interviewLocationSettingId.toString(),
                    item.interviewLocationName,
                    item.interviewLocationAddress,
                    item.mapUrl
                  )
                }}>
                選択する
              </Button>
            )}
            <dl className='message-target-info'>
              <dt className='message-target-info__head'>面接場所名</dt>
              <dd className='message-target-info__text'>
                {' '}
                {item.interviewLocationName}
              </dd>
              <dt className='message-target-info__head'>住所</dt>
              <dd className='message-target-info__text'>
                {item.interviewLocationAddress}
              </dd>
              {item.mapUrl && (
                <>
                  <dt></dt>
                  <dd className='message-target-info__text'>
                    <div className={classes.mapLabel}>MAP</div>
                  </dd>
                </>
              )}
            </dl>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Results
