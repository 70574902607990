import React, { useState, Dispatch, SetStateAction } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControl } from '@material-ui/core'
import { Option } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { ErrorsForCheck } from './Modal/Modal'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  textarea: {
    resize: 'none',
  },
}))

interface Props extends FieldProps {
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
  option: Option
  modalName: string
  errorsForCheckState: [
    ErrorsForCheck[],
    Dispatch<SetStateAction<ErrorsForCheck[]>>
  ]
  title: string
}

export default ({
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  multiline,
  type,
  option,
  errorsForCheckState: [errorsForCheck, setErrorsForCheck],
  title,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form
  var arr1 = name.match(/\[(\S*)\]/) != null ? name.match(/\[(\S*)\]/) : []
  var nameString = arr1 != null ? arr1[1] : ''
  const [index, setIndex] = useState<number>(
    !isNaN(Number(nameString)) ? Number(nameString) : -1
  )

  const handleOnBlur = () => {
    let from
    let to
    if (title === 'イメジス情報を指定') {
      from = form.values.imagesObj[index].valueFrom
      to = form.values.imagesObj[index].valueTo
    } else if (title === 'GAB情報を指定') {
      from = form.values.gabObj[index].valueFrom
      to = form.values.gabObj[index].valueTo
    } else if (title === 'OPQ情報を指定') {
      from = form.values.opqObj[index].valueFrom
      to = form.values.opqObj[index].valueTo
    } else if (title === 'CAB情報を指定') {
      from = form.values.cabObj[index].valueFrom
      to = form.values.cabObj[index].valueTo
    }
    if (errorsForCheck[index].toInfo != '半角数字ではありません') {
      let errList: ErrorsForCheck[] = [...errorsForCheck]
      if (from && to) {
        if (Number(from) > Number(to)) {
          errList[index].to = true
          errList[index].toInfo = getMessage(
            magiContants.Correlation_Item_Check_Error_Message
          )
          setErrorsForCheck(errList)
        } else {
          errList[index].to = false
          errList[index].toInfo = ''
          setErrorsForCheck(errList)
        }
      } else {
        errList[index].to = false
        errList[index].toInfo = ''
        setErrorsForCheck(errList)
      }
    }
  }

  const handleChange = (e: any) => {
    if (!(hint && e.target.value.length > 100)) {
      form.setFieldValue(name, e.target.value)
      form.setFieldValue(`${name.split('.')[0]}.physicalName`, option.value)
      form.setFieldValue(`${name.split('.')[0]}.logicalName`, option.label)
      if (!/^\d*$/u.test(e.target.value)) {
        let errList: ErrorsForCheck[] = [...errorsForCheck]
        if (name.indexOf('valueFrom') > 0) {
          try {
            yup
              .object()
              .shape({ [name]: yup.string() })
              .validateSync({ [name]: e.target.value })
          } catch (err) {
            errList[index].from = true
            errList[index].formErrs = err.message.props.children
            errList[index].fromInfo = ''
            return
          }
        } else if (name.indexOf('valueTo') > 0) {
          try {
            yup
              .object()
              .shape({ [name]: yup.string() })
              .validateSync({ [name]: e.target.value })
          } catch (err) {
            errList[index].to = true
            errList[index].toErrs = err.message.props.children
            errList[index].toInfo = ''
            return
          }
        }
        if (name.indexOf('valueFrom') > 0) {
          errList[index].from = true
          errList[index].fromInfo = '半角数字ではありません'
          errList[index].formErrs = []
        } else if (name.indexOf('valueTo') > 0) {
          errList[index].to = true
          errList[index].toInfo = '半角数字ではありません'
          errList[index].toErrs = []
        }
        setErrorsForCheck(errList)
      } else {
        let errList: ErrorsForCheck[] = [...errorsForCheck]
        if (name.indexOf('valueFrom') > 0) {
          errList[index].from = false
          errList[index].fromInfo = ''
          errList[index].formErrs = []
        } else if (name.indexOf('valueTo') > 0) {
          errList[index].to = false
          errList[index].toInfo = ''
          errList[index].toErrs = []
        }
        setErrorsForCheck(errList)
      }
      let from
      let to
      if (title === 'イメジス情報を指定') {
        from = name.includes('From')
          ? e.target.value
          : form.values.imagesObj[index].valueFrom
        to = name.includes('To')
          ? e.target.value
          : form.values.imagesObj[index].valueTo
      } else if (title === 'GAB情報を指定') {
        from = name.includes('From')
          ? e.target.value
          : form.values.gabObj[index].valueFrom
        to = name.includes('To')
          ? e.target.value
          : form.values.gabObj[index].valueTo
      } else if (title === 'OPQ情報を指定') {
        from = name.includes('From')
          ? e.target.value
          : form.values.opqObj[index].valueFrom
        to = name.includes('To')
          ? e.target.value
          : form.values.opqObj[index].valueTo
      } else if (title === 'CAB情報を指定') {
        from = name.includes('From')
          ? e.target.value
          : form.values.cabObj[index].valueFrom
        to = name.includes('To')
          ? e.target.value
          : form.values.cabObj[index].valueTo
      }
      if (errorsForCheck[index].toInfo != '半角数字ではありません') {
        let errList: ErrorsForCheck[] = [...errorsForCheck]
        if (from && to) {
          if (Number(from) > Number(to)) {
            errList[index].to = true
            errList[index].toInfo = getMessage(
              magiContants.Correlation_Item_Check_Error_Message
            )
            setErrorsForCheck(errList)
          } else {
            errList[index].to = false
            errList[index].toInfo = ''
            setErrorsForCheck(errList)
          }
        } else {
          errList[index].to = false
          errList[index].toInfo = ''
          setErrorsForCheck(errList)
        }
      }
    }
  }

  const getStyle = () => {
    if (
      (name.indexOf('valueFrom') > 0 &&
        errorsForCheck[index].fromInfo !== '') ||
      (name.indexOf('valueTo') > 0 && errorsForCheck[index].toInfo !== '')
    ) {
      return {
        borderColor: 'red',
      }
    }
    if (
      (name.indexOf('valueFrom') > 0 &&
        errorsForCheck[index].formErrs != null &&
        errorsForCheck[index].formErrs.length !== 0) ||
      (name.indexOf('valueTo') > 0 &&
        errorsForCheck[index].toErrs != null &&
        errorsForCheck[index].toErrs.length !== 0)
    ) {
      return {
        borderColor: 'red',
      }
    }
  }

  return (
    <FormControl>
      <div className={className}>
        {multiline ? (
          <textarea
            value={value ? value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            className={`${classes.root} ${classes.textarea}`}
            disabled={disabled}
            name={name}
          />
        ) : (
          <input
            type={type ? type : 'text'}
            style={getStyle()}
            value={value ? value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            className={classes.root}
            disabled={disabled}
            onBlur={handleOnBlur}
            name={name}
          />
        )}
        {hint ? (
          <div className={classes.hint}>
            <Typography variant='caption'>{`入力${value.length} / 最大100`}</Typography>
          </div>
        ) : null}
        {name.indexOf('valueFrom') > 0 &&
          errorsForCheck[index].formErrs != null &&
          errorsForCheck[index].formErrs.length !== 0 ? (
          <p style={{ fontSize: '12px', color: 'red', width: '150px' }}>
            {errorsForCheck[index].formErrs}
          </p>
        ) : name.indexOf('valueFrom') > 0 &&
          errorsForCheck[index].fromInfo !== '' ? (
          <p style={{ fontSize: '12px', color: 'red', width: '150px' }}>
            {errorsForCheck[index].fromInfo}
          </p>
        ) : null}
        {name.indexOf('valueTo') > 0 &&
          errorsForCheck[index].toErrs != null &&
          errorsForCheck[index].toErrs.length !== 0 ? (
          <p style={{ fontSize: '12px', color: 'red', width: '150px' }}>
            {errorsForCheck[index].toErrs}
          </p>
        ) : name.indexOf('valueTo') > 0 &&
          errorsForCheck[index].toInfo !== '' ? (
          <p style={{ fontSize: '12px', color: 'red', width: '150px' }}>
            {errorsForCheck[index].toInfo}
          </p>
        ) : null}
      </div>
    </FormControl>
  )
}
