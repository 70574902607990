import React from 'react'
import { PropsInterface } from './interface'
import './style_group_horizontal.css'

export default function GroupHorizontal(props: PropsInterface) {
  // クリックイベント
  const click = (event: any) => {
    // ボタンの状態を設定
    setDecisionDivision(event.target.value)
    // クリック後実行したメソッド
    props.handlerClick(event.target.value)
  }

  // ボタンの表示名の定義
  let buttonNameDefinition = new Map([
    ['', null],
    ['0', ['判定中', '合格', '不合格']],
    ['1', ['参加', '不参加', 'その他']],
    ['2', ['判定中', '合格', '不合格']],
  ])

  // ボタンの値の定義
  let buttonValueDefinition = new Map([
    ['', null],
    ['0', ['1', '3', '5']],
    ['1', ['2', '4', '6']],
    ['2', ['1', '3', '5']],
  ])

  // 判定区分種別
  const [decisionDivisionType, setDecisionDivisionType] = React.useState(
    props.decisionDivisionType
  ) // 今のボタンの表示名

  const [buttonName, setButtonName] = React.useState(
    buttonNameDefinition.get(props.decisionDivisionType)
  )
  // 今のボタンの値
  const [buttonValue, setButtonValue] = React.useState(
    buttonValueDefinition.get(props.decisionDivisionType)
  )
  // どのボタンが選ばれました
  const [decisionDivision, setDecisionDivision] = React.useState(
    props.decisionDivision
  )

  // リセットの場合、状態がリセット
  React.useEffect(() => {
    if (
      decisionDivisionType != props.decisionDivisionType ||
      decisionDivision != props.decisionDivision
    ) {
      setDecisionDivisionType(props.decisionDivisionType)
      setButtonName(buttonNameDefinition.get(props.decisionDivisionType))
      setButtonValue(buttonValueDefinition.get(props.decisionDivisionType))
      setDecisionDivision(props.decisionDivision)
    }
  }, [props.decisionDivisionType, props.decisionDivision])

  // 判定区分種別がnullの場合、ボタンが表示しない
  if (buttonName == null || undefined) {
    return <p className='group-horizontal-hidden'></p>
  }

  return (
    <ul className='entry-select__statusList' tabIndex={-1}>
      {buttonName.map((name, index) => {
        let value = buttonValue == null || undefined ? '' : buttonValue[index]
        return (
          <li className='entry-select__status'>
            <label className='entry-select__label'>
              <input
                type='radio'
                name='change-progress-radio-01'
                value={value}
                checked={
                  decisionDivision == value && props.checkedFlag ? true : false
                }
                onClick={click}></input>
              <mark tabIndex={0}>{name}</mark>
            </label>
          </li>
        )
      })}
    </ul>
  )
}
