import React, { useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { FastField } from 'formik'
import Layout from './Layout'
import { Select } from 'componentsHsc'
import { Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import {
  selectTemplate,
  setEmptyTemplateSettingId,
} from 'reducers/messageSendReducer'
import { FormHandler } from '.'

const useStyles = makeStyles(theme => ({
  select: {
    marginLeft: '20px',
    whiteSpace: 'nowrap',
    width: '300px',
    '& div': {
      cursor: 'pointer!important',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
}))

interface Props {
  form: FormHandler
  isInitialized: boolean
  clearFile: () => void
}

const optionList: {
  value: string
  label: string
}[] = [
    {
      value: '-1',
      label: '指定しない',
    },
  ]

const MessageTemplate = ({ form, isInitialized, clearFile }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const selectedTemplateSettingId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.title.selectedTemplateSettingId
  )
  const messageTemplate = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.title.messageTemplate
  )
  let placeholder = ''

  messageTemplate.map(e => {
    if (
      e &&
      e.messageTemplateSettingId.toString() === selectedTemplateSettingId
    ) {
      placeholder = e.sequence
        ? e.sequence + '. ' + e.templateName
        : e.templateName
    }
  })

  useEffect(() => {
    if (isInitialized) {
      if (optionList.length > 1) {
        // すでにリストが作成されている場合は1件目だけにする。
        optionList.splice(1, optionList.length - 1)
      }
      messageTemplate.forEach(e => {
        optionList.push({
          value: e.messageTemplateSettingId.toString(),
          label: e.sequence
            ? e.sequence + '. ' + e.templateName
            : e.templateName,
        })
      })
    }
  }, [isInitialized])

  useEffect(() => {
    if (isInitialized) {
      const id = Number(form.values.templateName.value)
      const label = form.values.templateName.label
      if (label !== placeholder) {
        placeholder = ''
        dispatch(selectTemplate({ id, inputValues: form.values }))
        clearFile()
      }
    }
  }, [form.values.templateName.value])

  useEffect(() => {
    if (isInitialized) {
      const label = form.values.templateName.label
      if (label === '指定しない') {
        dispatch(setEmptyTemplateSettingId())
      }
    }
  }, [form.values.templateName.label])

  return (
    <section id='message-template'>
      <section>
        <h1>テンプレート</h1>
      </section>
      <label>
        <div>
          <FastField
            optionList={optionList}
            className={classes.select}
            name='templateName'
            placeholder={placeholder}
            component={Select}
            noOptionsMessage='一致する候補が見つかりません'
          />
        </div>
      </label>
    </section>
  )
}

export default React.memo(MessageTemplate)
