import React from 'react'
import { FieldProps } from 'formik'
import CreatableSelect from 'react-select/creatable'
import customSelectOptions from 'utils/customSelectOptions'
import { magiContants } from 'utils/contants'
import { makeStyles, responsiveFontSizes, Theme } from '@material-ui/core/styles'
import { classNames } from 'react-select/src/utils'
import { components } from 'react-select'
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue'
import { SvgIcon } from '@material-ui/core'


const selectStyles = (errorFlg: boolean) => {
  if (errorFlg) {
    return {
      input: () => ({
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
        wordBreak: 'break-all'
      }),
      menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
      container: (provided: any) => ({ ...provided, border: 'none' }),
      multiValue: (provided: any) => ({ ...provided, maxWidth: '80vw', backgroundColor: '#ECEFF1', borderRadius: '5px', }),
      multiValueLabel: (provided: any) => ({ ...provided, fontSize: '1.6rem !important' }),
      option: (styles: any) => ({ ...styles, wordBreak: 'break-all' }),
      control: (provided: any) => ({
        ...provided,
        boxShadow: '0 0 0 0px',
        borderColor: '#cccccc',
        '&:hover': {
          borderColor: '#cccccc',
        },
      }),
      multiValueRemove: (css: any) => ({ ...css, '&:hover': { background: 'none' }, padding: '0px' }),
    }
  } else {
    return {
      input: () => ({
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
        wordBreak: 'break-all'
      }),
      menu: (provided: any) => ({ ...provided, zIndex: 1000, fontSize: '1.4rem', }),
      container: (provided: any) => ({
        ...provided, border: 'none', fontSize: '1.6rem',
      }),
      multiValue: (provided: any) => ({ ...provided, maxWidth: '80vw', borderRadius: '5px', backgroundColor: '#ECEFF1' }),
      multiValueLabel: (provided: any) => ({ ...provided, fontSize: '1.6rem !important' }),
      option: (provided: any) => ({ ...provided, wordBreak: 'break-all' }),
      control: (provided: any,) => ({
        ...provided,
        borderColor: '#cccccc',
        boxShadow: '0 0 0 0px',
        '&:hover': {
          borderColor: '#cccccc',
        },
        minHeight: '40px !important',
        '& div': {
          '& span': {
            marginBottom: '5px',
            marginTop: '5px',
          },
        },
      }),
      multiValueRemove: (css: any) => ({ ...css, '&:hover': { background: 'none' } }),

    }
  }
}
const useStyles = makeStyles(theme => ({
  message: {
    color: '#e53935',
  },

}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  placeholder?: string
  className?: string
  optionList?: Option[]
  noOptionsMessage: string
}

export const GroupSelect = ({
  className,
  placeholder,
  field,
  form,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors } = form

  const convert = (arg: string[]) =>
    arg
      ? arg.map(i => ({
        value: i,
        label: i,
      }))
      : null

  const handleChange = (option: any) => {
    form.setFieldValue(
      name,
      option
        ? option.map((i: any) =>
          i.label.length === undefined ? i.value : i.label
        )
        : null
    )
  }

  return (
    <>
      <CreatableSelect
        name={name}
        value={convert(value)}
        isMulti
        options={optionList}
        placeholder={placeholder}
        styles={selectStyles(Boolean(errors[name]))}
        onChange={handleChange}
        formatCreateLabel={inputValue => `${inputValue}`}
        noOptionsMessage={({ inputValue }) =>
          inputValue === ''
            ? noOptionsMessage
            : `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
        }
        createOptionPosition={'first'}
        {...(customSelectOptions as any)}
      />
      {errors[name] ? (
        <span className={classes.message}>{errors[name]}</span>
      ) : null}
    </>
  )
}
