import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { makeStyles } from '@material-ui/core/styles'
import {
  Tabs,
  Tab,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControl,
} from '@material-ui/core'
import { AddOutlined, Remove } from '@material-ui/icons'
import { magiContants } from 'utils/contants'
import { Area } from 'utils/misc'
import TabPanel from 'sp/componentsHsc/TabPanel'
import Layout from './Layout'
import TabItem from './TabItem'
import { isEmpty, now, stringToDate } from 'common/generalUtil'
import {
  changeReplyAllow,
  changeTransmission,
  clearCandidate,
  inputManipulationSelection,
} from 'reducers/messageSendReducer'
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles({
  interviewDetail: {
    width: '100%',
  },
  subtitle: {
    marginTop: '5px',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '14px',
    '& + table': {
      marginTop: '4px !important',
    },
  },
  // edge10 74710 start
  tabs: {
    '& .MuiTabs-indicator': {
      bottom: 'auto',
      backgroundColor: 'inherit',
    },
    minHeight: 0,
  },
  // edge10 74710 end
  accordionRoot: {
    '&::before': {
      top: 0,
      height: 'inherit',
      position: 'inherit',
      backgroundColor: 'inherit',
    },
    color: 'inherit',
    position: 'inherit',
    backgroundColor: 'inherit',
  },
  accordionRounded: {
    '&:last-child': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    '&:first-child': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },
    borderRadius: 'initial',
  },
  accordionExpanded: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '0px !important',
  },
  accordionExpandedCondition: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '5px !important',
  },
  accordionExpandedList: {
    '&:last-child': {
      marginBottom: '10px !important',
    },
    margin: '10px !important',
  },
  accordionSummaryRoot: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
    marginTop: '5px !important',
    marginBottom: '5px !important',
  },
  accordionSummaryRootList: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
    marginTop: '5px !important',
  },
  accordionSummaryExpandedList: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
  accordionSummaryContent: {
    margin: 'initial',
    display: 'inherit',
    flexGrow: 'initial',
    width: '100%',
  },
  accordionDetailRoot: {
    display: 'initial',
    padding: 0,
  },
  Button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  flagIconPosition: {
    position: 'absolute',
    right: '25px',
  },
  flagIcon: {
    width: '20px',
    height: 'auto',
    color: '#595959',
  },
})

const dateToDateString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('YYYY/MM/DD')
    : null
}

const dateToTimeString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('HH:mm')
    : null
}

const dateToWeekdayString = (value: Date | null) => {
  if (value) {
    const wDay = new Array(7)
    wDay[0] = '日'
    wDay[1] = '月'
    wDay[2] = '火'
    wDay[3] = '水'
    wDay[4] = '木'
    wDay[5] = '金'
    wDay[6] = '土'
    return Number(moment(value).format('d')) > -1
      ? wDay[Number(moment(value).format('d'))]
      : ''
  }
  return ''
}

const setArea = (
  currentDate: moment.Moment,
  startTime: Date,
  endTime: Date,
  actualTime: number
) => {
  const range = moment(endTime).diff(moment(startTime), 'minute')
  if (actualTime <= range) {
    currentDate
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
    const nowDate = moment(now())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
    const day = currentDate.diff(nowDate, 'days')
    if (day > 0) {
      const startDate = dateToTimeString(startTime)
      const endTimeValue = moment(endTime)
        .add(-((actualTime / 30) * 30), 'minute')
        .add(30, 'minute')
        .toDate()
      const endDate = dateToTimeString(endTimeValue)
      let allowedArea: Area = {
        day: day,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      }
      return allowedArea
    }
    return null
  } else {
    return null
  }
}

interface Props {
  selectedInterviewLocationId: string
  candidateListId: number
  updateFormModal: boolean
  selectInterviewLocationId: string
  selectInterviewLocationName: string
  selectInterviewLocationAddress: string
  selectInterviewLocationMapUrl: string
  setUpdateFormModal: (state: boolean) => void
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
  setCandidateListId: (state: number) => void
  setSelectedInterviewLocationId: (state: string) => void
  handleToolTipInterviewDetail: (state: boolean) => void
  handleToolTipCalenderChoose: (state: boolean) => void
  handleToolTipReserved: (state: boolean) => void
  setCurrentReservationList: (state: Reservation[]) => void
  toolTipReserved: boolean
  currentReservationList: Reservation[]
}

interface Reservation {
  name: string
  start: string
  end: string
  location: string
  charge: string
}

const ScheduleAdjustment = ({
  selectedInterviewLocationId = '',
  candidateListId = 0,
  updateFormModal = false,
  selectInterviewLocationId,
  selectInterviewLocationAddress,
  selectInterviewLocationName = '',
  selectInterviewLocationMapUrl = '',
  setUpdateFormModal,
  setSelectInterviewLocationId,
  setSelectInterviewLocationName,
  setSelectInterviewLocationAddress,
  setSelectInterviewLocationMapUrl,
  setCandidateListId,
  setSelectedInterviewLocationId,
  handleToolTipInterviewDetail,
  handleToolTipCalenderChoose,
  handleToolTipReserved,
  setCurrentReservationList,
  toolTipReserved,
  currentReservationList,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [value, setValue] = useState(magiContants.SELECTION_NUMBER_PRESENT)
  const [temporaryValue, setTemporaryValue] = useState(
    magiContants.SELECTION_NUMBER_PRESENT
  )

  const [tabLabel, setTabLabel] = useState([''])
  const [tabId, setTabId] = useState([magiContants.SELECTION_NUMBER_PRESENT])
  const [showInterviewSchedule, setShowInterviewSchedule] = useState(false)
  const [showInterviewCalendar, setShowInterviewCalendar] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [showFix, setShowFix] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const sendTarget = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const jobSeekerInterviewDesiredSchedule = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.jobSeekerInterviewDesiredSchedule
  )
  const jobSeekerInterviewNotPossibleSchedule = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.jobSeekerInterviewNotPossibleSchedule
  )
  const adjustmentInterviewScheduleId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.hiddenItems
        .adjustmentInterviewScheduleId
  )
  const interviewSchedule = useSelector((state: RootState) =>
    state.messageSend.initResult.interviewSchedule
      ? state.messageSend.initResult.interviewSchedule.filter(
        interviewSchedule =>
          interviewSchedule.interviewScheduleDraftFlag === false
      )
      : state.messageSend.initResult.interviewSchedule
  )
  const interviewScheduleAdjustmentAction = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.action
  )
  const interviewScheduleAdjustmentStatus = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.interviewScheduleAdjustmentStatus
  )
  const interviewDetail = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewDetail
  )
  const interviewDurationId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.requiredTimeId
  )
  const interviewDurationSelect = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewDurationSelect
  )
  const companyInterviewSchedule = useSelector(
    (state: RootState) => state.messageSend.initResult.companyInterviewSchedule
  )
  const draftMessageSendId = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.draftMessageSendId
  )
  const messageType = useSelector(
    (state: RootState) => state.messageSend.initResult.messageType
  )
  const [allowedAreaList, setAllowedAreaList] = useState<Area[]>([])
  const [notAllowedAreaList, setNotAllowedAreaList] = useState<Area[]>([])
  const [reservationList, setReservationList] = useState<Reservation[]>([])
  const interviewWebUrlSendFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag
  )

  const [existsDesiredDate, setExistsDesiredDate] = useState(
    sendTarget &&
    sendTarget.length > 0 &&
    sendTarget[0].sentMessageCount <= 0 &&
    isEmpty(interviewScheduleAdjustmentAction) &&
    jobSeekerInterviewDesiredSchedule &&
    jobSeekerInterviewDesiredSchedule.length > 0 &&
    jobSeekerInterviewDesiredSchedule.some(item =>
      moment(item.interviewPreferredDate).isAfter(moment(now(), 'days'))
    )
  )

  useEffect(() => {
    if (
      jobSeekerInterviewDesiredSchedule &&
      jobSeekerInterviewDesiredSchedule.length > 0
    ) {
      let list: Area[] = []
      const currentDuration = interviewDurationSelect.find(
        item => String(item.interviewDurationId) === interviewDurationId
      )
      const actualTime: number =
        currentDuration && currentDuration.actualTime
          ? currentDuration.actualTime
          : magiContants.DURATION_ACTUAL_TIME_MINUTE_OTHER
      let checkedDateList: Date[] = []
      jobSeekerInterviewDesiredSchedule.map(item => {
        const checkDate = item.interviewPreferredDate
        if (
          checkedDateList.every(check => check !== item.interviewPreferredDate)
        ) {
          const currentDateList = jobSeekerInterviewDesiredSchedule.filter(
            value => value.interviewPreferredDate === checkDate
          )
          const currentDate = moment(item.interviewPreferredDate)
          if (currentDateList && currentDateList.length > 0) {
            let startTime = currentDateList[0].startTime
            let endTime = currentDateList[0].endTime
            if (currentDateList.length === 1) {
              const allowedArea: Area | null = setArea(
                currentDate,
                startTime,
                endTime,
                actualTime
              )
              if (allowedArea) {
                list = [...list, allowedArea]
              }
              checkedDateList = [...checkedDateList, checkDate]
            } else {
              currentDateList.map((item, index) => {
                const currentStartTime = moment(item.startTime)
                const currentEndTime = moment(item.endTime)
                if (
                  moment(startTime).diff(currentStartTime, 'minute') !== 0 &&
                  moment(endTime).diff(currentEndTime, 'minute') !== 0
                ) {
                  if (
                    (currentStartTime.isBefore(moment(endTime), 'minute') ||
                      currentStartTime.diff(moment(endTime), 'minute') === 0) &&
                    currentEndTime.isAfter(moment(endTime))
                  ) {
                    endTime = item.endTime
                  } else {
                    const allowedArea: Area | null = setArea(
                      currentDate,
                      startTime,
                      endTime,
                      actualTime
                    )
                    if (allowedArea) {
                      list = [...list, allowedArea]
                    }
                    startTime = item.startTime
                    endTime = item.endTime
                  }
                  if (currentDateList.length === index + 1) {
                    const allowedArea: Area | null = setArea(
                      currentDate,
                      startTime,
                      endTime,
                      actualTime
                    )
                    if (allowedArea) {
                      list = [...list, allowedArea]
                    }
                    checkedDateList = [...checkedDateList, checkDate]
                  }
                }
              })
            }
          }
        }
      })
      setAllowedAreaList(list)
    }
  }, [jobSeekerInterviewDesiredSchedule, interviewDurationId])

  useEffect(() => {
    if (
      jobSeekerInterviewNotPossibleSchedule &&
      jobSeekerInterviewNotPossibleSchedule.length > 0
    ) {
      let list: Area[] = []
      jobSeekerInterviewNotPossibleSchedule.map(item => {
        const nowDate = moment(now())
        const startDate = dateToTimeString(item.startTime)
        const endDate = dateToTimeString(item.endTime)
        const notPossibleDate = moment(item.interviewNotPossibleDate)
        const day = notPossibleDate.diff(nowDate, 'days')
        let notAllowedArea: Area = {
          day: notPossibleDate.isAfter(nowDate, 'days') ? day + 1 : day,
          startDate: startDate ? startDate : '',
          endDate: endDate ? endDate : '',
        }
        list = [...list, notAllowedArea]
      })
      setNotAllowedAreaList(list)
    }
  }, [jobSeekerInterviewNotPossibleSchedule])

  useEffect(() => {
    if (companyInterviewSchedule && companyInterviewSchedule.length > 0) {
      let list: Reservation[] = []
      companyInterviewSchedule.map(item => {
        const name =
          (!isEmpty(item.familyName) ? item.familyName : '') +
          (!isEmpty(item.name) ? item.name : '')
        const start =
          moment(item.interviewStartTime).format('YYYY-MM-DD') +
          'T' +
          moment(item.interviewStartTime).format('HH:mm')
        const end =
          moment(item.interviewEndTime).format('YYYY-MM-DD') +
          'T' +
          moment(item.interviewEndTime).format('HH:mm')
        let reservation: Reservation = {
          name: name,
          start: start ? start : '',
          end: end ? end : '',
          location: item.interviewLocationName,
          charge: item.interviewStaffName,
        }
        list = [...list, reservation]
      })
      setReservationList(list)
    }
  }, [companyInterviewSchedule])

  useEffect(() => {
    let tabIdList: number[] = []
    let tabLabelList: string[] = []
    // 有効な面接希望日程があるか
    setExistsDesiredDate(
      sendTarget &&
      sendTarget.length > 0 &&
      sendTarget[0].sentMessageCount <= 0 &&
      isEmpty(interviewScheduleAdjustmentAction) &&
      jobSeekerInterviewDesiredSchedule &&
      jobSeekerInterviewDesiredSchedule.length > 0 &&
      jobSeekerInterviewDesiredSchedule.some(item =>
        moment(item.interviewPreferredDate).isAfter(moment(now(), 'days'))
      )
    )
    // 有効な面接NG日程があるか
    const existsNotPossibleDate: boolean =
      sendTarget &&
      sendTarget.length > 0 &&
      sendTarget[0].sentMessageCount <= 0 &&
      isEmpty(interviewScheduleAdjustmentAction) &&
      jobSeekerInterviewNotPossibleSchedule &&
      jobSeekerInterviewNotPossibleSchedule.length > 0 &&
      jobSeekerInterviewNotPossibleSchedule.some(item =>
        moment(item.interviewNotPossibleDate).isAfter(moment(now(), 'days'))
      )

    let actionSelect = magiContants.SELECTION_NUMBER_PRESENT


    tabIdList = [...tabIdList, magiContants.SELECTION_NUMBER_PRESENT]
    tabLabelList = [...tabLabelList, '候補日を提示する']


    if (sendTarget.length <= 1) {
      tabIdList = [...tabIdList, magiContants.SELECTION_NUMBER_SEND]
      tabLabelList = [...tabLabelList, '確定日を送る']
    }

    tabIdList = [...tabIdList, magiContants.SELECTION_NUMBER_NOT_PRESENT]
    tabLabelList = [...tabLabelList, '日程調整しない']
    setTabId(tabIdList)
    setTabLabel(tabLabelList)

    if (sendTarget.length > 1) {
      actionSelect = magiContants.SELECTION_NUMBER_PRESENT
      if (draftMessageSendId) {
        if (messageType && messageType.length > 0) {
          actionSelect = manipulationSelection
          if (
            magiContants.MESSAGE_TYPE_INTERVIEW_SCHEDULE ===
            messageType[0].messageType
          ) {
            actionSelect = magiContants.SELECTION_NUMBER_PRESENT
            if (interviewScheduleAdjustmentStatus === 'fixed') {
              actionSelect = magiContants.SELECTION_NUMBER_SEND
            }
          }
        }
      }
    } else {
      let interviewScheduleAdjustmentActionTemp = interviewScheduleAdjustmentAction
      if (draftMessageSendId) {
        interviewScheduleAdjustmentActionTemp = ''
      }
      switch (interviewScheduleAdjustmentActionTemp) {
        case '':
          if (existsDesiredDate || existsNotPossibleDate) {
            if (existsDesiredDate) {
              if (draftMessageSendId) {
                if (messageType && messageType.length > 0) {
                  if (
                    magiContants.MESSAGE_TYPE_INTERVIEW_SCHEDULE ===
                    messageType[0].messageType
                  ) {
                    switch (interviewScheduleAdjustmentStatus) {
                      case 'hope_fixed':
                        actionSelect = magiContants.SELECTION_NUMBER_PRESENT
                        break
                      case 'fixed':
                        actionSelect = magiContants.SELECTION_NUMBER_SEND
                        break
                      default:
                        actionSelect = magiContants.SELECTION_NUMBER_OTHER
                        break
                    }
                  } else {
                    actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
                  }
                }
              } else {
                actionSelect = magiContants.SELECTION_NUMBER_FIX
              }
            }
            if (existsNotPossibleDate) {
              actionSelect = magiContants.SELECTION_NUMBER_PRESENT
            }
          } else {
            actionSelect = magiContants.SELECTION_NUMBER_PRESENT
            if (
              interviewWebUrlSendFlag ===
              magiContants.INTERVIEW_WEB_URL_SEND_FLAG
            ) {
              actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
            }
            if (draftMessageSendId) {
              if (messageType && messageType.length > 0) {
                if (messageType[0].messageType === '03') {
                  actionSelect = magiContants.SELECTION_NUMBER_PRESENT
                  if (interviewScheduleAdjustmentStatus === 'fixed') {
                    actionSelect = magiContants.SELECTION_NUMBER_SEND
                  }
                } else {
                  actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
                }
              }
            }
            if (existsDesiredDate) {
              actionSelect = magiContants.SELECTION_NUMBER_PRESENT
            }
          }
          break
        case magiContants.ACTION_CHANGE_POSSIBLE:
          actionSelect = magiContants.SELECTION_NUMBER_PRESENT
          break
        case magiContants.ACTION_FIX_INTERVIEW:
          actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
        case magiContants.ACTION_CHANGE_INTERVIEW:
          actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
          break
        case magiContants.ACTION_WITHDRAW_INTERVIEW:
          actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
          break
        case magiContants.ACTION_READJUST_INTERVIEW:
          actionSelect = magiContants.SELECTION_NUMBER_PRESENT
          break
      }
    }
    if (
      sendTarget.some(
        item =>
          item.selectionClassification ===
          magiContants.SELECTION_CLASSIFICATION_SEMINAR_APPLICATION ||
          item.selectionClassification ===
          magiContants.SELECTION_CLASSIFICATION_QUESTION ||
          item.selectionClassification ===
          magiContants.SELECTION_CLASSIFICATION_EVENT_SIT_DOWN
      )
    ) {
      actionSelect = magiContants.SELECTION_NUMBER_NOT_PRESENT
    }
    setValue(actionSelect)
    dispatch(inputManipulationSelection(actionSelect))
  }, [sendTarget])

  useEffect(() => {
    switch (interviewScheduleAdjustmentAction) {
      case magiContants.ACTION_READJUST_INTERVIEW:
        if (
          interviewScheduleAdjustmentStatus === 'offer' ||
          interviewScheduleAdjustmentStatus === 'inquest'
        ) {
          setShowInterviewSchedule(true)
        } else {
          setShowInterviewSchedule(false)
        }
        break
      case magiContants.ACTION_FIX_INTERVIEW:
      case magiContants.ACTION_CHANGE_INTERVIEW:
      case magiContants.ACTION_CHANGE_POSSIBLE:
      case magiContants.ACTION_WITHDRAW_INTERVIEW:
        setShowInterviewSchedule(true)
        break
    }

    switch (interviewScheduleAdjustmentAction) {
      case magiContants.ACTION_FIX_INTERVIEW:
      case magiContants.ACTION_CHANGE_INTERVIEW:
        setShowInterviewCalendar(false)
        break
      case magiContants.ACTION_READJUST_INTERVIEW:
      case magiContants.ACTION_CHANGE_POSSIBLE:
      case magiContants.ACTION_WITHDRAW_INTERVIEW:
      case '':
        setShowInterviewCalendar(true)
        break
    }

    switch (interviewScheduleAdjustmentAction) {
      case magiContants.ACTION_FIX_INTERVIEW:
      case magiContants.ACTION_CHANGE_INTERVIEW:
        setShowFix(true)
        break
      case magiContants.ACTION_CHANGE_POSSIBLE:
      case magiContants.ACTION_WITHDRAW_INTERVIEW:
      case magiContants.ACTION_READJUST_INTERVIEW:
        setShowFix(false)
        break
    }
    switch (interviewScheduleAdjustmentAction) {
      case magiContants.ACTION_FIX_INTERVIEW:
        setShowCancel(false)
        break
      case magiContants.ACTION_CHANGE_POSSIBLE:
      case magiContants.ACTION_WITHDRAW_INTERVIEW:
      case magiContants.ACTION_CHANGE_INTERVIEW:
        if (interviewScheduleAdjustmentStatus === 'inquest') {
          setShowCancel(false)
        } else {
          setShowCancel(true)
        }
        break
      case magiContants.ACTION_READJUST_INTERVIEW:
        if (
          interviewScheduleAdjustmentStatus === 'offer' ||
          interviewScheduleAdjustmentStatus === 'inquest'
        ) {
          setShowCancel(true)
        } else {
          setShowCancel(false)
        }
        break
    }
  }, [interviewSchedule])

  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  useEffect(() => {
    if (isInitialized) {
      setValue(manipulationSelection)
      dispatch(inputManipulationSelection(manipulationSelection))
      if (manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
        dispatch(changeReplyAllow({ value: '1' }))
      } else {
        dispatch(clearCandidate())
      }
    }
  }, [manipulationSelection])

  const shouldShowResetScheduleRow = (schedule: any) => {
    const scheduleId: number = schedule.interviewScheduleId
    const scheduleStatus: string = schedule.interviewScheduleStatus
    if (
      scheduleStatus !== 'client_cancel' &&
      scheduleStatus !== 'cancel' &&
      (interviewScheduleAdjustmentAction ===
        magiContants.ACTION_CHANGE_POSSIBLE ||
        (interviewScheduleAdjustmentAction ===
          magiContants.ACTION_WITHDRAW_INTERVIEW &&
          scheduleStatus === 'fixed') ||
        (interviewScheduleAdjustmentAction ===
          magiContants.ACTION_CHANGE_INTERVIEW &&
          ((interviewScheduleAdjustmentStatus === 'fixed' &&
            scheduleStatus === 'fixed') ||
            (interviewScheduleAdjustmentStatus === 'inquest' &&
              !adjustmentInterviewScheduleId.some(
                item => item === scheduleId
              )))) ||
        (interviewScheduleAdjustmentAction ===
          magiContants.ACTION_READJUST_INTERVIEW &&
          (interviewScheduleAdjustmentStatus === 'inquest' ||
            interviewScheduleAdjustmentStatus === 'offer')))
    ) {
      return true
    }
    return false
  }

  const handleClick = (url: string) => {
    window.open(url)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    if (
      newValue === magiContants.SELECTION_NUMBER_PRESENT ||
      newValue === magiContants.SELECTION_NUMBER_SEND
    ) {
      setTemporaryValue(newValue)
    }
    dispatch(inputManipulationSelection(newValue))
    if (newValue !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      dispatch(changeReplyAllow({ value: '1' }))
    } else {
      dispatch(clearCandidate())
    }
    dispatch(changeTransmission({ value: '' }))
  }

  const [scheduleOpen, setScheduleOpen] = React.useState(true)
  const handleScheduleExpandedChange = () => {
    if (value != 0) {
      setScheduleOpen(!scheduleOpen)
    }
  }

  const [webScheduleOpen, setWebScheduleOpen] = React.useState(true)
  const handleWebScheduleExpandedChange = () => {
    setWebScheduleOpen(!webScheduleOpen)
  }

  return (showInterviewSchedule || showInterviewCalendar) &&
    interviewWebUrlSendFlag != magiContants.INTERVIEW_WEB_URL_SEND_FLAG ? (
    <Layout id='schedule-adjustment' icon='scheduleAdjustment'>
      <Accordion
        className='message-schedule-display'
        expanded={scheduleOpen}
        onChange={handleScheduleExpandedChange}>
        <AccordionSummary
          aria-label='Expand'
          aria-controls='schedule-adjustment-content'
          id='schedule-adjustment-id'
          className='message-schedule-display__head'
          style={{ padding: '0px', width: '100%' }}>
          <div
            className='message-schedule-display__head_top'
            style={{ width: '100%' }}>
            <h1>面接日程調整</h1>
            {value == 0 ? (
              <div></div>
            ) : (
              <span className='message-schedule-display__switch'>
                {scheduleOpen ? (
                  <i className='message-schedule-display__switch_none is-accordion-open' />
                ) : (
                  <i className='message-schedule-display__switch_open is-accordion-open' />
                )}
              </span>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={
            scheduleOpen
              ? 'message-schedule-display__body is-accordion-content-open'
              : 'message-schedule-display__body'
          }>
          <div className='message-schedule-display__wrap'>
            <div className='message-schedule-display__inner'>
              {showInterviewCalendar && (
                <div className='message-schedule-radio'>
                  {tabLabel &&
                    tabLabel.map((label, index) => (
                      <label
                        className='form__label_side'
                        key={label}
                        aria-label='Acknowledge'>
                        <Radio
                          color='primary'
                          value={tabId[index]}
                          onClick={e => handleChange(e, tabId[index])}
                          checked={value === tabId[index]}
                          className='form__radio'
                          style={{ padding: 0 }}
                          icon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_off'>
                              <use xlinkHref='#form__radio_off'></use>
                            </svg>
                          }
                          checkedIcon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_on'>
                              <use xlinkHref='#form__radio_on'></use>
                            </svg>
                          }
                        />
                        {value === tabId[index] ? (
                          <span
                            className='form__radio_text'
                            style={{ fontWeight: 'bold' }}>
                            {label}
                          </span>
                        ) : (
                          <span className='form__radio_text'>{label}</span>
                        )}
                      </label>
                    ))}
                </div>
              )}
              {showInterviewSchedule &&
                showCancel &&
                interviewSchedule &&
                interviewSchedule.length > 0 &&
                interviewSchedule.some(item =>
                  shouldShowResetScheduleRow(item)
                ) && (
                  <div
                    className='message-schedule__block'
                    style={{ margin: '0 10px 10px', wordBreak: 'break-all' }}>
                    <div className='message-schedule__ttl'>
                      取り消す面接日程
                    </div>
                    <div className='message-schedule__block_inner'>
                      <p className={classes.subtitle}>
                        {interviewDetail ? `面接内容：${interviewDetail}` : ''}
                      </p>
                      <dl className='message-details-content__list'>
                        {interviewSchedule &&
                          interviewSchedule.length > 0 &&
                          interviewSchedule.map(item => (
                            <>
                              {shouldShowResetScheduleRow(item) && (
                                <>
                                  <dt>面接日時</dt>
                                  <dd>
                                    <em>
                                      {`${dateToDateString(
                                        stringToDate(item.interviewStartTime)
                                      )}（${dateToWeekdayString(
                                        stringToDate(item.interviewStartTime)
                                      )}）${dateToTimeString(
                                        stringToDate(item.interviewStartTime)
                                      )}～`}
                                    </em>
                                    （所要時間：
                                    {interviewDurationSelect &&
                                      interviewDurationSelect.map(
                                        interviewDuration =>
                                          String(
                                            interviewDuration.interviewDurationId
                                          ) === interviewDurationId
                                            ? interviewDuration.displayTime
                                            : ''
                                      )}
                                    ）
                                  </dd>
                                  <dt>
                                    面接場所
                                    {item.mapUrl !== '' && (
                                      <button
                                        title='この面接場所の地図を表示します'
                                        type='button'
                                        className='btn'
                                        style={{
                                          minWidth: 'unset',
                                          minHeight: '25px',
                                          padding: '0 5px',
                                          margin: '0 0 0 5px',
                                        }}
                                        onClick={() =>
                                          handleClick(item.mapUrl)
                                        }>
                                        MAP
                                      </button>
                                    )}
                                  </dt>
                                  <dd>
                                    <span>{`${!isEmpty(item.interviewLocationName)
                                        ? item.interviewLocationName
                                        : ''
                                      }：${!isEmpty(item.interviewLocationAddress)
                                        ? item.interviewLocationAddress
                                        : ''
                                      }`}</span>
                                  </dd>
                                  <dt>担当者</dt>
                                  <dd>{item.interviewStaffName}</dd>
                                </>
                              )}
                            </>
                          ))}
                      </dl>
                    </div>
                  </div>
                )}
              {showInterviewSchedule &&
                showFix &&
                interviewSchedule &&
                interviewSchedule.length > 0 && (
                  <div
                    className='message-schedule__block'
                    style={{ margin: '0 10px 10px', wordBreak: 'break-all' }}>
                    <div className='message-schedule__ttl'>
                      確定する面接日程
                    </div>
                    <div className='message-schedule__block_inner'>
                      <p className={classes.subtitle}>
                        {interviewDetail ? `面接内容：${interviewDetail}` : ''}
                      </p>
                      <dl className='message-details-content__list'>
                        {interviewSchedule &&
                          interviewSchedule.length > 0 &&
                          interviewSchedule.map(item => (
                            <>
                              {item.interviewScheduleId ===
                                adjustmentInterviewScheduleId[0] && (
                                  <>
                                    <dt>面接日時</dt>
                                    <dd>
                                      <em>
                                        {`${dateToDateString(
                                          stringToDate(item.interviewStartTime)
                                        )}（${dateToWeekdayString(
                                          stringToDate(item.interviewStartTime)
                                        )}）${dateToTimeString(
                                          stringToDate(item.interviewStartTime)
                                        )}～`}
                                      </em>
                                      （所要時間：
                                      {interviewDurationSelect &&
                                        interviewDurationSelect.map(
                                          interviewDuration =>
                                            String(
                                              interviewDuration.interviewDurationId
                                            ) === interviewDurationId
                                              ? interviewDuration.displayTime
                                              : ''
                                        )}
                                      ）
                                    </dd>
                                    <dt>
                                      面接場所
                                      {item.mapUrl !== '' && (
                                        <button
                                          title='この面接場所の地図を表示します'
                                          type='button'
                                          className='btn'
                                          style={{
                                            minWidth: 'unset',
                                            minHeight: '25px',
                                            padding: '0 5px',
                                            margin: '0 0 0 5px',
                                          }}
                                          onClick={() =>
                                            handleClick(item.mapUrl)
                                          }>
                                          MAP
                                        </button>
                                      )}
                                    </dt>
                                    <dd>
                                      <span>{`${!isEmpty(item.interviewLocationName)
                                          ? item.interviewLocationName
                                          : ''
                                        }：${!isEmpty(item.interviewLocationAddress)
                                          ? item.interviewLocationAddress
                                          : ''
                                        }`}</span>
                                    </dd>
                                    <dt>担当者</dt>
                                    <dd>{item.interviewStaffName}</dd>
                                  </>
                                )}
                            </>
                          ))}
                      </dl>
                    </div>
                  </div>
                )}
              {showInterviewCalendar && (
                <div
                  className={
                    value === 0
                      ? 'message-schedule-contents__body'
                      : 'message-schedule-contents__body is-contents-open'
                  }>
                  <div className='message-schedule-contents__wrap'>
                    <div className='message-schedule-contents__inner'>
                      <>
                        <TabPanel
                          value={value}
                          index={magiContants.SELECTION_NUMBER_PRESENT}>
                          <TabItem
                            allowedArea={allowedAreaList}
                            notAllowedArea={notAllowedAreaList}
                            reservationList={reservationList}
                            setIsInitialized={setIsInitialized}
                            isInitialized={isInitialized}
                            isPermitOther={true}
                            selectedInterviewLocationId={
                              selectedInterviewLocationId
                            }
                            candidateListId={candidateListId}
                            updateFormModal={updateFormModal}
                            setUpdateFormModal={setUpdateFormModal}
                            setSelectInterviewLocationId={
                              setSelectInterviewLocationId
                            }
                            setSelectInterviewLocationName={
                              setSelectInterviewLocationName
                            }
                            setSelectInterviewLocationAddress={
                              setSelectInterviewLocationAddress
                            }
                            setSelectInterviewLocationMapUrl={
                              setSelectInterviewLocationMapUrl
                            }
                            selectInterviewLocationId={
                              selectInterviewLocationId
                            }
                            selectInterviewLocationAddress={
                              selectInterviewLocationAddress
                            }
                            selectInterviewLocationName={
                              selectInterviewLocationName
                            }
                            selectInterviewLocationMapUrl={
                              selectInterviewLocationMapUrl
                            }
                            setCandidateListId={setCandidateListId}
                            setSelectedInterviewLocationId={
                              setSelectedInterviewLocationId
                            }
                            sendTarget={sendTarget}
                            existsDesiredDate={existsDesiredDate}
                            handleToolTipInterviewDetail={
                              handleToolTipInterviewDetail
                            }
                            handleToolTipCalenderChoose={
                              handleToolTipCalenderChoose
                            }                            
                            handleToolTipReserved={
                              handleToolTipReserved
                            }
                            setCurrentReservationList={setCurrentReservationList}
                            currentReservationList={currentReservationList}
                            toolTipReserved={toolTipReserved}
                            value={value}
                            setValue={setValue}
                            temporaryValue={temporaryValue}
                          />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={magiContants.SELECTION_NUMBER_FIX}>
                          <TabItem
                            allowedArea={allowedAreaList}
                            notAllowedArea={notAllowedAreaList}
                            reservationList={reservationList}
                            setIsInitialized={setIsInitialized}
                            isInitialized={isInitialized}
                            isPermitOther={false}
                            selectedInterviewLocationId={
                              selectedInterviewLocationId
                            }
                            candidateListId={candidateListId}
                            updateFormModal={updateFormModal}
                            setUpdateFormModal={setUpdateFormModal}
                            setSelectInterviewLocationId={
                              setSelectInterviewLocationId
                            }
                            setSelectInterviewLocationName={
                              setSelectInterviewLocationName
                            }
                            setSelectInterviewLocationAddress={
                              setSelectInterviewLocationAddress
                            }
                            setSelectInterviewLocationMapUrl={
                              setSelectInterviewLocationMapUrl
                            }
                            selectInterviewLocationId={
                              selectInterviewLocationId
                            }
                            selectInterviewLocationAddress={
                              selectInterviewLocationAddress
                            }
                            selectInterviewLocationName={
                              selectInterviewLocationName
                            }
                            selectInterviewLocationMapUrl={
                              selectInterviewLocationMapUrl
                            }
                            setCandidateListId={setCandidateListId}
                            setSelectedInterviewLocationId={
                              setSelectedInterviewLocationId
                            }
                            sendTarget={sendTarget}
                            existsDesiredDate={existsDesiredDate}
                            handleToolTipInterviewDetail={
                              handleToolTipInterviewDetail
                            }
                            handleToolTipCalenderChoose={
                              handleToolTipCalenderChoose
                            }
                            handleToolTipReserved={
                              handleToolTipReserved
                            }
                            setCurrentReservationList={setCurrentReservationList}
                            currentReservationList={currentReservationList}
                            toolTipReserved={toolTipReserved}
                            value={value}
                            setValue={setValue}
                            temporaryValue={temporaryValue}
                          />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={magiContants.SELECTION_NUMBER_OTHER}>
                          <TabItem
                            allowedArea={allowedAreaList}
                            notAllowedArea={notAllowedAreaList}
                            reservationList={reservationList}
                            setIsInitialized={setIsInitialized}
                            isInitialized={isInitialized}
                            isPermitOther={true}
                            selectedInterviewLocationId={
                              selectedInterviewLocationId
                            }
                            candidateListId={candidateListId}
                            updateFormModal={updateFormModal}
                            setUpdateFormModal={setUpdateFormModal}
                            setSelectInterviewLocationId={
                              setSelectInterviewLocationId
                            }
                            setSelectInterviewLocationName={
                              setSelectInterviewLocationName
                            }
                            setSelectInterviewLocationAddress={
                              setSelectInterviewLocationAddress
                            }
                            setSelectInterviewLocationMapUrl={
                              setSelectInterviewLocationMapUrl
                            }
                            selectInterviewLocationId={
                              selectInterviewLocationId
                            }
                            selectInterviewLocationAddress={
                              selectInterviewLocationAddress
                            }
                            selectInterviewLocationName={
                              selectInterviewLocationName
                            }
                            selectInterviewLocationMapUrl={
                              selectInterviewLocationMapUrl
                            }
                            setCandidateListId={setCandidateListId}
                            setSelectedInterviewLocationId={
                              setSelectedInterviewLocationId
                            }
                            sendTarget={sendTarget}
                            existsDesiredDate={existsDesiredDate}
                            handleToolTipInterviewDetail={
                              handleToolTipInterviewDetail
                            }
                            handleToolTipCalenderChoose={
                              handleToolTipCalenderChoose
                            }
                            handleToolTipReserved={
                              handleToolTipReserved
                            }
                            setCurrentReservationList={setCurrentReservationList}
                            currentReservationList={currentReservationList}
                            toolTipReserved={toolTipReserved}
                            value={value}
                            setValue={setValue}
                            temporaryValue={temporaryValue}
                          />
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={magiContants.SELECTION_NUMBER_SEND}>
                          <TabItem
                            reservationList={reservationList}
                            isPermitOther={true}
                            setIsInitialized={setIsInitialized}
                            isInitialized={isInitialized}
                            selectedInterviewLocationId={
                              selectedInterviewLocationId
                            }
                            candidateListId={candidateListId}
                            updateFormModal={updateFormModal}
                            setUpdateFormModal={setUpdateFormModal}
                            setSelectInterviewLocationId={
                              setSelectInterviewLocationId
                            }
                            setSelectInterviewLocationName={
                              setSelectInterviewLocationName
                            }
                            setSelectInterviewLocationAddress={
                              setSelectInterviewLocationAddress
                            }
                            setSelectInterviewLocationMapUrl={
                              setSelectInterviewLocationMapUrl
                            }
                            selectInterviewLocationId={
                              selectInterviewLocationId
                            }
                            selectInterviewLocationAddress={
                              selectInterviewLocationAddress
                            }
                            selectInterviewLocationName={
                              selectInterviewLocationName
                            }
                            selectInterviewLocationMapUrl={
                              selectInterviewLocationMapUrl
                            }
                            setCandidateListId={setCandidateListId}
                            setSelectedInterviewLocationId={
                              setSelectedInterviewLocationId
                            }
                            sendTarget={sendTarget}
                            existsDesiredDate={existsDesiredDate}
                            handleToolTipInterviewDetail={
                              handleToolTipInterviewDetail
                            }
                            handleToolTipCalenderChoose={
                              handleToolTipCalenderChoose
                            }
                            handleToolTipReserved={
                              handleToolTipReserved
                            }
                            setCurrentReservationList={setCurrentReservationList}
                            currentReservationList={currentReservationList}
                            toolTipReserved={toolTipReserved}
                            value={value}
                            setValue={setValue}
                            temporaryValue={temporaryValue}
                          />
                        </TabPanel>
                      </>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Layout>
  ) : interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG ? (
    <Layout id='interview-web-time' icon='scheduleAdjustment'>
      <Accordion
        className='message-schedule-display'
        expanded={webScheduleOpen}
        onChange={handleWebScheduleExpandedChange}>
        <AccordionSummary
          aria-label='Expand'
          aria-controls='schedule-adjustment-content'
          id='web-schedule-adjustment-id'
          className='message-schedule-display__head'
          style={{ padding: '0px', width: '100%' }}>
          <div
            className='message-schedule-display__head_top'
            style={{ width: '100%' }}>
            <h1>面接日程調整</h1>
            <span className='message-schedule-display__switch'>
              {webScheduleOpen ? (
                <i className='message-schedule-display__switch_none is-accordion-open' />
              ) : (
                <i className='message-schedule-display__switch_open is-accordion-open' />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={
            'message-schedule-display__body is-accordion-content-open'
          }>
          <div
            className='message-schedule__block'
            style={{ margin: '10px 10px 10px', wordBreak: 'break-all' }}
            id='interview-web-time'>
            <div
              className='message-schedule__ttl'
              aria-label='Expand'
              aria-controls='schedule-adjustment-content'
              id='web-schedule-adjustment-id'>
              WEB面接日時
            </div>
            <div className='message-schedule__block_inner'>
              <h2>WEB面接の日程を確認します。</h2>
              <p className={classes.subtitle}>
                {interviewDetail ? `面接内容：${interviewDetail}` : ''}
              </p>
              <dl className='message-details-content__list'>
                {interviewSchedule &&
                  interviewSchedule.length > 0 &&
                  interviewSchedule.map(item => (
                    <>
                      <dt>面接日時</dt>
                      <dd>
                        <em>
                          {`${dateToDateString(
                            stringToDate(item.interviewStartTime)
                          )}（${dateToWeekdayString(
                            stringToDate(item.interviewStartTime)
                          )}）${dateToTimeString(
                            stringToDate(item.interviewStartTime)
                          )}～`}
                        </em>
                        （所要時間：
                        {interviewDurationSelect &&
                          interviewDurationSelect.map(interviewDuration =>
                            String(interviewDuration.interviewDurationId) ===
                              interviewDurationId
                              ? interviewDuration.displayTime
                              : ''
                          )}
                        ）
                      </dd>
                      <dt>
                        面接場所
                        {item.mapUrl !== '' && (
                          <button
                            title='この面接場所の地図を表示します'
                            type='button'
                            className='btn'
                            style={{
                              minWidth: 'unset',
                              minHeight: '25px',
                              padding: '0 5px',
                              margin: '0 0 0 5px',
                            }}
                            onClick={() => handleClick(item.mapUrl)}>
                            MAP
                          </button>
                        )}
                      </dt>
                      <dd>
                        <span>{`${!isEmpty(item.interviewLocationName)
                            ? item.interviewLocationName
                            : ''
                          }：${!isEmpty(item.interviewLocationAddress)
                            ? item.interviewLocationAddress
                            : ''
                          }`}</span>
                      </dd>
                      <dt>担当者</dt>
                      <dd>{item.interviewStaffName}</dd>
                    </>
                  ))}
              </dl>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Layout>
  ) : null
}

export default React.memo(ScheduleAdjustment)
