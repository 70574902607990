import { useMemo, useCallback, useRef, useEffect } from 'react'
import React from 'react'

type Props = {
  isOpen?: boolean
  children: React.ReactNode | React.ReactNodeArray
  className?: string
  onClose?: Function
  isMinimum?: boolean
  isMessageInput?: boolean
}

export const ModalDialog: React.FC<Props> = ({
  isOpen = false,
  children,
  className,
  onClose,
  isMinimum,
  isMessageInput = false
}: Props): React.ReactElement => {
  const dialogRef = useRef<HTMLDivElement>(null)
  const [active, setActive] = React.useState(false)
  const [active2, setActive2] = React.useState(false)
  useEffect((): void => {
    const dialogElement = dialogRef.current
    if (!dialogElement) {
      return
    }
    if (isOpen) {
      if (dialogElement.hasAttribute('open')) {
        return
      }
      setActive2(true)
      dialogElement.setAttribute('open', 'true')
      setActive(true)

    } else {
      if (!dialogElement.hasAttribute('open')) {
        return
      }
      setActive(false)
      dialogElement.addEventListener(
        'transitionend',
        () => {
          dialogElement.removeAttribute('open')
          setActive2(false)
        },
        { once: true }
      )

      if (onClose) {
        onClose()
      }

    }
  }, [isOpen])

  useEffect((): void => {
    if (active) {

      requestAnimationFrame(async () => {
        // モーダル表示後にアニメーションを開始
        if (dialogRef.current) {
          dialogRef.current.classList.add(`is-${className}-show`)
        }
      })
    } else {

      if (dialogRef.current) {
        dialogRef.current.classList.remove(`is-${className}-show`)
      }
    }
  }, [active])

  useEffect((): void => {
    if (!isOpen) {
      return
    }
    // 最小化制御
    if (isMinimum) {
      if (dialogRef.current) {
        dialogRef.current.classList.add(`is-${className}-min`)

        const stickyElmList = document.querySelectorAll<HTMLDivElement>('.sticky-footer-block')
        if (stickyElmList) {
          stickyElmList.forEach(stickyElm => {
            stickyElm.style.bottom = '50px'
          })
        }
      }
    } else {
      if (dialogRef.current) {
        dialogRef.current.classList.remove(`is-${className}-min`)
        if (isMessageInput) {
          const stickyElmList = document.querySelectorAll<HTMLDivElement>('.sticky-footer-block')
          if (stickyElmList) {
            stickyElmList.forEach(stickyElm => {
              stickyElm.style.bottom = '0px';
            })
          }

        }
      }
    }
  }, [isOpen, isMinimum, isMessageInput])

  const handleClickContent = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      // clickイベントの伝搬を止める。
      event.stopPropagation()
    },
    []
  )

  const dialogDynamicStyle = useMemo(() => {
    if (active2) {
      return
    }
    return { display: 'none' }
  }, [active2])

  return (
    <div
      className={`${className}`}
      ref={dialogRef}
      style={dialogDynamicStyle}
      onClick={handleClickContent}>
      {children}
    </div>
  )
}
