import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { validationMessageSet } from 'utils/contants'
import { Grid } from '@material-ui/core'
import { Title } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  errorSelectBorder: {
    border: '1px solid #e53935',
    '&:hover': {
      border: '1px solid #e53935',
    }
  },
  helperText: {
    fontSize: '11px',
    marginTop: '4px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    whiteSpace: 'pre-wrap',
  },
  selectWithCounter: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1),
  },
  fontWhite: {
    color: '#FFF',
  },
  title: {
    minWidth: 185,
  },
  backgroundColor: {
    backgroundColor: '#3f71bd',
  },
  // 次期開発12月 #51829 start
  grid: {
    width: '90%'
  },
  backgroundColorDeleted: {
    width: '90%',
    backgroundColor: '#3f71bd',
  },
  // 次期開発12月 #51829 end
}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
  title: string
  item: string
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
  // 次期開発12月 #51829 end
}

const ActionSetSelect = ({
  className,
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
  title,
  item,
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
  // 次期開発12月 #51829 end
}: Props) => {
  const classes = useStyles()
  const { name, value } = field

  const [error, setError] = useState({ isError: false, errorMsg: '' })
  // 次期開発12月 #51829 start
  const [errorChecked, setCheckedError] = useState({ isError: false, errorMsg: '' })
  // 次期開発12月 #51829 end

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, '')
    }
  }, [defaultValue])

  // 次期開発12月 #51829 start
  useEffect(() => {
    if ((prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && selectDoubleFalg == true) {
      if (clearRecruitmentManageFlag) {
        setError({ isError: false, errorMsg: '' })
      } else {
        setError(errorChecked)
      }
    }
  },[clearRecruitmentManageFlag])
  // 次期開発12月 #51829 end

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    form.setFieldValue(name, e.target.value)
    if(e.target.value.length === 0) {
      setError({errorMsg: error.errorMsg, isError: true})
      // 次期開発12月 #51829 start
      setCheckedError({errorMsg: error.errorMsg, isError: true})
      // 次期開発12月 #51829 end
    } else {
      setError({errorMsg: error.errorMsg, isError: false})
      // 次期開発12月 #51829 start
      setCheckedError({errorMsg: error.errorMsg, isError: false})
      // 次期開発12月 #51829 end
    }
  }

  return (
    // 次期開発12月 #51829 start
    <Grid container className={prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030' ? (selectDoubleFalg == false ? (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor) : (null != item && null != value && item.toString() === value.toString() && clearRecruitmentManageFlag == false ? classes.grid : classes.backgroundColorDeleted)) : (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor)}>
    {/* 次期開発12月 #51829 end */}
      <Grid item xs={12} className={classes.selectWithCounter}>
        <Grid item xs={3}>
          <Title
            title={title}
            className={`${classes.title} ${classes.fontWhite}`}
          />
        </Grid>
        <Title title='：' className={classes.fontWhite} />
        <Grid item xs={9}>
          <select
            // 次期開発12月 #51829 start
            disabled={clearRecruitmentManageFlag ? true :disabled}
            // 次期開発12月 #51829 end
            className={`${classes.root} ${error.isError ? classes.errorSelectBorder : validationMessageSet.required()}`}
            // 次期開発12月 #51829 start
            style={clearRecruitmentManageFlag ? {backgroundColor:'lightGrey',opacity:1,color:'black'} : {}}
            value={clearRecruitmentManageFlag ? {label:'項目のデータを一括削除します'} :value}
            // 次期開発12月 #51829 end
            onChange={handleChange}>
            {placeholder ? <option value=''>{placeholder}</option> : null}
            {optionList.map(i => (
              <option key={i.value} value={i.value}>
                {i.label}
              </option>
            ))}
          </select>
          {error.isError ? (
            <p className={classes.helperText}>{error.errorMsg}</p>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ActionSetSelect
