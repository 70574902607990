import React from 'react';
import Button from '../Button';

interface Props {
  type: string;
  source: string;
}

export default ({ type, source }: Props) => {
  let editOptionList = [],
    operationList = [];

  if (source === 'selection') {
    if (type === 'startJob') {
      editOptionList = [
        [
          { title: '採用管理フラグの入力', type: 'evaluation_edit' },
          { title: '対象外にする', type: 'excluded' },
        ],
        [
          { title: 'コメントを書く', type: 'edit' },
          { title: '選考フローの追加', type: 'recruitmentNotice' },
          { title: '選考フローの変更', type: 'recruitmentChange' },
        ],
        [
          { title: '評価の入力', type: 'evaluation' },
          { title: '応募経路の追加', type: 'addMediaflag' },
          { title: '応募経路の変更', type: 'changeMediaflag' },
        ],
        [{ title: 'タグの登録', type: 'tag' }],
      ];
      operationList = [
        [
          { title: 'CSV出力', type: 'csvOutput' },
          { title: 'MCB提出依頼', type: 'MCB' },
        ],
        [
          { title: 'PDF出力', type: 'pdfOutput' },
          { title: '適性テストID登録', type: 'properTest' },
        ],
        [
          { title: 'メッセージ送信', type: 'message' },
          { title: 'MCBダウンロード', type: 'MCB_dl' },
        ],
        [{ title: '関係者にシェア', type: 'share' }],
      ];
    } else {
      editOptionList = [
        [
          { title: '採用管理フラグの入力', type: 'evaluation_edit' },
          { title: '対象外にする', type: 'excluded' },
        ],
        [
          { title: 'コメントを書く', type: 'edit' },
          { title: '選考フローの変更', type: 'recruitmentChange' },
        ],
        [
          { title: '評価の入力', type: 'evaluation' },
          { title: '応募経路の変更', type: 'changeMediaflag' },
        ],
        [{ title: 'タグの登録', type: 'tag' }],
      ];
      operationList = [
        [{ title: 'CSV出力', type: 'csvOutput' }],
        [{ title: 'PDF出力', type: 'pdfOutput' }],
        [{ title: 'メッセージ送信', type: 'message' }],
        [{ title: '関係者にシェア', type: 'share' }],
      ];
    }
  } else if (source === 'prospective') {
    editOptionList = [
      [{ title: '採用管理フラグの入力', type: 'evaluation_edit' }],
      [{ title: 'コメントを書く', type: 'edit' }],
      [{ title: '評価の入力', type: 'evaluation' }],
      [{ title: 'タグの登録', type: 'tag' }],
    ];
    if (type === 'startJob') {
      operationList = [
        [
          { title: 'CSV出力', type: 'csvOutput' },
          { title: 'MCB提出依頼', type: 'MCB' },
        ],
        [
          { title: 'PDF出力', type: 'pdfOutput' },
          { title: '適性テストID登録', type: 'properTest' },
        ],
        [
          { title: 'メッセージ送信', type: 'message' },
          { title: 'MCBダウンロード', type: 'MCB_dl' },
        ],
        [
          { title: '関係者にシェア', type: 'share' },
          { title: '内定書PDF出力', type: 'prospectivePDF' },
        ],
      ];
    } else {
      operationList = [
        [{ title: 'CSV出力', type: 'csvOutput' }],
        [{ title: 'PDF出力', type: 'pdfOutput' }],
        [{ title: 'メッセージ送信', type: 'message' }],
        [
          { title: '関係者にシェア', type: 'share' },
          { title: '内定書PDF出力', type: 'prospectivePDF' },
        ],
      ];
    }
  } else {
    editOptionList = [
      [
        { title: '採用管理フラグの入力', type: 'evaluation_edit' },
        { title: '対象外から戻す', type: 'include' },
      ],
      [{ title: 'コメントを書く', type: 'edit' }],
      [{ title: '評価の入力', type: 'evaluation' }],
      [
        { title: 'タグの登録', type: 'tag' },
        { title: '削除する', type: 'delete' },
      ],
    ];
    operationList = [
      [{ title: 'CSV出力', type: 'csvOutput' }],
      [{ title: 'PDF出力', type: 'pdfOutput' }],
      [{ title: 'メッセージ送信', type: 'message' }],
    ];
  }

  return (
    <div className="bln otherOperation-buttons show">
      <b>応募者データの編集</b>
      <div>
        {editOptionList.map(optionGroup => (
          <span>
            {optionGroup.map(i => (
              <Button title={i.title} type={i.type} />
            ))}
          </span>
        ))}
      </div>
      <b>その他の操作</b>
      <div>
        {operationList.map(optionGroup => (
          <span>
            {optionGroup.map(i => (
              <Button title={i.title} type={i.type} />
            ))}
          </span>
        ))}
      </div>
    </div>
  );
};
