import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import App from 'App'
import AppProvider from 'providers'
import '@openfonts/noto-sans-jp_japanese'

const RootPc = () => (
  <AppProvider>
    <App />
  </AppProvider>
);
export default RootPc;