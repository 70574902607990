import React, { useEffect, useState } from 'react'
import { RootState } from 'reducers'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty, dateTimeToString } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
    display: 'inline',
  },
  oblique: {
    fontStyle: 'oblique',
    display: 'inline',
  },
  underline: {
    textDecoration: 'underline',
    display: 'inline',
  },
  red: {
    color: 'red',
    display: 'inline',
  },
  orange: {
    color: 'orange',
    display: 'inline',
  },
  yellowgreen: {
    color: 'yellowgreen',
    display: 'inline',
  },
  lime: {
    color: 'lime',
    display: 'inline',
  },
  cyan: {
    color: 'cyan',
    display: 'inline',
  },
  blue: {
    color: 'blue',
    display: 'inline',
  },
  magenta: {
    color: 'magenta',
    display: 'inline',
  },
  notifyMailAddressDiv: {
    marginTop: '-22px',
  },
  link: {
    color: '#3380CC',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  sender: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  messagePreview: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  notify: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  notifylabel: {
    width: '168px',
  },
  notifylabel2: {
    float: 'right',
  },
}))

interface Props {
  recruitmentManagementDivision: string
  destinationDivision: string
  sendValue: viewConfig.MessageInput
  sendTimeValue: viewConfig.SendTimeInput
  notifyMailAddress: string
  notifyNotification: number

  sendToNonMember: number

  replyMailAddress: string
}

const MessageInput = ({
  recruitmentManagementDivision,
  destinationDivision,
  sendValue,
  sendTimeValue,
  notifyMailAddress,
  notifyNotification,
  replyMailAddress,
}: Props) => {
  const classes = useStyles()
  const [subjectState, setSubjectState] = useState('')
  const [bodyState, setBodyState] = useState('')
  const subjectVal = useSelector(
    (state: RootState) => state.messageSendConfirm.subject
  )
  const bodyVal = useSelector(
    (state: RootState) => state.messageSendConfirm.body
  )
  useEffect(() => {
    setSubjectState(subjectVal)
    // 送信先が「マイナビ優先」「e-mailのみ」の場合、本文に固定文言を追加
    if (
      destinationDivision ===
      magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED ||
      destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY
    ) {
      setBodyState(
        bodyVal +
        '<br /><br />※このメールはマイナビが提供する応募者管理システムを利用して送信されています※'
      )
    } else {
      setBodyState(bodyVal)
    }
  }, [sendValue, subjectVal, bodyVal])

  const openUrl = (valuses: string) => {
    window.open(valuses)
  }

  return (
    <section className='message-block'>
      <div className='form__ttl_modal_3'>メッセージ</div>
      {(destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) && (
        <div className='mb10'>送信元会社名：{sendValue.senderCompanyName}</div>
      )}
      {destinationDivision !==
        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
          <>
            <div className='mb10'>送信元メールアドレス：{replyMailAddress}</div>
            <div className='mb10'>
              返信先メールアドレス：{sendValue.senderMailAddress}
            </div>
          </>
        )}
      <div className='message-input__ttl'>件名</div>
      <div className='message-target-list__wrap mb10'>
        <div
          className='message-target-list__inner'
          dangerouslySetInnerHTML={{ __html: subjectState }}
        />
      </div>

      <div className='message-input__ttl'>本文</div>
      <div className='message-target-list__wrap mb10'>
        <div
          className='message-target-list__inner'
          dangerouslySetInnerHTML={{ __html: bodyState }}
        />
      </div>

      {destinationDivision ===
        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
          <>
            {sendValue.attachment &&
              sendValue.attachment.length > 0 &&
              sendValue.attachment.some(
                item => !isEmpty(item.attachmentFileName)
              ) ? (
                <>
                  {sendValue.attachment.map((item, index) => (
                    <>
                      {!isEmpty(item.attachmentFileName) && (
                        <div className='mb10'>
                          {sendValue.attachment.findIndex(
                            item => !isEmpty(item.attachmentFileName)
                          ) === index
                            ? '添付ファイル：'
                            : ''}
                          {item.attachmentFileName}
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <div className='mb10'>添付ファイル：なし</div>
              )}
          </>
        )}

      {destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
        <>
          {!sendValue.homePage ||
            sendValue.homePage.length <= 0 ||
            !sendValue.homePage.some(item => !isEmpty(item.homePageUrl)) ? (
              <div className='mb10'>ホームページURL：なし</div>
            ) : (
              <>
                {sendValue.homePage.map((item, index) => (
                  <>
                    {!isEmpty(item.homePageUrl) && (
                      <div className='mb10'>
                        {sendValue.homePage.findIndex(
                          item => !isEmpty(item.homePageUrl)
                        ) === index
                          ? 'ホームページURL：'
                          : ''}
                        {!isEmpty(item.homePageTitle) && (
                          <>
                            <a
                              className={classes.link}
                              onClick={() => openUrl(item.homePageUrl)}>
                              {item.homePageTitle}
                            </a>
                            &nbsp;
                        </>
                        )}
                        {item.homePageUrl}
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
        </>
      )}

      <div className='mb10'>
        送信日時：
        {sendTimeValue.transmission === '0' && <>すぐに送信する</>}
        {sendTimeValue.transmission === '1' && (
          <>
            タイマー送信する
            {!isEmpty(dateTimeToString(sendTimeValue.sendPlanTime)) && (
              <span>（{dateTimeToString(sendTimeValue.sendPlanTime)}）</span>
            )}
          </>
        )}
      </div>

      {recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        destinationDivision ===
        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
          <div className='mb10'>
            返信時にお知らせを希望する：
            {String(notifyNotification) ===
              magiContants.NOTIFICATION_NOT_DESIRED ||
              isEmpty(notifyMailAddress)
              ? '希望しない'
              : '希望する（' + notifyMailAddress + '）'}
          </div>
        )}
    </section>
  )
}

export default MessageInput
