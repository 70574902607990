import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import React from 'react'

type ConfirmDialogResult = 'confirm' | 'cancel'

export type ConfirmDialogProps = {
  onClose: (result: ConfirmDialogResult) => void
  message: string
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { onClose, message } = props
  return (
    <Dialog
      open={true}
      onClose={() => onClose('cancel')}
      className={''}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose('cancel')}>キャンセル</Button>
        <Button onClick={() => onClose('confirm')}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
