import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { ThemeProvider as MuiProvider, makeStyles } from '@material-ui/styles'

import theme from 'sp/theme'
import { Loading } from 'sp/components'

const useStyles = makeStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
    '.loadingOverlay': {
      display: 'none',
    },
    '.loading-indicator .loadingOverlay': {
      display: 'block',
    },
  },
})

const ThemeProvider: React.FC = ({ children }) => {
  useStyles()
  const entryListSearchCount = useSelector(
    (state: RootState) => state.entryList.searchCount
  )
  const applicantListSearchCount = useSelector(
    (state: RootState) => state.applicantList.searchCount
  )
  // 年度対応 start
  const openLoadingFlag = useSelector(
    (state: RootState) => state.recruitmentStatusReport.openLoadingFlag
  )
  // 年度対応 end
  //MCBリプレース MCBHS040 START
  const submissionRequestListSearchCount = useSelector(
    (state: RootState) => state.submissionRequestList.searchCount
  )
  //MCBリプレース MCBHS040 END
  return (
    <MuiProvider theme={theme}>
      {/* 年度対応 start MCBリプレース MCBHS040 START */}
      {entryListSearchCount === 2 || applicantListSearchCount === 2 || submissionRequestListSearchCount === 2 || !openLoadingFlag ? null : (
        <Loading className='loadingOverlay' />
      )}
      {/* 年度対応 end MCBリプレース MCBHS040 END */}
      {children}
    </MuiProvider>
  )
}

export default ThemeProvider
