import React from 'react'

interface Props {
  checked: boolean
  onChange: (query: object) => void
}

const CheckBoxCoulumn: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <>
      <label onClick={onChange}>
        <input
          type='checkbox'
          className='form__checkbox'
          checked={checked}
          onClick={event => event.stopPropagation()}
          onChange={onChange}
        />
        <mark onClick={event => event.stopPropagation()}></mark>
      </label>
    </>
  )
}

export default CheckBoxCoulumn
