import React, { useState } from 'react'
import {
  Button,
  Dialog,
  makeStyles,
} from '@material-ui/core'
import {
  TextField, LoadingDialog, ModalDialog,
} from 'sp/components'
import { Formik, Form, FastField, Field } from 'formik'
import {
  setDialogOpen,
  createsearchCriteriaName,
  updatesearchCriteria,
  checkActionInfo,
  setCheckDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import Toggle from './Toggle'
import {
  selectRadioList,
  initialValues,
  textMap,
  buttonTextMap,
} from 'pages/MCARS030/formConfig'
import { magiContants, validationMessageSet } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { openModal } from 'reducers/messageReducer'
import { onKeyDown } from 'common/generalUtil'

const useStyles = makeStyles(theme => ({
  divHidden: {
    display: 'none',
  },
  cancelButton: {
    color: '#000',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  confirmButton: {
    color: '#FFF',
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#3380cc',
    },
  },
}))

interface Props {
  escsId: string
  scName: string
  sCriteria: any
}
const MCARM030 = ({ escsId, scName, sCriteria }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('OK')
  const [initialValuesForm, setInitialValuesForm] = useState(initialValues)
  const [optionVlue, setOptionVlue] = useState('')
  const [getSearchInsertName, setSearchInsertName] = useState('')
  const [getInternalId, setInternalId] = useState('')
  const [getSearchName, setSearchName] = useState('')
  const [getVersionNumber, setVersionNumber] = useState<any>(null)
  const [getVersionNumber1, setVersionNumber1] = useState<any>(null)
  const [verNumber, setVerNumber] = useState('')
  const [verNumber1, setVerNumber1] = useState('')
  const [searchCriteria, setSearchCriteria] = useState<any>(null)

  const option = useSelector(
    (state: RootState) => state.selectCriteriaManagement.selectDate
  )
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const checkDialogOpen = useSelector(
    (state: RootState) => state.selectCriteriaManagement.checkDialogOpen
  )
  const checkMsg = useSelector(
    (state: RootState) => state.selectCriteriaManagement.checkMsg
  )

  const globalMenu = useSelector((state: RootState) => state.globalMenu)

  // 「キャンセルする」ボタン押下
  const handleCancel = () => {
    setType('cancel')
    setOpen(true)
  }

  const handleMsgDialogClose = () => {
    setOpen(false)
    dispatch(setCheckDialogOpen(false))
  }
  const [error, setError] = useState({ isError: false, message: '' })
  const [error2, setError2] = useState({ isError: false, message: '' })
  const handleSubmit = (values: any) => {
    if (error.isError || error2.isError) {
      return
    }
    setType('OK')
    setOptionVlue(values.selectRadio)
    setSearchInsertName(values.searchInsertName)
    setSearchCriteria(sCriteria)
    setInternalId(values.internalId)
    setSearchName(values.nameCheck)
    if (verNumber === '' || values.sysVersionNumber != 0) {
      setVersionNumber(values.sysVersionNumber)
      setVersionNumber1(values.sysVersionNumber1)
    } else {
      setVersionNumber(verNumber)
      setVersionNumber1(verNumber1)
    }

    if (values.selectRadio === '1') {
      if (values.searchInsertName.trim() === '') {
        dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCARS030_004)))
      } else {
        if (escsId !== null && escsId !== '') {
          dispatch(
            checkActionInfo({
              internalId: escsId,
              searchName: values.searchInsertName,
              sysVersionNumber: 0,
              sysVersionNumber1: 0,
              searchCriteria: sCriteria,
            })
          )
        } else {
          setOpen(true)
        }
      }
    } else if (values.selectRadio === '0') {
      if (values.nameCheck === '') {
        dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCARS030_003)))
      } else {
        dispatch(
          checkActionInfo({
            internalId: values.internalId,
            searchName: '',
            sysVersionNumber: 0,
            sysVersionNumber1: 0,
            searchCriteria: sCriteria,
          })
        )
      }
    }
  }
  // 「OK」ボタン押下
  const handleCreate = () => {
    if (type !== magiContants.BUTTON_TYPE_CANCEL) {
      if (optionVlue === '1') {
        dispatch(
          createsearchCriteriaName({
            searchCriteria: searchCriteria,
            searchName: getSearchInsertName,
            recruitmentManagementDivision: recruitmentManagementDivision,
          })
        )
      } else if (optionVlue === '0') {
        dispatch(
          updatesearchCriteria({
            internalId: getInternalId,
            searchName: getSearchName,
            sysVersionNumber: getVersionNumber,
            sysVersionNumber1: getVersionNumber1,
            searchCriteria: searchCriteria,
          })
        )
      }
      setInitialValuesForm(initialValues)
    } else {
      dispatch(setDialogOpen(false))
    }
    setOpen(false)
    setError({ isError: false, message: '' })
    dispatch(setCheckDialogOpen(false))
  }

  const formModal = useSelector(
    (state: RootState) => state.selectCriteriaManagement.open
  )

  return (
    <>
      {formModal ? <LoadingDialog className='loadingOverlay' /> : null}
      {/* 検索条件保存 */}
      <ModalDialog isOpen={formModal} className='modal'>
        {formModal &&
          (
            <Formik
              initialValues={initialValuesForm}
              // 相関項目チェック
              validate={values => {
                const errors = {
                  selectRadio: '',
                  searchName: '',
                  searchInsertName: '',
                  searchInsertActionName: '',
                }
                let errFlg = false
                if (values.selectRadio === '1') {
                  if (
                    values.searchInsertName.length >
                    magiContants.MAX_LENGTH_MCARS030
                  ) {
                    errors.searchInsertName = validationMessageSet.maxLength({
                      max: magiContants.MAX_LENGTH_MCARS030,
                    })
                    errFlg = true
                  }
                } else if (values.selectRadio === '0') {
                  if (
                    values.nameCheck.length > magiContants.MAX_LENGTH_MCARS030
                  ) {
                    errors.searchName = validationMessageSet.maxLength({
                      max: magiContants.MAX_LENGTH_MCARS030,
                    })
                    errFlg = true
                  }

                } else if (values.selectRadio === '2') {
                  if (
                    values.searchInsertActionName.length >
                    magiContants.MAX_LENGTH_MCARS030
                  ) {
                    errors.searchInsertActionName = validationMessageSet.maxLength(
                      {
                        max: magiContants.MAX_LENGTH_MCARS030,
                      }
                    )
                    errFlg = true
                  }

                }
                if (errFlg) {
                  return errors
                }
              }}
              onSubmit={handleSubmit}
              render={() => (
                <Form onKeyDown={onKeyDown}>
                  <div className='modal-content'>
                    <div className='modal-title-wrap'>
                      <h3 className='modal-title'>MY検索（応募管理）保存</h3>
                      <span
                        className='close-btn'
                        onClick={handleCancel}>
                        <svg width='25' height='25' viewBox='0 0 50 50'>
                          <path
                            id='form__modal_close_white'
                            data-name='form__modal_close'
                            d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
                            transform='translate(0 0)'
                            fill='#FFF'
                          />
                        </svg>
                      </span>
                    </div>
                    <div style={{ marginLeft: '8px', marginRight: '8px' }}
                      dangerouslySetInnerHTML={{
                        __html: getMessage(magiContants.MESSAGECODE_MCARS030_000_1),
                      }}
                    />
                    <div className='modal-section mt5'>
                      <div className='form__body_modal'>
                        <Field
                          name='selectRadio'
                          component={Toggle}
                          radiolist={selectRadioList}
                          setError={setError}
                          setError2={setError2}
                          options={option}
                          initId={escsId}
                          error={error}
                          error2={error2}
                        />
                      </div>
                      <div className={classes.divHidden}>
                        <div>
                          <FastField
                            name={'internalId'}
                            type={'hidden'}
                            component={TextField}
                          />
                        </div>
                        <div>
                          <FastField
                            name={'sysVersionNumber'}
                            type={'hidden'}
                            component={TextField}
                          />
                        </div>
                        <div>
                          <FastField
                            name={'sysVersionNumber1'}
                            type={'hidden'}
                            component={TextField}
                          />
                        </div>
                        <div>
                          <FastField
                            name={'searchCriteria'}
                            type={'hidden'}
                            component={TextField}
                          />
                        </div>
                        <div>
                          <FastField
                            name={'nameCheck'}
                            type={'hidden'}
                            component={TextField}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='modal-section-btn'>
                      <Button
                        variant='contained'
                        onClick={handleCancel}
                        className={`${'btn__cancel'} ${classes.cancelButton}`}>
                        {textMap['cancel'].cancel}
                      </Button>
                      <Button
                        type='submit'
                        variant='contained'
                        className={`${'btn__blue_ok'} ${classes.confirmButton}`}>
                        {textMap['OK'].submit}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />)}
      </ModalDialog >

      <Dialog open={open} disableScrollLock={true} className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>{textMap[type].message}</div>
          <div className='dialog-section-btn'>
            <button onClick={handleMsgDialogClose} className='daialogCancelBtn'>{buttonTextMap.no}</button>
            <button onClick={handleCreate} className='daialogConfirmBtn'>
              {buttonTextMap.yes}
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={checkDialogOpen} disableScrollLock={true} className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>{getMessage(checkMsg)}</div>
          <div className='dialog-section-btn'>
            <button onClick={handleMsgDialogClose} className='daialogCancelBtn'>{buttonTextMap.no}</button>
            <button onClick={handleCreate} className='daialogConfirmBtn'>
              {buttonTextMap.yes}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default MCARM030
