import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FieldProps } from 'formik'
import ReactSelect from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'
import { magiContants } from 'utils/contants'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 1000, fontSize: '1.4rem', }),
  container: (provided: any) => ({ ...provided, fontSize: '1.6rem', }),
  multiValue: (provided: any) => ({
    ...provided,
    maxWidth: '80vw',
    borderRadius: '5px',
    backgroundColor: '#ECEFF1',
  }),
  multiValueLabel: (provided: any) => ({ ...provided, fontSize: '1.6rem' }),
  option: (provided: any) => ({ ...provided, wordBreak: 'break-all', }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '1.6rem',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '40px !important',
    '& div': {
      '& span': {
        marginBottom: '5px',
        marginTop: '5px',
      },
    },
    boxShadow: '0 0 0 0px',
    borderColor: '#cccccc',
    '&:hover': { borderColor: '#cccccc' },
  }),
}

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  defaultValue?: any
  placeholder?: string
  className?: string
  isMulti?: boolean
  isClearable?: boolean
}

export const Select = ({
  isMulti,
  isClearable,
  className,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(
        name,
        option ? (option as Option[]).map(i => i.value) : []
      )
    } else {
      form.setFieldValue(name, option.value)
    }
  }
  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  const defaultValueCheck = (defaultValue: any) => {
    const colourOptions =
      defaultValue !== null
        ? defaultValue.map((i: any) => {
          return { label: i, value: i }
        })
        : null
    return colourOptions
  }

  const optionLabelList = optionList.map(i => i.label)

  return (
    <ReactSelect
      isMulti={isMulti}
      isClearable={isClearable}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValueCheck(formValue) : null}
      noOptionsMessage={({ inputValue }) =>
        optionLabelList.includes(inputValue)
          ? `${inputValue}${magiContants.SUGGEST_ALREADY_INPUT}`
          : noOptionsMessage
      }
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
    />
  )
}

export const SelectOptions = ({
  isMulti,
  isClearable,
  className,
  optionList,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
  defaultValue,
  placeholder,
  field,
  form,
}: Props) => {
  const dispatch = useDispatch()
  const { name, value } = field

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(name, option ? (option as Option[]).map(i => i) : [])
    } else {
      form.setFieldValue(name, option as Option)
    }

    if (
      name === 'applicationTypeObj' &&
      form.values.questionnaireObj !== null
    ) {
      //応募職種が変更された
      //かつ、アンケート回答内容条件が指定されている
      if (!option || form.values.applicationTypeObj.value !== option.value) {
        //応募職種項目が削除された
        //または、応募職種項目が変更された
        form.setFieldValue('questionnaireObj', null)
      }
    }
  }

  const [formValue, setDefaultValue] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue != null) {
      setDefaultValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <ReactSelect
      isMulti={isMulti}
      isClearable={isClearable}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={formValue !== undefined ? defaultValue : null}
      noOptionsMessage={() => noOptionsMessage}
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
    />
  )
}
