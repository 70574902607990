import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import ReactSelect, {
  components as componentsML,
  MenuListComponentProps,
} from 'react-select'
import { useDispatch } from 'react-redux'
import { setTemplateNameSelect } from 'reducers/messageSendCompReducer'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  option: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
  }),
  menu: (provided: any) => ({
    ...provided,
    msOverflowStyle: 'auto',
  }),
}

interface Option {
  value: string
  label: string
  templateType: string
  sysVersionNumber: string
}

function MenuList(props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div
        id='menuListScrollDiv'
        style={{ display: 'inline-block', minWidth: '100%', marginTop: '4px' }}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList,
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  placeholder?: string
  className?: string
  isMulti?: boolean
  defaultValue: string
}

export default ({
  isMulti,
  className,
  optionList,
  placeholder,
  field,
  form,
  defaultValue,
}: Props) => {
  const { name, value } = field
  const dispatch = useDispatch()

  useEffect(() => {
    if (defaultValue != null && defaultValue !== '') {
      form.setFieldValue(name, defaultValue)
      const option = optionList.find(i => i.value === defaultValue)
      if (option != null) {
        dispatch(setTemplateNameSelect(option))
      }
    }
  }, [defaultValue])

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(name, option ? (option as Option[]).map(i => i) : [])
    } else {
      form.setFieldValue(name, option as Option)
    }
    dispatch(setTemplateNameSelect(option))
  }

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={optionList.find(i => i.value === value)}
      noOptionsMessage={() => magiContants.REACT_SELECT_NOOPTIONSMESSAGE}
      placeholder={placeholder}
      onChange={handleChange}
      menuPlacement={'auto'}
      maxMenuHeight={125}
      filterOption={customSelectOptions.filterOption}
      components={components}
    />
  )
}
