import { makeStyles } from '@material-ui/core/styles'

export const useErrorStyle = makeStyles({
  helperText: {
    fontSize: '11px',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    margin: '8px 14px 0',
    whiteSpace: 'pre-wrap',
  },
})

export { default as Page } from './Page'
export { default as TextField } from './TextField'
export { default as Checkbox } from './Checkbox'
export { default as Toggle } from 'components/Toggle'
export { default as AccountEdit } from 'components/AccountEdit'
export { default as SubmitButton } from 'components/SubmitButton'
export { default as Label } from 'components/Label'
export { default as SubTitle } from './SubTitle'
export { default as Select } from 'components/Select'
export { default as Paginate } from './Paginate'
export { default as Footer } from './Footer'
export { default as Loading } from './Loading'
export { default as GroupOptions } from './GroupOptions'
export { default as DatePicker } from './DatePicker'
export { default as ProgressStatus } from './ProgressStatus'
export { default as TopBar } from 'components/TopBar'
export { default as Alert } from 'components/Alert'
export { default as BulkChange } from 'components/BulkChange'
export { default as Button } from './Button'
export { default as ButtonGroup } from './ButtonGroup'
export { default as ConfirmModal } from 'components/ConfirmModal'
export { default as DataTable } from 'components/DataTable'
export { default as FieldGroup } from './FieldGroup'
export { default as Filter } from 'components/Filter'
export { default as FormField } from './FormField'
export { default as OperationPanel } from 'components/OperationPanel'
export { default as Radio } from 'components/Radio'
export { default as ScrollToTop } from 'components/ScrollToTop'
export { default as SearchButton } from 'components/SearchButton'
export { default as SearchPanel } from 'components/SearchPanel'
export { default as StyledTableCell } from 'components/StyledTableCell'
export { default as Tab } from 'components/Tab'
export { default as TabForMessage } from './TabForMessage'
// MCAZS170~MCAZS200 start
export { default as TabMcb } from 'components/TabMcb'
export { default as TabNormal } from 'components/TabNormal'
export { default as TabForMessageMycareerbox } from 'components/TabForMessageMycareerbox'
// MCAZS170~MCAZS200 end
export { default as Table } from 'components/Table'
export { default as Title } from './Title'
export { default as GroupHorizontal } from './GroupHorizontal'
export { default as InputWithCounter } from './InputWithCounter'
export { default as MultiTextWithCounter } from 'components/MultiTextWithCounter'
export { default as TextareaAutosize } from 'components/TextareaAutosize'
export { default as MapTextField } from 'components/MapTextField'
export { default as MultilineEllipsie } from 'components/MultilineEllipsie'
export { default as DataTimePicker } from 'components/DataTimePicker'
// MCBリプレース MCBJS020 START
export { default as DataTimePickerCustom } from 'components/DataTimePickerCustom'
// MCBリプレース MCBJS020 END
export { default as SelectForMessage } from './SelectForMessage'
export { default as SelectForMessageReact } from './SelectForMessageReact'
export { default as TextFieldForMessage } from './TextFieldForMessage'
export { default as SelectMessage } from 'components/SelectMessage'
export { default as SelectMessageReact } from 'components/SelectMessageReact'
export { default as InputWithPostalCode } from 'components/InputWithPostalCode'
export { default as AddressModal } from 'components/AddressModal'
export { default as ContentMessage } from 'components/ContentMessage'
export { default as MajorField } from 'components/MajorField'
export { default as SchoolDetailedDivision } from 'components/SchoolDetailedDivision'
export { default as FiftyPhonogram } from 'components/FiftyPhonogram'
export { default as SchoolNameSelect } from 'components/SchoolNameSelect'
export { default as TypeSuggestSelect } from 'components/TypeSuggestSelect'
export { default as TypeToggle } from 'components/TypeToggle'
export { default as InputWithContent } from 'components/InputWithContent'
export { default as PromptBack } from './PromptBack'
export { default as CheckBoxCoulumn } from './CheckBoxCoulumn'
export { default as LoadingDialog } from './LoadingDialog'
//年度対応 start
export { default as SelectOfTargetYear } from 'components/SelectOfTargetYear'
//年度対応 end
export { default as EntryDetailsStepIcon } from 'sp/components/EntryDetailsStepIcon'
export { default as FormModalCloseIcon } from 'sp/components/FormModalCloseIcon'
export { ModalDialog } from 'sp/components/ModalDialog'
export { default as Contact } from 'sp/components/Contact'
export { default as StickyFooterBlock } from 'sp/components/StickyFooterBlock'