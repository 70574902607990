import React from 'react';

export default () => {
  return (
    <div className="other-operation">
      <button
        type="button"
        className="btn most circular icon-changeProgress"
        data-action="check-open-change-progress"
        data-balloon="changeProgress">
        合否・選考
        <br />
        ステップを
        <br />
        一括変更
      </button>
      <button
        type="button"
        className="btn most circular icon-otherOperation"
        data-action="open-balloon-otherOperations"
        data-balloon="otherOperation">
        その他の
        <br />
        一括操作
      </button>
    </div>
  );
};
