import React from 'react'
import { FieldProps } from 'formik'
import { TextFieldForMessage } from 'componentsHsc'

interface Props extends FieldProps {
  name?: string
  label: string
  type?: string
  variant?: any
  className?: any
  maxAmount?: number
  onChange?: (value: string) => void
  onBlur?: (target: any) => void
}

const HomepageInput = ({
  field,
  form,
  label,
  type,
  variant,
  className,
  maxAmount,
  onChange,
  onBlur,
  ...others
}: Props) => {
  const { name, value } = field
  const handleChange = (event: any) => {
    if (400 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value)
    }
  }
  const hanleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event.target)
    }
    form.setFieldTouched(name, true)
  }
  return (
    <TextFieldForMessage
      form={form}
      field={{ ...field, onChange: handleChange, onBlur: hanleBlur }}
      type={type}
      {...others}
    />
  )
}

export default HomepageInput
