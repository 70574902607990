import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { isEmpty } from 'common/generalUtil'
import { DatePicker } from 'componentsHsc'
import { Field } from 'formik'
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { changeTransmission, inputSendTimer } from 'reducers/messageSendReducer'
import { magiContants } from 'utils/contants'
import { FormHandler } from '.'
import Layout from './Layout'
import MessageInputToolTip from './MessageInputToolTip'
import { useStyles } from './Reminder'
import { act } from 'react-dom/test-utils'
import { SubscriptionsOutlined } from '@material-ui/icons'

const name = 'sendTime'

interface Props {
  form: FormHandler
  isInitialized: boolean
  destinationSelection: string
  interviewWebUrlSendFlag: string
  setTimeState: (state: boolean) => void
}

const SendTime = ({
  form,
  isInitialized,
  destinationSelection,
  interviewWebUrlSendFlag,
  setTimeState,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [state, setState] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const sendTimeInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.sendTimeInput
  )
  const targetList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const messageType = useSelector(
    (state: RootState) => state.messageSend.initResult.messageType
  )
  const action = useSelector(
    (state: RootState) => state.messageSend.messageInitRequest.action
  )
  const adjustmentInterviewScheduleAdjustmentId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.hiddenItems
        .adjustmentInterviewScheduleAdjustmentId
  )
  const interviewScheduleAdjustmentEntry = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.interviewScheduleAdjustmentEntry
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const draftMessageSendId = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.draftMessageSendId
  )

  useEffect(() => {
    if (sendTimeInput.transmission === '1') {
      setState(false)
    }
  }, [])

  useEffect(() => {
    if (isInitialized && form.values.sendTime) {
      const value = form.values.sendTime
      dispatch(inputSendTimer({ value }))
    }
  }, [form.values.sendTime])

  useEffect(() => {
    if (
      destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
    ) {
      if (
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        setDisabled(false)
        return
      }
      if (!isEmpty(action)) {
        switch (action) {
          case magiContants.ACTION_CHANGE_POSSIBLE:
          case magiContants.ACTION_CHANGE_INTERVIEW:
          case magiContants.ACTION_FIX_INTERVIEW:
          case magiContants.ACTION_WITHDRAW_INTERVIEW:
            setDisabled(true)
            break
          case magiContants.ACTION_READJUST_INTERVIEW:
            if (
              manipulationSelection !==
              magiContants.SELECTION_NUMBER_NOT_PRESENT
            ) {
              setDisabled(true)
            }
            break
          default:
            break;
        }
        return
      }
      switch (manipulationSelection) {
        case magiContants.SELECTION_NUMBER_NOT_PRESENT:
          setDisabled(false)
          break
        case magiContants.SELECTION_NUMBER_PRESENT:
        case magiContants.SELECTION_NUMBER_OTHER:
          if (
            adjustmentInterviewScheduleAdjustmentId &&
            adjustmentInterviewScheduleAdjustmentId.length > 0
          ) {
            if (
              draftMessageSendId &&
              messageType &&
              messageType.length > 0 &&
              messageType[0].messageType === '03'
            ) {
              setDisabled(false)
            } else {
              setDisabled(true)
            }
          } else {
            const selectionManagementIdList = targetList.map(
              item => item.selectionManagementId
            )
            const filterdList = interviewScheduleAdjustmentEntry.filter(item =>
              selectionManagementIdList.includes(
                String(item.selectionManagementId)
              )
            )
            if (filterdList && filterdList.length > 0) {
              setDisabled(true)
            } else {
              setDisabled(false)
            }
          }
          break
        case magiContants.SELECTION_NUMBER_FIX:
        case magiContants.SELECTION_NUMBER_SEND:
          setDisabled(true)
          break
      }
    } else {
      setDisabled(false)
    }
  }, [manipulationSelection, action, destinationSelection])

  const enableInput = () => {
    setState(false)
  }

  const handleClick = (e: any) => {
    form.setFieldValue(name, null)
    setState(true)
  }

  const handleChangeTransmission = (value: string) => {
    dispatch(changeTransmission({ value }))
  }


  useEffect(() => {
    form.setFieldValue('sendTimeType', sendTimeInput.transmission)
  }, [sendTimeInput.transmission])

  useEffect(() => {
    setTimeState(disabled)
  }, [disabled])


  const toolTip1Title = useMemo(() => {
    return (
      <span>
        {'タイマー送信すると、指定した日時に自動送信されます。'}
        <br></br>
        {'メッセージの送信予約は30日後まで指定可能です。'}
      </span>
    )
  }, [])

  return (
    <Layout id='send-time' icon='sendTime'>
      <div>
        <section>
          <h1 className={classes.required} style={{ fontWeight: 'bold' }}>
            送信日時
            <MessageInputToolTip
              title={toolTip1Title}
            />
          </h1>
          {form.touched.sendTimeType && form.errors.sendTimeType && (
            <p>{form.errors.sendTimeType}</p>)
          }
          <div>
            <FormControl className={classes.root} style={{ marginLeft: '10px' }}>
              <RadioGroup name='sendTimeType' value={sendTimeInput.transmission} aria-label='sendTypeRadioGroup'
                onChange={e => handleChangeTransmission(e.target.value)}>
                <FormControlLabel
                  className={classes.immediate}
                  control={
                    <Radio color='primary' onClick={handleClick} />
                  }
                  label='すぐに送信する'
                  value='0'
                />
                <FormControlLabel
                  className={disabled ? classes.disabled : ''}
                  disabled={disabled}
                  control={
                    <Radio color='primary' onClick={enableInput} />
                  }
                  label={
                    <div className={classes.container}>
                      <Typography className={classes.text}>
                        タイマー送信する
                      </Typography>
                      <Field
                        name={name}
                        className={state ? classes.disabled : ''}
                        disabled={state}
                        component={DatePicker}
                        validateTimeUnit
                        showPlaceholder={false}
                        labelName='タイマーは'
                        selected={sendTimeInput.sendPlanTime}
                      />
                    </div>
                  }
                  value='1'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SendTime
