import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
  DialogContent,
} from '@material-ui/core'
import { getMessage } from 'common/messageUtil'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import {
  setinitRequest,
  setRMFInfo,
  insertByRMF,
  setDialogOpen,
} from 'reducers/recruitmentManagementFlagChangeReducer'
import theme from 'theme'
import { magiContants } from 'utils/contants'
import InputOnlyNumber from './InputOnlyNumber'
import InputWithCounter from './InputWithCounter'
import ActionSetSelect from './ActionSetSelect'
import { onKeyDown } from 'common/generalUtil'
import * as yup from 'yup'
import { LoadingDialog } from 'components'

const useStyles = makeStyles(theme => ({
  fontWhite: {
    color: '#FFF',
  },
  title: {
    minWidth: 185,
  },
  dialogTitle: {
    backgroundColor: '#565a61',
    padding: theme.spacing(3, 3, 0, 3),
  },
  dialogActions: {
    justifyContent: 'center',
    backgroundColor: '#44474d',
    padding: theme.spacing(5, 5),
    minHeight: 50,
  },
  button: {
    border: '1px solid',
    borderRadius: '4px',
    minWidth: '196px',
    margin: '0 20px',
    color: '#1a1a1a',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
    },
  },
  formField: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(0.25, 7),
  },
  dialogContent: {
    margin: '0 17px',
    overflowY: 'auto',
    paddingRight: 17,
  },
  contentTitle: {
    padding: theme.spacing(0, 6, 0, 6),
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper':{
      overflowY: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'auto' : 'hidden',
      display: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'block' : 'flex',
    },
    '& .MuiDialogContent-root':{
      minWidth: '960px',
      maxHeight: '100%',
      backgroundColor: '#565a61',
      padding: 0,
    },
  },
  // 次期開発12月 #51829 start
  checkboxLabel: {
    marginLeft:'40px',
  },
  deletedBox: {
    width:'26px!important',
    height:'26px!important',
    border:'4px solid rgb(117 130 157)!important'
  },
  // 次期開発12月 #51829 end
}))
interface Props {
  open: boolean
  onOk: () => void
  onCancel: () => void
  prevScreenId:string
}
const MCAXS340 = ({ open, onOk, onCancel, prevScreenId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // 初期化取得したJSON情報
  const initData = useSelector(
    (state: RootState) => state.mcaxs340Reducer.initRMFInfo
  )
  const isDialogOpen = useSelector(
    (state: RootState) => state.mcaxs340Reducer.isDialogOpen
  )
  const mcaxs340InitRequest = useSelector(
    (state: RootState) => state.mcaxs340Reducer.initRequest
  )
  const companyList = useSelector(
    (state: RootState) => state.mcaxs340Reducer.initRMFInfo.mcaxs340RecruitmentManagementFlagCompanyListResultDto
  )
  // 初期化取得した採用管理フラグ情報
  const [rmfList, setRMFList] = useState<any>([])
  // 初期化取得した採用管理フラグ選択肢情報
  const [rmfoList, setRMFOList] = useState<any>([])
  // 初期化取得した更新対象の採用管理フラグ情報
  const [updateCheckList, setUpdateCheckList] = useState<any>([])
  const scrollBodyRef = useRef<HTMLDivElement>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [findUpdateInit, setFindUpdateInit] = useState<any>(null)
  // 次期開発12月 #51829 start
  // データ削除した採用管理フラグ設定ID配列
  const [selectedRFSettingId,setSelectedRFSettingId] = useState([] as string[])
  // 次期開発12月 #51829 end

  useEffect(() => {
    if (mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId) {
      const selectionManagementId = mcaxs340InitRequest.selectionManagementId
      // 選考管理IDが設定されていない または　選考管理IDが1件
      if (selectionManagementId.length === 0) {
        setRMFList(
          initData.mcaxs340RecruitmentManagementFlagListResultDto
            ? initData.mcaxs340RecruitmentManagementFlagListResultDto
            : []
        )
        // 選考管理IDが複数件
      } else if (selectionManagementId.length >= 1) {
        setRMFList(
          initData.mcaxs340RecruitmentManagementFlagCompanyListResultDto
        )
        setUpdateCheckList(
          initData.mcaxs340RecruitmentManagementFlagUpdateCheckDto
        )
      }
      setRMFOList(initData.mcaxs340RecruitmentManagementFlagOptionListResultDto)
    }
  }, [initData])

  const RecruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  // 画面の「OK」ボタン押下
  const MCAXS340Submit = (values: any) => {
    const newData: any = {}
    const valueData: any = {}
    Object.keys(values).forEach(key => {
      valueData[key] = '' + values[key]
    })
    Object.keys(findUpdateInit).forEach(key => {
      if (findUpdateInit[key].toString() !== valueData[key].toString()) {
        newData[key] = valueData[key]
      }
      // 次期開発12月 #51829 start
      // 変更する管理項目にデータ削除した採用管理フラグ設定IDを置く
      for (let a = 0;a < selectedRFSettingId.length;a++){
        newData[selectedRFSettingId[a]] = valueData[selectedRFSettingId[a]]
      }
      // 次期開発12月 #51829 end
    })
    let submitValues = {
      jobSeekerId: mcaxs340InitRequest.jobSeekerId.map(i => i.toString()),
      entryId: mcaxs340InitRequest.entryId.map(i => i.toString()),
      selectionManagementId: mcaxs340InitRequest.selectionManagementId.map(i => i.toString()),
      recruitmentManagementFlagId: [] as string[],
      recruitmentManagementFlagSettingId: [] as string[],
      recruitmentManagementFlagName: [] as string[],
      recruitmentManagementFlagOptionSettingId: [] as string[],
      recruitmentManagementFlagOption: [] as string[],
      settingNumber: [] as string[],
      settingString: [] as string[],
      type: [] as string[],
      sysVersionNumber: [] as string[],
      recruitmentManagementDivision: RecruitmentManagementDivision,
      prevScreenId: prevScreenId,
    }
    // 変更項目ありの場合、選考管理IDは初期化する
    for (const id in newData) {
  　　submitValues.selectionManagementId = []
  　　break
　　}
    for (const rmfsId in newData) {
      for (
        let i = 0;
        i < mcaxs340InitRequest.selectionManagementId.length;
        i++
      ) {
        const selManagId = mcaxs340InitRequest.selectionManagementId[i]
        const mfItem = rmfList.find(
          (i: any) => i.recruitmentManagementFlagSettingId.toString() === rmfsId.toString()
        )
        if (mfItem) {
          let optionId = '0'
          let optionName = ''
          let settingNum = '0'
          let settingStr = ''
          if (mfItem.type === '30') {
            // 次期開発12月 #51829 start
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、採用管理フラグ選択肢設定IDを「0」設定
            if (selectedRFSettingId.indexOf(rmfsId) >= 0) {
              newData[rmfsId] = '0'
            } 
            // 次期開発12月 #51829 end
            if (newData[rmfsId].length === 0) {
              return
            }
            optionId = newData[rmfsId]
            const rmfoItem = rmfoList.find(
              (i: any) =>
                i.recruitmentManagementFlagOptionSettingId == optionId &&
                i.recruitmentManagementFlagSettingId ==
                mfItem.recruitmentManagementFlagSettingId
            )
            if (rmfoItem) {
              optionName = rmfoItem.option
            }
          } else if (mfItem.type === '20') {
            // 次期開発12月 #51829 start
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、対応する数値を「''」設定
            if(selectedRFSettingId.indexOf(rmfsId) >= 0) {
              newData[rmfsId] = ''
            }
            // 次期開発12月 #51829 end
            const pattDecimal = /^-?(0|[1-9]\d*)(\.\d+)?$/
            const pattFloat = /^-?(0|[1-9]\d*)(\.\d{1,2})?$/
            if (
              (newData[rmfsId].length > 11 && newData[rmfsId] > 0 ) ||
              (newData[rmfsId].length > 12 && newData[rmfsId] < 0 ) ||
              newData[rmfsId] > 99999999.99 ||
              newData[rmfsId] < -99999999.99 ||
              !(newData[rmfsId] === '' || pattDecimal.test(newData[rmfsId])) ||
              !(newData[rmfsId] === '' || pattFloat.test(newData[rmfsId]))
            ) {
              return
            }
            if (newData[rmfsId] === '') {
              settingNum = ''
            } else {
              settingNum = newData[rmfsId]
            }
          } else if (mfItem.type === '10') {
            // 次期開発12月 #51829 start
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、対応する文字列を「''」設定
            if (selectedRFSettingId.indexOf(rmfsId) >= 0){
              newData[rmfsId] = ''
            }
            // 次期開発12月 #51829 end
            // [phase2] start
            if (newData[rmfsId].length > 400) {
            // [phase2] end
              return
            }
            settingStr = newData[rmfsId]
          }
          if (mcaxs340InitRequest.selectionManagementId.length >= 1) {
            const upCheckItem = updateCheckList.find(
              (i: any) => i.recruitmentManagementFlagSettingId.toString() === rmfsId.toString() && i.selectionManagementId.toString() === selManagId.toString()
            )
            if (upCheckItem) {
              submitValues.recruitmentManagementFlagId.push(
                upCheckItem.recruitmentManagementFlagId
              )
              submitValues.sysVersionNumber.push(upCheckItem.sysVersionNumber)
            } else {
              submitValues.recruitmentManagementFlagId.push('0')
              submitValues.sysVersionNumber.push('0')
            }
          }
          submitValues.selectionManagementId.push(selManagId.toString())
          submitValues.recruitmentManagementFlagOptionSettingId.push(optionId)
          submitValues.recruitmentManagementFlagOption.push(optionName)
          submitValues.settingNumber.push(settingNum)
          submitValues.settingString.push(settingStr)
          submitValues.recruitmentManagementFlagSettingId.push(
            mfItem.recruitmentManagementFlagSettingId
          )
          submitValues.recruitmentManagementFlagName.push(
            mfItem.recruitmentManagementFlagName
          )
          submitValues.type.push(mfItem.type)
        }
      }
    }
    if (mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId) {
      const selectionManagementId = mcaxs340InitRequest.selectionManagementId
      if (selectionManagementId.length > 0) {
        // 次期開発12月 #51829 start
        dispatch(insertByRMF({ request: submitValues, onOk: onOk ,clearSelectedRFSettingId: clearSelectedRFSettingId}))
        // 次期開発12月 #51829 end
      } else {
        handleConfirm()
      }
    }
  }
  // 次期開発12月 #51829 start
  const clearSelectedRFSettingId = () => {
    setSelectedRFSettingId([])
  }
  // 次期開発12月 #51829 end

  // 画面の「キャンセル」ボタン押下
  const handleGamennCancel = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmCancel = () => {
    handleConfirm()
    onCancel()
    // 次期開発12月 #51829 start
    setSelectedRFSettingId([])
    // 次期開発12月 #51829 end
  }

  // 確認ダイアログの「OK」ボタン押下
  const handleConfirm = () => {
    dispatch(setDialogOpen(false))
    setConfirmDialogOpen(false)
    dispatch(
      setRMFInfo({
        mcaxs340RecruitmentManagementFlagListResultDto: [],
        mcaxs340RecruitmentManagementFlagOptionListResultDto: [],
        mcaxs340RecruitmentManagementFlagCompanyListResultDto: [],
        mcaxs340RecruitmentManagementFlagUpdateCheckDto: [],
      })
    )
    dispatch(
      setinitRequest({
        jobSeekerId: [],
        entryId: [],
        selectionManagementId: [],
        recruitmentManagementFlagSettingId: [],
        recruitmentManagementFlagOptionSettingId: [],
        settingString: [],
        settingNumber: [],
        recruitmentManagementDivision: '',
      })
    )
  }

  // 確認ダイアログの「キャンセル」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
  }

  // 初期化時にFormの情報取得
  const getFormInitValues = () => {
    if (findUpdateInit) {
      return findUpdateInit
    }
    let initValues: any = new Object()
    if (rmfList && rmfList.length > 0) {
      rmfList.map((i: any) => {
        if (i['recruitmentManagementFlagSettingId']) {
          let key = i['recruitmentManagementFlagSettingId']
          let value = ''
          let valueFromer = i
          // 選考管理IDが1件
          if (
            mcaxs340InitRequest &&
            mcaxs340InitRequest.selectionManagementId &&
            mcaxs340InitRequest.selectionManagementId.length === 1
          ) {
            if (updateCheckList && updateCheckList.length > 0) {
              const tmpObj = updateCheckList.find(
                (i: any) => i.recruitmentManagementFlagSettingId === key
              )
              if (tmpObj) {
                valueFromer = tmpObj
              }
            }
          }
          if (i.type === '30') {
            value = valueFromer['recruitmentManagementFlagOptionSettingId']
              ? valueFromer['recruitmentManagementFlagOptionSettingId']
              : 0
          } else if (i.type === '20') {
            value = null != valueFromer['settingNumber'] 
              ? valueFromer['settingNumber']
              : ''
          } else if (i.type === '10') {
            value = valueFromer['settingString']
              ? valueFromer['settingString']
              : ''
          }
          if (i.type === '30' || i.type === '20' || i.type === '10') {
            initValues[key] = value
          }
        }
      })
      setFindUpdateInit(initValues)
    }
    return initValues
  }

  // 画面情報取得
  const getManagementFlagList = () => {
    if (
      findUpdateInit &&
      Object.keys(findUpdateInit).length > 0 &&
      rmfList &&
      rmfList.length > 0
    ) {
      const managementFlagList = rmfList.filter(
        (i: any) => null != i['recruitmentManagementFlagSettingId']
      )
      return managementFlagList
    } else {
      return []
    }
  }

  // プルダウン情報取得
  const getManagementFlagOptionList = (
    recruitmentManagementFlagSettingId: any
  ) => {
    const managementFlagOptionList = rmfoList.reduce(
      (arrOption: Array<any>, item: any) => {
        if (
          Number.parseInt(item.recruitmentManagementFlagSettingId) ===
          recruitmentManagementFlagSettingId
        ) {
          arrOption.push({
            label: item.option,
            value: item.recruitmentManagementFlagOptionSettingId,
          })
        }
        return arrOption
      },
      // 次期開発12月 #51829 start
      [(prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') ? 
        (mcaxs340InitRequest 
          && mcaxs340InitRequest.selectionManagementId
          && mcaxs340InitRequest.selectionManagementId.length === 1 ?
            { label: '（未選択）', value: 0 }
            : (selectedRFSettingId.indexOf(recruitmentManagementFlagSettingId.toString()) !== -1 ? 
              {label: '項目のデータを一括削除します' , value: -1} 
              : 
              { label: '' , value: 0 })
          ) 
        : { label: '（未選択）', value: 0 }
      ]
      // 次期開発12月 #51829 end
    )
    return managementFlagOptionList
  }

  // 次期開発12月 #51829 start
  // データ削除のチェックボックス押下
  const handleSelectOne = (id: number) => () => {
    const selectedIndex = selectedRFSettingId.indexOf(id.toString())

    let newSelectedRFSettingId: string[] = []

    if (selectedIndex === -1) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId,
        id.toString()
      )
    } else if (selectedIndex === 0) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(1)
      )
    } else if (selectedIndex === selectedRFSettingId.length - 1) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(0,-1)
      )
    } else if (selectedIndex > 0) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(0,selectedIndex),
        selectedRFSettingId.slice(selectedIndex + 1)
      )
    }
    setSelectedRFSettingId(newSelectedRFSettingId)
  }
  // 次期開発12月 #51829 end

  return (
    <>
      {open ? <LoadingDialog className='overlay' /> : null}
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onEnter={() => getFormInitValues()}
        onExited={() => setFindUpdateInit(null)}
        className={classes.dialog + ' mainDialog'}>
          <DialogContent>
          <Formik
            onSubmit={MCAXS340Submit}
            enableReinitialize
            initialValues={findUpdateInit}>
            <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} onKeyDown={onKeyDown}>
              <div className={classes.dialogContent}>
                <div ref={scrollBodyRef}>
                  <DialogTitle>
                    <div className={classes.dialogTitle}>
                      <Typography
                        variant='h2'
                        gutterBottom
                        style={{ color: (theme.palette as any).white }}>
                        管理項目変更
                      </Typography>
                      <Typography variant='h6' gutterBottom>
                        <div
                          dangerouslySetInnerHTML={{
                            // 次期開発12月 #51829 start
                            __html: getMessage((prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') ? (mcaxs340InitRequest 
                              && mcaxs340InitRequest.selectionManagementId
                              && mcaxs340InitRequest.selectionManagementId.length === 1 ?
                              magiContants.MESSAGECODE_MCAXS340_000 : magiContants.MESSAGECODE_MCAXS340_0000)
                             : magiContants.MESSAGECODE_MCAXS340_000)
                            // 次期開発12月 #51829 end
                          }}
                          style={{ color: (theme.palette as any).white }}
                        />
                      </Typography>
                    </div>
                  </DialogTitle>
                  <div className={classes.contentTitle}>
                    <Typography variant='h6' gutterBottom>
                      <div
                        dangerouslySetInnerHTML={{ __html: '管理項目一覧' }}
                        // 次期開発12月 #51829 start
                        style={{ color: (theme.palette as any).white , marginLeft : '0px' , display: 'inline-block' }}
                        // 次期開発12月 #51829 end
                      />
                      {/* 次期開発12月 #51829 start */}
                      {(prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1 ? 
                      <div
                        dangerouslySetInnerHTML={{ __html: 'データ削除' }}
                        style={{ color: (theme.palette as any).white , marginLeft : '640px' , display: 'inline-block' }}
                      /> : ''}
                      {/* 次期開発12月 #51829 end */}
                    </Typography>
                    <Divider
                      style={{
                        height: 2,
                        backgroundColor: 'rgba(255,255,255,.3)',
                      }}
                    />
                    <br />
                  </div>
                  {getManagementFlagList().map((item: any, index: number) => (
                    <div key={index}>
                      {item.type === '30' ? (
                        <div className={classes.formField}>
                          <Field
                            name={item.recruitmentManagementFlagSettingId.toString()}
                            optionList={getManagementFlagOptionList(
                              item.recruitmentManagementFlagSettingId
                            )}
                            component={ActionSetSelect}
                            title={item.recruitmentManagementFlagName}
                            item={
                              findUpdateInit
                                ? findUpdateInit[
                                item.recruitmentManagementFlagSettingId.toString()
                                ]
                                : null
                            }
                            // 次期開発12月 #51829 start
                            // データ削除のチェックボックスの選択状態
                            clearRecruitmentManageFlag={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1 ? true : false}
                            // 選考管理IDの件数
                            selectDoubleFalg={mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1}
                            // 遷移元画面ID
                            prevScreenId={prevScreenId}
                            // 次期開発12月 #51829 end
                          />
                          {/* 次期開発12月 #51829 start */}
                          {(prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1 ? 
                          <label className={classes.checkboxLabel}>
                            <input
                                  type='checkbox'
                                  checked={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1}
                                  onChange={handleSelectOne(item.recruitmentManagementFlagSettingId)}
                                />
                                <mark className={classes.deletedBox}></mark>
                          </label>
                          : ''}
                          {/* 次期開発12月 #51829 end */}
                        </div>
                      ) : item.type === '20' ? (
                        <div className={classes.formField}>
                          <Field
                            name={item.recruitmentManagementFlagSettingId.toString()}
                            component={InputOnlyNumber}
                            title={item.recruitmentManagementFlagName}
                            item={
                              findUpdateInit
                                ? findUpdateInit[
                                item.recruitmentManagementFlagSettingId.toString()
                                ]
                                : null
                            }
                            validate={async (value: any) => {
                              let flg = false
                              let message
                              await yup.object().shape({
                                [item.recruitmentManagementFlagSettingId.toString()]: yup
                                .string()
                              }).validate({[item.recruitmentManagementFlagSettingId.toString()]:value}).catch(err=>{
                                flg = true
                                message = err.errors
                              })
                              if (flg) {
                                return message
                              } else {
                                return ''
                              }
                            }}
                            // 次期開発12月 #51829 start
                            // データ削除のチェックボックスの選択状態
                            clearRecruitmentManageFlag={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1 ? true : false}
                            // 選考管理IDの件数
                            selectDoubleFalg={mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1}
                            // 遷移元画面ID
                            prevScreenId={prevScreenId}
                            // 次期開発12月 #51829 end
                          />
                          {/* 次期開発12月 #51829 start */}
                          {(prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1 ? 
                          <label className={classes.checkboxLabel}>
                          <input
                                type='checkbox'
                                checked={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1}
                                onChange={handleSelectOne(item.recruitmentManagementFlagSettingId)}
                              />
                              <mark className={classes.deletedBox}></mark>
                          </label>
                          : ''}
                          {/* 次期開発12月 #51829 end */}
                        </div>
                      ) : item.type === '10' ? (
                        <div className={classes.formField}>
                          <Field
                            name={item.recruitmentManagementFlagSettingId.toString()}
                            component={InputWithCounter}
                            title={item.recruitmentManagementFlagName}
                             // [phase2] start
                            maxAmount={400}
                             // [phase2] end

                            item={
                              findUpdateInit
                                ? findUpdateInit[
                                item.recruitmentManagementFlagSettingId.toString()
                                ]
                                : null
                            }
                            validate={async (value: any) => {
                              let flg = false
                              let message
                              await yup.object().shape({
                                [item.recruitmentManagementFlagSettingId.toString()]: yup
                                .string()
                              }).validate({[item.recruitmentManagementFlagSettingId.toString()]:value}).catch(err=>{
                                flg = true
                                message = err.errors
                              })
                              if (flg) {
                                return message
                              } else {
                                return ''
                              }
                            }}
                            // 次期開発12月 #51829 start
                            // データ削除のチェックボックスの選択状態
                            clearRecruitmentManageFlag={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1 ? true : false}
                            // 選考管理IDの件数
                            selectDoubleFalg={mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1}
                            // 遷移元画面ID
                            prevScreenId={prevScreenId}
                            // 次期開発12月 #51829 end
                          />
                          {/* 次期開発12月 #51829 start */}
                          {(prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId && mcaxs340InitRequest.selectionManagementId.length > 1 ? 
                          <label className={classes.checkboxLabel}>
                          <input
                                type='checkbox'
                                checked={selectedRFSettingId.indexOf(item.recruitmentManagementFlagSettingId.toString()) !== -1}
                                onChange={handleSelectOne(item.recruitmentManagementFlagSettingId)}
                              />
                              <mark className={classes.deletedBox}></mark>
                          </label>
                          : ''}
                          {/* 次期開発12月 #51829 end */}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={handleGamennCancel}
                  variant='contained'
                  className={classes.button}>
                  キャンセルする
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  className={classes.button}
                  disabled = {companyList.length ==0 ? true: false}>
                  OK
                </Button>
              </DialogActions>
            </Form>
          </Formik>
          </DialogContent>
      </Dialog>
      <Dialog open={confirmDialogOpen}>
        <DialogTitle>
          {getMessage(magiContants.MESSAGECODE_MCAXS340_003)}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleConfirmCancel} color='primary'>
          OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default MCAXS340
