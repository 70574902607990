import { Grid, TextField as MuiTextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { Title } from 'components';
import theme from 'theme';
import { magiContants, validationMessageSet } from 'utils/contants';
import { getMessage } from 'common/messageUtil';

const useStyles = makeStyles(theme => ({
  textCounter: {
    float: 'right',
    minWidth: 120,
    textAlign: 'right',
  },
  inputWithCounter: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1, 1, 0, 1),
  },
  inputWithCounter1: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(0, 1, 1, 1),
    height: 'auto',
    minHeight: '0px'
  },
  fontWhite: {
    color: '#FFF',
  },
  input: {
    textAlign: 'right',
    fontSize: 14,
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'textfield!important',
    },
    '&::-ms-clear, ::-ms-reveal': {
      display: 'none',
    },
  },
  title: {
    minWidth: 185,
  },
  backgroundColor: {
    backgroundColor: '#3f71bd',
  },
  helperText: {
      fontSize: '11px',
      lineHeight: '1em',
      color: '#e53935',
      margin: '4px 14px',
  },
  fontWhite1: {
      width: '22px'
  },
  // 次期開発12月 #51829 start
  backgroundColorDeleted: {
    width: '90%',
    backgroundColor: '#3f71bd',
  },
  grid: {
    width: '90%'
  },
  muiTextFieldStyleDeleted: {
    background: 'lightGrey!important',
    color: 'black',
  },
  // 次期開発12月 #51829 end
}))
interface Errors {
  isError: boolean
  errorMsg: string
}
interface Props extends FieldProps {
  name?: string
  title: string
  item: string
  onChange?: (value: string) => void
  setHasError?: (isError: boolean) => void
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
  // 次期開発12月 #51829 end
}

const InputOnlyNumber = ({
  field,
  form,
  title,
  item,
  onChange,
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
  // 次期開発12月 #51829 end
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const {errors} = form
  const [errorss, setError] = useState([] as Errors[])
  const [errorsList, setErrorList] = useState([] as String[])

  // 次期開発12月 #51829 start
  const [errorChecked, setErrorChecked] = useState([] as Errors[])
  const [errorCheckedList,setErrorCheckedList] = useState([] as String[])
  const [nameValue,setNameValue] = useState('')
  useEffect(() => {
    if ((prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && selectDoubleFalg == true) {
      if (clearRecruitmentManageFlag) {
        setNameValue(form.values[name])
        setError([])
        setErrorList([])
        form.setFieldValue(name, '')
      } else {
        setError(errorChecked)
        setErrorList(errorCheckedList)
        form.setFieldValue(name, nameValue)
      }
    }
  },[clearRecruitmentManageFlag])
  // 次期開発12月 #51829 end
  const handleChange = (event: any) => {
    const errorS: Errors[] = []
    form.setFieldValue(name, event.target.value)
    const pattDecimal = /^-?(0|[1-9]\d*)(\.\d+)?$/
    const pattFloat = /^-?(0|[1-9]\d*)(\.\d{1,2})?$/
    if (event.target.value.length > magiContants.MCAXS340_MAXLENGTH && event.target.value > 0) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxLength({ max: magiContants.MCAXS340_MAXLENGTH }),
      })
    }
    if (event.target.value.length > magiContants.MCAXS340_MAXLENGTH_NEGATIVE && event.target.value < 0) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxLength({ max: magiContants.MCAXS340_MAXLENGTH }),
      })
    }
    if (event.target.value > magiContants.MCAXS340_MAXVALUE) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxValue({ max: magiContants.MCAXS340_MAXVALUE }),
      })
    }
    if (event.target.value < magiContants.MCAXS340_MINVALUE) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.minValue({ min: magiContants.MCAXS340_MINVALUE }),
      })
    }
    if(!(event.target.value === '' || pattDecimal.test(event.target.value))){
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.halfWidthNumber(),
      })
    } else if(!(event.target.value === '' || pattFloat.test(event.target.value))){
      errorS.push({
        isError: true,
        errorMsg: getMessage('MCAXS340-004'),
      })
    }
    if(errorS.length == 0){
      if (onChange) {
        onChange(event.target.value)
      }
    }
    setError(errorS)
    // 次期開発12月 #51829 start
    setErrorChecked(errorS)
    // 次期開発12月 #51829 end
    const errorLabels = errorS.map(i =>{
      return i.errorMsg
    })
    setErrorList(errorLabels)
    // 次期開発12月 #51829 start
    setErrorCheckedList(errorLabels)
    // 次期開発12月 #51829 end
  }

  return (
    <React.Fragment>
      {/* 次期開発12月 #51829 start */}
      <Grid container className={prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030' ? (selectDoubleFalg == false ? (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor) : (null != item && null != value && item.toString() === value.toString() && clearRecruitmentManageFlag == false ? classes.grid : classes.backgroundColorDeleted)) : (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor)}>
      {/* 次期開発12月 #51829 end */}
        <Grid item xs={12} className={classes.inputWithCounter}>
          <Grid item xs={3}>
            <Title
              title={title}
              className={`${classes.title} ${classes.fontWhite}`}
            />
          </Grid>
          <Title title='：' className={classes.fontWhite} />
          <Grid item xs={4}>
            <MuiTextField
              // 次期開発12月 #51829 start
              disabled={clearRecruitmentManageFlag}
              // 次期開発12月 #51829 end
              variant='outlined'
              fullWidth
              type='text'
              inputProps={{ spellCheck: false }}
              InputProps={{
                // 次期開発12月 #51829 start
                classes: clearRecruitmentManageFlag ? {input:classes.muiTextFieldStyleDeleted} : { input: classes.input },
                // 次期開発12月 #51829 end
              }}
              error={(errorss.length > 0 && errorss[0].isError) || Boolean(errors[name])}
              {...field}
              // 次期開発12月 #51829 start
              value={clearRecruitmentManageFlag ? '項目のデータを一括削除します' : value}
              // 次期開発12月 #51829 end
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography
                variant='h6'
                style={{ color: (theme.palette as any).white }}>
                {`（数値）`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.inputWithCounter1}>
          <Grid item xs={3}>
            <Title
              title={''}
              className={`${classes.title} ${classes.fontWhite}`}
            />
          </Grid>
          <Title title='' className={`${classes.fontWhite} ${classes.fontWhite1}`} />
          <Grid item xs={4}>
            { errors[name] ? <p className={classes.helperText}>{errors[name]}</p> : errorsList ? (<>
              {errorsList.map(info => <p className={classes.helperText}>{info}</p>)}
            </>): null }
          </Grid>
          <Grid item xs={5}>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InputOnlyNumber
