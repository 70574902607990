/**
 * MCAOS020CreateRequest validation
 */

import * as yup from 'yup'
import { validationMessageSet } from 'utils/contants'
import { miscRegexSet } from 'utils/regex'

export default yup.object().shape({

  // 面接場所名
  interviewLocationName: yup
    .string()
    .trim()
    .required(validationMessageSet.required)
    .max(30, validationMessageSet.maxLength),

  // 住所
  streetAddress: yup
    .string()
    .trim()
    .required(validationMessageSet.required)
    .max(50, validationMessageSet.maxLength),

  // 地図URL
  map: yup
  .string()
  // MCB1.5次開発 #9201 START
  .max(200, validationMessageSet.maxLength)
  // MCB1.5次開発 #9201 END
  .test('isHalfWidthAlphanumericSymbol', validationMessageSet.halfWidthAlphanumericSymbol, (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)),
});