import { getMessage } from "common/messageUtil";
import { magiContants } from "utils/contants";


const attachmentTypeList = [
  { name: 'attachmentExist', label: 'あり', id: '1' },
  { name: 'attachmentNot', label: 'なし', id: '2' },
];

const destinationTypeList = [
  { name: 'destinationNavi', label: 'マイページ', id: '1' },
  { name: 'destinationMail', label: 'e-mail', id: '2' },
];

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
];

const replyNecessaryCheckList = [
  { name: 'replyNecessaryCheckOn', label: '表示あり', id: '1' },
  { name: 'replyNecessaryCheckOff', label: '表示なし', id: '2' },
]

const initialValues = {
  searchTargetList: '0',
  searchWordText: '',
  reserveFromDate: '',
  reserveToDate: '',
  destination: {
    destinationNavi: '0',
    destinationMail: '0',
  },
  attachment: {
    attachmentExist: '0',
    attachmentNot: '0',
  },
  onlyBulkSend: '0',
  sort: '0',
  countPage: 1,
  optionFlag: '0',
  recruitmentManagementDivision: '',
  replyNecessaryFlag: {
    replyNecessaryCheckOn: '0',
    replyNecessaryCheckOff: '0',
  },
  replyLimitFromDate: '',
  replyLimitToDate: '',
};

const submitValueMCAZS030 = {
  searchTargetList: '0',
  searchWordText: '',
  reserveFromDate: '',
  reserveToDate: '',
  destinationNavi: '0',
  destinationMail: '0',
  attachmentExist: '0',
  attachmentNot: '0',
  onlyBulkSend: '0',
  sort: '0',
  countPage: 1,
  optionFlag: '0',
  recruitmentManagementDivision: '',
  replyNecessaryCheckOn: '0',
  replyNecessaryCheckOff: '0',
  replyLimitFromDate: '',
  replyLimitToDate: '',
};

const messageMap: { [key: string]: string } = {
  moveDraftMessage: getMessage(magiContants.MESSAGECODE_MCAZS030_003),
  messageSend: getMessage(magiContants.MESSAGECODE_MCAZS030_004),
};

const textMap = {
  '019': {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCAXS050-019'),
  }
}

export { initialValues, attachmentTypeList, destinationTypeList, messageMap, submitValueMCAZS030, templateOptionList, replyNecessaryCheckList, textMap };
