/**
 * MCAZS080MessageConfirmRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'
import { magiContants } from 'utils/contants'
import { isEmpty } from 'common/generalUtil'

export default (arg: any) => {

  const recruitmentManagementDivision: string = arg.recruitmentManagementDivision
  const destinationSelection: string = arg.destinationSelection
  const notificationSelect: string = arg.notificationSelect
  const action: number = arg.action
  const isDraft: boolean = arg.isDraft

  const validations = yup.object().shape({

    // [phase2] start
    // マイナビ退会者を送信対象者に含む
    sendToNonMember: yup
      .number(),
    // [phase2] end

    // 送信元会社名
    senderCompanyName: isDraft || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY)
      ? yup.string()
        .notRequired()
        .max(200, validationMessageSet.maxLength)
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value))
      : yup.string()
        .test('required', validationMessageSet.required, (value: string) => {
          if (isEmpty(value)) {
            return false
          }
          return !isEmpty(value.trim())
        })
        .max(200, validationMessageSet.maxLength)
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

    // 送信元メールアドレス
    senderMailAddress: destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
      ? yup.string()
        .notRequired()
      : isDraft
        ? yup.string()
          .notRequired()
          .max(256, validationMessageSet.maxLength)
          .test('isMail', validationMessageSet.email, (value: any) => miscRegexSet.email.test(value))
        : yup.string()
          .required(validationMessageSet.required)
          .max(256, validationMessageSet.maxLength)
          .test('isMail', validationMessageSet.email, (value: any) => miscRegexSet.email.test(value)),

    // 面接内容
    interviewDetail: isDraft || action === magiContants.SELECTION_NUMBER_NOT_PRESENT
      ? yup.string()
        .notRequired()
      : yup.string()
        .test('required', validationMessageSet.required, (value: string) => {
          if (isEmpty(value)) {
            return false
          }
          return !isEmpty(value.trim())
        })
        .max(10, validationMessageSet.maxLength),

    // 件名
    subject: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(200, validationMessageSet.maxLength)
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

    // 本文
    body: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ? 2000 : 1959, validationMessageSet.maxLength)
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),


    // 通知先メールアドレス
    reminder: notificationSelect === magiContants.NOTIFICATION_NOT_DESIRED || recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY)
      ? yup.string()
        .notRequired()
      : isDraft
        ? yup.string()
          .notRequired()
          .test('isEmails', validationMessageSet.email(), (value: string) => {
            if (value) {
              const isValid = value
                .split(',')
                .map(email => email.trim())
                .filter(v => !isEmpty(v))
                .find(v => !yup.string().test('isMail', validationMessageSet.email, (value: any) => miscRegexSet.email.test(value)).isValidSync(v))
              return !isValid
            } else {
              return true
            }
          })
          .max(160, validationMessageSet.maxLength)
        : yup.string()
          .required(validationMessageSet.required)
          .test('isEmails', validationMessageSet.email(), (value: string) => {
            if (value) {
              const isValid = value
                .split(',')
                .map(email => email.trim())
                .filter(v => !isEmpty(v))
                .find(v => !yup.string().test('isMail', validationMessageSet.email, (value: any) => miscRegexSet.email.test(value)).isValidSync(v))
              return !isValid
            } else {
              return true
            }
          })
          .max(160, validationMessageSet.maxLength),

    // ホームページタイトル1
    homepageTitle1: yup
      .string()
      .max(80, validationMessageSet.maxLength),

    // ホームページタイトル2
    homepageTitle2: yup
      .string()
      .max(80, validationMessageSet.maxLength),

    // ホームページタイトル3
    homepageTitle3: yup
      .string()
      .max(80, validationMessageSet.maxLength),

    // ホームページURL1
    homepageUrl1: yup
      .string()
      .url(validationMessageSet.url)
      .test('isHalfWidthAlphanumericSymbol', validationMessageSet.halfWidthAlphanumericSymbol, (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value))
      .max(256, validationMessageSet.maxLength),

    // ホームページURL2
    homepageUrl2: yup
      .string()
      .url(validationMessageSet.url)
      .test('isHalfWidthAlphanumericSymbol', validationMessageSet.halfWidthAlphanumericSymbol, (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value))
      .max(256, validationMessageSet.maxLength),

    // ホームページURL3
    homepageUrl3: yup
      .string()
      .url(validationMessageSet.url)
      .test('isHalfWidthAlphanumericSymbol', validationMessageSet.halfWidthAlphanumericSymbol, (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value))
      .max(256, validationMessageSet.maxLength),

    // 送信日時
    sendTime: yup
      .date()
      .nullable()
      .test(
        'isUnit',
        validationMessageSet.timeUnit(10),
        (value: Date) => {
          if (value) {
            return value.getMinutes() % 10 === 0
          }

          return true
        }
      ),
    //送信日時必須チェック
    sendTimeType: yup
      .string()
      .required(validationMessageSet.required),

    // 返信期限
    repliesLimit: yup
      .string()
      .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),
  })

  return validations
}