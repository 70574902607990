import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { initialCreateValues, textMap } from 'pages/MCAOS020/formConfig'
import { Formik, Form, FastField } from 'formik'
import MCAOS020CreateRequestValidation from 'validations/MCAOS020/MCAOS020CreateRequestValidation'
import { InputWithCounter, MapTextField, LoadingDialog } from 'sp/components'
import { RootState } from 'reducers'
import {
  setCreateDialogOpen,
  setInterviewCreate,
} from 'reducers/interviewReducer'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { onKeyDown } from 'common/generalUtil'
import { ModalDialog } from 'sp/components/ModalDialog'

const useStyles = makeStyles(theme => ({
  cancel: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    width: '130px',
    height: '40px',
  },
  mapURLButton: {
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
    height: '30px',
    fontSize: '12px',
  },
  iframe: {
    border: '1px solid gray',
    display: 'flex',
    justifyContent: 'center',
  },
  msg: {
    color: '#1A1A1A',
    marginTop: 5,
    marginLeft: 10,
  },
  fontResize: { fontSize: 12 },
  memoStyle: {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    color: '#1A1A1A',
  },
}))

interface Props {
  onClose: () => void
  screenId: string
}

const MCAOM020 = ({ onClose, screenId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('register')
  const [interviewLocation, setInterviewLocation] = useState<any>(null)
  const [map, setMap] = useState('')

  const createDialogOpen = useSelector(
    (state: RootState) => state.interview.createDialogOpen
  )
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )

  const handleCancel = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }
  const handleSubmit = (values: any) => {
    setConfirmDialogOpen(true)
    setType('register')
    values.recruitmentManagementDivision = recruitmentManagementDivision
    setInterviewLocation(values)
  }
  const handleDialogClose = () => {
    setConfirmDialogOpen(false)
  }
  const handleCreate = (resetForm: () => void) => {
    if (type === 'register') {
      dispatch(
        setInterviewCreate({ param: interviewLocation, screenId: screenId })
      )
    } else if (type === 'cancel') {
      dispatch(setCreateDialogOpen(false))
    }
    resetForm()
    onClose()
    setConfirmDialogOpen(false)
  }

  const realUrl = useSelector((state: RootState) => state.url)

  useEffect(() => {
    if (createDialogOpen === false) {
      setMap('')
    }
  }, [createDialogOpen])

  const handleClick = () => {
    const w = window.open(
      'about:blank',
      '_blank'
    )
    if (w != null) {
      w.location.href = realUrl.MCAO_MAP_SERCH_URL_OPEN
    }
  }

  const onBlurMapUrl =
    realUrl.MCAO_MAP_IMAGE_URL +
    '&size=' +
    magiContants.MCAO_MAP_IMAGE_WIDTH +
    'x' +
    magiContants.MCAO_MAP_IMAGE_HEIGHT
  const inputOnBlur = (event: any) => {
    let str = event.target.value || ''
    if (str != '') {
      setMap(onBlurMapUrl + str)
    } else {
      setMap('')
    }
  }

  return (
    <>
      {createDialogOpen ? <LoadingDialog className='loadingOverlay' /> : null}
      <ModalDialog isOpen={createDialogOpen} className='modal'>
        <div className='modal-content'>
          <div className='modal-title-wrap'>
            <h3 className='modal-title'>面接場所登録</h3>
            <span
              className='close-btn'
              onClick={() => {
                handleCancel()
              }}>
              <svg width='25' height='25' viewBox='0 0 50 50'>
                <path
                  id='form__modal_close_white'
                  data-name='form__modal_close'
                  d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
                  transform='translate(0 0)'
                  fill='#fff'
                />
              </svg>
            </span>
          </div>
          <Formik
            initialValues={initialCreateValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={MCAOS020CreateRequestValidation}
            render={({ resetForm }) => (
              <Form onKeyDown={onKeyDown} style={{ height: '100%' }}>
                <div className='modal-section'>
                  <div className='mb20'>
                    <div
                      className={classes.msg}
                      dangerouslySetInnerHTML={{
                        __html: getMessage(
                          magiContants.MESSAGECODE_MCAOS020_000
                        ),
                      }}
                    />
                  </div>
                  <div>
                    {'面接場所名　'}
                    <i className='modal-flow__badge_required'>必須</i>
                  </div>
                  <div className='form__body_modal'>
                    <div className='form__body_item_modal'>
                      <FastField
                        name='interviewLocationName'
                        component={InputWithCounter}
                        maxAmount={30}
                      />
                    </div>
                  </div>
                  <div>
                    {'住所　'}
                    <i className='modal-flow__badge_required'>必須</i>
                  </div>
                  <div className='form__body_modal'>
                    <div className='form__body_item_modal'>
                      <FastField
                        name='streetAddress'
                        component={InputWithCounter}
                        maxAmount={50}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='modal-section-btn'
                  style={{ position: 'fixed' }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleCancel()
                    }}
                    className='btn__cancel'>
                    {textMap['cancel'].cancel}
                  </Button>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='btn__blue_ok'>
                    {textMap['register'].submit}
                  </Button>
                </div>
                <Dialog
                  open={confirmDialogOpen}
                  disableScrollLock={true}
                  className={'dialog is-dialog-show'}>
                  <div className='dialog-content' style={{ width: '100%' }}>
                    <div className={'dialog-section'}>
                      {textMap[type].message}
                    </div>
                    <div className='dialog-section-btn'>
                      <button
                        onClick={handleDialogClose}
                        className='daialogCancelBtn'>
                        キャンセル
                      </button>
                      <button
                        onClick={() => handleCreate(resetForm)}
                        className='daialogConfirmBtn'>
                        OK
                      </button>
                    </div>
                  </div>
                </Dialog>
              </Form>
            )}
          />
        </div>
      </ModalDialog>
    </>
  )
}
export default MCAOM020
