import { useEffect,  useState} from 'react'

export const useScrolling = (delay: number) => {
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    let debounceTimer : NodeJS.Timeout;
    const eventHandler = () => {
      if (scrolling === false) {
        setScrolling(true);
      }
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setScrolling(false);
      }, delay);
    }
    window.addEventListener('scroll', eventHandler);
    return (() => {
      window.removeEventListener('scroll', eventHandler);
    })
  }, []);
  return scrolling;
}