import React, { useState } from 'react'
import { ModalDialog } from 'sp/components/ModalDialog'
import { FormModalCloseIcon, Contact } from 'sp/components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { signout } from 'reducers/globalMenuReducer'
import { setAlerted } from 'reducers/companyLoginReducer'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { ConfirmDialog, ConfirmDialogProps } from 'sp/components/ConfirmDialog'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'

interface Props {
  setMcaam050OpenFlag: (state: boolean) => void
  isOpen: boolean
}

const MCAAM050 = ({ setMcaam050OpenFlag, isOpen }: Props) => {
  const dispatch = useDispatch()
  const { companyName, fullName, companyId, companyAccountId } = useSelector(
    (state: RootState) => state.globalMenu
  )
  const { MYNAVI_JOB_CHANGE_URL } = useSelector((state: RootState) => state.url)

  // モーダル閉じる押下時
  const setMcaam050OpenFlag2 = () => {
    setMcaam050OpenFlag(false)
  }

  // メッセージ入力ダイアログコントロール
  const { isMessageInputDialogOpen } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<ConfirmDialogProps | undefined>()

  //ログアウトアイコン押下時
  const handleSignout = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    event.preventDefault()
    dispatch(signout({ companyAccountId }))
    dispatch(setAlerted(''))
  }

  return (
    <>
      <ModalDialog isOpen={isOpen} className='modal'>
        <div className={'modal-content'}>
          <div className={'modal-title-wrap'}>
            <span className={'close-btn'} onClick={setMcaam050OpenFlag2}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use xlinkHref={'#form__modal_close'}></use>
              </svg>
            </span>
          </div>
          <div className={'modal-section'}>
            <div className='modal-my-menu__head'>
              MIWSID {companyId} <br />
              {companyName}
              <br />
              {fullName}
            </div>
            <div className='modal-logout-btn'>
              <button
                type='button'
                className={`btn__logout`}
                onClick={handleSignout}>
                ログアウト
              </button>
            </div>
            <div className='modal-my-menu__ttl'>その他のサービス</div>
            <div className='modal-my-menu__text'>
              <a href={MYNAVI_JOB_CHANGE_URL} className='modal-my-menu__link'>
                マイナビ転職企業専用画面
              </a>
            </div>
            <div className={'modal-my-menu__ttl'}>お問い合わせ先</div>
            <Contact />
          </div>
        </div>
      </ModalDialog>
      <FormModalCloseIcon />
      {/* メッセージ有無チェック */}
      {confirmDialogConfig && <ConfirmDialog {...confirmDialogConfig} />}
    </>
  )
}

export default MCAAM050
