import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Area } from 'utils/misc'
import CalendarCell from './CalenderCell'
import { InterviewLocation } from 'pages/MCAZS080/viewConig'
import { ClickAwayListener } from '@material-ui/core'
moment.tz.setDefault('Asia/Tokyo')

export interface Reservation {
  name: string
  start: string
  end: string
  location: string
  charge: string
}

export interface CandidateDay {
  interviewDate: Date | null
  interviewLocation: string
  interviewLocationId: string
  charge: string
  datePickerRef: React.RefObject<DatePicker>
  containerRef: React.RefObject<HTMLDivElement>
  fromCalendar?: boolean
}

interface Props {
  allowedArea: Area[]
  notAllowedArea: Area[]
  reservationList: Reservation[]
  isPermitOther: boolean
  lengthLimit: number
  selected: CandidateDay[]
  setSelected: (selected: CandidateDay[]) => void
  interviewLocation: InterviewLocation[]
  handleToolTipReserved: (state: boolean) => void
  setCurrentReservationList: (state:Reservation[]) => void
  toolTipReserved: boolean
  currentReservationList: Reservation[]
}

const Calendar = ({
  allowedArea,
  notAllowedArea,
  reservationList,
  lengthLimit,
  selected,
  setSelected,
  interviewLocation,
  handleToolTipReserved,
  setCurrentReservationList,
  toolTipReserved,
  currentReservationList,
}: Props) => {
  const weekday = ['日', '月', '火', '水', '木', '金', '土']
  const now = new Date()
  let dates: string[] = []
  let showDates: Date[] = []
  for (let i = 0; i < 90; i++) {
    let showDate = new Date()
    showDate.setDate(now.getDate() + i)
    let month = showDate.getMonth() + 1
    let day = showDate.getDate()
    let dayofweek = showDate.getDay()
    let firstday = month + '/' + day + '(' + weekday[dayofweek] + ')'
    dates.push(firstday)
    showDates.push(showDate)
  }
  const timeSlots = [
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  const [displayDates, setDisplayDates] = useState<string[]>([])
  const [currentDays, setCurrentDays] = useState<string>('')
  const [showTime, setShowTime] = useState(false)

  useEffect(() => {
    setDisplayDates(dates.slice(currentIndex, currentIndex + 3))
    if (displayDates[1]) {
      const currentYear = showDates[currentIndex + 1].getFullYear()
      const [currentMonth, currentDay] = displayDates[1]
        .split('(')[0]
        .split('/')
      const currentDays = `${currentYear}/${currentMonth}/${currentDay}`
      setCurrentDays(currentDays)
    }
  }, [currentIndex, displayDates[1]])

  const handleNextThreeDays = () => {
    if (currentIndex + 3 < dates.length) {
      setCurrentIndex(currentIndex + 3)
    }
  }

  const handleNextWeek = () => {
    if (currentIndex + 7 + 3 < dates.length) {
      setCurrentIndex(currentIndex + 7)
    } else {
      setCurrentIndex(dates.length - 3)
    }
  }

  const handlePrevThreeDays = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3)
    }
  }

  const handlePrevWeek = () => {
    if (currentIndex - 7 >= 0) {
      setCurrentIndex(currentIndex - 7)
    } else {
      setCurrentIndex(0)
    }
  }

  const handleToolTip = () => {
    handleToolTipReserved(false)
    setCurrentReservationList([])
  }

  return (
    <div className='message-schedule__calendar fc fc-media-screen fc-direction-ltr fc-theme-standard'>
      <div className='fc-header-toolbar fc-toolbar fc-toolbar-ltr'>
        <div className='fc-toolbar-chunk'>
          <h2 className='fc-toolbar-title'>{currentDays}付近</h2>
        </div>
        <div className='fc-toolbar-chunk'></div>
        <div className='fc-toolbar-chunk'>
          <div className='fc-button-group'>
            <button
              onClick={handlePrevWeek}
              type='button'
              title=''
              aria-pressed='false'
              className='fc-prevWeek-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevrons-left' role='img'></span>
            </button>
            <button
              onClick={handlePrevThreeDays}
              type='button'
              title='Previous '
              aria-pressed='false'
              className='fc-prev-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevron-left' role='img'></span>
            </button>
            <button
              onClick={handleNextThreeDays}
              type='button'
              title='Next '
              aria-pressed='false'
              className='fc-next-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevron-right' role='img'></span>
            </button>
            <button
              onClick={handleNextWeek}
              type='button'
              title=''
              aria-pressed='false'
              className='fc-nextWeek-button fc-button fc-button-primary'>
              <span
                className='fc-icon fc-icon-chevrons-right'
                role='img'></span>
            </button>
          </div>
        </div>
      </div>
      <div className='fc-view-harness fc-view-harness-passive'>
        <ul className='message-schedule-calendar__legend'>
          <li className='message-schedule-calendar__icon-preferred'>
            応募者希望
          </li>
          <li className='message-schedule-calendar__icon-ng'>応募者NG</li>
          <li className='message-schedule-calendar__icon-candidateDate'>
            提示日
          </li>
          <li className='message-schedule-calendar__icon-planned'>予定あり</li>
        </ul>

        <div className='fc-timeGridThreeDay-view fc-view fc-timegrid'>
          <table className='fc-scrollgrid'>
            <thead role='rowgroup'>
              <tr
                role='presentation'
                className='fc-scrollgrid-section fc-scrollgrid-section-header  fc-scrollgrid-section-sticky'>
                <th role='presentation'>
                  <div className='fc-scroller-harness'>
                    <div
                      className='fc-scroller'
                      style={{ overflow: 'visible' }}>
                      <table role='presentation' className='fc-col-header '>
                        <colgroup>
                          <col style={{ width: '56px' }} />
                        </colgroup>
                        <thead role='presentation'>
                          <tr role='row'>
                            <th
                              aria-hidden='true'
                              className='fc-timegrid-axis'
                              style={{
                                borderTop: '1px solid #dfdfdf',
                                borderBottom: 'unset',
                              }}>
                              <div className='fc-timegrid-axis-frame'>時間</div>
                            </th>
                            {displayDates.map(date => (
                              <>
                                <th
                                  key={date}
                                  role='columnheader'
                                  className='fc-col-header-cell fc-day fc-day-tue fc-day-past'
                                  style={{
                                    borderTop: '1px solid #dfdfdf',
                                    borderBottom: 'unset',
                                  }}>
                                  <div className='fc-scrollgrid-sync-inner'>
                                    <a className='fc-col-header-cell-cushion'>
                                      {date}
                                    </a>
                                  </div>
                                </th>
                              </>
                            ))}
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody role='rowgroup'>
              <tr
                role='presentation'
                className='fc-scrollgrid-section fc-scrollgrid-section-body '>
                <td role='presentation'>
                  <div className='fc-scroller-harness'>
                    <div
                      className='fc-scroller'
                      style={{ overflow: 'visible' }}>
                      <div
                        className='fc-timegrid-body'
                        style={{ width: '338px' }}>
                        <div className='fc-timegrid-slots'>
                          <table
                            aria-hidden='true'
                            className=''
                            style={{ width: '338px' }}>
                            <colgroup>
                              <col style={{ width: '56px' }} />
                            </colgroup>
                              <ClickAwayListener onClickAway={handleToolTip} >
                            <tbody>
                                {timeSlots.slice(0, 18).map(slot => (
                                  <tr>
                                    <td
                                      className={
                                        slot.endsWith('00')
                                          ? 'fc-timegrid-slot fc-timegrid-slot-label fc-scrollgrid-shrink'
                                          : 'fc-timegrid-slot fc-timegrid-slot-label fc-timegrid-slot-minor'
                                      }
                                      style={{
                                        borderTop: slot.endsWith('00')
                                          ? ''
                                          : '1px solid #dfdfdf',
                                        borderBottom: slot.endsWith('00')
                                          ? '1px solid #dfdfdf'
                                          : '',
                                      }}>
                                      {slot.endsWith('00') && (
                                        <div className='fc-timegrid-slot-label-frame fc-scrollgrid-shrink-frame'>
                                          <div className='fc-timegrid-slot-label-cushion fc-scrollgrid-shrink-cushion'>
                                            {slot}
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    {displayDates.map((date, index) => (
                                      <CalendarCell
                                        allowedArea={allowedArea}
                                        notAllowedArea={notAllowedArea}
                                        reservationList={reservationList}
                                        lengthLimit={lengthLimit}
                                        selected={selected}
                                        setSelected={setSelected}
                                        interviewLocation={interviewLocation}
                                        date={date}
                                        slot={slot}
                                        year={showDates[index + currentIndex].getFullYear()}
                                        handleToolTipReserved={handleToolTipReserved}
                                        setCurrentReservationList={setCurrentReservationList}
                                        toolTipReserved={toolTipReserved}
                                        currentReservationList={currentReservationList}
                                      />
                                    ))}
                                  </tr>
                                ))}

                                {showTime &&
                                  timeSlots.slice(18).map(slot => (
                                    <tr>
                                      <td
                                        className={
                                          slot.endsWith('00')
                                            ? 'fc-timegrid-slot fc-timegrid-slot-label fc-scrollgrid-shrink'
                                            : 'fc-timegrid-slot fc-timegrid-slot-label fc-timegrid-slot-minor'
                                        }
                                        style={{
                                          borderTop: slot.endsWith('00')
                                            ? ''
                                            : '1px solid #dfdfdf',
                                          borderBottom: slot.endsWith('00')
                                            ? '1px solid #dfdfdf'
                                            : '',
                                        }}>
                                        {slot.endsWith('00') && (
                                          <div className='fc-timegrid-slot-label-frame fc-scrollgrid-shrink-frame'>
                                            <div className='fc-timegrid-slot-label-cushion fc-scrollgrid-shrink-cushion'>
                                              {slot}
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                      {displayDates.map((date, index) => (
                                        <CalendarCell
                                          allowedArea={allowedArea}
                                          notAllowedArea={notAllowedArea}
                                          reservationList={reservationList}
                                          lengthLimit={lengthLimit}
                                          selected={selected}
                                          setSelected={setSelected}
                                          interviewLocation={interviewLocation}
                                          date={date}
                                          slot={slot}
                                          year={showDates[index + currentIndex].getFullYear()}
                                          handleToolTipReserved={handleToolTipReserved}
                                          setCurrentReservationList={setCurrentReservationList}
                                          toolTipReserved={toolTipReserved}
                                          currentReservationList={currentReservationList}
                                        />

                                      ))}
                                    </tr>
                                  ))}
                            </tbody>
                              </ClickAwayListener>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <button
            type='button'
            className={
              showTime ? 'btn__calendar-more is-hidden' : 'btn__calendar-more'
            }
            onClick={() => setShowTime(true)}>
            18:00～22:00を表示
          </button>
        </div>

        <ul className='message-schedule-calendar__legend'>
          <li className='message-schedule-calendar__icon-preferred'>
            応募者希望
          </li>
          <li className='message-schedule-calendar__icon-ng'>応募者NG</li>
          <li className='message-schedule-calendar__icon-candidateDate'>
            提示日
          </li>
          <li className='message-schedule-calendar__icon-planned'>予定あり</li>
        </ul>
      </div>
      <div className='fc-header-toolbar fc-toolbar fc-toolbar-ltr'>
        <div className='fc-toolbar-chunk'>
          <h2 className='fc-toolbar-title'>{currentDays}付近</h2>
        </div>
        <div className='fc-toolbar-chunk'></div>
        <div className='fc-toolbar-chunk'>
          <div className='fc-button-group'>
            <button
              onClick={handlePrevWeek}
              type='button'
              title=''
              aria-pressed='false'
              className='fc-prevWeek-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevrons-left' role='img'></span>
            </button>
            <button
              onClick={handlePrevThreeDays}
              type='button'
              title='Previous '
              aria-pressed='false'
              className='fc-prev-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevron-left' role='img'></span>
            </button>
            <button
              onClick={handleNextThreeDays}
              type='button'
              title='Next '
              aria-pressed='false'
              className='fc-next-button fc-button fc-button-primary'>
              <span className='fc-icon fc-icon-chevron-right' role='img'></span>
            </button>
            <button
              onClick={handleNextWeek}
              type='button'
              title=''
              aria-pressed='false'
              className='fc-nextWeek-button fc-button fc-button-primary'>
              <span
                className='fc-icon fc-icon-chevrons-right'
                role='img'></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Calendar)
