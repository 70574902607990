import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { Field } from 'formik'
import {
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { isEmpty } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import { FormHandler } from '.'
import { changeTransmission, inputSendTimer } from 'reducers/messageSendReducer'
import { DatePicker } from 'sp/componentsHsc'
import { useErrorStyle } from 'sp/componentsHsc'
import { makeStyles } from '@material-ui/core/styles'

const name = 'sendTime'

interface Props {
  form: FormHandler
  isInitialized: boolean
  destinationSelection: string
  interviewWebUrlSendFlag: string
  setTimeState: (state: boolean) => void
}

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
}))

const SendTime = ({
  form,
  isInitialized,
  destinationSelection,
  interviewWebUrlSendFlag,
  setTimeState,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [state, setState] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const sendTimeInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.sendTimeInput
  )
  const targetList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const messageType = useSelector(
    (state: RootState) => state.messageSend.initResult.messageType
  )
  const action = useSelector(
    (state: RootState) => state.messageSend.messageInitRequest.action
  )
  const adjustmentInterviewScheduleAdjustmentId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.hiddenItems
        .adjustmentInterviewScheduleAdjustmentId
  )
  const interviewScheduleAdjustmentEntry = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.interviewScheduleAdjustmentEntry
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const draftMessageSendId = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.draftMessageSendId
  )
  const errorClasses = useErrorStyle()

  useEffect(() => {
    if (sendTimeInput.transmission === '1') {
      setState(false)
    }
  }, [])

  useEffect(() => {
    if (isInitialized && form.values.sendTime) {
      const value = form.values.sendTime
      dispatch(inputSendTimer({ value }))
    }
  }, [form.values.sendTime])

  useEffect(() => {
    if (
      destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
    ) {
      if (
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        setDisabled(false)
        return
      }
      if (!isEmpty(action)) {
        switch (action) {
          case magiContants.ACTION_CHANGE_POSSIBLE:
          case magiContants.ACTION_CHANGE_INTERVIEW:
          case magiContants.ACTION_FIX_INTERVIEW:
          case magiContants.ACTION_WITHDRAW_INTERVIEW:
            setDisabled(true)
            break
          case magiContants.ACTION_READJUST_INTERVIEW:
            if (
              manipulationSelection !==
              magiContants.SELECTION_NUMBER_NOT_PRESENT
            ) {
              setDisabled(true)
            }
            break
          default:
            break;
        }
        return
      }
      switch (manipulationSelection) {
        case magiContants.SELECTION_NUMBER_NOT_PRESENT:
          setDisabled(false)
          break
        case magiContants.SELECTION_NUMBER_PRESENT:
        case magiContants.SELECTION_NUMBER_OTHER:
          if (
            adjustmentInterviewScheduleAdjustmentId &&
            adjustmentInterviewScheduleAdjustmentId.length > 0
          ) {
            if (
              draftMessageSendId &&
              messageType &&
              messageType.length > 0 &&
              messageType[0].messageType === '03'
            ) {
              setDisabled(false)
            } else {
              setDisabled(true)
            }
          } else {
            const selectionManagementIdList = targetList.map(
              item => item.selectionManagementId
            )
            const filterdList = interviewScheduleAdjustmentEntry.filter(item =>
              selectionManagementIdList.includes(
                String(item.selectionManagementId)
              )
            )
            if (filterdList && filterdList.length > 0) {
              setDisabled(true)
            } else {
              setDisabled(false)
            }
          }
          break
        case magiContants.SELECTION_NUMBER_FIX:
        case magiContants.SELECTION_NUMBER_SEND:
          setDisabled(true)
          break
      }
    } else {
      setDisabled(false)
    }
  }, [manipulationSelection, action, destinationSelection])

  const enableInput = () => {
    setState(false)
  }

  const handleClick = (e: any) => {
    form.setFieldValue(name, null)
    setState(true)
  }

  const handleChangeTransmission = (value: string) => {
    dispatch(changeTransmission({ value }))
  }

  useEffect(() => {
    form.setFieldValue('sendTimeType', sendTimeInput.transmission)
  }, [sendTimeInput.transmission])

  useEffect(() => {
    setTimeState(disabled)
  }, [disabled])

  const [toolTip, setToolTip] = useState(false)

  const handleToolTip = () => {
    setToolTip(false)
  }

  return (
    <div id='send-time' className='message-select-block'>
      <div className='form__ttl_modal_3 mb12'>
        送信日時
        <ClickAwayListener onClickAway={handleToolTip}>
          <svg
            className='form__body_ttl_icon mr10'
            focusable='false'
            viewBox='0 0 24 24'
            aria-hidden='true'
            onClick={() => setToolTip(!toolTip)}>
            <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
          </svg>
        </ClickAwayListener>
        <i className='modal-flow__badge_required'>必須</i>
      </div>
    {form.touched.sendTimeType && form.errors.sendTimeType && (
      <p className={'message-input__alert'} style={{ margin: '8px 12px 8px 12px' }}>
        {form.errors.sendTimeType}
      </p>
    )}
      <div className='message-input__inner'>
        <FormControl>
          <RadioGroup
            name='sendTimeType'
            value={sendTimeInput.transmission}
            aria-label='sendTypeRadioGroup'
            onChange={e => handleChangeTransmission(e.target.value)}>
            <div className='mb8'>
              <label className='form__label_side'>
                <div className='form__label_side'>
                  <FormControlLabel
                    value='0'
                    control={
                      <Radio
                        color='primary'
                        onClick={handleClick}
                        className='form__radio'
                        style={{ padding: 0 }}
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_off'>
                            <use xlinkHref='#form__radio_off'></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_on'>
                            <use xlinkHref='#form__radio_on'></use>
                          </svg>
                        }
                      />
                    }
                    label={
                      <span
                        className='message-select-radio__text'
                        style={{
                          fontWeight:
                            sendTimeInput.transmission === '0'
                              ? 'bold'
                              : 'unset',
                        }}>
                        すぐに送信する
                      </span>
                    }
                  />
                </div>
              </label>
            </div>
            <div className='mb10'>
              <label className='form__label_side mb15'>
                <div className='form__label_side'>
                  <FormControlLabel
                    value='1'
                    className={disabled ? classes.disabled : ''}
                    disabled={disabled}
                    control={
                      <Radio
                        color='primary'
                        onClick={enableInput}
                        className='form__radio'
                        style={{ padding: 0 }}
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_off'>
                            <use xlinkHref='#form__radio_off'></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_on'>
                            <use xlinkHref='#form__radio_on'></use>
                          </svg>
                        }
                      />
                    }
                    label={
                      <span
                        className='message-select-radio__text'
                        style={{
                          fontWeight:
                            sendTimeInput.transmission === '1'
                              ? 'bold'
                              : 'unset',
                        }}>
                        タイマー送信する
                      </span>
                    }
                  />
                </div>
              </label>
              <div style={{ marginBottom: '0px' }}>
                <Field
                  name={name}
                  disabled={state || disabled}
                  component={DatePicker}
                  validateTimeUnit
                  showPlaceholder={false}
                  labelName='タイマーは'
                  selected={sendTimeInput.sendPlanTime}
                />
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      {/* 情報アイコン（送信日時） */}
      {toolTip && (
        <div className='tooltip is-tooltip-show'>
          <p>
            {'タイマー送信すると、指定した日時に自動送信されます。'}
            <br />
            {'メッセージの送信予約は30日後まで指定可能です。'}
          </p>
        </div>
      )}
      {/* 情報アイコン（送信日時） */}

      {/* <!-- ラジオボタン --> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_on'
            data-name='form__radio_on'
            transform='translate(-78 -2778)'>
            <g
              transform='translate(78 2778)'
              fill='#fff'
              stroke='#3380cc'
              stroke-width='1'>
              <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
              <circle cx='10.5' cy='10.5' r='10' fill='none' />
            </g>
            <circle
              cx='7'
              cy='7'
              r='7'
              transform='translate(81.5 2781.5)'
              fill='#3380cc'
            />
          </g>
        </defs>
        <use xlinkHref='#form__radio_on'></use>
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_off'
            data-name='form__radio_off'
            fill='#fff'
            stroke='#1a1a1a'
            stroke-width='1'>
            <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
            <circle cx='10.5' cy='10.5' r='10' fill='none' />
          </g>
        </defs>
        <use xlinkHref='#form__radio_off'></use>
      </svg>
      {/* <!-- /ラジオボタン --> */}
    </div>
  )
}

export default SendTime
