import { Tooltip } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import candidateDateIcon from 'images/candidateDate.png'
import planned from 'images/planned.png'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { formatTemplate, unitSet } from 'utils/contants'
import {
  Area
} from 'utils/misc'
import { CandidateDay, Reservation } from './Calendar'
import { InterviewLocation } from './viewConig'
import { Light } from 'react-syntax-highlighter'
import { BooleanTypeKnob } from '@storybook/addon-knobs/dist/components/types'
moment.tz.setDefault('Asia/Tokyo')

interface Props {
  allowedArea: Area[]
  notAllowedArea: Area[]
  reservationList: Reservation[]
  isSelected?: boolean
  setSelectedDate: (date: Date, e: any) => void
  date: string
  slot: string
  year: number
}

const LightTooltip = withStyles((theme: Theme) => ({
  popper: {
    '&[x-out-of-boundaries]': {
      display: 'none',
    },
  },
  tooltip: {
    backgroundColor: '#6e6e6e',
    color: '#f0f0f0',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  badge: {
    color: 'white',
    backgroundColor: 'orangered',
    padding: '1px 6px',
    borderRadius: '10px',
  },
  bgBlack: {
    backgroundColor: 'black',
  },
  event: {
    borderColor: 'transparent',
    backgroundColor: 'transparent !important',
    height: '20px',
    textAlign: 'center',
    marginRight: '0 !important',
    left: '0% !important',
    zIndex: '99 !important' as any,
  },
  eventContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipName: {
    fontWeight: 'bold',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  bgOrangered: {
    backgroundColor: 'orangered',
  },
}))

const CalendarCell = ({
  allowedArea,
  notAllowedArea,
  reservationList,
  setSelectedDate,
  isSelected,
  date,
  slot,
  year,
}: Props) => {
  const classes = useStyles()
  // 選択済みフラグ
  const [isFlagShow, setFlagShow] = useState(false)

  //　応募者希望日
  const [isAllowed, setAllowed] = useState(false)

  //　応募者NG
  const [isNotAllowed, setNotAllowed] = useState(false)

  //　予定あり
  const [isReserved, setReserved] = useState(false)
  const [reservationCount, setReservationCount] = useState(Number)
  const [showReservationList, setShoeReservationList] = useState<Reservation[]>([])
  const [candidateCount, setCandidateCount] = useState(Number)

  const month = date.split('/')[0].padStart(2, '0')
  const day = String(date.split('/')[1])
    .split('(')[0]
    .padStart(2, '0')
  const currentDate = new Date(
    year,
    Number(month) - 1,
    Number(day),
    Number(slot.split(':')[0]),
    Number(slot.split(':')[1]),
    0,
    0
  )

  const currentDayTimeString = year + '-' + month + '-' + day + 'T' + slot

  useEffect(() => {
    //応募者希望日の表示
    setAllowed(false)
    if (allowedArea.length) {
      allowedArea.forEach(area => {
        const start =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.startDate
        const end =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.endDate
        if (start <= currentDayTimeString && end > currentDayTimeString) {
          setAllowed(true)
        }
      })
    }
  }, [allowedArea])

  useEffect(() => {
    //応募者NG日程の表示
    if (notAllowedArea.length) {
      notAllowedArea.forEach(area => {
        const start =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.startDate
        const end =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.endDate

        if (start <= currentDayTimeString && end >= currentDayTimeString) {
          setNotAllowed(true)
        }
      })
    }

    // 予定あり日程の表示
    let count = 0
    if (reservationList.length) {
      reservationList.forEach(item => {
        if (item.start <= currentDayTimeString && item.end > currentDayTimeString) {
          setReserved(true)
          setShoeReservationList((showReservationList) => [
            ...showReservationList,
            {
              name: item.name,
              start: item.start,
              end: item.end,
              location: item.location,
              charge: item.charge
            }
          ])
          count = count + 1
          setReservationCount(count)
        }
      })
    }
  }, [])

  useEffect(() => {
    // 提示日に旗を立てる    
    let count = 0
    setFlagShow(false)
    if (isSelected) {
      setFlagShow(true)
      count = count + 1
      setCandidateCount(count)
    }
  }, [isSelected])

  //日付のセルをクリックしたときの動作
  const handleSlotClick = (e: any) => {
    const now = new Date();
    if (!isFlagShow && now <= currentDate) {
      //候補日を提示していない日程を選択したときに候補日を追加
      //現在日時より前の日程は選択不可
      setSelectedDate(currentDate, e)
    }
  }

  return (
    <td
      className={
        isAllowed
          ? 'allowedDayStyle'
          : isNotAllowed
            ? 'notPossibleDayStyle'
            : 'dayStyle'
      }
      onClick={handleSlotClick}>
      {//公募日の一覧に選択した日付が入っていなければ候補日に追加
        isFlagShow ? (
          <>
            <LightTooltip
              placement='left'
              title='candidateDate'
            >
              <div className={classes.eventContainer + `${isAllowed ? ' alreadyClicked' : " alreadyClicked"}`}>
                <img className='photo' src={candidateDateIcon} alt='flag' />
                {candidateCount !== 1 ? (
                  <span className={`${classes.badge} ${classes.bgOrangered}`}>
                    {candidateCount}
                  </span>
                ) : (
                  <></>
                )
                }
              </div>
            </LightTooltip>
            {/* <img className='photo' src={candidateDateIcon} alt='flag' /> */}
          </>
        ) : (
          <></>
        )}
      {
        isReserved ? (
          <LightTooltip
            placement='left'
            title={showReservationList.map(
              (i: any, index: number) => (
                <React.Fragment key={index}>
                  {index !== 0 && <br />}
                  <p className={classes.tooltipName}>{i.name}</p>
                  <p>
                    {String(i.start).split('T')[1]}～{String(i.end).split('T')[1]}
                  </p>
                  <p>{i.location}</p>
                  <p>担当者：{i.charge}</p>
                </React.Fragment>
              )
            )}
          >
            <div className={classes.eventContainer}>
              <img className='photo' src={planned} alt='flag' />
              {reservationCount !== 1 ? (
                <span className={`${classes.badge} ${classes.bgBlack}`}>
                  {reservationCount}
                </span>
              ) : (
                <></>
              )
              }
            </div>
          </LightTooltip>
        ) : (
          <></>
        )
      }
    </td >
  )
}

export default React.memo(CalendarCell)
