import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, RootRef } from '@material-ui/core'
import { FieldProps } from 'formik'
import { TextareaAutosize } from 'sp/components'

const useStyles = makeStyles(theme => ({
  textCounter: {
    float: 'right',
    minWidth: 120,
    textAlign: 'right',
    marginTop: theme.spacing(-0.5),
    '& h6': {
      fontWeight: 'normal',
    },
  },
  multiTextWithCounter: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: theme.spacing(30),
  },
  nonBorder: {
    border: 'none!important' as any,
    padding: '0!important' as any,
    marginTop: '0!important' as any,
  },
}))

interface Props extends FieldProps {
  className: any
  maxAmount: number
  onChange?: (value: string) => void
  onBlur?: () => void
  innerRef?: (instance: any) => void
}

const MessageMultiTextWithCounter = ({
  field,
  form,
  maxAmount,
  onChange,
  onBlur,
  innerRef,
}: Props) => {
  const classes = useStyles()
  const textArea = useRef<HTMLTextAreaElement>(null)
  const { name, value } = field
  const handleChangeTextAreaValue = (event: any) => {
    if (3000 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value)
    }
  }
  const handleBlur = () => {
    if (onBlur) {
      onBlur()
    }
    form.setFieldTouched(name, true)
  }

  return (
    <React.Fragment>
      <Grid container className={`${classes.nonBorder} show`}>
        <Grid item xs={12}>
          <RootRef rootRef={textArea}>
            <>
              <TextareaAutosize
                field={{
                  ...field,
                  onChange: handleChangeTextAreaValue,
                  onBlur: handleBlur,
                }}
                form={form}
                label=''
                aria-label='empty textarea'
                className={classes.multiTextWithCounter}
                innerRef={innerRef}
              />
              <Grid container justify='flex-end'>
                <Grid item className={classes.textCounter}>
                  <Typography gutterBottom variant='h6'>
                    {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </RootRef>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MessageMultiTextWithCounter
