import React from "react";
import { isMobile } from "react-device-detect";
import RootPc from "RootPc";
import RootSp from "sp/RootSp";

const Root = () => (
  <React.Fragment>

    {//window.innerWidth > 768 ? <RootPc /> : <RootSp />
      isMobile ? <RootSp /> : <RootPc /> 
    }
  </React.Fragment>
);

export default Root;