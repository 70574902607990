export default {
  root: {
    minHeight: 'inherit',
    '&$expanded': {
      minHeight: 'inherit',
    },
    // height: '40px',
    // minHeight: '40px',
    // background: '#E0E0E0',
    // '&:hover': {
    //   background: '#c1d1eb',
    // },
  },
  content: {
    margin: 'inherit',
    '&$expanded': {
      margin: 'inherit',
    },
  },
}
