import { routeList } from 'routes/routes'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { JudgeUrl } from '.'
import history from 'utils/history'

export const url = [
  // '/entryDetail',
  // '/applicantDetailForJobChange',
  '/applicantDetailForEmplyment',
  '/MCAVS020',
  // '/messageTab/MCAZS070',
  '/dummy',
  '/MCAXS121',
  '/MCAXS130',
  '/messageSend',
  '/commonSettingsMenu/MCAXS230',
  '/MCAXS240',
  '/redirect',
  '/MCAXS640',
  '/commonSettingsMenu/MCAXS650',
  '/newLoginId',
  '/duplicateLogin',
  '/myCareerBoxDataLink/MCBCS010', //MCBCS010_My CareerBoxデータ連携
  '/myCareerBoxDataLink/MCBCS020', //MCBCS020_My CareerBoxデータ連携完了
  '/MCBCS030', //MCBCS030_My CareerBox連携対象一覧CSV作成中
  '/commonSettingsMenu/MCBCS040', //MCBCS040_My CareerBox連携対象一覧CSVダウンロード
  '/mcbMessageSend', //MCAZS220_My CareerBoxメッセージ入力
  '/changeTermRequest', // MCBリプレース MCBHS060
  '/forcedStopSubmissionRequest', // MCBHS090_提出リクエスト強制停止
  // MCBリプレース MCBHS010 START
  '/submissionRequestSend',
  // MCBリプレース MCBHS010 END
  // MCB リプレース B票#538対応 start
  '/messageTab/MCAZS210', // メッセージ詳細(My CareerBox)モックアップ
  '/submissionRequestManagementTab/MCBHS050', //提出依頼メッセージ詳細
  // MCB リプレース B票#538対応 end
]

export const windowsOpenUrl = [
  '/entryDetail',
  '/applicantDetailForJobChange',
  '/applicantDetailForEmplyment',
  '/MCAVS020',
  '/messageTab/MCAZS070',
  '/MCAXS121',
  '/MCAXS130',
  '/messageSend',
  '/pdf',
  '/myCareerBoxDataLink',
  '/MCBCS030',
  '/commonSettingsMenu/MCBCS040',
  '/mcbMessageSend',
  '/changeTermRequest',
  '/forcedStopSubmissionRequest',
]
// #5721 start
// パラメータ付きURL
export const paramLoginUrl = [
  'entryDetail?',
  'applicantDetailForEmplyment?',
  'MCAZS070?',
  'pdf/MCAXS151?',
]
// パラメータ付きURLのチェック
export const paramLoginUrlCheck = () => {
  let count = 0
  paramLoginUrl.forEach(u => {
    if (window.location.href.includes(u)) {
      count++
    }
  })
  if (count > 0) {
    return true
  } else {
    return false
  }
}
// #5721 end

// 離脱チェック系
export const promptUrl = [
  routeList.password,
  routeList.jobSeekerNameInput,
  routeList.jobSeekerLogIn,
  routeList.jobSeekerInfoInsertConfirm,
  routeList.errorReport,
]
// 離脱しないが離脱チェックを行いたい画面
export const specialPromptUrlType1 = [routeList.password]

// 年度と関係なく表示すべき画面
export const forceDisplayUrl = [
  routeList.errorReport,
  routeList.errorReportComplete,
  routeList.duplicateLogin,
  routeList.interruption,
  routeList.newLoginId,
  routeList.envCharManual,
  routeList.networkError,
  routeList.notFound,
  routeList.error,
]

// 転職March #75621 START
// 切り替え時メッセージ
const marchModeChangeMessage1 = new Map([
  [
    routeList.password,
    getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003),
  ],
  [
    routeList.errorReport,
    getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003),
  ],
  [
    routeList.entryInsEdit,
    getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003),
  ],
  [
    routeList.notAdoptedNotification,
    getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003),
  ],
])

// 強制エントリー一覧へ遷移ケース
export const caseOfToEntryListUrl = [
  routeList.commonSettingsMenu,
  routeList.password,
  routeList.account,
  routeList.recruitGroup,
  routeList.alertPreferenceSettings,
  routeList.recruitFlag,
  routeList.errorReport,
  routeList.errorReportComplete,
  routeList.notAdoptedNotification,
  routeList.entryInsEdit,
]

export const getMarchModeChangeMessage1 = (url: any) => {
  let result = marchModeChangeMessage1.get(url)
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_009)
  return result
}
export const getMarchModeChangeMessage2 = (url: any) => {
  let result = undefined
  // 別ウィンドウ
  if (
    (window.opener && window.opener !== window) ||
    JudgeUrl() ||
    routeList.newLoginId == history.location.pathname ||
    routeList.entryDetail == history.location.pathname ||
    routeList.applicantDetailForJobChange == history.location.pathname
  ) {
    result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_011)
  } else {
    if (document.querySelector('.is-modal-show')) {
      result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_012)
    }
  }
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_010)
  return result
}
// 転職March #75621 END
