import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import Select, {
  components as componentsML,
  MenuListComponentProps,
} from 'react-select'
import { useErrorStyle } from 'sp/components'

export interface OptionType {
  label: string
  value: string
  sysVersionNumber: string
  sysVersionNumber1: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlSize: {
      width: '95%',
      marginTop: '0px',
    },
  })
)

function MenuList(props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div style={{ display: 'inline-block', minWidth: '100%' }}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList,
}

interface Props extends FieldProps {
  options: OptionType[]
  initId: string
}
export function IntegrationReactSelect({
  field,
  form,
  initId,
  options,
}: Props) {
  const { errors, touched } = form
  const { name, value } = field
  const classes = useStyles()
  const errorclasses = useErrorStyle()
  const optionZero = {
    label: '（以下から選択）',
    value: '',
    sysVersionNumber: '',
    sysVersionNumber1: '',
  } as OptionType
  const optionList = [optionZero, ...options]
  const [menuValue, setMenuValue] = useState(optionZero)
  const selectStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      fontSize: '1.6rem',
      borderStyle: 'none',
      borderColor:
        errors[name] && touched[name] ? '#e53935' : 'rgb(204 204 204)',
      boxShadow: '0px 0px 0px 0px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: 'rgb(128,128,128)',
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
      paddingBottom: '0px',
      paddingTop: '0px',
      fontSize: '1.6rem',
      '& input': {
        height: '20px',
        lineHeight: '18px',
      },
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: '1.6rem',
      wordBreak: 'break-all',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1400 }),
  }

  useEffect(() => {
    if (initId) {
      form.setFieldValue(name, initId)
      form.setFieldValue('internalId', initId)
      options.find(item => {
        if (item.value == initId) {
          form.setFieldValue('nameCheck', item.label)
          form.setFieldValue('sysVersionNumber', item.sysVersionNumber)
          form.setFieldValue('sysVersionNumber1', item.sysVersionNumber1)
        }
        return item.value == initId
      })
    }
  }, [options])

  const handleChange = (e: any) => {
    if (e.value !== value) {
      if (e.value) {
        options.find(item => {
          if (item.value == e.value) {
            form.setFieldValue('nameCheck', item.label)
            form.setFieldValue('sysVersionNumber', item.sysVersionNumber)
            form.setFieldValue('sysVersionNumber1', item.sysVersionNumber1)
          }
          return item.value == e.value
        })
        form.setFieldValue(name, e.value)
        form.setFieldValue('internalId', e.value)
      } else {
        form.setFieldValue(name, '')
        form.setFieldValue('nameCheck', '')
        form.setFieldValue('internalId', '')
        form.setFieldValue('sysVersionNumber', '')
        form.setFieldValue('sysVersionNumber1', '')
      }
    }
  }

  return (
    <div className='form__control' aria-disabled={
      (form.values.selectRadio === '1' || form.values.selectRadio === '2')
        ? true
        : false
    }
    >
      <Select
        classes={classes}
        styles={selectStyles}
        options={optionList}
        components={components}
        isClearable={false}
        value={optionList.filter(i => i.value == value)}
        defaultValue={optionList.filter(i => i.value == value)}
        placeholder={''}
        onChange={handleChange}
        isSearchable={true}
        filterOption={customSelectOptions.filterOption}
        noOptionsMessage={({ inputValue }) => {
          return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
        }}
        menuPlacement={'auto'}
        isDisabled={
          form.values.selectRadio === '1' || form.values.selectRadio === '2'
            ? true
            : false
        }
        menuPortalTarget={null}
        menuPosition={'absolute'}
      />
    </div>
  )
}
