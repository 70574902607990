import {
  all,
  call,
  select,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'

import {
  // MCAZS010 START
  initReceiveSelect,
  setDisplaySelectionResults,
  setDisplayProgressResults,
  setMessageReceiveList,
  setMessageReceiveListSearchCondition,
  setMessageReceiveListCount,
  receiveToTrashbox,
  setReceiveFromDate,
  // phase2 Start
  selectReceiveCount,
  selectReserveCount,
  selectDraftCount,
  setReceiveCount,
  setReserveCount,
  setDraftCount,
  getinitAreaOutBox,
  getinitAreaReceive,
  getinitAreaReservation,
  getinitAreaDraft,
  getinitAreaDustBox,
  // phase2 End
  sendMessageMCAZS010,
  // MCAZS010 END
  // MCAZS030 START
  initSelectMCAZS030,
  setMessageReservationListResults,
  setMessageReservationListAllCount,
  setMessageReservationListSearchCondition,
  deleteMessageMCAZS030,
  sendMessageMCAZS030,
  setReserveFromDate,
  // MCAZS030 END
  setMessageDraftListResults,
  setMessageDraftListSearchCondition,
  setMessageDraftListAllCount,
  initSelect,
  deleteMessage,
  setDeleteFromDate,
  // MCAZS020 START
  outBoxInit,
  setMessageOutBoxListResults,
  setMessageOutBoxListAllCount,
  setMessageOutBoxListSearchCondition,
  deleteOutBoxMessage,
  setSendMessageFlag,
  getSendMessageFlag,
  setSentFromDate,
  // MCAZS020 END
  setMessageDustboxListResults,
  deleteDustboxMessage,
  setMessageDustboxListAllCount,
  setMessageDustboxListSearchCondition,
  undoDustboxMessage,
  initDustboxSelect,
  setMessageFlagResult,
  getFailSelectionManagementIdBulkList,
  setUpdateFromDate,
  getFailSelectionManagementIdBulkListMCAZS030,
  getSelectionManagementIdBulkListMCAZS020,
  getSelectionManagementIdBulkListMCAZS030,
  getSelectionManagementIdBulkListMCAZS040,
  getSelectionManagementIdBulkListMCAZS050,
  // 次期開発5月向#58931 start
  editMessageMCAZS030,
  editMessageMCAZS040,
  setSelectMessageAliveList,
  // MCB ロット３　start
  setMcbReserveCount,
  setMcbDraftCount,
  selectMcbReserveCount,
  selectMcbDraftCount,
  // MCB ロット３　end
  // 次期開発5月向#58931 end
} from 'reducers/messageListReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  initReceiveRequest,
  selectMessageReceiveRequest,
  moveToTrashMessageRequest,
  receiveCountRequest,
  reserveCountRequest,
  draftCountRequest,
  sendMessageMCAZS010Request,
  // MCB ロット３　start
  mcbReserveCountRequest,
  mcbDraftCountRequest,
  // MCB ロット３　end
} from 'apis/MCAZS010Api'
// 次期開発5月向#58931 add
import {
  initReservationRequest,
  selectMessageReservationRequest,
  deleteMessageReservationRequest,
  sendMessageReservationRequest,
  getFailSelectionManagementApiMCAZS030,
  getSelectionManagementApiMCAZS030,
  editMessageMCAZS030Request,
} from 'apis/MCAZS030Api'
import {
  selectMessageDraftRequest,
  initRequest,
  deleteMessageRequest,
  getSelectionManagementApiMCAZS040,
  // 次期開発5月向#58931 add
  editMessageMCAZS040Request,
  // 次期開発5月向#58931 end
} from 'apis/MCAZS040Api'
import {
  selectMessageOutBoxRequest,
  outBoxInitRequest,
  moveTrashMessageRequest,
  getFailSelectionManagementApi,
  sendMessageCheckAPI,
  getSelectionManagementApiMCAZS020,
} from 'apis/MCAZS020Api'
import { magiContants } from 'utils/contants'
import {
  getMessageDraftListSearchCondition,
  getMessageOutBoxListSearchCondition,
  getMessageDustboxListSearchCondition,
  getMessageReservationListSearchCondition,
  // MCB ロット３　start
  getMcbDraftCount,
  getMcbReserveCount,
  // MCB ロット３　end
} from 'selectors'
import {
  selectMessageDustboxRequest,
  initDustboxRequest,
  deleteDustboxMessageRequest,
  undoDustboxMessageRequest,
  getSelectionManagementApiMCAZS050,
} from 'apis/MCAZS050Api'
import { getMessageReceiveListSearchCondition } from 'selectors'
import {
  getRecruitmentManagementDivision,
  getGlobalMenuData,
  getGlobalTargetYear,
} from 'selectors/authSelectors'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { getMessage } from 'common/messageUtil'
import { openMessageInputDialog } from 'reducers/messageInputDialogReducer'

// ===========================================MCAZS010 START=================================================
function* initReceiveSelectSaga(action: ReturnType<typeof initReceiveSelect>) {
  try {
    yield put(setMessageReceiveList([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initReceiveRequest, action.payload)
    yield put(setReceiveFromDate(data.receiveFromDate))
    yield put(setMessageReceiveListCount(data.receiveMsgCount))
    if (
      data.selectReceiveMsgList != null &&
      data.selectReceiveMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReceiveList(data.selectReceiveMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    if (
      data.displaySelectionList != null &&
      data.displaySelectionList.length > magiContants.INTEGER_0
    ) {
      yield put(setDisplaySelectionResults(data.displaySelectionList))
    }
    if (
      data.displayProgressList != null &&
      data.displayProgressList.length > magiContants.INTEGER_0
    ) {
      yield put(setDisplayProgressResults(data.displayProgressList))
    }
    // 次期開発5月向#58931 start
    if (
      data.selectMessageAliveList != null &&
      data.selectMessageAliveList.length > magiContants.INTEGER_0
    ) {
      yield put(setSelectMessageAliveList(data.selectMessageAliveList))
    }
    // 次期開発5月向#58931 end
    // MCB ロット３　start
    const mcbReserveCount: ReturnType<typeof getMcbReserveCount> = yield select(
      getMcbReserveCount
    )
    const mcbDraftCount: ReturnType<typeof getMcbDraftCount> = yield select(
      getMcbDraftCount
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(
      getGlobalTargetYear
    )
    if (
      recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (mcbDraftCount == null || mcbReserveCount == null) &&
      Number(targetYear) >= Number('10002025')
    ) {
      yield put(selectMcbReserveCount())
      yield put(selectMcbDraftCount())
    }
    // MCB ロット３　end
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* setMessageReceiveListSearchConditionSaga(
  action: ReturnType<typeof setMessageReceiveListSearchCondition>
) {
  try {
    yield put(setMessageReceiveList([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageReceiveRequest, action.payload)
    yield put(setMessageReceiveListCount(data.receiveMsgCount))
    yield put(setMessageReceiveList(data.selectReceiveMsgList))
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* receiveToTrashboxSaga(action: ReturnType<typeof receiveToTrashbox>) {
  try {
    yield put(setMessageReceiveList([]))
    yield put(setMessageFlagResult(-1))
    yield call(moveToTrashMessageRequest, action.payload)
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const searchCondition: ReturnType<typeof getMessageReceiveListSearchCondition> = yield select(
      getMessageReceiveListSearchCondition
    )
    const receiveSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      receiveFromDate: searchCondition.receiveFromDate,
      receiveToDate: searchCondition.receiveToDate,
      unreadCheck: searchCondition.unreadCheck,
      unsentCheck: searchCondition.unsentCheck,
      sentCheck: searchCondition.sentCheck,
      attachmentExist: searchCondition.attachmentExist,
      attachmentNot: searchCondition.attachmentNot,
      messageType: searchCondition.messageType,
      selection: searchCondition.selection,
      progress: searchCondition.progress,
      judge: searchCondition.judge,
      sort: searchCondition.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivision,
      removeFunctionId: searchCondition.removeFunctionId,
    }
    const data = yield call(selectMessageReceiveRequest, receiveSearchCondition)
    yield put(setMessageReceiveListCount(data.receiveMsgCount))
    if (
      data.selectReceiveMsgList != null ||
      data.selectReceiveMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReceiveList(data.selectReceiveMsgList))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS010_004))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// phase2 Start
// 受信トレー件数取得
function* selectReceiveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(receiveCountRequest, recruitmentManagementDivision)
    yield put(setReceiveCount(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 下書き件数取得
function* selectDraftCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(draftCountRequest, recruitmentManagementDivision)
    yield put(setDraftCount(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信予約件数取得
function* selectReserveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(reserveCountRequest, recruitmentManagementDivision)
    yield put(setReserveCount(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 受信トレーアラートエリア初期表示
function* getinitAreaReceiveRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信トレーアラートエリア初期表示
function* getinitAreaOutBoxRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信予約アラートエリア初期表示
function* getinitAreaReservationRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 下書きエリア初期表示
function* getinitAreaDraftRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ゴミ箱エリア初期表示
function* getinitAreaDustBoxRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// phase2 End

function* sendMessageMCAZS010Saga(
  action: ReturnType<typeof setMessageReceiveListSearchCondition>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    const data = yield call(sendMessageMCAZS010Request, action.payload)
    // メッセージ入力へ遷移する
    const requestParam = {
      messageSendId: data,
    }
    localStorage.setItem('messageInputRequest', JSON.stringify(requestParam))
    localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCB ロット３　start
function* selectMcbReserveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(
      mcbReserveCountRequest,
      recruitmentManagementDivision
    )
    yield put(setMcbReserveCount(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* selectMcbDraftCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(mcbDraftCountRequest, recruitmentManagementDivision)
    yield put(setMcbDraftCount(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCB ロット３　end
// ===========================================MCAZS010 END=================================================

// ===========================================MCAZS030 START=================================================
function* initSelectMCAZS030Saga(
  action: ReturnType<typeof initSelectMCAZS030>
) {
  try {
    yield put(setMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initReservationRequest, action.payload)
    yield put(setReserveFromDate(data.reserveFromDate))
    yield put(setMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCB ロット３　start
    const mcbReserveCount: ReturnType<typeof getMcbReserveCount> = yield select(
      getMcbReserveCount
    )
    const mcbDraftCount: ReturnType<typeof getMcbDraftCount> = yield select(
      getMcbDraftCount
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(
      getGlobalTargetYear
    )
    if (
      recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (mcbDraftCount == null || mcbReserveCount == null) &&
      Number(targetYear) >= Number('10002025')
    ) {
      yield put(selectMcbReserveCount())
      yield put(selectMcbDraftCount())
    }
    // MCB ロット３　end
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* setMessageReservationListSearchConditionSaga(
  action: ReturnType<typeof setMessageReservationListSearchCondition>
) {
  try {
    yield put(setMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageReservationRequest, action.payload)
    yield put(setMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* deleteMessageMCAZS030Saga(
  action: ReturnType<typeof deleteMessageMCAZS030>
) {
  try {
    yield put(setMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(deleteMessageReservationRequest, action.payload)
    const searchCondition: ReturnType<typeof getMessageReservationListSearchCondition> = yield select(
      getMessageReservationListSearchCondition
    )

    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    const tempSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      reserveFromDate: searchCondition.reserveFromDate,
      reserveToDate: searchCondition.reserveToDate,
      destinationNavi: searchCondition.destinationNavi,
      destinationMail: searchCondition.destinationMail,
      attachmentExist: searchCondition.attachmentExist,
      attachmentNot: searchCondition.attachmentNot,
      onlyBulkSend: searchCondition.onlyBulkSend,
      sort: searchCondition.sort,
      countPage: 1,
      optionFlag: '0',
      recruitmentManagementDivision: recruitmentManagementDivision,
      replyNecessaryCheckOn: searchCondition.replyNecessaryCheckOn,
      replyNecessaryCheckOff: searchCondition.replyNecessaryCheckOff,
      replyLimitFromDate: searchCondition.replyLimitFromDate,
      replyLimitToDate: searchCondition.replyLimitToDate,
    }

    const data = yield call(
      selectMessageReservationRequest,
      tempSearchCondition
    )
    yield put(setMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS030_005))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* sendMessageMCAZS030Saga(
  action: ReturnType<typeof deleteMessageMCAZS030>
) {
  try {
    yield put(setMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(sendMessageReservationRequest, action.payload)
    const searchCondition: ReturnType<typeof getMessageReservationListSearchCondition> = yield select(
      getMessageReservationListSearchCondition
    )

    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    const tempSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      reserveFromDate: searchCondition.reserveFromDate,
      reserveToDate: searchCondition.reserveToDate,
      destinationNavi: searchCondition.destinationNavi,
      destinationMail: searchCondition.destinationMail,
      attachmentExist: searchCondition.attachmentExist,
      attachmentNot: searchCondition.attachmentNot,
      onlyBulkSend: searchCondition.onlyBulkSend,
      sort: searchCondition.sort,
      countPage: 1,
      optionFlag: '0',
      recruitmentManagementDivision: recruitmentManagementDivision,
      replyNecessaryCheckOn: searchCondition.replyNecessaryCheckOn,
      replyNecessaryCheckOff: searchCondition.replyNecessaryCheckOff,
      replyLimitFromDate: searchCondition.replyLimitFromDate,
      replyLimitToDate: searchCondition.replyLimitToDate,
    }

    const data = yield call(
      selectMessageReservationRequest,
      tempSearchCondition
    )
    yield put(setMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS030_006))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// [phase2] start by zhangxp
function* getFailSelectionManagementIdMCAZS030Saga(
  action: ReturnType<typeof getFailSelectionManagementIdBulkListMCAZS030>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS030',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS030_010),
      specificSelectionManagementIdList: [] as string[],
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const failSelectionManagementsIds = yield call(
      getFailSelectionManagementApiMCAZS030,
      action.payload.messageSendId
    )
    if (data.bulkSendFlag === magiContants.BULK_SEND_FLAG_1) {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        specificSelectionManagementIdList: failSelectionManagementsIds,
      }
    } else {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        screenId: 'MCAZS030',
        specificSelectionManagementIdList: [data.selectionManagementId],
      }
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [phase2] end by zhangxp
// #5654 #59450 start
function* getSelectionManagementIdMCAZS030Saga(
  action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS030>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS030',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS030_011),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      selectionManagementId: data.selectionManagementId.toString(),
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const selectionManagementsIds = yield call(
      getSelectionManagementApiMCAZS030,
      sendObjTransitionEntryListRequest
    )
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 次期開発5月向#58931 start
function* editMessageMCAZS030Saga(action: ReturnType<any>) {
  try {
    yield call(editMessageMCAZS030Request, action.payload.checkParamDetail)
    localStorage.setItem(
      'messageInputRequest',
      JSON.stringify(action.payload.requestParam)
    )
    localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(action.payload.globalMenuInfo)
    )
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 次期開発5月向#58931 end

// #5654 #59450 end
// ===========================================MCAZS030 END=================================================

// ===========================================MCAZS040 START=================================================

function* setMessageDraftListSearchConditionSaga(
  action: ReturnType<typeof setMessageDraftListSearchCondition>
) {
  try {
    yield put(setMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageDraftRequest, action.payload)
    yield put(setMessageDraftListAllCount(data.draftCount))

    if (
      data.mcazs040EntrySearchOutDtoList != null &&
      data.mcazs040EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageDraftListResults(data.mcazs040EntrySearchOutDtoList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* initSelectSaga(action: ReturnType<typeof initSelect>) {
  try {
    yield put(setMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initRequest, action.payload)
    yield put(setUpdateFromDate(data.updateFromDate))
    yield put(setMessageDraftListAllCount(data.draftCount))
    if (
      data.mcazs040EntrySearchOutDtoList != null &&
      data.mcazs040EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageDraftListResults(data.mcazs040EntrySearchOutDtoList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCB ロット３　start
    const mcbReserveCount: ReturnType<typeof getMcbReserveCount> = yield select(
      getMcbReserveCount
    )
    const mcbDraftCount: ReturnType<typeof getMcbDraftCount> = yield select(
      getMcbDraftCount
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(
      getGlobalTargetYear
    )
    if (
      recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (mcbDraftCount == null || mcbReserveCount == null) &&
      Number(targetYear) >= Number('10002025')
    ) {
      yield put(selectMcbReserveCount())
      yield put(selectMcbDraftCount())
    }
    // MCB ロット３　end
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* deleteMessageSaga(action: ReturnType<typeof deleteMessage>) {
  try {
    yield put(setMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(deleteMessageRequest, action.payload)

    const searchCondition: ReturnType<typeof getMessageDraftListSearchCondition> = yield select(
      getMessageDraftListSearchCondition
    )

    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    const operationValues = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      updateFromDate: searchCondition.updateFromDate,
      updateToDate: searchCondition.updateToDate,
      attachmentExist: searchCondition.attachmentExist,
      attachmentNot: searchCondition.attachmentNot,
      onlyBulkSend: searchCondition.onlyBulkSend,
      sort: searchCondition.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivision,
      replyNecessaryCheckOn: searchCondition.replyNecessaryCheckOn,
      replyNecessaryCheckOff: searchCondition.replyNecessaryCheckOff,
      replyLimitFromDate: searchCondition.replyLimitFromDate,
      replyLimitToDate: searchCondition.replyLimitToDate,
    }
    const data = yield call(selectMessageDraftRequest, operationValues)
    yield put(setMessageDraftListAllCount(data.draftCount))

    if (
      data.mcazs040EntrySearchOutDtoList != null &&
      data.mcazs040EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageDraftListResults(data.mcazs040EntrySearchOutDtoList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS040_004))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 start
function* getSelectionManagementIdMCAZS040Saga(
  action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS040>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS040',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS040_006),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      selectionManagementId: data.selectionManagementId.toString(),
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const selectionManagementsIds = yield call(
      getSelectionManagementApiMCAZS040,
      sendObjTransitionEntryListRequest
    )
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 end

// 次期開発5月向#58931 start
function* editMessageMCAZS040Saga(action: ReturnType<any>) {
  try {
    yield call(editMessageMCAZS040Request, action.payload.checkParamDetail)
    localStorage.setItem(
      'messageInputRequest',
      JSON.stringify(action.payload.requestParam)
    )
    localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(action.payload.globalMenuInfo)
    )
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
    
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 次期開発5月向#58931 end
// ===========================================MCAZS040 END=================================================

// ===========================================MCAZS020 START===============================================
function* outBoxInitSelectSaga(action: ReturnType<typeof outBoxInit>) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(outBoxInitRequest, action.payload)
    yield put(setSentFromDate(data.sentFromDate))
    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if (
      data.selectSendMsgList != null &&
      data.selectSendMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    if (
      data.displaySelectionList != null &&
      data.displaySelectionList.length > 0
    ) {
      yield put(setDisplaySelectionResults(data.displaySelectionList))
    }
    if (
      data.displayProgressList != null &&
      data.displayProgressList.length > 0
    ) {
      yield put(setDisplayProgressResults(data.displayProgressList))
    }
    // MCB ロット３　start
    const mcbReserveCount: ReturnType<typeof getMcbReserveCount> = yield select(
      getMcbReserveCount
    )
    const mcbDraftCount: ReturnType<typeof getMcbDraftCount> = yield select(
      getMcbDraftCount
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(
      getGlobalTargetYear
    )
    if (
      recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (mcbDraftCount == null || mcbReserveCount == null) &&
      Number(targetYear) >= Number('10002025')
    ) {
      yield put(selectMcbReserveCount())
      yield put(selectMcbDraftCount())
    }
    // MCB ロット３　end
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* setMessageOutBoxListSearchConditionSaga(
  action: ReturnType<typeof setMessageOutBoxListSearchCondition>
) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageOutBoxRequest, action.payload)
    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if (
      data.selectSendMsgList != null &&
      data.selectSendMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* deleteOutBoxMessageSaga(
  action: ReturnType<typeof deleteOutBoxMessage>
) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(moveTrashMessageRequest, action.payload)

    const searchCondition: ReturnType<typeof getMessageOutBoxListSearchCondition> = yield select(
      getMessageOutBoxListSearchCondition
    )

    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    const tempSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      sentFromDate: searchCondition.sentFromDate,
      sentToDate: searchCondition.sentToDate,
      destinationNavi: searchCondition.destinationNavi,
      destinationMail: searchCondition.destinationMail,
      attachmentExist: searchCondition.attachmentExist,
      attachmentNot: searchCondition.attachmentNot,
      onlyBulkSend: searchCondition.onlyBulkSend,
      selection: searchCondition.selection,
      progress: searchCondition.progress,
      judge: searchCondition.judge,
      sort: searchCondition.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivision,
      replyNecessaryCheckOn: searchCondition.replyNecessaryCheckOn,
      replyNecessaryCheckOff: searchCondition.replyNecessaryCheckOff,
      replyLimitFromDate: searchCondition.replyLimitFromDate,
      replyLimitToDate: searchCondition.replyLimitToDate,
      messageNormal: searchCondition.messageNormal,
      messageThanks: searchCondition.messageThanks,
      messageScout: searchCondition.messageScout,
      messageSeminarCancel: searchCondition.messageSeminarCancel,
    }
    const data = yield call(selectMessageOutBoxRequest, tempSearchCondition)

    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if (data.selectSendMsgList.length > magiContants.INTEGER_0) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS020_004))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getFailSelectionManagementIdSaga(
  action: ReturnType<typeof getFailSelectionManagementIdBulkList>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS020',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS020_005),
      specificSelectionManagementIdList: [] as string[],
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const failSelectionManagementsIds = yield call(
      getFailSelectionManagementApi,
      action.payload.messageSendId
    )
    if (data.bulkSendFlag === magiContants.BULK_SEND_FLAG_1) {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        specificSelectionManagementIdList: failSelectionManagementsIds,
      }
    } else {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        screenId: 'MCAZS020',
        specificSelectionManagementIdList: [data.selectionManagementId],
      }
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// phase2 start by 考
function* getSendMessageCheckSaga(
  action: ReturnType<typeof getSendMessageFlag>
) {
  try {
    const data = yield call(sendMessageCheckAPI, action.payload)
    yield put(setSendMessageFlag(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 start
function* getSelectionManagementIdMCAZS020Saga(
  action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS020>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS020',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS020_009),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      selectionManagementId: data.selectionManagementId.toString(),
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const selectionManagementsIds = yield call(
      getSelectionManagementApiMCAZS020,
      sendObjTransitionEntryListRequest
    )
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 end
// ===========================================MCAZS020 END===============================================

// ===========================================MCAZS050 START=============================================
function* setMessageDustboxListSearchConditionSaga(
  action: ReturnType<typeof setMessageDustboxListSearchCondition>
) {
  try {
    yield put(setMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageDustboxRequest, action.payload)
    yield put(setMessageDustboxListAllCount(data.duxtBoxCount))
    if (
      data.mcazs050EntrySearchOutDtoList != null &&
      data.mcazs050EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(
        setMessageDustboxListResults(data.mcazs050EntrySearchOutDtoList)
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* initDustboxSelectSaga(action: ReturnType<typeof initDustboxSelect>) {
  try {
    yield put(setMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initDustboxRequest, action.payload)
    yield put(setDeleteFromDate(data.deleteFromDate))
    yield put(setMessageDustboxListAllCount(data.duxtBoxCount))
    if (
      data.mcazs050EntrySearchOutDtoList != null &&
      data.mcazs050EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(
        setMessageDustboxListResults(data.mcazs050EntrySearchOutDtoList)
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCB ロット３　start
    const mcbReserveCount: ReturnType<typeof getMcbReserveCount> = yield select(
      getMcbReserveCount
    )
    const mcbDraftCount: ReturnType<typeof getMcbDraftCount> = yield select(
      getMcbDraftCount
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const targetYear: ReturnType<typeof getGlobalTargetYear> = yield select(
      getGlobalTargetYear
    )
    if (
      recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (mcbDraftCount == null || mcbReserveCount == null) &&
      Number(targetYear) >= Number('10002025')
    ) {
      yield put(selectMcbReserveCount())
      yield put(selectMcbDraftCount())
    }
    // MCB ロット３　end
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* undoDustboxMessageSaga(
  action: ReturnType<typeof undoDustboxMessage>
) {
  try {
    yield put(setMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(undoDustboxMessageRequest, action.payload)

    const searchCondition: ReturnType<typeof getMessageDustboxListSearchCondition> = yield select(
      getMessageDustboxListSearchCondition
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const tempSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      deleteFromDate: searchCondition.deleteFromDate,
      deleteToDate: searchCondition.deleteToDate,
      classificationReceived: searchCondition.classificationReceived,
      classificationSent: searchCondition.classificationSent,
      classificationReserved: searchCondition.classificationReserved,
      classificationDraft: searchCondition.classificationDraft,
      sort: searchCondition.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(selectMessageDustboxRequest, tempSearchCondition)

    if (
      data.mcazs050EntrySearchOutDtoList != null &&
      data.mcazs050EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(
        setMessageDustboxListResults(data.mcazs050EntrySearchOutDtoList)
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(setMessageDustboxListAllCount(data.duxtBoxCount))
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS050_005))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* deleteDustboxMessageSaga(
  action: ReturnType<typeof deleteDustboxMessage>
) {
  try {
    yield put(setMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    yield call(deleteDustboxMessageRequest, action.payload)

    const searchCondition: ReturnType<typeof getMessageDustboxListSearchCondition> = yield select(
      getMessageDustboxListSearchCondition
    )
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const tempSearchCondition = {
      searchTargetList: searchCondition.searchTargetList,
      searchWordText: searchCondition.searchWordText,
      deleteFromDate: searchCondition.deleteFromDate,
      deleteToDate: searchCondition.deleteToDate,
      classificationReceived: searchCondition.classificationReceived,
      classificationSent: searchCondition.classificationSent,
      classificationReserved: searchCondition.classificationReserved,
      classificationDraft: searchCondition.classificationDraft,
      sort: searchCondition.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(selectMessageDustboxRequest, tempSearchCondition)

    if (
      data.mcazs050EntrySearchOutDtoList != null &&
      data.mcazs050EntrySearchOutDtoList.length > magiContants.INTEGER_0
    ) {
      yield put(
        setMessageDustboxListResults(data.mcazs050EntrySearchOutDtoList)
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(setMessageDustboxListAllCount(data.duxtBoxCount))
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS050_006))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 start
function* getSelectionManagementIdMCAZS050Saga(
  action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS050>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS050',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS050_009),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      selectionManagementId: data.selectionManagementId.toString(),
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const recruitmentManagementDivisions: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const selectionManagementsIds = yield call(
      getSelectionManagementApiMCAZS050,
      sendObjTransitionEntryListRequest
    )
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    if (recruitmentManagementDivisions === '1') {
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
    } else if (recruitmentManagementDivisions === '2') {
      // 転職：応募者検索・一覧（エントリー単位）
      pathName = routeList.entryList
    }
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #5654 #59450 end
// ===========================================MCAZS050 end=================================================

export default function* extraAccountSaga() {
  yield all([
    // ===========================================MCAZS010 START=================================================
    takeEvery(initReceiveSelect, initReceiveSelectSaga),
    takeLatest(
      setMessageReceiveListSearchCondition,
      setMessageReceiveListSearchConditionSaga
    ),
    takeEvery(receiveToTrashbox, receiveToTrashboxSaga),
    // phase2 Start
    takeEvery(selectReceiveCount, selectReceiveCountSaga),
    takeEvery(selectReserveCount, selectReserveCountSaga),
    takeEvery(selectDraftCount, selectDraftCountSaga),
    takeLatest(getinitAreaReceive, getinitAreaReceiveRequest),
    takeLatest(getinitAreaOutBox, getinitAreaOutBoxRequest),
    takeLatest(getinitAreaReservation, getinitAreaReservationRequest),
    takeLatest(getinitAreaDraft, getinitAreaDraftRequest),
    takeLatest(getinitAreaDustBox, getinitAreaDustBoxRequest),
    // phase2 End
    takeEvery(sendMessageMCAZS010, sendMessageMCAZS010Saga),
    // MCB ロット３　start
    takeEvery(selectMcbReserveCount, selectMcbReserveCountSaga),
    takeEvery(selectMcbDraftCount, selectMcbDraftCountSaga),
    // MCB ロット３　end
    // ===========================================MCAZS010 end=================================================
    // ===========================================MCAZS030 START=================================================
    takeLatest(initSelectMCAZS030, initSelectMCAZS030Saga),
    takeLatest(
      setMessageReservationListSearchCondition,
      setMessageReservationListSearchConditionSaga
    ),
    // [phase2] start by zhangxp
    takeLatest(
      getFailSelectionManagementIdBulkListMCAZS030,
      getFailSelectionManagementIdMCAZS030Saga
    ),
    // [phase2] end by zhangxp
    takeEvery(deleteMessageMCAZS030, deleteMessageMCAZS030Saga),
    takeEvery(sendMessageMCAZS030, sendMessageMCAZS030Saga),
    // #5654 #59450 start
    takeLatest(
      getSelectionManagementIdBulkListMCAZS030,
      getSelectionManagementIdMCAZS030Saga
    ),
    // #5654 #59450 end
    // 次期開発5月向#58931 start
    takeLatest(editMessageMCAZS030, editMessageMCAZS030Saga),
    // 次期開発5月向#58931 end
    // ===========================================MCAZS030 end=================================================
    takeLatest(
      setMessageDraftListSearchCondition,
      setMessageDraftListSearchConditionSaga
    ),
    takeLatest(initSelect, initSelectSaga),
    takeEvery(deleteMessage, deleteMessageSaga),
    // #5654 #59450 start
    takeLatest(
      getSelectionManagementIdBulkListMCAZS040,
      getSelectionManagementIdMCAZS040Saga
    ),
    // #5654 #59450 end
    // 次期開発5月向#58931 start
    takeLatest(editMessageMCAZS040, editMessageMCAZS040Saga),
    // 次期開発5月向#58931 end
    // ===========================================MCAZS020 START=================================================
    takeLatest(
      setMessageOutBoxListSearchCondition,
      setMessageOutBoxListSearchConditionSaga
    ),
    takeLatest(outBoxInit, outBoxInitSelectSaga),
    takeLatest(deleteOutBoxMessage, deleteOutBoxMessageSaga),
    takeLatest(
      getFailSelectionManagementIdBulkList,
      getFailSelectionManagementIdSaga
    ),
    takeLatest(getSendMessageFlag, getSendMessageCheckSaga),
    // #5654 #59450 start
    takeLatest(
      getSelectionManagementIdBulkListMCAZS020,
      getSelectionManagementIdMCAZS020Saga
    ),
    // #5654 #59450 end
    // ===========================================MCAZS020 END=================================================
    // ===========================================MCAZS050 START=================================================
    takeLatest(
      setMessageDustboxListSearchCondition,
      setMessageDustboxListSearchConditionSaga
    ),
    takeLatest(initDustboxSelect, initDustboxSelectSaga),
    takeLatest(undoDustboxMessage, undoDustboxMessageSaga),
    takeLatest(deleteDustboxMessage, deleteDustboxMessageSaga),
    // #5654 #59450 start
    takeLatest(
      getSelectionManagementIdBulkListMCAZS050,
      getSelectionManagementIdMCAZS050Saga
    ),
    // #5654 #59450 end
    // ===========================================MCAZS050 END=================================================
  ])
}
