import palette from '../palette'

export default {
  root: {
    // color: palette.icon,
    color: 'inherit',
    '&:hover': {
      // backgroundColor: 'rgba(0, 0, 0, 0.03)',
      backgroundColor: 'inherit',
    },
    padding: 'inherit',
    fontSize: 'inherit',
    // borderRadius: 'inherit',
    textAlign: 'inherit',
  },
}
