import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAge } from 'common/businessUtil'
import { isEmpty } from 'common/generalUtil'
import { message, messageRequest } from 'componentsHsc/Message'
import { MessageScreenTransitionRequest } from 'utils/tableDataTransfrom'
import {
  ApplicantDataHeaderInfo,
  applicantDataHeaderInfo,
  ApplicantDetailsRequest,
  ApplicantDetailsTargetInfo,
  applicantDetailsTargetInfo,
  CommonHeaderInfo,
  commonHeaderInfo,
  EntryInfo,
  entryInfo,
  InitResult,
  managementItem,
  ProfileInfo,
  profileInfo,
  aptitudeTestIMAGESInfo,
  aptitudeTestGABInfo,
  aptitudeTestOPQInfo,
  aptitudeTestCABInfo,
  selectionFlow,
  ScreenTransitionRequest,
  PDFScreenTransitionRequest,
  MCAXS170InitRequest,
  CurrentApplicantsListInfo,
  NotAdoptedNotificationRequest,
} from 'pages/MCAXS050/apiConfig'
import {
  screenIdContents,
  cmnHeadContents,
  cmnContents,
  profileContents,
  images,
  gab,
  opq,
  cab,
  commonHeader,
  selectionFlowArea,
  jobCareer,
  msgContents,
  profile,
  qualificationsSkills,
  ScreenDisplayItems,
  selfPR,
  webContents,
  marchContents,
} from 'pages/MCAXS050/viewConfig'
import { MCAXS050SendMessageRequest } from 'types/MCAXS050/MCAXS050SendMessageRequest'
import { MCAXS050RouteScreenTransitionRequest } from 'types/MCAXS050/MCAXS050RouteScreenTransitionRequest'
import { MCAXS050ScreenTransitionRequest } from 'types/MCAXS050/MCAXS050ScreenTransitionRequest'
import { MCAXS050UpdateExcludeRequest } from 'types/MCAXS050/MCAXS050UpdateExcludeRequest'
import { MCAXS050UpdateUnExcludeRequest } from 'types/MCAXS050/MCAXS050UpdateUnExcludeRequest'
import { MCAXS620RouteScreenTransitionRequest } from 'types/MCAXS620/MCAXS620RouteScreenTransitionRequest'
import { MCAXS620ScreenTransitionRequest } from 'types/MCAXS620/MCAXS620ScreenTransitionRequest'
import { MCAXS620UpdateExcludeRequest } from 'types/MCAXS620/MCAXS620UpdateExcludeRequest'
import { MCAXS620UpdateUnExcludeRequest } from 'types/MCAXS620/MCAXS620UpdateUnExcludeRequest'
import { MCAXS280InitRequest } from 'types/MCAXS280/MCAXS280InitRequest'
import { magiContants } from 'utils/contants'
import { MessageData } from 'utils/tableDataTransfrom'

const initResult: InitResult = {
  commonHeaderInfo: commonHeaderInfo,
  applicantDataHeaderInfo: applicantDataHeaderInfo,
  applicantDetailsTargetInfo: applicantDetailsTargetInfo,
  displayTargetListInfoList: [],
  duplicationInfoList: [],
  profileInfo: profileInfo,
  entryInfo: entryInfo,
  questionInfoList: [],
  jobCareerInfoList: [],
  nuisanceHistory: [],
  aptitudeTestIMAGESInfo: aptitudeTestIMAGESInfo,
  aptitudeTestGABInfo: aptitudeTestGABInfo,
  aptitudeTestOPQInfo: aptitudeTestOPQInfo,
  aptitudeTestCABInfo: aptitudeTestCABInfo,
  messageInfoList: [],
  selectionFlow: selectionFlow,
  managementItem: managementItem,
}

const applicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  directionId: '',
  displayTargetId: '',
  listIndex: 0,
  mediaFlag: null,
  coordinationCompanyKey: null,
  coordinationEntryKey: null,
  sourceFunctionId: null,
}

const currentApplicantsListInfo: CurrentApplicantsListInfo = {
  applicantsListInfo: [],
  listIndex: 0,
}

const screenDisplayItems: ScreenDisplayItems = {
  jobSeekerId: 0,
  selectionFlowArea: selectionFlowArea,
  commonHeader: commonHeader,
  profile: profile,
  question: [],
  jobCareer: jobCareer,
  qualificationsSkills: qualificationsSkills,
  selfPR: selfPR,
  aptitudeTestAreaViewFlag: false,
  images: images,
  gab: gab,
  opq: opq,
  cab: cab,
  message: [],
  nuisanceAreaViewFlag: false,
}

/** 画面遷移リクエスト */
const screenTransitionRequest: ScreenTransitionRequest = {
  screenId: '',
  jobSeekerId: '',
  jobSeekerIdForDisplay: '',
  entryData: [],
}

/** 画面遷移リクエスト(PDF) */
const pdfScreenTransitionRequest: PDFScreenTransitionRequest = {
  jobSeekerId: [],
  entryId: [],
  pdfType: '',
  prevScreenId: '',
}

/** 画面遷移リクエスト(MCAXS170) */
const mcaxs170InitRequest: MCAXS170InitRequest = {
  screenId: '',
  jobSeekerId: [],
  selectionManagementId: [],
  entryId: [],
}

const initialState: {
  screenId: string
  applicantDetailsRequest: ApplicantDetailsRequest
  initResult: InitResult
  businessCheckResult: boolean
  screenDisplayItems: ScreenDisplayItems
  screenTransitionRequest: ScreenTransitionRequest
  pdfScreenTransitionRequest: PDFScreenTransitionRequest
  messageRequest: MessageScreenTransitionRequest
  doInit: boolean
  mcaxs170InitRequest: MCAXS170InitRequest
  openManageListDialog: boolean
  currentApplicantsListInfo: CurrentApplicantsListInfo
  showMCAXS050: boolean
  showMCAXS620: boolean
  sendMessageErrorFlag: boolean,
  inValidCheckCount: string[],
  serviceMode: boolean,
  marchResponsibleCompanyFlag: boolean
} = {
  screenId: '',
  applicantDetailsRequest: applicantDetailsRequest,
  initResult: initResult,
  businessCheckResult: false,
  screenDisplayItems: screenDisplayItems,
  screenTransitionRequest: screenTransitionRequest,
  pdfScreenTransitionRequest: pdfScreenTransitionRequest,
  messageRequest: messageRequest,
  doInit: false,
  mcaxs170InitRequest: mcaxs170InitRequest,
  openManageListDialog: false,
  currentApplicantsListInfo: currentApplicantsListInfo,
  showMCAXS050: false,
  showMCAXS620: false,
  sendMessageErrorFlag: false,
  inValidCheckCount: [],
  serviceMode: false,
  marchResponsibleCompanyFlag: false,
}

const entryDetailSlice = createSlice({
  name: 'entryDetail',
  initialState,
  reducers: {
    init(
      state,
      action: PayloadAction<{
        request: ApplicantDetailsRequest
        currentApplicantsListInfo: CurrentApplicantsListInfo
        searchLinkFlg?: boolean
      }>
    ) {
      /*----------------------------------*
       * 初期表示イベント *
       *----------------------------------*/
      console.log('reducer処理(getInit)')
      return state
    },
    mcaxs620Init(state, action: PayloadAction<ApplicantDetailsRequest>) {
      /*----------------------------------*
       * 初期表示イベント *
       *----------------------------------*/
      console.log('reducer処理(getInit)')
      return state
    },
    setInit(state, action: PayloadAction<InitResult>) {
      /*----------------------------------*
       * SVから受け取ったデータをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setInit)')
      state.initResult = action.payload
      return state
    },
    setInitMarchParam(state, action: PayloadAction<{
      serviceMode : string
      marchResponsibleCompanyFlag : string
    }>) {
      /*----------------------------------*
       * MarchのParam設定 *
       *----------------------------------*/
      state.serviceMode = action.payload.serviceMode == marchContents.MARCH_ENABLED_FLAG_ON
      state.marchResponsibleCompanyFlag = action.payload.marchResponsibleCompanyFlag == marchContents.MARCH_RESPONSIBLE_COMPANY_FLAG_ON
      return state
    },
    setInitDisplay(state) {
      /*----------------------------------*
       * stateを表示用に初期化 *
       *----------------------------------*/
      state.initResult = initResult
      state.screenDisplayItems = screenDisplayItems
      return state
    },
    setScreenId(state, action: PayloadAction<string>) {
      /*----------------------------------*
       * 画面IDをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setScreenId)')
      state.screenId = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      /*----------------------------------*
       * SVから受け取ったデータをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setCode)')
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        default:
          state.businessCheckResult = false
          break
      }

      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
    setDoInit(state) {
      state.doInit = true
      return state
    },
    setScreenDisplayItems(state) {
      state.doInit = false
      const serviceMode: boolean = state.serviceMode
      const marchResponsibleCompanyFlag: boolean = state.marchResponsibleCompanyFlag
      /*-----------------------------------*
       * SVから受け取ったデータを表示用に編集 *
       *-----------------------------------*/
      console.log('reducer処理(setScreenDisplayItems)')

      const data = state.initResult

      /*----------------------*
       * 画面遷移リクエスト(詳細系)を設定 *
       *----------------------*/
      const applicantDetailsRequest: ApplicantDetailsRequest = {
        ...state.applicantDetailsRequest,
        applicantsListInfo: data.applicantDetailsTargetInfo.applicantsListInfo,
        directionId: data.applicantDetailsTargetInfo.directionId,
        displayTargetId: data.applicantDetailsTargetInfo.displayTargetId,
        listIndex: data.applicantDetailsTargetInfo.listIndex,
      }
      state.applicantDetailsRequest = applicantDetailsRequest
      if (state.currentApplicantsListInfo.listIndex < 0) {
        // 現在の表示対象未設定時は取得した表示対象を現在表示リストに確保する
        const displayTarget = data.displayTargetListInfoList[0]
        const info = {
          applicantsListInfo: [
            {
              jobSeekerId: displayTarget.jobSeekerId,
              entryId: displayTarget.entryId,
              selectionManagementId: displayTarget.selectionManagementId,
            },
          ],
          listIndex: data.applicantDetailsTargetInfo.listIndex,
        }
        state.currentApplicantsListInfo = info
        const detailRequest = {
          state: info,
        }
        sessionStorage.removeItem('detailRequest')
        localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
      }

      /*----------------------*
       * 求職者IDを設定 *
       *----------------------*/
      state.screenDisplayItems.jobSeekerId =
        state.initResult.displayTargetListInfoList[0].jobSeekerId

      /*----------------------*
       * 画面遷移リクエスト(screenTransitionRequest)を設定 *
       *----------------------*/
      state.screenTransitionRequest.screenId = state.screenId
      state.screenTransitionRequest.jobSeekerId = state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      state.screenTransitionRequest.jobSeekerIdForDisplay =
        state.initResult.applicantDataHeaderInfo.jobSeekerIdForDisplay
      state.screenTransitionRequest.entryData = state.initResult.applicantDetailsTargetInfo.applicantsListInfo.map(
        item => ({
          entryId: item.entryId.toString(),
          selectionManagementId: item.selectionManagementId.toString(),
        })
      )

      /*----------------------*
       * 画面遷移リクエスト(PDFscreenTransitionRequest)を設定 *
       *----------------------*/
      state.pdfScreenTransitionRequest.jobSeekerId = []
      state.pdfScreenTransitionRequest.entryId = []

      state.pdfScreenTransitionRequest.jobSeekerId.push(
        state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      )
      state.pdfScreenTransitionRequest.entryId.push(
        state.initResult.displayTargetListInfoList[0].entryId.toString()
      )
      state.pdfScreenTransitionRequest.pdfType = '1'

      /*----------------------*
       * 画面遷移リクエスト(messageRequest)を設定 *
       *----------------------*/
      state.messageRequest.entryId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.entryId.toString()
        }
      )
      state.messageRequest.selectionManagementId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.selectionManagementId.toString()
        }
      )

      /*----------------------*
       * 画面遷移リクエスト(mcaxs170InitRequest)を設定 *
       *----------------------*/
      state.mcaxs170InitRequest.screenId = state.screenId
      state.mcaxs170InitRequest.jobSeekerId = []
      state.mcaxs170InitRequest.jobSeekerId.push(
        state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      )
      state.mcaxs170InitRequest.entryId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.entryId.toString()
        }
      )
      state.mcaxs170InitRequest.selectionManagementId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.selectionManagementId.toString()
        }
      )

      /*----------------------*
       * 選考フローエリア表示フラグを設定 *
       *----------------------*/
      const selectionFlowAreaViewFlag = state.screenDisplayItems.selectionFlowArea.selectionFlowAreaViewFlag
      selectionFlowAreaViewFlag.interviewSchedule = !serviceMode
      selectionFlowAreaViewFlag.applicationDateTime = !serviceMode
      selectionFlowAreaViewFlag.recommendDate =
        serviceMode || marchResponsibleCompanyFlag
      selectionFlowAreaViewFlag.selectionFlowChange = !(
        serviceMode || marchResponsibleCompanyFlag
      )
      selectionFlowAreaViewFlag.selectionFlowRouteChange = !(
        serviceMode || marchResponsibleCompanyFlag
      )
      selectionFlowAreaViewFlag.selectionFlowExclude = !serviceMode
      selectionFlowAreaViewFlag.selectionFlowUnExclude = !serviceMode
      selectionFlowAreaViewFlag.changePassingStatusSelection = !serviceMode
      selectionFlowAreaViewFlag.selectionFlowProgressBar = !(
        serviceMode || marchResponsibleCompanyFlag
      )
      selectionFlowAreaViewFlag.selectionFlowProgressUpdateDateTime = !(
        serviceMode || marchResponsibleCompanyFlag
      )
      selectionFlowAreaViewFlag.marchLabel =
      serviceMode || marchResponsibleCompanyFlag
      selectionFlowAreaViewFlag.selectionFlowExtraInfo = !serviceMode
      selectionFlowAreaViewFlag.selectionFlowEvaluation = !serviceMode
      selectionFlowAreaViewFlag.selectionFlowComment = !serviceMode
      selectionFlowAreaViewFlag.entryJobCategoryNameBadge = !serviceMode
      selectionFlowAreaViewFlag.applicationDivisionBadge = !serviceMode
      selectionFlowAreaViewFlag.scoutBenefits = !serviceMode
      selectionFlowAreaViewFlag.tagFunction = !serviceMode
      selectionFlowAreaViewFlag.createNotAdopetdNortificationApplicant = !(
        serviceMode || marchResponsibleCompanyFlag
      )
      selectionFlowAreaViewFlag.createNotAdopetdNortificationInterview = !(
        serviceMode || marchResponsibleCompanyFlag
      )

      /*----------------------*
       * 共通ヘッダエリアを設定 *
       *----------------------*/
      /** 共通ヘッダ情報 */
      const commonHeaderInfoDto: CommonHeaderInfo = data.commonHeaderInfo
      /** 応募者データヘッダ情報 */
      const applicantDataHeaderInfoDto: ApplicantDataHeaderInfo =
        data.applicantDataHeaderInfo
      /** 応募者詳細表示対象情報 */
      const applicantDetailsTargetInfoDto: ApplicantDetailsTargetInfo =
        data.applicantDetailsTargetInfo

      state.screenDisplayItems.commonHeader.companyName =
        commonHeaderInfoDto.companyName
      state.screenDisplayItems.commonHeader.loginUserName = commonHeaderInfoDto.fullName
      state.screenDisplayItems.commonHeader.nameWithLinkFlag = !(serviceMode || marchResponsibleCompanyFlag)
      state.screenDisplayItems.commonHeader.fullName = `${applicantDataHeaderInfoDto.familyName} ${applicantDataHeaderInfoDto.name}`
      state.screenDisplayItems.commonHeader.familyName = applicantDataHeaderInfoDto.familyName
      state.screenDisplayItems.commonHeader.name = applicantDataHeaderInfoDto.name
      state.screenDisplayItems.commonHeader.fullNameKana = `${applicantDataHeaderInfoDto.familyNameKana} ${applicantDataHeaderInfoDto.nameKana}`
      state.screenDisplayItems.commonHeader.familyNameKana = applicantDataHeaderInfoDto.familyNameKana
      state.screenDisplayItems.commonHeader.nameKana = applicantDataHeaderInfoDto.nameKana
      state.screenDisplayItems.commonHeader.age = `${getAge(
        applicantDataHeaderInfoDto.birthdate
      )}歳`
      state.screenDisplayItems.commonHeader.sex = applicantDataHeaderInfoDto.sex
      state.screenDisplayItems.commonHeader.jobSeekerIdForDisplay = `(${applicantDataHeaderInfoDto.jobSeekerIdForDisplay})`

      if (!isEmpty(applicantDataHeaderInfoDto.unsubscribeTime)) {
        // 退会日時が存在する場合：退会済みバッジを表示
        state.screenDisplayItems.commonHeader.UnsubscribedBadge = true
      } else {
        state.screenDisplayItems.commonHeader.UnsubscribedBadge = false
      }

      /** 重複情報 */
      state.screenDisplayItems.commonHeader.duplicationSelectionBadge = false
      state.screenDisplayItems.commonHeader.duplicationBadge = false
      const duplicationInfoDtoList = data.duplicationInfoList

      let duplicationInfoDto
      for (duplicationInfoDto of duplicationInfoDtoList) {
        if (duplicationInfoDto.targetCount > 1) {
          switch (duplicationInfoDto.countId) {
            case cmnHeadContents.MULTIPLE_SELECTION:
              state.screenDisplayItems.commonHeader.duplicationSelectionBadge = true
              break
            case cmnHeadContents.DUPLICATION:
              state.screenDisplayItems.commonHeader.duplicationBadge = true
              break
            default:
              break
          }
        }
      }
      /** 過去の応募を確認 */
      state.screenDisplayItems.commonHeader.pastApplicationConfirmation = serviceMode
        ? cmnHeadContents.PAST_APPLICATION_CONFIRMATION_LABEL.PAST_RECOMMEND
        : cmnHeadContents.PAST_APPLICATION_CONFIRMATION_LABEL.PAST_APPLICATION

      /** 推薦済みバッジ */
      const selectionFlowColumn = state.initResult.selectionFlow.selectionFlowColumnList[0]
      const recommendFlag: boolean = selectionFlowColumn ? selectionFlowColumn.recommendFlag == cmnHeadContents.RECOMMEND_FLAG_ENABLE: false
      state.screenDisplayItems.commonHeader.recommendedBadge = marchResponsibleCompanyFlag &&  recommendFlag
      /** 新規メッセージを作成 */
      state.screenDisplayItems.commonHeader.createNewMessageViewFlag = !serviceMode
      state.screenDisplayItems.commonHeader.tabMessageViewFlag = !serviceMode
      state.screenDisplayItems.commonHeader.duplicationBadgeViewFlag = !serviceMode

      /*------------------------*
       * プロフィールエリアを設定 *
       *------------------------*/
      /** プロフィール（応募者）情報 */
      const profileInfoDto: ProfileInfo = data.profileInfo

      let prefecturesName = cmnContents.BLANK
      let addressAfterCity = cmnContents.BLANK
      let birthdate = cmnContents.HYPHEN
      let sex = cmnContents.BLANK
      if (!isEmpty(profileInfoDto.prefecturesName)) {
        prefecturesName = profileInfoDto.prefecturesName
      }
      if (!isEmpty(profileInfoDto.addressAfterCity)) {
        addressAfterCity = profileInfoDto.addressAfterCity
      }
      if (!isEmpty(profileInfoDto.birthdate)) {
        birthdate = profileInfoDto.birthdate
      }
      if (profileContents.UNKNOWN !== profileInfoDto.sex) {
        sex = profileInfoDto.sex
      }

      // 応募「者」情報の設定
      let profileFullNameKana = cmnContents.BLANK
      if (!isEmpty(profileInfoDto.familyNameKana) && !isEmpty(profileInfoDto.nameKana)) {
        profileFullNameKana = `(${profileInfoDto.familyNameKana} ${profileInfoDto.nameKana})`
      }
      else if (!isEmpty(profileInfoDto.familyNameKana)) {
        profileFullNameKana = `(${profileInfoDto.familyNameKana})`
      }
      else if (!isEmpty(profileInfoDto.nameKana)) {
        profileFullNameKana = `(${profileInfoDto.nameKana})`
      }
      state.screenDisplayItems.profile.profileFullName = `${profileInfoDto.familyName} ${profileInfoDto.name}${profileFullNameKana}`
      state.screenDisplayItems.profile.profilePostalCode =
        profileInfoDto.postalCode
      state.screenDisplayItems.profile.profileStreetAddress = `${prefecturesName}${addressAfterCity}`
      state.screenDisplayItems.profile.profileBirthDateSex = `${birthdate} （${getAge(
        birthdate
      )}歳） ${sex}`
      state.screenDisplayItems.profile.profileSpouse = profileInfoDto.spouse
      state.screenDisplayItems.profile.profileAnnualIncome =
        profileInfoDto.currentAnnualIncome
      state.screenDisplayItems.profile.profileCompaniesExperience =
        profileInfoDto.companiesExperience
      state.screenDisplayItems.profile.profileEmploymentStatus =
        profileInfoDto.employmentStatus
      state.screenDisplayItems.profile.profileMobilePhone =
        profileInfoDto.phoneNumber3
      state.screenDisplayItems.profile.profileFixedLinePhone =
        profileInfoDto.phoneNumber1
      state.screenDisplayItems.profile.profileMobileEmail =
        profileInfoDto.mailAddress2
      state.screenDisplayItems.profile.profileEmail =
        profileInfoDto.mailAddress1
      state.screenDisplayItems.profile.profileSchoolInformation =
        profileInfoDto.educationalBackgroundSummary
      state.screenDisplayItems.profile.profileEditViewFlag = !serviceMode

      /*---------------------------------*
       * 適性テストエリア表示を設定 *
       *---------------------------------*/
      state.screenDisplayItems.aptitudeTestAreaViewFlag = !(serviceMode || marchResponsibleCompanyFlag)

      /*---------------------------------*
       * 適性テスト（イメジス）エリアを設定 *
       *---------------------------------*/
      /** 適性テスト（イメジス）情報 */
      const IMAGESInfoDto = data.aptitudeTestIMAGESInfo
      if (IMAGESInfoDto === null) {
        state.screenDisplayItems.images.viewFlag = false
      } else {
        if (IMAGESInfoDto.imagesDataId === null) {
          state.screenDisplayItems.images = { ...images }
        } else {
          // 値の設定
          switch (IMAGESInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.images.imagesUpdatePath =
                webContents.MANUAL
              state.screenDisplayItems.images.imagesCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.images.imagesUpdatePath =
                webContents.AUTOMATIC
              state.screenDisplayItems.images.imagesCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.images.imagesIntelligenceDeviationSynthesis =
            IMAGESInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationCounting =
            IMAGESInfoDto.countingDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationLanguage =
            IMAGESInfoDto.languageDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationEnglish =
            IMAGESInfoDto.englishDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceStandardCounting =
            IMAGESInfoDto.countingStandardPoint
          state.screenDisplayItems.images.imagesIntelligenceStandardLanguage =
            IMAGESInfoDto.languageStandardPoint
          state.screenDisplayItems.images.imagesIntelligenceStandardEnglish =
            IMAGESInfoDto.englishStandardPoint
          state.screenDisplayItems.images.imagesPersonalityI =
            IMAGESInfoDto.irealityImagination
          state.screenDisplayItems.images.imagesPersonalityM =
            IMAGESInfoDto.mflexibleMethodical
          state.screenDisplayItems.images.imagesPersonalityA =
            IMAGESInfoDto.aselfPacedEffortIntention
          state.screenDisplayItems.images.imagesPersonalityG =
            IMAGESInfoDto.gintrovertedOpened
          state.screenDisplayItems.images.imagesPersonalityE =
            IMAGESInfoDto.erelaxWorrier
          state.screenDisplayItems.images.imagesPersonalityS =
            IMAGESInfoDto.scalmSympathy
          state.screenDisplayItems.images.imagesPersonalityVitality =
            IMAGESInfoDto.evigor
          state.screenDisplayItems.images.imagesPersonalityInterpersonal =
            IMAGESInfoDto.hantipersonnel
          state.screenDisplayItems.images.imagesPersonalityEstablishment =
            IMAGESInfoDto.mfixation
          state.screenDisplayItems.images.imagesBirthday =
            IMAGESInfoDto.birthdate
          state.screenDisplayItems.images.imagesUpdateDate =
            IMAGESInfoDto.updateTime
          // Phase2 Start
          state.screenDisplayItems.images.imagesPersonalityD =
            IMAGESInfoDto.dstaticDynamic
          state.screenDisplayItems.images.imagesPersonalityW =
            IMAGESInfoDto.wthinkingKindness
          // Phase2 End
        }
        state.screenDisplayItems.images.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（GAB）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（GAB）情報 */
      const gabInfoDto = data.aptitudeTestGABInfo
      if (gabInfoDto === null) {
        state.screenDisplayItems.gab.viewFlag = false
      } else {
        if (gabInfoDto.webGabDataId === null) {
          state.screenDisplayItems.gab = { ...gab }
        } else {
          // 値の設定
          switch (gabInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.gab.gabUpdatePath = webContents.MANUAL
              state.screenDisplayItems.gab.gabCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.gab.gabUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.gab.gabCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.gab.gabTestLanguage = gabInfoDto.examLanguage
          state.screenDisplayItems.gab.gabExamDateCounting =
            gabInfoDto.countingExamDate
          state.screenDisplayItems.gab.gabExamDateLanguage =
            gabInfoDto.languageExamDate
          state.screenDisplayItems.gab.gabExamDateOpq = gabInfoDto.opqExamDate
          state.screenDisplayItems.gab.gabIntelligenceDeviationSynthesis =
            gabInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceDeviationCounting =
            gabInfoDto.countingDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceDeviationLanguage =
            gabInfoDto.languageDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceStandardSynthesis =
            gabInfoDto.comprehensiveStandardPoint
          state.screenDisplayItems.gab.gabIntelligenceStandardCounting =
            gabInfoDto.countingStandardPoint
          state.screenDisplayItems.gab.gabIntelligenceStandardLanguage =
            gabInfoDto.languageStandardPoint
          state.screenDisplayItems.gab.gabManagementQualities =
            gabInfoDto.managementCapacity
          state.screenDisplayItems.gab.gabAbilityVitality = gabInfoDto.vitality
          state.screenDisplayItems.gab.gabAbilityPerPerson =
            gabInfoDto.perPerson
          state.screenDisplayItems.gab.gabAbilityTeamwork = gabInfoDto.teamwork
          state.screenDisplayItems.gab.gabAbilityCreativeThinking =
            gabInfoDto.creativeThinkingPower
          state.screenDisplayItems.gab.gabAbilityProblemSolving =
            gabInfoDto.problemSolvingPower
          state.screenDisplayItems.gab.gabAbilitySituationalAdaptability =
            gabInfoDto.statusAdaptationPower
          state.screenDisplayItems.gab.gabAbilityPressureResistance =
            gabInfoDto.proofStressToPressure
          state.screenDisplayItems.gab.gabAbilityOrganizingAbility =
            gabInfoDto.organizeAbility
          state.screenDisplayItems.gab.gabAbilityLeadership =
            gabInfoDto.commandPower
          state.screenDisplayItems.gab.gabJobAptitudeSales1 = gabInfoDto.salesI
          state.screenDisplayItems.gab.gabJobAptitudeSales2 = gabInfoDto.salesIi
          state.screenDisplayItems.gab.gabJobAptitudeStaff = gabInfoDto.staff
          state.screenDisplayItems.gab.gabJobAptitudeGeneralSecretary =
            gabInfoDto.generalOffice
          state.screenDisplayItems.gab.gabJobAptitudeEngineer =
            gabInfoDto.engineer
          state.screenDisplayItems.gab.gabJobAptitudeResearchDevelopment =
            gabInfoDto.researchDevelopment
          state.screenDisplayItems.gab.gabJobAptitudeCustomersCounterCallCenter =
            gabInfoDto.serviceOverTheCounterSalesCallCenter
          state.screenDisplayItems.gab.gabOpqPersuasiveness =
            gabInfoDto.persuasionPower
          state.screenDisplayItems.gab.gabOpqExtrovert = gabInfoDto.extroversion
          state.screenDisplayItems.gab.gabOpqHumility = gabInfoDto.humility
          state.screenDisplayItems.gab.gabOpqConcreteThings =
            gabInfoDto.concreteThing
          state.screenDisplayItems.gab.gabOpqHuman = gabInfoDto.human
          state.screenDisplayItems.gab.gabOpqConceptuality =
            gabInfoDto.conceptuality
          state.screenDisplayItems.gab.gabOpqElaborate = gabInfoDto.accurate
          state.screenDisplayItems.gab.gabOpqAnxiousMind = gabInfoDto.worrier
          state.screenDisplayItems.gab.gabOpqOptimistic = gabInfoDto.optimistic
          state.screenDisplayItems.gab.gabOpqCompetitive =
            gabInfoDto.competitiveness
          state.screenDisplayItems.gab.gabOpqLeadership =
            gabInfoDto.coachingPower
          state.screenDisplayItems.gab.gabOpqFriendliness =
            gabInfoDto.friendship
          state.screenDisplayItems.gab.gabOpqConsultativeness =
            gabInfoDto.consultation
          state.screenDisplayItems.gab.gabOpqData = gabInfoDto.data
          state.screenDisplayItems.gab.gabOpqOrthodox = gabInfoDto.orthodox
          state.screenDisplayItems.gab.gabOpqCreative = gabInfoDto.creative
          state.screenDisplayItems.gab.gabOpqMeticulous = gabInfoDto.methodical
          state.screenDisplayItems.gab.gabOpqTough = gabInfoDto.tough
          state.screenDisplayItems.gab.gabOpqCritical = gabInfoDto.criticism
          state.screenDisplayItems.gab.gabOpqAscension =
            gabInfoDto.upwardIntention
          state.screenDisplayItems.gab.gabOpqUniqueness = gabInfoDto.uniqueness
          state.screenDisplayItems.gab.gabOpqSocial = gabInfoDto.sociality
          state.screenDisplayItems.gab.gabOpqTrouble = gabInfoDto.toCare
          state.screenDisplayItems.gab.gabOpqAestheticValue =
            gabInfoDto.aestheticValue
          state.screenDisplayItems.gab.gabOpqChangeOriented =
            gabInfoDto.changeIntention
          state.screenDisplayItems.gab.gabOpqPlanning = gabInfoDto.planning
          state.screenDisplayItems.gab.gabOpqMargin = gabInfoDto.allowance
          state.screenDisplayItems.gab.gabOpqSuppression =
            gabInfoDto.suppression
          state.screenDisplayItems.gab.gabOpqAbilityTakeAction =
            gabInfoDto.activityPower
          state.screenDisplayItems.gab.gabOpqDetermination =
            gabInfoDto.decisionPower
          state.screenDisplayItems.gab.gabConsistency = gabInfoDto.consistency
          state.screenDisplayItems.gab.gabUpdateDate = gabInfoDto.updateTime
        }
        state.screenDisplayItems.gab.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（OPQ）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（OPQ）情報 */
      const opqInfoDto = data.aptitudeTestOPQInfo
      if (opqInfoDto === null) {
        state.screenDisplayItems.opq.viewFlag = false
      } else {
        if (opqInfoDto.webOpqDataId === null) {
          state.screenDisplayItems.opq = { ...opq }
        } else {
          // 値の設定
          switch (opqInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.opq.opqUpdatePath = webContents.MANUAL
              state.screenDisplayItems.opq.opqCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.opq.opqUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.opq.opqCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.opq.opqExamDate = opqInfoDto.examDate
          state.screenDisplayItems.opq.opqManagementQualities =
            opqInfoDto.managementCapacity
          state.screenDisplayItems.opq.opqAbilityVitality = opqInfoDto.vitality
          state.screenDisplayItems.opq.opqAbilityPerPerson =
            opqInfoDto.perPerson
          state.screenDisplayItems.opq.opqAbilityTeamwork = opqInfoDto.teamwork
          state.screenDisplayItems.opq.opqAbilityCreativeThinking =
            opqInfoDto.creativeThinkingPower
          state.screenDisplayItems.opq.opqAbilityProblemSolving =
            opqInfoDto.problemSolvingPower
          state.screenDisplayItems.opq.opqAbilitySituationalAdaptability =
            opqInfoDto.statusAdaptationPower
          state.screenDisplayItems.opq.opqAbilityPressureResistance =
            opqInfoDto.proofStressToPressure
          state.screenDisplayItems.opq.opqAbilityOrganizingAbility =
            opqInfoDto.organizeAbility
          state.screenDisplayItems.opq.opqAbilityLeadership =
            opqInfoDto.commandPower
          state.screenDisplayItems.opq.opqJobAptitudeSales1 = opqInfoDto.salesI
          state.screenDisplayItems.opq.opqJobAptitudeSales2 = opqInfoDto.salesIi
          state.screenDisplayItems.opq.opqJobAptitudeStaff = opqInfoDto.staff
          state.screenDisplayItems.opq.opqJobAptitudeGeneralSecretary =
            opqInfoDto.generalOffice
          state.screenDisplayItems.opq.opqJobAptitudeEngineer =
            opqInfoDto.engineer
          state.screenDisplayItems.opq.opqJobAptitudeResearchDevelopment =
            opqInfoDto.researchDevelopment
          state.screenDisplayItems.opq.opqJobAptitudeCustomersCounterCallCenter =
            opqInfoDto.serviceOverTheCounterSalesCallCenter
          state.screenDisplayItems.opq.opqOpqPersuasiveness =
            opqInfoDto.persuasionPower
          state.screenDisplayItems.opq.opqOpqExtrovert = opqInfoDto.extroversion
          state.screenDisplayItems.opq.opqOpqHumility = opqInfoDto.humility
          state.screenDisplayItems.opq.opqOpqConcreteThings =
            opqInfoDto.concreteThing
          state.screenDisplayItems.opq.opqOpqHuman = opqInfoDto.human
          state.screenDisplayItems.opq.opqOpqConceptuality =
            opqInfoDto.conceptuality
          state.screenDisplayItems.opq.opqOpqElaborate = opqInfoDto.accurate
          state.screenDisplayItems.opq.opqOpqAnxiousMind = opqInfoDto.worrier
          state.screenDisplayItems.opq.opqOpqOptimistic = opqInfoDto.optimistic
          state.screenDisplayItems.opq.opqOpqCompetitive =
            opqInfoDto.competitiveness
          state.screenDisplayItems.opq.opqOpqLeadership =
            opqInfoDto.coachingPower
          state.screenDisplayItems.opq.opqOpqFriendliness =
            opqInfoDto.friendship
          state.screenDisplayItems.opq.opqOpqConsultativeness =
            opqInfoDto.consultation
          state.screenDisplayItems.opq.opqOpqData = opqInfoDto.data
          state.screenDisplayItems.opq.opqOpqOrthodox = opqInfoDto.orthodox
          state.screenDisplayItems.opq.opqOpqCreative = opqInfoDto.creative
          state.screenDisplayItems.opq.opqOpqMeticulous = opqInfoDto.methodical
          state.screenDisplayItems.opq.opqOpqTough = opqInfoDto.tough
          state.screenDisplayItems.opq.opqOpqCritical = opqInfoDto.criticism
          state.screenDisplayItems.opq.opqOpqAscension =
            opqInfoDto.upwardIntention
          state.screenDisplayItems.opq.opqOpqUniqueness = opqInfoDto.uniqueness
          state.screenDisplayItems.opq.opqOpqSocial = opqInfoDto.sociality
          state.screenDisplayItems.opq.opqOpqTrouble = opqInfoDto.toCare
          state.screenDisplayItems.opq.opqOpqAestheticValue =
            opqInfoDto.aestheticValue
          state.screenDisplayItems.opq.opqOpqChangeOriented =
            opqInfoDto.changeIntention
          state.screenDisplayItems.opq.opqOpqPlanning = opqInfoDto.planning
          state.screenDisplayItems.opq.opqOpqMargin = opqInfoDto.allowance
          state.screenDisplayItems.opq.opqOpqSuppression =
            opqInfoDto.suppression
          state.screenDisplayItems.opq.opqOpqAbilityTakeAction =
            opqInfoDto.activityPower
          state.screenDisplayItems.opq.opqOpqDetermination =
            opqInfoDto.decisionPower
          state.screenDisplayItems.opq.opqConsistency = opqInfoDto.consistency
          state.screenDisplayItems.opq.opqUpdateDate = opqInfoDto.updateTime
        }
        state.screenDisplayItems.opq.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（CAB）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（CAB）情報 */
      const cabInfoDto = data.aptitudeTestCABInfo
      if (cabInfoDto === null) {
        state.screenDisplayItems.cab.viewFlag = false
      } else {
        if (cabInfoDto.webCabDataId === null) {
          state.screenDisplayItems.cab = { ...cab }
        } else {
          // 値の設定
          switch (cabInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.cab.cabUpdatePath = webContents.MANUAL
              state.screenDisplayItems.cab.cabCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.cab.cabUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.cab.cabCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.cab.cabTestLanguage = cabInfoDto.examLanguage
          state.screenDisplayItems.cab.cabExamDateCab1 = cabInfoDto.cab1ExamDate
          state.screenDisplayItems.cab.cabExamDateCab2 = cabInfoDto.cab2ExamDate
          state.screenDisplayItems.cab.cabExamDateCab3 = cabInfoDto.cab3ExamDate
          state.screenDisplayItems.cab.cabExamDateCab4 = cabInfoDto.cab4ExamDate
          state.screenDisplayItems.cab.cabExamDateOpq = cabInfoDto.opqExamDate
          state.screenDisplayItems.cab.cabIntelligenceDeviationSynthesis =
            cabInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab1 =
            cabInfoDto.cab1DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab2 =
            cabInfoDto.cab2DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab3 =
            cabInfoDto.cab3DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab4 =
            cabInfoDto.cab4DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceStandardSynthesis =
            cabInfoDto.comprehensiveStandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab1 =
            cabInfoDto.cab1StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab2 =
            cabInfoDto.cab2StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab3 =
            cabInfoDto.cab3StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab4 =
            cabInfoDto.cab4StandardPoint
          state.screenDisplayItems.cab.cabComputerAptitudePg =
            cabInfoDto.pgCompetence
          state.screenDisplayItems.cab.cabComputerAptitudeSe =
            cabInfoDto.seCompetence
          state.screenDisplayItems.cab.cabComputerAptitudeCe =
            cabInfoDto.ceCompetence
          state.screenDisplayItems.cab.cabComputerAptitudePm =
            cabInfoDto.pmCompetence
          state.screenDisplayItems.cab.cabAbilityVitality = cabInfoDto.vitality
          state.screenDisplayItems.cab.cabAbilityPerPerson =
            cabInfoDto.perPerson
          state.screenDisplayItems.cab.cabAbilityTeamwork = cabInfoDto.teamwork
          state.screenDisplayItems.cab.cabAbilityCreativeThinking =
            cabInfoDto.creativeThinkingPower
          state.screenDisplayItems.cab.cabAbilityProblemSolving =
            cabInfoDto.problemSolvingPower
          state.screenDisplayItems.cab.cabAbilitySituationalAdaptability =
            cabInfoDto.statusAdaptationPower
          state.screenDisplayItems.cab.cabAbilityPressureResistance =
            cabInfoDto.proofStressToPressure
          state.screenDisplayItems.cab.cabAbilityOrganizingAbility =
            cabInfoDto.organizeAbility
          state.screenDisplayItems.cab.cabAbilityLeadership =
            cabInfoDto.commandPower
          state.screenDisplayItems.cab.cabOpqPersuasiveness =
            cabInfoDto.persuasionPower
          state.screenDisplayItems.cab.cabOpqExtrovert = cabInfoDto.extroversion
          state.screenDisplayItems.cab.cabOpqHumility = cabInfoDto.humility
          state.screenDisplayItems.cab.cabOpqConcreteThings =
            cabInfoDto.concreteThing
          state.screenDisplayItems.cab.cabOpqHuman = cabInfoDto.human
          state.screenDisplayItems.cab.cabOpqConceptuality =
            cabInfoDto.conceptuality
          state.screenDisplayItems.cab.cabOpqElaborate = cabInfoDto.accurate
          state.screenDisplayItems.cab.cabOpqAnxiousMind = cabInfoDto.worrier
          state.screenDisplayItems.cab.cabOpqOptimistic = cabInfoDto.optimistic
          state.screenDisplayItems.cab.cabOpqCompetitive =
            cabInfoDto.competitiveness
          state.screenDisplayItems.cab.cabOpqLeadership =
            cabInfoDto.coachingPower
          state.screenDisplayItems.cab.cabOpqFriendliness =
            cabInfoDto.friendship
          state.screenDisplayItems.cab.cabOpqConsultativeness =
            cabInfoDto.consultation
          state.screenDisplayItems.cab.cabOpqData = cabInfoDto.data
          state.screenDisplayItems.cab.cabOpqOrthodox = cabInfoDto.orthodox
          state.screenDisplayItems.cab.cabOpqCreative = cabInfoDto.creative
          state.screenDisplayItems.cab.cabOpqMeticulous = cabInfoDto.methodical
          state.screenDisplayItems.cab.cabOpqTough = cabInfoDto.tough
          state.screenDisplayItems.cab.cabOpqCritical = cabInfoDto.criticism
          state.screenDisplayItems.cab.cabOpqAscension =
            cabInfoDto.upwardIntention
          state.screenDisplayItems.cab.cabOpqUniqueness = cabInfoDto.uniqueness
          state.screenDisplayItems.cab.cabOpqSocial = cabInfoDto.sociality
          state.screenDisplayItems.cab.cabOpqTrouble = cabInfoDto.toCare
          state.screenDisplayItems.cab.cabOpqAestheticValue =
            cabInfoDto.aestheticValue
          state.screenDisplayItems.cab.cabOpqChangeOriented =
            cabInfoDto.changeIntention
          state.screenDisplayItems.cab.cabOpqPlanning = cabInfoDto.planning
          state.screenDisplayItems.cab.cabOpqMargin = cabInfoDto.allowance
          state.screenDisplayItems.cab.cabOpqSuppression =
            cabInfoDto.suppression
          state.screenDisplayItems.cab.cabOpqAbilityTakeAction =
            cabInfoDto.activityPower
          state.screenDisplayItems.cab.cabOpqDetermination =
            cabInfoDto.decisionPower
          state.screenDisplayItems.cab.cabConsistency = cabInfoDto.consistency
          state.screenDisplayItems.cab.cabUpdateDate = cabInfoDto.updateTime
        }
        state.screenDisplayItems.cab.viewFlag = true
      }


      /*----------------------*
       * メッセージエリアを設定 *
       *----------------------*/

      /** メッセージ情報 */
      const messageInfoList = data.messageInfoList

      let messageInfoDto
      const workMessageInfoDto: MessageData[] = []
      // 値の設定
      for (messageInfoDto of messageInfoList) {
        /** work */
        const workMessage = Object.assign({}, message)
        switch (messageInfoDto.sendAndReceiveDivision) {
          case msgContents.DIVISION_SEND:
            workMessage.sender = messageInfoDto.senderName
            switch (messageInfoDto.messageSendType) {
              case msgContents.SEND_TYPE_E_MAIL:
                workMessage.status = cmnContents.HYPHEN
                break
              default:
                switch (messageInfoDto.status) {
                  case msgContents.STATUS_SEND:
                    if (messageInfoDto.readFlag === msgContents.FLAG_READ) {
                      workMessage.status = msgContents.UNREAD
                    } else {
                      workMessage.status = msgContents.READ
                    }
                    break
                  default:
                    workMessage.status = cmnContents.HYPHEN
                    break
                }
            }
            switch (messageInfoDto.status) {
              case msgContents.STATUS_SEND:
                // phase2 start by 考
                if(messageInfoDto.convertingBatchCompleteFlag == msgContents.STRING_0 &&
                   !(messageInfoDto.messageType == msgContents.MESSAGETYPE_02 || messageInfoDto.messageType == msgContents.MESSAGETYOE_20)){
                    if(messageInfoDto.sendTimeFlag == msgContents.STRING_0){
                      workMessage.classification = msgContents.SENDING
                      workMessage.datetime = messageInfoDto.sendTime
                    }else{
                      workMessage.classification = msgContents.SEND_RESERVATION
                      workMessage.datetime = messageInfoDto.sendPlanTime
                    }
                }else{
                  workMessage.classification = msgContents.SEND
                  workMessage.datetime = messageInfoDto.sendTime
                }
                // phase2 end by 考
                break
              case msgContents.STATUS_SEND_RESERVATION:
                workMessage.classification = msgContents.SEND_RESERVATION
                workMessage.datetime = messageInfoDto.sendPlanTime
                break
              default:
                break
            }
            break
          case msgContents.DIVISION_RECEIV:
            workMessage.sender = msgContents.HYPHEN
            workMessage.classification = msgContents.RECEIVING
            if (messageInfoDto.readFlag === msgContents.FLAG_READ) {
              workMessage.status = msgContents.UNREAD
            } else {
              if (messageInfoDto.repliedFlag === msgContents.FLAG_UNREPLIED) {
                workMessage.status = msgContents.UNREPLIED
              } else {
                workMessage.status = msgContents.REPLIED
              }
            }
            workMessage.datetime = messageInfoDto.receivingTime
            break
          default:
            break
        }
        if (messageInfoDto.messageSendType === msgContents.SEND_TYPE_E_MAIL) {
          workMessage.destination = msgContents.E_MAIL
          workMessage.status = msgContents.HYPHEN
        }
        workMessage.messageId = messageInfoDto.messageId
        workMessage.messageSendId = messageInfoDto.messageSendId
        workMessage.type = messageInfoDto.messageType
        workMessage.entryType = messageInfoDto.entryType
        workMessage.subject = messageInfoDto.subject
        if (!isEmpty(messageInfoDto.maxSequence)) {
          workMessage.attachedFile = true
        }
        workMessage.selectionFlow = messageInfoDto.selectionName
        workMessage.sysVersionNumber = messageInfoDto.sysVersionNumber

        workMessageInfoDto.push(workMessage)
      }

      state.screenDisplayItems.message = workMessageInfoDto.map(item => ({
        classification: item.classification,
        destination: item.destination,
        status: item.status,
        messageId: item.messageId,
        messageSendId: item.messageSendId,
        type: item.type,
        entryType: item.entryType,
        subject: item.subject,
        attachedFile: item.attachedFile,
        selectionFlow: item.selectionFlow,
        sender: item.sender,
        datetime: item.datetime,
        // phase2 start
        messageRepliesNecessary: '',
        messageRepliesLimit: '',
        messageScoutCharacter: '',
        sendingResult: '',
        // phase2 end
        sysVersionNumber: item.sysVersionNumber,
      }))

      if (state.screenId === screenIdContents.ENTRY_DETAIL) {
        /*----------------------*
         * 共通ヘッダエリアを設定 *
         *----------------------*/
        // listIndex=0：「前へ」ボタンを非活性
        state.screenDisplayItems.commonHeader.goBack =
          state.currentApplicantsListInfo.listIndex === 0 ? false : true
        // listIndex=一覧で表示した求職者数：「次へ」ボタンを非活性
        state.screenDisplayItems.commonHeader.goNext =
          state.currentApplicantsListInfo.listIndex ===
          state.currentApplicantsListInfo.applicantsListInfo.length - 1
            ? false
            : true

        /*------------------------*
         * プロフィールエリアを設定 *
         *------------------------*/

        // AWS（S3）から画像を取得
        if (profileInfoDto.facePhotoManagementId) {
          state.screenDisplayItems.profile.profileFacePhoto = `${magiContants.MCAXS050_S3_IMAGES_URL}?photoManagementId=${profileInfoDto.facePhotoManagementId}&num=${Math.random()}`
        } else {
          state.screenDisplayItems.profile.profileFacePhoto = cmnContents.BLANK
        }
        state.screenDisplayItems.profile.profileFacePhotoUploadViewFlag = !serviceMode
        state.screenDisplayItems.profile.profileFacePhotoUploadButtonDisabledFlag = profileInfoDto.scanVirusFlag
        /** プロフィール（応募者）情報 */
        const entryInfoDto: EntryInfo = data.entryInfo

        if (compareDeep(profileInfoDto, entryInfoDto)) {
          state.screenDisplayItems.profile.profileWhenApplyingFlag = true
        } else {
          let profileWhenPrefecturesName = cmnContents.BLANK
          let profileWhenAddressAfterCity = cmnContents.BLANK
          let profileWhenBirthdate = cmnContents.HYPHEN
          let profileWhenSex = cmnContents.BLANK
          if (!isEmpty(entryInfoDto.prefecturesName)) {
            profileWhenPrefecturesName = entryInfoDto.prefecturesName
          }
          if (!isEmpty(entryInfoDto.addressAfterCity)) {
            profileWhenAddressAfterCity = entryInfoDto.addressAfterCity
          }
          if (!isEmpty(entryInfoDto.birthdate)) {
            profileWhenBirthdate = entryInfoDto.birthdate
          }
          if (profileContents.UNKNOWN !== entryInfoDto.sex) {
            profileWhenSex = entryInfoDto.sex
          }

          // 応募「時」情報の設定
          let profileWhenApplyingFullNameKana = cmnContents.BLANK
          if (!isEmpty(entryInfoDto.familyNameKana) && !isEmpty(entryInfoDto.nameKana)) {
            profileWhenApplyingFullNameKana = `(${entryInfoDto.familyNameKana} ${entryInfoDto.nameKana})`
          }
          else if (!isEmpty(entryInfoDto.familyNameKana)) {
            profileWhenApplyingFullNameKana = `(${entryInfoDto.familyNameKana})`
          }
          else if (!isEmpty(entryInfoDto.nameKana)) {
            profileWhenApplyingFullNameKana = `(${entryInfoDto.nameKana})`
          }
              state.screenDisplayItems.profile.profileWhenApplyingFullName = `${entryInfoDto.familyName} ${entryInfoDto.name}${profileWhenApplyingFullNameKana}`
          state.screenDisplayItems.profile.profileWhenApplyingPostalCode =
            entryInfoDto.postalCode
          state.screenDisplayItems.profile.profileWhenApplyingStreetAddress = `${profileWhenPrefecturesName}${profileWhenAddressAfterCity}`
          state.screenDisplayItems.profile.profileWhenApplyingBirthDateSex = `${profileWhenBirthdate} （${getAge(
            profileWhenBirthdate
          )}歳） ${profileWhenSex}`
          state.screenDisplayItems.profile.profileWhenApplyingSpouse =
            entryInfoDto.spouse
          state.screenDisplayItems.profile.profileWhenApplyingAnnualIncome =
            entryInfoDto.currentAnnualIncome
          state.screenDisplayItems.profile.profileWhenApplyingCompaniesExperience =
            entryInfoDto.companiesExperience
          state.screenDisplayItems.profile.profileWhenApplyingEmploymentStatus =
            entryInfoDto.employmentStatus
          state.screenDisplayItems.profile.profileWhenApplyingMobilePhone =
            entryInfoDto.phoneNumber3
          state.screenDisplayItems.profile.profileWhenApplyingFixedLinePhone =
            entryInfoDto.phoneNumber1
          state.screenDisplayItems.profile.profileWhenApplyingMobileEmail =
            entryInfoDto.mailAddress2
          state.screenDisplayItems.profile.profileWhenApplyingEmail =
            entryInfoDto.mailAddress1
          state.screenDisplayItems.profile.profileWhenApplyingSchoolInformation =
            entryInfoDto.educationalBackgroundSummary
          state.screenDisplayItems.profile.profileWhenApplyingFlag = false
        }
        if (entryInfoDto.suggestedWorkingPlace.length === 0) {
          state.screenDisplayItems.profile.desiredWorkRegion = ''
        } else {
          const suggestedWorkingPlace = entryInfoDto.suggestedWorkingPlace.join(
            ','
          )
          const desiredWorkRegion = suggestedWorkingPlace.replace(/,/g, '／')
          state.screenDisplayItems.profile.desiredWorkRegion = desiredWorkRegion
        }
        state.screenDisplayItems.profile.desiredSalary =
          entryInfoDto.suggestedAnnualIncome
        state.screenDisplayItems.profile.timeToChangeJob =
          entryInfoDto.suggestedJobChangeTime

        /*----------------------*
         * アンケートエリアを設定 *
         *----------------------*/
        /** アンケート情報 */
        const questionInfoList = data.questionInfoList

        if (questionInfoList != null) {
          state.screenDisplayItems.question = questionInfoList.map(
            (item, index) => ({
              questionNo: index + 1,
              questionContent: item.question,
              questionAnswer: item.answer,
            })
          )
        }

        /*----------------------*
         * 職務経歴エリアを設定 *
         *----------------------*/
        /** 職務経歴情報 */
        const jobCareerInfoList = data.jobCareerInfoList

        // 値の設定
        state.screenDisplayItems.jobCareer.experienceCompanies =
          entryInfoDto.companiesExperience
        state.screenDisplayItems.jobCareer.experienceOccupation =
          entryInfoDto.experienceJobCategorySummary

        if (jobCareerInfoList != null) {
          state.screenDisplayItems.jobCareer.jobCareerDetail = jobCareerInfoList.map(
            (item, index) => ({
              jobCareerNumberOfCompanies: `${index + 1}社目`,
              jobCareerCompanyName: item.companyName,
              jobCareerEnrollmentPeriod: item.enrollmentPeriod,
              jobCareerContent: item.dutyCareerSummary,
            })
          )
        } else {
          state.screenDisplayItems.jobCareer.jobCareerDetail = []
        }

        /*----------------------*
         * 資格・スキルエリアを設定 *
         *----------------------*/
        if (isEmpty(entryInfoDto.tag4) && isEmpty(entryInfoDto.tag3)) {
          state.screenDisplayItems.qualificationsSkills.qualificationsSkillsAvailability = true
        } else {
          state.screenDisplayItems.qualificationsSkills.qualificationsSkillsLanguage =
            entryInfoDto.tag4
          state.screenDisplayItems.qualificationsSkills.qualificationsSkillsEligibility =
            entryInfoDto.tag3
          state.screenDisplayItems.qualificationsSkills.qualificationsSkillsAvailability = false
        }

        /*----------------------*
         * 自己PRなどエリアを設定 *
         *----------------------*/
        if (
          isEmpty(entryInfoDto.wishIncentive) &&
          isEmpty(entryInfoDto.selfPr) &&
          isEmpty(entryInfoDto.notice)
        ) {
          state.screenDisplayItems.selfPR.selfPRAvailability = true
        } else {
          state.screenDisplayItems.selfPR.selfPRMotive =
            entryInfoDto.wishIncentive
          state.screenDisplayItems.selfPR.selfPRContent = entryInfoDto.selfPr
          state.screenDisplayItems.selfPR.selfPRRemarks = entryInfoDto.notice
          state.screenDisplayItems.selfPR.selfPRAvailability = false
        }
      }

      /*------------------------------*
       * アコーディオン開閉状態を設定 *
       *------------------------------*/
      //選考フロー(応募者詳細(転職)のみ)
      data.selectionFlow.selectionFlowColumnList.forEach((i, index) => {
        const toggleArticle = document.getElementById(
          `toggle-article-selection-${index}`
        ) as HTMLInputElement
        if (toggleArticle !== null) {
          toggleArticle.checked = true
        }
      })
      //登録タグ
      const toggleArticleTag = document.getElementById(
        'toggle-article-02'
      ) as HTMLInputElement
      if (toggleArticleTag !== null) {
        toggleArticleTag.checked = true
      }
      //イメジス
      const toggleArticleImages = document.getElementById(
        'toggle-article-21'
      ) as HTMLInputElement
      if (toggleArticleImages !== null) {
        toggleArticleImages.checked = true
      }
      //GAB
      const toggleArticleGAB = document.getElementById(
        'toggle-article-22'
      ) as HTMLInputElement
      if (toggleArticleGAB !== null) {
        toggleArticleGAB.checked = true
      }
      //OPQ
      const toggleArticleOPQ = document.getElementById(
        'toggle-article-23'
      ) as HTMLInputElement
      if (toggleArticleOPQ !== null) {
        toggleArticleOPQ.checked = true
      }
      //CAB
      const toggleArticleCAB = document.getElementById(
        'toggle-article-24'
      ) as HTMLInputElement
      if (toggleArticleCAB !== null) {
        toggleArticleCAB.checked = true
      }
      //応募時情報
      const toggleArticleProfileWhenApplying = document.getElementById(
        'toggle-article-03'
      ) as HTMLInputElement
      if (toggleArticleProfileWhenApplying !== null) {
        toggleArticleProfileWhenApplying.checked = true
      }
      //職務経歴
      const toggleArticleJobcareer = document.getElementsByName(
        'toggle-article-jobcareer'
      )
      toggleArticleJobcareer.forEach(
        (value: HTMLElement, key: number, parent: NodeListOf<HTMLElement>) => {
          ;(value as HTMLInputElement).checked = true
        }
      )

      /*---------------------------------*
       * 迷惑行為通報エリア表示を設定 *
       *---------------------------------*/
      // Marchモードフラグが1(有効)の時は迷惑行為通報エリアを非表示にする
      state.screenDisplayItems.nuisanceAreaViewFlag = !serviceMode

      return state
    },
    doSelectionFlowChange(
      state,
      action: PayloadAction<{
        screenTransitionRequest: MCAXS050ScreenTransitionRequest
        initRequest: MCAXS280InitRequest
        setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
      }>
    ) {
      console.log('reducer処理(doSelectionFlowChange)')
      return state
    },
    mcaxs620DoSelectionFlowChange(
      state,
      action: PayloadAction<{
        screenTransitionRequest: MCAXS620ScreenTransitionRequest
        initRequest: MCAXS280InitRequest
        setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
      }>
    ) {
      console.log('reducer処理(doSelectionFlowChange)')
      return state
    },
    doSelectionFlowRouteChange(
      state,
      action: PayloadAction<MCAXS050RouteScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowRouteChange)')
      return state
    },
    mcaxs620DoSelectionFlowRouteChange(
      state,
      action: PayloadAction<MCAXS620RouteScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowRouteChange)')
      return state
    },
    doSelectionFlowExclude(
      state,
      action: PayloadAction<MCAXS050UpdateExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowExclude)')
      return state
    },
    mcaxs620DoSelectionFlowExclude(
      state,
      action: PayloadAction<MCAXS620UpdateExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowExclude)')
      return state
    },
    doSelectionFlowUnExclude(
      state,
      action: PayloadAction<MCAXS050UpdateUnExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowUnExclude)')
      return state
    },
    mcaxs620DoSelectionFlowUnExclude(
      state,
      action: PayloadAction<MCAXS620UpdateUnExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowUnExclude)')
      return state
    },
    setOpenManageListDialog(state, action: PayloadAction<boolean>) {
      state.openManageListDialog = action.payload
      return state
    },
    setCurrentApplicantsListInfo(
      state,
      action: PayloadAction<CurrentApplicantsListInfo>
    ) {
      state.currentApplicantsListInfo = action.payload
      console.log('reducer処理(setCurrentApplicantsListInfo)')
      return state
    },
    setApplicantDetailsRequest(
      state,
      action: PayloadAction<ApplicantDetailsRequest>
    ) {
      state.applicantDetailsRequest = action.payload
      console.log('reducer処理(setApplicantDetailsRequest)')
      return state
    },
    setShowMCAXS050(state, action: PayloadAction<boolean>) {
      state.showMCAXS050 = action.payload
      console.log('reducer処理(setShowMCAXS050)')
      return state
    },
    setShowMCAXS620(state, action: PayloadAction<boolean>) {
      state.showMCAXS620 = action.payload
      console.log('reducer処理(setShowMCAXS620)')
      return state
    },
    updateStatusHsc(state, action: PayloadAction<number>) {
      state.screenDisplayItems.message[action.payload].status = '未返信'
      return state
    },
    sendMessageChk(state, action: PayloadAction<
      {
        request : MCAXS050SendMessageRequest | undefined
        onSendMessage : () => void
      }>) {
      return state
    },
    setSendMessageChk(state, action: PayloadAction<string[]>) {
      state.inValidCheckCount = action.payload;
      return state
    },
    setSendMessageErrorFlag(state, action: PayloadAction<boolean>) {
      state.sendMessageErrorFlag = action.payload
      return state
    },
    //ワンクリック不採用通知
    notAdoptedNotification(state, action: PayloadAction<NotAdoptedNotificationRequest>) {
      return state
    },
  },
})

function compareDeep(
  profileInfoDto: ProfileInfo,
  entryInfoDto: EntryInfo
): boolean {
  console.log('reducer処理(doDeepEqual)')
  let equal = false
  let cnt = 0

  equal = profileInfoDto.familyName === entryInfoDto.familyName ? true : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.name === entryInfoDto.name ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.familyNameKana === entryInfoDto.familyNameKana ? true : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.nameKana === entryInfoDto.nameKana ? true : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.postalCode === entryInfoDto.postalCode ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.prefecturesName === entryInfoDto.prefecturesName
      ? true
      : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.addressAfterCity === entryInfoDto.addressAfterCity
      ? true
      : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.birthdate === entryInfoDto.birthdate ? true : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.sex === entryInfoDto.sex ? true : false
  cnt = equal ? cnt : cnt + 1
  equal = profileInfoDto.spouse === entryInfoDto.spouse ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.currentAnnualIncome === entryInfoDto.currentAnnualIncome
      ? true
      : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.companiesExperience === entryInfoDto.companiesExperience
      ? true
      : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.employmentStatus === entryInfoDto.employmentStatus
      ? true
      : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.phoneNumber3 === entryInfoDto.phoneNumber3 ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.phoneNumber1 === entryInfoDto.phoneNumber1 ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.mailAddress2 === entryInfoDto.mailAddress2 ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.mailAddress1 === entryInfoDto.mailAddress1 ? true : false
  cnt = equal ? cnt : cnt + 1
  equal =
    profileInfoDto.educationalBackgroundSummary ===
    entryInfoDto.educationalBackgroundSummary
      ? true
      : false
  cnt = equal ? cnt : cnt + 1

  return cnt === 0 ? true : false
}

export const {
  init,
  setInit,
  setInitMarchParam,
  setInitDisplay,
  setScreenId,
  setCode,
  setBusinessCheckResult,
  setDoInit,
  setScreenDisplayItems,
  doSelectionFlowChange,
  doSelectionFlowRouteChange,
  doSelectionFlowExclude,
  doSelectionFlowUnExclude,
  mcaxs620Init,
  mcaxs620DoSelectionFlowChange,
  mcaxs620DoSelectionFlowRouteChange,
  mcaxs620DoSelectionFlowExclude,
  mcaxs620DoSelectionFlowUnExclude,
  setOpenManageListDialog,
  setCurrentApplicantsListInfo,
  setApplicantDetailsRequest,
  setShowMCAXS050,
  setShowMCAXS620,
  updateStatusHsc,
  sendMessageChk,
  setSendMessageChk,
  setSendMessageErrorFlag,
  notAdoptedNotification,
} = entryDetailSlice.actions
export default entryDetailSlice.reducer
