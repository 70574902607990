import React, { useMemo, useEffect, useState } from 'react'
import { ModalDialog } from 'sp/components/ModalDialog'
import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEmpty } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import {
  magiContants as contants,
  BROADCAST_CHANNEL_CONFIG,
} from 'utils/contants'
import { BroadcastChannel } from 'broadcast-channel'
import { MCAZS101NewRequest } from 'types/MCAZS101/MCAZS101NewRequest'
import { MCAZS101OrverWriteRequest } from 'types/MCAZS101/MCAZS101OrverWriteRequest'
import * as apiConfig from 'pages/MCAZS101/apiConfig'
import * as viewConfig from 'pages/MCAZS101/viewConfig'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { ProgressStatus, LoadingDialog } from 'sp/components'
import {
  initCondition as InitCondition,
  processChangeMessageInit,
  processChangeMessageListInit,
  UnifiedInit,
  progressChangeUpdateMessage,
  leftEmpty,
  toLeft,
  DecisionProcessChangeQueryResultList,
  leftReset,
  setMCAXS340Open,
} from 'reducers/selectionStatusUpdateReducer'
import {
  getInit,
  newTemplete,
  orverWrite,
  chkNotAdoptedNotificationType,
  setChkNotAdoptedNotification,
} from 'reducers/messageSendCompReducer'
import {
  textMap,
  staticTextMap,
  initialManagerFlowStartCommentData,
} from 'pages/MCAZS101/formConfig'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Formik } from 'formik'
import Select from './Select'
import { MCAXS340InitRequest } from 'pages/MCAXS340/formConfig'
import {
  initRMFInfo,
  setinitRequest,
} from 'reducers/recruitmentManagementFlagChangeReducer'
import MCAXS340 from 'sp/pages/MCAXM340'
import * as yup from 'yup'
import MCAXS110 from 'pages/MCAXS110' // 選考ステップを変更(march)
import { PropsSend } from 'sp/components/ProgressStatus/interface'

const useStyles = makeStyles(theme => ({
  select: {
    position: 'absolute',
    zIndex: 9,
    marginBottom: '5px',
    whiteSpace: 'nowrap',
    color: 'black',
    width: '100%',
    '& div': {
      cursor: 'pointer!important',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
  button: {
    paddingTop: '0',
    paddingBottom: '0',
    border: '1px solid rgba(153,153,153,.3)',
  },
  sendCount: {
    backgroundColor: '#7f7f7f',
    margin: '10px 0',
    padding: '5px 15px',
    '& span': {
      marginRight: '30px',
    },
  },
  textInput: {
    verticalAlign: 'top',
    marginRight: '10px !important',
  },
  container: {
    height: '500px !important',
    '& .style-progress-status': {
      height: 'auto',
      minHeight: '100px',
    },
  },
  noMarginHeader: {
    marginTop: '15px !important',
  },
  description: {
    marginBottom: '10px !important',
  },
  overwritingButton: {
    marginBottom: '15px !important',
  },
  hidden: {
    display: 'none',
  },
  messageBox: {
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 5,
  },
  paddingLeft: {
    padding: theme.spacing(2, 0, 2, 3),
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    display: 'inline-block',
  },
  paddingRight: {
    padding: theme.spacing(2, 3, 2, 0),
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    display: 'inline-block',
  },
  messageDiv: {
    marginBottom: '22px',
    width: '300px',
  },
  message: {
    color: '#e53935',
    fontSize: '12px',
  },
  textInputError: {
    marginBottom: '0px !important',
  },
  lineBreak: {
    whiteSpace: 'pre-line',
  },
  changeBtn: {
    float: 'right',
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 196,
  },
}))

interface Props {
  mCAZS101Open: boolean
  onClose: () => void
  messageConfVal: apiConfig.MessageConfVal
  registerValue: {
    messageType: string[]
    messageTemplateSettingId: string | null
    destinationId: string[]
    selectionManagementId: string[]
    destination: number
    // [phase2] start
    sendToNonMember: number
    // [phase2] end
    senderCompanyName: string
    senderMailAddress: string
    allowReply: number
    subject: string
    body: string
    attachment: any[]
    attachmentId: Array<string | null>
    templateAttachmentId: string[]
    mynaviAttachedOption: string
    homePageTitle1: string | null
    homePageTitle2: string | null
    homePageTitle3: string | null
    homePageUrl1: string | null
    homePageUrl2: string | null
    homePageUrl3: string | null
    barcodeDisplayFlag: number
    sendTimer: number
    sendPlanTime: string | null
    notifyNotification: number
    notifyMailAddress: string
    requiredTimeId: string
    interviewContents: string
    interviewDateN: (string | null)[]
    interviewLocationIdN: (number | null)[]
    chargeN: string[]
    fileManagementId: string[]
    entryId: number[]
    interviewScheduleAdjustmentId: number
    sysVersionNumber: string
    messageSendId: string
    interviewScheduleAdjustmentStatus: string
    interviewScheduleAdjustmentSelection: string
    adjustmentInterviewScheduleAdjustmentId: string
    interviewDurationId: string | null
    interviewDetail: string
    interviewScheduleId: number[]
    interviewStartTime: (string | null)[]
    interviewStaffName: string[]
    interviewScheduleStatus: string[]
    manipulationKubun: string
    seminarId: number[]
    entryBoxId: number[]
    sendTimeFlag: string
    replyPermitFlag: string
    destinationDivision: string
    manualRegistrationFlag: string[]
    decisionDivision: string[]
    progressStatusSettingId: number[]
    notSend: boolean[]
    notificationMailAddress: string
    replyFromMessageSendId: string | null
    selectionFlowSettingId: number[]
    title: viewConfig.Title
    interviewSchedule: viewConfig.InterviewSchedule
    messageInput: viewConfig.MessageInput
    sendTargetConfirmation: viewConfig.SendTargetConfirmation
    sendTimeInput: viewConfig.SendTimeInput
    recruitmentManagementFlag: viewConfig.RecruitmentManagementFlag[]
    seminarSchedule: viewConfig.SeminarSchedule[]
    seminar: viewConfig.Seminar[]
    signature: viewConfig.Signature[]
    jobSeekerId: string[]
    replyNecessaryFlag: string
    replyLimitTime: string | null
    notAdoptedNotificationType: string | null
  }
  setIsCompletedSending: (value: boolean) => void
}

export default ({
  mCAZS101Open,
  onClose,
  messageConfVal,
  registerValue,
  setIsCompletedSending,
}: Props) => {
  const { mCAXS340Open } = useSelector(
    (state: RootState) => state.selectionStatusUpdate
  )
  const dispatch = useDispatch()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogRegisterOpen, setConfirmDialogRegisterOpen] = useState(
    false
  )
  const [confirmDialogOverwriteOpen, setConfirmDialogOverwriteOpen] = useState(
    false
  )
  const [
    confirmDialogOverwriteMCBOpen,
    setConfirmDialogOverwriteMCBOpen,
  ] = useState(false)
  const [, setSelectionOutDto] = useState<any>(null)
  const [, setProgressTypeBefore] = useState('')
  const [, setProgressTypeAfter] = useState('')
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const processChangeResult = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResultList
  )
  const selectionFlowInitData = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResultList
        .selectionFlowInitData
  )
  const [selectionFlowInitDataTemp, setSelectionFlowInitDataTemp] = useState(
    processChangeResult.selectionFlowInitData
  )
  const decisionDivisionList = useSelector(
    (state: RootState) => state.selectionStatusUpdate.decisionDivisionList
  )
  const [settingId, setSettingId] = useState<number>(-1)
  const [progressName, setProgressName] = useState('')
  const [inputString, setInputString] = useState<string>('')
  let selectMagResult = initialManagerFlowStartCommentData
  if (processChangeResult.selectMagSelectSelectionInfoOutDto[0] != undefined) {
    selectMagResult = processChangeResult.selectMagSelectSelectionInfoOutDto[0]
  }
  const [shouldShowProgress, setShouldShowProgress] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('selecting')
  const [, setGroupHorizontalReset] = React.useState(false)
  const [open, setOpen] = useState(false)

  const [selectTemp, setSelectTemp] = useState('')
  const [selectTemplateType, setSelectTemplateType] = useState('')
  const [sysVersionNumber, setsysVersionNumber] = useState('')
  const [nonAdoptedId, setNonAdoptedId] = useState<number>(0)
  const [nonAdoptedValue, setNonAdoptedValue] = useState<number>(0)
  const [declineId, setDeclineId] = useState<number>(0)
  const [declineValue, setDeclineValue] = useState<number>(0)

  const initResult = useSelector(
    (state: RootState) => state.messageSendComp.initResult
  )
  const messageTemplate = registerValue.title.messageTemplate
  const optionList: {
    value: string
    label: string
    templateType: string
    sysVersionNumber: string
  }[] = [
      {
        value: '',
        label: '(以下から選択)',
        templateType: '',
        sysVersionNumber: '',
      },
    ]
  const registerResult = useSelector(
    (state: RootState) => state.messageSendConfirm.messageComfRegisterResult
  )
  const [error, setError] = useState({ isError: false, message: '' })
  messageTemplate.forEach(e => {
    optionList.push({
      value: e.messageTemplateSettingId.toString(),
      label: e.sequence ? e.sequence + '. ' + e.templateName : e.templateName,
      templateType: e.templateType,
      sysVersionNumber: e.sysVersionNumber,
    })
  })
  const chkNotAdoptedNotification = useSelector(
    (state: RootState) => state.messageSendComp.chkNotAdoptedNotification
  )
  const [
    notAdoptedNotificationDialogOpen,
    setnotAdoptedNotificationDialogOpen,
  ] = useState(false)
  const [
    notAdoptedNotificationPropsNew,
    setNotAdoptedNotificationPropsNew,
  ] = useState('')

  // 一括送信フラグ
  const [, setBulkSendFlg] = useState(false)

  const channel = useMemo(
    () => new BroadcastChannel(BROADCAST_CHANNEL_CONFIG.NAME),
    []
  )

  useEffect(() => {
    return () => {
      channel.close()
    }
  }, [])

  const classes = useStyles()

  useEffect(() => {
    channel.postMessage(BROADCAST_CHANNEL_CONFIG.MESSAGE)
    if (
      initResult.progressStatusManagementDtos &&
      initResult.progressStatusManagementDtos.length > 0 &&
      initResult.selectionManagementDtos &&
      initResult.selectionManagementDtos.length > 0 &&
      initResult.selectionStepDtos &&
      initResult.selectionStepDtos.length > 0
    ) {
      setBulkSendFlg(true)
      const sendTimeFlag = registerValue.sendTimeFlag
      if (sendTimeFlag && sendTimeFlag === contants.SEND_TIME_FLAG_IMMEDIATE) {
        const targetList = registerValue.sendTargetConfirmation.sendTarget
        if (
          targetList &&
          targetList.every(
            item =>
              item.selectionClassification !==
              contants.SELECTION_CLASSIFICATION_QUESTION
          )
        ) {
          setShouldShowProgress(true)
        }
      }
    }
  }, [initResult])

  useEffect(() => {
    if (registerValue.notAdoptedNotificationType) {
      dispatch(openSnackbar(getMessage('MCAZS101-011')))
    }
  }, [decisionDivisionList])

  useEffect(() => {
    setIsCompletedSending(true)
    const selectionManagementId = registerValue.selectionManagementId[0]

    const initCondition: InitCondition = {
      selectionManagementId: Number(selectionManagementId),
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(processChangeMessageInit(initCondition))

    const unifiedInit: UnifiedInit = {
      selectionManagementIdArray: registerValue.selectionManagementId.map(
        item => Number(item)
      ),
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(processChangeMessageListInit(unifiedInit))

    dispatch(getInit(messageConfVal))
  }, [dispatch])

  useEffect(() => {
    if (
      notAdoptedNotificationPropsNew == null ||
      notAdoptedNotificationPropsNew === ''
    ) {
      // 処理なし
    } else {
      if (
        chkNotAdoptedNotification == null ||
        chkNotAdoptedNotification === ''
      ) {
        handleProgressChangeChk(notAdoptedNotificationPropsNew)
      } else {
        setnotAdoptedNotificationDialogOpen(true)
      }
    }
  }, [chkNotAdoptedNotification])

  useEffect(() => {
    if (
      processChangeResult.selectionFlowInitData.rightProgressList.length > 1
    ) {
      const nonAdoptedElement = processChangeResult.selectionFlowInitData.rightProgressList.find(
        item => item.name === textMap.noAdopt.messageOne
      )
      if (nonAdoptedElement) {
        setNonAdoptedValue(nonAdoptedElement.settingId)
        setNonAdoptedId(nonAdoptedElement.id)
      }

      const declineElement = processChangeResult.selectionFlowInitData.rightProgressList.find(
        item => item.name === textMap.dismiss.messageOne
      )
      if (declineElement) {
        setDeclineValue(declineElement.settingId)
        setDeclineId(declineElement.id)
      }
    }
  }, [processChangeResult.selectionFlowInitData.rightProgressList])

  // 「新規保存する」ボタン押下
  const handleNew = () => {
    if (!error.isError) {
      if (inputString == null || inputString.trim() === '') {
        dispatch(openModal(getMessage('MCAZS101-001')))
        return
      }
      setConfirmDialogRegisterOpen(true)
    } else {
      return
    }
  }

  const templateNameOption = useSelector(
    (state: RootState) => state.messageSendComp.templateNameOption
  )
  // 「上書き保存する」ボタン押下
  const handleOrverWrite = () => {
    if (null == templateNameOption || isEmpty(templateNameOption.value)) {
      dispatch(openModal(getMessage('MCAZS101-005')))
      return
    } else {
      setSelectTemp(templateNameOption.value)
      setSelectTemplateType(templateNameOption.templateType)
      setsysVersionNumber(templateNameOption.sysVersionNumber)
    }

    if (
      templateNameOption.templateType ===
      contants.DESTINATION_DIVISION_MYCAREERBOX
    ) {
      setConfirmDialogOverwriteMCBOpen(true)
    } else {
      setConfirmDialogOverwriteOpen(true)
    }
  }

  // 「送信画面を閉じる」ボタン押下
  const handleClose = () => {
    onClose()
  }

  //管理項目一覧を見るボタン押下
  const inputManagementItem = () => {
    const param: MCAXS340InitRequest = {
      jobSeekerId: [],
      entryId: registerValue.entryId,
      selectionManagementId: registerValue.selectionManagementId.map(Number),
      recruitmentManagementFlagSettingId: [],
      recruitmentManagementFlagOptionSettingId: [],
      settingString: [],
      settingNumber: [],
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(initRMFInfo({ request: param, id: 'MCAZS101' }))
    dispatch(setinitRequest(param))
  }

  const messageTemplateNameYup = yup.object().shape({
    messageTemplateName: yup.string(),
  })
  const transInputString = (e: any) => {
    setInputString(e.target.value)
    setError({ isError: false, message: '' })
    messageTemplateNameYup
      .validate({ messageTemplateName: e.target.value })
      .then()
      .catch(err => {
        setError({ isError: true, message: err.errors })
      })
  }

  const inArray = (content: string, array: string[]) => {
    for (var i in array) {
      if (content === array[i]) {
        return true
      }
    }
    return false
  }

  const handleProcessChangeConfirm = (settingId: number) => {
    if (settingId != selectMagResult.progressStatusSettingId) {
      const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
      const selectionOutDto = selectMagResult
      setSelectionOutDto(selectionOutDto)
      //表示順(変更元)
      let displayOrderBefore = 0
      //進捗種別(変更元)
      let progressTypeBefore = ''
      //判定区分種別(変更元)
      let decisionDivisionTypeBefore = ''
      //選考ステップ設定ID(変更元)
      let progressStatusSettingIdBefore = -1
      //選考ステップ設定ID(変更先)
      let progressStatusSettingIdAfter = -1
      //進捗種別(変更先)
      let progressTypeAfter = ''
      //表示順(変更先)
      let displayOrderAfter = 0
      //進捗名(変更先)
      let progressNameAfter = ''
      //選考名
      let selectionName = ''
      //判定区分種別(変更先)
      let decisionDivisionTypeAfter = ''
      for (let i in flowList) {
        //進捗の変更元情報を取得する
        if (
          selectionOutDto.progressStatusSettingId ==
          flowList[i].progressStatusSettingId
        ) {
          //表示順(変更元)
          displayOrderBefore = flowList[i].displayOrder
          //進捗種別(変更元)
          progressTypeBefore = flowList[i].progressType
          //判定区分種別(変更元)
          decisionDivisionTypeBefore = flowList[i].decisionDivisionType
          progressStatusSettingIdBefore = flowList[i].progressStatusSettingId
        }
        //進捗の変更先情報を取得する
        if (settingId == flowList[i].progressStatusSettingId) {
          //選考ステップ設定ID(変更先)
          progressStatusSettingIdAfter = flowList[i].progressStatusSettingId
          //進捗種別(変更先)
          progressTypeAfter = flowList[i].progressType
          //表示順(変更先)
          displayOrderAfter = flowList[i].displayOrder
          //進捗名(変更先)
          progressNameAfter = flowList[i].progressName
          //選考名
          selectionName = flowList[i].selectionName
          //判定区分種別(変更先)
          decisionDivisionTypeAfter = flowList[i].decisionDivisionType
        }
      }
      const JsonProcessChangeResult = JSON.parse(
        JSON.stringify(processChangeResult)
      )
      const data: DecisionProcessChangeQueryResultList = {
        selectionManagementIdArray:
          JsonProcessChangeResult.selectionManagementIdArray,
        selectMagSelectFlowInfoOutDtoList:
          JsonProcessChangeResult.selectMagSelectFlowInfoOutDtoList,
        selectMagSelectSelectionInfoOutDto:
          JsonProcessChangeResult.selectMagSelectSelectionInfoOutDto,
        progressStatusSettingIdBefore: progressStatusSettingIdBefore, //選考ステップ設定ID(変更元)
        passingStatusDivisionId:
          JsonProcessChangeResult.passingStatusDivisionId, //合否区分ID
        historyInsertDivisionId: '0', //履歴登録区分("1":登録)
        progressTypeBefore: progressTypeBefore, //進捗種別(変更元)
        displayOrderBefore: displayOrderBefore, //表示順(変更元)
        progressNameBefore: '', //進捗名(変更元)
        decisionDivisionTypeBefore: decisionDivisionTypeBefore, //判定区分種別(変更元)
        progressStatusSettingIdAfter: progressStatusSettingIdAfter, //選考ステップ設定ID(変更先)
        progressTypeAfter: progressTypeAfter, //進捗種別(変更先)
        displayOrderAfter: displayOrderAfter, //表示順(変更先)
        progressNameAfter: progressNameAfter, //進捗名(変更先)
        selectionName: selectionName, //選考名
        decisionDivisionTypeAfter: decisionDivisionTypeAfter, //判定区分種別(変更先)
        sysVersionNumberList: JsonProcessChangeResult.sysVersionNumberList, //バージョン番号と判定区分情報
        selectionFlowInitData: JsonProcessChangeResult.selectionFlowInitData, //選考フロー初期化情報
        decisionDivisionType: JsonProcessChangeResult.decisionDivisionType, //判定区分種別(初期表示)
        inValidCheckCount: JsonProcessChangeResult.inValidCheckCount, //メッセージ送信エラー情報
        initResultDto: JsonProcessChangeResult.initResultDto,
      }
      //1.5 "進捗"クリック
      dispatch(progressChangeUpdateMessage(data))
      setGroupHorizontalReset(true)
      toolTipShow('unifiedToolTipProgress')
    }
    setConfirmDialogOpen(false)
    setType('selecting')
    setOpen(false)
  }

  const toolTipShow = (toolTipId: string) => {
    let toolTip = document.getElementById(toolTipId)
    if (toolTip != null && toolTip.className.search(' show') == -1) {
      let classNametemp = toolTip.className
      toolTip.className = toolTip.className + ' show'
      setTimeout(() => {
        if (toolTip != null) {
          toolTip.className = classNametemp
        }
      }, 5000)
    }
  }

  //進捗
  const handleProgressChangeChk = (propsNew: any) => {
    let settingId = propsNew.eventTarget.value
    dispatch(setChkNotAdoptedNotification(''))
    setnotAdoptedNotificationDialogOpen(false)
    const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
    const selectionOutDto = selectMagResult
    setSettingId(settingId)
    //表示順(変更元)
    let displayOrderBefore = -1
    //進捗種別(変更元)
    let progressTypeBefore = ''
    //進捗種別(変更先)
    let progressTypeAfter = ''
    //表示順(変更先)
    let displayOrderAfter = -1
    //進捗名(変更先)
    let progressNameAfter = ''
    //選考名
    let selectionName = ''
    for (let i in flowList) {
      //進捗の変更元情報を取得する
      if (
        selectionOutDto.progressStatusSettingId ==
        flowList[i].progressStatusSettingId
      ) {
        //表示順(変更元)
        displayOrderBefore = flowList[i].displayOrder
        //進捗種別(変更元)
        progressTypeBefore = flowList[i].progressType
        setProgressTypeBefore(flowList[i].progressType)
      }
      //進捗の変更先情報を取得する
      if (settingId == flowList[i].progressStatusSettingId) {
        //進捗種別(変更先)
        progressTypeAfter = flowList[i].progressType
        setProgressTypeAfter(flowList[i].progressType)
        //表示順(変更先)
        displayOrderAfter = flowList[i].displayOrder
        //進捗名(変更先)
        progressNameAfter = flowList[i].progressName
        //選考名
        selectionName = flowList[i].selectionName
        setProgressName(progressNameAfter)
      }
    }
    //"進捗"クリックを重複押下する場合
    if (
      selectionOutDto &&
      settingId == selectionOutDto.progressStatusSettingId
    ) {
      //処理終了
    } else {
      //進捗種別リスト（選考中：[0]応募 [1]カスタム）
      let processTypeSelecting = ['0', '1']
      //進捗種別リスト（内定以降：[2]内定 [3]インターンシップ参加 [4]内定承諾 [5]入社）
      let processTypeConfrimAfter = ['2', '3', '4', '5']
      //進捗種別リスト（[6]不合格 [7]不採用 [8]辞退）
      let processTypeNoPass = ['6', '7', '8']
      if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeSelecting) &&
        inArray(progressTypeAfter, processTypeSelecting)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeConfrimAfter) &&
        inArray(progressTypeAfter, processTypeConfrimAfter)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeNoPass) &&
        inArray(progressTypeAfter, processTypeNoPass)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '1' == recruitmentManagementDivision &&
        (inArray(progressTypeBefore, processTypeSelecting) ||
          inArray(progressTypeBefore, processTypeNoPass)) &&
        (inArray(progressTypeAfter, processTypeSelecting) ||
          inArray(progressTypeAfter, processTypeNoPass))
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '1' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeConfrimAfter) &&
        inArray(progressTypeAfter, processTypeConfrimAfter)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
        //[3]インターンシップ参加
      } else if (
        '1' == recruitmentManagementDivision &&
        ('3' == progressTypeAfter || '3' == progressTypeBefore)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else {
        //[0]応募 [1]カスタム
        if (inArray(progressTypeAfter, processTypeSelecting)) {
          setType('selecting')
          //[2]内定 [3]インターンシップ参加 [4]内定承諾 [5]入社
        } else if (inArray(progressTypeAfter, processTypeConfrimAfter)) {
          setType('confrimAfter')
          //[6]不合格
        } else if ('6' == progressTypeAfter) {
          setType('noPass')
          //[7]不採用
        } else if ('7' == progressTypeAfter) {
          setType('noAdopt')
          //[8]辞退
        } else if ('8' == progressTypeAfter) {
          setType('dismiss')
        }
        setOpen(true)
      }
    }
  }

  // テンプレート保存ダイアログの「キャンセル」ボタン押下
  const handleCancel = () => {
    setConfirmDialogRegisterOpen(false)
    setConfirmDialogOverwriteOpen(false)
    setConfirmDialogOverwriteMCBOpen(false)
  }

  // 選考ステップ変更確認ダイアログの「キャンセル」ボタン押下
  const handleDialogCancel = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setConfirmDialogOpen(false)
  }
  // 選考ステップ変更確認ダイアログの「キャンセル」ボタン押下
  const handleDialogClose = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setType('selecting')
    setOpen(false)
  }
  // 選考ステップ変更確認ダイアログの「はい」ボタン押下
  const handleConfirm = () => {
    handleProcessChangeConfirm(settingId)
    setConfirmDialogOpen(false)
  }

  // 選考ステップ変更時の不採用通知設定チェック
  const handleProgressChangeNotAdoptedNotificationChk = (propsNew: any) => {
    let rightProgressList =
      processChangeResult.selectionFlowInitData.rightProgressList
    let settingId = propsNew.eventTarget.value
    let displayOrder: number = propsNew.eventTarget.id
    setSelectionFlowInitDataTemp(processChangeResult.selectionFlowInitData)
    const selectionOutDto = selectMagResult
    let flg = false
    for (let k in rightProgressList) {
      if (settingId == rightProgressList[k].settingId) {
        flg = true
        dispatch(leftEmpty(displayOrder))
      }
    }
    if (flg == false) {
      dispatch(toLeft(propsNew))
    }

    setNotAdoptedNotificationPropsNew(propsNew)
    //進捗種別(変更先)
    let progressTypeAfter = ''
    const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
    for (let i in flowList) {
      //進捗の変更先情報を取得する
      if (settingId == flowList[i].progressStatusSettingId) {
        //進捗種別(変更先)
        progressTypeAfter = flowList[i].progressType
      }
    }
    //"進捗"クリックを重複押下する場合
    if (
      selectionOutDto &&
      settingId == selectionOutDto.progressStatusSettingId
    ) {
      //処理終了
    } else {
      // 転職かつ不採用の場合
      if ('2' === recruitmentManagementDivision && '7' == progressTypeAfter) {
        dispatch(
          chkNotAdoptedNotificationType(registerValue.selectionManagementId)
        )
      } else {
        handleProgressChangeChk(propsNew)
      }
    }
  }

  // 不採用通知設定チェックダイアログの「キャンセル」ボタン押下
  const handleProgressChangeNotAdoptedNotificationClose = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setnotAdoptedNotificationDialogOpen(false)
    setNotAdoptedNotificationPropsNew('')
    dispatch(setChkNotAdoptedNotification(''))
  }

  const handleConfirmRegister = () => {
    const formData: MCAZS101NewRequest = apiConfig.newRequest

    formData.messageTemplateName = inputString
    formData.destination = registerValue.destination
    // [phase2] start
    formData.sendToNonMember = registerValue.sendToNonMember
    // [phase2] end
    formData.senderCompanyName = registerValue.senderCompanyName
    formData.senderMailAddress = registerValue.senderMailAddress
    formData.allowReply = registerValue.allowReply
    formData.subject = registerValue.subject
    formData.text = registerValue.body
    formData.attachmentName = registerResult.attachmentNames
    formData.fileManagementId = registerResult.fileManagementIds
    formData.entryBoxId = registerValue.entryBoxId
    formData.seminarId = registerValue.seminarId
    formData.homepageTitle1 = registerValue.homePageTitle1
    formData.homepageTitle2 = registerValue.homePageTitle2
    formData.homepageTitle3 = registerValue.homePageTitle3
    formData.homepageUrl1 = registerValue.homePageUrl1
    formData.homepageUrl2 = registerValue.homePageUrl2
    formData.homepageUrl3 = registerValue.homePageUrl3
    formData.barcodeDisplay = registerValue.barcodeDisplayFlag
    formData.replyLimitTime = registerValue.replyLimitTime
      ? registerValue.replyLimitTime
      : ''
    formData.replyNecessaryFlag = registerValue.replyNecessaryFlag
    dispatch(newTemplete({ formData }))

    setConfirmDialogRegisterOpen(false)
  }

  const handleConfirmOverwrite = () => {
    const formData: MCAZS101OrverWriteRequest = apiConfig.orverWriteRequest

    formData.messageTemplateId = selectTemp
    formData.destination = registerValue.destination
    // [phase2] start
    formData.sendToNonMember = registerValue.sendToNonMember
    // [phase2] end
    formData.senderCompanyName = registerValue.senderCompanyName
    formData.senderMailAddress = registerValue.senderMailAddress
    formData.allowReply = registerValue.allowReply
    formData.subject = registerValue.subject
    formData.text = registerValue.body
    formData.attachmentName = registerResult.attachmentNames
    formData.fileManagementId = registerResult.fileManagementIds
    formData.entryBoxId = registerValue.entryBoxId
    formData.seminarId = registerValue.seminarId
    formData.homepageTitle1 = registerValue.homePageTitle1
    formData.homepageTitle2 = registerValue.homePageTitle2
    formData.homepageTitle3 = registerValue.homePageTitle3
    formData.homepageUrl1 = registerValue.homePageUrl1
    formData.homepageUrl2 = registerValue.homePageUrl2
    formData.homepageUrl3 = registerValue.homePageUrl3
    formData.barcodeDisplay = registerValue.barcodeDisplayFlag
    formData.replyLimitTime = registerValue.replyLimitTime
      ? registerValue.replyLimitTime
      : null
    formData.replyNecessaryFlag = registerValue.replyNecessaryFlag
      ? registerValue.replyNecessaryFlag
      : null
    formData.sysVersionNumber = sysVersionNumber
    dispatch(orverWrite({ formData }))

    setConfirmDialogOverwriteOpen(false)
    setConfirmDialogOverwriteMCBOpen(false)
  }

  // 『辞退にする』のクリックイベント
  const dismissClick = (event: any) => {
    const propsNew: any = {
      flag: declineId, // 旗の位置
      leftSelect: selectionFlowInitData.flag, // 左進捗の選択位置
      rightSelect: declineId, // 右進捗の選択位置
      leftGrey: true, // 左進捗のグレー表示の標識
      rightGrey: false, // 右進捗のグレー表示の標識
      eventTarget: event.target, // クリック対象
    }
    handleProgressChangeNotAdoptedNotificationChk(propsNew)
  }

  // 『不採用にする』のクリックイベント
  const noAdoptClick = (event: any) => {
    const propsNew: any = {
      flag: nonAdoptedId, // 旗の位置
      leftSelect: selectionFlowInitData.flag, // 左進捗の選択位置
      rightSelect: nonAdoptedId, // 右進捗の選択位置
      leftGrey: true, // 左進捗のグレー表示の標識
      rightGrey: false, // 右進捗のグレー表示の標識
      eventTarget: event.target, // クリック対象
    }
    handleProgressChangeNotAdoptedNotificationChk(propsNew)
  }

  return (
    <ModalDialog isOpen={mCAZS101Open} className={'modal'}>
      <LoadingDialog className='loadingOverlay' />
      <div className='modal-content-black'>
        <header className='modal-title-wrap-black'>
          <h3 className='modal-title'>
            {registerValue.sendTimeFlag === '1'
              ? '送信予約受付完了'
              : '送信完了'}
          </h3>
          <span className='close-btn'>
            <svg
              width='25'
              height='25'
              viewBox='0 0 50 50'
              fill='#fff'
              onClick={handleClose}>
              <use xlinkHref='#form__modal_close'></use>
            </svg>
          </span>
        </header>

        <PerfectScrollbar className='modal-section'>
          {registerValue.sendTimeFlag === '1' ? (
            <p className='modal-text__12'>
              メッセージを送信予約しました。
              最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。
            </p>
          ) : (
              <p className='modal-text__12'>
                メッセージを送信しました。
              メッセージが届くまで、時間がかかる場合があります。<br></br>
                最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。
            </p>
            )}

          <div className='message-complete__item'>
            <dl className='message-complete-info'>
              <dt className='message-complete-info__head'>
                マイナビメッセージ
              </dt>
              <dd className='message-complete-info__text'>
                {messageConfVal.successfulCountMessage -
                  (registerResult.addFailureCount
                    ? registerResult.addFailureCount
                    : 0)}
                件
              </dd>
              <dt className='message-complete-info__head'>メールアドレス</dt>
              <dd className='message-complete-info__text'>
                {messageConfVal.successfulCountEmail}件
              </dd>
              <dt className='message-complete-info__head'>送信失敗</dt>
              <dd className='message-complete-info__text'>
                {messageConfVal.failedCount +
                  (registerResult.addFailureCount
                    ? registerResult.addFailureCount
                    : 0)}
                件
              </dd>
            </dl>
          </div>
          {registerValue.sendTimeFlag === '1' ? (
            <p className='modal-text__12' style={{ marginTop : '8px'}}>
              送信キャンセルは送信予約日時まで可能です。
            </p>
          ) : (
              ''
            )}
          <div className='mb20'></div>
          <>
            {shouldShowProgress && (
              <>
                <div className='modal-title_2'>選考ステップ</div>
                <div className='modal-section__inner mb40'>
                  <p className='modal-text__12'>
                    送信と同時に本画面上で選考ステップを更新することができます。
                  </p>
                  <div className='modal-step'>
                    <ProgressStatus
                      flag={selectionFlowInitData.flag}
                      leftSelect={selectionFlowInitData.leftSelect}
                      rightSelect={selectionFlowInitData.rightSelect}
                      leftGrey={selectionFlowInitData.leftGrey}
                      rightGrey={selectionFlowInitData.rightGrey}
                      target={selectionFlowInitData.target}
                      leftProgress={selectionFlowInitData.leftProgressList}
                      rightProgress={selectionFlowInitData.rightProgressList}
                      handlerClick={
                        handleProgressChangeNotAdoptedNotificationChk
                      }
                      clickFlag={true}></ProgressStatus>
                  </div>
                  <ul className='modal-btn-list'>
                    <li className='modal-btn-list__item'>
                      <button
                        type='button'
                        className='btn__gray_100pw icon-btn__decline'
                        id={declineId.toString()}
                        value={declineValue}
                        onClick={dismissClick}
                        disabled={
                          Boolean(
                            processChangeResult.selectMagSelectSelectionInfoOutDto.find(
                              v => v.nonTargetFlag === '1'
                            )
                          ) ||
                          Boolean(
                            selectionFlowInitData.rightProgressList.find(
                              e => e.id === selectionFlowInitData.flag
                            )
                          )
                        }>
                        辞退にする
                      </button>
                    </li>
                    <li className='modal-btn-list__item'>
                      <button
                        type='button'
                        className='btn__gray_100pw icon-btn__non-adopted'
                        onClick={noAdoptClick}
                        id={nonAdoptedId.toString()}
                        value={nonAdoptedValue}
                        disabled={
                          Boolean(
                            processChangeResult.selectMagSelectSelectionInfoOutDto.find(
                              v => v.nonTargetFlag === '1'
                            )
                          ) ||
                          Boolean(
                            selectionFlowInitData.rightProgressList.find(
                              e => e.id === selectionFlowInitData.flag
                            )
                          )
                        }>
                        不採用にする
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </>

          {!registerValue.notAdoptedNotificationType && (
            <>
              <div className='modal-title_2'>テンプレート保存</div>
              <div className='modal-section__inner'>
                <p className='modal-text__12'>
                  このメッセージをメッセージテンプレートとして保存できます。
                </p>
                <div className='mb5'>
                  既存のメッセージテンプレートに上書き保存
                </div>

                <Formik onSubmit={() => { }} initialValues={''}>
                  <Field
                    optionList={optionList}
                    className={classes.select}
                    placeholder='(以下から選択)'
                    name='templateName'
                    component={Select}
                    defaultValue={registerValue.messageTemplateSettingId}
                  />
                </Formik>

                <ul className='modal-btn-list mb20'>
                  <li className='modal-btn-list__item'>
                    <button
                      type='submit'
                      className='btn__gray_100pw'
                      onClick={handleOrverWrite}>
                      上書き保存する
                    </button>
                  </li>
                </ul>

                <div className='mb5'>新規メッセージテンプレートとして保存</div>
                <input
                  placeholder=''
                  type='text'
                  className={
                    error.isError
                      ? `${classes.textInputError} form__input_100pw mb5`
                      : `form__input_100pw mb5`
                  }
                  value={inputString}
                  name='messageTemplateName'
                  onChange={transInputString}
                />
                <ul className='modal-btn-list mb20'>
                  <li className='modal-btn-list__item'>
                    <button
                      type='submit'
                      className={
                        error.isError
                          ? `btn__gray_100pw ${classes.textInputError}`
                          : `btn__gray_100pw`
                      }
                      onClick={handleNew}>
                      新規保存する
                    </button>
                  </li>
                </ul>
                {error.isError ? (
                  <div className='modal-text__12'>
                    <span className={classes.message}>{error.message}</span>
                  </div>
                ) : null}
                <div className='mb5'>管理項目</div>
                <ul className='modal-btn-list mb20'>
                  <li className='modal-btn-list__item'>
                    <button
                      type='submit'
                      className='btn__gray_100pw'
                      onClick={inputManagementItem}>
                      管理項目の一覧を見る
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}

        </PerfectScrollbar>
        <footer className='modal-section-btn__black'>
          <button className='btn__cancel' type='submit' onClick={handleClose}>
            送信画面を閉じる
          </button>
        </footer>
      </div>

      <Dialog
        open={open}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <LoadingDialog className='loadingOverlay' />
          <div className={'dialog-section'}>
            {staticTextMap.staticMessageOne}
            {'selecting' != type && 'confrimAfter' != type ? '' : progressName}
            {'selecting' == type || 'confrimAfter' == type
              ? ''
              : textMap[type].messageOne}
            {staticTextMap.staticMessageTwo}
            {'1' === recruitmentManagementDivision
              ? textMap[type].messageThree
              : textMap[type].messageTwo}
            {staticTextMap.staticMessageThree}
          </div>
          <div className='dialog-section-btn'>
            <button onClick={handleDialogClose} className='daialogCancelBtn'>
              キャンセル
            </button>
            <button
              onClick={() => {
                handleProcessChangeConfirm(settingId)
              }}
              className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={confirmDialogRegisterOpen}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <LoadingDialog className='loadingOverlay' />
          <div className={'dialog-section'}>{getMessage('MCAZS101-002')}</div>
          <div className='dialog-section-btn'>
            <button onClick={handleCancel} className='daialogCancelBtn'>
              キャンセル
            </button>
            <button
              onClick={handleConfirmRegister}
              className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={confirmDialogOverwriteOpen}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <LoadingDialog className='loadingOverlay' />
          <div className={'dialog-section'}>{getMessage('MCAZS101-006')}</div>
          <div className='dialog-section-btn'>
            <button onClick={handleCancel} className='daialogCancelBtn'>
              キャンセル
            </button>
            <button
              onClick={handleConfirmOverwrite}
              className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={confirmDialogOverwriteMCBOpen}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <LoadingDialog className='loadingOverlay' />
          <div className={'dialog-section'}>{getMessage('MCAZS101-012')}</div>
          <div className='dialog-section-btn'>
            <button onClick={handleCancel} className='daialogCancelBtn'>
              キャンセル
            </button>
            <button
              onClick={handleConfirmOverwrite}
              className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>
            {staticTextMap.staticMessageOne}
            {'selecting' != type && 'confrimAfter' != type ? '' : progressName}
            {'selecting' == type || 'confrimAfter' == type
              ? ''
              : textMap[type].messageOne}
            {staticTextMap.staticMessageFour}
          </div>
          <div className='dialog-section-btn'>
            <button onClick={handleDialogCancel} className='daialogCancelBtn'>
              キャンセル
            </button>
            <button onClick={handleConfirm} className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={notAdoptedNotificationDialogOpen}
        disableScrollLock={true}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <LoadingDialog className='loadingOverlay' />
          <div className={'dialog-section'}>
            {'不採用通知の送信予約設定がされているため、'}
            <br />
            {'毎日5:00に不合格通知を自動で送信予約します。'}
            <br />
            {'送信予約日時は「選考ステップ：不採用」へ更新した日から'}
            <br />
            {'3日後の15:00に設定されます。'}
            <br />
            <br />
            {'不採用通知の送信予約を自動で実施したくない場合は'}
            <br />
            {'「キャンセル」を押下し、不採用通知設定を修正してください。'}
          </div>
          <div className='dialog-section-btn'>
            <button
              onClick={handleProgressChangeNotAdoptedNotificationClose}
              className='daialogCancelBtn'>
              キャンセル
            </button>
            <button
              onClick={() => {
                handleProgressChangeChk(notAdoptedNotificationPropsNew)
              }}
              className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>

      <MCAXS340
        open={mCAXS340Open}
        onOk={() => {
          dispatch(setMCAXS340Open(false))
        }}
        onCancel={() => {
          dispatch(setMCAXS340Open(false))
        }}
        prevScreenId={'MCAZS101'}
      />
      <MCAXS110></MCAXS110>
    </ModalDialog>
  )
}
