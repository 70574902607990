import React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { ModelessDialog } from './ModelessDialog'
import { Dialog } from '@material-ui/core'
import MCAZS080 from 'pages/MCAZS080'
import MCAZS101 from 'pages/MCAZS101'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import { setInitialState } from 'reducers/messageSendReducer'
import { setInitialState as msgSendCofirmInitState } from 'reducers/messageSendConfirmReducer'
import { setInitialState as msgSendCompnitState } from 'reducers/messageSendCompReducer'
import { makeStyles } from '@material-ui/core/styles'
import { height } from '@material-ui/system'

export type MessageInputParam = {
  show?: boolean
  onClose?: VoidFunction
  someKey: string
}

type Props = MessageInputParam

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-paper': {
      minWidth: '400px',
    },
  },
})

const newMsgWindow = {
  height: (window.innerHeight / 3) * 2,
  width: window.innerWidth / 2,
}

console.log("height: " + window.innerHeight)

const MessageInputDialog: React.FC<Props> = ({ show, onClose, someKey }) => {
  const dispatch = useDispatch()

  const [confstate, setConfState] = useState(viewConfig.messageConfVal)
  const [active, setActive] = useState(false)
  const [mCAZS101Open, setMCAZS101Open] = useState(false)
  const [confirmOpen, setConfirm1Open] = useState(false)
  const [isCompletedSending, setIsCompletedSending] = useState(false)
  const classes = useStyles()
  const registerValue = useSelector(
    (state: RootState) => state.messageSend.registerValue
  )


  useEffect((): void => {
    if (show) {
      dispatch(setInitialState())
      dispatch(msgSendCofirmInitState())
      dispatch(msgSendCompnitState())
      setActive(true)
    } else {
      setActive(false)
      setMCAZS101Open(false)
      setIsCompletedSending(false)
    }
  }, [show])

  const handleCloseClickContent = (): void => {
    setActive(false)
    setMCAZS101Open(false)
    setIsCompletedSending(false)
    if (onClose) {
      onClose()
    }
  }

  useEffect((): void => {
    if (mCAZS101Open) {
      setActive(false)
    }
  }, [mCAZS101Open])

  const [dialogRef, setDialogRef] = useState(useRef<HTMLDialogElement>(null))

  return (
    <>
      <ModelessDialog
        active={active}
        movable={true}
        width={newMsgWindow.width}
        height={newMsgWindow.height}
        onClose={onClose}
        title={confirmOpen ? '送信内容確認' : ''}
        titleClassName={confirmOpen ? 'send-confirm___title' : ''}
        dialogRef={dialogRef}
        setDialogRef={setDialogRef}>
        {active ? (
          <MCAZS080
            key={someKey}
            onDialogClose={handleCloseClickContent}
            isCompletedSending={isCompletedSending}
            registerValue={registerValue}
            setConfState={setConfState}
            setMCAZS101Open={setMCAZS101Open}
            setConfirm1Open={setConfirm1Open}
            modalHeight={newMsgWindow.height}
            dialogRef={dialogRef}></MCAZS080>
        ) : (
          ''
        )}
      </ModelessDialog>
      {mCAZS101Open ? (
        <Dialog
          open={mCAZS101Open}
          fullWidth
          maxWidth='sm'
          className={classes.modal}>
          <MCAZS101
            key={someKey}
            onClose={handleCloseClickContent}
            messageConfVal={confstate}
            registerValue={registerValue}
            setIsCompletedSending={setIsCompletedSending}
          />
        </Dialog>
      ) : (
        ''
      )}
    </>
  )
}
export default MessageInputDialog
