import palette from '../palette'

export default {
  root: {
    // color: palette.icon,
    height: 'inherit',
    display: 'inherit',
    maxHeight: 'inherit',
    AlignItem: 'inherit',
    whiteSpace: 'inherit',
  },
  positionEnd: {
    marginLeft: 'inherit',
  },
}
