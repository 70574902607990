import React from 'react'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControl,
  makeStyles,
} from '@material-ui/core'
import { FieldProps } from 'formik'
import { useErrorStyle } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '2px',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  label: {
    '& .MuiIconButton-label>input': {
      width: 0,
    },
  },
  noCheck: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  checked: {
    backgroundColor: '#3f71bd',
    backgroundPosition: 'center center',
    borderColor: '#3f71bd',
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  disabledNoCheck: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  disabledCheched: {
    background:
      'url(../../css/img/common/checkbox.png) no-repeat 16px 16px #fff',
    backgroundColor: 'lightgray',
    backgroundPosition: 'center center',
    border: '1px solid lightgray',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  divMargin: {
    marginLeft: '20px',
  },
}))
interface Props extends FieldProps {
  label?: string
  onShow?: (state: boolean) => void
  tabIndex?: number
  disabled?: boolean
  judge?: boolean
  className?: string
}

const Checkbox = ({
  label,
  field,
  form,
  onShow,
  tabIndex,
  disabled,
  judge,
  className
}: Props) => {
  const classes = useErrorStyle()
  const classes2 = useStyles()
  const { errors, touched } = form
  const { name, value } = field

  if (onShow) {
    onShow(Boolean(parseInt(value)))
  }

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.checked ? '1' : '0')
  }

  const icon = (disabled?: boolean) => {
    if (disabled) {
      return <div className={classes2.disabledNoCheck} />
    }
    return <div className={classes2.noCheck} />
  }

  const iconChecked = (disabled?: boolean) => {
    if (disabled) {
      return <div className={classes2.disabledCheched} />
    }
    return <div className={classes2.checked} />
  }

  return (
    <FormControl className={className} error={Boolean(errors[name] && touched[name])}>
      <FormControlLabel
        className='form__label_side'
        control={
          <label onClick={handleChange}>
            <input
              type='checkbox'
              tabIndex={tabIndex}
              className='form__checkbox'
              checked={Boolean(parseInt(value))}
              onClick={event => event.stopPropagation()}
              onChange={handleChange}
              disabled={disabled}
            />
            <mark onClick={event => event.stopPropagation()}></mark>
          </label>
        }
        label={label}
      />
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default Checkbox
