import { colors } from '@material-ui/core'

export default {
  root: {
    // backgroundColor: colors.blueGrey[50],
    // color: colors.blueGrey[900],
    backgroundColor: 'inherit',
    color: 'inherit',
    height: 'inherit',
  },
  deletable: {
    '&:focus': {
      // backgroundColor: colors.blueGrey[100],
      backgroundColor: 'inherit',
    },
  },
}
