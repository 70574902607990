import { makeStyles } from '@material-ui/core/styles'
import { useErrorStyle } from 'componentsHsc'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { formatTemplate, magiContants, unitSet } from 'utils/contants'
import { Area, validateDate, validateStaffName } from 'utils/misc'
import * as yup from 'yup'
import { CandidateDay, Reservation } from './Calendar'
import CustomDatePicker, { CommonHandler } from './CustomDatePicker'
import { itemList } from 'components/AccountEdit/formConfig'

const timeIntervals = magiContants.ZS080_TIMEINTERVALS
const startTime = moment()
  .set({ hour: 9, minute: 0 })
  .format(formatTemplate.dateWithHourMinuteSpace)
const endTime = moment()
  .set({ hour: 22, minute: 55 })
  .format(formatTemplate.dateWithHourMinuteSpace)

const useStyles = makeStyles({
  candidateInputArea: {
    height: 'auto!important',
    marginTop: '5px',
    marginBottom: '5px',
    '& .candidateLabel': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
    '& .staffName': {
      width: '230px',
    },
    '& .deleteButton': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
    '& .copyButton': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
  },
  changeButton: {
    // height: '32px',
    width: '186px',
    minWidth: '150px',
    marginRight: '5px',
    wordBreak: 'break-all'
  },
  dateGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& .react-datepicker__navigation': {
      top: '13px',
    },
  },
  dateGroup1: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& .react-datepicker__navigation': {
      top: '13px',
    },
    '& input': {
      borderColor: 'red',
    },
    '& input:hover': {
      borderColor: 'red',
    },
    '& input:focus': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  staffNameErr: {
    '& input': {
      borderColor: 'red',
    },
    '& input:hover': {
      borderColor: 'red',
    },
    '& input:focus': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  hint: {
    marginLeft: '215px',
    cursor: 'auto',
  },
  iconButton: {
    margin: '0 16px !important',
    width: '150px',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  validationCheck: {
    maxWidth: '215px',
    minWidth: '215px',
    margin: '8px 60px 0',
    lineHeight: '1.2em',
  },
})

interface Props extends CommonHandler {
  data: CandidateDay[]
  lengthLimit: number
  handleCopy: (item: CandidateDay, index: number) => () => void
  handleDelete: (itemIndex: number) => () => void
  handleChargeChange: (itemIndex: number) => (e: any) => void
  handleIconClick: (ref: React.RefObject<DatePicker>) => () => void
  handleOpenModal: (
    selectedInterviewLocationId: string,
    candidateListId: number
  ) => void
  notAllowedArea: Area[]
  reservationList: Reservation[]
}

//　応募者NG
const isNotAllowed: boolean[] = []
//　予定あり
const isReserved: boolean[] = []

const CandidateDateGroup = ({
  data,
  lengthLimit,
  handleDateChange,
  handleRawDateChange,
  handleKeyDown,
  handleCopy,
  handleDelete,
  handleChargeChange,
  handleIconClick,
  handleOpenModal,
  notAllowedArea,
  reservationList
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()

  const staffNameYup = yup.object().shape({
    staffName: yup.string(),
  })

  //　応募者NG、予定ありの表示
  const isNotAllowedsOrIsReserveds = (item: CandidateDay) => {
    let isNotAllowed = false
    let isReserved = false
    if (item) {
      const interviewDateTmp = item.interviewDate
      if (interviewDateTmp) {
        const interviewDate =
          interviewDateTmp.getFullYear() +
          '-' +
          String(interviewDateTmp.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(interviewDateTmp.getDate()).padStart(2, '0') +
          'T' +
          String(interviewDateTmp.getHours()).padStart(2, '0') +
          ':' +
          String(interviewDateTmp.getMinutes()).padStart(2, '0')
        if (notAllowedArea.length) {
          notAllowedArea.forEach(area => {
            const start =
              moment()
                .add(area.day, unitSet.days)
                .format(formatTemplate.date) +
              'T' +
              area.startDate
            const end =
              moment()
                .add(area.day, unitSet.days)
                .format(formatTemplate.date) +
              'T' +
              area.endDate

            if (start <= interviewDate && interviewDate < end) {
              isNotAllowed = true
            }
          })
        }
        if (reservationList.length) {
          reservationList.forEach(day => {
            if (day.start <= interviewDate && interviewDate < day.end) {
              isReserved = true
            }
          })
        }
      }
    }

    return (
      <>
        {isNotAllowed ? (
          <p style={{ color: 'red', border: 'solid 2px', padding: '3px 15px 3px 15px' }}>NG日程</p>
        ) : (
          <></>
        )}
        {isReserved ? (
          <p style={{ color: 'red', border: 'solid 2px', padding: '3px 15px 3px 15px' }}>予定あり</p>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <div
            id={'id_' + index}
            className={`box-candidate-date ${classes.candidateInputArea}`}
            key={index}
            ref={item.containerRef}>
            <div
              className={
                !item.interviewDate ||
                  item.interviewDate.getMinutes() % timeIntervals !== 0 ||
                  item.interviewDate.getHours() < 9 ||
                  item.interviewDate.getHours() > 22
                  ? classes.dateGroup1
                  : classes.dateGroup
              }>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <CustomDatePicker
                    {...{
                      item,
                      index,
                      handleDateChange,
                      handleRawDateChange,
                      handleKeyDown,
                    }}
                  />
                  <button
                    type='button'
                    onClick={handleIconClick(item.datePickerRef)}
                    className={`btn only-icon icon-input_calender ${classes.iconButton}`}
                  />
                </div>

                {/* 予定あり、NG日程の表示 */}
                {isNotAllowedsOrIsReserveds(item)}

                {validateDate({
                  input: item.interviewDate,
                  min: startTime.split(' ')[1],
                  max: endTime.split(' ')[1],
                  className: errorClasses.helperText,
                })}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <address>
                <button
                  type='button'
                  onClick={e =>
                    handleOpenModal(item.interviewLocationId, index)
                  }
                  className={`btn ${classes.changeButton}`}>
                  {item.interviewLocation}
                </button>
              </address>
              <label
                className={
                  staffNameYup.isValidSync({ staffName: item.charge })
                    ? ''
                    : classes.staffNameErr
                }>
                <input
                  type='text'
                  className='staffName'
                  value={item.charge}
                  onChange={handleChargeChange(index)}
                  name={`staffName${index}`}
                  placeholder='面接担当者を入力（任意）'
                />
                <br />

                {validateStaffName({
                  input: item.charge,
                  className: `${errorClasses.helperText} ${classes.validationCheck}`,
                  index: index,
                })}
              </label>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button
              type='button'
              onClick={handleCopy(item, index)}
              disabled={data.length >= lengthLimit}
              className='box-candidate-date__btn only-icon icon-candidateDate_copy copyButton'
              title='この面接日程をコピーします'>
              コピー
            </button>
            <button
              type='button'
              onClick={handleDelete(index)}
              className='box-candidate-date__btn only-icon icon-candidateDate_delete deleteButton'
              title='この面接日程を削除します'>
              削除
            </button>
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(CandidateDateGroup)
