import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field } from 'formik'
import {
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { magiContants } from 'utils/contants'
import MessageInputWithCounter from './messageInputWithCounter'
import Layout from './Layout'
import { FormHandler } from '.'
import {
  inputNotificationMailAddress,
  inputNotificationSelect,
} from 'reducers/messageSendReducer'
import { inputList } from 'pages/MCAZS080/formConfig'
import MessageInputToolTip from './MessageInputToolTip'

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    marginTop: '6px',
    marginRight: theme.spacing(1.5),
  },
  input: {},
  label: {
    marginTop: '20px',
    alignItems: 'flex-start',
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  immediate: {
    width: '150px',
  },
  notDesired: {
    width: '120px',
  },
  fullWidth: {
    width: '100%',
    '& > div > label:last-child': {
      width: '100%',
    },
    '& > div > label > span:last-child': {
      width: '100%',
    },
    '& > div > label > span:last-child > div > div': {
      width: 'calc(100% - 69px)',
    },
  },
  required: {
    flexBasis: '150px !important',
    '&:after': {
      content: '"必須"',
      backgroundColor: '#c93832',
      borderRadius: '12px',
      color: '#fff',
      display: 'inline-block',
      lineHeight: '24px',
      marginLeft: '8px',
      textAlign: 'center',
      width: '52px',
    },
    '& + div': {
      flexBasis: 'calc(100% - 150px) !important',
    },
  },
}))

export interface Props {
  form: FormHandler
}

const name = 'reminder'

const Reminder = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChangeReminder = (value: string) => {
    dispatch(inputNotificationMailAddress({ value }))
  }

  const enableInput = () => {
    const value = magiContants.NOTIFICATION_DESIRED
    form.setFieldValue('reminderSelect', value)
    dispatch(inputNotificationSelect({ value }))
  }

  const handleClick = () => {
    const value = magiContants.NOTIFICATION_NOT_DESIRED
    form.setFieldValue('reminderSelect', value)
    dispatch(inputNotificationSelect({ value }))
  }

  const [toolTip, setToolTip] = useState(false)

  const handleToolTip = () => {
    setToolTip(false)
  }

  return (
    <div className='message-select-block' id='reminder'>
      <div className='form__ttl_modal_3 mb12'>
        返信時にお知らせを希望する
        <ClickAwayListener onClickAway={handleToolTip}>
          <svg
            className='form__body_ttl_icon mr10'
            focusable='false'
            viewBox='0 0 24 24'
            aria-hidden='true'
            onClick={() => setToolTip(!toolTip)}>
            <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
          </svg>
        </ClickAwayListener>
      </div>
      <div className='message-input__inner'>
        <FormControl>
          <RadioGroup
            name='reminderSelect'
            defaultValue={form.values.reminderSelect}>
            <div className='mb8'>
              <label className='form__label_side'>
                <div className='form__label_side'>
                  <FormControlLabel
                    checked={
                      form.values.reminderSelect ===
                      magiContants.NOTIFICATION_NOT_DESIRED
                    }
                    control={
                      <Radio
                        color='primary'
                        value={magiContants.NOTIFICATION_NOT_DESIRED}
                        onClick={handleClick}
                        style={{ padding: 0 }}
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_off'>
                            <use xlinkHref='#form__radio_off'></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_on'>
                            <use xlinkHref='#form__radio_on'></use>
                          </svg>
                        }
                      />
                    }
                    label={
                      <span
                        className='message-select-radio__text'
                        style={{
                          fontWeight:
                            form.values.reminderSelect ===
                            magiContants.NOTIFICATION_NOT_DESIRED
                              ? 'bold'
                              : 'unset',
                        }}>
                        希望しない
                      </span>
                    }
                  />
                </div>
              </label>
            </div>
            <div className='mb10'>
              <label className='form__label_side mb15'>
                <div className='form__label_side'>
                  <FormControlLabel
                    checked={
                      form.values.reminderSelect ===
                      magiContants.NOTIFICATION_DESIRED
                    }
                    control={
                      <Radio
                        color='primary'
                        value={magiContants.NOTIFICATION_DESIRED}
                        onClick={enableInput}
                        style={{ padding: 0 }}
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_off'>
                            <use xlinkHref='#form__radio_off'></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_on'>
                            <use xlinkHref='#form__radio_on'></use>
                          </svg>
                        }
                      />
                    }
                    label={
                      <span
                        className='message-select-radio__text'
                        style={{
                          fontWeight:
                            form.values.reminderSelect ===
                            magiContants.NOTIFICATION_DESIRED
                              ? 'bold'
                              : 'unset',
                        }}>
                        希望する
                      </span>
                    }
                  />
                </div>
              </label>
              <div className='calendar-event-details__item-1'>
                <Field
                  name={name}
                  className={`${'form__input_100pw'} ${
                    form.values.reminderSelect !==
                    magiContants.NOTIFICATION_DESIRED
                      ? classes.disabled
                      : ''
                  }`}
                  component={MessageInputWithCounter}
                  hint
                  disabled={
                    form.values.reminderSelect !==
                    magiContants.NOTIFICATION_DESIRED
                  }
                  placeholder='xx@xxx.com 半角英数字で入力'
                  maxAmount={inputList.notifyMailAddress.max}
                  onChange={handleChangeReminder}
                />
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      {/* 情報アイコン（お知らせを希望する） */}
      {toolTip && (
        <div className='tooltip is-tooltip-show'>
          <p>
            {
              '応募者（質問者）からの返信があった際にお知らせを通知するメールアドレスを指定してください。'
            }
          </p>
        </div>
      )}
      {/* 情報アイコン（お知らせを希望する） */}

      {/* <!-- ラジオボタン --> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_on'
            data-name='form__radio_on'
            transform='translate(-78 -2778)'>
            <g
              transform='translate(78 2778)'
              fill='#fff'
              stroke='#3380cc'
              stroke-width='1'>
              <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
              <circle cx='10.5' cy='10.5' r='10' fill='none' />
            </g>
            <circle
              cx='7'
              cy='7'
              r='7'
              transform='translate(81.5 2781.5)'
              fill='#3380cc'
            />
          </g>
        </defs>
        <use xlinkHref='#form__radio_on'></use>
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_off'
            data-name='form__radio_off'
            fill='#fff'
            stroke='#1a1a1a'
            stroke-width='1'>
            <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
            <circle cx='10.5' cy='10.5' r='10' fill='none' />
          </g>
        </defs>
        <use xlinkHref='#form__radio_off'></use>
      </svg>
      {/* <!-- /ラジオボタン --> */}
    </div>
  )
}

export default React.memo(Reminder)
