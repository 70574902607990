import React, { useState } from 'react'
import { FieldProps } from 'formik'
import CreatableSelect from 'react-select/creatable'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import customSelectOptions from 'utils/customSelectOptions'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
    '&::placeholder': {
      color: '#808080',
    },
  }),
  container: (provided: any) => ({ ...provided, fontSize: '1.6rem', }),
  multiValue: (provided: any) => ({
    ...provided, backgroundColor: '#ECEFF1', borderRadius: '5px',
  }),
  multiValueLabel: (provided: any) => ({ ...provided, fontSize: '1.6rem' }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '40px !important',
  }),
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      color: '#e53935',
    },
  })
)

const components = {
  DropdownIndicator: null,
}

interface SelectOption {
  value: string
  label: string
}

interface Props extends FieldProps {
  placeholder?: string
  className?: string
  isMulti?: boolean
}

export const MultiSelect = ({ isMulti, className, placeholder, field, form }: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form
  const createOption = (label: string): SelectOption => ({
    value: label,
    label: label,
  })

  //フォームへの入力文字列を保持するstate
  const [inputValue, setInputValue] = useState('')

  //入力フォーム下部に表示するメッセージ
  const [message, setMessage] = useState('')

  /**
   * 入力フォームの文字列削除時の受付
   * @param event
   */
  const handleChange = (option: any) => {
    form.setFieldValue(name, option)
  }

  /**
   * キー入力時のイベント処理(入力文字受付)
   * @param event
   */
  const handleInputChange = (newValue: string) => {
    setInputValue(newValue)
  }

  /**
   * キー入力時のイベント処理
   * @param event
   */
  const handleKeyDown = (event: any) => {
    //キー入力が行われたら、入力フォーム下部のメッセージを初期化
    setMessage('')

    if (!inputValue) {
      return
    }
    switch (event.key) {
      case 'Enter':
        //Enterキーが押下されたとき

        const valueArray = value
          ? (value as SelectOption[]).map(i => i.value)
          : []

        //既に入力した文字列でないかチェック
        if (valueArray.includes(inputValue)) {
          setMessage(`${inputValue}は既に入力されています`)
          break
        }

        // 1キーワード 20文字以上でないかチェック
        if (inputValue.length > 20) {
          setMessage(`キーワードは20文字以内で入力してください`)
          break
        }

        // 5キーワード以上でないかチェック
        if (valueArray.length > 4) {
          setMessage(`指定できるキーワードの上限数は5件です`)
          break
        }

        // 1キーワード スペースでないかチェック
        if (inputValue.trim().length === 0) {
          setMessage(`必須です`)
          break
        }
        //検索条件にフォームへの入力文字列を設定
        let newValues: SelectOption[]
        if (value != null) {
          newValues = [...value, createOption(inputValue)]
        } else {
          newValues = [createOption(inputValue)]
        }
        form.setFieldValue(name, newValues)

        //フォームへの入力文字列を保持するstateの初期化
        setInputValue('')

        event.preventDefault()
    }
  }

  /**
   * フォーカスアウト時のイベント処理
   */
  const handleBlur = () => {
    //入力フォームからフォーカスアウトしたら、入力フォーム下部のメッセージを初期化
    setMessage('')
  }

  return (
    <>
      <CreatableSelect
        components={components}
        isMulti={true}
        isClearable
        menuIsOpen={false}
        name={name}
        className={className}
        styles={customStyles}
        inputValue={inputValue}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        {...(customSelectOptions as any)}
      />
      {errors[name] && message !== '必須です' ? (
        <div className={classes.message}>{errors[name]}</div>
      ) :
        <span className={classes.message}>{message}</span>
      }
    </>
  )
}
