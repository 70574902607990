import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { FieldGroup } from 'sp/components'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'flex',
    paddingRight: theme.spacing(40),
    '& button': {
      flex: 1,
      marginRight: theme.spacing(10),
    },
  },
}))

interface Action {
  label: string
  onClick?: () => void
}

interface Props {
  doAction: Action
  cancelAction: Action
}

export default ({ doAction, cancelAction }: Props) => {
  const classes = useStyles()

  return (
    <FieldGroup className={classes.buttonGroup}>
      <button className='btn' onClick={cancelAction.onClick}>
        {cancelAction.label}
      </button>
      <button
        className='btn high execute-rename-condition'
        onClick={doAction.onClick}>
        {doAction.label}
      </button>
    </FieldGroup>
  )
}
