import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { validationMessageSet } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  errorSelectBorder: {
    border: '1px solid #e53935',
    '&:hover': {
      border: '1px solid #e53935',
    },
  },
  helperText: {
    fontSize: '11px',
    marginTop: '4px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    whiteSpace: 'pre-wrap',
  },
  title: {
    minWidth: 185,
  },
  backgroundColor: {
    //backgroundColor: '#3f71bd',
  },
  grid: {
    width: '100%',
  },
  backgroundColorDeleted: {
    width: '100%',
    //backgroundColor: '#3f71bd',
  },
}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
  title: string
  item: string
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
}

const ActionSetSelect = ({
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
  title,
  item,
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
}:
  Props) => {
  const classes = useStyles()
  const { name, value } = field

  const [error, setError] = useState({ isError: false, errorMsg: '' })
  const [errorChecked, setCheckedError] = useState({
    isError: false,
    errorMsg: '',
  })

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, '')
    }
  }, [defaultValue])

  useEffect(() => {
    if (
      (prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') &&
      selectDoubleFalg == true
    ) {
      if (clearRecruitmentManageFlag) {
        setError({ isError: false, errorMsg: '' })
      } else {
        setError(errorChecked)
      }
    }
  }, [clearRecruitmentManageFlag])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    form.setFieldValue(name, e.target.value)
    if (e.target.value.length === 0) {
      setError({ errorMsg: error.errorMsg, isError: true })
      setCheckedError({ errorMsg: error.errorMsg, isError: true })
    } else {
      setError({ errorMsg: error.errorMsg, isError: false })
      setCheckedError({ errorMsg: error.errorMsg, isError: false })
    }
  }

  return (
    <>
      <div
        className={
          prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030'
            ? selectDoubleFalg == false
              ? null != item &&
                null != value &&
                item.toString() === value.toString()
                ? undefined
                : classes.backgroundColor
              : null != item &&
                null != value &&
                item.toString() === value.toString() &&
                clearRecruitmentManageFlag == false
                ? classes.grid
                : classes.backgroundColorDeleted
            : null != item &&
              null != value &&
              item.toString() === value.toString()
              ? undefined
              : classes.backgroundColor
        }>
      </div>
      <div className={'form__sttl_modal mb10'}>
        {title}
      </div>
      <select name='dummy_modal_MCAXS340_1'
        disabled={clearRecruitmentManageFlag ? true : disabled}
        className={`${'select-100per'} ${error.isError
          ? classes.errorSelectBorder
          : validationMessageSet.required()
          }`}
        style={
          clearRecruitmentManageFlag
            ? { backgroundColor: 'lightGrey', opacity: 1, color: 'black' }
            : { padding: '0 31px 0 15px' }
        }
        value={
          clearRecruitmentManageFlag
            ? { label: '項目のデータを一括削除します' }
            : value
        }
        onChange={handleChange}>

        {placeholder ? <option value=''>{placeholder}</option> : null}
        {optionList.map(i => (
          <option key={i.value} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
      {error.isError ? (
        <p className={classes.helperText}>{error.errorMsg}</p>
      ) : null}
    </>
  )
}

export default ActionSetSelect
