import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  getinterviewLocationResultList,
  checkInterviewLocation,
} from 'reducers/interviewReducer'
import { Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { maxcount, msg } from 'pages/MCAZS130/formConfig'
import Results from './Results'
import { textMap } from 'pages/MCAZS130/formConfig'
import { MCAZS130InitDisplayRequest } from 'types/MCAZS130/MCAZS130InitDisplayRequest'
import MCAOS020 from 'sp/pages/MCAOM020'
import { magiContants } from 'utils/contants'
import { ModalDialog } from 'sp/components/ModalDialog'

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    color: '#FFFFFF !important',
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    width: 150,
    height: '40px',
    marginTop: '20px',
    marginLeft: '10px',
    marginBottom: '10px',
    fontSize: '14px',
  },
}))
interface Props {
  selectedInterviewLocationId: string
  candidateListId: number
  updateFormModal: boolean
  setUpdateFormModal: (state: boolean) => void
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
}
function getStepContent(count: number) {
  if (count >= maxcount) {
    return msg
  } else {
    return ''
  }
}

const MCAZM130 = ({
  selectedInterviewLocationId,
  candidateListId,
  updateFormModal,
  setUpdateFormModal,
  setSelectInterviewLocationId,
  setSelectInterviewLocationName,
  setSelectInterviewLocationAddress,
  setSelectInterviewLocationMapUrl,
}: Props) => {
  const classes = useStyles()
  const [id, setId] = useState('')
  const data = useSelector(
    (state: RootState) => state.interview.interviewLocationResultList
  )
  const dispatch = useDispatch()
  const handleDialogClose = () => {
    setId('')
  }

  useEffect((): void => {
    if (updateFormModal) {
      dispatch(
        getinterviewLocationResultList({ recruitmentManagementDivision: '' })
      )
    }
  }, [updateFormModal])

  const initInfo = () => {
    dispatch(
      getinterviewLocationResultList({ recruitmentManagementDivision: '' })
    )
  }
  const handleAddCheck = () => {
    const requestData: MCAZS130InitDisplayRequest = {
      // selectedInterviewLocationId:selectedInterviewLocationId,
      // candidateListId:[],
      recruitmentManagementDivision: '',
    }
    dispatch(checkInterviewLocation(requestData))
  }
  const backToHome = () => {
    setUpdateFormModal(false)
  }
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  return (
    <>
      <ModalDialog isOpen={updateFormModal} className='modal'>
        <div className='modal-content'>
          <div className='modal-title-wrap'>
            <h3 className='modal-title'>面接場所選択</h3>
            <span
              className='close-btn'
              onClick={() => {
                backToHome()
              }}>
              <svg width='25' height='25' viewBox='0 0 50 50'>
                <path
                  id='form__modal_close_white'
                  data-name='form__modal_close'
                  d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
                  transform='translate(0 0)'
                  fill='#fff'
                />
              </svg>
            </span>
          </div>
          <div className='modal-section'>
            <div>
              {data.length >= maxcount ? (
                <span
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{
                    __html: getStepContent(data.length).replace('<br>', ''),
                  }}></span>
              ) : (
                  <>面接場所の追加は「新規に登録する」から登録できます。</>
                )}
            </div>
            <div>
              <Button
                variant='contained'
                onClick={handleAddCheck}
                disabled={data.length >= maxcount ? true : false}
                className={classes.buttonStyle}>
                <AddCircleIcon />
                {textMap['register'].renew}
              </Button>
            </div>
            <Results
              id={selectedInterviewLocationId}
              candidateListId={candidateListId}
              setUpdateFormModal={setUpdateFormModal}
              setSelectInterviewLocationId={setSelectInterviewLocationId}
              setSelectInterviewLocationName={setSelectInterviewLocationName}
              setSelectInterviewLocationAddress={
                setSelectInterviewLocationAddress
              }
              setSelectInterviewLocationMapUrl={
                setSelectInterviewLocationMapUrl
              }
            />
          </div>
          <div className='modal-section-btn'>
            <Button
              variant='contained'
              className='btn__cancel'
              onClick={backToHome}>
              キャンセルする
            </Button>
          </div>
        </div>
      </ModalDialog>

      <Dialog open={confirmDialogOpen} className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>{textMap['register'].message}</div>
          <DialogActions>
            <button
              onClick={handleDialogClose}
              className='daialogOnlyConfirmBtn'>
              OK
            </button>
          </DialogActions>
        </div>
      </Dialog>
      <MCAOS020
        onClose={handleDialogClose}
        screenId={magiContants.SCREENID_MCAZS130}
      />
    </>
  )
}

export default MCAZM130
