import React, {
  useState,
  useEffect,
  useRef,
  RefObject,
  Children
} from 'react'

interface Props {
  locationSubtitle: (viewflg: boolean) => void
  children: React.ReactNode | React.ReactNodeArray;
}

export const useInView = (ref: RefObject<HTMLElement | null>) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) =>
      setInView(entry.isIntersecting)
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, setInView]);

  return inView;
};

const SubModal = ({
  locationSubtitle,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref);

  useEffect(() => {
    locationSubtitle(inView)
  }, [inView])

  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

export default SubModal
