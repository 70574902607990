import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles(theme => ({
  nonTargetDiv: {
    color: '#fff',
    position: 'absolute',
    width: '70px',
    display: 'inline-block',
    '& label': {
      width: '70px',
      height: '25px',
      textAlign: 'center',
      float: 'right',
      backgroundImage: `url(
        "data:image/svg+xml;charset=utf-8,%3Csvg width='72' height='26' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Created with Method Draw - http://github.com/duopixel/Method-Draw/ --%3E %3Cg%3E %3Ctitle%3Ebackground%3C/title%3E %3Crect fill='%23fff' id='canvas_background' height='28' width='72' y='-1' x='-1'/%3E %3Cg display='none' overflow='visible' y='0' x='0' height='100%25' width='100%25' id='canvasGrid'%3E %3Crect fill='url(%23gridpattern)' stroke-width='0' y='0' x='0' height='100%25' width='100%25'/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3Ctitle%3ELayer 1%3C/title%3E %3Cimage xlink:href='data:image/png%3Bbase64%2CiVBORw0KGgoAAAANSUhEUgAAAEYAAAAaCAYAAAAKYioIAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAI6SURBVFhH7ZjNigIxEIQzKqJHD77/ayji2ZcRxKP4M2tlKSmbJCaZYWdFPwgzSSfpTk2nZbfZbrftHTcajdwX56AFaNbrdTudTt3lcnG3280PfipN07jJZOJ1aDabTYvOfr93p9PJGz8V3Jrlcuk1eNwfpNCnN02Kp8Lilbqr9g4NsfYZr4oCOldcql1LzXqdn1rbJa5OwqBIpQ6GcRT1kJ2FXtdfr1ff2A%2BtAxhnxsTm%2BAJ6t9f%2BoFQJg2AYEFMxBgp7Cl0/Ho99Y1qHDo0xzGcM6lvnc47aS%2BgkjL2XltQXzSG0PzMANrWHsvdVfCmyhbEObWAxYvNy1oZETQnNLOmDLGHojM9cUVLkrMdBbY2IXU3Oxb59iJMtTF8OSymtEX8qDJzxawwJYrAZpOTEmVqvZAuDL0fHQ2QOQAypDEJc%2BFULxYcxxJ6bgWV5egcbDyXMKxBb7PCIuST2YmFeperQxGLDeMkHrcqYIYXhlcDTHlT71oaY2XIoFuY/oFciJg6e1lZCtTBdnHZBr4S%2BA%2B3jicyqparGABsU0cD0vS9Y47TQMib4gQ1wTi3FK%2BFQA1G0j3fMC/11jXHuAWw/hR5WRYAPNB3jew1PwtgDhKBDHMQ6ZmB8ouEgdh5txPZzwRr1pXvU7Kc8hOEX49dLNQqIp44D2jkH2Hm0x/olze6jtppGHv8MPxwOPu27Kv3OILsXi4UXuFmtVu1sNvOGTxYFQBBwPp9ds9vt2uPx6P/G%2BPJbCubzufsB%2B6QJ1yCe%2B6kAAAAASUVORK5CYII=' id='svg_1' height='26' width='70' y='0' x='0'/%3E %3C/g%3E %3C/svg%3E"
      )`,
      paddingTop: '3px',
      position: 'relative',
      top: '2px',
      left: '14px',
    },
  },
  ProgressDiv: {
    fontSize: 'inherit',
    color: '#FFF',
    margin: theme.spacing(0, 0, 4, 0),
    '& h1': {
      display: 'inline-block',
      fontSize: '24px',
      fontWeight: 'bold',
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    '& dl': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
    },
    '& dl > dt': {
      flexBasis: '11em',
      '&:after': {
        content: '"："',
        float: 'right',
      },
    },
    '& dl > dd': {
      clear: 'both',
      flexBasis: 'calc(100% - 13em)',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
  },
}))
interface Props {
  bulkSendFlg: boolean
  sendFlg: boolean
}

const ProgressInfo = ({bulkSendFlg,sendFlg }: Props) => {
  const classes = useStyles()
  const marchServiceFlag = useSelector(
    (state: RootState) => state.globalMenu.marchServiceFlag
  )
  const serviceMode = useSelector(
    (state: RootState) => state.globalMenu.serviceMode
  )
  const processChangeResult = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResult
  )
  const flowList = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResult
        .selectMagSelectFlowInfoOutDtoList
  )
  const selectionOutDto = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResult
        .selectMagSelectSelectionInfoOutDto
  )

  // 合否判定の値の定義
  let decisionValueDef = new Map([
    ['', null],
    ['0', '未判定'],
    ['1', '判定中'],
    ['3', '合格'],
    ['5', '不合格'],
  ])
  // Marchモードフラグ
  const marchModeFlag = marchServiceFlag === '1' && serviceMode === '1'
  // 進捗ステータス
  const progress = flowList.find(
    item =>
      item.progressStatusSettingId === selectionOutDto.progressStatusSettingId
  )
  // 進捗種別
  const progressType = progress && progress.progressType

  return (
    <div>
      <section className={classes.ProgressDiv}>
        {bulkSendFlg ? 
        <h1>{progress && progress.progressName}</h1>
        : null}
        {selectionOutDto.nonTargetFlag === '1' && bulkSendFlg ? (
          <div className={classes.nonTargetDiv}>
            <label>対象外</label>
          </div>
        ) : null}
        {sendFlg ? null :
        <dl>
          {processChangeResult.decisionDivisionType === '0' &&
            progressType === '0' && (
              <>
                <dt>面接日時</dt>
                <dd>
                  {selectionOutDto.interviewTime
                    ? moment(new Date(selectionOutDto.interviewTime)).format(
                        'YYYY/MM/DD HH:mm'
                      ) + '～'
                    : null}
                </dd>
              </>
            )}
          {(processChangeResult.decisionDivisionType === '0' ||
            processChangeResult.decisionDivisionType === '2') &&
            progressType === '0' && (
              <>
                <dt>合否判定</dt>
                <dd>
                  {decisionValueDef.get(selectionOutDto.decisionDivision)}
                </dd>
              </>
            )}
          {!marchModeFlag &&
            progressType === '7' &&
            processChangeResult.failureReasonIdCodeList.map(
              item =>
                item.itemCode === selectionOutDto.failureReasonId && (
                  <>
                    <dt>不合格理由</dt>
                    <dd>
                      {(item.itemCode === '99' ? '6' : item.itemCode) +
                        ':' +
                        item.itemName}
                    </dd>
                    {item.itemCode === '99' && (
                      <>
                        <dt>不合格理由（その他）</dt>
                        <dd>
                          {selectionOutDto.inputContent
                            ? selectionOutDto.inputContent
                            : '- '}
                        </dd>
                      </>
                    )}
                  </>
                )
            )}
          {!marchModeFlag &&
            progressType == '8' &&
            processChangeResult.declineReasonIdCodeList.map(
              item =>
                item.itemCode === selectionOutDto.declineReasonId && (
                  <>
                    <dt>辞退理由</dt>
                    <dd>
                      {(item.itemCode === '99' ? '8' : item.itemCode) +
                        ':' +
                        item.itemName}
                    </dd>
                    {item.itemCode === '99' && (
                      <>
                        <dt>辞退理由（その他）</dt>
                        <dd>
                          {selectionOutDto.inputContent
                            ? selectionOutDto.inputContent
                            : '- '}
                        </dd>
                      </>
                    )}
                  </>
                )
            )}
          <dt>最終更新日時</dt>
          <dd>
            {selectionOutDto.manipulationTime
              ? moment(new Date(selectionOutDto.manipulationTime)).format(
                  'YYYY/MM/DD HH:mm'
                )
              : null}
          </dd>
        </dl>
        }
      </section>
    </div>
  )
}

export default ProgressInfo
