import React from 'react'

interface Props {
  id?: string
  title?: string
  icon?: string
  subtitle?: string
}

const Layout: React.FC<Props> = ({ id, title, icon, subtitle, children }) => {
  return (
    <section id={id}>
      {title ? (
        <h1>
          {title}
          {subtitle ? <span>{subtitle}</span> : null}
        </h1>
      ) : null}
      {children}
    </section>
  )
}
export default Layout
