import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import freeDial from 'assets/img/report/freeDial.png'
const Contact = () => {
  // お問い合わせを取得する
  const url = useSelector((state: RootState) => state.url)
  return (
    <>
      <div className={'modal-my-menu__ttl2'}>
        MIWS操作方法に関するお問い合わせ
      </div>
      <div className='modal-my-menu__inner'>
        <p className='modal-my-menu__inner_text'>
          画面操作に関するお問い合わせはこちらから
        </p>
        <dl className='modal-contact-list'>
          <dt className='modal-contact-list__icon'>
            <img src={freeDial} width='25' height='25' />
          </dt>
          <dd className='modal-contact-list__text'>
            <a
              href={'tel:' + url.SP_TENSHOKU_SP_PHONE}
              className='modal-contact-list__link'>
              {url.SP_TENSHOKU_SP_PHONE}
            </a>
          </dd>
          <dt className='modal-contact-list__icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='21'
              height='15'
              viewBox='0 0 42.315 29'>
              <path
                id='icon-mail'
                d='M38.6,2.44l1.553,3.106L21.157,14.5,1.874,5.546,3.286,2.44l17.657,8.095ZM0,29H42.315V0H0Z'
              />
            </svg>
          </dt>
          <dd className='modal-contact-list__text'>
            <a
              href={'mailto:' + url.SP_TENSHOKU_SP_MAIL}
              className='modal-contact-list__link'>
              メールで問い合わせる
            </a>
          </dd>
        </dl>
        <p className='modal-my-menu__inner_text'>
          {url.SP_TENSHOKU_SP_BUSINESS_HOURS}
        </p>
      </div>
    </>
  )
}
export default Contact
