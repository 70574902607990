/** InitRequest */
export interface InitRequest {
  replyFromMessageSendId: string|null
  messageTemplateSettingId: string|null
  entryId: number[]
  selectionManagementId: string[]
  memberType: string[]
  messageType: string[]
  destination: number
  // [phase2] start by zhangxp
  sendToNonMember: number
  // [phase2] end by zhangxp
  senderCompanyName: string
  senderMailAddress: string
  allowReply: number
  subject: string
  text: string
  attachment: any[]
  attachmentId: string[]
  templateAttachmentId: string[]
  templateAttachmentName: string[]
  mynaviAttachedOption: string
  homepageTitleN: string|null
  homepageUrlN: string|null
  barcodeDisplay: number
  sendTimer: number
  sendPlanTime: string|null
  notifyNotification: number
  notifyMailAddress: string
  interviewScheduleAdjustmentId: number
  interviewScheduleAdjustmentStatus: string
  interviewDurationId: string|null
  interviewDetail: string
  interviewScheduleIdN: number[]
  interviewStartTimeN: (string|null)[]
  interviewLocationSettingId: (number|null)[]
  interviewStaffName: string[]
  interviewScheduleStatus: string[]
  selectionFlow: string[]
  flagBodyExistFlg: boolean
  flagValueBodyExistFlg: boolean
}

const initRequest: InitRequest = {
  replyFromMessageSendId: null,
  messageTemplateSettingId: "",
  entryId: [],
  selectionManagementId: [],
  memberType: [],
  messageType: [],
  destination: 0,
  // [phase2] start by zhangxp
  sendToNonMember: 0,
  // [phase2] end by zhangxp
  senderCompanyName: "",
  senderMailAddress: "",
  allowReply: 0,
  subject: "",
  text: "",
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  mynaviAttachedOption: "",
  homepageTitleN: "",
  homepageUrlN: "",
  barcodeDisplay: 0,
  sendTimer: 0,
  sendPlanTime: "",
  notifyNotification: 0,
  notifyMailAddress: "",
  interviewScheduleAdjustmentId: 0,
  interviewScheduleAdjustmentStatus: "",
  interviewDurationId: "",
  interviewDetail: "",
  interviewScheduleIdN: [],
  interviewStartTimeN: [],
  interviewLocationSettingId: [],
  interviewStaffName: [],
  interviewScheduleStatus: [],
  selectionFlow: [],
  flagBodyExistFlg: false,
  flagValueBodyExistFlg: false,
}

export interface MCAZS091InitResult {
  adjustmentInterviewScheduleAdjustmentId: number[]
  alertMessageIdList: string[]
  noValueOnlySendList: string[]
  noValueBulkSendList: string[]
}

export interface MCAZS091RegisterResult {
  fileManagementIds: string[]
  attachmentNames: string[]
  addFailureCount: number
}

const initResult: MCAZS091InitResult = {
  adjustmentInterviewScheduleAdjustmentId: [],
  alertMessageIdList: [],
  noValueOnlySendList: [],
  noValueBulkSendList: [],
}

export {
  initRequest,
  initResult,
}

