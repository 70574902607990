import React from 'react'
import logo from 'sp/images/miws_on-navy.jpg'

const TopBarSp = () => {
  return (
    <header className='header'>
      <div className='header__wrap'>
        <p className='header__logo'>
          <img src={logo} />
        </p>
      </div>
    </header>
  )
}

export default TopBarSp
