import palette from '../palette'

export default {
  root: {
    // color: palette.icon,
    // minWidth: 32,
    color: 'inherit',
    minWidth: 'inherit',
  },
}
