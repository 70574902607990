import { useCallback, useEffect, useMemo, useRef, useState, RefObject } from 'react'
import React from 'react'
import { useModeless } from './useModeless'
import classes from './ModelessDialog.module.css'
import openInFullIcon from './img/open_in_full_24dp.png'
import closeFullscreenIcon from './img/close_fullscreen_24dp.png'
import minimizeIcon from './img/minimize_24dp.png'
import maximizeIcon from './img/maximize_24dp.png'
import closeIcon from './img/close_24dp.png'
import { textMap } from '../../pages/MCAZS080/formConfig'
import { Dialog, Button, DialogTitle, DialogActions } from '@material-ui/core'
import { LoadingDialog } from 'components'
import {
  changeConfirmState,
  closeConfirmModal,
  close060ConfirmModal,
  confirm,
  getInit,
  inputAttachment,
  saveDraft,
  setConfirmRequest,
  setDraftReqeust,
  setRecruitmentManagementDivision,
  setIsDraft,
  inputSubject,
  inputBody,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputNotificationMailAddress,
  inputNotificationSelect,
  inputInterviewDetail,
  getPreview,
  getNotAdoptedNotification,
} from 'reducers/messageSendReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'


type ModelessProps = {
  width?: number
  height?: number
  title?: string
  titleClassName?: string
  movable?: boolean
  active?: boolean
  children: React.ReactNode | React.ReactNodeArray
  onClose?: Function
  dialogRef: RefObject<HTMLDialogElement>
  setDialogRef: Function
}

export const ModelessDialog = ({
  title = '',
  titleClassName = '',
  width = 0,
  height = 0,
  movable = false,
  active = false,
  children,
  onClose,
  dialogRef,
  setDialogRef
}: ModelessProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isModal, setIsModal] = useState(false)
  const {
    modelessConfig,
    open,
    close,
    moveStartModeless,
    showModal,
    minimalaize,
    normalaize,
  } = useModeless(dialogRef, contentRef)
  const dispatch = useDispatch()
  const [type, setType] = useState<keyof typeof textMap>('confirm')
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [attachmentFileValue, setAttachmentFileValue] = useState<any[]>([])
  const attachmentFileRegistrationMax = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.attachmentFileRegistrationMax
  )
  const readableFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.readableFlag
  )
  const [isOpen, setIsOpen] = useState(true);

  const style = useMemo(() => {
    // ダイアログに適用するスタイル
    // 最大化（モーダルモード）の時はブラウザサイズの0.8倍、それ以外は入力パラメータ値
    // 最小化時は横400px、縦auto
    return {
      width: modelessConfig.minimum
        ? '400px'
        : modelessConfig.modal
          ? `${window.innerWidth * 0.8}px`
          : `${width}px`,
      height: modelessConfig.minimum
        ? 'auto'
        : modelessConfig.modal
          ? `${window.innerHeight * 0.8}px`
          : `${height}px`,
      left: `${modelessConfig.left}px`,
      top: `${modelessConfig.top}px`,
    }
  }, [
    width,
    height,
    modelessConfig.minimum,
    modelessConfig.modal,
    modelessConfig.left,
    modelessConfig.top,
  ])

  useEffect((): void => {
    // ダイアログのオーブン、クローズ
    if (active) {
      open()
    } else {
      close()
    }
  }, [active, width, height])

  useEffect(() => {
    setDialogRef(dialogRef)
  }, [dialogRef])

  // ダイアログ内のコンテンツ操作時、ダイアログ外にイベントを伝播しないようにする。
  const handleClickContent = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      // 別ダイアログが表示されていた場合、tabindexを無効化する
      const dialogElmList = document.querySelectorAll<HTMLDivElement>('.MuiDialog-container')
      if (dialogElmList) {
        dialogElmList.forEach(dialogElm => {
          dialogElm.removeAttribute('tabIndex')
        })
      }
      // clickイベントの伝搬を止める。
      event.stopPropagation()
    },
    []
  )

  // フォーカスが外れた場合、ダイアログのtabindexをもとに戻す。
  const handleBlurContent = useCallback(
    (event: React.FocusEvent<HTMLElement>): void => {
      // 別ダイアログが表示されていた場合、ダイアログのtabindexをもとに戻す。
      const dialogElmList = document.querySelectorAll<HTMLDivElement>('.MuiDialog-container')
      if (dialogElmList) {
        dialogElmList.forEach(dialogElm => {
          dialogElm.tabIndex = -1
        })
      }
    },
    []
  )

  const draftRequest = useSelector(
    (state: RootState) => state.messageSend.draftRequest
  )

  // 閉じるボタン押下
  const handleCloseClickContent = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }
  // 「いいえ」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
    dispatch(setIsDraft(false))
  }

  // 「はい」ボタン押下
  const handleConfirm = () => {
    if (type === 'draft') {
      let formData: MCAZS080MessageDraftRequest = draftRequest
      let files: any[] = attachmentFileValue
      dispatch(
        saveDraft({
          formData,
          files,
          attachmentFileRegistrationMax,
          readableFlag,
        })
      )
      dispatch(setIsDraft(false))
    }
    if (type === 'cancel') {
      // window.close()
      if (onClose) {
        onClose()
      }
    }
    setConfirmDialogOpen(false)
  }

  // 最大化/通常ボタン押下
  const handleMaxClickContent = useCallback(
    (event): void => {
      if (modelessConfig.modal) {
        // すでにモーダルモードの場合は、通常モードに切り替える
        normalaize()
      } else {
        if (modelessConfig.minimum) {
          // 最小化モードの場合は通常モードに一旦切り替え
          normalaize()
        }
        // モーダルモードに切り替え 最小化モードからの切り替えでは位置は保存しない
        showModal(!modelessConfig.minimum)
        event.stopPropagation()
      }
    },
    [modelessConfig.modal, modelessConfig.minimum]
  )

  //最小化ボタン押下
  const handleMinumunClickContent = useCallback(
    (event): void => {
      // モーダルモードからの切り替えでは位置は保存しない
      minimalaize(!modelessConfig.modal)
      event.stopPropagation()
    },
    [modelessConfig.minimum]
  )

  // 通常モードボタン押下
  const handleNormalClickContent = useCallback(
    (event): void => {
      normalaize()
      event.stopPropagation()
    },
    [modelessConfig.minimum]
  )

  // ヘッダー部クリック時
  const onMousedownHeader = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (modelessConfig.modal) {
        // モーダルモードの場合は何もしない
        return
      }
      // 移動開始
      moveStartModeless(e)
    },
    [modelessConfig.modal, moveStartModeless]
  )

  // 表示非表示切り替え
  const contentDynamicClassName = useMemo(() => {
    return modelessConfig.visible ? 'visible' : 'invisible'
  }, [modelessConfig.visible])

  // モーダル、モーダレスで適用するスタイルを切り替える。
  const headerDynamicStyle = useMemo(() => {
    return modelessConfig.modal
      ? classes['modelessdialog_header_modal']
      : classes['modelessdialog_header']
  }, [modelessConfig.modal])

  const childrenDynamicStyle = useMemo(() => {
    // 最小化時にコンテンツを非表示にする。
    if (modelessConfig.minimum) {
      return {
        display: 'none',
      }
    } else {
      return {
        height: '100%',
      }
    }

    return {}
  }, [modelessConfig.minimum])

  return (
    <>
      {isOpen &&
        <div
          className={
            modelessConfig.modal ? classes['modelessdialog_backdrop'] : ''
          }>
          <dialog
            id='mesgInputDialog'
            style={style}
            ref={dialogRef}
            className={
              modelessConfig.modal || modelessConfig.minimum
                ? classes['modelessdialog_noresize']
                : classes['modelessdialog']
            }>
            <div id='frameTop'></div>
            {modelessConfig.minimum ? (
              <header
                className={headerDynamicStyle}
                onMouseDown={onMousedownHeader}>
                <span></span>
                <span>
                  <h3 className={'te xt-white'}>
                    入力中のメッセージがあります
                      </h3>
                </span>
                <span>
                  <button
                    type='button'
                    style={{ backgroundColor: '#133e80' }}
                    onClick={handleNormalClickContent}>
                    <img src={maximizeIcon} height={'22px'} />
                  </button>
                  <button
                    type='button'
                    style={{ backgroundColor: '#133e80' }}
                    onClick={handleMaxClickContent}>
                    {modelessConfig.modal ? (
                      <img src={closeFullscreenIcon} height={'22px'} />
                    ) : (
                        <img src={openInFullIcon} height={'22px'} />
                      )}
                  </button>
                  <button
                    type='button'
                    style={{ backgroundColor: '#133e80' }}
                    onClick={handleCloseClickContent}>
                    <img src={closeIcon} height={'22px'} />
                  </button>
                </span>
              </header>
            ) : (
                <header
                  className={headerDynamicStyle}
                  onMouseDown={onMousedownHeader}>
                  <span className={classes['modelessdialog_title_span']}>
                    {title ? <h1 className={titleClassName}>{title}</h1> : ''}
                  </span>
                  <span>
                  </span>
                  <span>
                    <button
                      type='button'
                      style={{ backgroundColor: '#133e80' }}
                      onClick={handleMinumunClickContent}>
                      <img src={minimizeIcon} height={'22px'} />
                    </button>
                    <button
                      type='button'
                      style={{ backgroundColor: '#133e80' }}
                      onClick={handleMaxClickContent}>
                      {modelessConfig.modal ? (
                        <img src={closeFullscreenIcon} height={'22px'} />
                      ) : (
                          <img src={openInFullIcon} height={'22px'} />
                        )}
                    </button>
                    <button
                      type='button'
                      style={{ backgroundColor: '#133e80' }}
                      onClick={handleCloseClickContent}>
                      <img src={closeIcon} height={'22px'} />
                    </button>
                  </span>
                </header>
              )}
            <div style={{ height: "100% " }} >
              <section
                ref={contentRef}
                className={`${contentDynamicClassName}`}
                style={modelessConfig.minimum ? { height: "100%" } : { height: "100%" }}
                onClick={handleClickContent}
                onBlur={handleBlurContent}>
                <div style={childrenDynamicStyle}>{children}</div>
              </section>
            </div>
          </dialog>
          <Dialog open={confirmDialogOpen}>
            <LoadingDialog className='overlay' />
            <DialogTitle>{textMap[type].message}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCancel}>{textMap[type].cancel}</Button>
              <Button onClick={handleConfirm} color='primary'>
                {textMap[type].submit}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
    </>
  )
}
