import React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { Dialog } from '@material-ui/core'
import MCAZS080 from 'sp/pages/MCAZM080'
import MCAZS101 from 'sp/pages/MCAZM101'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import { setInitialState } from 'reducers/messageSendReducer'
import { setInitialState as msgSendCofirmInitState } from 'reducers/messageSendConfirmReducer'
import { setInitialState as msgSendCompnitState } from 'reducers/messageSendCompReducer'
import { ModalDialog } from 'sp/components/ModalDialog'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'

export type MessageInputParam = {
  show?: boolean
  onClose?: VoidFunction
  someKey: string
}

type Props = MessageInputParam

const MessageInputDialog: React.FC<Props> = ({ show, onClose, someKey }) => {
  const dispatch = useDispatch()

  const [confstate, setConfState] = useState(viewConfig.messageConfVal)
  const [active, setActive] = useState(false)
  const [mCAZS101Open, setMCAZS101Open] = useState(false)
  const [isCompleteOpen, setIsCompleteOpen] = useState(false)
  const [mCAZS080Open, setMCAZS080Open] = useState(false)
  const [isCompletedSending, setIsCompletedSending] = useState(false)


  const registerValue = useSelector(
    (state: RootState) => state.messageSend.registerValue
  )

  useEffect((): void => {
    if (show) {
      dispatch(setInitialState())
      dispatch(msgSendCofirmInitState())
      dispatch(msgSendCompnitState())
      setActive(true)
      setMCAZS080Open(true)
    } else {
      setMCAZS080Open(false)
      if (mCAZS080Open === false) { setActive(false) }
      setMCAZS101Open(false)
      if (mCAZS101Open === false) { setIsCompleteOpen(false) }
      setIsCompletedSending(false)
    }
  }, [show])

  async function closeDialog() {
    setMCAZS080Open(false)
    if (mCAZS080Open === false) { setActive(false) }
    setIsCompleteOpen(false)
    if (isCompleteOpen === false) { setMCAZS101Open(false) }
    setIsCompletedSending(false)
  }

  const handleCloseClickContent = async () => {
    await closeDialog()
    if (onClose) {
      onClose()
    }
  }

  useEffect((): void => {
    if (mCAZS101Open) {
      setMCAZS080Open(false)
      setIsCompleteOpen(true)
      if (mCAZS080Open === false) { setActive(false) }
    }
  }, [mCAZS101Open])


  const [minimization, setMinimization] = useState(false)

  return <>
    {active ? (
      <ModalDialog isOpen={mCAZS080Open} className={'modal'} isMinimum={minimization} isMessageInput={true}>
        <MCAZS080
          key={someKey}
          onDialogClose={handleCloseClickContent}
          isCompletedSending={isCompletedSending}
          registerValue={registerValue}
          setConfState={setConfState}
          setMCAZS101Open={setMCAZS101Open}
          mCAZS080Open={mCAZS080Open}
          minimization={minimization}
          setMinimization={setMinimization} />
      </ModalDialog>
    ) : (
        ''
      )}
    {mCAZS101Open &&
      <MCAZS101
        key={someKey}
        mCAZS101Open={isCompleteOpen}
        onClose={handleCloseClickContent}
        messageConfVal={confstate}
        registerValue={registerValue}
        setIsCompletedSending={setIsCompletedSending}
      />}
  </>
}
export default MessageInputDialog
