import { makeStyles } from '@material-ui/core/styles'
import { DataTable } from 'componentsHsc'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { RootState } from 'reducers'
import { routeList } from 'routes/routes'
import {
  MessageData,
  MessageScreenTransitionRequest,
  messageTableDataTransform,
} from 'utils/tableDataTransfrom'
import {
  updateStatusHsc,
} from 'reducers/entryDetailReducer'
import {
  updateStatus,
} from 'reducers/applicantDetailForEmplymentReducer'
const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: 'center',
    fontSize: '20px',
  },
  table: {
    overflowX: 'hidden',
    '& table': {
      '& tr:hover td:first-child': {
        padding: '16px 16px 16px 14px !important',
      },
      '& i': {
        height: 'auto !important',
        padding: '2px 8px !important',
      },
    },
  },
}))
/** メッセージエリア */
export const message: MessageData = {
  classification: '',
  destination: '',
  messageSendId: '',
  messageId: '',
  status: '',
  type: '',
  entryType: '',
  subject: '',
  attachedFile: false,
  selectionFlow: '',
  sender: '',
  datetime: '',
  // phase2 start
  messageRepliesNecessary: '',
  messageRepliesLimit: '',
  messageScoutCharacter: '',
  sendingResult: '',
  // phase2 end
  sysVersionNumber: undefined
}

/** メッセージエリア */
export const messageRequest: MessageScreenTransitionRequest = {
  messageSendId: [],
  entryId: [],
  selectionManagementId: []
}

interface Props {
  screenId: string,
  messageDataList: MessageData[]
}

const Message = ({ screenId, messageDataList }: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const entryMessageRequest = useSelector(
    (state: RootState) => state.entryDetail.messageRequest
  )
  const applicantMessageRequest = useSelector(
    (state: RootState) => state.applicantDetailForEmplyment.messageRequest
  )
  const recruitmentManagementDivisions = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  // 代行ログインアカウントフラグ
  const delegateLoginAccountFlag = useSelector(
    (state: RootState) => state.globalMenu.delegateLoginAccountFlag
  )
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)
  const handleClick = (index: number) => () => {
    if (!(
      document.getSelection() &&
      (document.getSelection() as any).toString()
    )) {
      const messageRequest: MessageScreenTransitionRequest = screenId === 'MCAXS040' ? applicantMessageRequest : entryMessageRequest
      const classification = messageDataList[index].classification
      const status = messageDataList[index].status
      let to = ''
      let localStorageName = ''
      let messageSendId = undefined
      let draftMessageSendId = undefined
      let sysVersionNumber = undefined
      if (classification === '受信') {
        if (status === '未読') {
          if (screenId === 'MCAXS040') {
            if (!delegateLoginAccountFlag) {
              dispatch(updateStatus(index))
            }
            to = routeList.messageDetail
            localStorageName = 'MessageDetail'
            messageSendId = [messageDataList[index].messageSendId]
          }
          else {
            if (!delegateLoginAccountFlag) {
              dispatch(updateStatusHsc(index))
            }
            to = routeList.messageDetail
            localStorageName = 'MessageDetail'
            messageSendId = [messageDataList[index].messageSendId]
          }
        }
        else {
          to = routeList.messageDetail
          localStorageName = 'MessageDetail'
          messageSendId = [messageDataList[index].messageSendId]
        }
      }
      else if (classification === '送信' || classification === '送信中') {
        to = routeList.messageDetail
        localStorageName = 'MessageDetail'
        messageSendId = [messageDataList[index].messageSendId]
      } else if (classification === '送信予約') {
        if (messageRequest.entryId && messageRequest.selectionManagementId) {
          to = routeList.messageDetail
          localStorageName = 'MessageDetail'
          messageSendId = [messageDataList[index].messageSendId]
        } else {
          to = routeList.messageSend
          localStorageName = 'messageInputRequest'
          draftMessageSendId = messageDataList[index].messageSendId
          sysVersionNumber = messageDataList[index].sysVersionNumber
        }
      }
      const requestParams = {
        screenId: screenId,
        messageId: messageDataList[index].messageId,
        messageSendId: messageDataList[index].messageSendId,
        jobSeekerSort: '1',
        recruitmentManagementDivision: recruitmentManagementDivisions,
      }
      const request = {
        pathname: to,
        messageSendId: messageSendId,
        draftMessageSendId: draftMessageSendId,
        sysVersionNumber: sysVersionNumber,
        request: {
          ...messageRequest,
          ...requestParams,
        },
      }
      localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
      localStorage.setItem(localStorageName, JSON.stringify(request))

      if (to === routeList.messageDetail) {
        const params = new URLSearchParams(requestParams as {}).toString()
        to = to + '?' + params
      }
      history.push(to)
    }
  }

  return (
    <section id='messages-all'>
      <h1>
        メッセージ件数：<strong>{messageDataList.length}</strong>件
      </h1>
      {messageDataList.length > 0 ? (
        <div className='selection-table detail-message-list'>
          {recruitmentManagementDivisions === "1" ?
            <DataTable
              widthPercent={['12%', '14%', '24%', '24%', '11%', '15%']}
              labelList={[
                '分類',
                '状態',
                (<span>{'種別'}<br />{'件名'}</span>),
                '選考フロー',
                '送信者',
                '送信（予定）/受信日時',
              ]}
              dataList={messageTableDataTransform(messageDataList, recruitmentManagementDivisions)}
              rowCondition={{ keyword: 'unread', className: 'unread' }}
              handleClick={handleClick}
              className={classes.table}
            />
            : <DataTable
              widthPercent={['12%', '14%', '24%', '24%', '11%', '15%']}
              labelList={[
                '分類',
                '状態',
                (<span>{'種別'}<br />{'件名'}</span>),
                '選考フロー',
                '送信者',
                '送信（予定）/受信日時',
              ]}
              dataList={messageTableDataTransform(messageDataList, recruitmentManagementDivisions)}
              rowCondition={{ keyword: 'unread', className: 'unread' }}
              handleClick={handleClick}
              className={classes.table}
            />}
        </div>
      ) : (
          <p className={classes.noData}>
            条件に一致する検索結果がありませんでした。
        </p>
        )}
    </section>
  )
}

export default Message
