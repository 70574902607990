import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import AppSp from './AppSp'
import AppProvider from 'sp/providers'
import '@openfonts/noto-sans-jp_japanese'

const RootSp = () => (
  <AppProvider>
    <AppSp />
  </AppProvider>
);
export default RootSp;