import React from 'react';

export default () => {
  return (
    <div className="box-space-between">
      <div className="group-horizontal">
        <a
          href="applicant-search.html"
          className="btn on-icon icon-recruitmentFlag">
          選考中一覧
        </a>
        <a
          href="applicant-search-prospectiveEmployee.html"
          className="btn on-icon icon-prospectiveEmployee current">
          内定者一覧
        </a>
        <a
          href="applicant-search-notSubject.html"
          className="btn on-icon icon-Notsubject">
          対象外一覧
        </a>
      </div>
    </div>
  );
};
