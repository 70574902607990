import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, SvgIcon, colors, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    minHeight: 500,
    marginTop: theme.spacing(0),
    '& table': {
      marginTop: theme.spacing(0),
      borderLeft: '1px solid ',
      borderColor: colors.grey[300]
    },

    marginLeft: theme.spacing(2)
  },

  interviewLocationName: {
    cursor: 'auto',
    minWidth: 268,
    width: '30%',
    backgroundColor: colors.grey[300],
    boxSizing: 'border-box',
    padding: '12px',
    verticalAlign: 'top',
    textAlign: "center",
    border: '0px'
  },
  interviewLocationAddress: {
    cursor: 'auto',
    width: '40%',
    backgroundColor: colors.grey[300],
    padding: '12px',
    textAlign: "center",
    border: '0px'
  },
  tdCursordefault: {
    cursor: 'default',
    width: '15%',
    backgroundColor: colors.grey[300],
    border: '0px'
  },
  tdCursorMove: {
    minWidth: '99px',
    width: '15%',
    backgroundColor: colors.grey[300],
    borderRight: "1px solid",
    borderRightColor: colors.grey[300],
    border: '0px'
  },
  thead: {
    display: 'block',
  },
  tablebox: {
    margin: theme.spacing(0, 0, 2, 1),

  },
  tbody: {
    '& tr': {
      // display: 'table',
      // width: 900,
      tableLayout: 'fixed',
      borderBottom: '2px solid #dcdcdc',
    },
    '& td:nth-child(1)': {
      width: '30%',
      padding: '10px',
      borderLeft: '0px',
      borderRight: '0px'
    },
    '& td:nth-child(2)': {
      width: '40%',
      padding: '10px',
      borderLeft: '0px',
      borderRight: '0px'
    },
    '& td:nth-child(3)': {
      width: '15%',
      borderLeft: '0px',
      borderRight: '0px'
    },
    '& td:nth-child(4)': {
      width: '15%',
      borderLeft: '0px'
    },

  },
  mapLabel: {
    color: "#3380cc",
    border: '2px solid #3380cc',
    width: 45,
    textAlign: 'center',
    borderRadius: '5px'
  },
  selectButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6'
    },
  },
  changetr: {
    backgroundColor: '#f4cccc',
  },
  td: {
    backgroundColor: '#ffffff',
  },
  tdLastChange: {
    backgroundColor: '#f4cccc',
    borderRight: "1px  solid",
    borderRightColor: colors.grey[300]
  },
  tdLast: {
    backgroundColor: '#ffffff',
    borderRight: "1px solid",
    borderRightColor: colors.grey[300]
  },
  cursorAuto:{
    cursor:'auto',
  },
}))

interface params {
  setUpdateFormModal: Function
  candidateListId: number
  id: string
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
}
const Results = (
  {
    setUpdateFormModal,
    candidateListId,
    id,
    setSelectInterviewLocationId,
    setSelectInterviewLocationName,
    setSelectInterviewLocationAddress,
    setSelectInterviewLocationMapUrl,
  }: params
) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const itemList = useSelector(
    (state: RootState) => state.interview.interviewLocationResultList
  )
  const setFrontData = (id: string, name: string, address: string, mapUrl: string) => {
    setUpdateFormModal(false)
    setSelectInterviewLocationId(id)
    setSelectInterviewLocationName(name)
    setSelectInterviewLocationAddress(address)
    setSelectInterviewLocationMapUrl(mapUrl)
  }
  return (
    <div className={classes.root}>
      <table className={classes.tablebox} style={{ width: '100%' }}>
        <thead>
          <tr>
            <th className={classes.interviewLocationName} >面接場所名</th>
            <th className={classes.interviewLocationAddress}>
              住所
              </th>
            <th className={classes.tdCursordefault}></th>
            <th className={classes.tdCursorMove} style={{ borderRightWidth: "1px" }}></th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {itemList.map((item, index) =>
            <tr>
              <td align='left' className={item.interviewLocationSettingId == Number.parseInt(id) ? classes.changetr : classes.td}>
                {item.interviewLocationName}
              </td>
              <td align='left' className={item.interviewLocationSettingId == Number.parseInt(id) ? classes.changetr : classes.td}>
                {item.interviewLocationAddress}
              </td>
              <td align='center' className={item.interviewLocationSettingId == Number.parseInt(id) ? classes.changetr : classes.td}>
                {item.mapUrl &&
                  <div
                    className={classes.mapLabel}>
                    MAP
                  </div>
                }
              </td>
              <td align='center' className={item.interviewLocationSettingId == Number.parseInt(id) ? classes.tdLastChange : classes.tdLast} style={{ borderRightWidth: "1px" }}>
                {item.interviewLocationSettingId == Number.parseInt(id) ?
                  <label className={classes.cursorAuto}>
                    選択中
                       </label>
                  : (<Button
                    type='button'
                    className={classes.selectButton}
                    onClick={() => {
                      setFrontData(item.interviewLocationSettingId.toString(), item.interviewLocationName, item.interviewLocationAddress, item.mapUrl)
                    }}>
                    選択する
                  </Button>)
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Results
