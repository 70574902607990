import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty, now } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import { LoadingDialog } from 'components'
import Loading from 'componentsHsc/Loading'
import moment from 'moment'
import momentTz from 'moment-timezone'
import * as apiConfig from 'pages/MCAZS091/apiConfig'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import React, { useEffect, useState, useRef, RefObject } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { getInit, redistMessage, setBody, setCount, setNotSend, setSubject } from 'reducers/messageSendConfirmReducer'
import { setIsDraft } from 'reducers/messageSendReducer'
import { MCAZS091InitRequest } from 'types/MCAZS091/MCAZS091InitRequest'
import { MCAZS091InitResult } from 'types/MCAZS091/MCAZS091InitResult'
import { MCAZS091RegisterRequest } from 'types/MCAZS091/MCAZS091RegisterRequest'
import { magiContants as contants, magiContants } from 'utils/contants'
import { insertionCharacters, textDecoration } from 'utils/messageChangeUtil'
import { textMap } from './formConfig'
import InterviewTarget from './InterviewTarget'
import MessageInput from './MessageInput'
import SendTarget from './SendTarget'
import { Height } from '@material-ui/icons'
momentTz.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles(theme => ({
  multiProgress: {
    backgroundColor: 'red',
    margin: '0px 48px 20px 48px',
  },
  multiProgressDiv: {
    color: 'white',
    margin: '0px -35px 0px -35px',
    padding: '10px 0px 10px 0px',
  },
  alert: {
    color: '#ff5050',
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
  actions: {
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
  },
  scrollbar: {
    '& .scrollbar-container': {
      height: 0,
    },
  },
  dialogPaper: {
    '& .ps__rail-y': {
      width: '0px',
    },
    '& .scrollbar-container': {
      minHeight: '500px',
    },
  },
  notAdoptedNotification: {
    color: '#ff5050',
    margin: '0px 0px 20px 0px',
  },
  dialog: {
    height: (window.innerHeight / 3) * 2,
    width: window.innerWidth / 2,
  }

}))

interface Props {
  open: boolean
  onClose: () => void
  registerValue: {
    messageType: string[]
    messageTemplateSettingId: string | null
    destinationId: string[]
    selectionManagementId: string[]
    memberType: string[]
    destination: number
    // [phase2] start by zhangxp
    sendToNonMember: number
    // [phase2] end by zhangxp
    senderCompanyName: string
    senderMailAddress: string
    allowReply: number
    subject: string
    body: string
    attachment: any[]
    attachmentId: Array<string | null>
    templateAttachmentId: string[]
    templateAttachmentName: string[]
    mynaviAttachedOption: string
    homePageTitle1: string | null
    homePageTitle2: string | null
    homePageTitle3: string | null
    homePageUrl1: string | null
    homePageUrl2: string | null
    homePageUrl3: string | null
    barcodeDisplayFlag: number
    sendTimer: number
    sendPlanTime: string | null
    notifyNotification: number
    notifyMailAddress: string
    requiredTimeId: string
    interviewContents: string
    interviewDateN: (string | null)[]
    interviewLocationIdN: (number | null)[]
    chargeN: string[]
    fileManagementId: string[]
    fileManagementName: string[]
    entryId: number[]
    interviewScheduleAdjustmentId: number
    sysVersionNumber: string
    messageSendId: string
    interviewScheduleAdjustmentStatus: string
    interviewScheduleAdjustmentSelection: string
    adjustmentInterviewScheduleAdjustmentId: string
    interviewDurationId: string | null
    interviewDetail: string
    interviewScheduleId: number[]
    interviewStartTime: (string | null)[]
    interviewStaffName: string[]
    interviewScheduleStatus: string[]
    adjustmentInterviewDurationId: string | null
    adjustmentInterviewDetail: string
    adjustmentInterviewScheduleId: number[]
    adjustmentInterviewStartTime: (string | null)[]
    adjustmentInterviewStaffName: string[]
    adjustmentInterviewScheduleStatus: string[]
    newInterviewScheduleStatus: string[]
    adjustmentInterviewLocationId: (number | null)[]
    manipulationKubun: string
    seminarId: number[]
    entryBoxId: number[]
    sendTimeFlag: string
    replyPermitFlag: string
    replyNecessaryFlag: string
    replyLimitTime: string | null
    destinationDivision: string
    manualRegistrationFlag: string[]
    decisionDivision: string[]
    progressStatusSettingId: number[]
    notSend: boolean[]
    notificationMailAddress: string
    replyFromMessageSendId: string | null
    selectionFlowSettingId: number[]
    title: viewConfig.Title
    interviewSchedule: viewConfig.InterviewSchedule
    messageInput: viewConfig.MessageInput
    sendTargetConfirmation: viewConfig.SendTargetConfirmation
    sendTimeInput: viewConfig.SendTimeInput
    recruitmentManagementFlag: viewConfig.RecruitmentManagementFlag[]
    seminarSchedule: viewConfig.SeminarSchedule[]
    seminar: viewConfig.Seminar[]
    signature: viewConfig.Signature[]
    successfulCountMessage: number
    successfulCountEmail: number
    failedCount: number
    fullName: string
    jobSeekerId: string[]
    interviewScheduleAdjustmentMax: viewConfig.MCAZS080InterviewScheduleAdjustmentMaxResultDto[]
    searchCriteriaJson: string
    mySearchCondition: string | null
    mySearchCriteriaNameJson: string[]
    mySearchCriteriaJson: string[]
    selectionManagementSysVersionNumber: number | null
    progressManagementSysVersionNumber: number | null
    notAdoptedNotificationType: string | null
    interviewScheduleIdWeb: number
    interviewWebEnableFlag: string
    interviewWebUrlSendFlag: string
    sysVersionNumberWeb: string
    senderMessageId: (number | null)[]
  }
  attachment: any[]
  isFixedSubject: boolean
  setMCAZS101Open: (value: boolean) => void
  setConfState: (value: viewConfig.MessageConfVal) => void
  modalHeight: number
  dialogRef: RefObject<HTMLDialogElement>
}
// phase2 start
export default ({
  open,
  onClose,
  registerValue,
  attachment,
  isFixedSubject,
  setMCAZS101Open,
  setConfState,
  modalHeight,
  dialogRef
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [state, setState] = useState(registerValue)
  const [alertMessageList, setAlertMessageList] = useState<string[]>([])
  const [type, setType] = useState<keyof typeof textMap>('confirm')
  const sendTarget = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const destinationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .destinationSelection
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const interviewScheduleAdjustmentAction = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.action
  )
  const previewDataList = useSelector(
    (state: RootState) => state.messageSend.previewDataList
  )
  const successfulCountMessage = useSelector(
    (state: RootState) => state.messageSendConfirm.successfulCountMessage
  )
  const successfulCountEmail = useSelector(
    (state: RootState) => state.messageSendConfirm.successfulCountEmail
  )
  const failedCount = useSelector(
    (state: RootState) => state.messageSendConfirm.failedCount
  )
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const notSendList = useSelector(
    (state: RootState) => state.messageSendConfirm.notSendList
  )
  const searchCriteriaName = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .searchCriteriaName
  )
  const searchCriteria = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.searchCriteria
  )
  const searchCriteriaInfo = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .searchCriteriaInfo
  )
  const filterCondition = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .filterCondition
  )
  const mySearchCondition = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .mySearchCondition
  )
  const conversionMySearch = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .conversionMySearch
  )

  const templateList = useSelector(
    (state: RootState) => state.messageSend.initResult.messageTemplate
  )
  const isSending = useSelector(
    (state: RootState) => state.messageSendConfirm.isSending
  )
  const messageSendIds = useSelector(
    (state: RootState) => state.messageSend.messageInitRequest.messageSendId
  )
  const draftMessageSendId = useSelector(
    (state: RootState) =>
      state.messageSend.messageInitRequest.draftMessageSendId
  )
  const interviewWebUrlSendFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag
  )

  useEffect(() => {
    if (open) {
      let anchorElement = document.getElementById('frameTop')
      if (anchorElement) {
        anchorElement.scrollIntoView()
      }
    }
  }, [open])

  useEffect(() => {
    setState(registerValue)
    if (
      previewDataList &&
      previewDataList.length > 0 &&
      registerValue.subject !== ''
    ) {
      const initVal: MCAZS091InitRequest = apiConfig.initRequest
      initVal.replyFromMessageSendId = registerValue.replyFromMessageSendId
      initVal.messageTemplateSettingId = registerValue.messageTemplateSettingId
      initVal.entryId = registerValue.entryId
      initVal.selectionManagementId = registerValue.selectionManagementId
      initVal.memberType = registerValue.memberType
      initVal.messageType = registerValue.messageType
      initVal.destination = registerValue.destination
      // [phase2] start by zhangxp
      initVal.sendToNonMember = registerValue.sendToNonMember
      // [phase2] end by zhangxp
      initVal.senderCompanyName = registerValue.senderCompanyName
      initVal.senderMailAddress = registerValue.senderMailAddress
      initVal.allowReply = registerValue.allowReply
      initVal.subject = registerValue.subject
      initVal.text = registerValue.body
      initVal.attachment = registerValue.attachment
      initVal.attachmentId = registerValue.attachmentId
      initVal.templateAttachmentId = registerValue.templateAttachmentId
      initVal.templateAttachmentName = registerValue.templateAttachmentName
      initVal.mynaviAttachedOption = registerValue.mynaviAttachedOption
      initVal.homepageTitleN = registerValue.homePageTitle1
      initVal.homepageUrlN = registerValue.homePageUrl1
      initVal.barcodeDisplay = registerValue.barcodeDisplayFlag
      initVal.sendTimer = registerValue.sendTimer
      initVal.sendPlanTime = registerValue.sendPlanTime
      initVal.notifyNotification = registerValue.notifyNotification
      initVal.notifyMailAddress = registerValue.notifyMailAddress
      initVal.interviewScheduleAdjustmentId =
        registerValue.interviewScheduleAdjustmentId
      initVal.interviewScheduleAdjustmentStatus =
        registerValue.interviewScheduleAdjustmentStatus
      initVal.interviewDurationId = registerValue.interviewDurationId
      initVal.interviewDetail = registerValue.interviewDetail
      initVal.interviewScheduleIdN = registerValue.interviewScheduleId
      initVal.interviewStartTimeN = registerValue.interviewStartTime
      initVal.interviewLocationSettingId = registerValue.interviewLocationIdN
      initVal.interviewStaffName = registerValue.interviewStaffName
      initVal.interviewScheduleStatus = registerValue.interviewScheduleStatus
      let selectionFlowList: Array<string> = []
      const sendTarget = registerValue.sendTargetConfirmation.sendTarget
      sendTarget.forEach(item => {
        selectionFlowList = [...selectionFlowList, item.selectionFlow]
      })
      initVal.selectionFlow = selectionFlowList
      let subjectVl: string = registerValue.subject
      if (isFixedSubject) {
        subjectVl = generateFixSubject()
      }
      const replacedValue = setReplace(subjectVl, registerValue.body)
      setState(registerValue)
      dispatch(setSubject(replacedValue.subject))
      dispatch(setBody(replacedValue.body))
      initVal.flagBodyExistFlg = replacedValue.flagBodyExistFlg
      initVal.flagValueBodyExistFlg = replacedValue.flagValueBodyExistFlg
      dispatch(getInit({ initVal }))
    }
  }, [previewDataList])

  const [modals, setModals] = useState(false)
  const businessCheckResult = useSelector(
    (state: RootState) => state.messageSendConfirm.businessCheckResult
  )

  // 「送信する」ボタン押下
  const handleSubmit = () => {
    const formData: MCAZS091RegisterRequest = viewConfig.registerValue
    formData.messageTemplateSettingId = state.messageTemplateSettingId
    formData.destinationId = state.destinationId
    formData.selectionManagementId = state.selectionManagementId
    formData.destination = state.destination
    // [phase2] start by zhangxp
    formData.sendToNonMember = state.sendToNonMember
    // [phase2] end by zhangxp
    formData.senderCompanyName = state.senderCompanyName
    formData.senderMailAddress = state.senderMailAddress
    formData.allowReply = state.allowReply
    formData.subject = state.subject
    if (isFixedSubject) {
      formData.subject = generateFixSubject()
    }
    formData.body = state.body
    formData.attachment = state.attachment
    formData.attachmentId = state.attachmentId
    formData.templateAttachmentId = state.templateAttachmentId
    formData.templateAttachmentName = state.templateAttachmentName
    formData.mynaviAttachedOption = state.mynaviAttachedOption
    formData.homePageTitle1 = state.homePageTitle1
    formData.homePageTitle2 = state.homePageTitle2
    formData.homePageTitle3 = state.homePageTitle3
    formData.homePageUrl1 = state.homePageUrl1
    formData.homePageUrl2 = state.homePageUrl2
    formData.homePageUrl3 = state.homePageUrl3
    formData.barcodeDisplayFlag = state.barcodeDisplayFlag
    formData.sendTimer = state.sendTimer
    formData.sendPlanTime = state.sendPlanTime
    formData.notifyNotification = state.notifyNotification
    formData.notifyMailAddress =
      String(state.notifyNotification) === contants.NOTIFICATION_DESIRED &&
        !isEmpty(state.notifyMailAddress)
        ? state.notifyMailAddress
        : ''
    formData.requiredTimeId = state.requiredTimeId
    formData.interviewContents = state.interviewContents
    formData.interviewDateN = state.interviewDateN
    formData.interviewLocationIdN = state.interviewLocationIdN
    formData.chargeN = state.chargeN
    formData.fileManagementId = state.fileManagementId
    formData.fileManagementName = state.fileManagementName
    formData.entryId = state.entryId
    formData.interviewScheduleAdjustmentId = state.interviewScheduleAdjustmentId
    formData.sysVersionNumber = state.sysVersionNumber
    formData.messageSendId = state.messageSendId
    formData.interviewScheduleAdjustmentStatus =
      state.interviewScheduleAdjustmentStatus
    formData.interviewScheduleAdjustmentSelection =
      state.interviewScheduleAdjustmentSelection
    formData.adjustmentInterviewScheduleAdjustmentId =
      state.adjustmentInterviewScheduleAdjustmentId
    formData.adjustmentInterviewScheduleId = state.adjustmentInterviewScheduleId
    formData.interviewDurationId = state.interviewDurationId
    formData.interviewDetail = state.interviewDetail
    formData.interviewScheduleId = state.interviewScheduleId
    formData.interviewStartTime = state.interviewStartTime
    formData.interviewStaffName = state.interviewStaffName
    formData.interviewScheduleStatus = state.interviewScheduleStatus
    formData.adjustmentInterviewDetail = state.adjustmentInterviewDetail
    formData.adjustmentInterviewDurationId = state.adjustmentInterviewDurationId
    formData.adjustmentInterviewLocationId = state.adjustmentInterviewLocationId
    formData.adjustmentInterviewScheduleId = state.adjustmentInterviewScheduleId
    formData.adjustmentInterviewScheduleStatus =
      state.newInterviewScheduleStatus
    formData.adjustmentInterviewStaffName = state.adjustmentInterviewStaffName
    formData.adjustmentInterviewStartTime = state.adjustmentInterviewStartTime
    formData.manipulationKubun = state.manipulationKubun
    formData.seminarId = state.seminarId
    formData.entryBoxId = state.entryBoxId
    formData.sendTimeFlag = state.sendTimeFlag
    formData.replyPermitFlag = state.replyPermitFlag
    formData.replyNecessaryFlag = state.replyNecessaryFlag
    formData.replyLimitTime = state.replyLimitTime
    formData.destinationDivision = state.destinationDivision
    formData.manualRegistrationFlag = state.manualRegistrationFlag
    formData.memberType = state.memberType
    formData.decisionDivision = state.decisionDivision
    formData.progressStatusSettingId = state.progressStatusSettingId
    formData.notSend = notSendList
    formData.notificationMailAddress = state.notificationMailAddress
    formData.replyFromMessageSendId = state.replyFromMessageSendId
    formData.fullName =
      sendTarget != null && sendTarget.length > 0 ? sendTarget[0].fullName : ''
    formData.draftMessageSendId = draftMessageSendId
    formData.messageSendIds = messageSendIds
    formData.interviewScheduleAdjustmentMax =
      state.interviewScheduleAdjustmentMax
    formData.searchCriteriaName =
      state.sendTargetConfirmation.searchCriteriaName
    formData.searchCriteria = state.sendTargetConfirmation.searchCriteria
    formData.searchCriteriaJson = state.searchCriteriaJson
    formData.mySearchCondition = state.mySearchCondition
    formData.mySearchCriteriaNameJson = state.mySearchCriteriaNameJson
    formData.mySearchCriteriaJson = state.mySearchCriteriaJson
    formData.selectionManagementSysVersionNumber =
      state.selectionManagementSysVersionNumber
    formData.progressManagementSysVersionNumber =
      state.progressManagementSysVersionNumber
    formData.notAdoptedNotificationType = state.notAdoptedNotificationType
    formData.interviewScheduleIdWeb = state.interviewScheduleIdWeb
    formData.interviewWebEnableFlag = state.interviewWebEnableFlag
    formData.interviewWebUrlSendFlag = state.interviewWebUrlSendFlag
    formData.sysVersionNumberWeb = state.sysVersionNumberWeb
    formData.senderMessageId = state.senderMessageId
    let files: any[] = attachment

    // ファイルあり　かつ　送信先：e-mailのみ かつ　非会員あるいは退会済みの場合
    if (state.fileManagementId.length > 0) {
      if (
        !state.memberType.some(
          memberType =>
            memberType !== magiContants.MEMBERS_TYPE_NON_MEMBER &&
            memberType !== magiContants.MEMBERS_TYPE_DEACTIVATED
        )
      ) {
        files = []
        formData.attachment = []
        formData.attachmentId = []
        formData.templateAttachmentId = []
        formData.fileManagementId = []
      }
    }
    dispatch(redistMessage({ formData, files }))
  }

  useEffect(() => {
    if (businessCheckResult) {
      const mCAZS091JobSeekerSelectionInfoDto =
        viewConfig.mCAZS091JobSeekerSelectionInfoDto
      mCAZS091JobSeekerSelectionInfoDto.progressStatusSettingId =
        state.progressStatusSettingId
      const messageConfVal = viewConfig.messageConfVal
      messageConfVal.messageTemplateSettingId = state.messageTemplateSettingId
      messageConfVal.entryId = state.entryId
      messageConfVal.selectionManagementId = state.selectionManagementId
      messageConfVal.progressStatusSettingId = state.progressStatusSettingId
      messageConfVal.selectionFlowSettingId = state.selectionFlowSettingId.map(
        item => Number(item)
      )
      messageConfVal.sendTimeFlag = state.sendTimeFlag
      messageConfVal.successfulCountMessage = successfulCountMessage
      messageConfVal.successfulCountEmail = successfulCountEmail
      messageConfVal.failedCount = failedCount
      messageConfVal.messageTemplateSttingId = state.messageTemplateSettingId
      messageConfVal.templateName = ''
      messageConfVal.notAdoptedNotificationType =
        state.notAdoptedNotificationType
      setConfState(messageConfVal)
      setMCAZS101Open(true)
    } else {
      onClose()
    }
  }, [businessCheckResult])

  // 「修正する」ボタン押下
  const handleClose = () => {
    onClose()
  }

  const handleCansel = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }
  const [stateV, setStateV] = useState(apiConfig.initResult)

  const initResult: MCAZS091InitResult = useSelector(
    (state: RootState) => state.messageSendConfirm.messageComInitResult
  )
  useEffect(() => {
    setStateV(initResult)
  }, [initResult])

  const handleSend = () => {
    if (!stateV) {
      handleModalOpen()
    } else {
      handleSubmit()
    }
  }

  const handleModalOpen = () => {
    setModals(true)
  }

  const handleModalClose = () => {
    onClose()
    window.close()
  }

  const generateFixSubject = () => {
    let text = ''
    text = registerValue.interviewSchedule.interviewDetail
      ? registerValue.interviewSchedule.interviewDetail
      : ''
    text += `確定 `
    if (
      registerValue.interviewSchedule.candidateDate &&
      registerValue.interviewSchedule.candidateDate.length > 0
    ) {
      let scheduleDate =
        registerValue.interviewSchedule.candidateDate[0].interviewStartTime
      if (
        registerValue.interviewSchedule.action ===
        contants.ACTION_FIX_INTERVIEW ||
        registerValue.interviewSchedule.action ===
        contants.ACTION_CHANGE_INTERVIEW
      ) {
        if (
          registerValue.adjustmentInterviewScheduleId &&
          registerValue.adjustmentInterviewScheduleId.length > 0
        ) {
          registerValue.newInterviewScheduleStatus.map((item, index) => {
            if (
              registerValue.adjustmentInterviewStartTime[index] &&
              item === 'fixed'
            ) {
              const startTime =
                registerValue.adjustmentInterviewStartTime[index]
              scheduleDate =
                startTime && startTime !== '' ? startTime : scheduleDate
            }
          })
        }
      }
      const day = momentTz(scheduleDate)
      let dateString: string | null = ''
      let dayString = ''
      let timeString = ''
      if (scheduleDate) {
        dateString = dateToDateString(day.toDate(), false)
        dayString = getDay(day.toDate(), false)
        timeString = timeToString(day.toDate(), false)
      }
      text += `${dateString} (${dayString}) ${timeString}～`
    }
    let durationText = ''
    registerValue.interviewSchedule.interviewDurationSelect.map(item => {
      if (
        item.interviewDurationId ===
        registerValue.interviewSchedule.requiredTimeId
      ) {
        durationText = item.displayTime
      }
    })
    text += `（所要時間：${durationText}）`
    return text
  }

  const escapCharacter = (value: string) => {
    let res = ''
    if (!isEmpty(value)) {
      res = value
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\r?\n/g, '<br />')
    }
    return res
  }

  const setReplace = (subjectVl: string, bodyVl: string) => {
    let subject = subjectVl
    let body = bodyVl
    const recruitmentManagementFlagList =
      registerValue.recruitmentManagementFlag
    let seminarScheduleList = registerValue.seminarSchedule
    let seminarList = registerValue.seminar

    let alertMessageList: string[] = []

    subject = subject
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    body = body
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')

    // 不正文字チェック
    if (
      (!isEmpty(subject) && subject.indexOf('●●') >= 0) ||
      (!isEmpty(body) && body.indexOf('●●') >= 0)
    ) {
      alertMessageList = [...alertMessageList, getMessage('MCAZS091-001')]
    }

    // 件名の文字装飾の置換
    textDecoration.map(item => {
      if (
        subject.indexOf(item.before) >= 0 ||
        subject.indexOf(item.beforeEnd) >= 0
      ) {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item.before]),
        ]
      }
    })

    // 件名の差し込み文字の置換
    const insertionResultSubject = replaceContent(subject)
    subject = insertionResultSubject.content
    alertMessageList = alertMessageList.concat(
      insertionResultSubject.alertMessageList
    )

    // phase2 start
    // 件名の管理項目(新)のチェック
    const matchedFlagSubject = subject.match(/{@flagvalue[0-9]*}/g)
    if (matchedFlagSubject && matchedFlagSubject.length > 0) {
      matchedFlagSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }
    // phase2 end

    // 件名の管理項目のチェック
    const matchedFlagValueSubject = subject.match(/{@flag[0-9]*}/g)
    if (matchedFlagValueSubject && matchedFlagValueSubject.length > 0) {
      matchedFlagValueSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名のセミナー日程のチェック
    const matchedSeminarScheduleSubject = subject.match(
      /{@seminar_schedule[0-9]*}/g
    )
    if (
      matchedSeminarScheduleSubject &&
      matchedSeminarScheduleSubject.length > 0
    ) {
      matchedSeminarScheduleSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名のセミナー画面のチェック
    const matchedSeminarScreenSubject = subject.match(/{@seminar[0-9]*}/g)
    if (matchedSeminarScreenSubject && matchedSeminarScreenSubject.length > 0) {
      matchedSeminarScreenSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名のセミナー画面（新）のチェック
    const matchedSeminarIdScreenSubject = subject.match(/{@seminarId[0-9]*}/g)
    if (
      matchedSeminarIdScreenSubject &&
      matchedSeminarIdScreenSubject.length > 0
    ) {
      matchedSeminarIdScreenSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名のWEB面接日時のチェック
    const matchedInterviewDatetimeSubject = subject.match(
      /{@interview_datetime}/g
    )
    if (
      matchedInterviewDatetimeSubject &&
      matchedInterviewDatetimeSubject.length > 0
    ) {
      matchedInterviewDatetimeSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名のWEB面接URLのチェック
    const matchedInterviewUrlSubject = subject.match(/{@interview_url}/g)
    if (matchedInterviewUrlSubject && matchedInterviewUrlSubject.length > 0) {
      matchedInterviewUrlSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名の返信元件名、返信元本文のチェック、置換
    let senderMessageId = registerValue.senderMessageId[0]
    // 件名の返信元件名のチェック
    const matchedReplayTitleSubject = subject.match(/{@reply_title}/g)
    if (
      matchedReplayTitleSubject &&
      matchedReplayTitleSubject.length > 0 &&
      !senderMessageId
    ) {
      matchedReplayTitleSubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名の返信元本文のチェック
    const matchedReplayBodySubject = subject.match(/{@reply_body}/g)
    if (matchedReplayBodySubject && matchedReplayBodySubject.length > 0) {
      matchedReplayBodySubject.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 件名の返信元件名の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
    ) {
      if (
        matchedReplayTitleSubject &&
        matchedReplayTitleSubject.length > 0 &&
        senderMessageId
      ) {
        subject = subject.replace(
          /{@reply_title}/g,
          '<span style="font-weight: bold">[この部分には自動で返信元の件名が入ります]</span>'
        )
      }
    }

    // 本文の置換処理開始
    body = body.replace(/\r?\n/g, '<br />')

    // 本文の文字装飾の置換
    const decorationResult = decorationContent(body)
    body = decorationResult.content
    alertMessageList = alertMessageList.concat(
      decorationResult.alertMessageList
    )

    // 本文の差し込み文字の置換
    const insertionResultBody = replaceContent(body)
    body = insertionResultBody.content
    alertMessageList = alertMessageList.concat(
      insertionResultBody.alertMessageList
    )
    // 掲載社名（{@COMPANY_NAME}）の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
      (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ||
        destinationSelection ===
        magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)
    ) {
      body = body.replace(
        /{@COMPANY_NAME}/g,
        '<span style="font-weight: bold">[この部分には自動で掲載社名が入ります]</span>'
      )
    }
    // phase2 start
    // 管理項目(新)のチェック
    const matchedFlagValueBody = body.match(/{@flagvalue[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedFlagValueBody &&
      matchedFlagValueBody.length > 0
    ) {
      matchedFlagValueBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 管理項目(新)の置換
    let flagValueBodyExistFlg = false
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      if (body.search('{@flagvalue') >= 0) {
        const idSet: Set<string> = new Set<string>()
        const bodyArray = body.split('{@flagvalue')
        for (var i = 1; i < bodyArray.length; i++) {
          const itemArray = bodyArray[i].split('}')
          if (
            itemArray.length > 1 ||
            bodyArray[i].indexOf('}') == bodyArray[i].length - 1
          ) {
            idSet.add(itemArray[0])
          }
        }
        if (idSet.size > 0) {
          const flagNameList = Array.from(idSet)
          flagNameList.map(item => {
            recruitmentManagementFlagList.map(flag => {
              if (item == flag.recruitmentManagementFlagSettingId.toString()) {
                body = body
                  .split(`{@flagvalue${item}}`)
                  .join(
                    `<span style="font-weight: bold">[${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}の設定値]</span>`
                  )
                flagValueBodyExistFlg = true
              }
            })
          })
        }
      }
    }
    // phase2 end
    // 管理項目のチェック
    const matchedFlagBody = body.match(/{@flag[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedFlagBody &&
      matchedFlagBody.length > 0
    ) {
      matchedFlagBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 管理項目の置換
    let flagBodyExistFlg = false
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      if (body.search('{@flag') >= 0) {
        const idSet: Set<string> = new Set<string>()
        const bodyArray = body.split('{@flag')
        for (var i = 1; i < bodyArray.length; i++) {
          const itemArray = bodyArray[i].split('}')
          if (
            itemArray.length > 1 ||
            bodyArray[i].indexOf('}') == bodyArray[i].length - 1
          ) {
            idSet.add(itemArray[0])
          }
        }
        if (idSet.size > 0) {
          const flagNameList = Array.from(idSet)
          flagNameList.map(item => {
            recruitmentManagementFlagList.map(flag => {
              if (item == flag.recruitmentManagementFlagSettingId.toString()) {
                body = body
                  .split(`{@flag${item}}`)
                  .join(
                    `<span style="font-weight: bold">${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}:[${escapCharacter(
                      flag.recruitmentManagementFlagName
                    )}の設定値]</span>`
                  )
                flagBodyExistFlg = true
              }
            })
          })
        }
      }
    }

    //セミナー日程のチェック
    const matchedSeminarScheduleBody = body.match(/{@seminar_schedule[0-9]*}/g)
    // セミナー日程は直接文字列を設定するようになったため、置換文字はNG
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarScheduleBody &&
      matchedSeminarScheduleBody.length > 0
    ) {
      matchedSeminarScheduleBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    //セミナー画面のチェック
    const matchedSeminarScreenBody = body.match(/{@seminar[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarScreenBody &&
      matchedSeminarScreenBody.length > 0
    ) {
      matchedSeminarScreenBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    //セミナー画面（新）のチェック
    const matchedSeminarIdScreenBody = body.match(/{@seminarId[0-9]*}/g)
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
      matchedSeminarIdScreenBody &&
      matchedSeminarIdScreenBody.length > 0
    ) {
      matchedSeminarIdScreenBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // セミナー画面の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
    ) {
      // 置換文字チェック（表示期間外のセミナー画面）
      // 置換文字チェック（存在しないセミナー画面）
      if (
        (matchedSeminarScheduleBody && matchedSeminarScheduleBody.length > 0) ||
        (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) ||
        (matchedSeminarIdScreenBody && matchedSeminarIdScreenBody.length > 0)
      ) {
        let isError = false
        let isErrorPeriod = false
        if (
          matchedSeminarScheduleBody &&
          matchedSeminarScheduleBody.length > 0
        ) {
          matchedSeminarScheduleBody.map(item => {
            const seminarScheduleId = item
              .replace('{@seminar_schedule', '')
              .replace('}', '')
            const current = seminarScheduleList.find(
              item => String(item.seminarScheduleId) === seminarScheduleId
            )
            if (!current) {
              isError = true
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
              }
              body = body
                .split(`{@seminar_schedule${seminarScheduleId}}`)
                .join(
                  `<span style="font-weight: bold">[この部分には自動でセミナー日程が入ります]</span>`
                )
            }
          })
        }
        if (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) {
          matchedSeminarScreenBody.map(item => {
            const seminarId = item.replace('{@seminar', '').replace('}', '')
            const current = seminarList.find(
              item => String(item.seminarId) === seminarId
            )
            if (!current) {
              isError = true
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
                if (sendTarget.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminar${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS091-002', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminar${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              } else {
                if (sendTarget.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminar${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS091-002', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminar${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              }
            }
          })
        }
        if (
          matchedSeminarIdScreenBody &&
          matchedSeminarIdScreenBody.length > 0
        ) {
          matchedSeminarIdScreenBody.map(item => {
            const seminarId = item.replace('{@seminarId', '').replace('}', '')
            const current = seminarList.find(
              item => String(item.coordinationFormerSeminarId) === seminarId
            )
            if (!current) {
              isError = true
            } else {
              const currentDate = momentTz(now())
              const eventDate = momentTz(current.eventDate)
              const displayStartDate = momentTz(current.displayStartDate)
              const displayEndDate = momentTz(current.displayEndDate)
              if (
                currentDate.isAfter(eventDate) ||
                currentDate.isBefore(displayStartDate) ||
                currentDate.isAfter(displayEndDate)
              ) {
                isErrorPeriod = true
                if (sendTarget.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminarId${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS091-002', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminarId${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              } else {
                if (sendTarget.length === 1) {
                  if ('' != current.seminarScheduleDisplay) {
                    body = body
                      .split(`{@seminarId${seminarId}}`)
                      .join(current.seminarScheduleDisplay)
                  } else {
                    alertMessageList = [
                      ...alertMessageList,
                      getMessage('MCAZS091-002', [item]),
                    ]
                  }
                } else {
                  //#74734 維持保守 start
                  body = body
                    .split(`{@seminarId${seminarId}}`)
                    .join(
                      '<span style="font-weight: bold">[この部分には自動で予約したセミナー日程が入ります]</span>'
                    )
                  //#74734 維持保守 end
                }
              }
            }
          })
        }
        if (isErrorPeriod) {
          alertMessageList = [...alertMessageList, getMessage('MCAZS091-005')]
        }
      }

      // 置換文字チェック（セミナー画面の複数指定）
      if (
        (matchedSeminarScreenBody || matchedSeminarIdScreenBody) &&
        (matchedSeminarScreenBody ? matchedSeminarScreenBody.length : 0) +
        (matchedSeminarIdScreenBody ? matchedSeminarIdScreenBody.length : 0) >
        1
      ) {
        // 差込できないのを除く
        let count: number = 0
        let countSeminerId: number = 0
        if (matchedSeminarScreenBody && matchedSeminarScreenBody.length > 0) {
          let matchedSeminarIdArray: string[] = matchedSeminarScreenBody.map(
            i =>
              i.match(/{@seminar(\S*)}/)
                ? (i.match(/{@seminar(\S*)}/) as any)[1]
                : i
          )
          count = matchedSeminarIdArray.length
          let seminarFlag: string[] = seminarList
            ? seminarList.map(i => i.seminarId.toString())
            : []
          let tempArray: string[] = matchedSeminarIdArray.filter(
            i => seminarFlag.indexOf(i) != -1
          )
          count =
            count -
            matchedSeminarIdArray.filter(i => tempArray.indexOf(i) == -1).length
        }
        if (
          matchedSeminarIdScreenBody &&
          matchedSeminarIdScreenBody.length > 0
        ) {
          let matchedSeminarIdArray: string[] = matchedSeminarIdScreenBody.map(
            i =>
              i.match(/{@seminarId(\S*)}/)
                ? (i.match(/{@seminarId(\S*)}/) as any)[1]
                : i
          )
          countSeminerId = matchedSeminarIdArray.length
          let seminarFlag: string[] = seminarList
            ? seminarList.map(i => i.coordinationFormerSeminarId.toString())
            : []
          let tempArray: string[] = matchedSeminarIdArray.filter(
            i => seminarFlag.indexOf(i) != -1
          )
          countSeminerId =
            countSeminerId -
            matchedSeminarIdArray.filter(i => tempArray.indexOf(i) == -1).length
        }
        if (count + countSeminerId > 1) {
          alertMessageList = [...alertMessageList, getMessage('MCAZS091-008')]
        }
      }
    }

    // WEB面接日時のチェック
    const matchedInterviewDatetimeBody = body.match(/{@interview_datetime}/g)
    if (
      interviewWebUrlSendFlag !== magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
      matchedInterviewDatetimeBody &&
      matchedInterviewDatetimeBody.length > 0
    ) {
      matchedInterviewDatetimeBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    } else {
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        body = body
          .split(`{@interview_datetime}`)
          .join(
            `<span style="font-weight: bold">[この部分には自動でWEB面接日時が入ります]</span>`
          )
      }
    }

    // WEB面接URLのチェック
    const matchedInterviewUrlBody = body.match(/{@interview_url}/g)
    if (
      interviewWebUrlSendFlag !== magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
      matchedInterviewUrlBody &&
      matchedInterviewUrlBody.length > 0
    ) {
      matchedInterviewUrlBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    } else {
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
        interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG
      ) {
        body = body
          .split(`{@interview_url}`)
          .join(
            `<span style="font-weight: bold">[この部分には自動でWEB面接URLが入ります]</span>`
          )
      }
    }

    // 本文の返信元件名、返信元本文のチェック、置換
    // 本文の返信元件名のチェック
    const matchedReplayTitleBody = body.match(/{@reply_title}/g)
    if (matchedReplayTitleBody && matchedReplayTitleBody.length > 0) {
      matchedReplayTitleBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 本文の返信元本文のチェック
    const matchedReplayBodyBody = body.match(/{@reply_body}/g)
    if (
      matchedReplayBodyBody &&
      matchedReplayBodyBody.length > 0 &&
      !senderMessageId
    ) {
      matchedReplayBodyBody.map(item => {
        alertMessageList = [
          ...alertMessageList,
          getMessage('MCAZS091-002', [item]),
        ]
      })
    }

    // 本文の返信元本文の置換
    if (
      recruitmentManagementDivision ===
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
    ) {
      if (
        matchedReplayBodyBody &&
        matchedReplayBodyBody.length > 0 &&
        senderMessageId
      ) {
        body = body.replace(
          /{@reply_body}/g,
          '<span style="font-weight: bold">[この部分には自動で返信元の本文が入ります]</span>'
        )
      }
    }

    // 送信先チェック：送信先の選択
    const memberTypeList = registerValue.memberType
    const destination = registerValue.destination
    const sendToNonMember = registerValue.sendToNonMember
    let successfulCountMessage = 0
    let successfulCountEmail = 0
    let failedCount = 0
    let notSendList: boolean[] = []
    if (memberTypeList && memberTypeList.length > 0) {
      switch (String(destination)) {
        case magiContants.DESTINATION_DIVISION_MYNAVI_ONLY:
          if (
            memberTypeList.some(
              item =>
                item === magiContants.MEMBERS_TYPE_NON_MEMBER ||
                item === magiContants.MEMBERS_TYPE_DEACTIVATED
            )
          ) {
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-010')]
          }
          memberTypeList.map(memberType => {
            if (memberType === magiContants.MEMBERS_TYPE_MEMBER) {
              successfulCountMessage++
              notSendList = [...notSendList, false]
            }
            if (
              memberType === magiContants.MEMBERS_TYPE_NON_MEMBER ||
              memberType === magiContants.MEMBERS_TYPE_DEACTIVATED
            ) {
              failedCount++
              notSendList = [...notSendList, true]
            }
          })
          dispatch(
            setCount({
              successfulCountMessage,
              successfulCountEmail,
              failedCount,
            })
          )
          break
        case magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED:
          // [phase2] start by zhangxp
          if (
            memberTypeList.some(
              item =>
                item === magiContants.MEMBERS_TYPE_DEACTIVATED &&
                sendToNonMember === magiContants.NUMBER_0
            )
          ) {
            // [phase2] end by zhangxp
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-011')]
          }
          memberTypeList.map(memberType => {
            if (memberType === magiContants.MEMBERS_TYPE_MEMBER) {
              successfulCountMessage++
              notSendList = [...notSendList, false]
            }
            if (memberType === magiContants.MEMBERS_TYPE_NON_MEMBER) {
              successfulCountEmail++
              notSendList = [...notSendList, false]
            }
            if (memberType === magiContants.MEMBERS_TYPE_DEACTIVATED) {
              const sendToNonMemberFlag =
                sendToNonMember === magiContants.NUMBER_0 ? true : false
              if (sendToNonMemberFlag) {
                failedCount++
                notSendList = [...notSendList, true]
              } else {
                successfulCountEmail++
                notSendList = [...notSendList, false]
              }
            }
          })
          dispatch(
            setCount({
              successfulCountMessage,
              successfulCountEmail,
              failedCount,
            })
          )
          break
        case magiContants.DESTINATION_DIVISION_EMAIL_ONLY:
          // [phase2] start by zhangxp
          if (
            memberTypeList.some(
              item =>
                item === magiContants.MEMBERS_TYPE_DEACTIVATED &&
                sendToNonMember === magiContants.NUMBER_0
            )
          ) {
            // [phase2] end by zhangxp
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-012')]
          }
          memberTypeList.map(memberType => {
            if (
              memberType === magiContants.MEMBERS_TYPE_MEMBER ||
              memberType === magiContants.MEMBERS_TYPE_NON_MEMBER
            ) {
              successfulCountEmail++
              notSendList = [...notSendList, false]
            }
            if (memberType === magiContants.MEMBERS_TYPE_DEACTIVATED) {
              const sendToNonMemberFlag =
                sendToNonMember === magiContants.NUMBER_0 ? true : false
              if (sendToNonMemberFlag) {
                failedCount++
                notSendList = [...notSendList, true]
              } else {
                successfulCountEmail++
                notSendList = [...notSendList, false]
              }
            }
          })
          // ファイルあり　かつ　送信先：e-mailのみ かつ　非会員あるいは退会済みの場合
          if (state.fileManagementId.length > 0) {
            if (
              !memberTypeList.some(
                memberType =>
                  memberType !== magiContants.MEMBERS_TYPE_NON_MEMBER &&
                  memberType !== magiContants.MEMBERS_TYPE_DEACTIVATED
              )
            ) {
              alertMessageList = [
                ...alertMessageList,
                getMessage('MCAZS091-053'),
              ]
            }
          }

          dispatch(
            setCount({
              successfulCountMessage,
              successfulCountEmail,
              failedCount,
            })
          )
          break
      }
      dispatch(setNotSend(notSendList))
    }

    let alertMessageIds: string[] = []

    // 送信先チェック：書類選考免除
    const immunityFlagList = sendTarget.map(
      item => item.applicantScreeningImmunityFlag
    )
    if (immunityFlagList && immunityFlagList.length > 0) {
      if (
        immunityFlagList.every(
          item => item === magiContants.IMMUNITY_FLAG_IMMUNITY
        )
      ) {
        alertMessageIds = [...alertMessageIds, 'MCAZS091-014']
      } else {
        if (
          immunityFlagList.some(
            item => item === magiContants.IMMUNITY_FLAG_IMMUNITY
          )
        ) {
          alertMessageIds = [...alertMessageIds, 'MCAZS091-015']
        }
      }
    }

    const selectionClassificationList = sendTarget.map(
      item => item.selectionClassification
    )
    if (selectionClassificationList && selectionClassificationList.length > 0) {
      // 送信先チェック：イベント着席
      if (
        selectionClassificationList.every(
          item => item === magiContants.SELECTION_CLASSIFICATION_EVENT_SIT_DOWN
        )
      ) {
        alertMessageIds = [...alertMessageIds, 'MCAZS091-016']
      } else {
        if (
          selectionClassificationList.some(
            item =>
              item === magiContants.SELECTION_CLASSIFICATION_EVENT_SIT_DOWN
          )
        ) {
          alertMessageIds = [...alertMessageIds, 'MCAZS091-017']
        }
      }

      // 送信先チェック：セミナー・面談参加申込み
      if (
        selectionClassificationList.every(
          item =>
            item === magiContants.SELECTION_CLASSIFICATION_SEMINAR_APPLICATION
        )
      ) {
        alertMessageIds = [...alertMessageIds, 'MCAZS091-018']
      } else {
        if (
          selectionClassificationList.some(
            item =>
              item === magiContants.SELECTION_CLASSIFICATION_SEMINAR_APPLICATION
          )
        ) {
          alertMessageIds = [...alertMessageIds, 'MCAZS091-019']
        }
      }

      // 送信先チェック：質問（お問い合わせ）
      if (
        selectionClassificationList.every(
          item => item === magiContants.SELECTION_CLASSIFICATION_QUESTION
        )
      ) {
        alertMessageIds = [...alertMessageIds, 'MCAZS091-020']
      } else {
        if (
          selectionClassificationList.some(
            item => item === magiContants.SELECTION_CLASSIFICATION_QUESTION
          )
        ) {
          alertMessageIds = [...alertMessageIds, 'MCAZS091-021']
        }
      }
    }

    // 送信先チェック：固定文言
    if (alertMessageIds && alertMessageIds.length > 0) {
      if (alertMessageList.length > 0) {
        alertMessageList = [...alertMessageList, '\n']
      }
      alertMessageList = [...alertMessageList, getMessage('MCAZS091-013')]
      alertMessageIds.map(item => {
        alertMessageList = [...alertMessageList, getMessage(item)]
      })
      alertMessageList = [...alertMessageList, getMessage('MCAZS091-022')]
    }

    // テンプレート選択チェック
    const decisionDivisionList = sendTarget.map(item => item.decisionDivision)
    const progressTypeList = sendTarget.map(item => item.progressType)
    const messageTemplateSettingId = registerValue.messageTemplateSettingId
    if (
      templateList &&
      templateList.length > 0 &&
      messageTemplateSettingId &&
      messageTemplateSettingId !== ''
    ) {
      const currentTemplate = templateList.find(
        item =>
          String(item.messageTemplateSettingId) === messageTemplateSettingId
      )
      if (currentTemplate) {
        if (
          progressTypeList.some(
            item => item === magiContants.PROGRESS_TYPE_NOT_ADOPTED
          )
        ) {
          if (
            currentTemplate.templateType ===
            magiContants.TEMPLATE_TYPE_PASSING_NOTIFICATION
          ) {
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-023')]
          }
        }
        if (
          decisionDivisionList.some(
            item => item === magiContants.DECISION_DIVISION_PASSING
          ) &&
          decisionDivisionList.some(
            item => item === magiContants.DECISION_DIVISION_FAILURE
          )
        ) {
          if (
            currentTemplate.templateType ===
            magiContants.TEMPLATE_TYPE_NOT_ADOPTED_NOTIFICATION
          ) {
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-024')]
          }
          if (
            currentTemplate.templateType ===
            magiContants.TEMPLATE_TYPE_PASSING_NOTIFICATION
          ) {
            alertMessageList = [...alertMessageList, getMessage('MCAZS091-025')]
          }
        }
      }
    }
    // 管理項目チェック
    if (flagBodyExistFlg || flagValueBodyExistFlg) {
      alertMessageList = [...alertMessageList, getMessage('MCAZS091-060')]
    }

    if (alertMessageList && alertMessageList.length > 0) {
      alertMessageList = [...alertMessageList, getMessage('MCAZS091-026')]
    }

    setAlertMessageList(alertMessageList)
    return { subject, body, flagBodyExistFlg, flagValueBodyExistFlg }
  }

  const decorationContent = (content: string) => {
    let alertMessageList: string[] = []

    textDecoration.map(item => {
      if (
        content.indexOf(item.before) >= 0 ||
        content.indexOf(item.beforeEnd) >= 0
      ) {
        var arr =
          content.match(item.rule) != null ? content.match(item.rule) : []
        var arr1 =
          content.match(item.ruleEnd) != null ? content.match(item.ruleEnd) : []

        if (
          destinationSelection !== magiContants.DESTINATION_DIVISION_EMAIL_ONLY
        ) {
          alertMessageList = [
            ...alertMessageList,
            getMessage('MCAZS091-002', [item.before]),
          ]
        } else {
          if (arr && arr1 && arr.length === arr1.length) {
            content = content.replace(item.rule, item.after)
            content = content.replace(item.ruleEnd, item.afterEnd)
          } else {
            alertMessageList = [
              ...alertMessageList,
              getMessage('MCAZS091-009', [
                item.before + 'xxxxx' + item.beforeEnd,
              ]),
            ]
          }
        }
      }
    })

    const result = { content, alertMessageList }
    return result
  }

  const replaceContent = (content: string) => {
    let alertMessageList: string[] = []
    const targetList = sendTarget

    insertionCharacters.map(item => {
      if (content.indexOf(item.before) >= 0) {
        let isEmptyData = false
        let isError = false
        switch (item.name) {
          case 'insertTextUserName':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(
                item => isEmpty(item.familyName) || isEmpty(item.name)
              )
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextUserSei':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.familyName))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextUserMei':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.name))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextId':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item =>
                  isEmpty(item.jobSeekerIdForDisplay)
                )
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextUserId':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                !previewDataList ||
                previewDataList.length <= 0 ||
                previewDataList.some(item =>
                  isEmpty(item.jobSeekerIdForDisplay)
                )
              ) {
                isEmptyData = true
              }
            }
            break
          case 'insertTextDate':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            }
            break
          case 'insertTextCompanyName':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_FIX_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_WITHDRAW_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_CHANGE_INTERVIEW
              ) {
                if (
                  !previewDataList ||
                  previewDataList.length <= 0 ||
                  previewDataList.some(item => isEmpty(item.companyName))
                ) {
                  isEmptyData = true
                }
              }
            }
            break
          case 'insertTextJob':
            if (
              recruitmentManagementDivision ===
              magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1
            ) {
              isError = true
            } else {
              if (
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_FIX_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_WITHDRAW_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_CHANGE_INTERVIEW
              ) {
                if (
                  !previewDataList ||
                  previewDataList.length <= 0 ||
                  previewDataList.some(item =>
                    isEmpty(item.entryJobCategoryName)
                  )
                ) {
                  isEmptyData = true
                }
              }
            }
            break
          case 'insertTextLatestProgress':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.progressName))
            ) {
              isEmptyData = true
            }
            break
          case 'insertTextLatestDecision':
            if (
              !previewDataList ||
              previewDataList.length <= 0 ||
              previewDataList.some(item => isEmpty(item.decisionDivision))
            ) {
              isEmptyData = true
            }
            break
          case 'insertInterviewDate':
            if (
              interviewWebUrlSendFlag ===
              magiContants.INTERVIEW_WEB_URL_SEND_FLAG
            ) {
              isError = true
            } else if (
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_FIX_INTERVIEW &&
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_WITHDRAW_INTERVIEW &&
              interviewScheduleAdjustmentAction !==
              magiContants.ACTION_CHANGE_INTERVIEW &&
              Number(manipulationSelection) !==
              magiContants.SELECTION_NUMBER_FIX &&
              Number(manipulationSelection) !==
              magiContants.SELECTION_NUMBER_SEND
            ) {
              isError = true
            } else {
              if (
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_FIX_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_WITHDRAW_INTERVIEW ||
                interviewScheduleAdjustmentAction ===
                magiContants.ACTION_CHANGE_INTERVIEW
              ) {
                if (
                  !previewDataList ||
                  previewDataList.length <= 0 ||
                  previewDataList.some(item => item.interviewStartTime === null)
                ) {
                  isEmptyData = true
                }
              }
            }
            break
          case 'insertInterviewLocation':
            if (
              interviewWebUrlSendFlag !==
              magiContants.INTERVIEW_WEB_URL_SEND_FLAG
            ) {
              if (
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_FIX_INTERVIEW &&
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_WITHDRAW_INTERVIEW &&
                interviewScheduleAdjustmentAction !==
                magiContants.ACTION_CHANGE_INTERVIEW &&
                Number(manipulationSelection) !==
                magiContants.SELECTION_NUMBER_FIX &&
                Number(manipulationSelection) !==
                magiContants.SELECTION_NUMBER_SEND
              ) {
                isError = true
              } else {
                if (
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_FIX_INTERVIEW ||
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_WITHDRAW_INTERVIEW ||
                  interviewScheduleAdjustmentAction ===
                  magiContants.ACTION_CHANGE_INTERVIEW
                ) {
                  if (
                    !previewDataList ||
                    previewDataList.length <= 0 ||
                    previewDataList.some(item =>
                      isEmpty(item.interviewLocationName)
                    )
                  ) {
                    isEmptyData = true
                  }
                }
              }
            }
            break
          case 'requestUrl':
            isError = true
            break
        }
        if (isError || isEmptyData) {
          if (isError) {
            alertMessageList = [
              ...alertMessageList,
              getMessage('MCAZS091-002', [item.before]),
            ]
          }
          if (isEmptyData) {
            if (targetList.length > 1) {
              alertMessageList = [
                ...alertMessageList,
                getMessage('MCAZS091-004', [item.before]),
              ]
            } else {
              alertMessageList = [
                ...alertMessageList,
                getMessage('MCAZS091-003', [item.before]),
              ]
            }
          }
        } else {
          if (item.name === 'insertTextCompanyName') {
            if (
              destinationSelection ===
              magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
            ) {
              content = content.replace(
                item.rule,
                '<span style="font-weight: bold">[この部分には自動で掲載社名が入ります]</span>'
              )
            } else if (
              destinationSelection ===
              magiContants.DESTINATION_DIVISION_EMAIL_ONLY
            ) {
              content = content.replace(
                item.rule,
                '<span style="font-weight: bold">[この部分には自動で送信元会社名が入ります]</span>'
              )
            }
          } else {
            content = content.replace(item.rule, item.after)
          }
        }
      }
    })
    // phase2 end
    const result = { content, alertMessageList }
    return result
  }

  const dateToDateString = (
    target: Date | null,
    shouldUseTimezone: boolean
  ) => {
    if (shouldUseTimezone) {
      return target && momentTz(target).isValid()
        ? momentTz(target).format('YYYY/MM/DD')
        : null
    } else {
      return target && moment(target).isValid()
        ? moment(target).format('YYYY/MM/DD')
        : null
    }
  }

  const getDay = (value: Date, shouldUseTimezone: boolean) => {
    if (value) {
      const wDay = new Array(7)
      wDay[0] = '日'
      wDay[1] = '月'
      wDay[2] = '火'
      wDay[3] = '水'
      wDay[4] = '木'
      wDay[5] = '金'
      wDay[6] = '土'
      if (shouldUseTimezone) {
        return Number(momentTz(value).format('d')) > -1
          ? wDay[Number(momentTz(value).format('d'))]
          : ''
      } else {
        return Number(moment(value).format('d')) > -1
          ? wDay[Number(moment(value).format('d'))]
          : ''
      }
    }
    return ''
  }

  const timeToString = (target: Date | null, shouldUseTimezone: boolean) => {
    if (shouldUseTimezone) {
      return target && momentTz(target).isValid()
        ? momentTz(target).format('HH:mm')
        : ''
    } else {
      return target && moment(target).isValid()
        ? moment(target).format('HH:mm')
        : ''
    }
  }

  //メッセージ内容確認画面を閉じる
  // 「いいえ」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
    dispatch(setIsDraft(false))
  }
  // 「はい」ボタン押下
  const handleConfirm = () => {
    onClose()
    setConfirmDialogOpen(false)
    dispatch(closeMessageInputDialog())
  }

  return (
    <div style={{ display: open ? '' : 'none', height: '100%', minHeight: 'fit-content' }}>
      {(!previewDataList || previewDataList.length <= 0) && <Loading />}
      {isSending && (
        <>
          <h1>isSending...</h1>
          <Loading />
        </>
      )}
      <aside className='message-confirm'
        style={{ height: '100%', minHeight: 'fit-content' }}>
        {recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
          initResult.multiProgressFlg && (
            <section className={classes.multiProgress}>
              <div className={classes.multiProgressDiv}>
                {getMessage(magiContants.MESSAGECODE_MCAZS091_065)}
              </div>
            </section>
          )}
        {destinationSelection ===
          contants.DESTINATION_DIVISION_EMAIL_ONLY && (
            <section className={classes.alert}>
              応募者が登録しているemailアドレス宛にメールで送付します。
            </section>
          )}
        {recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
          state.notAdoptedNotificationType && (
            <section className={classes.notAdoptedNotification}>
              <p>
                不採用通知設定で登録した内容で、翌日の15:00に配信予約されます。
                <br />
                メッセージの内容をよくご確認の上、送信してください。
                <br />
                メッセージの内容を修正したい場合は、メッセージ送信予約画面より修正ください。
                <br />
                配信予約完了後、選考ステップは「不採用」に変更されます。変更後は選考対象外一覧に移動されます。
              </p>
            </section>
          )}
        {interviewWebUrlSendFlag ===
          magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
          recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && (
            <section className={classes.alert}>
              ※応募者に送信されたWEB面接URLは面接調整一覧に表示されます。
              <br />
              ※担当者用のWEB面接URLもあわせて面接調整一覧から確認できます。
            </section>
          )}
        {interviewWebUrlSendFlag !==
          magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
          interviewScheduleAdjustmentAction ===
          magiContants.ACTION_WITHDRAW_INTERVIEW &&
          recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && (
            <section className={classes.alert}>
              発行済みのWEB面接URLがある場合は、メッセージ送信後にWEB面接URLもキャンセルとなります。
            </section>
          )}
        {alertMessageList && alertMessageList.length > 0 && (
          <section className={classes.alert}>
            {alertMessageList.map(item => (
              <>{item}</>
            ))}
          </section>
        )}
        <SendTarget
          targetList={state.sendTargetConfirmation.sendTarget}
          destinationSelection={state.destinationDivision}
          recruitmentManagementDivision={recruitmentManagementDivision}
          searchCriteriaName={searchCriteriaName}
          searchCriteria={searchCriteria}
          searchCriteriaInfo={searchCriteriaInfo}
          mySearchCondition={mySearchCondition}
          conversionMySearch={conversionMySearch}
          filterCondition={filterCondition}
          destinationDivision={state.destinationDivision}
        />
        {state.manipulationKubun !== '' ||
          state.interviewScheduleAdjustmentSelection !== '0' ? (
          <InterviewTarget
            targetList={state.interviewSchedule.candidateDate}
            interviewScheduleAdjustmentAction={state.manipulationKubun}
            interviewScheduleAdjustmentStatus={
              state.interviewScheduleAdjustmentStatus
            }
            interviewScheduleAdjustmentSelection={
              state.interviewScheduleAdjustmentSelection
            }
            adjustmentInterviewScheduleId={
              state.adjustmentInterviewScheduleId
            }
            adjustmentInterviewDurationId={
              state.adjustmentInterviewDurationId
            }
            adjustmentInterviewDetail={state.adjustmentInterviewDetail}
            interviewDurationId={state.interviewDurationId}
            interviewDurationList={
              state.interviewSchedule.interviewDurationSelect
            }
            interviewLocationList={
              state.interviewSchedule.interviewLocation
            }
            interviewLocationIdList={state.adjustmentInterviewLocationId}
            interviewScheduleIdList={state.adjustmentInterviewScheduleId}
            interviewStaffNameList={state.adjustmentInterviewStaffName}
            interviewStartTimeList={state.adjustmentInterviewStartTime}
            interviewScheduleStatusList={
              state.adjustmentInterviewScheduleStatus
            }
            interviewDetail={state.interviewDetail}
            newInterviewScheduleStatusList={
              state.newInterviewScheduleStatus
            }
            senderCompanyName={state.senderCompanyName}
          />
        ) : interviewWebUrlSendFlag ===
          magiContants.INTERVIEW_WEB_URL_SEND_FLAG &&
          recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 ? (
          <InterviewTarget
            targetList={state.interviewSchedule.candidateDate}
            interviewScheduleAdjustmentAction={state.manipulationKubun}
            interviewScheduleAdjustmentStatus={
              state.interviewScheduleAdjustmentStatus
            }
            interviewScheduleAdjustmentSelection={
              state.interviewScheduleAdjustmentSelection
            }
            adjustmentInterviewScheduleId={
              state.adjustmentInterviewScheduleId
            }
            adjustmentInterviewDurationId={
              state.adjustmentInterviewDurationId
            }
            adjustmentInterviewDetail={state.adjustmentInterviewDetail}
            interviewDurationId={state.interviewDurationId}
            interviewDurationList={
              state.interviewSchedule.interviewDurationSelect
            }
            interviewLocationList={
              state.interviewSchedule.interviewLocation
            }
            interviewLocationIdList={state.adjustmentInterviewLocationId}
            interviewScheduleIdList={state.adjustmentInterviewScheduleId}
            interviewStaffNameList={state.adjustmentInterviewStaffName}
            interviewStartTimeList={state.adjustmentInterviewStartTime}
            interviewScheduleStatusList={
              state.adjustmentInterviewScheduleStatus
            }
            interviewDetail={state.interviewDetail}
            newInterviewScheduleStatusList={
              state.newInterviewScheduleStatus
            }
            senderCompanyName={state.senderCompanyName}
          />
        ) : null}
        <MessageInput
          destinationDivision={state.destinationDivision}
          recruitmentManagementDivision={recruitmentManagementDivision}
          sendValue={state.messageInput}
          sendTimeValue={state.sendTimeInput}
          notifyNotification={state.notifyNotification}
          notifyMailAddress={state.notifyMailAddress}
          // [phase2] start by zhangxp
          sendToNonMember={state.sendToNonMember}
          // [phase2] end by zhangxp
          replyMailAddress={initResult.replyMailAddress}
        />
      </aside>
      <footer className='ta-unset footer'>
        {recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
          state.notAdoptedNotificationType ? (
          <button className='btn' type='submit' onClick={handleCansel}>
            キャンセルする
          </button>
        ) : (
          <button className='btn' type='submit' onClick={handleClose}>
            修正する
          </button>
        )}
        <button id='sendMessage' className='btn high' type='submit' onClick={handleSend}>
          送信する
        </button>
      </footer>
      <Dialog open={modals} fullWidth maxWidth='sm'>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {getMessage(contants.MESSAGECODE_MCAZS161_002)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCansel}>キャンセル</Button>
          <Button onClick={handleSubmit}>送信する</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOpen}>
        <LoadingDialog className='overlay' />
        <DialogTitle>{textMap[type].message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>{textMap[type].cancel}</Button>
          <Button onClick={handleConfirm} color='primary'>
            {textMap[type].submit}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}
