import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'sp/componentsHsc'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cusMargin: {
    marginLeft: 'initial',
    marginRight: '30px',
  },
  cusLabelWidth: {
    width: 'max-content',
  },
})

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  myChange: Function
  formName?: string
  target?: string
  targetIndex?: number
  selectOptionList?: Option[]
}

const UncheckableToggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  target,
  targetIndex,
  selectOptionList,
}: Props) => {
  const classes = useErrorStyle()
  const cusClasses = useStyles()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const selectOptionName = name.split('.')[0] + '.managementItemFlagCheckBoxObj'

  const handleChange = (e: any) => {
    if (e.target.value !== undefined) {
      switch (target) {
        case 'answerCondition':
        case 'managementItemPresence':
          if (handleRadioCheck(e.target.value)) {
            form.setFieldValue(name, '')
            if (undefined != selectOptionList && e.target.value === '0') {
              form.setFieldValue(selectOptionName, [])
            }
          } else {
            form.setFieldValue(name, e.target.value)
            if (undefined != selectOptionList && e.target.value === '0') {
              form.setFieldValue(selectOptionName, selectOptionList)
            }
          }
          break
        default:
          form.setFieldValue(name, e.target.value)
          break
      }
    }
  }

  const handleRadioCheck = (value: string) => {
    if (value !== undefined) {
      let result = undefined
      switch (target) {
        case 'answerCondition':
          if (targetIndex !== undefined && form.values['conditionList'][targetIndex] !== undefined) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex].answerConditionFlag ===
              value
          }
          break
        case 'managementItemPresence':
          if (targetIndex !== undefined && form.values['conditionList'][targetIndex] !== undefined ) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex]
                .managementItemPresenceFlag === value
          }
          break
        default:
          break
      }
      return result
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <div className='form__body_item_modal' >
        <RadioGroup row={row} value={value} onClick={handleChange} style={{ flexWrap: "initial" }}>
          {optionList.map(i => (
            <FormControlLabel
              key={i.value}
              className={cusClasses.cusMargin}
              control={
                <Radio
                  color='primary'
                  value={i.value}
                  disabled={disabled}
                  checked={handleRadioCheck(i.value)}
                  name={i.label}
                  style={{ padding: 0 }}
                  icon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='21'
                      height='21'
                      viewBox='0 0 21 21'
                      className='form__radio_svg_off'>
                      <use
                        xlinkHref='#form__radio_off'></use>
                    </svg>
                  }
                  checkedIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='21'
                      height='21'
                      viewBox='0 0 21 21'
                      className='form__radio_svg_on'>
                      <use
                        xlinkHref='#form__radio_on'></use>
                    </svg>
                  }
                />
              }
              label={<span className={`form__radio_text ${cusClasses.cusLabelWidth}`}>{i.label}</span>}
            />
          ))}
        </RadioGroup>
      </div>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default UncheckableToggle
