import React from 'react';

export default () => {
  return (
    <div className="box-detailed-search">
      <button
        type="button"
        className="on-icon icon-searchCondition"
        data-action="open-search-condition">
        検索条件を呼び出す
      </button>
      <hr />
      <button type="button" className="btn on-icon icon-addApplicant">
        応募者追加・更新
      </button>
      <button type="button" className="btn on-icon icon-applylistname">
        名寄せ
      </button>
      <button type="button" className="btn high on-icon icon-search">
        詳細検索を行う
      </button>
      <div className="">
        <input type="checkbox" id="toggle-detailed-seartch-01" />
        <label htmlFor="toggle-detailed-seartch-01">分類名</label>
        <dl>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <input type="text" placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <select>
              <option>xxxxxxxxxxxxxxxxxxxxx</option>
            </select>
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <input type="text" placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <label>
              <input type="checkbox" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
            <label>
              <input type="checkbox" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <label>
              <input type="radio" name="radio-toggle-detailed-seartch-01" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
            <label>
              <input type="radio" name="radio-toggle-detailed-seartch-02" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
          </dd>
        </dl>
        <input type="checkbox" id="toggle-detailed-seartch-02" />
        <label htmlFor="toggle-detailed-seartch-02">分類名</label>
        <dl>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <input type="text" placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <select>
              <option>xxxxxxxxxxxxxxxxxxxxx</option>
            </select>
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <input type="text" placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <label>
              <input type="checkbox" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
            <label>
              <input type="checkbox" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
          </dd>
          <dt>xxxxxxxxxxxx</dt>
          <dd>
            <label>
              <input type="radio" name="radio-toggle-detailed-seartch-01" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
            <label>
              <input type="radio" name="radio-toggle-detailed-seartch-02" />
              <mark></mark>xxxxxxxxxxxxxxx
            </label>
          </dd>
        </dl>
        <div>
          <button type="button" className="btn high on-icon icon-search">
            検索
          </button>
          <button type="button" className="btn">
            検索条件を保存
          </button>
        </div>
      </div>
      <button type="button" className="btn only-icon icon-brackets_toggle">
        検索条件を閉じる
      </button>
    </div>
  );
};
