import { Tooltip, Chip, ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from 'sp/components'
import { FastField, Field } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from './GroupOptions'
import { SelectOptions } from './Select'
import {
  QuestionnaireCondition,
  QuestionnaireObj,
  retainAll,
} from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { initeRequest } from 'componentsHsc/AdvancedSearchJobChange/Config/formConfig'
import {
  getMcaxs561InitJobChange,
  setEntryReplyModalStateJobChange,
} from 'reducers/itemSpecificationReducer'
import { setEntryReplyModalOpen } from 'reducers/advancedSearchJobChangeReducer'
import { setSearchCondition } from 'reducers/entryListReducer'
import { toEntryList } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { stringToDate, dateToString } from 'common/generalUtil'
import moment from 'moment'
import { magiContants } from 'utils/contants'
import React, { useState } from 'react'
const useStyles = makeStyles(theme => ({
  dateRange: {
    '& input': {
      padding: '0 8px',
    },
  },
  notitle: {
    paddingLeft: 10,
    marginRight: 80,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    margin: theme.spacing(0.38, 0),
    fontSize: '12px',
    maxWidth: '295px',
    backgroundColor: '#eceff1',
    height: '30px',
  },
  datePickerDiv: {
    maxwidth: '170px',
    float: 'left',
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: '13px 12px 0px 20px',
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '200px',
    top: '9px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '200px',
    marginTop: '9px',
  },
  cursor: {
    '& div': {
      cursor: 'pointer!important',
    },
  },
  bodyitem: {
    margin: 'inherit',
  },
  selectWidth: {
    maxwidth: 'none !important',
  },
  bodyItem: {
    margin: '0 0 0 10px',
  },
  itemCloumn: {
    margin: '0 0 20px 10px',
  },
  styleCloumn: {
    display: 'flex',
    flexdirection: 'column',
  },
  inBodyItem: {
    display: 'flex',
    flexwrap: 'wrap',
    alignitems: 'center',
    width: 'calc(100% - 10px)',
    gap: '10px 25px',
  },
  formControl: {
    border: '0px solid !important',
  },
  itemControl: {
    padding: '2px 0 2px 0 !important',
  },
  pToolTip: {
    ' & p ': {
      wordWrap: 'break-word',
    },
  },
  marginTop: {
    marginTop: '10px !important',
  }

}))

interface Props {
  form: FormControl
  setIsToolTipShow: (value: boolean) => void
}

export const unreadOptionList = [
  { value: '0', label: '履歴書が未読のエントリーのみ対象とする' },
]

/**
 * アンケート回答内容条件の編集
 */
const generateLabel = (item: QuestionnaireCondition) => {
  const question = item.questionTitle
  let answer: string = ''
  if (item.answerConditionFlag === '1') {
    //回答有無FLGが[1]回答なしの場合
    answer = '回答なし'
  } else {
    //回答有無FLGが[0]回答ありの場合
    if (item.questionTypeCode === '1') {
      //質問種別コードが[1]自由記述の場合
      if (item.answerText === '') {
        //回答テキストがない場合
        answer = '回答があるすべて'
      } else {
        //回答テキストがある場合
        answer = `回答に「${item.answerText}」が含まれる`
      }
    } else {
      //質問種別コードが[1]自由記述　以外の場合
      if (item.answerCheckBoxObj.length === 0) {
        //回答テキストがない場合
        answer = '回答があるすべて'
      } else {
        //回答テキストがある場合
        const answerLabelList = item.answerCheckBoxObj.map(i => i.label)
        answer = answerLabelList.join('、')
      }
    }
  }
  return `Q.${question} A.${answer}`
}

const exemptionOptionList = [
  { value: '0', label: '書類選考免除のエントリーのみ対象とする' },
]
const duplicationOptionList = [
  { value: '0', label: '重複したエントリーのみ対象とする' },
]
const multipleOptionList = [
  { value: '0', label: '複数選考中のエントリーのみ対象とする' },
]
const setQuestionTypeName = (questionTypeCode: string) => {
  let questionTypeName: string = ''
  switch (questionTypeCode) {
    case '0':
      questionTypeName = 'なし'
      break
    case '1':
      questionTypeName = '自由記述'
      break
    case '2':
      questionTypeName = '単一選択'
      break
    case '3':
      questionTypeName = '複数選択'
      break
    default:
      break
  }
  return questionTypeName
}

// 応募情報
const Applicant = ({ form, setIsToolTipShow }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { conditionList = [], typeName = '' } =
    form.values.questionnaireObj || {}

  // 初期表示データ設定
  const {
    memberTypeList,
    applicationRouteList,
    entryClassificationList,
    entryTypeList,
    entryJobCategoryList,
    scoutBenefitsList,
  } = useSelector(
    (state: RootState) => state.advancedSearchJobChange.applicantInfo
  )

  // 転職Booster start
  const { serviceMode, marchServiceFlag } = useSelector(
    (state: RootState) => state.globalMenu
  )
  // 転職Booster end

  const applicationRouteOptionList = applicationRouteList.map(i => {
    return {
      applicationRouteFlag: i.applicationRouteFlag,
      value: i.applicationRouteId,
      label: i.applicationRouteName,
    }
  })

  const postingStartTime = form.values.postingStartTime
    ? form.values.postingStartTime
    : null
  const postingEndTime = form.values.postingEndTime
    ? form.values.postingEndTime
    : null
  const filterEntryJobCategoryList = () => {
    let entryJobCategoryOptionList = entryJobCategoryList
    if (null != stringToDate(postingStartTime)) {
      const startMilliseconds = moment(postingStartTime)
        .toDate()
        .getTime()
      entryJobCategoryOptionList = entryJobCategoryOptionList.filter(
        i =>
          !(
            moment(dateToString(i.postingStartTime))
              .toDate()
              .getTime() < startMilliseconds &&
            moment(dateToString(i.postingEndTime))
              .toDate()
              .getTime() < startMilliseconds
          )
      )
    }
    if (null != stringToDate(postingEndTime)) {
      const endMilliseconds = moment(postingEndTime)
        .toDate()
        .getTime()
      entryJobCategoryOptionList = entryJobCategoryOptionList.filter(
        i =>
          !(
            moment(dateToString(i.postingStartTime))
              .toDate()
              .getTime() > endMilliseconds &&
            endMilliseconds <
            moment(dateToString(i.postingEndTime))
              .toDate()
              .getTime()
          )
      )
    }
    return entryJobCategoryOptionList.map(i => {
      return {
        value: i.jobId,
        value2: i.coordinationFormerJobCategoryIdentificationKey,
        label: i.entryJobCategoryName,
      }
    })
  }

  const disabledCheck = (form: FormControl) => {
    if (form.values.applicationTypeObj === null) {
      return true
    } else {
      const applicationTypeObj = form.values.applicationTypeObj

      let selectedApplicationTypeInfo = null
      if (applicationTypeObj.value) {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i => i.jobId === applicationTypeObj.value
        )
      } else {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i =>
            i.coordinationFormerJobCategoryIdentificationKey ===
            applicationTypeObj.value2 &&
            i.entryJobCategoryNameRaw === applicationTypeObj.label
        )
      }

      if (selectedApplicationTypeInfo) {
        return selectedApplicationTypeInfo.umuFlag !== 1
      } else {
        return true
      }
    }
  }

  useEffect(() => {
    dispatch(
      getMcaxs561InitJobChange({
        request: {
          ...initeRequest,
          coordinationFormerJobCategoryIdentificationKey:
            form.values.applicationTypeObj !== null
              ? form.values.applicationTypeObj.value2
              : null,
        },
        isInitSelectedQuestionnaireObj: true,
        toggle: () => { },
      })
    )
  }, [])

  const entryReplyModalState = useSelector(
    (state: RootState) => state.itemSpecification.entryReplyModalStateJobChange
  )

  // #49324 start
  const questionaire = useSelector(
    (state: RootState) => state.itemSpecification.questionaire
  )
  const questionaireInit = (questionnaireObj: QuestionnaireObj) => {
    if (questionnaireObj && questionaire && questionaire.length > 0) {
      const newCondList: QuestionnaireCondition[] = []
      // 「エントリー・企業質問情報」と「呼び出すのMY検索データ」をループする、判定条件に基づいて「設問」に「選択肢」と「テキスト」の表示内容を決定します。
      questionaire.forEach(item => {
        const filterResult = questionnaireObj.conditionList.filter(obj =>
          // 「選択肢」と「テキスト」を入力しないの場合、同じ「設問」のデータを抽出する
          obj.answerCheckBoxObj.length === 0 && '' === obj.answerText
            ? obj.questionTitle === item.title &&
            (null !== obj.questionOption
              ? (obj.questionOption === item.questionOption ||
                ('' === obj.questionOption &&
                  null === item.questionOption)) &&
              obj.questionTypeCode === item.type
              : true)
            : // 設問形式が「自由記述」の場合、同じ「設問形式」のデータを抽出する。それ以外の場合、「同じ選択肢を存在する」のデータを抽出する
            ('1' === item.type
              ? obj.questionTypeCode === item.type
              : retainAll(item.optionList, obj.answerCheckBoxObj).length >
              0) &&
            // 「設問」が同じのデータを抽出する
            obj.questionTitle === item.title &&
            // 保存したのMY検索条件で「選択肢の文字列（questionOption）」存在の場合、同じ「選択肢の文字列」のデータを抽出する
            (obj.questionOption && item.questionOption
              ? obj.questionOption === item.questionOption &&
              obj.questionTypeCode === item.type
              : true)
        )
        const equalsQuestionTitle = questionnaireObj.conditionList.filter(
          obj => obj.questionTitle === item.title
        )
        const findResult = filterResult.find(filter =>
          equalsQuestionTitle.length > 1
            ? filter.questionTypeCode === item.type
            : true
        )
        if (findResult) {
          if (findResult.answerCheckBoxObj && item.optionList) {
            // 「選択肢」存在するの場合
            const filterCheckBoxResult = retainAll(
              findResult.answerCheckBoxObj,
              item.optionList
            )
            // filterCheckBoxResult：「MY検索条件で保存の選択肢の数」と「エントリーデータの選択肢の数」が異なる場合、「選択肢」の交集を取得し、エントリーデータにある選択肢のみがチェックする
            if (filterCheckBoxResult && filterCheckBoxResult.length > 0) {
              // 「選択肢」の交集存在する
              newCondList.push({
                ...findResult,
                answerCheckBoxObj: filterCheckBoxResult, //　「選択肢」の交集を「回答選択肢」に設定する
                questionNumber: item.id ? item.id : findResult.questionNumber, //　「設問番号」を設定する
                questionOption: item.questionOption ? item.questionOption : '', //　「選択肢の文字列」を設定する
                questionTypeCode: item.type
                  ? item.type
                  : findResult.questionTypeCode, //　「質問種別コード」のを設定する
                questionTypeName: item.type
                  ? setQuestionTypeName(item.type)
                  : setQuestionTypeName(findResult.questionTypeCode), //　「質問種別名」を設定する
              })
            } else {
              // 「選択肢」の交集存在しない
              newCondList.push({
                ...findResult,
                questionNumber: item.id ? item.id : findResult.questionNumber,
                questionOption: item.questionOption ? item.questionOption : '',
                questionTypeCode: item.type
                  ? item.type
                  : findResult.questionTypeCode,
                questionTypeName: item.type
                  ? setQuestionTypeName(item.type)
                  : setQuestionTypeName(findResult.questionTypeCode),
              })
            }
          } else {
            // 設問形式が「自由記述」の場合
            newCondList.push({
              ...findResult,
              questionNumber: item.id ? item.id : findResult.questionNumber,
              questionOption: item.questionOption ? item.questionOption : '',
              questionTypeCode: item.type
                ? item.type
                : findResult.questionTypeCode,
              questionTypeName: item.type
                ? setQuestionTypeName(item.type)
                : setQuestionTypeName(findResult.questionTypeCode),
            })
          }
        }
      })
      const newQuestionnaireObj: QuestionnaireObj = {
        ...questionnaireObj,
        conditionList: newCondList,
      }
      return newQuestionnaireObj
    } else {
      return questionnaireObj
    }
  }
  // #49324 end

  const handleEntryReplyClick = () => {
    const request = { ...initeRequest }

    if (form.values.applicationTypeObj) {
      const applicationTypeObj = form.values.applicationTypeObj
      let selectedApplicationTypeInfo = null
      if (applicationTypeObj.value) {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i => i.jobId === applicationTypeObj.value
        )
      } else {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i =>
            i.coordinationFormerJobCategoryIdentificationKey ===
            applicationTypeObj.value2 &&
            i.entryJobCategoryNameRaw === applicationTypeObj.label
        )
      }
      if (selectedApplicationTypeInfo) {
        request.coordinationFormerJobCategoryIdentificationKey =
          selectedApplicationTypeInfo.coordinationFormerJobCategoryIdentificationKey
      }
    }

    let isInitSelectedQuestionnaireObj = true
    const newEntryReplyModalState = { ...entryReplyModalState }
    const questionnaireObj = form.values.questionnaireObj && questionaireInit(form.values.questionnaireObj)
    newEntryReplyModalState.typeCode = questionnaireObj && questionnaireObj.typeCode
    newEntryReplyModalState.typeName = questionnaireObj && questionnaireObj.typeName
    if (
      entryReplyModalState.conditionList &&
      entryReplyModalState.conditionList.length > 0
    ) {
      if (form.values.questionnaireObj) {
        isInitSelectedQuestionnaireObj = false

        const newCond: QuestionnaireCondition[] = []
        if (newEntryReplyModalState.conditionList) {
          newEntryReplyModalState.conditionList.forEach(i => {
            const findResult = questionnaireObj && questionnaireObj.conditionList.find(
              itemObjCondition =>
                itemObjCondition.questionNumber === i.questionNumber &&
                itemObjCondition.questionOption === i.questionOption &&
                itemObjCondition.questionTitle === i.questionTitle &&
                itemObjCondition.questionTypeCode === i.questionTypeCode
            )
            if (findResult) {
              newCond.push(findResult)
            } else {
              newCond.push({
                ...i,
                answerConditionFlag: '',
                answerText: '',
                answerCheckBoxObj: [],
              })
            }
          })
        }

        newEntryReplyModalState.conditionList = newCond
      }
    }
    dispatch(setEntryReplyModalStateJobChange(newEntryReplyModalState))

    dispatch(
      getMcaxs561InitJobChange({
        request: request,
        isInitSelectedQuestionnaireObj: isInitSelectedQuestionnaireObj,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setEntryReplyModalOpen(true))
        },
      })
    )
  }

  const handleChipDelete = (
    conditionList: QuestionnaireCondition[],
    index: number
  ) => {
    const newConditionList = conditionList.slice()
    newConditionList.splice(index, 1)
    if (newConditionList.length > 0) {
      form.setFieldValue('questionnaireObj.conditionList', newConditionList)
    } else {
      form.setFieldValue('questionnaireObj', null)
    }
    handleTooltipClose()
  }

  const [toolTip, setToolTip] = useState(false)
  const handleToolTip = () => {
    setToolTip(false)
  }

  const [openTooltipIndex, setopenTooltipIndex] = useState<number | null>(null)
  const [tooltipContent, setTooltipContent] = useState('')

  const handleTooltipOpen = (index: number, content: string) => {
    setopenTooltipIndex(index);
    setTooltipContent(content);
  };

  const handleTooltipClose = () => {
    setopenTooltipIndex(null);
    setTooltipContent('');
  };

  useEffect(() => {
    if (toolTip || openTooltipIndex) {
      setIsToolTipShow(true)
    } else {
      setIsToolTipShow(false)
    }
  }, [openTooltipIndex, toolTip])

  return (
    <>
      <div>
        <div className={'form__ttl'}>応募情報</div>
        <div className={'form__body'}>
          {/* 転職Booster start */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl'}>応募日</div>
              <div className={'form__body_item_gap_nocenter mb20'}>
                <FastField
                  name='applicationFrom'
                  selectsStart
                  className={'form__input-calender'}
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                />
                <span className={`form__text_while_3 ${classes.marginTop}`}>～</span>
                <FastField
                  name='applicationTo'
                  selectsEnd
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                />
              </div>
            </div>
          )}
          {/* 転職Booster end */}
          <div className={'form__body_ttl'}>会員種別</div>
          <FastField
            name='memberTypeObj'
            row
            component={GroupOptions}
            optionList={memberTypeList}
            groupClassName={'form__body_item'}
          />
          <div className={'form__body_ttl'}>応募経路</div>
          <div className={classes.bodyItem}>
            <FastField
              name='applicationRouteObj'
              row
              component={GroupOptions}
              optionList={applicationRouteOptionList}
              groupClassName={classes.inBodyItem}
            />
          </div>
        </div>
      </div>
      {/* 転職Booster #90253 START */}
      {serviceMode === magiContants.STRING_1 &&
        marchServiceFlag === magiContants.STRING_1 ? (
        ''
      ) : (
        <div className={'form__body'}>
          <div className={'form__ttl'}>応募職種</div>
          <div>
            <div className={'form__body'}>
              <div className={'form__body_ttl'}>応募職種</div>
              <div className={'form__body_item_gap0 mb5'}>
                掲載期間を指定する
                <ClickAwayListener onClickAway={handleToolTip}>
                  <svg
                    className={'form__body_ttl_icon mr10'}
                    focusable='false'
                    viewBox='0 0 24 24'
                    aria-hidden='true'
                    data-js-tooltip-trigger='tooltip-message-1'
                    onClick={() => setToolTip(!toolTip)}>
                    <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
                  </svg>
                </ClickAwayListener>
              </div>
              <div className={'form__body_item_gap_nocenter mb20'}>
                <FastField
                  name='postingStartTime'
                  selectsStart
                  className={'form__input-calender'}
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                />
                <span className={`form__text_while_3 ${classes.marginTop}`}>～</span>
                <FastField
                  name='postingEndTime'
                  selectsEnd
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                />
              </div>
              <div className={'form__body_item'}>
                <div className={`${classes.formControl} form__control `}>
                  <div className={`${classes.itemControl} form__control_item`}>
                    <Field
                      name='applicationTypeObj'
                      optionList={filterEntryJobCategoryList()}
                      defaultValue={form.values.applicationTypeObj}
                      placeholder='応募職種を選択'
                      component={SelectOptions}
                      className={'form__control_input text-overflow'}
                      selectClassName={'form__control_item_singleValue is-suggest-hidden'}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div className={'form__body_ttl'}>企業から応募者への質問</div>
              <div className={classes.itemCloumn}>
                <div className={classes.styleCloumn}>
                  <Button
                    onClick={handleEntryReplyClick}
                    disabled={disabledCheck(form)}
                  />
                  <div
                    className={
                      window.navigator.userAgent
                        .toLocaleLowerCase()
                        .match(/(trident)\/([\d.]+)/)
                        ? classes.andOrStyleIE
                        : classes.andOrStyleOther
                    }>
                    {typeName}
                  </div>
                </div>
                {conditionList.length > 0 && (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      {conditionList.map((item, index) => (
                        <Chip
                          key={index}
                          className={classes.chip}
                          label={generateLabel(item)}
                          onClick={() =>
                            handleTooltipOpen(index, generateLabel(item))
                          }
                          onDelete={() =>
                            handleChipDelete(conditionList, index)}
                        />
                      ))}
                    </div>
                  </ClickAwayListener>
                )}
              </div>
              {/* 転職Booster start */}
              {serviceMode === magiContants.STRING_1 &&
                marchServiceFlag === magiContants.STRING_1 ? (
                ''
              ) : (
                <div>
                  <div className={'form__body_ttl'}>応募種別</div>
                  <FastField
                    name='entryTypeObj'
                    row
                    component={GroupOptions}
                    optionList={entryTypeList}
                    groupClassName={'form__body_item'}
                  />
                </div>
              )}
              {/* 転職Booster end */}
              {/* 転職Booster start */}
              {serviceMode === magiContants.STRING_1 &&
                marchServiceFlag === magiContants.STRING_1 ? (
                ''
              ) : (
                <div>
                  <div className={'form__body_ttl'}>応募区分</div>
                  <FastField
                    name='entryClassificationObj'
                    row
                    component={GroupOptions}
                    optionList={entryClassificationList}
                    groupClassName={'form__body_item'}
                  />
                </div>
              )}
              {/* 転職Booster end */}
              {/* 転職Booster start */}
              {serviceMode === magiContants.STRING_1 &&
                marchServiceFlag === magiContants.STRING_1 ? (
                ''
              ) : (
                <div>
                  <div className={'form__body_ttl'}>スカウト特典</div>
                  <FastField
                    name='scoutBenefitsObj'
                    row
                    component={GroupOptions}
                    optionList={scoutBenefitsList}
                    groupClassName={'form__body_item'}
                  />
                </div>
              )}
              {/* 転職Booster end */}
              {/* 転職Booster start */}
              {serviceMode === magiContants.STRING_1 &&
                marchServiceFlag === magiContants.STRING_1 ? (
                ''
              ) : (
                <div>
                  <div className={'form__body_ttl'}>書類選考免除</div>
                  <FastField
                    name='exemptionObj'
                    row
                    component={GroupOptions}
                    optionList={exemptionOptionList}
                    groupClassName={'form__body_item'}
                  />
                </div>
              )}
              {/* 転職Booster end */}
            </div>
          </div>
        </div>
      )}
      {/* 転職Booster #90253 END */}
      <div className={'form__ttl'}>その他</div>
      <div className={'form__body'}>
        <div className={'form__body_ttl'}>履歴書未読</div>
        <FastField
          name='unreadObj'
          row
          component={GroupOptions}
          optionList={unreadOptionList}
          groupClassName={'form__body_item'}
        />
        {serviceMode === magiContants.STRING_1 &&
          marchServiceFlag === magiContants.STRING_1 ? (
          ''
        ) : (
          <div>
            <div className={'form__body_ttl'}>重複</div>
            <FastField
              name='duplicationObj'
              row
              component={GroupOptions}
              optionList={duplicationOptionList}
              groupClassName={'form__body_item'}
            />
          </div>
        )}
        {serviceMode === magiContants.STRING_1 &&
          marchServiceFlag === magiContants.STRING_1 ? (
          ''
        ) : (
          <div>
            <div className={'form__body_ttl'}>複数選考</div>
            <FastField
              name='multipleObj'
              row
              component={GroupOptions}
              optionList={multipleOptionList}
              groupClassName={'form__body_item'}
            />
          </div>
        )}
      </div>
      {/* 掲載期間を指定する */}
      {toolTip && (
        <div id='tooltip-1' className='tooltip is-tooltip-show'>
          <p>
            掲載期間を指定すると「応募職種を選択」するプルダウンで選択できる職種が絞り込まれます。
          </p>
        </div>
      )}
      {/* 掲載期間を指定する */}

      {/* 企業から応募者への質問 ツールチップ */}
      {openTooltipIndex !== null && (
        <div id='tooltip-2' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
          <p>
            {tooltipContent}
          </p>
        </div>
      )}
      {/* 企業から応募者への質問 ツールチップ */}


    </>

  )
}

export default Applicant
