import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { getinterviewLocationResultList,checkInterviewLocation, setCreateDialogOpen} from 'reducers/interviewReducer'
import { Dialog, DialogTitle, DialogActions, colors ,DialogContent} from '@material-ui/core'
import { maxcount, msg } from './formConfig'
import Results from './Results'
import { textMap } from './formConfig'
import { MCAZS130InitDisplayRequest } from 'types/MCAZS130/MCAZS130InitDisplayRequest'
import MCAOS020 from 'pages/MCAOS020'
import { magiContants } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    margin: theme.spacing(0, 2, 1, 3),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    width: 150,
    height:"40px"
  },
  back: {
    margin: theme.spacing(1, 0, 2, 3),
   color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 150,
    width:150
  },
  container: {
    marginLeft: theme.spacing(1),
    color:'red',

  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  topStyle:{
    display:"flex",
    flexDirection:"row",
    paddingTop:"40px",
    height:"100px"
  },
  titleText:{
    backgroundColor: '#133e80',
    height: '50px',
  },
  titleDiv: {
    verticalAlign: 'middle',
    color: (theme.palette as any).white,
    fontSize:28,
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper':{
      overflowY: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'auto' : 'hidden',
      display: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'block' : 'flex',
    },
  }
}))
interface Props {
  selectedInterviewLocationId:string
  candidateListId:number,
  updateFormModal: boolean
  setUpdateFormModal: (state: boolean) => void
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
}
function getStepContent(count: number) {
  if (count >= maxcount) {
    return msg
  }
  else{
    return ""
  }
}


const MCAZS130 = ({
  selectedInterviewLocationId,
  candidateListId,
  updateFormModal,
  setUpdateFormModal,
  setSelectInterviewLocationId,
  setSelectInterviewLocationName,
  setSelectInterviewLocationAddress,
  setSelectInterviewLocationMapUrl,
}: Props) => {
  const classes = useStyles()
  const [id, setId] = useState('')
  const data = useSelector(
    (state: RootState) => state.interview.interviewLocationResultList
  )
  const dispatch = useDispatch()
  const handleDialogClose = () => {
    setId('')
  }
  const initInfo=()=>{
    dispatch(getinterviewLocationResultList({recruitmentManagementDivision:''}))
  }
  const handleAddCheck = () => {
    const requestData:MCAZS130InitDisplayRequest={
      // selectedInterviewLocationId:selectedInterviewLocationId,
      // candidateListId:[],
      recruitmentManagementDivision:''
    }
    dispatch(checkInterviewLocation(requestData))
  }
  const backToHome = () => {  
    setUpdateFormModal(false)
  }
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  return (
    <>
    <Dialog
    fullWidth
    maxWidth='md'
    onEnter={initInfo}
    open={updateFormModal}
    className={classes.dialog}
    >
      <DialogContent style={{padding: 0,minWidth: '960px', maxHeight: '100%'}}>
            <DialogTitle className={classes.titleText} id="max-width-dialog-title">
                <div className={classes.titleDiv}>面接場所の追加は「新規に登録する」から登録できます。</div>
            </DialogTitle>

          <DialogContent>
     
        <div className={classes.topStyle}>
        <Button
          variant='contained'
          onClick={handleAddCheck}
          disabled={data.length >= maxcount ? true : false}
          className={classes.buttonStyle}>
          <AddCircleIcon />
          {textMap['register'].renew}
        </Button>
        <span style={{color:"red"}} dangerouslySetInnerHTML={{ __html:getStepContent(data.length)}}></span>
        </div>
      
       <Results
        id={selectedInterviewLocationId}
        candidateListId={candidateListId}
        setUpdateFormModal={setUpdateFormModal}
        setSelectInterviewLocationId={setSelectInterviewLocationId}
        setSelectInterviewLocationName={setSelectInterviewLocationName}
        setSelectInterviewLocationAddress={setSelectInterviewLocationAddress}
        setSelectInterviewLocationMapUrl={setSelectInterviewLocationMapUrl} />
        </DialogContent>
        <Button
          variant='contained'
          className={classes.back} onClick={backToHome}>
          キャンセルする
        </Button>
        </DialogContent>
    </Dialog>

    <Dialog open={confirmDialogOpen}>
        <DialogTitle>{textMap['register'].message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <MCAOS020 onClose={handleDialogClose} screenId={magiContants.SCREENID_MCAZS130} />
    </>
  )
}

export default MCAZS130
