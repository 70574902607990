import palette from '../palette'

export default {
  root: {
    '&$selected': {
      // backgroundColor: palette.background.default,
      backgroundColor: 'inherit',
    },
    '&$hover': {
      '&:hover': {
        // backgroundColor: palette.background.default,
        backgroundColor: 'inherit',
      },
    },
  },
}
