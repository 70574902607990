import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { TextFieldForMessage } from 'componentsHsc'


const useStyles = makeStyles(theme => ({
  textCounter: {
    float: "right",
    minWidth: 120,
    textAlign: "right",
    '& h6': {
      fontWeight: 'normal',
    },
  },
  MultiTextWithCounter: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: theme.spacing(5),
    maxHeight: theme.spacing(5),
    overflow: 'hidden !important',
    whiteSpace:"nowrap",
    resize: 'none',
  },
  TextWithCounter: {
    '& input': {
      background: 'none #fff',
    },
  },
  nonBorder: {
    border: 'none!important' as any,
    padding: '0!important' as any,
    marginTop: '0!important' as any,
  },
}));

interface Props extends FieldProps {
  name?: string
  label: string
  type?: string
  variant?: any
  className?: any
  maxAmount?: number
  onChange?: (value: string) => void
  onBlur?: (target: any) => void
  innerRef?: (instance: any) => void
  disabled?: boolean
}

const MessageInputWithCounter = ({
  innerRef,
  field,
  form,
  label,
  type,
  variant,
  className,
  maxAmount,
  onChange,
  onBlur,
  disabled,
  ...others
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const handleChange = (event: any) => {
    if (400 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value)
    }
  }
  const hanleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event.target)
    }
    form.setFieldTouched(name, true);
  }
  return (
    <React.Fragment>
      <Grid container className={`${className} ${classes.nonBorder} show`}>
        <Grid item xs={12} className={classes.TextWithCounter}>
          <TextFieldForMessage
            form={form}
            field={{ ...field, onChange: handleChange, onBlur: hanleBlur}}
            // label={label}
            type={type}
            innerRef={innerRef}
            disabled={disabled}
            {...others}
          />
        </Grid>
        <Grid item xs={12} className={classes.textCounter}>
          <Typography gutterBottom variant='h6'>
            {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MessageInputWithCounter;
