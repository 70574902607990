import { TextField as MuiTextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'
import theme from 'theme'
import { magiContants, validationMessageSet } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

const useStyles = makeStyles(theme => ({
  placeholder: {
    color: 'red',
    textAlign: 'left',
  },

  input: {
    textAlign: 'right',
    fontSize: '1.6rem',
    minWidth: 144,
    minHeight: 40,
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'textfield!important',
    },
    '&::-ms-clear, ::-ms-reveal': {
      display: 'none',
    },
    '&::placeholder': {
      color: '#808080',
      textAlign: 'left',
    },
  },
  title: {
    //    minWidth: 185,
  },
  backgroundColor: {
    //    backgroundColor: '#3f71bd',
  },
  helperText: {
    fontSize: '11px',
    lineHeight: '1em',
    color: '#e53935',
    margin: '4px 14px',
  },
  backgroundColorDeleted: {
    //    backgroundColor: '#3f71bd',
  },
  grid: {
    //    width: '90%',
  },
  muiTextFieldStyleDeleted: {
    minWidth: 240,
    minHeight: 40,
    background: 'lightGrey !important',
    backgroundColor: 'lightGrey !important',
    color: 'black !important',
    opacity: '1 !important',
  },
}))
interface Errors {
  isError: boolean
  errorMsg: string
}
interface Props extends FieldProps {
  name?: string
  title: string
  item: string
  onChange?: (value: string) => void
  setHasError?: (isError: boolean) => void
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
}

const InputOnlyNumber = ({
  field,
  form,
  title,
  item,
  onChange,
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors } = form
  const [errorss, setError] = useState([] as Errors[])
  const [errorsList, setErrorList] = useState([] as String[])
  const [errorChecked, setErrorChecked] = useState([] as Errors[])
  const [errorCheckedList, setErrorCheckedList] = useState([] as String[])
  const [nameValue, setNameValue] = useState('')
  useEffect(() => {
    if (
      (prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') &&
      selectDoubleFalg == true
    ) {
      if (clearRecruitmentManageFlag) {
        setNameValue(form.values[name])
        setError([])
        setErrorList([])
        form.setFieldValue(name, '')
      } else {
        setError(errorChecked)
        setErrorList(errorCheckedList)
        form.setFieldValue(name, nameValue)
      }
    }
  }, [clearRecruitmentManageFlag])

  const handleChange = (event: any) => {
    const errorS: Errors[] = []
    form.setFieldValue(name, event.target.value)
    const pattDecimal = /^-?(0|[1-9]\d*)(\.\d+)?$/
    const pattFloat = /^-?(0|[1-9]\d*)(\.\d{1,2})?$/
    if (
      event.target.value.length > magiContants.MCAXS340_MAXLENGTH &&
      event.target.value > 0
    ) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxLength({
          max: magiContants.MCAXS340_MAXLENGTH,
        }),
      })
    }
    if (
      event.target.value.length > magiContants.MCAXS340_MAXLENGTH_NEGATIVE &&
      event.target.value < 0
    ) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxLength({
          max: magiContants.MCAXS340_MAXLENGTH,
        }),
      })
    }
    if (event.target.value > magiContants.MCAXS340_MAXVALUE) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.maxValue({
          max: magiContants.MCAXS340_MAXVALUE,
        }),
      })
    }
    if (event.target.value < magiContants.MCAXS340_MINVALUE) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.minValue({
          min: magiContants.MCAXS340_MINVALUE,
        }),
      })
    }
    if (!(event.target.value === '' || pattDecimal.test(event.target.value))) {
      errorS.push({
        isError: true,
        errorMsg: validationMessageSet.halfWidthNumber(),
      })
    } else if (
      !(event.target.value === '' || pattFloat.test(event.target.value))
    ) {
      errorS.push({
        isError: true,
        errorMsg: getMessage('MCAXS340-004'),
      })
    }
    if (errorS.length == 0) {
      if (onChange) {
        onChange(event.target.value)
      }
    }
    setError(errorS)
    setErrorChecked(errorS)
    const errorLabels = errorS.map(i => {
      return i.errorMsg
    })
    setErrorList(errorLabels)
    setErrorCheckedList(errorLabels)
  }

  return (
    <React.Fragment>
      <div
        className={
          prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030'
            ? selectDoubleFalg == false
              ? null != item &&
                null != value &&
                item.toString() === value.toString()
                ? undefined
                : classes.backgroundColor
              : null != item &&
                null != value &&
                item.toString() === value.toString() &&
                clearRecruitmentManageFlag == false
              ? classes.grid
              : classes.backgroundColorDeleted
            : null != item &&
              null != value &&
              item.toString() === value.toString()
            ? undefined
            : classes.backgroundColor
        }></div>
      <div className={'form__sttl_modal mb15'}>{title}</div>
      <div className={'form__body_item_column ml0'}>
        <MuiTextField
          disabled={clearRecruitmentManageFlag}
          variant='outlined'
          placeholder='半角数字'
          type='text'
          inputProps={{ spellCheck: false }}
          InputProps={{
            classes: clearRecruitmentManageFlag
              ? { input: classes.muiTextFieldStyleDeleted }
              : { input: classes.input },
          }}
          error={
            (errorss.length > 0 && errorss[0].isError) || Boolean(errors[name])
          }
          {...field}
          value={
            clearRecruitmentManageFlag ? '項目のデータを一括削除します' : value
          }
          onChange={handleChange}></MuiTextField>
        <span className='form__text_while'>（数値）</span>
      </div>

      {errors[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : errorsList ? (
        <>
          {errorsList.map(info => (
            <p className={classes.helperText}>{info}</p>
          ))}
        </>
      ) : null}
    </React.Fragment>
  )
}

export default InputOnlyNumber
