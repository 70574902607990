import moment from 'moment'
import momentTZ from 'moment-timezone'
import { routeList } from 'routes/routes'
import { PARENT_WINDOW_NAME } from 'utils/contants'
momentTZ.tz.setDefault('Asia/Tokyo')

// 件数バッチのフォーマット済文字列の取得
// バッチ表示用のフォーマット済み文字列を取得する。
export const badgeFormat = (count: number) => {
  return count.toLocaleString()
}

// 空チェック
// 与えられた文字列が空かどうか判定する。
export const isEmpty = (target: string | null) => {
  return target == null || target === '' ? true : false
}

// 現在時刻取得
// 現在の時刻を取得する。
export const now = () => {
  return new Date()
}

// 数値変換(number)
// 与えられた文字列をnumberに変換する。
export const stringToNumber = (target: string | null) => {
  return target && Number(target) ? Number(target) : null
}

// 日付変換(日付形式)
// 与えられた文字列を日付に変換する。
export const stringToDate = (target: string | null) => {
  return target && moment(target, 'YYYY/MM/DD').isValid()
    ? moment(target).toDate()
    : null
}

// 日付変換(日時形式)
// 与えられた文字列を日付に変換する。
export const stringToDateTime = (target: string) => {
  return target && moment(target, 'YYYY/MM/DD HH:mm').isValid()
    ? moment(target).toDate()
    : null
}

// 文字列変換(日付)
// 与えられた日付を文字列に変換する。
export const dateToString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('YYYY/MM/DD')
    : null
}

// 文字列変換(日時)
// 与えられた日付を文字列に変換する。
export const dateTimeToString = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('YYYY/MM/DD HH:mm')
    : null
}

// 最大25文字までとし、26文字目以降は三点リーダーとする。
export const stringToEllipsis = (target: String | null) => {
  if (target !== null && target.length > 25) {
    return target.slice(0, 25) + "..."
  } else {
    return target
  }
}

// バッジ数表示時format
export const badgeFormatComma = (count: Number) => {
  if (count > 9999) {
    return "9,999+"
  }
  if (count === 0) {
    return 0
  }
  let result = [], counter = 0;
  for (var i = count.toString().length - 1; i >= 0; i--) {
    counter++;
    result.unshift(count.toString()[i]);
    if ((counter % 3) == 0 && i != 0) {
      result.unshift(',');
    }
  }
  return result
}

// N日後の日付を取得します。
export const getNdaysLater = (day: string, dayCount: number) => {
  // 選択した行の「実行日時」のミリ秒数を取得します。
  const nowMilliseconds = moment(day).toDate().getTime()
  // N日後のミリ秒数を取得します。
  let laterMilliseconds = nowMilliseconds + dayCount * 24 * 60 * 60 * 1000;
  // N日後のミリ秒数により、日付時間を取得します。
  let laterDay = new Date(laterMilliseconds);
  return laterDay;
}
// N日後の日付を取得します。
export const getNhoursLater = (day: string, hourCount: number) => {
  // 選択した行の「実行日時」のミリ秒数を取得します。
  const nowMilliseconds = moment(day).toDate().getTime()
  // N日後のミリ秒数を取得します。
  let laterMilliseconds = nowMilliseconds + hourCount * 60 * 60 * 1000;
  // N日後のミリ秒数により、日付時間を取得します。
  let laterDay = new Date(laterMilliseconds);
  return laterDay;
}

// [day 23:59]の時間を取得します
export const getEndTime = (day: string) => {
  // 選択した行の「実行日時」のミリ秒数を取得します。
  const nowMilliseconds = moment(day).toDate().getTime()
  // N日後のミリ秒数を取得します。
  let laterMilliseconds = nowMilliseconds + 24 * 60 * 60 * 1000 - 1000;
  // N日後のミリ秒数により、日付時間を取得します。
  let laterDay = new Date(laterMilliseconds);
  return laterDay;
}

// 日付変換(年形式)
// 与えられた文字列を日付に変換する。
export const dateToStringYear = (target: Date | null) => {
  return target && moment(target).isValid()
    ? moment(target).format('YYYY')
    : null
}

export const onKeyDown = (keyEvent: any) => {
  if (
    (keyEvent.charCode || keyEvent.keyCode) === 13 &&
    keyEvent.target.type !== 'textarea'
  ) {
    keyEvent.preventDefault()
    return false
  }
}

// 当日からの日数の差分を数値で取得します。
export const getDiffFromToday = (target: Date) => {
  const currentDate = momentTZ(target).hour(0).minute(0).second(0).millisecond(0)
  const nowDate = momentTZ(now())
  nowDate.add(nowDate.utcOffset() + now().getTimezoneOffset(), 'minute')
  nowDate.hour(0).minute(0).second(0).millisecond(0)

  return currentDate.diff(nowDate, 'day')
}

// 与えられた日付からHH:mm形式に変換。
export const timeToString = (target: Date | null, shouldUseTimezone: boolean) => {
  if (shouldUseTimezone) {
    return target && momentTZ(target).isValid()
      ? momentTZ(target).format('HH:mm')
      : ''
  } else {
    return target && moment(target).isValid()
      ? moment(target).format('HH:mm')
      : ''
  }
}
const dateToDateString = (target: Date | null, shouldUseTimezone: boolean) => {
  if (shouldUseTimezone) {
    return target && momentTZ(target).isValid()
      ? momentTZ(target).format('YYYY/MM/DD')
      : null
  } else {
    return target && moment(target).isValid()
      ? moment(target).format('YYYY/MM/DD')
      : null
  }
}

// 与えられた日付から曜日に変換。
export const getDay = (value: Date, shouldUseTimezone: boolean) => {
  if (value) {
    const wDay = new Array(7)
    wDay[0] = '日'
    wDay[1] = '月'
    wDay[2] = '火'
    wDay[3] = '水'
    wDay[4] = '木'
    wDay[5] = '金'
    wDay[6] = '土'
    if (shouldUseTimezone) {
      return Number(momentTZ(value).format('d')) > -1 ? wDay[Number(momentTZ(value).format('d'))] : ''
    } else {
      return Number(moment(value).format('d')) > -1 ? wDay[Number(moment(value).format('d'))] : ''
    }
  }
  return ''
}

// 第1引数の値がnullの場合、第2引数の値を返却。
export const emptyDisp = (target: String | null, emptyWord: String | null) => {
  if (target == null || target == '') {
    if (emptyWord == null) {
      return ''
    } else {
      return emptyWord
    }
  } else {
    return target
  }
}

// フェーズ2改修 start
export const getWeekText = (eventDate: Date | null) => {
  if (eventDate) {
    const wDay = new Array(7)
    wDay[0] = '(日)'
    wDay[1] = '(月)'
    wDay[2] = '(火)'
    wDay[3] = '(水)'
    wDay[4] = '(木)'
    wDay[5] = '(金)'
    wDay[6] = '(土)'
    return Number(momentTZ(eventDate).format('d')) > -1 ? wDay[Number(momentTZ(eventDate).format('d'))] : ''
  }
  return ''
}
// フェーズ2改修 end

// #51347 次期開発2022年9月 start
export const openEntryManageTab = () => {
  if (window.opener) {
    const parentWindow = window.open('', PARENT_WINDOW_NAME)
    try {
      if (parentWindow && parentWindow.location.href !== routeList.entryList) {
        parentWindow.location.href = routeList.entryList
      } else {
        window.open(routeList.entryList)
      }
    } catch {
      window.open(routeList.entryList)
    }
  } else {
    window.open(routeList.entryList)
  }
}
// #51347 次期開発2022年9月 end