import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import {
  searchMessageDetail,
  searchMessageDetailInterview,
  setMessageDetail,
  attachmentFileDownload,
  csvFileDownload,
  memberStatusCheck,
  sysVersionNumberCheck,
  setNoPermissionOrDeletedFlag,
  setBulkReplyMessageCount,
  setBulkReplyMessageFlag,
  setMessageDetailParam
} from 'reducers/messageDetailReducer'
import {
  searchMessageDetailRequest,
  memberStatusCheckRequest,
  attachmentFileDownloadRequest,
  destinationDownloadRequest,
} from 'apis/MCAZS070Api'
import { sysVersionNumberCheckRequest } from 'apis/MCAZS150Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { routeList } from 'routes/routes'
import { magiContants } from 'utils/contants'
import { setDefFlag } from 'reducers/mediaReducer'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { checkVersionNumberRequest } from 'apis/MCBAS020Api'
import { openMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { isMobile } from 'react-device-detect'

function* searchMessageDetailSaga(
  action: ReturnType<typeof searchMessageDetail>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    const data = yield call(searchMessageDetailRequest, action.payload.request)
    yield put(setDefFlag(1))
    yield put(setMessageDetail(data))
    if (action.payload.flag === '0') {
      const path = {
        pathname: routeList.messageDetail,
        state: action.payload,
      }
      localStorage.setItem('MessageDetail', JSON.stringify(action.payload))
      localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
      //別タブで開く
      const params = new URLSearchParams(
        action.payload.request as {}
      ).toString()

      if (!isMobile) {
        const w = window.open('', '_blank')
        if (w) {
          w.location.href = routeList.messageDetail + '?' + params
        }
      } else {
        yield put(setMessageDetailParam(params))
/*         var a = document.createElement('a')
        a.href = routeList.messageDetail + '?' + params
        a.target = '_blank'
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
 */      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* searchMessageDetailInterviewSaga(
  action: ReturnType<typeof searchMessageDetailInterview>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // 面接調整状態整合性チェック
    yield call(checkVersionNumberRequest, action.payload.request020)
    const data = yield call(searchMessageDetailRequest, action.payload.request)
    yield put(setDefFlag(1))
    yield put(setMessageDetail(data))
    if (action.payload.flag === '0') {
      const path = {
        pathname: routeList.messageDetail,
        state: action.payload,
      }
      localStorage.setItem('MessageDetail', JSON.stringify(action.payload))
      localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
      //別タブで開く
      const params = new URLSearchParams(
        action.payload.request as {}
      ).toString()

      // const w = window.open(routeList.messageDetail + '?' + params, '_blank')
      if (!isMobile) {
        const w = window.open('', '_blank')
        if (w) {
          w.location.href = routeList.messageDetail + '?' + params
        }
      } else {
        yield put(setMessageDetailParam(params))
        // var a = document.createElement('a')
        // a.href = routeList.messageDetail + '?' + params
        // a.target = '_blank'
        // a.style.display = 'none'
        // document.body.appendChild(a)
        // a.click()
        // document.body.removeChild(a)
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* attachmentFileDownloadSaga(
  action: ReturnType<typeof attachmentFileDownload>
) {
  try {
    const data = yield call(attachmentFileDownloadRequest, action.payload)
    var blob = new Blob([data.data])

    if (
      'application/json' == data.data.type ||
      'application/json;charset=UTF-8' == data.data.type
    ) {
      yield put(openModal(magiContants.MCAZS070_004))
    } else {
      if (navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, action.payload.fileName)
      } else {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = action.payload.fileName
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* csvFileDownloadSaga(action: ReturnType<typeof csvFileDownload>) {
  try {
    const data = yield call(destinationDownloadRequest, action.payload)
    var blob = new Blob([data.data])
    const date = new Date()
    var curr_date = date.getDate() + ''
    var curr_month = date.getMonth() + 1 + ''
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + ''
    var curr_minutue = date.getMinutes() + ''
    curr_month = curr_month.length < 2 ? '0' + curr_month : curr_month
    curr_date = curr_date.length < 2 ? '0' + curr_date : curr_date
    curr_hour = curr_hour.length < 2 ? '0' + curr_hour : curr_hour
    curr_minutue = curr_minutue.length < 2 ? '0' + curr_minutue : curr_minutue
    const yymmdd =
      curr_year + curr_month + curr_date + '_' + curr_hour + curr_minutue
    const fileName =
      (action.payload.recruitmentManagementDivision == '1'
        ? 'syusyoku_job_seeker_list_'
        : 'tensyoku_job_seeker_list_') +
      action.payload.messageSendId +
      '_' +
      yymmdd +
      '.csv'

    if (
      'application/json' == data.data.type ||
      'application/json;charset=UTF-8' == data.data.type
    ) {
      yield put(openModal(magiContants.MCAZS070_005))
    } else {
      if (navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 返信チェック
function* memberStatusCheckSaga(action: ReturnType<typeof memberStatusCheck>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // 次期開発12月 #72025 start
    const data: {
      replyMessageCount: number
      checkMessage: string
    } = yield call(memberStatusCheckRequest, action.payload.requestParam)
    // 転職、かつ一括送信のフラグ
    let tennsyokuAndBulkSendFlag = false
    if (
      magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 ===
      globalMenuInfo.recruitmentManagementDivision &&
      '1' === action.payload.bulkSendFlag
    ) {
      tennsyokuAndBulkSendFlag = true
      // 「一括返信チェック」の返信件数を設定
      yield put(setBulkReplyMessageCount(data.replyMessageCount))
    }
    if (
      tennsyokuAndBulkSendFlag &&
      magiContants.MCAZS070_007 === data.checkMessage
    ) {
      // 「閲覧権限、削除済応募者チェック」アラートを開く
      yield put(setNoPermissionOrDeletedFlag(true))
    } else if (tennsyokuAndBulkSendFlag && data.replyMessageCount > 1) {
      // 「一括返信チェック」アラートを開く
      yield put(setBulkReplyMessageFlag(true))
    } else {
      const messageInputRequest = {
        replyMessageSendId: action.payload.requestParam.messageSendId,
      }
      localStorage.setItem(
        'messageInputRequest',
        JSON.stringify(messageInputRequest)
      )
      localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
      // メッセージ入力画面オープン
      yield put(openMessageInputDialog())
    }
    // 次期開発12月 #72025 end
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* sysVersionNumberCheckSaga(
  action: ReturnType<typeof sysVersionNumberCheck>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    yield call(sysVersionNumberCheckRequest, action.payload)
    const messageInputRequest = {
      interviewScheduleAdjustmentId:
        action.payload.interviewScheduleAdjustmentId,
      interviewScheduleId: [action.payload.interviewScheduleId],
      action: action.payload.action,
      sysVersionNumber: action.payload.sysVersionNumber,
    }
    localStorage.setItem(
      'messageInputRequest',
      JSON.stringify(messageInputRequest)
    )
    localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* updateSaga() {
  yield all([
    takeEvery(searchMessageDetail, searchMessageDetailSaga),
    takeEvery(searchMessageDetailInterview, searchMessageDetailInterviewSaga),
    takeEvery(attachmentFileDownload, attachmentFileDownloadSaga),
    takeEvery(csvFileDownload, csvFileDownloadSaga),
    takeEvery(memberStatusCheck, memberStatusCheckSaga),
    takeEvery(sysVersionNumberCheck, sysVersionNumberCheckSaga),
  ])
}
