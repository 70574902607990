import React from 'react'
import { Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { SEARCH_BUTTON_ID } from 'utils/contants'
import DisplayLimitSelect from 'sp/componentsHsc/OverlaySearch/DisplayLimitSelect'
import { DisplayLimitOptionInfo, Option } from 'pages/MCAXS010/formConfig'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

const useStyles = makeStyles({
  root: {
    fontSize: '2.1rem !important',
    height: '64px',
    width: '362px',
  },
  hidenButton: {
    display: 'none',
  },
  displaylimitSelect: {
    width: '200px',
    padding: '2px',
    borderRadius: '3px',
    fontSize: '14px',
  },
  centeredButton: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-60%)',
  },
  displaylimitposition: {
    position: 'absolute',
    left: '50%',
    marginLeft: '160px',
  },
  displayLimitHidden: {
    display: 'none',
  },
})

function onKeyDown(keyEvent: any) {
  if (
    (keyEvent.charCode || keyEvent.keyCode) === 13 &&
    keyEvent.target.type !== 'textarea'
  ) {
    keyEvent.preventDefault()
    return false
  }
}

interface Props {
  onSubmitButtonClick: (type: string) => void
  enableDisplayLimit?: boolean
  displayLimit?: Option
  displayLimitOption?: DisplayLimitOptionInfo[]
  serviceMode?: string
}

const TabItem: React.FC<Props> = ({
  children,
  onSubmitButtonClick,
  enableDisplayLimit = false,
  displayLimit = { label: '', value: '' },
  displayLimitOption = [],
  serviceMode = '0',
}) => {
  const classes = useStyles()
  const { open } = useSelector((state: RootState) => state.advancedSearch)

  return (
    <Form onKeyDown={onKeyDown}>
      {children}
      <div className='detail-search__btncontainer'>
        <Box>
          <button
            id={SEARCH_BUTTON_ID}
            type='submit'
            className='btn__search detail-display-search'
            onClick={() => onSubmitButtonClick('search')}>
            検索
          </button>
        </Box>
        {enableDisplayLimit && (
          <React.Fragment>
            <div
              className={`${classes.displaylimitposition} ${
                open ? '' : classes.displayLimitHidden
              }`}>
              <Box p={1} zIndex={2}>
                <DisplayLimitSelect
                  className={classes.displaylimitSelect}
                  displayLimit={displayLimit}
                  displayLimitOption={displayLimitOption}
                />
              </Box>
            </div>
          </React.Fragment>
        )}
        <div>
          <Box>
            {serviceMode !== '1' && (
              <button
                type='submit'
                className='btn__mySearch modal-MCARS030'
                onClick={() => onSubmitButtonClick('save')}>
                MY検索に保存
              </button>
            )}
            <button
              id='searhConditionReset'
              type='reset'
              className={classes.hidenButton}
            />
          </Box>
        </div>
      </div>
    </Form>
  )
}

export default TabItem
