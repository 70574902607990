import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import {
  excludedata,
  restoredata,
  deletedata,
  outputPdfdata,
  mailSharedata,
  sendMessagedata,
  outputCsvdata,
  init,
  setState,
  setMCAXS111Open,
  mcbCheck,
  outputMcbCsvData,
  outputMcbPdfData,
  setSubmitCheckDialog,
  sendMcbRequestMessageData,
  otherOperationOfMcbData,
  submissionRequestInfo,
  setSubmissionRequestInfo,
} from 'reducers/changeOthersReducer'
import {
  openSnackbar,
  openModal,
  openModalWithArg,
} from 'reducers/messageReducer'
import { openModel, openDialog } from 'reducers/changeOthersReducer'
import {
  excludeApi,
  restoreApi,
  deleteApi,
  outputPdfApi,
  mailShareApi,
  sendMessageApi,
  outputCsvApi,
  initApi,
  mcbCheckApi,
  outputMcbCsvApi,
  outputMcbPdfApi,
  sendMcbRequestMessageApi,
  otherOperationOfMcbApi,
} from 'apis/MCAXS260Api'
import {
  getRecruitmentManagementDivision,
  getGlobalMenuData,
} from 'selectors/authSelectors'
import { MCAXS260TargetIdRequest } from 'types/MCAXS260/MCAXS260TargetIdRequest'
import { getMessage } from 'common/messageUtil'
import { getInitApi } from 'apis/MCAXS111Api'
import {
  setInitValues,
  setTemplateInfoList,
  setSelectionFlowList,
  setSelectionFlag,
  setSelectedTemplateInfo,
  TemplateInfo,
  SelectionFlowInfo,
} from 'reducers/csvOutputReducer'
import { initialValues } from 'pages/MCAXS111/formConfig'
import { magiContants } from 'utils/contants'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { PayloadAction } from '@reduxjs/toolkit'
import MCBCS010DuplicateDataRequest from 'types/MCBCS010/MCBCS010DuplicateDataRequest'
import { getMyCareerBoxInfo } from 'reducers/myCareerBoxDataLinkReducer'
import {
  initScreenTransitionInput,
  RequestObj,
} from 'pages/MCBHS040/formConfig'
import { setMCBHS190OpenFlg } from 'reducers/mcbPdfDownloadReducer'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'
import { openMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { isMobile } from "react-device-detect";

function* initSaga(action: ReturnType<typeof init>) {
  try {
    const data = yield call(initApi)
    yield put(setState(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* excludeSaga(action: ReturnType<typeof excludedata>) {
  try {
    // 年度対応　MCAXS260 start
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(excludeApi, {
      ...action.payload.request,
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    // 年度対応　MCAXS260 end
    yield put(openSnackbar(data))
    if (action.payload.onclose !== undefined) {
      action.payload.onclose()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* restoreSaga(action: ReturnType<typeof restoredata>) {
  try {
    // 年度対応　MCAXS260 start
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const data = yield call(restoreApi, {
      ...action.payload.request,
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    // 年度対応　MCAXS260 end
    yield put(openSnackbar(data))
    if (action.payload.onclose !== undefined) {
      action.payload.onclose()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* deleteSaga(action: ReturnType<typeof deletedata>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
    getRecruitmentManagementDivision
  )
  try {
    // [phase2] start by zhangxp
    const data = yield call(deleteApi, {
      ...action.payload.request,
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    yield put(openSnackbar(data))
    if (action.payload.onclose !== undefined) {
      action.payload.onclose()
    }
    // [phase2] end by zhangxp
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* outputPdfSaga(action: ReturnType<typeof outputPdfdata>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
    getRecruitmentManagementDivision
  )
  try {
    const data = yield call(outputPdfApi, {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    window.localStorage.setItem(
      'pdfParam',
      JSON.stringify({
        jobSeekerId: action.payload.jobSeekerIdList,
        entryId: action.payload.entryIdList,
        pdfType: action.payload.pdfType,
        prevScreenId: 'MCAXS260',
      })
    )
    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    // PDF作成中へ遷移する
    window.open(
      routeList.pdfGenerating,
      '_blank',
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=650px,height=600px'
    )
  } catch (error) {
    // 転職March #9479 #75621 START
    if (error.message) {
      const messResult: Array<string> = error.message.split(',')
      if (messResult.length == 1) {
        yield put(openModal(messResult[0]))
      } else {
        const [messageId, ...args] = messResult
        yield put(openModalWithArg({ messageId, args }))
      }
    }
    // 転職March #9479 #75621 END
  }
}

function* mailShareSaga(action: ReturnType<typeof mailSharedata>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
    getRecruitmentManagementDivision
  )
  try {
    const data: string = yield call(mailShareApi, {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    })
    if (isMobile) {
      document.location.href = data.replace(/\?selection_management_id=/g, '%3fselection_management_id%3d')
    } else {
      document.location.href = data
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* sendMessageSaga(action: ReturnType<typeof sendMessagedata>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    const reqeustParam: MCAXS260TargetIdRequest = {
      jobSeekerIdList: action.payload.jobSeekerIdList,
      entryIdList: action.payload.entryIdList,
      selectionManagementIdList: action.payload.selectionManagementIdList,
      recruitmentManagementDivision:
        action.payload.recruitmentManagementDivision,
    }

    yield call(sendMessageApi, reqeustParam)
    // メッセージ入力へ遷移する
    const requestParam = {
      jobSeekerId: action.payload.jobSeekerIdList,
      entryId: action.payload.entryIdList,
      selectionManagementId: action.payload.selectionManagementIdList,
      searchCriteria: action.payload.searchCriteria,
      searchCriteriaName: action.payload.searchCriteriaName,
      filterConditionTitle: action.payload.filterConditionTitle,
      filterConditionValue: action.payload.filterConditionValue,
      // 次期開発9月 #58497 start
      mySearchCondition: action.payload.mySearchCondition,
      mySearch: action.payload.mySearch,
      // 次期開発9月 #58497 end
    }
    localStorage.setItem('messageInputRequest', JSON.stringify(requestParam))
    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
  } catch (error) {
    yield put(openDialog({ openCheckModal: true, modalMessage: error.message }))
  }
}
function* outputCsvSaga(action: ReturnType<typeof outputCsvdata>) {
  try {
    yield call(outputCsvApi, action.payload)
    // MCBリプレース Lot3 start
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )
    const param: MCAXS111initRequest = {
      jobSeekerIdList: action.payload.jobSeekerIdList,
      entryIdList: action.payload.entryIdList,
      selectionManagementIdList: action.payload.selectionManagementIdList,
      selectionFlowSettingIdList: action.payload.selectionFlowSettingIdList,
      requestList: [],
      callDivision: action.payload.callDivision,
    }
    const data: {
      templateInfo: TemplateInfo[]
      selectionFlowInfo: SelectionFlowInfo[]
    } = yield call(getInitApi, recruitmentManagementDivision, param)
    // MCBリプレース Lot3 end
    let tmpItem: any = {}
    if (data.templateInfo && data.templateInfo.length > 0) {
      tmpItem = data.templateInfo.find(
        (i: any) => i.defaultTemplateFlag === magiContants.STRING_1
      )
      if (tmpItem) {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
            templateItemList: tmpItem.templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
          })
        )
        if (tmpItem.selectionFlowNecessaryFlag === magiContants.STRING_1) {
          yield put(setSelectionFlag(true))
        }
      } else {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
            templateItemList: data.templateInfo[0].templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
          })
        )
        if (
          data.templateInfo[0].selectionFlowNecessaryFlag ===
          magiContants.STRING_1
        ) {
          yield put(setSelectionFlag(true))
        }
      }
    }
    yield put(setTemplateInfoList(data.templateInfo))
    yield put(setSelectionFlowList(data.selectionFlowInfo))
    yield put(setMCAXS111Open(true))
    // MCAXS111 end
  } catch (error) {
    // 転職March #9479 #75621 START
    if (error.message) {
      const messResult: Array<string> = error.message.split(',')
      if (messResult.length == 1) {
        yield put(openModal(messResult[0]))
      } else {
        const [messageId, ...args] = messResult
        yield put(openModalWithArg({ messageId, args }))
      }
    }
    // 転職March #9479 #75621 END
  }
}

// [phase2] start by zhangxp
function* mcbCheckSaga(action: PayloadAction<MCBCS010DuplicateDataRequest>) {
  try {
    yield call(mcbCheckApi)
    yield put(getMyCareerBoxInfo(action.payload))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [phase2] end by zhangxp

// MCBリプレース MCAXS260 start
// 「提出データのCSV出力」ボタンを押下
function* outputMcbCsvSaga(action: ReturnType<typeof outputMcbCsvData>) {
  try {
    const submissionRequestInfo: submissionRequestInfo[] = yield call(
      outputMcbCsvApi,
      action.payload
    )
    yield put(setSubmissionRequestInfo(submissionRequestInfo))

    // CSVテンプレート選択画面を開く、既存ソースの流用
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    // MCBリプレース Lot3 start
    const param: MCAXS111initRequest = {
      jobSeekerIdList: action.payload.jobSeekerIdList,
      entryIdList: action.payload.entryIdList,
      selectionManagementIdList: action.payload.selectionManagementIdList,
      selectionFlowSettingIdList: action.payload.selectionFlowSettingIdList,
      requestList: [],
      callDivision: action.payload.callDivision,
    }
    const data: {
      templateInfo: TemplateInfo[]
      selectionFlowInfo: SelectionFlowInfo[]
    } = yield call(getInitApi, recruitmentManagementDivision, param)
    // MCBリプレース Lot3 end
    let tmpItem: any = {}
    if (data.templateInfo && data.templateInfo.length > 0) {
      tmpItem = data.templateInfo.find(
        (i: any) => i.defaultTemplateFlag === magiContants.STRING_1
      )
      if (tmpItem) {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
            templateItemList: tmpItem.templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId: tmpItem.entryDataIoTemplateSettingId,
            templateName: tmpItem.templateName,
          })
        )
        if (tmpItem.selectionFlowNecessaryFlag === magiContants.STRING_1) {
          yield put(setSelectionFlag(true))
        }
      } else {
        yield put(
          setInitValues({
            ...initialValues,
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
            templateItemList: data.templateInfo[0].templateItemList,
          })
        )
        yield put(
          setSelectedTemplateInfo({
            entryDataIoTemplateSettingId:
              data.templateInfo[0].entryDataIoTemplateSettingId,
            templateName: data.templateInfo[0].templateName,
          })
        )
        if (
          data.templateInfo[0].selectionFlowNecessaryFlag ===
          magiContants.STRING_1
        ) {
          yield put(setSelectionFlag(true))
        }
      }
    }
    yield put(setTemplateInfoList(data.templateInfo))
    yield put(setSelectionFlowList(data.selectionFlowInfo))
    yield put(setMCAXS111Open(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「提出データのPDF出力」ボタンを押下
function* outputMcbPdfSaga(action: ReturnType<typeof outputMcbPdfData>) {
  try {
    const data: {
      submissionRequestInfo: submissionRequestInfo[]
      checkMessage: string
    } = yield call(outputMcbPdfApi, action.payload)
    // 提出リクエスト情報を設定する
    yield put(setSubmissionRequestInfo(data.submissionRequestInfo))
    // 「未提出有チェック」があるの場合、警告アラートを表示する
    if (magiContants.MESSAGECODE_MCAXS260_041 === data.checkMessage) {
      yield put(setSubmitCheckDialog(true))
    } else {
      //「未提出有チェック」がないの場合、PDF出力対象選択（My CareerBox）画面を開く
      yield put(setMCBHS190OpenFlg(true))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「提出リクエスト」ボタンを押下
function* sendMcbRequestMessageSaga(
  action: ReturnType<typeof sendMcbRequestMessageData>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    const jobSeekerIdList = {
      jobSeekerIdList: action.payload.jobSeekerIdList,
    }
    const data: submissionRequestInfo[] = yield call(
      sendMcbRequestMessageApi,
      action.payload
    )
    const mcbMessageInputRequest = {
      jobSeekerByCompanyIdList: data.map(item => item.jobSeekerByCompanyId),
      searchCriteriaName: action.payload.searchCriteriaName,
      searchCriteriaText: action.payload.searchCriteriaText,
      filterTitle: action.payload.filterTitle,
      filterNum: action.payload.filterNum,
      jobSeekerIdList: action.payload.jobSeekerIdList,
      entryIdList: action.payload.entryIdList,
      selectionManagementIdList: action.payload.selectionManagementIdList,
    }
    window.localStorage.setItem(
      'mcbMessageInputRequest',
      JSON.stringify(mcbMessageInputRequest)
    )
    window.localStorage.setItem(
      'globalMenuInfo',
      JSON.stringify(globalMenuInfo)
    )
    // 提出リクエスト入力画面へ遷移する
    // TODO
    // alert("提出リクエスト入力画面を開く")
    window.open(
      routeList.submissionRequestSend,
      '_blank',
      'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' +
      1280 +
      ',height=' +
      948 +
      ',left=' +
      10 +
      ',top=' +
      10 +
      ''
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「メッセージ・締切変更・強制停止」ボタンを押下
function* otherOperationOfMcbSaga(
  action: ReturnType<typeof otherOperationOfMcbData>
) {
  try {
    const submissionRequestInfo: submissionRequestInfo[] = yield call(
      otherOperationOfMcbApi,
      action.payload
    )
    let requestObjList: RequestObj[] = submissionRequestInfo.map(item => ({
      jobSeekerByCompanyId: item.jobSeekerByCompanyId,
      requestId: '',
    }))

    const param = {
      request: {
        ...initScreenTransitionInput,
        screenId: action.payload.sourceFunctionId,
        requestList: requestObjList,
      },
    }
    const path = {
      // 「提出リクエスト一覧」画面に遷移する
      pathname: routeList.submissionRequestManagement,
      state: param,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBリプレース MCAXS260 end

export default function* MCAXS260Saga() {
  yield all([
    takeEvery(init, initSaga),
    takeEvery(excludedata, excludeSaga),
    takeEvery(restoredata, restoreSaga),
    takeEvery(deletedata, deleteSaga),
    takeEvery(outputPdfdata, outputPdfSaga),
    takeEvery(mailSharedata, mailShareSaga),
    takeEvery(sendMessagedata, sendMessageSaga),
    takeEvery(outputCsvdata, outputCsvSaga),
    takeEvery(mcbCheck, mcbCheckSaga),
    // MCBリプレース MCAXS260 start
    takeEvery(outputMcbCsvData, outputMcbCsvSaga),
    takeEvery(outputMcbPdfData, outputMcbPdfSaga),
    takeEvery(sendMcbRequestMessageData, sendMcbRequestMessageSaga),
    takeEvery(otherOperationOfMcbData, otherOperationOfMcbSaga),
    // MCBリプレース MCAXS260 end
  ])
}
