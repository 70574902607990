import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { makeStyles } from '@material-ui/core/styles'
import DatePicker from 'react-datepicker'
import { FastField } from 'formik'
import {
  addInterviewLocation,
  copyCandidate,
  inputInterviewDetail,
  inputInterviewDate,
  inputInterviewDuration,
  inputInterviewStaff,
  updateCandidate,
  inputManipulationSelection,
  changeReplyAllow,
  clearCandidate,
} from 'reducers/messageSendReducer'
import { SendTarget } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { CandidateDate, InterviewLocation } from 'pages/MCAZS080/viewConig'
import { magiContants } from 'utils/contants'
import { Area } from 'utils/misc'
import { dateTimeToString, isEmpty, stringToDateTime } from 'common/generalUtil'
import MessageInputNoneCounter from './messageInputNoneCounter'
import Calendar, { Reservation, CandidateDay } from './Calendar'
import CandidateDateGroup from './CandidateDateGroup'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip as MuiTooptip,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { withStyles } from '@material-ui/styles'
import { formatTemplate, unitSet } from 'utils/contants'
import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  calendar: {
    fontSize: '12px',
    '& .fc-center div': {
      display: 'flex',
      '& button': {
        margin: theme.spacing(0, 2),
      },
    },
    '& th.fc-disabled-day': {
      backgroundColor: '#e0e0e0',
      opacity: 1,
    },
    '& .fc-bgevent': {
      backgroundColor: '#c4dae8',
      opacity: 1,
    },
    '& .fc-event': {
      backgroundColor: '#c4dae8',
      opacity: 1,
      border: 'none',
      borderRadius: 0,
      marginRight: '0 !important',
    },
    '& .fc-disallow': {
      backgroundColor: '#e6e6e6',
      pointerEvents: 'none',
    },
    '& .fc-content .fc-time': {
      display: 'none',
    },
    '& .fc-time-grid-event': {
      marginBottom: '0 !important',
      left: '0 !important',
    },
    '& .fc-time-grid-event-inset': {
      boxShadow: 'none',
    },
    '& .fc-ltr .fc-time-grid .fc-event-container': {
      margin: 0,
    },
    '& .fc-day-header span': {
      display: 'block',
    },
  },
  addGroup: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
  required: {
    flexBasis: '150px !important',
    '&:after': {
      content: '"必須"',
      backgroundColor: '#c93832',
      borderRadius: '12px',
      color: '#fff',
      display: 'inline-block',
      lineHeight: '24px',
      marginLeft: '8px',
      textAlign: 'center',
      width: '52px',
    },
    '& + div': {
      flexBasis: 'calc(100% - 150px) !important',
    },
  },
  interviewDetail: {
    maxWidth: '210px',
  },
  interviewDetailWrapper: {
    display: 'flex',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '5px',
    '& > h1': {
      position: 'relative',
      transform: 'translate(0,.25em)',
      flexBasis: '100px',
    },
    '& > h1::before': {
      backgroundColor: '#8593a6',
      borderRadius: '50%',
      content: '',
      display: 'block',
      height: '24px',
      left: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-54px ,-50%)',
      width: '24px',
    },
    '& > h1 + div': {
      flexBasis: 'calc(100% - 100px)',
    },
  },
  warning: {
    color: '#e50000',
    marginLeft: '1em',
  },
  select: {
    marginTop: '8px',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  Button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioGroup: {
    display: 'inlineBlock',
  },
  btnCalendarAdd: {
    backgroundColor: '#e0e0e0',
    borderColor: '#ccc',
    boxShadow: 'none',
    color: '#999',
    pointerEvents: 'none',
  },
}))

interface Props {
  allowedArea?: Area[]
  notAllowedArea?: Area[]
  reservationList?: Reservation[]
  setIsInitialized: (state: boolean) => void
  isInitialized: boolean
  isPermitOther?: boolean
  selectedInterviewLocationId: string
  selectInterviewLocationId: string
  selectInterviewLocationName: string
  candidateListId: number
  selectInterviewLocationAddress: string
  selectInterviewLocationMapUrl: string
  updateFormModal: boolean
  setCandidateListId: (state: number) => void
  setUpdateFormModal: (state: boolean) => void
  setSelectInterviewLocationId: (state: string) => void
  setSelectInterviewLocationName: (state: string) => void
  setSelectInterviewLocationAddress: (state: string) => void
  setSelectInterviewLocationMapUrl: (state: string) => void
  setSelectedInterviewLocationId: (state: string) => void
  sendTarget: SendTarget[]
  existsDesiredDate: boolean
  handleToolTipInterviewDetail: (state: boolean) => void
  handleToolTipCalenderChoose: (state: boolean) => void
  handleToolTipReserved: (state: boolean) => void
  setCurrentReservationList: (state: Reservation[]) => void
  currentReservationList: Reservation[]
  toolTipReserved: boolean
  value: number
  setValue: (state: number) => void
  temporaryValue: number
}

const dayRange = 90

const TabItem = ({
  allowedArea = [],
  notAllowedArea = [],
  reservationList = [],
  setIsInitialized,
  isInitialized,
  isPermitOther = false,
  selectInterviewLocationId = '',
  candidateListId = 0,
  selectInterviewLocationAddress = '',
  selectInterviewLocationName = '',
  selectInterviewLocationMapUrl = '',
  setUpdateFormModal,
  setSelectInterviewLocationId,
  setCandidateListId,
  setSelectedInterviewLocationId,
  sendTarget,
  existsDesiredDate,
  handleToolTipInterviewDetail,
  handleToolTipCalenderChoose,
  handleToolTipReserved,
  setCurrentReservationList,
  currentReservationList,
  toolTipReserved,
  value,
  setValue,
  temporaryValue,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    messageInitRequest,
    screenDisplayItems,
    initResult,
    draftRequest,
  } = useSelector((state: RootState) => state.messageSend)

  const { draftMessageSendId } = messageInitRequest
  const { interviewSchedule } = screenDisplayItems
  const { mapShowURL } = initResult
  const { interviewDurationId } = draftRequest

  const {
    interviewDurationSelect,
    candidateDate,
    manipulationSelection,
    interviewLocation,
  } = interviewSchedule

  let lengthLimit = 30
  if (
    manipulationSelection === magiContants.SELECTION_NUMBER_SEND ||
    manipulationSelection === magiContants.SELECTION_NUMBER_FIX
  ) {
    lengthLimit = 1
  }

  const [selectedDurationId, setSelectedDurationId] = useState(
    interviewSchedule.requiredTimeId === 'null'
      ? ''
      : interviewSchedule.requiredTimeId
  )
  const [state, setState] = useState([] as CandidateDay[])
  const [actualTime, setActualTime] = useState(
    String(magiContants.DURATION_ACTUAL_TIME_MINUTE_OTHER)
  )

  useEffect(() => {
    if (selectInterviewLocationId && !isEmpty(selectInterviewLocationName)) {
      const exists = interviewLocation.some(
        item => String(item.id) === selectInterviewLocationId
      )
      if (!exists) {
        let interviewLocation: InterviewLocation = {
          id: Number(selectInterviewLocationId),
          name: selectInterviewLocationName,
          address: selectInterviewLocationAddress,
          mapUrl: !isEmpty(selectInterviewLocationMapUrl)
            ? mapShowURL +
            '?BT2=' +
            selectInterviewLocationAddress +
            selectInterviewLocationMapUrl
            : '',
          type: '',
          deleteFlag: false,
        }
        dispatch(addInterviewLocation({ interviewLocation }))
      }
    }
  }, [selectInterviewLocationName])

  useEffect(() => {
    if (candidateDate && candidateDate.length > 0) {
      if (!isInitialized && draftMessageSendId) {
        let candidateDayList: CandidateDay[] = []
        candidateDate.map(item => {
          if (!isEmpty(item.interviewStartTime)) {
            let currentLocation = interviewLocation[0]
            if (interviewLocation && interviewLocation.length > 0) {
              interviewLocation.map(location => {
                if (String(location.id) === item.interviewLocationId) {
                  currentLocation = location
                }
              })
            }
            let candidateDay: CandidateDay = {
              interviewDate: stringToDateTime(item.interviewStartTime),
              interviewLocation: currentLocation ? currentLocation.name : '',
              interviewLocationId: currentLocation
                ? String(currentLocation.id)
                : '',
              charge: item.interviewStaffName,
              datePickerRef: React.createRef(),
              containerRef: React.createRef(),
              fromCalendar: false,
            }
            candidateDayList = [...candidateDayList, candidateDay]
          }
        })
        setState(candidateDayList)
        setIsInitialized(true)
        setSelectedDurationId(interviewSchedule.requiredTimeId)
        dispatch(
          inputInterviewDuration({ value: interviewSchedule.requiredTimeId })
        )
        const currentDuration = interviewDurationSelect.find(
          item => item.interviewDurationId === interviewSchedule.requiredTimeId
        )
        const actualTime = currentDuration
          ? currentDuration.actualTime
          : String(magiContants.DURATION_ACTUAL_TIME_MINUTE_OTHER)
        setActualTime(actualTime)
      }
    }
  }, [candidateDate])

  useEffect(() => {
    if (state != null) {
      let candidateList: CandidateDate[] = []
      state.map(item => {
        let candidate: CandidateDate = {
          interviewLocationId: '',
          interviewStaffName: '',
          interviewStartTime: '',
        }
        candidate.interviewLocationId = item.interviewLocationId
        candidate.interviewStaffName = item.charge
        let stringInterviewDate: any = ''
        if (item.interviewDate !== null) {
          stringInterviewDate = dateTimeToString(item.interviewDate)
        }
        candidate.interviewStartTime = stringInterviewDate
        candidateList = [...candidateList, candidate]
      })
      dispatch(updateCandidate({ value: candidateList }))
      if (isEmpty(selectedDurationId)) {
        setSelectedDurationId(interviewDurationSelect[0].interviewDurationId)
        dispatch(
          inputInterviewDuration({
            value: interviewDurationSelect[0].interviewDurationId,
          })
        )
      }
    }
  }, [state])

  useEffect(() => {
    if (state != null) {
      if (state.length > 0) {
        if (selectInterviewLocationId) {
          let candidateDayList: CandidateDay[] = [...state]
          let candidateDay: CandidateDay = candidateDayList[candidateListId]
          candidateDay.interviewLocationId = selectInterviewLocationId
          candidateDay.interviewLocation = selectInterviewLocationName
          candidateDayList[candidateListId] = candidateDay
          setState(candidateDayList)
          setSelectInterviewLocationId('')
        }
      }
    }
  }, [selectInterviewLocationId])

  const handleInputInterviewDetail = (value: string) => {
    dispatch(inputInterviewDetail({ value }))
  }

  const handleChangeDuration = (value: string) => {
    setSelectedDurationId(value)
    dispatch(inputInterviewDuration({ value }))
  }

  const handleAdd = () => {
    setState([
      ...state,
      {
        interviewDate: null,
        interviewLocation:
          interviewLocation && interviewLocation.length > 0
            ? interviewLocation[0].name
            : '',
        interviewLocationId:
          interviewLocation && interviewLocation.length > 0
            ? String(interviewLocation[0].id)
            : '',
        charge: '',
        datePickerRef: React.createRef(),
        containerRef: React.createRef(),
      },
    ])
  }

  const handleOpenModal = (
    selectedInterviewLocationId: string,
    candidateListId: number
  ) => {
    setSelectedInterviewLocationId(selectedInterviewLocationId)
    setCandidateListId(candidateListId)
    setUpdateFormModal(true)
  }

  const handleDelete = (itemIndex: number) => () => {
    setState(state.filter((_, index) => index !== itemIndex))
  }

  const handleCopy = (item: CandidateDay, index: number) => () => {
    let sourceCandidateDayList: CandidateDay[] = [...state]
    let targetCandidateDayList: CandidateDay[] = []

    for (var i = 0; i < state.length; i++) {
      if (i == index) {
        targetCandidateDayList[i] = sourceCandidateDayList[i]
        targetCandidateDayList[i + 1] = sourceCandidateDayList[i]
      } else if (i < index) {
        targetCandidateDayList[i] = sourceCandidateDayList[i]
      } else {
        targetCandidateDayList[i + 1] = sourceCandidateDayList[i]
      }
    }

    setState(targetCandidateDayList)

    const sourceIndex: number = index
    const targetIndex: number = sourceIndex + 1
    dispatch(copyCandidate({ sourceIndex, targetIndex }))
  }

  const handleChargeChange = (itemIndex: number) => (e: any) => {
    setState(
      state.map((item, index) =>
        index === itemIndex && e.target.value.length <= 15
          ? {
            ...item,
            charge: e.target.value,
          }
          : item
      )
    )
    dispatch(inputInterviewStaff({ index: itemIndex, value: e.target.value }))
  }

  const handleDateChange = (
    itemIndex: number,
    ref: React.RefObject<DatePicker>
  ) => (date: Date | null) => {
    setState(
      state.map((item, index) =>
        index === itemIndex
          ? {
            ...item,
            interviewDate: date,
          }
          : item
      )
    )
    dispatch(inputInterviewDate({ index: itemIndex, value: date }))
  }

  const handleRawDateChange = (
    itemIndex: number,
    ref: React.RefObject<DatePicker>
  ) => (e: any) => {
    const newDate = new Date(e.target.value)
    if (!isNaN(newDate.getTime())) {
      setState(
        state.map((item, index) =>
          index === itemIndex
            ? {
              ...item,
              interviewDate: newDate,
            }
            : item
        )
      )
      dispatch(inputInterviewDate({ index: itemIndex, value: newDate }))
    }
  }

  const handleKeyDown = (ref: React.RefObject<DatePicker>) => (e: any) => {
    // detect the Enter key
    if (e.keyCode && e.keyCode === 13) {
      if (ref && ref.current) {
        ref.current.setOpen(false)
      }
    }
  }

  const handleIconClick = (ref: React.RefObject<DatePicker>) => () => {
    if (ref && ref.current) {
      ref.current.setFocus()
      ref.current.setOpen(true)
    }
  }

  const [calenderDisplay, setCalenderDisplay] = React.useState(
    existsDesiredDate ? false : true
  )
  const handleSelectionNumberFix = (newValue: number) => {
    setValue(newValue)
    if (newValue) {
      dispatch(inputManipulationSelection(newValue))
    }
    if (newValue !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      dispatch(changeReplyAllow({ value: '1' }))
    } else {
      dispatch(clearCandidate())
    }
  }

  const handleCalenderDisplayChange = () => {
    setCalenderDisplay(!calenderDisplay)
    setValue(temporaryValue)
    if (temporaryValue) {
      dispatch(inputManipulationSelection(temporaryValue))
    }
    if (temporaryValue !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      dispatch(changeReplyAllow({ value: '1' }))
    } else {
      dispatch(clearCandidate())
    }
  }

  useEffect(() => {
    if (
      value === magiContants.SELECTION_NUMBER_PRESENT ||
      value === magiContants.SELECTION_NUMBER_SEND
    ) {
      setCalenderDisplay(true)
    }
  }, [value])

  return (
    <>
      <div className='message-schedule__block'>
        <div className='message-schedule__ttl'>
          面接所要時間
          <i className='modal-flow__badge_required ml10'>必須</i>
        </div>
        <div className='message-schedule__block_inner'>
          <select
            className='select-100per'
            onChange={e => handleChangeDuration(e.target.value)}
            id='jikan'>
            {interviewDurationSelect.map(duration => (
              <option
                value={duration.interviewDurationId}
                selected={
                  interviewSchedule &&
                  interviewSchedule.requiredTimeId ===
                  duration.interviewDurationId
                }>
                {duration.displayTime}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='message-schedule__block'>
        <div className='message-schedule__ttl'>
          面接内容
          <ClickAwayListener
            onClickAway={() => handleToolTipInterviewDetail(false)}>
            <svg
              className='form__body_ttl_icon mr10'
              focusable='false'
              viewBox='0 0 24 24'
              aria-hidden='true'
              onClick={() => handleToolTipInterviewDetail(true)}>
              <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
            </svg>
          </ClickAwayListener>
          <i className='modal-flow__badge_required ml10'>必須</i>
        </div>
        <div className='message-schedule__block_inner'>
          <FastField
            className={'form__input_100pw'}
            name={'interviewDetail'}
            type='none'
            placeholder='応募者に通知する面接の内容'
            component={MessageInputNoneCounter}
            maxAmount={10}
            onChange={handleInputInterviewDetail}
          />
        </div>
      </div>
      <div className='message-schedule__block'>
        <div className='message-schedule__ttl'>
          日程選択
          <ClickAwayListener
            onClickAway={() => handleToolTipCalenderChoose(false)}>
            <svg
              className='form__body_ttl_icon mr10'
              focusable='false'
              viewBox='0 0 24 24'
              aria-hidden='true'
              onClick={() => handleToolTipCalenderChoose(true)}>
              <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
            </svg>
          </ClickAwayListener>
          <i className='modal-flow__badge_required ml10'>必須</i>
        </div>
        <div className='message-schedule__block_inner'>
          <div className='form__body_item_modal'>
            <RadioGroup
              row
              defaultValue={magiContants.DESTINATION_DIVISION_MYNAVI_ONLY}>
              <div className='form__label_side'>
                <FormControlLabel
                  control={
                    <Radio
                      className='form__radio'
                      style={{ padding: 0 }}
                      color='primary'
                      value={magiContants.DESTINATION_DIVISION_MYNAVI_ONLY}
                      checked={calenderDisplay}
                      disabled={false}
                      onChange={handleCalenderDisplayChange}
                      icon={
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='21'
                          height='21'
                          viewBox='0 0 21 21'
                          className='form__radio_svg_off'>
                          <use xlinkHref='#form__radio_off'></use>
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='21'
                          height='21'
                          viewBox='0 0 21 21'
                          className='form__radio_svg_on'>
                          <use xlinkHref='#form__radio_on'></use>
                        </svg>
                      }
                    />
                  }
                  label={
                    <span
                      className='form__radio_text'
                      style={{
                        fontWeight: calenderDisplay ? 'bold' : 'unset',
                      }}>
                      カレンダーを表示
                    </span>
                  }
                />
              </div>
              {sendTarget.length <= 1 && existsDesiredDate ? (
                <div className='form__label_side'>
                  <FormControlLabel
                    control={
                      <Radio
                        className='form__radio'
                        style={{ padding: 0 }}
                        color='primary'
                        value={magiContants.DESTINATION_DIVISION_EMAIL_ONLY}
                        checked={value === magiContants.SELECTION_NUMBER_FIX}
                        disabled={false}
                        onChange={() =>
                          handleSelectionNumberFix(
                            magiContants.SELECTION_NUMBER_FIX
                          )
                        }
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_off'>
                            <use xlinkHref='#form__radio_off'></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            viewBox='0 0 21 21'
                            className='form__radio_svg_on'>
                            <use xlinkHref='#form__radio_on'></use>
                          </svg>
                        }
                      />
                    }
                    label={
                      <span
                        className='form__radio_text'
                        style={{
                          fontWeight: !calenderDisplay ? 'bold' : 'unset',
                        }}>
                        応募者の希望を表示
                      </span>
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </RadioGroup>
          </div>
        </div>

        {calenderDisplay ? (
          <Calendar
            {...{
              allowedArea,
              notAllowedArea,
              reservationList,
              isPermitOther,
              lengthLimit,
              interviewLocation,
            }}
            selected={state.filter(item => {
              return item.interviewDate &&
                item.interviewDate.getMinutes() % 5 === 0 &&
                item.interviewDate.getHours() >= 9 && item.interviewDate.getHours() <= 22
            })}
            setSelected={setState}
            handleToolTipReserved={handleToolTipReserved}
            setCurrentReservationList={setCurrentReservationList}
            currentReservationList={currentReservationList}
            toolTipReserved={toolTipReserved}
          />
        ) : (
          <div className='message-schedule-applicant'>
            <tbody>
              <tr>
                <th
                  className='message-schedule-applicant__head'
                  style={{ fontWeight: 'normal' }}>
                  応募者の
                  <br />
                  希望
                </th>
                <td className='message-schedule-applicant__body'>
                  <ul>
                    {allowedArea.map(item => (
                      <li className='message-schedule-applicant__item'>
                        {moment()
                          .add(item.day, unitSet.days)
                          .format(formatTemplate.date)}{' '}
                        {item.startDate}~{item.endDate}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </div>
        )}

        <CandidateDateGroup
          {...{
            data: state,
            lengthLimit,
            handleDateChange,
            handleRawDateChange,
            handleKeyDown,
            handleChargeChange,
            handleCopy,
            handleIconClick,
            handleOpenModal,
            handleDelete,
            notAllowedArea,
            reservationList
          }}
        />
        <div className={'btn__calendar-add-area'}>
          <button
            type='button'
            className={`btn__calendar-add ${state.length >= lengthLimit ? classes.btnCalendarAdd : ''
              }`}
            disabled={state.length >= lengthLimit}
            onClick={handleAdd}>
            候補日を追加する
          </button>
          <br />
          {state.length === lengthLimit && lengthLimit === 1 && (
            <span>確定日の送付は1日程しか指定できません。</span>
          )}
          {state.length === lengthLimit && lengthLimit === 30 && (
            <span>登録上限数は30件です。</span>
          )}
        </div>
      </div>

      {/* <!-- ラジオボタン --> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_on'
            data-name='form__radio_on'
            transform='translate(-78 -2778)'>
            <g
              transform='translate(78 2778)'
              fill='#fff'
              stroke='#3380cc'
              stroke-width='1'>
              <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
              <circle cx='10.5' cy='10.5' r='10' fill='none' />
            </g>
            <circle
              cx='7'
              cy='7'
              r='7'
              transform='translate(81.5 2781.5)'
              fill='#3380cc'
            />
          </g>
        </defs>
        <use xlinkHref='#form__radio_on'></use>
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='form__radio_off'
            data-name='form__radio_off'
            fill='#fff'
            stroke='#1a1a1a'
            stroke-width='1'>
            <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
            <circle cx='10.5' cy='10.5' r='10' fill='none' />
          </g>
        </defs>
        <use xlinkHref='#form__radio_off'></use>
      </svg>
      {/* <!-- /ラジオボタン --> */}
    </>
  )
}

export default TabItem
