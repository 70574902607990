import React, { useEffect } from 'react'
import { TextField as MuiTextField, PropTypes } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'

const useStyles = makeStyles({
  input: {
    '&::placeholder': {
      color: 'darkgray',
    },
    '&:disabled': {
      color: '#999999',
      borderColor: '#cccccc',
      border: '1px solid #999999',
    },
    fontSize: '1.6rem',
    minHeight: '40px',
  },
  color: {
    '& div': {
      backgroundColor: 'white',
    },
  },
})

interface Props extends FieldProps {
  type?: string
  margin?: PropTypes.Margin
  placeholder?: string
  defaultValue?: string
  disabled?: boolean
}

const TextField = ({
  type = 'text',
  margin = 'dense',
  placeholder,
  field,
  form,
  defaultValue,
  disabled,
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name } = field
  useEffect(() => {
    if (defaultValue != null) {
      form.setFieldValue(name, defaultValue)
    }
  }, [defaultValue])
  return (
    <MuiTextField
      className={classes.color}
      variant='outlined'
      margin={margin}
      fullWidth
      defaultValue={defaultValue}
      type={type}
      inputProps={{ spellCheck: false }}
      InputProps={{
        classes: { input: classes.input },
      }}
      error={Boolean(errors[name] && touched[name])}
      helperText={errors[name] && touched[name] ? errors[name] : null}
      placeholder={placeholder}
      disabled={disabled}
      {...field}
    />
  )
}

export default TextField
