import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { DataTable } from 'componentsHsc'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { routeList } from 'routes/routes'
import { useHistory } from 'react-router-dom'
import {
  MessageData,
  MessageScreenTransitionRequest,
} from 'utils/tableDataTransfrom'
import { isEmpty } from 'common/generalUtil'
import { updateStatusHsc } from 'reducers/entryDetailReducer'
import { updateStatus } from 'reducers/applicantDetailForEmplymentReducer'
import { openMessageInputDialog } from 'reducers/messageInputDialogReducer'
import history from 'utils/history'
import { ConfirmDialog, ConfirmDialogProps } from 'sp/components/ConfirmDialog'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'

const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: 'center',
    fontSize: '16px',
  },
  table: {
    overflowX: 'hidden',
    '& table': {
      '& tr:hover td:first-child': {
        padding: '16px 16px 16px 14px !important',
      },
      '& i': {
        height: 'auto !important',
        padding: '2px 8px !important',
      },
    },
  },
}))
/** メッセージエリア */
export const message: MessageData = {
  classification: '',
  destination: '',
  messageSendId: '',
  messageId: '',
  status: '',
  type: '',
  entryType: '',
  subject: '',
  attachedFile: false,
  selectionFlow: '',
  sender: '',
  datetime: '',
  // phase2 start
  messageRepliesNecessary: '',
  messageRepliesLimit: '',
  messageScoutCharacter: '',
  sendingResult: '',
  // phase2 end
  sysVersionNumber: undefined,
}

/** メッセージエリア */
export const messageRequest: MessageScreenTransitionRequest = {
  messageSendId: [],
  entryId: [],
  selectionManagementId: [],
}

interface Props {
  screenId: string
  messageDataList: MessageData[]
}

const Message = ({ screenId, messageDataList }: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()

  const entryMessageRequest = useSelector(
    (state: RootState) => state.entryDetail.messageRequest
  )
  const applicantMessageRequest = useSelector(
    (state: RootState) => state.applicantDetailForEmplyment.messageRequest
  )
  const recruitmentManagementDivisions = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  // 代行ログインアカウントフラグ
  const delegateLoginAccountFlag = useSelector(
    (state: RootState) => state.globalMenu.delegateLoginAccountFlag
  )
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)

  // メッセージ入力ダイアログコントロール
  const { isMessageInputDialogOpen } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<
    ConfirmDialogProps | undefined
  >()
  const handleClick = (index: number) => async () => {
    if (
      !(document.getSelection() && (document.getSelection() as any).toString())
    ) {
      const messageRequest: MessageScreenTransitionRequest =
        screenId === 'MCAXS040' ? applicantMessageRequest : entryMessageRequest
      const classification = messageDataList[index].classification
      const status = messageDataList[index].status
      let to = ''
      let localStorageName = ''
      let messageSendId = undefined
      let draftMessageSendId = undefined
      let sysVersionNumber = undefined
      if (classification === '受信') {
        if (status === '未読') {
          if (screenId === 'MCAXS40') {
            if (!delegateLoginAccountFlag) {
              dispatch(updateStatus(index))
            }
            to = routeList.messageDetail
            localStorageName = 'MessageDetail'
            messageSendId = [messageDataList[index].messageSendId]
          } else {
            if (!delegateLoginAccountFlag) {
              dispatch(updateStatusHsc(index))
            }
            to = routeList.messageDetail
            localStorageName = 'MessageDetail'
            messageSendId = [messageDataList[index].messageSendId]
          }
        } else {
          to = routeList.messageDetail
          localStorageName = 'MessageDetail'
          messageSendId = [messageDataList[index].messageSendId]
        }
      } else if (classification === '送信' || classification === '送信中') {
        to = routeList.messageDetail
        localStorageName = 'MessageDetail'
        messageSendId = [messageDataList[index].messageSendId]
      } else if (classification === '送信予約') {
        if (messageRequest.entryId && messageRequest.selectionManagementId) {
          to = routeList.messageDetail
          localStorageName = 'MessageDetail'
          messageSendId = [messageDataList[index].messageSendId]
        } else {
          to = routeList.messageSend
          localStorageName = 'messageInputRequest'
          draftMessageSendId = messageDataList[index].messageSendId
          sysVersionNumber = messageDataList[index].sysVersionNumber
        }
      }
      const requestParams = {
        screenId: screenId,
        messageId: messageDataList[index].messageId,
        messageSendId: messageDataList[index].messageSendId,
        jobSeekerSort: '1',
        recruitmentManagementDivision: recruitmentManagementDivisions,
      }
      const request = {
        pathname: to,
        messageSendId: messageSendId,
        draftMessageSendId: draftMessageSendId,
        sysVersionNumber: sysVersionNumber,
        request: {
          ...messageRequest,
          ...requestParams,
        },
      }

      localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
      localStorage.setItem(localStorageName, JSON.stringify(request))

      if (to === routeList.messageDetail) {
        const params = new URLSearchParams(requestParams as {}).toString()
        to = to + '?' + params
      }
      history.push(to)
    }
  }

  return (
    <>
      <div className='entry-details-message'>
        <div className='entry-details-message__total'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='25'
            height='25'
            viewBox='0 0 50 50'
            className='entry-details-message__icon_message'>
            <image
              id='header_messages'
              width='50'
              height='50'
              xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsSAAALEgHS3X78AAABp0lEQVRYhe1X0W3CQAx9rfJfRsgGTSX/lxFgAmADNmjZoBukTFBGoP+WgA06QpmAyuilMtdTEkiU5iNPSkQud/aL79ln7k6nE/qA+16wGIhE0Bsiid1EZATgDcCsY/9rAEtV/U448B8k4HzOk2BgrarzLhiIyDv9zjyRM4yEiFh0MoZs37LzjNHf09fvLsTEmgJ4BrDlwjZJbGk7Dd/HiNjWHAA8ANiJSOOtoo0dbR7oo5yIKRjAmIo25CLy2oCErc35aDbH9HGBJLaYE20PQTG9iEh6rZCdIFGVCKUFjQsXfJyJyJY1p4rAyOY6Eouqj6isrKpqXzUFcHQi/iM2RyJ1orQ1U9ooRa0Sr6ob6sYMP1r6xTKKY3vOOVIPmzo+ap81rCmZyyiLzMSRmDASRWZk19ShqFhLyHyJiEVmw9B/iEihoSIzPgFMYpnRGhG49HYZkbvXNx8RN7cBdLhyQ6sm51SjfkRVrVg92cXfN+PqrYmQaeVgHFrFEAOREBdEWBs6QeiryJp10T/69q0jnPueIiJL1wh1TcJ8Y/gTHmIgEqIfRAD8AO06oM07/eNyAAAAAElFTkSuQmCC'
            />
          </svg>
          メッセージ件数
          <strong className='entry-details-message__total_em'>
            {messageDataList.length > 0 ? messageDataList.length : 0}
          </strong>
          件
        </div>
        {messageDataList.length > 0 ? (
          <ul className='entry-details-message-list'>
            {messageDataList.map((item, rowIndex) => (
              <li
                className={
                  item.classification === '受信' && item.status === '未読'
                    ? 'entry-details-message-list__item is-message-unread'
                    : 'entry-details-message-list__item'
                }
                onClick={handleClick(rowIndex)}>
                <div className='entry-details-message-list__side'>
                  <div
                    className={
                      item.classification === '受信'
                        ? 'entry-details-message-list__status is-status-receive'
                        : 'entry-details-message-list__status is-status-send'
                    }>
                    {/* {item.classification} */}

                    {item.classification === '送信予約' ? (
                      <>
                        送信
                        <br />
                        予約
                      </>
                    ) : (
                      item.classification
                    )}

                    {item.destination === 'e-mail' ? (
                      <>
                        <br /> e-mail
                      </>
                    ) : item.classification === '送信予約' ||
                      item.classification === '送信中' ? (
                      <></>
                    ) : item.status === '返信済み' ? (
                      <>
                        <br />
                        {magiContants.REPLAY_FLAG_REPLIED_NAME}
                      </>
                    ) :
                      (<>
                        <br />
                        {item.status}
                      </>
                      )}
                  </div>

                  {item.attachedFile ? (
                    <div className='entry-details-message-list__status_attached'>
                      有
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='entry-details-message-list__item_block'>
                  <p className='entry-details-message-list__title'>
                    <a
                      href='#'
                      dangerouslySetInnerHTML={{ __html: item.subject }}></a>
                  </p>
                  <div>
                    {isEmpty(item.type) ? null : item.type == 'お問い合わせ' ? (
                      <i className='entry-details-message__badge ptn4'>
                        {item.type}
                      </i>
                    ) : (
                      <i className='entry-details-message__badge ptn7'>
                        {item.type}
                      </i>
                    )}
                    {isEmpty(item.entryType) ? null : (
                      <i className='entry-details-message__badge ptn3'>
                        {item.entryType}
                      </i>
                    )}
                  </div>
                  <p className='entry-details-message-list__text'>
                    選考フロー：{item.selectionFlow}
                    <br />
                    送信者：{item.sender}
                    <br />
                    {`${item.classification === '受信' ? "受信日時" : item.classification === '送信予約' ? "送信予約日時" : "送信日時"}`}：
                    {moment(new Date(item.datetime)).format('YYYY/MM/DD') +
                      ' ' +
                      moment(new Date(item.datetime)).format('HH:mm')}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className={classes.noData}>
            条件に一致する検索結果がありませんでした。
          </p>
        )}
      </div>
      {/* メッセージ有無チェック */}
      {confirmDialogConfig && <ConfirmDialog {...confirmDialogConfig} />}
    </>
  )
}

export default Message
