import React from 'react';

export default () => {
  return (
    <div className="box-info">
      <p className="alert">
        <a href="#">ステータス10日間以上未更新の応募者が5名います。</a>
      </p>
    </div>
  );
};
