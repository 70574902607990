import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorStyle } from 'componentsHsc'
import DatePicker from 'react-datepicker'
import CustomDatePicker, { CommonHandler } from './CustomDatePicker'
import { validateDate, validateStaffName } from 'utils/misc'
import { magiContants, formatTemplate, unitSet } from 'utils/contants'
import * as yup from 'yup'
import { Area } from 'utils/misc'
import { CandidateDay, Reservation } from './Calendar'

const timeIntervals = magiContants.ZS080_TIMEINTERVALS
const startTime = moment()
  .set({ hour: 9, minute: 0 })
  .format(formatTemplate.dateWithHourMinuteSpace)
const endTime = moment()
  .set({ hour: 22, minute: 55 })
  .format(formatTemplate.dateWithHourMinuteSpace)

const useStyles = makeStyles({
  candidateInputArea: {
    height: 'auto!important',
    marginTop: '5px',
    marginBottom: '5px',
    '& .candidateLabel': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
    '& .staffName': {
      width: '180px',
    },
    '& .deleteButton': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
    '& .copyButton': {
      gridRowEnd: '3!important',
      msGridRowSpan: '2!important',
    },
  },
  changeButton: {
    height: '32px',
    width: '186px',
    minWidth: '150px',
    marginRight: '5px',
  },
  dateGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& .react-datepicker__navigation': {
      top: '13px',
    },
  },
  dateGroup1: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& .react-datepicker__navigation': {
      top: '13px',
    },
    '& input': {
      borderColor: 'red',
    },
    '& input:hover': {
      borderColor: 'red',
    },
    '& input:focus': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  staffNameErr: {
    '& input': {
      borderColor: 'red',
    },
    '& input:hover': {
      borderColor: 'red',
    },
    '& input:focus': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  hint: {
    marginLeft: '215px',
    cursor: 'auto',
  },
  iconButton: {
    margin: '0 16px !important',
    width: '150px',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  validationCheck: {
    maxWidth: '215px',
    minWidth: '215px',
    margin: '8px 60px 0',
    lineHeight: '1.2em',
  },
  erroText: {
    margin: '0px 8px 8px 8px',
  },
})

interface Props extends CommonHandler {
  data: CandidateDay[]
  lengthLimit: number
  handleCopy: (item: CandidateDay, index: number) => () => void
  handleDelete: (itemIndex: number) => () => void
  handleChargeChange: (itemIndex: number) => (e: any) => void
  handleIconClick: (ref: React.RefObject<DatePicker>) => () => void
  handleOpenModal: (
    selectedInterviewLocationId: string,
    candidateListId: number
  ) => void
  notAllowedArea: Area[]
  reservationList: Reservation[]
}

const CandidateDateGroup = ({
  data,
  lengthLimit,
  handleDateChange,
  handleRawDateChange,
  handleKeyDown,
  handleCopy,
  handleDelete,
  handleChargeChange,
  handleIconClick,
  handleOpenModal,
  notAllowedArea,
  reservationList,
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()

  const staffNameYup = yup.object().shape({
    staffName: yup.string(),
  })

  //　応募者NG、予定ありの表示
  const isNotAllowedsOrIsReserveds = (item: CandidateDay) => {
    let isNotAllowed = false
    let isReserved = false
    if (item) {
      const interviewDateTmp = item.interviewDate
      if (interviewDateTmp) {
        const interviewDate =
          interviewDateTmp.getFullYear() +
          '-' +
          String(interviewDateTmp.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(interviewDateTmp.getDate()).padStart(2, '0') +
          'T' +
          String(interviewDateTmp.getHours()).padStart(2, '0') +
          ':' +
          String(interviewDateTmp.getMinutes()).padStart(2, '0')
        if (notAllowedArea.length) {
          notAllowedArea.forEach(area => {
            const start =
              moment()
                .add(area.day, unitSet.days)
                .format(formatTemplate.date) +
              'T' +
              area.startDate
            const end =
              moment()
                .add(area.day, unitSet.days)
                .format(formatTemplate.date) +
              'T' +
              area.endDate

            if (start <= interviewDate && interviewDate < end) {
              isNotAllowed = true
            }
          })
        }
        if (reservationList.length) {
          reservationList.forEach(day => {
            if (day.start <= interviewDate && interviewDate < day.end) {
              isReserved = true
            } 
          })
        }
      }
    }
    return (
      <>
        {isNotAllowed ? (
          <p style={{ color: 'red', border: 'solid 2px',padding:'5px' }}>NG日程</p>
        ) : (
          <></>
        )}
        {isReserved ? (
          <p style={{ color: 'red', border: 'solid 2px',padding:'5px'}}>予定あり</p>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {data.map((item, index) => (
        <div
          className='calendar-event-details'
          id={'id_' + index}
          key={index}
          ref={item.containerRef}>
          <div className='calendar-event-details__head'>
            <div className='calendar-event-details__num'>候補日{index + 1}</div>
            <button
              type='button'
              onClick={handleDelete(index)}
              className='btn__calendar-delete'
              title='この面接日程を削除します'>
              候補日の削除
            </button>
          </div>
          <div className='calendar-event-details__body'>
            <div className='calendar-event-details__item-1'>
              <CustomDatePicker
                {...{
                  item,
                  index,
                  handleDateChange,
                  handleRawDateChange,
                  handleKeyDown,
                }}
              />
              <button
                type='button'
                onClick={handleIconClick(item.datePickerRef)}
                className='btn__calendar-icon'
              />
              {/* 予定あり、NG日程の表示 */}
              {isNotAllowedsOrIsReserveds(item)}             
            </div>
            {validateDate({
                  input: item.interviewDate,
                  min: startTime.split(' ')[1],
                  max: endTime.split(' ')[1],
                  className: `${errorClasses.helperText} ${classes.erroText}`,
               })}
            <div>
              <address>
                <button
                  type='button'
                  onClick={e =>
                    handleOpenModal(item.interviewLocationId, index)
                  }
                  className='btn__calendar-place'>
                  {item.interviewLocation}
                </button>
              </address>
            </div>
            <div className='calendar-event-details__item-2'>
              <input
                type='text'
                className='form__input_100pw'
                value={item.charge}
                onChange={handleChargeChange(index)}
                name={`staffName${index}`}
                placeholder='面接担当者を入力（任意）'
              />
              <br />
              {validateStaffName({
                input: item.charge,
                className: `${errorClasses.helperText} ${classes.validationCheck}`,
                index: index,
              })}

              <div className='form__count'>
                <p className='form__count-word'>
                  {`${item.charge.length}/15文字`}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(CandidateDateGroup)
