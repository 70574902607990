import { makeStyles } from '@material-ui/core/styles'

export const useErrorStyle = makeStyles({
  helperText: {
    fontSize: '11px',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    margin: '8px 14px 0',
    whiteSpace: 'pre-wrap',
  },
})

export { default as AccountEdit } from 'componentsHsc/AccountEdit'
export { default as AdvancedSearch } from 'componentsHsc/AdvancedSearch'
export { default as AdvancedSearchJobChange } from 'sp/componentsHsc/AdvancedSearchJobChange'
export { default as Alert } from 'componentsHsc/Alert'
export { default as BulkChange } from 'componentsHsc/BulkChange'
export { default as Button } from 'componentsHsc/Button'
export { default as ButtonGroup } from 'componentsHsc/ButtonGroup'
export { default as Checkbox } from 'componentsHsc/Checkbox'
export { default as ConfirmModal } from 'componentsHsc/ConfirmModal'
export { default as DataTable } from 'componentsHsc/DataTable'
export { default as DatePicker } from 'sp/componentsHsc/DatePicker'
export { default as DndList } from 'componentsHsc/DndList'
export { default as EntryTable } from 'componentsHsc/EntryTable'
export { default as ErrorFocus } from 'componentsHsc/ErrorFocus'
export { default as FieldGroup } from 'componentsHsc/FieldGroup'
export { default as Filter } from 'componentsHsc/Filter'
export { default as Footer } from 'componentsHsc/Footer'
export { default as FormField } from 'componentsHsc/FormField'
export { default as GroupOptions } from 'componentsHsc/GroupOptions'
export { default as Label } from 'componentsHsc/Label'
export { default as NumberField } from 'componentsHsc/NumberField'
export { default as OperationPanel } from 'componentsHsc/OperationPanel'
export { default as Page } from './Page'
export { default as Paginate } from './Paginate'
export { default as ProgressStatus } from 'componentsHsc/ProgressStatus'
export { default as Radio } from 'componentsHsc/Radio'
export { default as ScrollToTop } from 'componentsHsc/ScrollToTop'
export { default as SearchButton } from 'componentsHsc/SearchButton'
export { default as SearchPanel } from './SearchPanel'
export { default as Select } from 'componentsHsc/Select'
export { default as SelectMessage } from './SelectMessage'
export { default as StyledTableCell } from 'componentsHsc/StyledTableCell'
export { default as SubTitle } from 'componentsHsc/SubTitle'
export { default as SubmitButton } from 'componentsHsc/SubmitButton'
export { default as Tab } from 'componentsHsc/Tab'
export { default as Table } from 'componentsHsc/Table'
export { default as TabPanel } from 'sp/componentsHsc/TabPanel'
export { default as TextareaAutosize } from 'componentsHsc/TextareaAutosize'
export { default as TextField } from 'componentsHsc/TextField'
export { default as TextFieldForMessage } from 'componentsHsc/TextFieldForMessage'
export { default as Title } from 'sp/componentsHsc/Title'
export { default as Toggle } from 'componentsHsc/Toggle'
export { default as TopBar } from 'componentsHsc/TopBar'
export { default as Layout } from 'componentsHsc/Layout'
export { default as OtherOperation } from 'componentsHsc/OtherOperation'
export { default as Header } from 'componentsHsc/Header'
export { default as Message } from 'sp/componentsHsc/Message'
export { default as NavigateButton } from 'componentsHsc/NavigateButton'
export { default as OverlaySearch } from './OverlaySearch'
export { default as Captcha } from 'componentsHsc/Captcha'
export { default as LoadingDialog } from 'componentsHsc/LoadingDialog'
export { default as MCBMessage } from 'componentsHsc/MyCareerBoxMessage'
