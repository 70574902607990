import React, { useEffect, useState } from 'react'
import { isEmpty } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import { Accordion, AccordionSummary } from '@material-ui/core'

interface Props {
  targetList: viewConfig.SendTarget[]
  destinationSelection: string
  recruitmentManagementDivision: string
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: viewConfig.SearchCriteriaInfo
  filterCondition: string
  mySearchCondition: string | null
  conversionMySearch: viewConfig.MySearch[]
}

const memberTypeNameList = new Map([
  [magiContants.MEMBERS_TYPE_NON_MEMBER, 'マイナビ非会員'],
  [magiContants.MEMBERS_TYPE_MEMBER, 'マイナビ会員'],
  [magiContants.MEMBERS_TYPE_DEACTIVATED, '退会済み'],
])

const entryTypeList = new Map([
  ['01', '一括'],
  ['02', '個別'],
  ['03', 'セミナー'],
  ['04', 'イベント'],
  ['11', '通常応募'],
  ['12', 'スカウト応募'],
  ['13', 'セミナー・面談参加申込み'],
  ['14', '質問（お問い合わせ）'],
  ['15', '匿名質問'],
  ['16', '旧イベント応募'],
  ['17', 'イベント着席'],
  ['18', 'イベント応募'],
  ['19', '他ルート応募'],
  ['90', '取込'],
])

const progressTypeList = new Map([
  ['0', '未判定'],
  ['1', '判定中'],
  ['2', '参加'],
  ['3', '合格'],
  ['4', '不参加'],
  ['5', '不合格'],
  ['6', 'その他'],
])

const SendTarget = ({
  targetList,
  destinationSelection,
  searchCriteriaName,
  searchCriteria,
  conversionMySearch,
}: Props) => {
  const [] = useState(false)

  // 「条件を表示する」ボタン押下
  const [detailDialogOpen, setDetailDialogOpen] = useState(true)

  const [targetExpanedList, setTargetExpanedList] = React.useState([
    { id: '', isExpanded: true },
  ])

  useEffect(() => {
    targetList.map(applicant =>
      setTargetExpanedList(prevState => [
        ...prevState,
        { id: applicant.entryId, isExpanded: true },
      ])
    )
  }, [targetList])

  const isExpaned = (id: string) => {
    if (targetExpanedList) {
      const obj = targetExpanedList.find(item => item.id === id)
      if (obj) {
        return obj.isExpanded
      }
    }
    return false
  }

  const handleExpandedChange = (id: string) => (
    event: React.ChangeEvent<{}>
  ) => {
    setTargetExpanedList(prevState =>
      prevState.map(obj =>
        obj.id === id ? { id: obj.id, isExpanded: !obj.isExpanded } : obj
      )
    )
    event.stopPropagation()
  }

  return (
    <>
      {/* 送信先ブロック */}
      <section
        id='send-target'
        className='message-block'
        style={{ padding: '0px 10px 5px' }}>
        <div className='form__ttl_modal_3'>送信先</div>
        <div className='form__body_modal mb0'>
          <div className='form__body_item_modal'>
            {destinationSelection ===
              magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
              <label className='form__label_side'>マイナビのみ</label>
            )}
            {destinationSelection ===
              magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
              <label className='form__label_side'>e-mailのみ</label>
            )}
          </div>
        </div>
      </section>
      {/* 送信先ブロック */}

      {/* 送信対象ブロック */}
      <div className='message-block'>
        <div className='form__ttl_modal_3' style={{ width: '100%' }}>
          <h1>{`送信対象（${targetList ? targetList.length : ''}件）`}</h1>
        </div>

        <div className='message-target-conditions'>
          {targetList &&
            targetList.length > 1 &&
            !isEmpty(searchCriteria) &&
            (!conversionMySearch || conversionMySearch.length == 0) && (
              <div className='message-target-conditions'>
                <div className='message-target-conditions__head'>検索条件</div>
                <div className='message-target-conditions__body'>
                  {!isEmpty(searchCriteriaName) ? (
                    <div>
                      選択しているMY検索
                      <span style={{fontWeight: 600, margin:'10px',wordBreak: 'break-all',}}>{searchCriteriaName}</span>
                    </div>
                  ) : null}
                  <div
                    className={
                      detailDialogOpen
                        ? 'message-target-conditions__text text-overflow'
                        : 'message-target-conditions__text'
                    }
                    dangerouslySetInnerHTML={{
                      __html: searchCriteria,
                    }}
                    style={{
                      overflow: detailDialogOpen ? 'hidden' : '',
                    }}
                  />
                  <button
                    type='button'
                    className='message-target-swith-btn'
                    onClick={() => {
                      setDetailDialogOpen(!detailDialogOpen)
                    }}>
                    {detailDialogOpen
                      ? '＋ 検索条件を表示'
                      : 'ー 検索条件を閉じる'}
                  </button>
                </div>
                <span style={{ fontSize: '12px' }}>
                  {magiContants.SEARCH_CRITERIA_DESCRIPTION}
                </span>
              </div>
            )}
        </div>

        <>
          <ul className='message-target-list'>
            {targetList && targetList.length > 100 ? null : (
              <>
                {targetList &&
                  targetList.map(applicant =>
                    applicant.readableFlag == '0' ? (
                      <div className='message-target-list__item'>
                        <div className='message-target-list__body is-accordion-content-open'>
                          <div className='message-target-list__wrap'>
                            <div className='message-target-list__inner'>
                              権限がないため閲覧できません（ID：
                              {applicant.jobSeekerId}）
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Accordion
                        key={applicant.entryId}
                        className='message-target-list__item'
                        expanded={isExpaned(applicant.entryId)}
                        onClick={e => e.stopPropagation()}
                        onChange={handleExpandedChange(applicant.entryId)}>
                        <AccordionSummary
                          aria-label='Expand'
                          aria-controls={`send-list-cassette-panel${applicant.entryId}_content`}
                          id={`send-list-cassette-panel${applicant.entryId}_id`}
                          onClick={e => e.stopPropagation()}
                          className='message-target-list__head'
                          style={{
                            padding: '0px',
                            width: '100%',
                            wordBreak: 'break-all',
                          }}>
                          <div
                            className='message-target-list__head_top'
                            style={{ width: '100%' }}>
                            {applicant.applicationType ===
                            magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION ? (
                              <>
                                <tr style={{ width: '100%' }}>
                                  <td>
                                    <p className='message-target-list__ttl'>
                                      匿名
                                    </p>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <p className='message-target-list__ttl'>
                                  {`${applicant.fullName}
                                      ${applicant.age}歳 ${
                                    applicant.progress
                                  } （
                                      ${applicant.progressType !== '1' ? ' - ' : progressTypeList.get(applicant.decisionDivision)}
                                    ）`}
                                </p>
                              </>
                            )}
                            <span className={'message-target-display__switch'}>
                              {isExpaned(applicant.entryId) ? (
                                <i className='message-target-list__switch_none is-accordion-open' />
                              ) : (
                                <i className='message-target-list__switch_open is-accordion-open' />
                              )}
                            </span>
                          </div>
                        </AccordionSummary>

                        <div className='message-target-list__body is-accordion-content-open'>
                          <div className='message-target-list__wrap'>
                            <div className='message-target-list__inner'>
                              <dl className='message-target-info'>
                                <dt className='message-target-info__head'>
                                  応募職種
                                </dt>
                                <dd className='message-target-info__text'>
                                  {!isEmpty(applicant.jobTitle)
                                    ? applicant.jobTitle
                                    : '-'}
                                </dd>

                                {applicant.entryType !==
                                  magiContants.ENTRY_TYPE_QUESTION &&
                                applicant.entryType !==
                                  magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION ? (
                                  <>
                                    <dt className='message-target-info__head'>
                                      選考フロー
                                    </dt>
                                    <dd className='message-target-info__text'>
                                      {applicant.selectionFlow}
                                    </dd>
                                  </>
                                ) : (
                                  <>
                                    <dt className='message-target-info__head'>
                                      選考フロー
                                    </dt>
                                    <dd className='message-target-info__text'>
                                      -
                                    </dd>
                                  </>
                                )}

                                <dt className='message-target-info__head'>
                                  応募日時
                                </dt>
                                <dd className='message-target-info__text'>
                                  {applicant.applicationDate}
                                  {'　　'}
                                  {(function() {
                                    const rlt = memberTypeNameList.get(
                                      applicant.mynaviMemberInfo
                                    )
                                    return rlt
                                  })()}
                                </dd>

                                {applicant.jobSeekerId &&
                                  destinationSelection ===
                                    magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
                                    <>
                                      <dt className='message-target-info__head'>
                                        利用者番号
                                      </dt>
                                      <dd className='message-target-info__text'>
                                        {`${applicant.jobSeekerId}`}
                                      </dd>
                                    </>
                                  )}

                                {applicant.mailAddress &&
                                  (destinationSelection ===
                                    magiContants.DESTINATION_DIVISION_EMAIL_ONLY ||
                                    applicant.mynaviMemberInfo !==
                                      magiContants.MEMBERS_TYPE_MEMBER) && (
                                    <>
                                      <dt
                                        className='message-target-info__head'
                                        style={{ width: '8em' }}>
                                        メールアドレス
                                      </dt>
                                      <dd
                                        className='message-target-info__text'
                                        style={{
                                          width: 'calc(100% - 8em)',
                                        }}>
                                        {applicant.mailAddress}
                                      </dd>
                                    </>
                                  )}
                              </dl>

                              <div>
                                <i className='entry-details-message__badge ptn3'>
                                  {entryTypeList.get(applicant.applicationType)}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                    )
                  )}
              </>
            )}
          </ul>
        </>
      </div>
      {/* 送信対象ブロック */}
    </>
  )
}

export default SendTarget
