import React, { useEffect, useRef } from 'react'
interface Props {
  className: string
}

const StickyFooterBlock: React.FC<Props> = ({ children, className }) => {

  const divRef = useRef<HTMLDivElement>(null)
  const minModalElmList = document.querySelector<HTMLDivElement>('.is-modal-min')

  useEffect(() => {
    // メッセージ入力画面　画面遷移・最小化時にスティッキーエリアの位置を調整する。
    if (minModalElmList && divRef.current) {
      divRef.current.style.bottom = '50px'
    } else if (divRef.current) {
      divRef.current.style.bottom = '0px'
    }
  }, [minModalElmList])

  return (
    <div
      className={className}
      ref={divRef}>{children}</div>
  )
}

export default StickyFooterBlock
