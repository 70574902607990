import React from 'react';

export default () => {
  return (
    <section>
      <h1>
        件数：<strong>12</strong>件
      </h1>
      <hr />
      <button type="button" className="btn on-icon icon-changeItem">
        一覧の表示項目を設定
      </button>
      <div className="group-horizontal">
        <a href="applicant-search-entry.html" className="btn">
          エントリーで表示
        </a>
        <a href="applicant-search.html" className="btn current">
          応募者で表示
        </a>
      </div>
      <div className="selection-table applicant">
        <div className="other-operation">
          <button
            type="button"
            className="btn most circular icon-changeProgress"
            data-action="check-open-change-progress"
            data-balloon="changeProgress">
            合否・選考
            <br />
            ステップを
            <br />
            一括変更
          </button>
          <button
            type="button"
            className="btn most circular icon-otherOperation"
            data-action="open-balloon-otherOperations"
            data-balloon="otherOperation">
            その他の
            <br />
            一括操作
          </button>
        </div>
        <label>
          <input type="checkbox" />
          <mark></mark>すべて選択
        </label>
        <label className="btn show" data-balloon="selectAll">
          <input type="checkbox" />
          <mark>検索結果の全件を選択</mark>
        </label>
        <hr />
        <label>
          <select>
            <option>ID順（昇順）</option>
          </select>
          でソート
        </label>
        <div className="pager">
          <button type="button" className="btn current">
            1
          </button>
          <button type="button" className="btn">
            2
          </button>
          <button type="button" className="btn">
            3
          </button>
          <button type="button" className="btn">
            4
          </button>
          <button type="button" className="btn">
            5
          </button>
          <hr />
          <button type="button" className="btn">
            &gt;
          </button>
          <button type="button" className="btn">
            ≫
          </button>
          1-50/全500件
        </div>
        <table>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>選択</th>
              <th>
                姓名
                <br />
                ID
              </th>
              <th>
                学校
                <br />
                学部
                <br />
                学科
              </th>
              <th>
                文理
                <br />
                区分
              </th>
              <th>現住所</th>
              <th>
                未読
                <br />
                メッセージ
              </th>
              <th>応募経路</th>
              <th>
                選考フロー
                <br />
                評価
                <br />
                進捗
                <br />
                判定
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td rowSpan={2} className="">
                <a href="#">毎日太郎</a>
                (01234567890)
              </td>
              <td rowSpan={2} className="">
                あいうえおかきくけこ
                <br />
                大学あいうえお学部
                <br />
                広告コミュニケーション学科
              </td>
              <td rowSpan={2} className="">
                理系
              </td>
              <td rowSpan={2} className="">
                北海道
              </td>
              <td rowSpan={2} className="">
                <b>
                  <a href="#">10</a>
                </b>
                件
              </td>
              <td>
                <i className="badge ptn1">マイナビ2019</i>
              </td>
              <td>
                <span>20本採用＜営業＞</span>
                <input type="number" value="4" className="evaluation-meter" />
                <em>一次面接</em>
                <span className="group-horizontal">
                  <label>
                    <input type="radio" name="select-table-01" />
                    <mark>判定中</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-01" />
                    <mark>合格</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-01" />
                    <mark>不合格</mark>
                  </label>
                </span>
              </td>
            </tr>
            <tr className="">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td>
                <i className="badge ptn1">マイナビ2019</i>
              </td>
              <td>
                <span>20本採用＜営業＞</span>
                <input type="number" value="4" className="evaluation-meter" />
                <em>一次面接</em>
                <span className="group-horizontal">
                  <label>
                    <input type="radio" name="select-table-01-2" />
                    <mark>判定中</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-01-2" />
                    <mark>合格</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-01-2" />
                    <mark>不合格</mark>
                  </label>
                </span>
              </td>
            </tr>
            <tr className="duplicate">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td>
                <a href="#">毎日森森森森森森森森森森森森森森森森森森</a>
                (01234567890)
              </td>
              <td>
                あいうえおかきくけこ
                <br />
                大学あいうえお学部
                <br />
                広告コミュニケーション学科
              </td>
              <td>理系</td>
              <td>北海道</td>
              <td>
                <b>
                  <a href="#">10</a>
                </b>
                件
              </td>
              <td>
                <i className="badge ptn1">マイナビ2019</i>
              </td>
              <td>
                <span>20本採用＜営業＞</span>
                <input type="number" value="4" className="evaluation-meter" />
                <em>一次面接</em>
                <span className="group-horizontal">
                  <label>
                    <input type="radio" name="select-table-02" />
                    <mark>判定中</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-02" />
                    <mark>合格</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-02" />
                    <mark>不合格</mark>
                  </label>
                </span>
              </td>
            </tr>
            <tr className="unread">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td>
                <a href="#">毎日太郎</a>
                (01234567890)
              </td>
              <td>
                あいうえおかきくけこ
                <br />
                大学あいうえお学部
                <br />
                広告コミュニケーション学科
              </td>
              <td>理系</td>
              <td>北海道</td>
              <td>
                <b>
                  <a href="#">10</a>
                </b>
                件
              </td>
              <td>
                <i className="badge ptn1">マイナビ2019</i>
              </td>
              <td>
                <span>20本採用＜営業＞</span>
                <input type="number" value="4" className="evaluation-meter" />
                <em>一次面接</em>
                <span className="group-horizontal">
                  <label>
                    <input type="radio" name="select-table-03" />
                    <mark>判定中</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-03" />
                    <mark>合格</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-03" />
                    <mark>不合格</mark>
                  </label>
                </span>
              </td>
            </tr>
            <tr className="">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td>
                <a href="#">毎日太郎</a>
                (01234567890)
              </td>
              <td>
                あいうえおかきくけこ
                <br />
                大学あいうえお学部
                <br />
                広告コミュニケーション学科
              </td>
              <td>理系</td>
              <td>北海道</td>
              <td>
                <b>
                  <a href="#">10</a>
                </b>
                件
              </td>
              <td>
                <i className="badge ptn1">マイナビ2019</i>
              </td>
              <td>
                <span>20本採用＜営業＞</span>
                <input type="number" value="4" className="evaluation-meter" />
                <em>一次面接</em>
                <span className="group-horizontal">
                  <label>
                    <input type="radio" name="select-table-04" />
                    <mark>判定中</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-04" />
                    <mark>合格</mark>
                  </label>
                  <label>
                    <input type="radio" name="select-table-04" />
                    <mark>不合格</mark>
                  </label>
                </span>
              </td>
            </tr>
            <tr className="unsubscribe">
              <td>
                <label>
                  <input type="checkbox" />
                  <mark></mark>
                </label>
                <a href="#">tdクリック時のリンク</a>
              </td>
              <td>
                <a>このデータは削除されました</a>(01234567890)
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
