import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { RecruitmentManagementFlag, Seminar, SeminarSchedule } from 'pages/MCAZS080/viewConig'
import momentTz from 'moment-timezone'
import { isEmpty, now } from 'common/generalUtil'

export const textDecoration = [
  { before: '{@b}', after: '<span style=\"font-weight: bold\">', beforeEnd: '{@/b}', afterEnd: '</span>', rule: /{@b}/g, ruleEnd: /{@\/b}/g, name: 'textDecorationBold'},
  { before: '{@i}', after: '<span style=\"font-style: italic\">', beforeEnd: '{@/i}', afterEnd: '</span>', rule: /{@i}/g, ruleEnd: /{@\/i}/g, name: 'textDecorationItalic'},
  { before: '{@u}', after: '<span style=\"text-decoration: underline\">', beforeEnd: '{@/u}', afterEnd: '</span>', rule: /{@u}/g, ruleEnd: /{@\/u}/g, name: 'textDecorationUnderline'},
  { before: '{@red}', after: '<span style=\"color: red\">', beforeEnd:'{@/red}', afterEnd: '</span>', rule: /{@red}/g, ruleEnd: /{@\/red}/g, name: 'textDecorationRed'},
  { before: '{@orange}', after: '<span style=\"color: orange\">', beforeEnd: '{@/orange}', afterEnd: '</span>', rule: /{@orange}/g, ruleEnd: /{@\/orange}/g, name: 'textDecorationOrange'},
  { before: '{@yellowgreen}', after: '<span style=\"color: yellowgreen\">', beforeEnd: '{@/yellowgreen}', afterEnd: '</span>', rule: /{@yellowgreen}/g, ruleEnd: /{@\/yellowgreen}/g, name: 'textDecorationYellowgreen'},
  { before: '{@lime}', after: '<span style=\"color: lime\">', beforeEnd: '{@/lime}', afterEnd: '</span>', rule: /{@lime}/g, ruleEnd: /{@\/lime}/g, name: 'textDecorationLime'},
  { before: '{@cyan}', after: '<span style=\"color: cyan\">', beforeEnd: '{@/cyan}', afterEnd: '</span>', rule: /{@cyan}/g, ruleEnd: /{@\/cyan}/g, name: 'textDecorationCyan'},
  { before: '{@blue}', after: '<span style=\"color: blue\">', beforeEnd: '{@/blue}', afterEnd: '</span>', rule: /{@blue}/g, ruleEnd: /{@\/blue}/g, name: 'textDecorationBlue'},
  { before: '{@magenta}', after: '<span style=\"color: magenta\">', beforeEnd: '{@/magenta}', afterEnd: '</span>', rule: /{@magenta}/g, ruleEnd: /{@\/magenta}/g, name: 'textDecorationMagenta'},
]
export const insertionCharacters = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@id}', after: '<span style="font-weight: bold">[この部分には自動で応募者管理IDが入ります]</span>', rule: /{@id}/g, name: 'insertTextId'},
  { before: '{@user_id}', after: '<span style="font-weight: bold">[この部分には自動で利用者番号が入ります]</span>', rule: /{@user_id}/g, name: 'insertTextUserId'},
  { before: '{@date}', after: '<span style="font-weight: bold">[この部分には自動で現在の日付(○○○○年○○月○○日)が入ります]</span>', rule: /{@date}/g, name: 'insertTextDate'},
  { before: '{@company_name}', after: '{@company_name}', rule: /{@company_name}/g, name: 'insertTextCompanyName'},
  { before: '{@job}', after: '<span style="font-weight: bold">[この部分には自動で応募職種名が入ります]</span>', rule: /{@job}/g, name: 'insertTextJob'},
  { before: '{@latest_progress}', after: '<span style="font-weight: bold">[この部分には自動で選考ステップが入ります]</span>', rule: /{@latest_progress}/g, name: 'insertTextLatestProgress'},
  { before: '{@latest_decision}', after: '<span style="font-weight: bold">[この部分には自動で合否が入ります]</span>', rule: /{@latest_decision}/g, name: 'insertTextLatestDecision'},
  { before: '{@interview_date}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接日が入ります]</span>', rule: /{@interview_date}/g, name: 'insertInterviewDate'},
  { before: '{@interview_location}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接場所が入ります]</span>', rule: /{@interview_location}/g, name: 'insertInterviewLocation'},
  { before: '{@request_url}', after: '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>', rule: /{@request_url}/g, name: 'requestUrl'},
]

export const insertionCharactersR1 = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@id}', after: '<span style="font-weight: bold">[この部分には自動で応募者管理IDが入ります]</span>', rule: /{@id}/g, name: 'insertTextId'},
  { before: '{@latest_progress}', after: '<span style="font-weight: bold">[この部分には自動で選考ステップが入ります]</span>', rule: /{@latest_progress}/g, name: 'insertTextLatestProgress'},
  { before: '{@latest_decision}', after: '<span style="font-weight: bold">[この部分には自動で合否が入ります]</span>', rule: /{@latest_decision}/g, name: 'insertTextLatestDecision'},
]
export const insertionCharactersR2 = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@user_id}', after: '<span style="font-weight: bold">[この部分には自動で利用者番号が入ります]</span>', rule: /{@user_id}/g, name: 'insertTextUserId'},
  { before: '{@date}', after: '<span style="font-weight: bold">[この部分には自動で現在の日付(○○○○年○○月○○日)が入ります]</span>', rule: /{@date}/g, name: 'insertTextDate'},
  { before: '{@company_name}', after: '', rule: /{@company_name}/g, name: 'insertTextCompanyName'},
  { before: '{@job}', after: '<span style="font-weight: bold">[この部分には自動で応募職種名が入ります]</span>', rule: /{@job}/g, name: 'insertTextJob'},
  { before: '{@latest_progress}', after: '<span style="font-weight: bold">[この部分には自動で選考ステップが入ります]</span>', rule: /{@latest_progress}/g, name: 'insertTextLatestProgress'},
  { before: '{@latest_decision}', after: '<span style="font-weight: bold">[この部分には自動で合否が入ります]</span>', rule: /{@latest_decision}/g, name: 'insertTextLatestDecision'},
]
// MCBリプレース MCAMS040 START
export const insertionCharactersMcbTempSubject = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
]
export const insertionCharactersMcbTempBody = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@request_url}', after: '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>', rule: /{@request_url}/g, name: 'requestUrl'},
]
// MCBリプレース MCAMS040 END
// MCBリプレース MCBJS020 START
export const insertionCharactersMcb = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@id}', after: '<span style="font-weight: bold">[この部分には自動で応募者管理IDが入ります]</span>', rule: /{@id}/g, name: 'insertTextId'},
  { before: '{@user_id}', after: '<span style="font-weight: bold">[この部分には自動で利用者番号が入ります]</span>', rule: /{@user_id}/g, name: 'insertTextUserId'},
  { before: '{@date}', after: '<span style="font-weight: bold">[この部分には自動で現在の日付(○○○○年○○月○○日)が入ります]</span>', rule: /{@date}/g, name: 'insertTextDate'},
  { before: '{@company_name}', after: '{@company_name}', rule: /{@company_name}/g, name: 'insertTextCompanyName'},
  { before: '{@job}', after: '<span style="font-weight: bold">[この部分には自動で応募職種名が入ります]</span>', rule: /{@job}/g, name: 'insertTextJob'},
  { before: '{@latest_progress}', after: '<span style="font-weight: bold">[この部分には自動で選考ステップが入ります]</span>', rule: /{@latest_progress}/g, name: 'insertTextLatestProgress'},
  { before: '{@latest_decision}', after: '<span style="font-weight: bold">[この部分には自動で合否が入ります]</span>', rule: /{@latest_decision}/g, name: 'insertTextLatestDecision'},
  { before: '{@interview_date}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接日が入ります]</span>', rule: /{@interview_date}/g, name: 'insertInterviewDate'},
  { before: '{@interview_location}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接場所が入ります]</span>', rule: /{@interview_location}/g, name: 'insertInterviewLocation'},
  // MCBリプレイス #8670 START
  { before: '{@reply_title}', after: '<span style="font-weight: bold">[この部分には自動で返信元の件名が入ります]</span>', rule: /{@reply_title}/g, name: 'insertReplyTitle'},
  { before: '{@reply_body}', after: '<span style="font-weight: bold">[この部分には自動で返信元の本文が入ります]</span>', rule: /{@reply_body}/g, name: 'insertReplyBody'},
  // MCBリプレイス #8670 END
]
// MCBリプレース MCBJS020 END

// MCBリプレース MCAZS230 Start
export const insertionCharactersMcbMsg = [
  { before: '{@user_name}', after: '<span style="font-weight: bold">[この部分には自動で応募者氏名が入ります]</span>', rule: /{@user_name}/g, name: 'insertTextUserName'},
  { before: '{@sei}', after: '<span style="font-weight: bold">[この部分には自動で応募者姓が入ります]</span>', rule: /{@sei}/g, name: 'insertTextUserSei' },
  { before: '{@mei}', after: '<span style="font-weight: bold">[この部分には自動で応募者名が入ります]</span>', rule: /{@mei}/g, name: 'insertTextUserMei'},
  { before: '{@id}', after: '<span style="font-weight: bold">[この部分には自動で応募者管理IDが入ります]</span>', rule: /{@id}/g, name: 'insertTextId'},
  { before: '{@user_id}', after: '<span style="font-weight: bold">[この部分には自動で利用者番号が入ります]</span>', rule: /{@user_id}/g, name: 'insertTextUserId'},
  { before: '{@date}', after: '<span style="font-weight: bold">[この部分には自動で現在の日付(○○○○年○○月○○日)が入ります]</span>', rule: /{@date}/g, name: 'insertTextDate'},
  { before: '{@company_name}', after: '{@company_name}', rule: /{@company_name}/g, name: 'insertTextCompanyName'},
  { before: '{@job}', after: '<span style="font-weight: bold">[この部分には自動で応募職種名が入ります]</span>', rule: /{@job}/g, name: 'insertTextJob'},
  { before: '{@latest_progress}', after: '<span style="font-weight: bold">[この部分には自動で選考ステップが入ります]</span>', rule: /{@latest_progress}/g, name: 'insertTextLatestProgress'},
  { before: '{@latest_decision}', after: '<span style="font-weight: bold">[この部分には自動で合否が入ります]</span>', rule: /{@latest_decision}/g, name: 'insertTextLatestDecision'},
  { before: '{@interview_date}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接日が入ります]</span>', rule: /{@interview_date}/g, name: 'insertInterviewDate'},
  { before: '{@interview_location}', after: '<span style="font-weight: bold">[この部分には自動で確定した面接場所が入ります]</span>', rule: /{@interview_location}/g, name: 'insertInterviewLocation'},
  { before: '{@request_url}', after: '<span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span>', rule: /{@request_url}/g, name: 'insertTextRequestUrl'},
  // MCBリプレイス #8670 START
  { before: '{@reply_title}', after: '<span style="font-weight: bold">[この部分には自動で返信元の件名が入ります]</span>', rule: /{@reply_title}/g, name: 'insertReplyTitle'},
  { before: '{@reply_body}', after: '<span style="font-weight: bold">[この部分には自動で返信元の本文が入ります]</span>', rule: /{@reply_body}/g, name: 'insertReplyBody'},
  // MCBリプレイス #8670 END
]
// MCBリプレース MCAZS230 End