import { SelectionFlowList } from 'reducers/progressStatusChangeReducer'
import { stringToNumber } from 'common/generalUtil'

export interface InputValues {
    selectionStep: string
    interviewDay: Date | undefined
    fairueReason: string
    declineReason: string
    other: string
    message: string
}

// 進捗変更前チェック時のメッセージリスト（進捗変更時の確認メッセージ）
const messageMap = new Map([
    // 選考中→辞退 選考中→不採用 選考中→内定以降
    // 辞退→選考中 辞退→内定以降
    // 不採用→選考中 不採用→内定以降
    // 内定以降→選考中 内定以降→辞退 内定以降→不採用
    ['1', '選考ステップを{0}へ変更しますか？変更後は{1}に移動されます。'],
    // 書類回収中→書類選考結果待ち
    ['2', 'この応募者を推薦します。よろしいですか？'],
    // -(上記以外の場合)
    ['3', '選考ステップを{0}へ変更しますか？']
])

export const progressTypeMap = new Map([
    ['0', '選考中一覧'],
    ['2', '内定者一覧'],
    ['4', '内定者一覧'],
    ['5', '内定者一覧'],
    ['7', '選考対象外一覧'],
    ['8', '選考対象外一覧'],
])

// メッセージ取得
// 与えられたメッセージIDを元に該当するメッセージを取得する。
export const getMessage = (selectionStepBefore: string, selectionStepAfter: string, selectionFlowList: SelectionFlowList[]): string => {
    // 1.2.2.3 
    if (selectionStepBefore == selectionStepAfter) {
        return ''
    }

    // 変更元情報
    // 　進捗種別（変更元）
    let progressTypeBefore = ''
    // 　システム進捗ステータスID（変更元）
    let systemProgressStatusIdBefore = 0
    // 変更先情報
    // 　進捗名（変更先）
    let progressNameAfter = ''
    // 　進捗種別（変更先）
    let progressTypeAfter = ''
    // 　システム進捗ステータスID（変更先）
    let systemProgressStatusIdAfter = 0

    for (var i = 0; i < selectionFlowList.length; i++) {
        // 1.2.2.1 進捗の変更元情報を取得します。
        if (selectionFlowList[i].progressStatusSettingId == stringToNumber(selectionStepBefore)) {
            progressTypeBefore = selectionFlowList[i].progressType
            systemProgressStatusIdBefore = selectionFlowList[i].systemProgressStatusId
        }
        // 1.2.2.2 進捗の変更先情報を取得します。
        if (selectionFlowList[i].progressStatusSettingId == stringToNumber(selectionStepAfter)) {
            progressNameAfter = selectionFlowList[i].progressName
            progressTypeAfter = selectionFlowList[i].progressType
            systemProgressStatusIdAfter = selectionFlowList[i].systemProgressStatusId
        }
    }

    // 1.2.2.4  進捗変更前チェックのメッセージを設定する
    let message: string = ''
    //進捗種別リスト（選考中：[0]応募）
    let processTypeSelecting = ['0']
    //進捗種別リスト（内定以降：[2]内定  [4]内定承諾 [5]入社）
    let processTypeConfrimAfter = ['2', '4', '5']
    //進捗種別リスト（[7]不採用 [8]辞退）
    let processTypeNoPass = ['7', '8']

    if (systemProgressStatusIdBefore == 1002 && systemProgressStatusIdAfter == 1003) {
        message = messageMap.get('2') || ''
        return message
    }
    if ((processTypeSelecting.includes(progressTypeBefore) && processTypeSelecting.includes(progressTypeAfter))
        || (processTypeConfrimAfter.includes(progressTypeBefore) && processTypeConfrimAfter.includes(progressTypeAfter))
        || (processTypeNoPass.includes(progressTypeBefore) && processTypeNoPass.includes(progressTypeAfter))) {
        message = messageMap.get('3') || ''
        message = message.replace(new RegExp(`\\{${0}\\}`), progressNameAfter)
        return message
    }
    message = messageMap.get('1') || ''
    message = message.replace(new RegExp(`\\{${0}\\}`), progressNameAfter)
    message = message.replace(new RegExp(`\\{${1}\\}`), progressTypeMap.get(progressTypeAfter) || '')
    return message
}



