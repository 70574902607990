import { getMessage } from 'common/messageUtil'
import { MCAZS080MessageInitRequest } from 'types/MCAZS080/MCAZS080MessageInitRequest'
import { MCAZS080MessageNotAdoptedNotificationRequest } from 'types/MCAZS080/MCAZS080MessageNotAdoptedNotificationRequest'

/** 初期表示リクエスト */
export interface MessageInitRequest {
  messageSendId: string[]
  entryId: number
  selectionManagementId: string[]
  searchCriteria: string
  interviewScheduleAdjustmentAction: string
  interviewScheduleAdjustmentId: string[]
  interviewScheduleId: number[]
  replyMessageSendId: string
  draftMessageSendId: string
  sysVersionNumber: string
}

export interface PreviewRequest {
  entryId: number[]
  companyId: number
}

export interface InputValues {
  body: string
  homepageTitle1: string
  homepageTitle2: string
  homepageTitle3: string
  homepageUrl1: string
  homepageUrl2: string
  homepageUrl3: string
  interviewDetail: string
  reminder: string
  reminderSelect: string
  sendTarget: string
  sendTime: Date | null
  sendTimeTransmission: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  templateName: {
    label: string, value: string
  }
  barcodeDisplay: string
  repliesLimit: string | null
  sendTimeType: string
}

const messageInitRequest: MCAZS080MessageInitRequest = {
  messageSendId: [],
  entryId: [],
  selectionManagementId: [],
  searchCriteria: '',
  filterConditionTitle: [],
  filterConditionValue: [],
  action: '',
  interviewScheduleAdjustmentId: 0,
  interviewScheduleId: [],
  replyMessageSendId: null,
  draftMessageSendId: null,
  sysVersionNumber: null,
}

const notAdoptedNotificationRequest: MCAZS080MessageNotAdoptedNotificationRequest = {
  entryId: [],
  selectionManagementId: [],
  notAdoptedNotificationType: null,
  jobSeekerId: [],
  screenId: '',
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
}

const inputValues = {
  subject: '',
  body: '',
  attachment: [],
}

const targetTextAreas = {
  subject: 'subject',
  body: 'body',
}

const inputList = {
  senderCompanyName: {
    max: 200,
  },
  senderMailAddress: {
    max: 256,
  },
  subject: {
    max: 200,
  },
  body: {
    max: 2000,
  },
  homePageUrl: {
    max: 256,
  },
  homePageTitle: {
    max: 80,
  },
  notifyMailAddress: {
    max: 160,
  },
}

const textMap = {
  '060': {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCBAS010-006'),
  },
  confirm: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: '入力された内容で更新します。よろしいですか？',
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCAZS080-058'),
  },
  draft: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage('MCAZS080-015'),
  },
  draftSaveComplete: {
    submit: 'OK',
    cancel: '',
    message: getMessage('MCAZS080-016'),
  },
  draftCandidateConfirm: {
    submit: 'OK',
    cancel: '',
    message: getMessage('MCBAS010-003'),
  },
}

export { messageInitRequest, inputList, targetTextAreas, textMap, notAdoptedNotificationRequest }
