import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import React from 'react'

type ConfirmDialogResult = 'confirm' | 'cancel'

export type ConfirmDialogProps = {
  onClose: (result: ConfirmDialogResult) => void
  message: string
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { onClose, message } = props

  const cancelClose = () => {
    onClose('cancel')
  }
  const confirmClose = () => {
    onClose('confirm')
  }

  return (
    <Dialog
      open={true}
      onClose={cancelClose}
      disableBackdropClick
      className={'dialog is-dialog-show'}
      disableScrollLock={true}>
      <div className='dialog-content' style={{ width: '100%' }}>
        <div className={'dialog-section'}>{message}</div>
        <div className='dialog-section-btn'>
          <button onClick={cancelClose} className='daialogCancelBtn'>キャンセル</button>
          <button onClick={confirmClose} className='daialogConfirmBtn'>OK</button>
        </div>
      </div>
    </Dialog>

  )
}
