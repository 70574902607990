import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { RootState } from 'reducers'
import { } from './formConfig'
import {
  setDialogOpen,
  recommendCheck,
  setRecommendCheckDialogClose,
  setProgressChangeBeforeCheckDialogOpeFlag,
  setUpdateProgressData,
  updateProgress,
  setCancelCheckDialogOpeFlag,
  RecommendCheckRequest,
  initial,
} from 'reducers/progressStatusChangeReducer'
import { MCAXS110UpdateProgressRequest } from 'types/MCAXS110/MCAXS110UpdateProgressRequest'
import { LoadingDialog, FieldGroup, Title, } from 'components'
import { Formik, Form, Field } from 'formik';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, } from '@material-ui/core'
import MyInputWithCounter from './MyInputWithCounter';
import MySelectMessage from './MySelectMessage';
import DataTimePickerCustDeadline from './DataTimePickerCustDeadline';
import MySelectSelectionStepMessage from './MySelectSelectionStepMessage';
import { onKeyDown, dateTimeToString } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import MCAXS110Validation from 'validations/MCAXS110/MCAXS110UpdateProgressRequestValidation'

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(0, 6, 3, 6),
    backgroundColor: '#565a61',
    position: 'relative',
  },
  dialogTitle: {
    backgroundColor: '#565a61',
    display: 'block',
    padding: theme.spacing(3, 6, 2, 6),
    color: '#FFF',
    position: 'relative',
  },
  title: {
    display: 'block',
    fontSize: '2.0em',
    fontWeight: 'bold',
    color: '#FFF',
    marginBottom: '10px',
    paddingBottom: '35px',
    borderBottom: '2px solid rgba(255,255,255,.3)',
  },
  message: {
    fontSize: 'inherit',
    color: '#FFF',
  },
  button: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 196,
  },
  submitButton: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 196,
    marginLeft: 25
  },
  footer: {
    textAlign: 'center',
    backgroundColor: '#44474d',
    display: 'block',
    padding: theme.spacing(4, 6),
    minHeight: 38,
  },
  body: {
    display: 'block',
    overflow: 'auto',
    maxHeight: 553,
    backgroundColor: '#565a61',
    padding: 0
  },
  titleText1: {
    minWidth: 185,
    color: '#FFF',
  },
  titleText2: {
    minWidth: 185,
    color: '#FFF',
    marginTop: -25
  },
  bodyHeight: {
    display: 'block',
    overflow: 'auto',
    maxHeight: 518,
    backgroundColor: '#565a61',
  },
  fontWhite: {
    color: '#FFF',
  },
  fontWhite1: {
    color: '#FFF',
    marginTop: -30,
    marginLeft: 7
  },
  selectWithCounter: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1),
    marginTop: 20
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      overflowY: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'auto' : 'hidden',
      display: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'block' : 'flex',
    },
    '& .MuiDialogContent-root': {
      minWidth: '960px',
      maxHeight: '100%',
    },
  },
}))

const MCAXS110 = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const initParm = useSelector((state: RootState) => state.progressStatusChange.initParm)
  const selectionFlowList = useSelector((state: RootState) => state.progressStatusChange.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList)
  const initialValues = useSelector((state: RootState) => state.progressStatusChange.initialValues)

  const progressNameList = useSelector((state: RootState) => state.progressStatusChange.progressNameList)
  const fairueReasonList = useSelector((state: RootState) => state.progressStatusChange.fairueReasonList)
  const declineReasonList = useSelector((state: RootState) => state.progressStatusChange.declineReasonList)
  // 表示するかどうかを判断するフラグ
  const interviewDayShowList = useSelector((state: RootState) => state.progressStatusChange.interviewDayShowList)
  const fairueReasonShow = useSelector((state: RootState) => state.progressStatusChange.fairueReasonShow)
  const declineReasonShow = useSelector((state: RootState) => state.progressStatusChange.declineReasonShow)

  const selectionStepBefore = useSelector((state: RootState) => state.progressStatusChange.initialValues.selectionStep)
  const [selectionStep, setSelectionStep] = useState(initialValues.selectionStep)
  useEffect(()=>{
    setSelectionStep(initialValues.selectionStep)
  },[initialValues.selectionStep])

  const openDialogFlag = useSelector((state: RootState) => state.progressStatusChange.openDialogFlag)
  const recommendCheckDialogOpeFlag = useSelector((state: RootState) => state.progressStatusChange.recommendCheckDialogOpeFlag)
  const progressChangeBeforeCheckDialogOpeFlag = useSelector((state: RootState) => state.progressStatusChange.progressChangeBeforeCheckDialogOpeFlag)
  const cancelCheckDialogOpeFlag = useSelector((state: RootState) => state.progressStatusChange.cancelCheckDialogOpeFlag)
  const updateProgressFlag = useSelector((state: RootState) => state.progressStatusChange.updateProgressFlag)

  const updateProgressData = useSelector((state: RootState) => state.progressStatusChange.updateProgressData)

  // 1.5 "OK"ボタンを押下
  const handleSubmit = (values: any) => {
    const data: MCAXS110UpdateProgressRequest = {
      selectionManagementId: initParm.selectionManagementId.map(String), //選考管理ID
      progressStatusSettingId: values.selectionStep, // 進捗ステータス設定ID
      interviewDayInput: dateTimeToString(values.interviewDay) || '', // 面接日時入力
      fairueReasonInput: values.fairueReason, // 不合格理由入力
      declineReasonInput: values.declineReason, // 辞退理由入力
      otherInput: values.other != "" ? values.other : null, // その他入力
      reccomendUpdateFlag: '', // 推薦更新フラグ
      versionNumberSelectionManagement: initParm.versionNumberSelectionManagement, // sysバージョン情報（選考管理）
      versionNumberProgressStatusManagement: [], // sysバージョン情報（進捗ステータス管理）
      versionNumberProgressStatusManagementDetail: [], // sysバージョン情報（進捗ステータス管理詳細）
    }
    dispatch(setUpdateProgressData(data))
    // 1.5.2.1 推薦チェックを実施する
    const request: RecommendCheckRequest = {
      selectionManagementId: initParm.selectionManagementId, // 選考管理ID
      entryId: initParm.entryId, // エントリーID
      progressStatusSettingId: values.selectionStep, // 進捗ステータス設定ID
    }
    dispatch(recommendCheck(request))
  }

  useEffect(() => {
    if (updateProgressFlag) {
      // 処理を継続する
      dispatch(updateProgress(updateProgressData))
    }
  }, [updateProgressFlag])

  // 1.6 "キャンセルする"ボタン押下
  const handleCloseDialog = () => {
    dispatch(setCancelCheckDialogOpeFlag(true))
  }

  // 推薦チェックのメッセージの「OK」ボタン押下
  const handleRecommendCheckConfirm = () => {
    // 1.5.2.1.1.2.1　推薦更新フラグを"1"に設計する
    const data: MCAXS110UpdateProgressRequest = {
      selectionManagementId: updateProgressData.selectionManagementId, //選考管理ID
      progressStatusSettingId: updateProgressData.progressStatusSettingId, // 進捗ステータス設定ID
      interviewDayInput: updateProgressData.interviewDayInput, // 面接日時入力
      fairueReasonInput: updateProgressData.fairueReasonInput, // 不合格理由入力
      declineReasonInput: updateProgressData.declineReasonInput, // 辞退理由入力
      otherInput: updateProgressData.otherInput, // その他入力
      reccomendUpdateFlag: '1', // 推薦更新フラグ
      versionNumberSelectionManagement: updateProgressData.versionNumberSelectionManagement, // sysバージョン情報（選考管理）
      versionNumberProgressStatusManagement: updateProgressData.versionNumberProgressStatusManagement, // sysバージョン情報（進捗ステータス管理）
      versionNumberProgressStatusManagementDetail: updateProgressData.versionNumberProgressStatusManagementDetail, // sysバージョン情報（進捗ステータス管理詳細）
    }
    // 処理を継続する
    dispatch(updateProgress(data))
    dispatch(setRecommendCheckDialogClose(false))
  }

  // 推薦チェックのメッセージの「キャンセル」ボタン押下
  const handleRecommendCheckCancel = () => {
    dispatch(setRecommendCheckDialogClose(false))
  }

  // 進捗変更前チェックのメッセージの「OK」ボタン押下
  const handleProgressChangeBeforeCheckConfirm = () => {
    // 1.5.2.2.1.1.1.1　処理を継続する
    dispatch(updateProgress(updateProgressData))
    dispatch(setProgressChangeBeforeCheckDialogOpeFlag(false))
  }

  // 進捗変更前チェックのメッセージの「キャンセル」ボタン押下
  const handleProgressChangeBeforeCheckCancel = () => {
    dispatch(setProgressChangeBeforeCheckDialogOpeFlag(false))
  }

  // キャンセルチェックのメッセージの「OK」ボタン押下
  const handleCancelCheckConfirm = () => {
    dispatch(setCancelCheckDialogOpeFlag(false))
    dispatch(setDialogOpen(false))
  }

  // キャンセルチェックのメッセージの「キャンセル」ボタン押下
  const handleCancelCheckCancel = () => {
    dispatch(setCancelCheckDialogOpeFlag(false))
  }

  // 転職march #9605 start
  // モーダルクローズ後
  const onExitedHandle = () => {
    dispatch(initial())
  }
  // 転職march #9605 end

  return (
    <>
      <Dialog maxWidth='md' fullWidth={true} open={openDialogFlag} className={classes.dialog + ' mainDialog'}
        // 転職march #9605 start
        onExited={onExitedHandle}
        // 転職march #9605 end
      >
        <DialogContent className={`${classes.body}`}>
          <DialogTitle className={classes.dialogTitle}>
            <h1 className={classes.title}>選考ステップを変更</h1>
          </DialogTitle>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={MCAXS110Validation({
              selectionStep: selectionStep,
              interviewDayShowList: interviewDayShowList,
              fairueReasonShow: fairueReasonShow,
              declineReasonShow: declineReasonShow
            })}
            onSubmit={handleSubmit}
            render={({ values }) => (
              <Form onKeyDown={onKeyDown}>
                <div className={classes.form}>
                  <div>
                    <FieldGroup>
                      <React.Fragment key='selectionStep'>
                        <Grid item xs={12} className={classes.selectWithCounter}>
                          <Grid item xs={3}>
                            <Title
                              title='選考ステップ'
                              className={`${classes.titleText1}`}
                              required={true}
                            />
                          </Grid>
                          <Title title='：' className={classes.fontWhite} />
                          <Grid item xs={6}>
                            <Field
                              name={'selectionStep'}
                              label={'選考ステップ'}
                              component={MySelectSelectionStepMessage}
                              optionList={progressNameList}
                              onChange={setSelectionStep}
                              selectionStepBefore={selectionStepBefore}
                              selectionFlowList={selectionFlowList}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                      {interviewDayShowList.includes(values.selectionStep) ? (
                        <React.Fragment key='interviewDay'>
                          <Grid item xs={12} className={classes.selectWithCounter}>
                            <Grid item xs={3}>
                              <Title
                                title='面接日時'
                                className={`${classes.titleText1}`}
                                required={true}
                              />
                            </Grid>
                            <Title title='：' className={classes.fontWhite} />
                            <Grid item xs={6}>
                              <Field
                                name={'interviewDay'}
                                label='面接日時'
                                component={DataTimePickerCustDeadline}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : ('')}
                      {values.selectionStep == fairueReasonShow ? (
                        <React.Fragment key='fairueReason'>
                          <Grid item xs={12} className={classes.selectWithCounter}>
                            <Grid item xs={3}>
                              <Title
                                title='不合格理由'
                                className={`${classes.titleText1}`}
                                required={true}
                              />
                            </Grid>
                            <Title title='：' className={classes.fontWhite} />
                            <Grid item xs={6}>
                              <Field
                                name={'fairueReason'}
                                label={'不合格理由'}
                                placeholder='（以下から選択）'
                                component={MySelectMessage}
                                optionList={fairueReasonList}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : ('')}
                      {values.selectionStep == declineReasonShow ? (
                        <React.Fragment key='declineReason'>
                          <Grid item xs={12} className={classes.selectWithCounter}>
                            <Grid item xs={3}>
                              <Title
                                title='辞退理由'
                                className={`${classes.titleText1}`}
                                required={true}
                              />
                            </Grid>
                            <Title title='：' className={classes.fontWhite} />
                            <Grid item xs={6}>
                              <Field
                                name={'declineReason'}
                                label={'辞退理由'}
                                placeholder='（以下から選択）'
                                component={MySelectMessage}
                                optionList={declineReasonList}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : ('')}
                      {(values.selectionStep == fairueReasonShow && values.fairueReason == '99')
                        || (values.selectionStep == declineReasonShow && values.declineReason == '99') ? (
                        <React.Fragment key='other'>
                          <Grid item xs={12} className={classes.selectWithCounter}>
                            <Grid item xs={3}>
                              <Title title='その他' className={classes.titleText2} />
                            </Grid>
                            <Title title='：' className={classes.fontWhite1} />
                            <Grid item xs={9}>
                              <Field
                                label={'その他'}
                                name={'other'}
                                component={MyInputWithCounter}
                                maxAmount={30}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : ('')}
                    </FieldGroup>
                  </div>
                </div>
                < div className={classes.footer} >
                  <Button
                    variant="contained"
                    onClick={handleCloseDialog}
                    className={classes.button}>
                    キャンセルする
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}>
                    OK
                  </Button>
                </div >
                {/* 推薦チェック */}
                <Dialog open={recommendCheckDialogOpeFlag}>
                  <LoadingDialog className='overlay' />
                  <DialogTitle>{getMessage('MCAXS110-001')}</DialogTitle>
                  <DialogActions>
                    <Button onClick={handleRecommendCheckCancel}>
                      キャンセル
                    </Button>
                    <Button onClick={handleRecommendCheckConfirm} color='primary'>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* 進捗変更前チェック */}
                <Dialog open={progressChangeBeforeCheckDialogOpeFlag}>
                  <LoadingDialog className='overlay' />
                  <DialogTitle>{getMessage('MCAYS020-003', [values.message])}</DialogTitle>
                  <DialogActions>
                    <Button onClick={handleProgressChangeBeforeCheckCancel}>
                      キャンセル
                    </Button>
                    <Button onClick={handleProgressChangeBeforeCheckConfirm} color='primary'>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* キャンセルチェック */}
                <Dialog open={cancelCheckDialogOpeFlag}>
                  <LoadingDialog className='overlay' />
                  <DialogTitle><div
                    dangerouslySetInnerHTML={{
                      __html: getMessage('MCAXS110-005'),
                    }}
                  /></DialogTitle>
                  <DialogActions>
                    <Button onClick={handleCancelCheckCancel}>
                      キャンセル
                    </Button>
                    <Button onClick={handleCancelCheckConfirm} color='primary'>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            )
            }
          />
        </DialogContent >
      </Dialog >
    </>
  )
}

export default MCAXS110
