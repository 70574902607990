import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'
import { getMessage } from 'common/messageUtil'
import { FieldGroup } from 'sp/componentsHsc'
import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import GroupOptions from '../GroupOptions'
import { QuestionnaireCondition } from 'componentsHsc/AdvancedSearchJobChange/Config/itemConfig'
import TextField from './TextField'
import Toggle from './Toggle'
import UncheckableToggle from '../UncheckableToggle'
import { setSearchCondition } from 'reducers/entryListReducer'
import { SearchCondition, toEntryList, innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { onKeyDown } from 'common/generalUtil'
import * as yup from 'yup'
import { ModalDialog } from 'sp/components/ModalDialog/ModalDialog'

const optionList = [
  { label: '回答あり', value: '0' },
  { label: '回答なし', value: '1' },
]

const typeList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: SearchCondition
}

const EntryReplyModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()

  const { questionaire, entryReplyModalStateJobChange } = useSelector(
    (state: RootState) => state.itemSpecification
  )

  const handleSave = (data: any) => {
    const newForm = { ...form }
    const newConditionList = (data.conditionList as QuestionnaireCondition[])
      .filter(i => i.answerConditionFlag !== '')
      .map(i => ({
        ...i,
        questionOption: i.questionOption ? i.questionOption : '',
        answerText: i.answerConditionFlag === '1' ? '' : i.answerText,
        answerCheckBoxObj:
          i.answerConditionFlag === '1'
            ? []
            : [...i.answerCheckBoxObj].sort((a, b) => {
              //選択肢の並びを画面順にソート
              const orderObjArray = questionaire.find(j => j.id === i.questionNumber
                && j.questionOption === i.questionOption
                && j.title === i.questionTitle
                && j.type === i.questionTypeCode
              )
              if (orderObjArray) {
                return innerSortFunc(
                  { answerCheckBoxName: a.label },
                  { answerCheckBoxName: b.label },
                  orderObjArray.optionList.map(j => ({ answerCheckBoxName: j.label }))
                )
              } else {
                return 0
              }
            }),
      }))
    if (newConditionList.length > 0) {
      const newData = { ...data }
      newData.conditionList = newConditionList
      newForm.questionnaireObj = newData
    } else {
      newForm.questionnaireObj = null
    }
    dispatch(setSearchCondition(toEntryList(newForm)))
    onClose()
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    onClose()
  }

  const fieldCheck = (num: number, values: any) => {
    if (values.conditionList[num].answerConditionFlag !== undefined) {
      if (values.conditionList !== null && values.conditionList[num]) {
        //テキスト入力またはチェックボックスがあります。
        if (
          (values.conditionList[num].answerText !== '' ||
            values.conditionList[num].answerCheckBoxObj.length > 0) &&
          values.conditionList[num].answerConditionFlag !== '1'
        ) {
          Object.defineProperty(
            values.conditionList[num],
            'answerConditionFlag',
            { value: '0' }
          )
        }
        //標準チェックボックスの状態
        if (values.conditionList[num].answerConditionFlag === '') {
          return false
        }
        return values.conditionList[num].answerConditionFlag !== '0'
      }
    }
  }

  let mySubmitForm: ((arg0: any) => void) | null = null;

  const bindSubmit = (submitForm: any) => {
    mySubmitForm = submitForm
  }

  const handleSubmitMyForm = (e: any) => {
    if (mySubmitForm) {
      mySubmitForm(e);
    }
  };

  return (
    <>

      <ModalDialog isOpen={open} className='modal'>
        <div className='modal-content'>
          <div className='modal-title-wrap'>
            <h3 className='modal-title'>企業から応募者への質問内容回答を指定</h3>
            <span className='close-btn' onClick={handleCancelOk}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use xlinkHref='#form__modal_close'></use>
              </svg>
            </span>
          </div>
          <Formik
            initialValues={entryReplyModalStateJobChange}
            enableReinitialize
            onSubmit={handleSave}
            render={formikProps => {
              const { values } = formikProps
              bindSubmit(formikProps.submitForm)
              return (
                <>
                  {open && (
                    <div className='modal-section'>
                      <Form onKeyDown={onKeyDown}>
                        {/* AND/OR */}
                        <FastField
                          name='typeCode'
                          component={Toggle}
                          optionList={typeList}
                          row
                          target='questionCondition'
                        />
                        {questionaire.map((item, index) => (
                          <>
                            <div className='form__ttl_modal'>{'Q' + item.id + '. ' + item.title}</div>
                            <div className={'form__body_modal'} id={item.id} >
                              <FieldGroup key={item.title} style={{ margin: "initial" }}>
                                {/* 回答なし/あり */}
                                <Field
                                  name={`conditionList[${index}].answerConditionFlag`}
                                  optionList={optionList}
                                  row
                                  component={UncheckableToggle}
                                  target='answerCondition'
                                  targetIndex={index}
                                />
                                {/* 回答欄 */}
                                {item.type === '1' ? (
                                  < Field
                                    name={`conditionList[${index}].answerText`}
                                    component={TextField}
                                    multiline
                                    placeholder='回答を入力'
                                    disabled={fieldCheck(index, values)}
                                    validate={async (value: any) => {
                                      let errorFlg = false
                                      let message = ''
                                      await yup.object().shape({
                                        [`conditionList[${index}].answerText`]: yup.string()
                                      }).validate({
                                        [`conditionList[${index}].answerText`]: value
                                      }).catch(err => {
                                        errorFlg = true
                                        message = err.message.props.children
                                      })
                                      if (errorFlg) {
                                        return message
                                      } else {
                                        return null
                                      }
                                    }}
                                  />
                                ) : item.type === '2' || item.type === '3' ? (
                                  <Field
                                    name={`conditionList[${index}].answerCheckBoxObj`}
                                    component={GroupOptions}
                                    optionList={item.optionList}
                                    row
                                    disabled={fieldCheck(index, values)}
                                    groupClassName={"form__body_item_modal"}
                                  />
                                ) : (
                                      <Field
                                        name={`conditionList[${index}].answerCheckBoxObj`}
                                        component={GroupOptions}
                                        optionList={item.optionList}
                                        row
                                        disabled={fieldCheck(index, values)}
                                        groupClassName={"form__body_item_modal"}
                                      />
                                    )}
                              </FieldGroup>
                            </div>
                          </>
                        ))}
                      </Form>
                    </div>
                  )}
                </>
              )
            }}
          />
          {open && (
            <div className={'modal-section-btn'}>
              <button
                type='button'
                className={'btn__cancel'}
                onClick={handleCancelOk}>
                キャンセル
              </button>
              <button
                type='button'
                className={'btn__blue_ok'}
                onClick={handleSubmitMyForm}>
                OK
              </button>
            </div>
          )}
        </div>
      </ModalDialog>
    </ >
  )
}

export default EntryReplyModal
