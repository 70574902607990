import { Grid, TextField as MuiTextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'components';
import { FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { validationMessageSet } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  textCounter: {
    float: 'right',
    minWidth: 120,
    textAlign: 'right',
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  inputWithCounter: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1, 1, 0, 1),
  },
  inputWithCounter1: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(0, 1),
  },
  title: {
    minWidth: 185,
  },
  fontWhite: {
    color: '#FFF',
    // [phase2] start
    marginTop: '-30px',
    // [phase2] end
  },
  hidden: {
    display: 'hidden',
  },
  input: {
    fontSize: 14,
    '&::-ms-clear, ::-ms-reveal': {
      display: 'none',
    },
  },
  backgroundColor: {
    backgroundColor: '#3f71bd',
  },
  // [phase2] start
  MuiTextFieldStyle: {
    backgroundColor: 'white',
    borderRadius: '5px',
    '& textarea': {
      overflow: 'auto',
      maxHeight: '50px'
    },
    '& .MuiInputBase-fullWidth': {
      height: '70px',
    },
    '& p': {
      backgroundColor: '#3f71bd',
      margin: theme.spacing(0, 0, 0, 0),
      padding: '3px 14px 0 14px',
    }
  },
  // [phase2] end
  helperText: {
    fontSize: '11px',
    lineHeight: '1em',
    color: '#e53935',
    margin: '4px 14px',
  },
  fontWhite1: {
    width: '22px'
  },
  // 次期開発12月 #51829 start
  grid: {
    width: '90%',
  },
  inputDeleted: {
    color:'black',
  },
  backgroundColorDeleted: {
    width: '90%',
    backgroundColor: '#3f71bd',
  },
  MuiTextFieldStyleDeleted: {
    backgroundColor: 'lightGrey',
    borderRadius: '5px',
    '& textarea': {
      overflow: 'auto',
      maxHeight: '50px'
    },
    '& .MuiInputBase-fullWidth': {
      height: '70px',
    },
    '& p': {
      backgroundColor: '#3f71bd',
      margin: theme.spacing(0, 0, 0, 0),
      padding: '3px 14px 0 14px',
    }
  },
  // 次期開発12月 #51829 end
}))

interface Props extends FieldProps {
  name?: string
  title: string
  maxAmount: number
  item: string
  onChange?: (value: string) => void
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
  // 次期開発12月 #51829 end
}

const InputWithCounter = ({
  field,
  form,
  title,
  maxAmount,
  onChange,
  item,
  // 次期開発12月 #51829 start
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
  // 次期開発12月 #51829 end
  ...others
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form

  const [error, setError] = useState({isError: false, errorMsg: validationMessageSet.maxLength({max: maxAmount,})})
  // 次期開発12月 #51829 start
  const [errorChecked, setErrorChecked] = useState({isError: false, errorMsg: validationMessageSet.maxLength({max: maxAmount,})})
  const [nameValue,setNameValue] = useState('')
  useEffect(() => {
    if ((prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') && selectDoubleFalg == true) {
      if(clearRecruitmentManageFlag) {
        setNameValue(form.values[name])
        form.setFieldValue(name, '')
        setError({isError: false, errorMsg: validationMessageSet.maxLength({max: maxAmount,})})
      } else {
        form.setFieldValue(name, nameValue)
        setError(errorChecked)
      }
    }
  },[clearRecruitmentManageFlag])
  // 次期開発12月 #51829 end

  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event.target.value)
    }
    form.setFieldValue(name, event.target.value)
    if (event.target.value.length > maxAmount) {
      setError({ errorMsg: error.errorMsg, isError: true })
      // 次期開発12月 #51829 start
      setErrorChecked({ errorMsg: error.errorMsg, isError: true })
      // 次期開発12月 #51829 end
    } else {
      setError({ errorMsg: error.errorMsg, isError: false })
      // 次期開発12月 #51829 start
      setErrorChecked({ errorMsg: error.errorMsg, isError: false })
      // 次期開発12月 #51829 end
    }
  }

  return (
    <React.Fragment>
      {/* 次期開発12月 #51829 start */}
      <Grid container className={prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030' ? (selectDoubleFalg == false ? (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor) : (null != item && null != value && item.toString() === value.toString() && clearRecruitmentManageFlag == false ? classes.grid : classes.backgroundColorDeleted)) : (null != item && null != value && item.toString() === value.toString() ? undefined : classes.backgroundColor)}>
      {/* 次期開発12月 #51829 end */}
        <Grid item xs={12} className={classes.inputWithCounter}>
          <Grid item xs={3}>
            <Title
              title={title}
              className={`${classes.title} ${classes.fontWhite}`}
            />
          </Grid>
          <Title title='：' className={classes.fontWhite} />
          <Grid item xs={9}>
            <MuiTextField
              variant='outlined'
              fullWidth
              type='text'
              inputProps={{ spellCheck: false }}
              InputProps={{
                // 次期開発12月 #51829 start
                classes: clearRecruitmentManageFlag ? {input:classes.inputDeleted} :{ input: classes.input },
                // 次期開発12月 #51829 end
              }}
              error={error.isError || Boolean(errors[name] && touched[name])}
              {...field}
              {...others}
              // 次期開発12月 #51829 start
              value={clearRecruitmentManageFlag ? '項目のデータを一括削除します' :value}
              // 次期開発12月 #51829 end
              helperText={errors[name] && touched[name] ? errors[name] : null}
              onChange={handleChange}
              // [phase2] start
              multiline
              rowsMax={3}
              // 次期開発12月 #51829 start
              className = {clearRecruitmentManageFlag ? classes.MuiTextFieldStyleDeleted :classes.MuiTextFieldStyle}
              disabled={clearRecruitmentManageFlag}
              // 次期開発12月 #51829 end
              // [phase2] end
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.inputWithCounter1}>
          <Grid item xs={3}>
            <Title
              title={''}
              className={`${classes.title} ${classes.fontWhite}`}
            />
          </Grid>
          <Title title='' className={`${classes.fontWhite} ${classes.fontWhite1}`} />
          <Grid item xs={9}>
            {(!Boolean(errors[name] && touched[name]) && error.isError) ? <><p className={classes.helperText}>{error.errorMsg}</p></> : null}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.textCounter}>
          <Grid item xs={1}></Grid>
          <Grid item xs={12} >
            <Typography
              gutterBottom
              variant='h6'
              style={{ color: (theme.palette as any).white }}>
              {`入力${value ? value.length : 0}/最大${maxAmount}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InputWithCounter
