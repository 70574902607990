import { TextField as MuiTextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BorderColor } from '@material-ui/icons'
import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { validationMessageSet } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  inputWithCounter: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1, 1, 0, 1),
  },
  inputWithCounter1: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(0, 1),
  },
  title: {
    minWidth: 185,
  },
  input: {
    minHeight: '226px',
    fontSize: 16,
    '&::-ms-clear, ::-ms-reveal': {
      display: 'none',
    },
  },
  backgroundColor: {
    //    backgroundColor: '#3f71bd',
  },
  MuiTextFieldStyle: {
    backgroundColor: 'white',
    borderRadius: '5px',
    minHeight: '263px',
    '& textarea': {
      overflow: 'auto',
    },
    '& .MuiInputBase-fullWidth': {
      lineHeight: '30px',
    },
    '& p': {
      backgroundColor: '#565a61',
      margin: theme.spacing(0, 0, 0, 0),
      padding: '3px 14px 0 14px',
    },
  },
  helperText: {
    fontSize: '11px',
    lineHeight: '1em',
    color: '#e53935',
    margin: '4px 14px',
  },
  grid: {
    //    width: '90%',
  },
  inputDeleted: {
    color: 'black !important',
    fontSize: 16,
    backgroundColor: 'lightGrey !important',
    border: 'none !important',
  },
  backgroundColorDeleted: {
    //    width: '90%',
    //    backgroundColor: '#3f71bd',
  },
  MuiTextFieldStyleDeleted: {
    backgroundColor: 'lightGrey',
    borderRadius: '5px',
    '& textarea': {
      overflow: 'auto',
      backgroundColor: 'lightGrey !important',
    },
    '& .MuiInputBase-fullWidth': {
      height: '263px',
      backgroundColor: 'lightGrey !important',
    },
    '& p': {
      //      backgroundColor: '#3f71bd',
      margin: theme.spacing(0, 0, 0, 0),
      padding: '3px 14px 0 14px',
    },
  },
}))

interface Props extends FieldProps {
  name?: string
  title: string
  maxAmount: number
  item: string
  onChange?: (value: string) => void
  clearRecruitmentManageFlag: boolean
  selectDoubleFalg: boolean
  prevScreenId: string
}

const InputWithCounter = ({
  field,
  form,
  title,
  maxAmount,
  onChange,
  item,
  clearRecruitmentManageFlag,
  selectDoubleFalg,
  prevScreenId,
  ...others
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form

  const [error, setError] = useState({
    isError: false,
    errorMsg: validationMessageSet.maxLength({ max: maxAmount }),
  })
  const [errorChecked, setErrorChecked] = useState({
    isError: false,
    errorMsg: validationMessageSet.maxLength({ max: maxAmount }),
  })
  const [nameValue, setNameValue] = useState('')
  useEffect(() => {
    if (
      (prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030') &&
      selectDoubleFalg == true
    ) {
      if (clearRecruitmentManageFlag) {
        setNameValue(form.values[name])
        form.setFieldValue(name, '')
        setError({
          isError: false,
          errorMsg: validationMessageSet.maxLength({ max: maxAmount }),
        })
      } else {
        form.setFieldValue(name, nameValue)
        setError(errorChecked)
      }
    }
  }, [clearRecruitmentManageFlag])

  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event.target.value)
    }
    form.setFieldValue(name, event.target.value)
    if (event.target.value.length > maxAmount) {
      setError({ errorMsg: error.errorMsg, isError: true })
      setErrorChecked({ errorMsg: error.errorMsg, isError: true })
    } else {
      setError({ errorMsg: error.errorMsg, isError: false })
      setErrorChecked({ errorMsg: error.errorMsg, isError: false })
    }
  }

  return (
    <React.Fragment>
      <div
        className={
          prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030'
            ? selectDoubleFalg == false
              ? null != item &&
                null != value &&
                item.toString() === value.toString()
                ? undefined
                : classes.backgroundColor
              : null != item &&
                null != value &&
                item.toString() === value.toString() &&
                clearRecruitmentManageFlag == false
              ? classes.grid
              : classes.backgroundColorDeleted
            : null != item &&
              null != value &&
              item.toString() === value.toString()
            ? undefined
            : classes.backgroundColor
        }>
        <div className={'form__sttl_modal mb15'}>{title}</div>
        <MuiTextField
          variant='outlined'
          fullWidth
          type='text'
          inputProps={{ spellCheck: false }}
          InputProps={{
            classes: clearRecruitmentManageFlag
              ? { input: classes.inputDeleted }
              : { input: classes.input },
          }}
          error={error.isError || Boolean(errors[name] && touched[name])}
          {...field}
          {...others}
          value={
            clearRecruitmentManageFlag ? '項目のデータを一括削除します' : value
          }
          helperText={errors[name] && touched[name] ? errors[name] : null}
          onChange={handleChange}
          multiline
          rowsMax={3}
          className={
            clearRecruitmentManageFlag
              ? classes.MuiTextFieldStyleDeleted
              : classes.MuiTextFieldStyle
          }
          disabled={clearRecruitmentManageFlag}
        />
        <div>
          {!Boolean(errors[name] && touched[name]) && error.isError ? (
            <>
              <p className={classes.helperText}>{error.errorMsg}</p>
            </>
          ) : null}
        </div>
        <div className={'form__count'}>
          <Typography gutterBottom className={'form__count-word'}>
            {`入力${value ? value.length : 0}/最大${maxAmount}`}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InputWithCounter
