import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Area } from 'utils/misc'
import CalendarCell from './CalenderCell'
import { InterviewLocation } from './viewConig'
import { formatTemplate } from 'utils/contants'
moment.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles(theme => ({
  cursorAuto: {
    cursor: 'auto',
  },
  indexView: {
    zIndex: '0 !important' as any,
  },

}))

export interface Reservation {
  name: string
  start: string
  end: string
  location: string
  charge: string
}

export interface CandidateDay {
  interviewDate: Date | null
  interviewLocation: string
  interviewLocationId: string
  charge: string
  datePickerRef: React.RefObject<DatePicker>
  containerRef: React.RefObject<HTMLDivElement>
  fromCalendar?: boolean
}

interface selectDateSlot {
  date: Date
  slot: string
  slot30: string
  isSelected: boolean
}

interface Props {
  allowedArea: Area[]
  notAllowedArea: Area[]
  reservationList: Reservation[]
  isPermitOther: boolean
  lengthLimit: number
  selected: CandidateDay[]
  setSelected: (selected: CandidateDay[]) => void
  interviewLocation: InterviewLocation[]
}


const Calendar = ({
  allowedArea,
  notAllowedArea,
  reservationList,
  lengthLimit,
  selected,
  setSelected,
  interviewLocation,
}: Props) => {
  const classes = useStyles()
  const weekday = ['日', '月', '火', '水', '木', '金', '土']
  const now = new Date()

  const [dates, setDates] = useState<string[]>([])
  const [showDates, setShowDates] = useState<Date[]>([])
  const [selectedDates, setSelectedDates] = useState<selectDateSlot[]>([])

  useEffect(() => {
    let localDates: string[] = []
    let localShowDates: Date[] = []
    for (let i = 0; i < 90; i++) {
      let showDate = new Date()
      showDate.setDate(now.getDate() + i)
      let month = showDate.getMonth() + 1
      let day = showDate.getDate()
      let dayofweek = showDate.getDay()
      let firstday = month + '/' + day + '(' + weekday[dayofweek] + ')'
      //setDates(dates => [...dates, firstday])
      //setShowDates(showDates => [...showDates, showDate])
      localDates.push(firstday)
      localShowDates.push(showDate)
      //dates.push(firstday)
      //showDates.push(showDate)
    }
    setDates(localDates)
    setShowDates(localShowDates)
    let localSelctedDates: selectDateSlot[] = []
    localShowDates.map((date) => (timeSlots.map((slot) => (
      localSelctedDates.push({
        date: date, slot: slot, slot30: String(Number(slot.split(':')[0])).padStart(2, '0') + ':' + (Number(slot.split(':')[1]) + 25),
        isSelected: isSelectedDate(date, slot)
      })
    ))))
    setSelectedDates(localSelctedDates)
  }, [])



  const getSelectDateSlot = (date: Date, slot: string, slot30: string, isSelected: boolean): selectDateSlot => {
    const newSelectedDate: selectDateSlot = { date: date, slot: slot, slot30: slot30, isSelected: isSelected }
    return newSelectedDate
  }
  useEffect(() => {
    if (!selected) {
      return
    }
    setSelectedDates(selectedDates => selectedDates.map((item) => getSelectDateSlot(item.date, item.slot, item.slot30, false)))
    selected.forEach(item => {
      if (item.interviewDate) {
        const dt = item.interviewDate
        const slot = item.interviewDate.getHours().toString().padStart(2, '0') + ":" + item.interviewDate.getMinutes().toString().padStart(2, '0')
        const findIndex = selectedDates.findIndex((item) => {
          return item.date.getFullYear() === dt.getFullYear() && item.date.getMonth() == dt.getMonth() &&
            item.date.getDate() === dt.getDate() && item.slot <= slot && slot <= item.slot30
        })
        if (findIndex > -1) {
          setSelectedDates(selectedDates => selectedDates.map((item, i) => i === findIndex ? getSelectDateSlot(item.date, item.slot, item.slot30, true) : item))
        }
      }
    })
  }, [selected])

  const timeView = [
    '09:00',
    '',
    '10:00',
    '',
    '11:00',
    '',
    '12:00',
    '',
    '13:00',
    '',
    '14:00',
    '',
    '15:00',
    '',
    '16:00',
    '',
    '17:00',
    '',
    '18:00',
    '',
    '19:00',
    '',
    '20:00',
    '',
    '21:00',
    '',
    '22:00',
    '',
  ]
  const timeSlots = [
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
  ]

  const isSelectedDate = (currentDate: Date, currentSlot: string): boolean => {

    if (!selected) {
      return false
    }
    const month = currentDate.getMonth().toString().padStart(2, '0')
    const day = currentDate.getDay().toString().padStart(2, '0')
    const currentDayTimeString = currentDate.getFullYear + '-' + month + '-' + day + 'T' + currentSlot
    const thirtyMinutesLaters = currentDate.getFullYear + '-' + month + '-' + day + 'T' + String(Number(currentSlot.split(':')[0])).padStart(2, '0') + ':' + (Number(currentSlot.split(':')[1]) + 25)

    selected.forEach(item => {
      if (
        currentDayTimeString.split('T')[0] ===
        moment(item.interviewDate)
          .format(formatTemplate.dateWithHourMinute)
          .split('T')[0]
      ) {
        if (
          currentDayTimeString.split('T')[1] <=
          moment(item.interviewDate)
            .format(formatTemplate.dateWithHourMinute)
            .split('T')[1] &&
          moment(item.interviewDate)
            .format(formatTemplate.dateWithHourMinute)
            .split('T')[1] <= thirtyMinutesLaters.split('T')[1]
        ) {
          return true
        }
      }
    })

    return false;
  }

  const setSelectedDate = (date: Date, e: any): void => {
    if (selected.length < lengthLimit) {
      setSelected([
        ...selected,
        {
          interviewDate: date,
          interviewLocation:
            interviewLocation.length > 0 ? interviewLocation[0].name : '',
          interviewLocationId:
            interviewLocation.length > 0 ? String(interviewLocation[0].id) : '',
          charge: '',
          datePickerRef: React.createRef(),
          containerRef: React.createRef(),
          fromCalendar: true,
        },
      ])
    }
  }

  return (
    <div>
      <div className='box-interview-calendar'>
        <div className='makeStyles-calendar-530'>
          <div className='fc fc-ltr fc-unthemed'>
            <div className='fc-view-container'>
              <div className={`fc-view fc-timeGridWeek-view fc-timeGrid-view ${classes.indexView}`}>
                <table className='makeStyles-scheduleTable'>
                  <tbody>
                    <tr>
                      <th>時間</th>
                      {timeView.map((slot, xindex) => (
                        <th key={xindex}>{slot}</th>
                      ))}
                    </tr>
                    {dates.map((date, index) => (
                      <tr key={index}>
                        <td key={date}>{date}</td>
                        {timeSlots.map((slot, sIndex) => (
                          <CalendarCell
                            key={date + slot}
                            allowedArea={allowedArea}
                            notAllowedArea={notAllowedArea}
                            reservationList={reservationList}
                            isSelected={selectedDates[index * timeSlots.length + sIndex].isSelected}
                            setSelectedDate={setSelectedDate}
                            date={date}
                            slot={slot}
                            year={showDates[index].getFullYear()}
                          />
                        )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='legend'>
          <span className='applicant-preferred'><label className={classes.cursorAuto}>応募者希望</label></span>
          <span className='applicant-ng'><label className={classes.cursorAuto}>応募者NG</label></span>
          <hr />
          <span className='on-icon icon-candidateDate'><label className={classes.cursorAuto}>提示日</label></span>
          <span className='on-icon icon-planned'><label className={classes.cursorAuto}>予定あり</label></span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Calendar)
