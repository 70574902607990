import React, { useState } from 'react'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers';
import { badgeFormatComma } from 'common/generalUtil';
import { submitValue } from 'pages/MCAZS040/formConfig';
import {
  setMessageDraftListSort,
  setButtonActiveFlag,
  setMessageDraftListPage,
  setPageChangeFlag,
  setSelectedFlag,
  initMessageDraftListSearchCondition,
  setMessageDustboxListPage,
  setSelectedDustboxFlag,
  setButtonActiveDustboxFlag,
  setMessageDustboxListSort,
  initMessageDustboxListSearchCondition,
  setMessageReservationListSort,
  setButtonActiveFlagMCAZS030,
  initMessageReservationListSearchCondition,
  setMessageReservationListPage,
  setSelectedFlagMCAZS030,
  setMessageOutBoxListSort,
  setOutBoxButtonActiveFlag,
  setOutBoxSelectedFlag,
  initMessageOutBoxListSearchCondition,
  setMessageOutBoxListPage,
  setMessageReceiveListSort,
  initMessageReceiveListSearchCondition,
  setReceiveButtonActiveFlag,
  setReceiveSelectedFlag,
  setMessageReceiveListPage,
  // MCB ロット３　start
  setMcbReserveCount,
  setMcbDraftCount,
  // MCB ロット３　end
} from 'reducers/messageListReducer'
import { dustboxSubmitValue } from 'pages/MCAZS050/formConfig';
import { submitValueMCAZS030 } from 'pages/MCAZS030/formConfig';
import { submitCondition } from 'pages/MCAZS020/formConfig';
import { receiveSubmitValue } from 'pages/MCAZS010/formConfig';
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { ConfirmDialog, ConfirmDialogProps } from 'components/ConfirmDialog'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

interface Props {
  pageValue: number
}

const TabForMessage = ({ pageValue }: Props) => {

  const pageChangeFlag = useSelector((state: RootState) => state.messageList.pageChangeFlag)
  const recruitmentManagementDivisions = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const dispatch = useDispatch()

  // メッセージ入力ダイアログコントロール
  const { isMessageInputDialogOpen } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<ConfirmDialogProps | undefined>()

  const handleClickInbox = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }

    event.preventDefault()
    dispatch(setMessageReceiveListSort('0'))
    dispatch(setReceiveButtonActiveFlag(0))
    dispatch(setReceiveSelectedFlag(0))
    dispatch(setMessageReceiveListPage(1))
    dispatch(initMessageReceiveListSearchCondition({ ...receiveSubmitValue, recruitmentManagementDivision: recruitmentManagementDivisions }))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    history.push(routeList.messageInBox)
  }
  // ===========================================MCAZS020 START=================================================
  const handleClickOutbox = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    event.preventDefault()
    dispatch(setMessageOutBoxListSort('0'))
    dispatch(setOutBoxButtonActiveFlag(0))
    dispatch(setOutBoxSelectedFlag(0))
    dispatch(setMessageOutBoxListPage(1))
    dispatch(
      initMessageOutBoxListSearchCondition({
        ...submitCondition,
        recruitmentManagementDivision: recruitmentManagementDivisions,
      })
    )
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    history.push(routeList.messageOutBox)
  }
  // ===========================================MCAZS020 END=================================================
  // ===========================================MCAZS030 START=================================================
  const handleClickReservation = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    event.preventDefault()
    dispatch(setMessageReservationListSort('0'))
    dispatch(setButtonActiveFlagMCAZS030(0))
    dispatch(setSelectedFlagMCAZS030(0))
    dispatch(setMessageReservationListPage(1))
    const operationValues = {
      searchTargetList: submitValueMCAZS030.searchTargetList,
      searchWordText: submitValueMCAZS030.searchWordText,
      reserveFromDate: submitValueMCAZS030.reserveFromDate,
      reserveToDate: submitValueMCAZS030.reserveToDate,
      destinationNavi: submitValueMCAZS030.destinationNavi,
      destinationMail: submitValueMCAZS030.destinationMail,
      attachmentExist: submitValueMCAZS030.attachmentExist,
      attachmentNot: submitValueMCAZS030.attachmentNot,
      onlyBulkSend: submitValueMCAZS030.onlyBulkSend,
      sort: submitValueMCAZS030.sort,
      countPage: 1,
      optionFlag: '0',
      recruitmentManagementDivision: recruitmentManagementDivisions,
      replyNecessaryCheckOn: submitValueMCAZS030.replyNecessaryCheckOn,
      replyNecessaryCheckOff: submitValueMCAZS030.replyNecessaryCheckOff,
      replyLimitFromDate: submitValueMCAZS030.replyLimitFromDate,
      replyLimitToDate: submitValueMCAZS030.replyLimitToDate,
    }
    dispatch(initMessageReservationListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    history.push(routeList.messageReservation)
  }
  // ===========================================MCAZS030 END=================================================
  // ================================================ MCAZS040 START========================================
  const handleClickDraft = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    event.preventDefault()
    dispatch(setMessageDraftListSort('0'))
    dispatch(setButtonActiveFlag(0))
    dispatch(setSelectedFlag(0))
    dispatch(setMessageDraftListPage(1))
    const operationValues = {
      searchTargetList: submitValue.searchTargetList,
      searchWordText: submitValue.searchWordText,
      updateFromDate: submitValue.updateFromDate,
      updateToDate: submitValue.updateToDate,
      attachmentExist: submitValue.attachmentExist,
      attachmentNot: submitValue.attachmentNot,
      onlyBulkSend: submitValue.onlyBulkSend,
      sort: submitValue.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivisions,
      replyNecessaryCheckOn: submitValue.replyNecessaryCheckOn,
      replyNecessaryCheckOff: submitValue.replyNecessaryCheckOff,
      replyLimitFromDate: submitValue.replyLimitFromDate,
      replyLimitToDate: submitValue.replyLimitToDate,
    }
    dispatch(initMessageDraftListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    history.push(routeList.messageDraft)
  }
  // ===========================================MCAZS040 END=================================================
  // ================================================ MCAZS050 START========================================
  const handleClickDustbox = async (event: any) => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(magiContants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }
    event.preventDefault()
    dispatch(setMessageDustboxListSort('0'))
    dispatch(setButtonActiveDustboxFlag(0))
    dispatch(setSelectedDustboxFlag(0))
    dispatch(setMessageDustboxListPage(1))
    const operationValues = {
      searchTargetList: dustboxSubmitValue.searchTargetList,
      searchWordText: dustboxSubmitValue.searchWordText,
      deleteFromDate: dustboxSubmitValue.deleteFromDate,
      deleteToDate: dustboxSubmitValue.deleteToDate,
      classificationReceived: dustboxSubmitValue.classificationReceived,
      classificationSent: dustboxSubmitValue.classificationSent,
      classificationReserved: dustboxSubmitValue.classificationReserved,
      classificationDraft: dustboxSubmitValue.classificationDraft,
      sort: dustboxSubmitValue.sort,
      countPage: 1,
      selectedFlag: 0,
      recruitmentManagementDivision: recruitmentManagementDivisions,
    }
    dispatch(initMessageDustboxListSearchCondition(operationValues))
    if (pageChangeFlag === 0) {
      dispatch(setPageChangeFlag(1))
    } else {
      dispatch(setPageChangeFlag(0))
    }
    // MCB ロット３　start
    dispatch(setMcbReserveCount(null))
    dispatch(setMcbDraftCount(null))
    // MCB ロット３　end
    history.push(routeList.messageDustBox)
  }
  // ================================================ MCAZS050 END========================================


  const receiveUnReadMsgCount = useSelector((state: RootState) => state.messageList.receiveCount)
  const reserveMsgCount = useSelector((state: RootState) => state.messageList.reserveCount)
  const draftMsgCount = useSelector((state: RootState) => state.messageList.draftCount)
  return (
    <>
      <ul>
        <li>
          <a href={"#!"}
            onClick={handleClickInbox}
            // 次期開発5月向#58931 start
            id={'mcazs010Reservation'}
            // 次期開発5月向#58931 end
            className={
              pageValue === 0
                ? `${'btn icon-inbox current'}`
                : `${'btn icon-inbox '}`
            }>
            受信トレイ{
              receiveUnReadMsgCount >= 1 ?
                <i>{badgeFormatComma(receiveUnReadMsgCount)}</i>
                : ""}
          </a>
        </li>
        <li>
          <a href={"#!"}
            onClick={handleClickOutbox}
            // 次期開発5月向#58931 start
            id={'mcazs020Reservation'}
            // 次期開発5月向#58931 end
            className={
              pageValue === 1
                ? `${'btn icon-outbox current'}`
                : `${'btn icon-outbox '}`
            }>
            送信トレイ
        </a>
        </li>
        <li>
          <a href={"#!"}
            onClick={handleClickReservation}
            id={'mcazs030Reservation'}
            className={
              pageValue === 2
                ? `${'btn icon-reservation current'}`
                : `${'btn icon-reservation '}`
            }>
            送信予約{
              reserveMsgCount >= 1 ?
                <i>{badgeFormatComma(reserveMsgCount)}</i>
                : ""}
          </a>
        </li>
        <li>
          <a href={"#!"}
            onClick={handleClickDraft}
            // 次期開発5月向#58931 start
            id={'mcazs040Reservation'}
            // 次期開発5月向#58931 end
            className={
              pageValue === 3
                ? `${'btn icon-draft current'}`
                : `${'btn icon-draft '}`
            }>
            下書き{
              draftMsgCount >= 1 ?
                <i>{badgeFormatComma(draftMsgCount)}</i>
                : ""}
          </a>
        </li>
        <li>
          <a href={"#!"}
            onClick={handleClickDustbox}
            // 次期開発5月向#58931 start
            id={'mcazs050Reservation'}
            // 次期開発5月向#58931 end
            className={
              pageValue === 4
                ? `${'btn icon-dustbox current'}`
                : `${'btn icon-dustbox '}`
            }>
            ゴミ箱
        </a>
        </li>
      </ul>
      {/* メッセージ有無チェック */}
      {confirmDialogConfig && <ConfirmDialog {...confirmDialogConfig} />}
    </>
  )
}
export default TabForMessage
