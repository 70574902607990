import React from 'react'
import { FieldProps } from 'formik'
import ReactSelect from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlSize: {
      width: '100%',
      marginTop: '0px',
    },
  })
)

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  placeholder?: string
  className?: string
  isSearchable?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  menuPlacement?: String
  isMulti?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean;
}

export default ({
  isMulti,
  className,
  optionList,
  noOptionsMessage,
  onChange,
  placeholder,
  isSearchable,
  isDisabled,
  isClearable,
  menuPlacement,
  defaultValue,
  field,
  form,
}: Props) => {
  const { name, value } = field
  const classes = useStyles()
  React.useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, "");
    }
  }, [defaultValue])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    if (isMulti) {
      form.setFieldValue(name, e ? (e as Option[]).map(i => i.value) : [])
    } else {
      form.setFieldValue(name, e ? (e as Option).value : "")
    }
  }
  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '40px !important',
      fontSize: '1.6rem',
      borderStyle: 'none',
      boxShadow: '0 0 0 0px',
      borderColor: '#cccccc',
      '&:hover': { borderColor: '#cccccc' },
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: '1.6rem',
      wordBreak: 'break-all',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0px 8px',
      fontSize: '1.6rem',
    }),

  }

  return (
    <div className={className} aria-disabled={isDisabled}>
      <ReactSelect
        isMulti={isMulti}
        name={name}
        className={classes.formControlSize}
        options={optionList}
        value={optionList.find(i => i.value === value)}
        noOptionsMessage={() => noOptionsMessage}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={customSelectOptions.filterOption}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isClearable={isClearable}
        styles={selectStyles}
      />
    </div>
  )
}
