import React from 'react'
import { Provider } from 'react-redux'
import moment, { Moment } from "moment"
import "moment/locale/ja";

import ThemeProvider from './themeProvider'
import RouterProvider from 'providers/routerProvider'
import store from 'store'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

moment.locale("ja");

class ExtendedUtils extends MomentUtils {
  getYearText(date: Moment) {
    return moment(date).format("YYYY年")  
  }
  getCalendarHeaderText(date: Moment) {
    return moment(date).format("YYYY年MM月");
  }
  getDatePickerHeaderText(date: Moment) {
    return moment(date).format("MM月DD日 ddd");
  }
}

const AppProvider: React.FC = ({ children }) => (
  <Provider store={store}>
    <ThemeProvider>
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale="ja">
      <RouterProvider>{children}</RouterProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
)

export default AppProvider
