import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'
import { getMessage } from 'common/messageUtil'
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { FormControl } from '..'
import { useModal } from 'utils'
import { Option } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { ModalDialog } from 'sp/components/ModalDialog'

const regionSet = {
  hokkaidotohoku: [
    { label: '北海道', value: '01' },
    { label: '青森県', value: '02' },
    { label: '岩手県', value: '03' },
    { label: '宮城県', value: '04' },
    { label: '秋田県', value: '05' },
    { label: '山形県', value: '06' },
    { label: '福島県', value: '07' },
  ],
  kanto: [
    { label: '茨城県', value: '08' },
    { label: '栃木県', value: '09' },
    { label: '群馬県', value: '10' },
    { label: '埼玉県', value: '11' },
    { label: '千葉県', value: '12' },
    { label: '東京都', value: '13' },
    { label: '神奈川県', value: '14' },
  ],
  kousinnetuhokuriku: [
    { label: '新潟県', value: '15' },
    { label: '富山県', value: '16' },
    { label: '石川県', value: '17' },
    { label: '福井県', value: '18' },
    { label: '山梨県', value: '19' },
    { label: '長野県', value: '20' },
  ],
  tokaicyubu: [
    { label: '岐阜県', value: '21' },
    { label: '静岡県', value: '22' },
    { label: '愛知県', value: '23' },
    { label: '三重県', value: '24' },
  ],
  kinnki: [
    { label: '滋賀県', value: '25' },
    { label: '京都府', value: '26' },
    { label: '大阪府', value: '27' },
    { label: '兵庫県', value: '28' },
    { label: '奈良県', value: '29' },
    { label: '和歌山県', value: '30' },
  ],
  cyugokusikoku: [
    { label: '鳥取県', value: '31' },
    { label: '島根県', value: '32' },
    { label: '岡山県', value: '33' },
    { label: '広島県', value: '34' },
    { label: '山口県', value: '35' },
    { label: '徳島県', value: '36' },
    { label: '香川県', value: '37' },
    { label: '愛媛県', value: '38' },
    { label: '高知県', value: '39' },
  ],
  kyusyuokinawa: [
    { label: '福岡県', value: '40' },
    { label: '佐賀県', value: '41' },
    { label: '長崎県', value: '42' },
    { label: '熊本県', value: '43' },
    { label: '大分県', value: '44' },
    { label: '宮崎県', value: '45' },
    { label: '鹿児島県', value: '46' },
    { label: '沖縄県', value: '47' },
  ],
  kaigai: [{ label: '海外', value: '99' }],
}

export type Area = keyof typeof regionSet
export type Region = 'preferredWorkplaceObj'

const areaList: { label: string; value: Area }[] = [
  { label: '北海道・東北', value: 'hokkaidotohoku' },
  { label: '関東', value: 'kanto' },
  { label: '甲信越・北陸', value: 'kousinnetuhokuriku' },
  { label: '東海・中部', value: 'tokaicyubu' },
  { label: '近畿', value: 'kinnki' },
  { label: '中国・四国', value: 'cyugokusikoku' },
  { label: '九州・沖縄', value: 'kyusyuokinawa' },
  { label: '海外', value: 'kaigai' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
  name: Region
  selectedAreaState: [Area, Dispatch<SetStateAction<Area>>]
}

const RegionModalWorkPlace = ({
  open,
  onClose,
  form,
  name,
  selectedAreaState: [selectedArea, setSelectedArea],
}: Props) => {

  const [selectedRegions, setSelectedRegions] = useState(
    form.values[name] || []
  )

  useEffect(() => {
    setSelectedRegions(form.values[name] || [])
  }, [form.values[name]])

  const handleSelect = (region: Option, checked: boolean) => () => {
    setSelectedRegions(
      checked
        ? selectedRegions.filter(i => i.value !== region.value)
        : [...selectedRegions, region]
    )
  }

  const handleSelectAll = (area: Area, checked: boolean) => () => {
    setSelectedArea(area)
    setSelectedRegions(
      checked
        ? selectedRegions.filter(i => regionSet[area].indexOf(i) === -1)
        : [
          ...new Map(
            [...selectedRegions, ...regionSet[area]].map(v => [
              v.value,
              v,
            ])
          ).values(),
        ]
    )
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    setSelectedRegions(form.values[name] || [])
    onClose()
  }

  const handleSave = () => {
    const tempValue = new Map()
    const selectValue: Option[] = selectedRegions.filter(
      i => !tempValue.has(i.value) && tempValue.set(i.value, 1)
    )
    const result = selectValue.slice().sort((a, b) => {
      if (a.value > b.value) {
        return 1
      }
      if (a.value < b.value) {
        return -1
      }
      return 0
    })
    form.setFieldValue(name, result)
    onClose()
  }
  return (
    <>
      <ModalDialog isOpen={open} className='modal'>
        <div className='modal-content'>
          <div className='modal-title-wrap'>
            <h3 className='modal-title'>希望勤務地（都道府県）を指定</h3>
            <span className='close-btn' onClick={handleCancelOk}>
              <svg width='25' height='25' viewBox='0 0 50 50' fill='#fff'>
                <use xlinkHref='#form__modal_close'></use>
              </svg>
            </span>
          </div>
          <div className='modal-section'>
            {/*　エリア */}
            {areaList.map(area => (
              <>
                <div>
                  <div className='form__ttl_modal_add'>
                    <div className='mb1'>{area.label}</div>
                    <div className='form__ttl_modal_allcheck'>
                      <label className='form__label_side'>
                        <input
                          type='checkbox'
                          className='form__checkbox'
                          onClick={handleSelectAll(
                            area.value,
                            regionSet[area.value].every(
                              (region, i) =>
                                selectedRegions
                                  .map((i: any) => i.value)
                                  .indexOf(region.value) !== -1
                            )
                          )}
                          checked={regionSet[area.value].every(
                            (region, i) =>
                              selectedRegions
                                .map((i: any) => i.value)
                                .indexOf(region.value) !== -1
                          )}
                        />
                        <mark></mark>
                        <span>全て選択</span>
                      </label>
                    </div>
                  </div>
                  {/* 都道府県 */}
                  <div className='form__body_modal'>
                    <div
                      className='form__body_item_modal'
                      data-js-allcheck-content>
                      {regionSet[area.value].map(region => {
                        const checked =
                          selectedRegions
                            .map((i: any) => i.value)
                            .indexOf(region.value) !== -1
                        return (
                          <>
                            <label className='form__label_side'>
                              <input
                                type='checkbox'
                                className='form__checkbox'
                                onClick={handleSelect(region, checked)}
                                checked={checked}
                              />
                              <mark></mark>
                              <span>{region.label}</span>
                            </label>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className='modal-section-btn'>
            <button
              type='button'
              className='btn__cancel'
              onClick={handleCancelOk}>
              キャンセル
            </button>
            <button type='button' className='btn__blue_ok' onClick={handleSave}>
              OK
            </button>
          </div>
        </div>
      </ModalDialog>

      <Dialog open={show} onClose={toggle(false)} disableScrollLock={true} >
        <DialogTitle>{getMessage('MCAXS521-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RegionModalWorkPlace
