import React from 'react'
const FormModalCloseIcon: React.FC = () => {
  return (
    <svg width='25' height='25' viewBox='0 0 50 50' fill='none' display='none'>
      <defs>
        <path
          id='form__modal_close'
          data-name='form__modal_close'
          d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
          transform='translate(0 0)'
        />
      </defs>
      <use xlinkHref={'#form__modal_close'}></use>
    </svg>
  )
}
export default FormModalCloseIcon
