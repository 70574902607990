import React from 'react'
import { FieldProps } from 'formik'

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  placeholder?: string
  className?: string
  selectClassName?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
}

export default ({
  className,
  selectClassName,
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
}: Props) => {
  const { name, value } = field
  React.useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, '')
    }
  }, [defaultValue])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    }
    form.setFieldValue(name, e.target.value)
  }

  return (
    <div className={className}>
      <select
        disabled={disabled}
        className={selectClassName}
        value={value}
        onChange={handleChange}>
        {placeholder ? <option value=''>{placeholder}</option> : null}
        {optionList.map(i => (
          <option key={i.value} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
    </div>
  )
}
