/**
 * MCAXS110UpdateProgressRequestValidation validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'
import { magiContants } from 'utils/contants'
import { isEmpty } from 'common/generalUtil'

export default (arg: any) => {
  const selectionStep: string = arg.selectionStep
  const interviewDayShowList: string[] = arg.interviewDayShowList
  const fairueReasonShow: string = arg.fairueReasonShow
  const declineReasonShow: string = arg.declineReasonShow

  const validations = yup.object().shape({

    // 選考ステップ
    selectionStep: yup
      .string()
      .trim()
      .required(validationMessageSet.required),

    // 面接日時
    interviewDay: interviewDayShowList.includes(selectionStep) ?
      yup
        .date().nullable()
        .required(validationMessageSet.required)
        .test('checkDate','日時が正しくありません', (value :Date) => {
          if (value) {
            let datetime = value.getFullYear() + '/' + (value.getMonth() + 1) + '/' + value.getDate() + ' ' + value.getHours();
            return miscRegexSet.datetimeFormat.test(datetime)
          }
          return true
        })
      : yup.mixed().notRequired(),

    // 不合格理由
    fairueReason: selectionStep == fairueReasonShow ?
      yup.string().trim().required(validationMessageSet.required)
      : yup.mixed().notRequired(),

    // 辞退理由
    declineReason: selectionStep == declineReasonShow ?
      yup.string().trim().required(validationMessageSet.required)
      : yup.mixed().notRequired(),

    // その他
    other: yup
      .string()
      .max(30, validationMessageSet.maxLength),

  })

  return validations
}