import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { randomBytes } from 'crypto'

const defaultCharset =
  'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz23456789'

const initialState: {
  isMessageInputDialogOpen: boolean
  messageInputDialogKey: string
} = {
  isMessageInputDialogOpen: false,
  messageInputDialogKey: '',
}

const messageInputDialogSlice = createSlice({
  name: 'messageInputDialog',
  initialState,
  reducers: {
    openMessageInputDialog(state) {
      state.isMessageInputDialogOpen = true
      state.messageInputDialogKey = randomString(32)
      return state
    },
    closeMessageInputDialog(state) {
      state.isMessageInputDialogOpen = false
      return state
    },
  },
})

const randomString = (length: number): string => {
  let result = ''

  while (result.length < length) {
    const bytes = randomBytes(length)

    for (const byte of bytes) {
      result += defaultCharset[byte % defaultCharset.length]
      if (result.length === length) break
    }
  }

  return result
}

export const {
  openMessageInputDialog,
  closeMessageInputDialog,
} = messageInputDialogSlice.actions
export default messageInputDialogSlice.reducer
