import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/TextField';


const useStyles = makeStyles(theme => ({
  text: {
    marginTop: -6,
  },
  textCounter: {
    float: "right",
    minWidth: 120,
    textAlign: "right",
    color: '#fff'
  },
  textColor: {
    color: '#fff'
  },
}));

interface Props extends FieldProps {
  name?: string
  label: string
  type?: string
  variant?: any
  className?: any
  maxAmount?: number
  onChange?: (value: string) => void
  onBlur?: (target: any) => void
}

const MyInputWithCounter = ({ field, form, label, type, variant, className, maxAmount, onChange, onBlur, ...others }: Props) => {
  const classes = useStyles();
  const { name, value } = field;
  const handleChange = (event: any) => {
    if (400 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value);
    }
  };
  const hanleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event.target)
    }
    form.setFieldTouched(name, true);
  }
  return (
    <React.Fragment>
      <Grid container >
        <Grid item xs={12} className={classes.text}>
          <TextField
            form={form}
            field={{ ...field, onChange: handleChange, onBlur: hanleBlur }}
            // label={label}
            type={type}
            {...others}
          />
        </Grid>
        <Grid item xs={12} className={classes.textCounter}>
          <Typography gutterBottom variant='h6'>
            <div className={classes.textColor}>
              {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
            </div>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MyInputWithCounter;
