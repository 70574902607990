import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BroadcastChannel } from 'broadcast-channel'
import { isEmpty } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import { LoadingDialog, ProgressStatus } from 'components'
import { Field, Formik } from 'formik'
import MCAXS110 from 'pages/MCAXS110'
import MCAXS340 from 'pages/MCAXS340'
import { MCAXS340InitRequest } from 'pages/MCAXS340/formConfig'
import React, { useEffect, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { chkNotAdoptedNotificationType, getInit, newTemplete, orverWrite, setChkNotAdoptedNotification } from 'reducers/messageSendCompReducer'
import { progressStatusChangeInit, ProgressStatusChangeInParm, SysVersionNumberList } from 'reducers/progressStatusChangeReducer'
import { initRMFInfo, setinitRequest } from 'reducers/recruitmentManagementFlagChangeReducer'
import { DecisionProcessChangeQueryResultList, initCondition as InitCondition, leftEmpty, leftReset, processChangeMessageInit, processChangeMessageListInit, progressChangeUpdateMessage, setMCAXS340Open, toLeft, UnifiedInit } from 'reducers/selectionStatusUpdateReducer'
import { MCAZS101NewRequest } from 'types/MCAZS101/MCAZS101NewRequest'
import { MCAZS101OrverWriteRequest } from 'types/MCAZS101/MCAZS101OrverWriteRequest'
import { BROADCAST_CHANNEL_CONFIG, magiContants as contants } from 'utils/contants'
import * as yup from 'yup'
import * as apiConfig from './apiConfig'
import { initialManagerFlowStartCommentData, staticTextMap, textMap } from './formConfig'
import ProgressInfo from './ProgressStatusInfo'
import Select from './Select'
import * as viewConfig from './viewConfig'

const useStyles = makeStyles(theme => ({
  select: {
    float: 'left',
    marginRight: 10,
    marginTop: -3,
    width: '300px',
    color: 'black',
  },
  button: {
    paddingTop: '0',
    paddingBottom: '0',
    border: '1px solid rgba(153,153,153,.3)',
  },
  sendCount: {
    backgroundColor: '#7f7f7f',
    margin: '10px 0',
    padding: '5px 15px',
    '& span': {
      marginRight: '30px',
    },
  },
  textInput: {
    verticalAlign: 'top',
    marginRight: '10px !important',
  },
  container: {
    height: '500px !important',
    '& .style-progress-status': {
      height: 'auto',
      minHeight: '100px',
    },
  },
  noMarginHeader: {
    marginTop: '15px !important',
  },
  description: {
    marginBottom: '10px !important',
  },
  overwritingButton: {
    marginBottom: '15px !important',
  },
  hidden: {
    display: 'none',
  },
  messageBox: {
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 5,
  },
  paddingLeft: {
    padding: theme.spacing(2, 0, 2, 3),
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    display: 'inline-block',
  },
  paddingRight: {
    padding: theme.spacing(2, 3, 2, 0),
    color: '#263238',
    fontWeight: 500,
    fontSize: 14,
    display: 'inline-block',
  },
  messageDiv: {
    marginBottom: '22px',
    width: '300px',
  },
  message: {
    color: '#e53935',
    fontSize: '0.7em',
  },
  textInputError: {
    marginBottom: '0px !important',
  },
  lineBreak: {
    whiteSpace: 'pre-line',
  },
  changeBtn: {
    float: 'right',
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 196,
  },
}))

interface Props {
  onClose: () => void
  messageConfVal: apiConfig.MessageConfVal
  registerValue: {
    messageType: string[]
    messageTemplateSettingId: string | null
    destinationId: string[]
    selectionManagementId: string[]
    destination: number
    // [phase2] start
    sendToNonMember: number
    // [phase2] end
    senderCompanyName: string
    senderMailAddress: string
    allowReply: number
    subject: string
    body: string
    attachment: any[]
    attachmentId: Array<string | null>
    templateAttachmentId: string[]
    mynaviAttachedOption: string
    homePageTitle1: string | null
    homePageTitle2: string | null
    homePageTitle3: string | null
    homePageUrl1: string | null
    homePageUrl2: string | null
    homePageUrl3: string | null
    barcodeDisplayFlag: number
    sendTimer: number
    sendPlanTime: string | null
    notifyNotification: number
    notifyMailAddress: string
    requiredTimeId: string
    interviewContents: string
    interviewDateN: (string | null)[]
    interviewLocationIdN: (number | null)[]
    chargeN: string[]
    fileManagementId: string[]
    entryId: number[]
    interviewScheduleAdjustmentId: number
    sysVersionNumber: string
    messageSendId: string
    interviewScheduleAdjustmentStatus: string
    interviewScheduleAdjustmentSelection: string
    adjustmentInterviewScheduleAdjustmentId: string
    interviewDurationId: string | null
    interviewDetail: string
    interviewScheduleId: number[]
    interviewStartTime: (string | null)[]
    interviewStaffName: string[]
    interviewScheduleStatus: string[]
    manipulationKubun: string
    seminarId: number[]
    entryBoxId: number[]
    sendTimeFlag: string
    replyPermitFlag: string
    destinationDivision: string
    manualRegistrationFlag: string[]
    decisionDivision: string[]
    progressStatusSettingId: number[]
    notSend: boolean[]
    notificationMailAddress: string
    replyFromMessageSendId: string | null
    selectionFlowSettingId: number[]
    title: viewConfig.Title
    interviewSchedule: viewConfig.InterviewSchedule
    messageInput: viewConfig.MessageInput
    sendTargetConfirmation: viewConfig.SendTargetConfirmation
    sendTimeInput: viewConfig.SendTimeInput
    recruitmentManagementFlag: viewConfig.RecruitmentManagementFlag[]
    seminarSchedule: viewConfig.SeminarSchedule[]
    seminar: viewConfig.Seminar[]
    signature: viewConfig.Signature[]
    jobSeekerId: string[]
    replyNecessaryFlag: string
    replyLimitTime: string | null
    notAdoptedNotificationType: string | null
  }
  setIsCompletedSending: (value: boolean) => void
}

export default ({
  onClose,
  messageConfVal,
  registerValue,
  setIsCompletedSending,
}: Props) => {
  const { mCAXS340Open } = useSelector(
    (state: RootState) => state.selectionStatusUpdate
  )
  const dispatch = useDispatch()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogRegisterOpen, setConfirmDialogRegisterOpen] = useState(
    false
  )
  const [confirmDialogOverwriteOpen, setConfirmDialogOverwriteOpen] = useState(
    false
  )
  const [
    confirmDialogOverwriteMCBOpen,
    setConfirmDialogOverwriteMCBOpen,
  ] = useState(false)
  const [selectionOutDto, setSelectionOutDto] = useState<any>(null)
  const [progressTypeBefore, setProgressTypeBefore] = useState('')
  const [progressTypeAfter, setProgressTypeAfter] = useState('')
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const processChangeResult = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResultList
  )
  const selectionFlowInitData = useSelector(
    (state: RootState) =>
      state.selectionStatusUpdate.decisionProcessChangeQueryResultList
        .selectionFlowInitData
  )
  const [selectionFlowInitDataTemp, setSelectionFlowInitDataTemp] = useState(
    processChangeResult.selectionFlowInitData
  )
  const decisionDivisionList = useSelector(
    (state: RootState) => state.selectionStatusUpdate.decisionDivisionList
  )
  const [settingId, setSettingId] = useState<number>(-1)
  const [progressName, setProgressName] = useState('')
  const [inputString, setInputString] = useState<string>('')
  let selectMagResult = initialManagerFlowStartCommentData
  if (processChangeResult.selectMagSelectSelectionInfoOutDto[0] != undefined) {
    selectMagResult = processChangeResult.selectMagSelectSelectionInfoOutDto[0]
  }
  const [shouldShowProgress, setShouldShowProgress] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('selecting')
  const [groupHorizontalReset, setGroupHorizontalReset] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [procsOpen, setProcsOpen] = useState(false)
  const [selectTemp, setSelectTemp] = useState('')
  const [selectTemplateType, setSelectTemplateType] = useState('')
  const [sysVersionNumber, setsysVersionNumber] = useState('')
  const initResult = useSelector(
    (state: RootState) => state.messageSendComp.initResult
  )
  const messageTemplate = registerValue.title.messageTemplate
  const optionList: {
    value: string
    label: string
    templateType: string
    sysVersionNumber: string
  }[] = [
      {
        value: '',
        label: '(以下から選択)',
        templateType: '',
        sysVersionNumber: '',
      },
    ]
  const registerResult = useSelector(
    (state: RootState) => state.messageSendConfirm.messageComfRegisterResult
  )
  const [error, setError] = useState({ isError: false, message: '' })
  messageTemplate.forEach(e => {
    optionList.push({
      value: e.messageTemplateSettingId.toString(),
      label: e.sequence ? e.sequence + '. ' + e.templateName : e.templateName,
      templateType: e.templateType,
      sysVersionNumber: e.sysVersionNumber,
    })
  })
  const chkNotAdoptedNotification = useSelector(
    (state: RootState) => state.messageSendComp.chkNotAdoptedNotification
  )
  const [
    notAdoptedNotificationDialogOpen,
    setnotAdoptedNotificationDialogOpen,
  ] = useState(false)
  const [
    notAdoptedNotificationPropsNew,
    setNotAdoptedNotificationPropsNew,
  ] = useState('')

  const marchResponsibleCompanyFlag = useSelector(
    (state: RootState) => state.globalMenu.marchResponsibleCompanyFlag
  )
  // March担当企業フラグ
  const marchResCompFlag =
    recruitmentManagementDivision == '2' && marchResponsibleCompanyFlag === '1'
  // 「選考ステップを変更」ボタン非活性フラグ
  const ProgressFlag = Boolean(
    processChangeResult.selectMagSelectSelectionInfoOutDto.find(
      v => v.nonTargetFlag === '1'
    )
  )
  // 一括送信フラグ
  const [bulkSendFlg, setBulkSendFlg] = useState(false)
  const sendFlg = Boolean(registerValue.entryId.length > 1)

  const channel = useMemo(
    () => new BroadcastChannel(BROADCAST_CHANNEL_CONFIG.NAME),
    []
  )

  useEffect(() => {
    return () => {
      channel.close()
    }
  }, [])

  const classes = useStyles()

  useEffect(() => {
    channel.postMessage(BROADCAST_CHANNEL_CONFIG.MESSAGE)
    if (
      initResult.progressStatusManagementDtos &&
      initResult.progressStatusManagementDtos.length > 0 &&
      initResult.selectionManagementDtos &&
      initResult.selectionManagementDtos.length > 0 &&
      initResult.selectionStepDtos &&
      initResult.selectionStepDtos.length > 0
    ) {
      setBulkSendFlg(true)
      const sendTimeFlag = registerValue.sendTimeFlag
      if (sendTimeFlag && sendTimeFlag === contants.SEND_TIME_FLAG_IMMEDIATE) {
        const targetList = registerValue.sendTargetConfirmation.sendTarget
        if (
          targetList &&
          targetList.every(
            item =>
              item.selectionClassification !==
              contants.SELECTION_CLASSIFICATION_QUESTION
          )
        ) {
          setShouldShowProgress(true)
        }
      }
    }
  }, [initResult])

  useEffect(() => {
    if (registerValue.notAdoptedNotificationType) {
      dispatch(openSnackbar(getMessage('MCAZS101-011')))
    }
  }, [decisionDivisionList])

  useEffect(() => {
    setIsCompletedSending(true)
    const selectionManagementId = registerValue.selectionManagementId[0]

    const initCondition: InitCondition = {
      selectionManagementId: Number(selectionManagementId),
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(processChangeMessageInit(initCondition))

    const unifiedInit: UnifiedInit = {
      selectionManagementIdArray: registerValue.selectionManagementId.map(
        item => Number(item)
      ),
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(processChangeMessageListInit(unifiedInit))

    dispatch(getInit(messageConfVal))
  }, [dispatch])

  useEffect(() => {
    if (
      notAdoptedNotificationPropsNew == null ||
      notAdoptedNotificationPropsNew === ''
    ) {
      // 処理なし
    } else {
      if (
        chkNotAdoptedNotification == null ||
        chkNotAdoptedNotification === ''
      ) {
        handleProgressChangeChk(notAdoptedNotificationPropsNew)
      } else {
        setnotAdoptedNotificationDialogOpen(true)
      }
    }
  }, [chkNotAdoptedNotification])

  // 「新規保存する」ボタン押下
  const handleNew = () => {
    if (!error.isError) {
      if (inputString == null || inputString.trim() === '') {
        dispatch(openModal(getMessage('MCAZS101-001')))
        return
      }
      setConfirmDialogRegisterOpen(true)
    } else {
      return
    }
  }

  const templateNameOption = useSelector(
    (state: RootState) => state.messageSendComp.templateNameOption
  )
  // 「上書き保存する」ボタン押下
  const handleOrverWrite = () => {
    if (null == templateNameOption || isEmpty(templateNameOption.value)) {
      dispatch(openModal(getMessage('MCAZS101-005')))
      return
    } else {
      setSelectTemp(templateNameOption.value)
      setSelectTemplateType(templateNameOption.templateType)
      setsysVersionNumber(templateNameOption.sysVersionNumber)
    }

    if (
      templateNameOption.templateType ===
      contants.DESTINATION_DIVISION_MYCAREERBOX
    ) {
      setConfirmDialogOverwriteMCBOpen(true)
    } else {
      setConfirmDialogOverwriteOpen(true)
    }
  }

  // 「送信画面を閉じる」ボタン押下
  const handleClose = () => {
    setIsCompletedSending(false)
    onClose()
  }

  //管理項目一覧を見るボタン押下
  const inputManagementItem = () => {
    const param: MCAXS340InitRequest = {
      jobSeekerId: [],
      entryId: registerValue.entryId,
      selectionManagementId: registerValue.selectionManagementId.map(Number),
      recruitmentManagementFlagSettingId: [],
      recruitmentManagementFlagOptionSettingId: [],
      settingString: [],
      settingNumber: [],
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    dispatch(initRMFInfo({ request: param, id: 'MCAZS101' }))
    dispatch(setinitRequest(param))
  }

  const messageTemplateNameYup = yup.object().shape({
    messageTemplateName: yup.string(),
  })
  const transInputString = (e: any) => {
    setInputString(e.target.value)
    setError({ isError: false, message: '' })
    // messageTemplateNameYup
    //   .validate({ messageTemplateName: e.target.value })
    //   .then()
    //   .catch(err => {
    //     setError({ isError: true, message: err.errors })
    //   })
  }

  const inArray = (content: string, array: string[]) => {
    for (var i in array) {
      if (content === array[i]) {
        return true
      }
    }
    return false
  }

  const handleProcessChangeConfirm = (settingId: number) => {
    if (settingId != selectMagResult.progressStatusSettingId) {
      const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
      const selectionOutDto = selectMagResult
      setSelectionOutDto(selectionOutDto)
      //表示順(変更元)
      let displayOrderBefore = 0
      //進捗種別(変更元)
      let progressTypeBefore = ''
      //判定区分種別(変更元)
      let decisionDivisionTypeBefore = ''
      //選考ステップ設定ID(変更元)
      let progressStatusSettingIdBefore = -1
      //選考ステップ設定ID(変更先)
      let progressStatusSettingIdAfter = -1
      //進捗種別(変更先)
      let progressTypeAfter = ''
      //表示順(変更先)
      let displayOrderAfter = 0
      //進捗名(変更先)
      let progressNameAfter = ''
      //選考名
      let selectionName = ''
      //判定区分種別(変更先)
      let decisionDivisionTypeAfter = ''
      for (let i in flowList) {
        //進捗の変更元情報を取得する
        if (
          selectionOutDto.progressStatusSettingId ==
          flowList[i].progressStatusSettingId
        ) {
          //表示順(変更元)
          displayOrderBefore = flowList[i].displayOrder
          //進捗種別(変更元)
          progressTypeBefore = flowList[i].progressType
          //判定区分種別(変更元)
          decisionDivisionTypeBefore = flowList[i].decisionDivisionType
          progressStatusSettingIdBefore = flowList[i].progressStatusSettingId
        }
        //進捗の変更先情報を取得する
        if (settingId == flowList[i].progressStatusSettingId) {
          //選考ステップ設定ID(変更先)
          progressStatusSettingIdAfter = flowList[i].progressStatusSettingId
          //進捗種別(変更先)
          progressTypeAfter = flowList[i].progressType
          //表示順(変更先)
          displayOrderAfter = flowList[i].displayOrder
          //進捗名(変更先)
          progressNameAfter = flowList[i].progressName
          //選考名
          selectionName = flowList[i].selectionName
          //判定区分種別(変更先)
          decisionDivisionTypeAfter = flowList[i].decisionDivisionType
        }
      }
      const JsonProcessChangeResult = JSON.parse(
        JSON.stringify(processChangeResult)
      )
      const data: DecisionProcessChangeQueryResultList = {
        selectionManagementIdArray:
          JsonProcessChangeResult.selectionManagementIdArray,
        selectMagSelectFlowInfoOutDtoList:
          JsonProcessChangeResult.selectMagSelectFlowInfoOutDtoList,
        selectMagSelectSelectionInfoOutDto:
          JsonProcessChangeResult.selectMagSelectSelectionInfoOutDto,
        progressStatusSettingIdBefore: progressStatusSettingIdBefore, //選考ステップ設定ID(変更元)
        passingStatusDivisionId:
          JsonProcessChangeResult.passingStatusDivisionId, //合否区分ID
        historyInsertDivisionId: '0', //履歴登録区分("1":登録)
        progressTypeBefore: progressTypeBefore, //進捗種別(変更元)
        displayOrderBefore: displayOrderBefore, //表示順(変更元)
        progressNameBefore: '', //進捗名(変更元)
        decisionDivisionTypeBefore: decisionDivisionTypeBefore, //判定区分種別(変更元)
        progressStatusSettingIdAfter: progressStatusSettingIdAfter, //選考ステップ設定ID(変更先)
        progressTypeAfter: progressTypeAfter, //進捗種別(変更先)
        displayOrderAfter: displayOrderAfter, //表示順(変更先)
        progressNameAfter: progressNameAfter, //進捗名(変更先)
        selectionName: selectionName, //選考名
        decisionDivisionTypeAfter: decisionDivisionTypeAfter, //判定区分種別(変更先)
        sysVersionNumberList: JsonProcessChangeResult.sysVersionNumberList, //バージョン番号と判定区分情報
        selectionFlowInitData: JsonProcessChangeResult.selectionFlowInitData, //選考フロー初期化情報
        decisionDivisionType: JsonProcessChangeResult.decisionDivisionType, //判定区分種別(初期表示)
        inValidCheckCount: JsonProcessChangeResult.inValidCheckCount, //メッセージ送信エラー情報
        initResultDto: JsonProcessChangeResult.initResultDto,
      }
      //1.5 "進捗"クリック
      dispatch(progressChangeUpdateMessage(data))
      setGroupHorizontalReset(true)
      toolTipShow('unifiedToolTipProgress')
    }
    setConfirmDialogOpen(false)
    setType('selecting')
    setOpen(false)
  }

  const toolTipShow = (toolTipId: string) => {
    let toolTip = document.getElementById(toolTipId)
    if (toolTip != null && toolTip.className.search(' show') == -1) {
      let classNametemp = toolTip.className
      toolTip.className = toolTip.className + ' show'
      setTimeout(() => {
        if (toolTip != null) {
          toolTip.className = classNametemp
        }
      }, 5000)
    }
  }

  //進捗
  const handleProgressChangeChk = (propsNew: any) => {
    let settingId = propsNew.eventTarget.value
    dispatch(setChkNotAdoptedNotification(''))
    setnotAdoptedNotificationDialogOpen(false)
    const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
    const selectionOutDto = selectMagResult
    setSettingId(settingId)
    //表示順(変更元)
    let displayOrderBefore = -1
    //進捗種別(変更元)
    let progressTypeBefore = ''
    //進捗種別(変更先)
    let progressTypeAfter = ''
    //表示順(変更先)
    let displayOrderAfter = -1
    //進捗名(変更先)
    let progressNameAfter = ''
    //選考名
    let selectionName = ''
    for (let i in flowList) {
      //進捗の変更元情報を取得する
      if (
        selectionOutDto.progressStatusSettingId ==
        flowList[i].progressStatusSettingId
      ) {
        //表示順(変更元)
        displayOrderBefore = flowList[i].displayOrder
        //進捗種別(変更元)
        progressTypeBefore = flowList[i].progressType
        setProgressTypeBefore(flowList[i].progressType)
      }
      //進捗の変更先情報を取得する
      if (settingId == flowList[i].progressStatusSettingId) {
        //進捗種別(変更先)
        progressTypeAfter = flowList[i].progressType
        setProgressTypeAfter(flowList[i].progressType)
        //表示順(変更先)
        displayOrderAfter = flowList[i].displayOrder
        //進捗名(変更先)
        progressNameAfter = flowList[i].progressName
        //選考名
        selectionName = flowList[i].selectionName
        setProgressName(progressNameAfter)
      }
    }
    //"進捗"クリックを重複押下する場合
    if (
      selectionOutDto &&
      settingId == selectionOutDto.progressStatusSettingId
    ) {
      //処理終了
    } else {
      //進捗種別リスト（選考中：[0]応募 [1]カスタム）
      let processTypeSelecting = ['0', '1']
      //進捗種別リスト（内定以降：[2]内定 [3]インターンシップ参加 [4]内定承諾 [5]入社）
      let processTypeConfrimAfter = ['2', '3', '4', '5']
      //進捗種別リスト（[6]不合格 [7]不採用 [8]辞退）
      let processTypeNoPass = ['6', '7', '8']
      if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeSelecting) &&
        inArray(progressTypeAfter, processTypeSelecting)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeConfrimAfter) &&
        inArray(progressTypeAfter, processTypeConfrimAfter)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '2' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeNoPass) &&
        inArray(progressTypeAfter, processTypeNoPass)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '1' == recruitmentManagementDivision &&
        (inArray(progressTypeBefore, processTypeSelecting) ||
          inArray(progressTypeBefore, processTypeNoPass)) &&
        (inArray(progressTypeAfter, processTypeSelecting) ||
          inArray(progressTypeAfter, processTypeNoPass))
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else if (
        '1' == recruitmentManagementDivision &&
        inArray(progressTypeBefore, processTypeConfrimAfter) &&
        inArray(progressTypeAfter, processTypeConfrimAfter)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
        //[3]インターンシップ参加
      } else if (
        '1' == recruitmentManagementDivision &&
        ('3' == progressTypeAfter || '3' == progressTypeBefore)
      ) {
        //ダイアログを表示
        setConfirmDialogOpen(true)
      } else {
        //[0]応募 [1]カスタム
        if (inArray(progressTypeAfter, processTypeSelecting)) {
          setType('selecting')
          //[2]内定 [3]インターンシップ参加 [4]内定承諾 [5]入社
        } else if (inArray(progressTypeAfter, processTypeConfrimAfter)) {
          setType('confrimAfter')
          //[6]不合格
        } else if ('6' == progressTypeAfter) {
          setType('noPass')
          //[7]不採用
        } else if ('7' == progressTypeAfter) {
          setType('noAdopt')
          //[8]辞退
        } else if ('8' == progressTypeAfter) {
          setType('dismiss')
        }
        setOpen(true)
      }
    }
  }

  // テンプレート保存ダイアログの「キャンセル」ボタン押下
  const handleCancel = () => {
    setConfirmDialogRegisterOpen(false)
    setConfirmDialogOverwriteOpen(false)
    setConfirmDialogOverwriteMCBOpen(false)
  }

  // 選考ステップ変更確認ダイアログの「キャンセル」ボタン押下
  const handleDialogCancel = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setConfirmDialogOpen(false)
  }
  // 選考ステップ変更確認ダイアログの「キャンセル」ボタン押下
  const handleDialogClose = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setType('selecting')
    setOpen(false)
  }
  // 選考ステップ変更確認ダイアログの「はい」ボタン押下
  const handleConfirm = () => {
    handleProcessChangeConfirm(settingId)
    setConfirmDialogOpen(false)
  }

  // 選考ステップ変更時の不採用通知設定チェック
  const handleProgressChangeNotAdoptedNotificationChk = (propsNew: any) => {
    let rightProgressList =
      processChangeResult.selectionFlowInitData.rightProgressList
    let settingId = propsNew.eventTarget.value
    let displayOrder: number = propsNew.eventTarget.id
    setSelectionFlowInitDataTemp(processChangeResult.selectionFlowInitData)
    const selectionOutDto = selectMagResult
    let flg = false
    for (let k in rightProgressList) {
      if (settingId == rightProgressList[k].settingId) {
        flg = true
        dispatch(leftEmpty(displayOrder))
      }
    }
    if (flg == false) {
      dispatch(toLeft(propsNew))
    }

    setNotAdoptedNotificationPropsNew(propsNew)
    //進捗種別(変更先)
    let progressTypeAfter = ''
    const flowList = processChangeResult.selectMagSelectFlowInfoOutDtoList
    for (let i in flowList) {
      //進捗の変更先情報を取得する
      if (settingId == flowList[i].progressStatusSettingId) {
        //進捗種別(変更先)
        progressTypeAfter = flowList[i].progressType
      }
    }
    //"進捗"クリックを重複押下する場合
    if (
      selectionOutDto &&
      settingId == selectionOutDto.progressStatusSettingId
    ) {
      //処理終了
    } else {
      // 転職かつ不採用の場合
      if ('2' === recruitmentManagementDivision && '7' == progressTypeAfter) {
        dispatch(
          chkNotAdoptedNotificationType(registerValue.selectionManagementId)
        )
      } else {
        handleProgressChangeChk(propsNew)
      }
    }
  }

  // 不採用通知設定チェックダイアログの「キャンセル」ボタン押下
  const handleProgressChangeNotAdoptedNotificationClose = () => {
    dispatch(leftReset(selectionFlowInitDataTemp))
    setnotAdoptedNotificationDialogOpen(false)
    setNotAdoptedNotificationPropsNew('')
    dispatch(setChkNotAdoptedNotification(''))
  }

  const handleConfirmRegister = () => {
    const formData: MCAZS101NewRequest = apiConfig.newRequest

    formData.messageTemplateName = inputString
    formData.destination = registerValue.destination
    // [phase2] start
    formData.sendToNonMember = registerValue.sendToNonMember
    // [phase2] end
    formData.senderCompanyName = registerValue.senderCompanyName
    formData.senderMailAddress = registerValue.senderMailAddress
    formData.allowReply = registerValue.allowReply
    formData.subject = registerValue.subject
    formData.text = registerValue.body
    formData.attachmentName = registerResult.attachmentNames
    formData.fileManagementId = registerResult.fileManagementIds
    formData.entryBoxId = registerValue.entryBoxId
    formData.seminarId = registerValue.seminarId
    formData.homepageTitle1 = registerValue.homePageTitle1
    formData.homepageTitle2 = registerValue.homePageTitle2
    formData.homepageTitle3 = registerValue.homePageTitle3
    formData.homepageUrl1 = registerValue.homePageUrl1
    formData.homepageUrl2 = registerValue.homePageUrl2
    formData.homepageUrl3 = registerValue.homePageUrl3
    formData.barcodeDisplay = registerValue.barcodeDisplayFlag
    formData.replyLimitTime = registerValue.replyLimitTime
      ? registerValue.replyLimitTime
      : ''
    formData.replyNecessaryFlag = registerValue.replyNecessaryFlag
    dispatch(newTemplete({ formData }))

    setConfirmDialogRegisterOpen(false)
  }

  const handleConfirmOverwrite = () => {
    const formData: MCAZS101OrverWriteRequest = apiConfig.orverWriteRequest

    formData.messageTemplateId = selectTemp
    formData.destination = registerValue.destination
    // [phase2] start
    formData.sendToNonMember = registerValue.sendToNonMember
    // [phase2] end
    formData.senderCompanyName = registerValue.senderCompanyName
    formData.senderMailAddress = registerValue.senderMailAddress
    formData.allowReply = registerValue.allowReply
    formData.subject = registerValue.subject
    formData.text = registerValue.body
    formData.attachmentName = registerResult.attachmentNames
    formData.fileManagementId = registerResult.fileManagementIds
    formData.entryBoxId = registerValue.entryBoxId
    formData.seminarId = registerValue.seminarId
    formData.homepageTitle1 = registerValue.homePageTitle1
    formData.homepageTitle2 = registerValue.homePageTitle2
    formData.homepageTitle3 = registerValue.homePageTitle3
    formData.homepageUrl1 = registerValue.homePageUrl1
    formData.homepageUrl2 = registerValue.homePageUrl2
    formData.homepageUrl3 = registerValue.homePageUrl3
    formData.barcodeDisplay = registerValue.barcodeDisplayFlag
    formData.replyLimitTime = registerValue.replyLimitTime
      ? registerValue.replyLimitTime
      : null
    formData.replyNecessaryFlag = registerValue.replyNecessaryFlag
      ? registerValue.replyNecessaryFlag
      : null
    formData.sysVersionNumber = sysVersionNumber
    dispatch(orverWrite({ formData }))

    setConfirmDialogOverwriteOpen(false)
    setConfirmDialogOverwriteMCBOpen(false)
  }

  const handleProgressStatusChange = () => {
    if (null != registerValue) {
      let versionNumberSelectionManagement: SysVersionNumberList[] = []
      for (
        var i = 0;
        i < processChangeResult.selectMagSelectSelectionInfoOutDto.length;
        i++
      ) {
        let SysVersionNumberInfo: SysVersionNumberList = {
          selectionManagementId: processChangeResult.selectMagSelectSelectionInfoOutDto[
            i
          ].selectionManagementId.toString(), //選考管理ID
          progressStatusSettingId: processChangeResult.selectMagSelectSelectionInfoOutDto[
            i
          ].progressStatusSettingId.toString(), //選考ステップ設定ID
          sysVersionNumber: processChangeResult.selectMagSelectSelectionInfoOutDto[
            i
          ].smSysVersionNumber.toString(), //sysバージョン番号
        }
        versionNumberSelectionManagement.push(SysVersionNumberInfo)
      }
      const inParam: ProgressStatusChangeInParm = {
        jobSeekerId:
          registerValue.jobSeekerId.length > 0
            ? registerValue.jobSeekerId.map(Number)
            : [],
        entryId: registerValue.entryId.length > 0 ? registerValue.entryId : [],
        selectionManagementId:
          registerValue.selectionManagementId.length > 0
            ? registerValue.selectionManagementId.map(Number)
            : [],
        versionNumberSelectionManagement: versionNumberSelectionManagement,
        screenId: 'MCAZS101',
      }
      dispatch(progressStatusChangeInit(inParam))
    }
  }

  return (
    <>
      <LoadingDialog className='overlay' />
      <aside className={`sendCompleteShow`}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <h1 style={{ fontSize: '20px' }}>✉</h1>
          <h1>{registerValue.sendTimeFlag === '1' ? '送信予約受付完了' : '送信完了'}</h1>
        </div>
        {registerValue.sendTimeFlag === '1' ?
          <p style={{ textAlign: 'left' }}>
            メッセージを送信予約しました。
            最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。
          </p> :
          <p style={{ textAlign: 'left' }}>
            メッセージを送信しました。
            メッセージが届くまで、時間がかかる場合があります。<br></br>
            最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。
          </p>
        }
        <PerfectScrollbar className={`ps-container ${classes.container}`}>
          <section>
            <div>
              <p className={classes.sendCount}>
                <span>
                  マイナビメッセージ：
                  {messageConfVal.successfulCountMessage -
                    (registerResult.addFailureCount
                      ? registerResult.addFailureCount
                      : 0)}
                  件
                </span>
                <span>
                  メールアドレス：{messageConfVal.successfulCountEmail}件
                </span>
                <span>
                  送信失敗：
                  {messageConfVal.failedCount +
                    (registerResult.addFailureCount
                      ? registerResult.addFailureCount
                      : 0)}
                  件
                </span>
              </p>
            </div>
            {registerValue.sendTimeFlag === '1' ? (
              <h3>送信キャンセルは送信予約日時まで可能です。</h3>
            ) : (
                ''
              )}
            {marchResCompFlag ? (
              <>
                {bulkSendFlg ? <h2>選考ステップ</h2> : null}
                <Grid container>
                  {bulkSendFlg ? (
                    <Grid item xs={9}>
                      <ProgressInfo
                        bulkSendFlg={bulkSendFlg}
                        sendFlg={sendFlg}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={3}>
                    {shouldShowProgress ? (
                      <Button
                        type='submit'
                        variant='outlined'
                        disabled={ProgressFlag}
                        onClick={handleProgressStatusChange}
                        className={`${classes.changeBtn}`}>
                        選考ステップを変更
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </>
            ) : (
                <>
                  {shouldShowProgress && (
                    <>
                      <h2>選考ステップ</h2>
                      <h3>
                        送信と同時に本画面上で選考ステップを更新することができます。
                    </h3>
                      <ProgressStatus
                        flag={selectionFlowInitData.flag}
                        leftSelect={selectionFlowInitData.leftSelect}
                        rightSelect={selectionFlowInitData.rightSelect}
                        leftGrey={selectionFlowInitData.leftGrey}
                        rightGrey={selectionFlowInitData.rightGrey}
                        target={selectionFlowInitData.target}
                        leftProgress={selectionFlowInitData.leftProgressList}
                        rightProgress={selectionFlowInitData.rightProgressList}
                        handlerClick={
                          handleProgressChangeNotAdoptedNotificationChk
                        }
                        clickFlag={true}></ProgressStatus>
                    </>
                  )}
                </>
              )}

            {!registerValue.notAdoptedNotificationType && (
              <>
                <h2 className={classes.noMarginHeader}>テンプレート保存</h2>
                <h3 className={classes.description}>
                  このメッセージをメッセージテンプレートとして保存できます。
                </h3>
                <h3>既存のメッセージテンプレートに上書き保存</h3>
                <Formik onSubmit={() => { }} initialValues={''}>
                  <Field
                    optionList={optionList}
                    className={classes.select}
                    placeholder='(以下から選択)'
                    name='templateName'
                    component={Select}
                    defaultValue={registerValue.messageTemplateSettingId}
                  />
                </Formik>

                <Button
                  className={`btn ${classes.button} ${classes.overwritingButton}`}
                  type='submit'
                  onClick={handleOrverWrite}>
                  上書き保存する
                </Button>
                <h3>新規メッセージテンプレートとして保存</h3>
                <input
                  placeholder=''
                  type='text'
                  className={
                    error.isError
                      ? `${classes.textInputError} ${classes.textInput}`
                      : `${classes.textInput}`
                  }
                  value={inputString}
                  name='messageTemplateName'
                  onChange={transInputString}
                  style={{ color: 'black' }}
                />
                <Button
                  className={
                    error.isError
                      ? `btn ${classes.button} ${classes.textInputError}`
                      : `btn ${classes.button}`
                  }
                  type='submit'
                  onClick={handleNew}>
                  新規保存する
                </Button>
                {error.isError ? (
                  <div className={classes.messageDiv}>
                    <span className={classes.message}>{error.message}</span>
                  </div>
                ) : null}
              </>
            )}
            {recruitmentManagementDivision ===
              contants.RECRUITMENT_MANAGEMENT_DIVISION_1 ? (
                ''
              ) : (
                <>
                  <h3 style={{marginTop:'10px'}}>管理項目</h3>
                  <Button
                    className={`btn ${classes.button}`}
                    type='submit'
                    onClick={inputManagementItem}>
                    管理項目一覧を見る
                </Button>
                </>
              )}
          </section>
        </PerfectScrollbar>
      </aside>
      <div className='sendCompleteFooter'>
        <div>
          <button className='btn' type='submit' onClick={handleClose}>
            送信画面を閉じる
          </button>
        </div>
      </div>
      <Dialog open={open}>
        <LoadingDialog className='overlay' />
        <DialogTitle className={classes.lineBreak}>
          {staticTextMap.staticMessageOne}
          {'selecting' != type && 'confrimAfter' != type ? '' : progressName}
          {'selecting' == type || 'confrimAfter' == type
            ? ''
            : textMap[type].messageOne}
          {staticTextMap.staticMessageTwo}
          {'1' === recruitmentManagementDivision
            ? textMap[type].messageThree
            : textMap[type].messageTwo}
          {staticTextMap.staticMessageThree}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>キャンセル</Button>
          <Button
            onClick={() => {
              handleProcessChangeConfirm(settingId)
            }}
            color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogRegisterOpen}>
        <LoadingDialog className='overlay' />
        <DialogTitle>{getMessage('MCAZS101-002')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleConfirmRegister} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOverwriteOpen}>
        <LoadingDialog className='overlay' />
        <DialogTitle>{getMessage('MCAZS101-006')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleConfirmOverwrite} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOverwriteMCBOpen}>
        <LoadingDialog className='overlay' />
        <DialogTitle>{getMessage('MCAZS101-012')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleConfirmOverwrite} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOpen}>
        <DialogTitle className={classes.lineBreak}>
          {staticTextMap.staticMessageOne}
          {'selecting' != type && 'confrimAfter' != type ? '' : progressName}
          {'selecting' == type || 'confrimAfter' == type
            ? ''
            : textMap[type].messageOne}
          {staticTextMap.staticMessageFour}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogCancel}>キャンセル</Button>
          <Button onClick={handleConfirm} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={notAdoptedNotificationDialogOpen}>
        <LoadingDialog className='overlay' />
        <DialogTitle>
          {'不採用通知の送信予約設定がされているため、'}
          <br />
          {'毎日5:00に不合格通知を自動で送信予約します。'}
          <br />
          {'送信予約日時は「選考ステップ：不採用」へ更新した日から'}
          <br />
          {'3日後の15:00に設定されます。'}
          <br />
          <br />
          {'不採用通知の送信予約を自動で実施したくない場合は'}
          <br />
          {'「キャンセル」を押下し、不採用通知設定を修正してください。'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleProgressChangeNotAdoptedNotificationClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              handleProgressChangeChk(notAdoptedNotificationPropsNew)
            }}
            color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <MCAXS340
        open={mCAXS340Open}
        onOk={() => {
          dispatch(setMCAXS340Open(false))
        }}
        onCancel={() => {
          dispatch(setMCAXS340Open(false))
        }}
        prevScreenId={'MCAZS101'}
      />
      <MCAXS110></MCAXS110>
    </>
  )
}
