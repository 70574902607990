import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles as useCheckboxStyles } from 'sp/componentsHsc/AdvancedSearchJobChange/GroupOptions';

const useStyles = makeStyles(theme => ({

  lable: {
    backgroundColor: '#e6f1ff',
    padding: '3px 7px 4px 7px',
  },
}))

interface Props {
  title: string;
  subtitle?: string;
  required?: boolean;
  className?: string;
  offset?: boolean;
  onSelectAll?: (e: any) => void;
  checked?: boolean;
}

export default ({
  title,
  subtitle,
  required,
  className,
  offset,
  onSelectAll,
  checked,
}: Props) => {
  const classes = useStyles({ offset });
  const checkboxClasses = useCheckboxStyles();

  return (
    <div>
      <div className={className}>
        {title}
        {subtitle && (
          <div>
            <p>：{subtitle}</p>
          </div>
        )}
        {required && (
          <div>
            <i className='badge ptn6'>必須</i>
          </div>
        )}
      </div>
      {onSelectAll ? (
        <div className={'form__body_ttl_allcheck'}>
          <label className={'form__label_side'}>
            <input
              type='checkbox'
              checked={checked}
              onChange={onSelectAll}
              className={'form__checkbox'}
            />
            <mark></mark>
            <span>全て選択</span>
          </label>
        </div>
      ) : null}
    </div>
  )
}
