import React from 'react'
import { Tooltip as MuiTooptip, IconButton } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

type Props = {
  title: NonNullable<React.ReactElement>
}

const MessageInputToolTip = ({ title }: Props) => {
  const classes = useStyles()
  const Tooltip = withStyles({
    tooltip: {
      fontSize: '1em',
      maxWidth: 'none',
    },
    popper: {
      whiteSpace: 'pre-line',
    },
  })(MuiTooptip)

  return (
    <Tooltip title={title} placement='top' tabIndex={-1}>
      <IconButton className={classes.iconButton}>
        <HelpOutlineIcon fontSize='large' />
      </IconButton>
    </Tooltip>
  )
}

export default MessageInputToolTip
