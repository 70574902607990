import React from 'react'
import { FieldProps } from 'formik'
import {
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { Field } from 'formik'
import { IntegrationReactSelect, OptionType } from './Select'
import InputWithCounter from './InputWithCounter'

interface Props extends FieldProps {
  disabled?: boolean
  label: string
  radiolist: {
    label: string
    value: string
  }[]
  setError: ({ isError: boolean, message: string }: any) => void
  setError2: ({ isError: boolean, message: string }: any) => void
  options: OptionType[]
  initId: string
  error: { isError: boolean; message: string }
  error2: { isError: boolean; message: string }
}

const Toggle = ({
  field,
  form,
  label,
  disabled,
  radiolist,
  setError,
  setError2,
  options,
  initId,
  error,
  error2,
  ...others
}: Props) => {
  const { name, value } = field

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.value)
    if (e.target.value === '0') {
      form.setFieldValue('searchInsertName', '')
      form.setFieldValue('searchInsertActionName', '')
      setError({ isError: false, message: '' })
      setError2({ isError: false, message: '' })
    } else if (e.target.value === '1') {
      form.setFieldValue('searchInsertActionName', '')
      setError2({ isError: false, message: '' })
    } else if (e.target.value === '2') {
      form.setFieldValue('searchInsertName', '')
      setError({ isError: false, message: '' })
    }
  }

  return (
    <RadioGroup
      aria-label='gender'
      value={value}
      onChange={handleChange}
      {...others}>
      {radiolist.map(i =>
        i.value === '2' ? (
          // アクション設定は非表示
          ''
        ) : (
            <>
              <div className="mb30">
                <div className="mb18">
                  <label className="form__label_side">
                    <Radio className='form__radio' value={i.value} key={i.value} disabled={disabled}
                      style={{ padding: 0 }}
                      checked={value === i.value}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_off}"}>
                          <use xlinkHref={"#form__radio_off"}></use>
                        </svg>
                      }
                      checkedIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_on}"}>
                          <use xlinkHref={"#form__radio_on"}></use>
                        </svg>
                      } />
                    <span className="form__radio_text_2">{i.label}</span>
                  </label>
                </div>
                {i.value === '0' ? (
                  <Field
                    name={'searchName'}
                    options={options}
                    initId={initId}
                    component={IntegrationReactSelect}
                  />) : i.value === '1' ? (
                    <Field
                      name='searchInsertName'
                      component={InputWithCounter}
                      maxAmount={100}
                      setError={setError}
                      error={error}
                    />) : ''}
              </div>
            </>
          )
      )}
    </RadioGroup>
  )
}

export default Toggle
