import { Chip, FormControlLabel, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldGroup, FormField, SubTitle, Title } from 'sp/componentsHsc'
import { TextFieldForMessage } from 'sp/components'
import { FastField, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from './GroupOptions'
import { SelectOptions } from './Select'
import { FastSelectOptions } from './FastSelect'
import {
  ManagementItemCondition,
  TestObj,
} from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import {
  setManagementModalStateJobChange,
  getMcaxs501Init,
} from 'reducers/itemSpecificationReducer'
import { setManagementModalOpen } from 'reducers/advancedSearchJobChangeReducer'
import { setSearchCondition } from 'reducers/entryListReducer'
import { toEntryList } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { getMessage } from 'common/messageUtil'
import { dateToString, isEmpty } from 'common/generalUtil'
import Modal from './Modal/Modal'
import {
  opqObject,
  opqTitles,
  gabObject,
  gabTitles,
  imagesObject,
  imagesTitles,
  cabObject,
  cabTitles,
} from 'componentsHsc/AdvancedSearch/Config/modalConfig'
import CancelIcon from '@material-ui/icons/Cancel'
import { magiContants } from 'utils/contants'
import { ClickAwayListener } from '@material-ui/core'

const useStyles = makeStyles(theme => ({

  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'inherit !important',
  },
  chip: {
    margin: theme.spacing(0.38, 0),
    fontSize: '12px',
    maxWidth: '295px',
    backgroundColor: '#eceff1',
    height: '30px',
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  tag: {
    paddingLeft: 10,
    marginRight: 80,
  },
  datePickerDiv: {
    width: '130px',
    float: 'left',
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: theme.spacing(1.625, 0.625, 0, 1),
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '200px',
    top: '-38px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '200px',
    marginTop: '-38px',
  },
  cursor: {
    '& div': {
      cursor: 'pointer!important',
    },
  },
  tagSelectAllStyle: {
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
    },
  },
  frequentlyUsedTagRow: {
    margin: '-16px 80px 16px 80px !important',
  },
  formControl: {
    border: '0px solid !important',
  },
  itemControl: {
    padding: '2px 0 2px 0 !important',
  },

  bodyItem: {
    margin: '0 0 0px 10px !important',
  },
  itemCloumn: {
    margin: '0 0 20px 10px',
  },
  styleCloumn: {
    display: 'contents',
    flexdirection: 'column',
  },
  iconMargin: {
    margin: '0 0 0 10px !important',
  },

  bodyItemcloumn: {
    margin: '0 0 20px 10px',
    display: 'block',
  },
  itemInput: {
    alignItems: 'baseline !important',
    '& div': {
      marginTop: 'initial ',
      marginBottom: '4px',
      width: '144px',
    },
  },
  text_while: {
    width: 'inherit !important',
    padding: '0 8px',
  },

  pToolTip: {
    ' & p ': {
      wordWrap: 'break-word',
    },
  },
  labelSide: {
    display: 'inline-flex',
    alignItems: 'center',
  }
}))

interface Props {
  form: FormControl,
  setIsToolTipShow: (value: boolean) => void
}


// #58578 次期開発2022年5月 start
const generateLabelModal = (input: TestObj) => {
  if (input.physicalName.toLowerCase().includes('exam_language')) {
    const valueFrom = input.valueFrom
    return `${input.logicalName} ${valueFrom}`
  } else {
    const valueFrom =
      input.physicalName.includes('Date') || input.physicalName.includes('date')
        ? isEmpty(input.valueFrom)
          ? ''
          : dateToString(new Date(input.valueFrom))
        : input.valueFrom
    const valueTo =
      input.physicalName.includes('Date') || input.physicalName.includes('date')
        ? isEmpty(input.valueTo)
          ? ''
          : dateToString(new Date(input.valueTo))
        : input.valueTo

    const from = valueFrom
      ? valueTo
        ? valueFrom === valueTo
          ? `${valueFrom}`
          : `${valueFrom} ～ `
        : `${valueFrom} ～`
      : ''
    const to = valueTo
      ? valueFrom
        ? valueFrom === valueTo
          ? ''
          : valueTo
        : `～ ${valueTo}`
      : ''

    const logicalName = '' === from && '' === to ? '' : input.logicalName
    return `${logicalName} ${from}${to}`
  }
}
const modalData = {
  OPQ情報: { object: opqObject, title: opqTitles },
  GAB情報: { object: gabObject, title: gabTitles },
  イメジス情報: { object: imagesObject, title: imagesTitles },
  CAB情報: { object: cabObject, title: cabTitles },
}
const nameSet = {
  OPQ情報: 'opqObj',
  GAB情報: 'gabObj',
  イメジス情報: 'imagesObj',
  CAB情報: 'cabObj',
}
type SHLTitle = keyof typeof modalData
// #58578 次期開発2022年5月 end

const generateLabel = (item: ManagementItemCondition) => {
  let valueText: string = ''
  const itemText: string = item.recruitmentManagementFlagName

  if (item.managementItemPresenceFlag === '1') {
    valueText = '値がない'
  } else {
    switch (item.managementItemTypeCode) {
      case '10':
        if (item.managementItemTagText === '') {
          valueText = '値がある全て'
        } else {
          valueText = `[${item.managementItemTagText}]が含まれる`
        }
        break
      case '20':
        if (
          item.managementItemTagFrom === '' &&
          item.managementItemFlagTo === ''
        ) {
          valueText = '値がある全て'
        } else if (item.managementItemTagFrom === item.managementItemFlagTo) {
          valueText = item.managementItemTagFrom
        } else if (item.managementItemTagFrom !== item.managementItemFlagTo) {
          valueText = `${item.managementItemTagFrom}～${item.managementItemFlagTo}`
        }
        break
      case '30':
        if (item.managementItemFlagCheckBoxObj.length === 0) {
          valueText = '値がある全て'
        } else {
          const valueTextList = item.managementItemFlagCheckBoxObj.map(
            i => i.label
          )
          valueText = valueTextList.join('、')
        }
        if (item.managementItemPresenceFlag === '2') {
          valueText = valueText + ' を除く'
        }
        break
      default:
        break
    }
  }

  return `${itemText} ${valueText}`
}

// 採用管理情報
const RecruitmentManagement = ({ form, setIsToolTipShow }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const invisibleRecruitmentManagementFlagSettingIdList = useSelector(
    (state: RootState) =>
      state.itemSpecification.invisibleRecruitmentManagementFlagSettingIdList
  )

  // 転職Booster start
  const { serviceMode, marchServiceFlag } = useSelector(
    (state: RootState) => state.globalMenu
  )
  // 転職Booster end

  const [tagExclusionCondition, setTagExclusionCondition] = useState('1') // 判定条件（タグ除外） ANR/OR
  const [tagCondition, setTagCondition] = useState('1') // 判定条件（タグ） ANR/OR
  const handleTagExclusionCondition = (e: any) => {
    const tagExclusionConditionObj =
      e.target.value === tagExclusionCondition ? '' : e.target.value
    setTagExclusionCondition(tagExclusionConditionObj)
  }
  const { conditionList = [], typeName = '' } =
    form.values.managementItemObj || {}

  // 初期表示データ設定
  const {
    selectionFlowList,
    selectionStepList,
    judgementList,
    tagList,
    frequentlyUsedTagList,
    displayShlInfo, // #58578 次期開発2022年5月
  } = useSelector(
    (state: RootState) =>
      state.advancedSearchJobChange.recruitmentManagementInfo
  )

  const selectionFlowOptionList =
    selectionFlowList != null
      ? selectionFlowList.map(i => {
        return { label: i.selectionName, value: i.selectionFlowSettingId }
      })
      : []

  const selectionStepOptionList =
    selectionStepList != null
      ? selectionStepList.map(i => {
        return { label: i.progressName, value: i.progressStatusSettingId }
      })
      : []

  const judgementOptionList = judgementList.map(i => {
    return { label: i.itemName, value: i.itemCode }
  })

  const tagOptionList = tagList.map(i => {
    return { label: i.tagName, value: String(i.tagSettingId) }
  })

  // タグ除外
  const tagExclusionOptionList = tagList.map(i => {
    return { label: i.tagName, value: String(i.tagSettingId) }
  })
  const frequentlyUsedTagOptionList = frequentlyUsedTagList.map(i => {
    return { label: i.tagName, key: String(i.tagSettingId) }
  })
  // よく使われるタグ除外
  const frequentlyUsedTagExclusionOptionList = frequentlyUsedTagList.map(i => {
    return { label: i.tagName, key: String(i.tagSettingId) }
  })

  /**
   * よく使われるタグクリック
   */
  const tagClick = (data: any) => (e: any) => {
    const newTag = { label: data.label, value: data.key }
    const newTagDate = form.values['tagTxtObj']
    if (newTagDate) {
      //タグが入力されている場合
      if (!newTagDate.map(tag => tag.value).includes(newTag.value)) {
        //重複が無ければ選択したタグを追加
        newTagDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagTxtObj', newTagDate)
      } else {
        //重複があれば何もしない
        return
      }
    } else {
      //タグが未入力の場合、タグを追加
      form.setFieldValue('tagTxtObj', [{ label: data.label, value: data.key }])
    }
  }

  /**
   * よく使われるタグ除外クリック
   */
  const tagExclusionClick = (data: any) => (e: any) => {
    const newTagExclusion = { label: data.label, value: data.key }
    const newTagExclusionDate = form.values['tagExclusionTxtObj']
    if (newTagExclusionDate) {
      //タグ除外が入力されている場合
      if (
        !newTagExclusionDate
          .map(tagExclusion => tagExclusion.value)
          .includes(newTagExclusion.value)
      ) {
        //重複が無ければ選択したタグ除外を追加
        newTagExclusionDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagExclusionTxtObj', newTagExclusionDate)
      } else {
        //重複があれば何もしない
        return
      }
    } else {
      //タグ除外が未入力の場合、タグを追加
      form.setFieldValue('tagExclusionTxtObj', [
        { label: data.label, value: data.key },
      ])
    }
  }

  useEffect(() => {
    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: true,
        isJobChange: true,
        toggle: () => { },
      })
    )
    // 検索一覧に戻る_預け入れタグ除外条件判定FLGを取得します
    if (!isEmpty(form.values['tagExclusionJudgmentFlag'])) {
      setTagExclusionCondition(String(form.values['tagExclusionJudgmentFlag']))
    }
    // 検索一覧に戻る_預け入れタグ条件判定FLGを取得します
    if (!isEmpty(form.values['tagJudgmentFlag'])) {
      setTagCondition(String(form.values['tagJudgmentFlag']))
    }
  }, [])

  //タグ除外条件判定FLG と タグ除外条件判定名_(タグ除外とタグ除外条件判定FLGの変化をモニターします)
  useEffect(() => {
    if (
      form.values['tagExclusionTxtObj'] != null &&
      form.values['tagExclusionTxtObj'].length > 0
    ) {
      form.setFieldValue('tagExclusionJudgmentFlag', tagExclusionCondition)
      form.setFieldValue(
        'tagExclusionJudgmentName',
        tagExclusionCondition === '0' ? '全てと一致' : 'いずれかに一致'
      )
    }
  }, [form.values['tagExclusionTxtObj'], tagExclusionCondition])

  //タグ条件判定FLG と タグ条件判定名_(タグとタグ条件判定FLGの変化をモニターします)
  useEffect(() => {
    if (
      form.values['tagTxtObj'] != null &&
      form.values['tagTxtObj'].length > 0
    ) {
      form.setFieldValue('tagJudgmentFlag', tagCondition)
      form.setFieldValue(
        'tagJudgmentName',
        tagCondition === '0' ? '全てと一致' : 'いずれかに一致'
      )
    }
  }, [form.values['tagTxtObj'], tagCondition])

  const managementModalState = useSelector(
    (state: RootState) => state.itemSpecification.managementModalStateJobChange
  )
  const handleManagementSettingClick = () => {
    let isInitManagementItemObj = true
    const newManagementModalState = {
      ...managementModalState,
    }
    const managementItemObj = form.values.managementItemObj
    newManagementModalState.typeCode = managementItemObj && managementItemObj.typeCode
    if (
      managementModalState.conditionList &&
      managementModalState.conditionList.length > 0
    )
      if (form.values.managementItemObj) {
        isInitManagementItemObj = false
        const newCond: ManagementItemCondition[] = []
        if (newManagementModalState.conditionList) {
          newManagementModalState.conditionList.forEach(i => {
            const findResult = managementItemObj && managementItemObj.conditionList.find(
              itemObjCondition =>
                itemObjCondition.recruitmentManagementFlagSettingId ===
                i.recruitmentManagementFlagSettingId
            )
            if (findResult) {
              newCond.push(findResult)
            } else {
              newCond.push({
                ...i,
                managementItemPresenceFlag: '',
                managementItemTagText: '',
                managementItemTagFrom: '',
                managementItemFlagTo: '',
                managementItemFlagCheckBoxObj: [],
              })
            }
          })
        }

        newManagementModalState.conditionList = newCond
      }
    dispatch(setManagementModalStateJobChange(newManagementModalState))

    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: isInitManagementItemObj,
        isJobChange: true,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setManagementModalOpen(true))
        },
      })
    )
  }

  const handleChipDelete = (
    conditionList: ManagementItemCondition[],
    index: number
  ) => {
    const newConditionList = conditionList.slice()
    newConditionList.splice(index, 1)
    if (newConditionList.length > 0) {
      form.setFieldValue('managementItemObj.conditionList', newConditionList)
    } else {
      form.setFieldValue('managementItemObj', null)
    }
    handleTooltipClose()
  }

  // AND/OR状態を取得
  const handleTagCondition = (e: any) => {
    const tagConditionObj =
      e.target.value === tagCondition ? '' : e.target.value
    setTagCondition(tagConditionObj)
  }

  const optionSet = {
    selectionStepObj: selectionStepOptionList,
    judgmentUndeterminedObj: judgementOptionList,
    tagTxtObj: tagOptionList,
    tagExclusionTxtObj: tagExclusionOptionList,
  }

  const handleSelectAll = (name: keyof typeof optionSet) => (e: any) => {
    form.setFieldValue(name, e.target.checked ? optionSet[name] : [])
  }

  const isChecked = (name: keyof typeof optionSet) => {
    const newValue = form.values[name]
    if (newValue && newValue.length === optionSet[name].length) {
      return true
    }

    return false
  }

  // 全て選択（タグ）
  const tagHandleSelectAll = (
    tagAll: {
      label: string
      value: string
    }[]
  ) => (e: any) => {
    form.setFieldValue('tagTxtObj', e.target.checked ? tagAll : [])
  }



  // 全て選択（タグ） 状態
  const tagIsChecked = () => {
    const newValue = form.values['tagTxtObj']
    if (
      newValue &&
      newValue.length === tagOptionList.length &&
      newValue.length > 0
    ) {
      return true
    }
    return false
  }

  const handleTagExclusionSelectAll = (
    tagExclusionSelectAll: {
      label: string
      value: string
    }[]
  ) => (e: any) => {
    form.setFieldValue(
      'tagExclusionTxtObj',
      e.target.checked ? tagExclusionSelectAll : []
    )
  }

  const tagExclusionChecked = () => {
    const newValue = form.values['tagExclusionTxtObj']
    if (
      newValue &&
      newValue.length === tagOptionList.length &&
      newValue.length > 0
    ) {
      return true
    }
    return false
  }

  // #58578 次期開発2022年5月 start
  const OPQSubmitFlag = useSelector(
    (state: RootState) => state.advancedSearchModal.OPQSubmitFlag
  )
  const GABSubmitFlag = useSelector(
    (state: RootState) => state.advancedSearchModal.GABSubmitFlag
  )
  const ImagesSubmitFlag = useSelector(
    (state: RootState) => state.advancedSearchModal.ImagesSubmitFlag
  )
  const CABSubmitFlag = useSelector(
    (state: RootState) => state.advancedSearchModal.CABSubmitFlag
  )

  const OPQModalObj = useSelector(
    (state: RootState) => state.advancedSearchModal.OPQModalObj
  )
  const GABModalObj = useSelector(
    (state: RootState) => state.advancedSearchModal.GABModalObj
  )
  const ImagesModalObj = useSelector(
    (state: RootState) => state.advancedSearchModal.ImagesModalObj
  )
  const CABModalObj = useSelector(
    (state: RootState) => state.advancedSearchModal.CABModalObj
  )

  const opqObj = OPQSubmitFlag ? form.values.opqObj || [] : OPQModalObj || []
  const gabObj = GABSubmitFlag ? form.values.gabObj || [] : GABModalObj || []
  const imagesObj = ImagesSubmitFlag
    ? form.values.imagesObj || []
    : ImagesModalObj || []
  const cabObj = CABSubmitFlag ? form.values.cabObj || [] : CABModalObj || []

  const handleDeleteOPQObj = (option: TestObj) => () => {
    if (opqObj) {
      const deleteNumber = opqObj.indexOf(option)
      if (deleteNumber >= 0) {
        opqObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = opqObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('opqObj', newConditionList)
      } else {
        form.setFieldValue('opqObj', null)
      }
    }
  }
  const handleDeleteGABObj = (option: TestObj) => () => {
    if (gabObj) {
      const deleteNumber = gabObj.indexOf(option)
      if (deleteNumber >= 0) {
        gabObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = gabObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('gabObj', newConditionList)
      } else {
        form.setFieldValue('gabObj', null)
      }
    }
  }
  const handleDeleteImages = (option: TestObj) => () => {
    if (imagesObj) {
      const deleteNumber = imagesObj.indexOf(option)
      if (deleteNumber >= 0) {
        imagesObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = imagesObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('imagesObj', newConditionList)
      } else {
        form.setFieldValue('imagesObj', null)
      }
    }
  }
  const handleDeleteCABObj = (option: TestObj) => () => {
    if (cabObj) {
      const deleteNumber = cabObj.indexOf(option)
      if (deleteNumber >= 0) {
        cabObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = cabObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('cabObj', newConditionList)
      } else {
        form.setFieldValue('cabObj', null)
      }
    }
  }
  // Modal
  const [state, setState] = useState({
    general: false,
    management: false,
  })
  const [currentModal, setCurrentModal] = useState<SHLTitle>('OPQ情報')
  //適性検査モーダル:検索分類選択state
  const [selectedSubtitle, setSelectedSubtitle] = useState(
    modalData[currentModal].title[0]
  )
  const handleClick = (title: SHLTitle) => () => {
    setSelectedSubtitle(modalData[currentModal].title[0])
    setCurrentModal(title)
    setState({ ...state, general: true })
  }
  const handleModal = (modalName: string, open = true) => () => {
    setState({
      ...state,
      [modalName]: open,
    })
  }
  // #58578 次期開発2022年5月 end
  const [toolTip, setToolTip] = useState('')
  const handleToolTip = (id: string) => {
    setToolTip(id)
  }

  const handleOffToolTip = () => {
    setToolTip('')
  }

  const [toolTip0, setToolTip0] = useState('')
  const clickToolTip = (id: string) => {
    setToolTip0(id)
  }

  const clickOffToolTip = () => {
    setToolTip0('')
  }

  const [openTooltipIndex, setopenTooltipIndex] = useState(null)
  const [tooltipContent, setTooltipContent] = useState('')

  const handleTooltipOpen = (Index: any, content: any) => {
    setopenTooltipIndex(Index);
    setTooltipContent(content);
  };

  const handleTooltipClose = () => {
    setopenTooltipIndex(null);
    setTooltipContent('');
  };


  const [openTooltipOPQ, setopenTooltipOPQ] = useState(null)
  const [tooltipPhysicalNameOPQ, setTooltipPhysicalNameOPQ] = useState('')

  const handleTooltipOPQOpen = (i: any, PhysicalName: any) => {
    setopenTooltipOPQ(i);
    setTooltipPhysicalNameOPQ(PhysicalName);
  };

  const handleTooltipOPQClose = () => {
    setopenTooltipOPQ(null);
    setTooltipPhysicalNameOPQ('');
  };


  const [openTooltipGAB, setopenTooltipGAB] = useState(null)
  const [tooltipPhysicalNameGAB, setTooltipPhysicalNameGAB] = useState('')

  const handleTooltipGABOpen = (i: any, PhysicalName: any) => {
    setopenTooltipGAB(i);
    setTooltipPhysicalNameGAB(PhysicalName);
  };

  const handleTooltipGABClose = () => {
    setopenTooltipGAB(null);
    setTooltipPhysicalNameGAB('');
  };

  const [openTooltipImages, setopenTooltipImages] = useState(null)
  const [tooltipPhysicalNameImages, setTooltipPhysicalNameImages] = useState('')

  const handleTooltipImagesOpen = (i: any, PhysicalName: any) => {
    setopenTooltipImages(i);
    setTooltipPhysicalNameImages(PhysicalName);
  };

  const handleTooltipImagesClose = () => {
    setopenTooltipImages(null);
    setTooltipPhysicalNameImages('');
  };

  const [openTooltipCAB, setopenTooltipCAB] = useState(null)
  const [tooltipPhysicalNameCAB, setTooltipPhysicalNameCAB] = useState('')

  const handleTooltipCABOpen = (i: any, PhysicalName: any) => {
    setopenTooltipCAB(i);
    setTooltipPhysicalNameCAB(PhysicalName);
  };

  const handleTooltipCABClose = () => {
    setopenTooltipCAB(null);
    setTooltipPhysicalNameCAB('');
  };

  useEffect(() => {
    if (toolTip0.length > 0 || toolTip.length > 0 || openTooltipIndex || openTooltipOPQ || openTooltipGAB || openTooltipImages || openTooltipCAB) {
      setIsToolTipShow(true)
    } else {
      setIsToolTipShow(false)
    }
  }, [toolTip, toolTip0, openTooltipIndex, openTooltipOPQ, openTooltipGAB, openTooltipImages, openTooltipCAB])

  return (
    <>
      <div
        className={
          'tab_content_body detail-search__tab_content is-tab-content-selected'
        }>
        <div className={'form__ttl'}>選考フロー/選考ステップ</div>
        <div className={'form__body'}>
          <div className={'data-js-selectbox'}>
            <div className={'form__body_ttl'}>選考フロー</div>
            <div className={'form__body_item mb15'}>
              <div className={`${classes.formControl} form__control `}>
                <div className={`${classes.itemControl} form__control_item`}>
                  <FastField
                    name='selectionFlowObj'
                    optionList={selectionFlowOptionList}
                    defaultValue={form.values.selectionFlowObj}
                    placeholder='選択してください。'
                    component={SelectOptions}
                    className={'form__control_input text-overflow'}
                    selectClassName={
                      'form__control_item_singleValue is-suggest-hidden'
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 転職Booster start */}
          <div
            className={'data-js-allcheck'} >
            {/* 転職Booster end */}
            <div className={'form__body_ttl_add'}>
              <div className={'form__body_ttl'}>選考ステップ</div>
              <ClickAwayListener onClickAway={handleOffToolTip}>
                <svg
                  className={`${classes.iconMargin} form__body_ttl_icon`}
                  focusable='false'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  onClick={() => handleToolTip('service')}>
                  <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
                </svg>
              </ClickAwayListener>
              {/* 選考ステップ と　選考ステップ（Boosterモード)*/}
              {toolTip === "service" && (
                <div id='tooltip-1' className='tooltip is-tooltip-show'>
                  <p>{serviceMode === magiContants.STRING_1 &&
                    marchServiceFlag === magiContants.STRING_1
                    ? getMessage('MCAXS431-003')
                    : getMessage('MCAXS431-002')}</p>
                </div>
              )}
              {/* 選考ステップ　と　選考ステップ（Boosterモード)*/}
              {selectionStepOptionList.length > 0 && (
                <div className={'form__body_ttl_allcheck'}>
                  <label className={'form__label_side'}>
                    <input
                      type='checkbox'
                      onChange={
                        selectionStepOptionList.length > 0
                          ? handleSelectAll('selectionStepObj')
                          : undefined
                      }
                      checked={isChecked('selectionStepObj')}

                      className={'form__checkbox'}
                    />
                    <mark></mark>
                    <span>全て選択</span>
                  </label>
                </div>
              )}
            </div>
            {selectionStepOptionList.length > 0 && (
              <FastField
                name='selectionStepObj'
                component={GroupOptions}
                row
                optionList={selectionStepOptionList}
                groupClassName={'form__body_item'}
                individualClassName={'form__label_side'}
                checkBoxClassName={'form__checkbox'}
              />)}
          </div>
          <div
            className={'data-js-allcheck'}
          >
            <div className={'form__body_ttl_add'}>
              <div className={'form__body_ttl'}>合否</div>
              <ClickAwayListener onClickAway={clickOffToolTip}>
                <svg
                  className={`${classes.iconMargin} form__body_ttl_icon`}
                  focusable='false'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  onClick={() => clickToolTip('MCAXS431-001')}>
                  <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
                </svg>
              </ClickAwayListener>
              {/* 合否 */}
              {toolTip0 === "MCAXS431-001" && (
                <div id='tooltip-2' className='tooltip is-tooltip-show'>
                  <p>{getMessage('MCAXS431-001')}</p>
                </div>
              )}
              {/* 合否 */}
              {selectionStepOptionList.length > 0 && (
                <div className={'form__body_ttl_allcheck'}>
                  <label className={'form__label_side'}>
                    <input
                      type='checkbox'
                      onChange={
                        selectionStepOptionList.length > 0
                          ? handleSelectAll('judgmentUndeterminedObj')
                          : undefined
                      }
                      checked={isChecked('judgmentUndeterminedObj')}
                      className={'form__checkbox'}
                    />
                    <mark></mark>
                    <span>全て選択</span>
                  </label>
                </div>
              )}
            </div>
            {selectionStepOptionList.length > 0 && (
              <FastField
                name='judgmentUndeterminedObj'
                component={GroupOptions}
                row
                optionList={judgementOptionList}
                groupClassName={`${classes.bodyItem} form__body_item`}
                individualClassName={'form__label_side'}
                checkBoxClassName={'form__checkbox'}
              />
            )}
          </div>
        </div>
        <div className={'form__ttl'}>優先度</div>
        <div className={'form__body'}>
          <div className={'form__body_ttl'}>管理項目</div>
          <div className={classes.itemCloumn}>
            <div className={classes.styleCloumn}>
              <Button onClick={handleManagementSettingClick} />
              {conditionList.length > 0 && (
                <div
                  className={
                    window.navigator.userAgent
                      .toLocaleLowerCase()
                      .match(/(trident)\/([\d.]+)/)
                      ? classes.andOrStyleIE
                      : classes.andOrStyleOther
                  }>
                  {conditionList.filter(i =>
                    invisibleRecruitmentManagementFlagSettingIdList.includes(
                      i.recruitmentManagementFlagSettingId
                    )
                  ).length === conditionList.length
                    ? undefined
                    : typeName}
                </div>
              )}
              {conditionList.length > 0 && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    {conditionList.map((item, index) =>
                      invisibleRecruitmentManagementFlagSettingIdList.includes(
                        item.recruitmentManagementFlagSettingId
                      ) ? (
                        undefined
                      ) : (
                        <Chip
                          title={generateLabel(item)}
                          className={classes.chip}
                          key={index}
                          label={generateLabel(item)}
                          onClick={() =>
                            handleTooltipOpen(index, generateLabel(item))
                          }
                          onDelete={() =>
                            handleChipDelete(conditionList, index)
                          }
                        />
                      )
                    )}
                  </div>
                </ClickAwayListener>
              )}
              {/* 企業から応募者への質問 ツールチップ */}
              {openTooltipIndex !== null && (
                <div id='tooltip-4' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
                  <p>
                    {tooltipContent}
                  </p>
                </div>
              )}
              {/* 企業から応募者への質問 ツールチップ */}

            </div>
          </div>
          {/* 転職Booster start */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl'}>優先度</div>
              <div>
                <div className={`form__body_item_column ${classes.itemInput}`}>
                  <FastField
                    name='priorityFrom'
                    type='text'
                    component={TextFieldForMessage}
                    stytle={{ display: 'block' }}
                    placeholder='半角数字'
                    className={'form__input_140w'}
                  />
                  <div className={` ${classes.text_while}`}>～</div>
                  <FastField
                    name='priorityTo'
                    component={TextFieldForMessage}
                    placeholder='半角数字'
                    className={`form__input_140w`}
                  />
                </div>
              </div>
            </div>
          )}
          {/* 転職Booster end */}
          {/* 転職Booster start */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl_add'}>
                <div className={'form__body_ttl'}>タグ</div>
                <Title
                  title=''
                  onSelectAll={tagHandleSelectAll(tagOptionList)}
                  checked={tagIsChecked()}
                />
              </div>
              <div className={'form__body_item mb15'}>
                <Field
                  name='tagTxtObj'
                  isMulti
                  component={FastSelectOptions}
                  className={classes.cursor}
                  defaultValue={form.values.tagTxtObj}
                  optionList={tagOptionList}
                  noOptionsMessage='一致する候補が見つかりません'
                  placeholder='キーワードを入力'

                />
                <div className={'entry-select-filter__tag'}>
                  {/* AND/OR */}
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='0'
                        name='tagCondition'
                        onChange={handleTagCondition}
                        checked={tagCondition === '0'}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_off}"}>
                            <use xlinkHref={"#form__radio_off"}></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_on}"}>
                            <use xlinkHref={"#form__radio_on"}></use>
                          </svg>
                        }
                      />
                    }
                    label='AND'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='1'
                        name='tagCondition'
                        onChange={handleTagCondition}
                        checked={tagCondition === '1'}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_off}"}>
                            <use xlinkHref={"#form__radio_off"}></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_on}"}>
                            <use xlinkHref={"#form__radio_on"}></use>
                          </svg>
                        }
                      />
                    }
                    label='OR'
                  />
                </div>
              </div>
            </div>
          )}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div className={`${classes.bodyItemcloumn} ml20`}>
              <div className={'entry-select-filter__text mb10'}>よく使われるタグ：</div>
              <div className={'entry-select-filter__tag'}>
                {frequentlyUsedTagOptionList.map(data => (
                  <Chip
                    key={data.key}
                    onClick={tagClick(data)}
                    label={data.label}
                    className={'btn__tag_item'}
                  />
                ))}
              </div>
            </div>
          )}
          {/* 転職Booster end */}
          {/* 転職Booster start */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl_add'}>
                <div className={'form__body_ttl'}>タグ（除外）</div>
                <Title
                  title=''
                  onSelectAll={handleTagExclusionSelectAll(
                    tagExclusionOptionList
                  )}
                  checked={tagExclusionChecked()}
                />
              </div>
              <div className={'form__body_item mb15'}>
                <Field
                  name='tagExclusionTxtObj'
                  isMulti
                  component={FastSelectOptions}
                  className={classes.cursor}
                  defaultValue={form.values.tagExclusionTxtObj}
                  optionList={tagExclusionOptionList}
                  noOptionsMessage='一致する候補が見つかりません'
                  placeholder='キーワードを入力'
                />
                <div className={'entry-select-filter__tag'}>
                  {/* AND/OR */}
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='0'
                        name='tagExclusionCondition'
                        onChange={handleTagExclusionCondition}
                        checked={tagExclusionCondition === '0'}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_off}"}>
                            <use xlinkHref={"#form__radio_off"}></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_on}"}>
                            <use xlinkHref={"#form__radio_on"}></use>
                          </svg>
                        }
                      />
                    }
                    label='AND'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value='1'
                        name='tagExclusionCondition'
                        onChange={handleTagExclusionCondition}
                        checked={tagExclusionCondition === '1'}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_off}"}>
                            <use xlinkHref={"#form__radio_off"}></use>
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" className={"form__radio_svg_on}"}>
                            <use xlinkHref={"#form__radio_on"}></use>
                          </svg>
                        }
                      />
                    }
                    label='OR'
                  />
                </div>
              </div>
            </div>
          )}
          {/* 転職Booster end */}
          {/* 転職Booster start */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div className={`${classes.bodyItemcloumn} ml20`}>
              <div className={'entry-select-filter__text mb10'}>よく使われるタグ：</div>
              <div className={'entry-select-filter__tag'}>

                <div className={'entry-select-filter__tag'}>
                  {frequentlyUsedTagExclusionOptionList.map(data => (
                    <Chip
                      key={data.key}
                      onClick={tagExclusionClick(data)}
                      label={data.label}
                      className={'btn__tag_item'}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* 転職Booster end */}

        {/* #58578 次期開発2022年5月 start */
          displayShlInfo.displayOpq ||
            displayShlInfo.displayGab ||
            displayShlInfo.displayImages ||
            displayShlInfo.displayCab ? (
            <div>
              <div className={'form__ttl'}>評価</div>
              <div className={'form__body'}>
                {displayShlInfo.displayOpq && (
                  <div>
                    <Title title='OPQ' className={'form__body_ttl'} />
                    <div className={`form__body_item_gap0 mb13 ${classes.modal}`}>
                      <Button onClick={handleClick('OPQ情報')} />
                      {opqObj.length > 0 && (
                        <ClickAwayListener onClickAway={handleTooltipOPQClose}>
                          <div>
                            {opqObj.map(i =>
                              i && i.physicalName && i.physicalName !== '' ? (
                                <Chip
                                  title={generateLabelModal(i)}
                                  key={i.physicalName}
                                  className={classes.chip}
                                  label={generateLabelModal(i)}
                                  onDelete={handleDeleteOPQObj(i)}
                                  deleteIcon={<CancelIcon />}
                                  onClick={() =>
                                    handleTooltipOPQOpen(i.physicalName, generateLabelModal(i))
                                  }
                                />
                              ) : null
                            )}
                          </div>
                        </ClickAwayListener>
                      )}
                      {/* OPQ情報 ツールチップ  */}
                      {openTooltipOPQ !== null && (
                        <div id='tooltip-5' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
                          <p>
                            {tooltipPhysicalNameOPQ}
                          </p>
                        </div>
                      )}
                      {/* OPQ情報 ツールチップ */}
                    </div>
                  </div>
                )}
                {displayShlInfo.displayGab && (
                  <div>
                    <Title title='GAB' className={'form__body_ttl'} />
                    <div className={`form__body_item_gap0 mb13 ${classes.modal}`}>
                      <Button onClick={handleClick('GAB情報')} />
                      {gabObj.length > 0 && (
                        <ClickAwayListener onClickAway={handleTooltipGABClose}>
                          <span>
                            {gabObj.map(i =>
                              i && i.physicalName && i.physicalName !== '' ? (
                                <Chip
                                  title={generateLabelModal(i)}
                                  key={i.physicalName}
                                  className={classes.chip}
                                  label={generateLabelModal(i)}
                                  onDelete={handleDeleteGABObj(i)}
                                  deleteIcon={<CancelIcon />}
                                  onClick={() =>
                                    handleTooltipGABOpen(i.physicalName, generateLabelModal(i))
                                  }
                                />
                              ) : null
                            )}
                          </span>
                        </ClickAwayListener>
                      )}
                      {/* GAB情報 ツールチップ  */}
                      {openTooltipGAB !== null && (
                        <div id='tooltip-5' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
                          <p>
                            {tooltipPhysicalNameGAB}
                          </p>
                        </div>
                      )}
                      {/* GAB情報 ツールチップ */}
                    </div>
                  </div>
                )}
                {displayShlInfo.displayImages && (
                  <div>
                    <Title title='イメジス' className={'form__body_ttl'} />
                    <div className={`form__body_item_gap0 mb13 ${classes.modal}`}>
                      <Button onClick={handleClick('イメジス情報')} />
                      {imagesObj.length > 0 && (
                        <ClickAwayListener onClickAway={handleTooltipImagesClose}>
                          <span>
                            {imagesObj.map(
                              i =>
                                i &&
                                i.physicalName &&
                                i.physicalName !== '' && (
                                  <Chip
                                    title={generateLabelModal(i)}
                                    className={classes.chip}
                                    label={generateLabelModal(i)}
                                    onDelete={handleDeleteImages(i)}
                                    deleteIcon={<CancelIcon />}
                                    onClick={() =>
                                      handleTooltipImagesOpen(i.physicalName, generateLabelModal(i))
                                    }
                                  />
                                )
                            )}
                          </span>
                        </ClickAwayListener>
                      )}
                      {/* Images情報 ツールチップ  */}
                      {openTooltipImages !== null && (
                        <div id='tooltip-5' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
                          <p>
                            {tooltipPhysicalNameImages}
                          </p>
                        </div>
                      )}
                      {/* Images情報 ツールチップ */}
                    </div>
                  </div>
                )}
                {displayShlInfo.displayCab && (
                  <div>
                    <Title title='CAB' className={'form__body_ttl'} />
                    <div className={`form__body_item_gap0 mb13 ${classes.modal}`}>
                      <Button onClick={handleClick('CAB情報')} />
                      {cabObj.length > 0 && (
                        <ClickAwayListener onClickAway={handleTooltipCABClose}>
                          <span>
                            {cabObj.map(i =>
                              i && i.physicalName && i.physicalName !== '' ? (
                                <Chip
                                  title={generateLabelModal(i)}
                                  key={i.physicalName}
                                  className={classes.chip}
                                  label={generateLabelModal(i)}
                                  onDelete={handleDeleteCABObj(i)}
                                  deleteIcon={<CancelIcon />}
                                  onClick={() =>
                                    handleTooltipCABOpen(i.physicalName, generateLabelModal(i))
                                  }
                                />
                              ) : null
                            )}
                          </span>
                        </ClickAwayListener>
                      )}
                      {/* CAB情報 ツールチップ  */}
                      {openTooltipCAB !== null && (
                        <div id='tooltip-5' className={`tooltip is-tooltip-show ${classes.pToolTip}`}>
                          <p>
                            {tooltipPhysicalNameCAB}
                          </p>
                        </div>
                      )}
                      {/* CAB情報 ツールチップ */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        <Modal
          title={`${currentModal}を指定`}
          optionSet={modalData[currentModal].object}
          subtitleList={modalData[currentModal].title}
          form={form}
          open={state.general}
          onClose={handleModal('general', false)}
          name={nameSet[currentModal]}
          selectedSubtitleState={[selectedSubtitle, setSelectedSubtitle]}
        />
        {/* #58578 次期開発2022年5月 end */}
      </div>
    </>
  )
}

export default RecruitmentManagement
