import React from 'react'
import { FieldProps } from 'formik'
import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import { PropTypes, makeStyles } from '@material-ui/core'

interface Props extends FieldProps {
  margin?: PropTypes.Margin
  disableFlag?: boolean
  icon?: React.ReactNode
  placeholder?: string
}

const useStyles = makeStyles({
  input: {
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    cursor: 'text',
    fontSize: '14px',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
    '&:focus': {
      borderColor: '#3f71bd',
    },
    '&:disabled': {
      cursor: 'auto',
      border: '1px solid lightgrey',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: '1px solid lightgrey',
      },
    },
  },
  inputForError: {
    border: '1px solid red',
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    padding: '0 8px',
    cursor: 'text',
    fontSize: '14px',
    '&:focus': {
      border: '2px solid red',
    },
  },
  disabled: {
    backgroundImage:
      'url(/css/img/common/input_calender_disable.png) !important',
  },
  icon: {
    borderLeft: '1px solid #ccc',
    padding: '0px 0px 0px 8px',
  },
  customDatepicker: {
    '& .MuiIconButton-root': {
      borderRadius: '50%',
    },

  },
})

const DatePicker = ({
  margin = 'none',
  disableFlag = false,
  icon,
  placeholder = '',
  field,
  form,
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { value, name } = field

  return (
    <MuiDatePicker
      className={classes.customDatepicker}
      InputProps={{ disableUnderline: true }}
      inputProps={{
        className:
          errors[name] && touched[name]
            ? 'form__input-calender-error'
            : 'form__input-calender',
      }}
      keyboardIcon={
        icon ? (
          icon
        ) : (
            <span
              className={`${'form__icon-input_calender'}  ${
                disableFlag ? classes.disabled : ''
                }`}
            />
          )
      }
      placeholder={placeholder}
      //      KeyboardButtonProps={{ edge: 'start' }}
      disabled={disableFlag}
      type={'datepicker'}
      inputVariant='standard'
      format='YYYY/MM/DD'
      margin={margin}
      name={name}
      value={isNaN(Date.parse(value)) ? null : new Date(value)}
      inputValue={value}
      helperText={errors[name] && touched[name] ? errors[name] : null}
      error={Boolean(errors[name] && touched[name])}
      onChange={(date, value) => {
        form.setFieldValue(field.name, value ? value : '', true)
        form.setFieldTouched(field.name, true, true)
      }}
      autoOk={true}
      cancelLabel='キャンセル'
    />
  )
}

export default DatePicker
