import { makeStyles } from '@material-ui/core/styles'
import { dateTimeToString, dateToString, isEmpty } from 'common/generalUtil'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { magiContants } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
    display: 'inline',
  },
  oblique: {
    fontStyle: "oblique",
    display: 'inline',
  },
  underline: {
    textDecoration: "underline",
    display: 'inline',
  },
  red: {
    color: 'red',
    display: 'inline',
  },
  orange: {
    color: 'orange',
    display: 'inline',
  },
  yellowgreen: {
    color: 'yellowgreen',
    display: 'inline',
  },
  lime: {
    color: 'lime',
    display: 'inline',
  },
  cyan: {
    color: 'cyan',
    display: 'inline',
  },
  blue: {
    color: 'blue',
    display: 'inline',
  },
  magenta: {
    color: 'magenta',
    display: 'inline',
  },
  notifyMailAddressDiv: {
    marginTop: '-22px'
  },
  link: {
    color: '#3380CC',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  sender: {
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  messagePreview: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    border: 'solid 0.5px',
    padding: '4px',
    marginTop: '5px',
    marginBottom: '5px'
  },
  message: {
    display: 'flex',
    marginTop: '10px',
    marginBottom: '10px'
  },
  notify: {
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  notifylabel: {
    // width: '168px'
  },
  notifylabel2: {
    float: 'right'
  },
}))

interface Props {
  recruitmentManagementDivision: string
  destinationDivision: string
  sendValue: viewConfig.MessageInput
  sendTimeValue: viewConfig.SendTimeInput
  notifyMailAddress: string
  notifyNotification: number
  // [phase2] start by zhangxp
  sendToNonMember: number
  // [phase2] end by zhangxp
  replyMailAddress: string
}

// [phase2] start by zhangxp
const MessageInput = ({ recruitmentManagementDivision, destinationDivision, sendValue, sendTimeValue, notifyMailAddress, notifyNotification, sendToNonMember, replyMailAddress }: Props) => {
  // [phase2] end by zhangxp
  const classes = useStyles();
  const [subjectState, setSubjectState] = useState("")
  const [bodyState, setBodyState] = useState("")
  const subjectVal = useSelector(
    (state: RootState) => state.messageSendConfirm.subject
  )
  const bodyVal = useSelector(
    (state: RootState) => state.messageSendConfirm.body
  )
  useEffect(() => {
    setSubjectState(subjectVal)
    // 送信先が「マイナビ優先」「e-mailのみ」の場合、本文に固定文言を追加
    if (destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      setBodyState(bodyVal + '<br /><br />※このメールはマイナビが提供する応募者管理システムを利用して送信されています※')
    } else {
      setBodyState(bodyVal)
    }
  })

  const getEntryBoxContractMediaNameString = (entryBoxId: string) => {
    const entryBoxList = sendValue.entryBox
    const entryBox = entryBoxList.find(item => String(item.entryBoxId) === entryBoxId)
    let contractMediaName = entryBox ? entryBox.contractMediaName : ''
    if (isEmpty(contractMediaName)) {
      entryBoxList.map(item => {
        let isContinue = true
        if (isContinue) {
          contractMediaName = !isEmpty(item.contractMediaName) ? item.contractMediaName : contractMediaName
          isContinue = String(item.entryBoxId) === entryBoxId ? false : true
        }
      })
    }
    return contractMediaName
  }

  const getSeminarContractMediaNameString = (seminarId: string) => {
    const seminarList = sendValue.seminar
    const seminar = seminarList.find(item => String(item.seminarId) === seminarId)
    let contractMediaName = seminar ? seminar.contractMediaName : ''
    if (isEmpty(contractMediaName)) {
      seminarList.map(item => {
        let isContinue = true
        if (isContinue) {
          contractMediaName = !isEmpty(item.contractMediaName) ? item.contractMediaName : contractMediaName
          isContinue = String(item.seminarId) === seminarId ? false : true
        }
      })
    }
    return contractMediaName
  }

  const generateEntryBoxString = (entryBoxId: string) => {
    const entryBoxList = sendValue.entryBox
    const entryBox = entryBoxList.find(item => String(item.entryBoxId) === entryBoxId)
    if (entryBox) {
      return entryBox.mynaviAttachedOptionName + '　期間：' + dateToString(entryBox.displayStartDate) + ' ～ ' + dateToString(entryBox.displayEndDate)
    }
    return ''
  }

  const generateSeminarString = (seminarId: string) => {
    const seminarList = sendValue.seminar
    const seminar = seminarList.find(item => String(item.seminarId) === seminarId)
    if (seminar) {
      return seminar.mynaviAttachedOptionName + '　期間：' + dateToString(seminar.displayStartDate) + ' ～ ' + dateToString(seminar.displayEndDate)
    }
    return ''
  }

  const openUrl = (valuses: string) => {
    window.open(valuses)
  }

  const getPreviewUrl = (sendValue: viewConfig.MessageInput, id: string, type: string) => {
    if (type === 'entryBoxId') {
      const entryBox = sendValue.entryBox.find(i => {
        return i.entryBoxId.toString() === id
      })
      if (entryBox) {
        return entryBox.previewUrl
      } else {
        return undefined
      }
    } else {
      const seminar = sendValue.seminar.find(i => {
        return i.seminarId.toString() === id
      })
      if (seminar) {
        return seminar.previewUrl
      } else {
        return undefined
      }
    }
  }

  const openPreviewUrl = (sendValue: viewConfig.MessageInput, id: string, type: string) => {
    if (type === 'entryBoxId') {
      const entryBox = sendValue.entryBox.find(i => {
        return i.entryBoxId.toString() === id
      })
      if (entryBox) {
        window.open(entryBox.previewUrl)
      } else {
        window.open("")
      }
    } else {
      const seminar = sendValue.seminar.find(i => {
        return i.seminarId.toString() === id
      })
      if (seminar) {
        window.open(seminar.previewUrl)
      } else {
        window.open("")
      }
    }
  }

  return (
    <section>
      <h2>メッセージ</h2>
      <div style={{ margin: '10px' }}>
        <dl>
          {/* [phase2] start by zhangxp */}
          {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && (destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY || destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)) && (
            <>
              <dt>マイナビ退会者を送信<br></br>対象者に含む</dt>
              <dd><br></br>{sendToNonMember == 1 ? '含む' : '含まない'}</dd>
            </>
          )}
          {/* [phase2] end by zhangxp */}

          {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) && (
            <div className={classes.message}>
              <p>送信元会社名：</p>
              <p className={classes.sender}>{sendValue.senderCompanyName}</p>
            </div>
          )}

          {destinationDivision !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
            <>
              <div className={classes.message}>
                <p>送信元メールアドレス：</p>
                <p className={classes.sender}>{replyMailAddress}</p>
              </div>
              <div className={classes.message}>
                <p>返信先メールアドレス：</p>
                <p className={classes.sender}>{sendValue.senderMailAddress}</p>
              </div>
            </>
          )}

          {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && destinationDivision !== magiContants.DESTINATION_DIVISION_EMAIL_ONLY) && (
            <>
              <dt>返信許可</dt>
              <dd>{sendValue.replyAllow === '1' ? '返信を許可する' : '返信を受け付けない'}</dd>
              <dt>返信希望アイコン</dt>
              <dd>{sendValue.repliesNecessary === '1' ? '返信希望アイコンを表示する' : '返信希望アイコンを表示しない'}</dd>
              <dt>応募者からの返信期限</dt>
              <dd>{(sendValue.repliesNecessary === '1' && sendValue.repliesLimit) ? sendValue.repliesLimit : '設定なし'}</dd>
            </>
          )}

          <dd>
            <h2>件名：</h2>
            <div
              className={classes.messagePreview}
              dangerouslySetInnerHTML={{ __html: subjectState }}
            />
            <h2>本文：</h2>
            <div
              className={classes.messagePreview}
              dangerouslySetInnerHTML={{ __html: bodyState }}
            />
          </dd>

          {destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
            <div className={classes.message} style={{ flexDirection: sendValue.attachment.length > 1 && sendValue.attachment.find(item => item.attachmentFileName.length > 20) ? "row" : "unset" }}>
              {sendValue.attachment && sendValue.attachment.length > 0 && sendValue.attachment.some(item => !isEmpty(item.attachmentFileName)) ?
                <>
                  <dt style={{ wordBreak: "keep-all" }}>{'添付ファイル：'}</dt>
                  {sendValue.attachment.length > 1 && sendValue.attachment.find(item => item.attachmentFileName.length > 20) ?
                    <div>
                      {sendValue.attachment.map((item, index) => (
                        <>
                          {!isEmpty(item.attachmentFileName) && (
                            <>
                              <dd style={{ marginLeft: "10px", wordBreak: "break-all" }}>{item.attachmentFileName}</dd>
                            </>
                          )}
                        </>
                      ))}
                    </div> :
                    <>
                      {sendValue.attachment.map((item, index) => (
                        <>
                          {!isEmpty(item.attachmentFileName) && (
                            <>
                              <dd style={{ marginLeft: "10px" }}>{item.attachmentFileName}</dd>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  }
                </>
                :
                <>
                  <dt>添付ファイル：</dt>
                  <dd>なし</dd>
                </>
              }
            </div>
          )}

          {recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && (
            <div className={classes.message}>
              {((!sendValue.entryBoxId || sendValue.entryBoxId.length < 1) && (!sendValue.seminarId || sendValue.seminarId.length < 1))
                ?
                <div>
                  <p>マイナビ添付オプション：</p>
                  <p>なし</p>
                </div>
                : <>
                  {sendValue.entryBoxId.map((entryBoxId, index) => (
                    <div>
                      <p>{index === 0 ? 'マイナビ添付オプション' : ''}</p>
                      {
                        getPreviewUrl(sendValue, entryBoxId, 'entryBoxId') ?
                          <p><a className={classes.link} onClick={() => openPreviewUrl(sendValue, entryBoxId, 'entryBoxId')}>
                            {getEntryBoxContractMediaNameString(entryBoxId)}　{generateEntryBoxString(entryBoxId)}</a></p> :
                          <p>{getEntryBoxContractMediaNameString(entryBoxId)}　{generateEntryBoxString(entryBoxId)}</p>
                      }
                    </div>
                  ))}
                  {sendValue.seminarId.map((seminarId, index) => (
                    <div>
                      <p>{index === 0 && sendValue.entryBoxId.length <= 0 ? 'マイナビ添付オプション' : ''}</p>
                      {
                        getPreviewUrl(sendValue, seminarId, 'seminarId') ?
                          <p><a className={classes.link} onClick={() => openPreviewUrl(sendValue, seminarId, 'seminarId')}>
                            {getSeminarContractMediaNameString(seminarId)}　{generateSeminarString(seminarId)}</a></p> :
                          <p>{getSeminarContractMediaNameString(seminarId)}　{generateSeminarString(seminarId)}</p>
                      }
                    </div>
                  ))}
                </>
              }
            </div>
          )}

          {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) && (
            <div className={classes.message}>
              <p style={{ wordBreak: "keep-all" }}>ホームページURL：</p>
              {(!sendValue.homePage || sendValue.homePage.length <= 0 || !sendValue.homePage.some(item => !isEmpty(item.homePageUrl))) ?
                <>
                  <p>なし</p>
                </>
                :
                <>
                  <div style={{ wordBreak: "break-all" }}>
                    {sendValue.homePage.map((item, index) => (
                      <>
                        {!isEmpty(item.homePageUrl) &&
                          <>
                            <p>
                              {!isEmpty(item.homePageTitle) &&
                                <>
                                  <a className={classes.link} onClick={() => openUrl(item.homePageUrl)}>{item.homePageTitle}</a>
                                  &nbsp;
                                </>
                              }
                              {item.homePageUrl}
                            </p>
                          </>
                        }
                      </>
                    ))}
                  </div>
                </>
              }
            </div>
          )}

          {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) && (
            <div className={classes.message}>
              <p>バーコード：</p>
              <p>{sendValue.barcodeDisplay === '1' ? '表示する' : '表示しない'}</p>
            </div>
          )}

          <div className={classes.message}>
            <p>送信日時：</p>
            {sendTimeValue.transmission === '0' && (
              <p>すぐに送信する</p>
            )}
            {sendTimeValue.transmission === '1' && (
              <p>タイマー送信する
                {!isEmpty(dateTimeToString(sendTimeValue.sendPlanTime)) && (
                  <span>（{dateTimeToString(sendTimeValue.sendPlanTime)}）</span>
                )}
              </p>
            )}
          </div>
          <div className={classes.message}>
            {(recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
              && destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) && (
                <>
                  <div className={classes.notifylabel}>
                    応募者（質問者）からの返信時にお知らせを希望する：
                  </div>
                  <dd className={classes.notify}>{String(notifyNotification) === magiContants.NOTIFICATION_NOT_DESIRED || isEmpty(notifyMailAddress) ? '希望しない' : '希望する（' + notifyMailAddress + '）'}</dd>
                </>
              )}
          </div>
        </dl>
      </div>
    </section>
  )
}

export default MessageInput