import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { SEARCH_BUTTON_ID, TABLE_CONTAINER_ID } from 'utils/contants'
import { useScrolling } from 'sp/hook/useScrolling'

interface Props {
  show: boolean
  onClick: (type: string) => void
  serviceMode?: string
}

const OverlaySearch = ({ show, onClick, serviceMode = '0' }: Props) => {
  const handleClick = (type: string) => () => {
    const searchButton = document.querySelector<HTMLButtonElement>(`#${SEARCH_BUTTON_ID}`)
    onClick(type)
  }
  // スクロール中かどうか
  const isScroll = useScrolling(100)
  const scrollToAnchor = () => {
    let anchorElement = document.getElementById('root')
    if (anchorElement) {
      anchorElement.scrollIntoView()
    }
  }
  return (
    <div
      className={
        isScroll
          ? 'sticky-footer-block is-scroll-hidden'
          : 'sticky-footer-block'
      }
      style={{ marginLeft: '-10px', marginRight: '-10px' }}>
      <div className={`${show ? 'floating is-floating-active' : 'floating'}`}>
        <div className={'btn__pagetop'} onClick={scrollToAnchor}></div>

        <div className={'floating-detail-display__btn'}>
          <button
            className={'btn__search'}
            type='submit'
            onClick={handleClick('search')}>
            検索
        </button>
          {serviceMode !== '1' && (
            <button
              className={'btn__mySearch'}
              type='submit'
              onClick={handleClick('save')}>
              MY検索に保存
          </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default OverlaySearch
