import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(2, 10),
  },
}))

interface Props {
  className?: string
}

const FormField: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()
  return <div className={`${className} ${classes.root}`}>{children}</div>
}

export default FormField
