import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Grid,
} from '@material-ui/core'
import { getMessage } from 'common/messageUtil'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import {
  setinitRequest,
  setRMFInfo,
  insertByRMF,
  setDialogOpen,
} from 'reducers/recruitmentManagementFlagChangeReducer'
import theme from 'theme'
import { magiContants } from 'utils/contants'
import InputOnlyNumber from './InputOnlyNumber'
import InputWithCounter from './InputWithCounter'
import ActionSetSelect from './ActionSetSelect'
import { onKeyDown } from 'common/generalUtil'
import * as yup from 'yup'
import { ModalDialog } from 'sp/components'

const useStyles = makeStyles(theme => ({
  title: {
    minWidth: 185,
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      overflowY: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/)
        ? 'auto'
        : 'hidden',
      display: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/)
        ? 'block'
        : 'flex',
    },
    '& .MuiDialogContent-root': {
      maxHeight: '100%',
      backgroundColor: '#565a61',
      padding: 0,
    },
  },
  checkboxLabel: {
    marginLeft: '40px',
  },
  deletedBox: {
    width: '26px!important',
    height: '26px!important',
    border: '4px solid rgb(117 130 157)!important',
  },
  confirmButton: {
    color: '#FFF',
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#3380cc',
    },
    width: 130,
  },
  cancelButton: {
    color: '#000',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    width: 130,
  },
}))
interface Props {
  open: boolean
  onOk: () => void
  onCancel: () => void
  prevScreenId: string
}
const MCAXM340 = ({ open, onOk, onCancel, prevScreenId }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const initData = useSelector(
    (state: RootState) => state.mcaxs340Reducer.initRMFInfo
  )
  const isDialogOpen = useSelector(
    (state: RootState) => state.mcaxs340Reducer.isDialogOpen
  )
  const mcaxs340InitRequest = useSelector(
    (state: RootState) => state.mcaxs340Reducer.initRequest
  )
  const companyList = useSelector(
    (state: RootState) =>
      state.mcaxs340Reducer.initRMFInfo
        .mcaxs340RecruitmentManagementFlagCompanyListResultDto
  )
  // 初期化取得した採用管理フラグ情報
  const [rmfList, setRMFList] = useState<any>([])
  // 初期化取得した採用管理フラグ選択肢情報
  const [rmfoList, setRMFOList] = useState<any>([])
  // 初期化取得した更新対象の採用管理フラグ情報
  const [updateCheckList, setUpdateCheckList] = useState<any>([])
  const scrollBodyRef = useRef<HTMLDivElement>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [findUpdateInit, setFindUpdateInit] = useState<any>(null)
  // データ削除した採用管理フラグ設定ID配列
  const [selectedRFSettingId, setSelectedRFSettingId] = useState([] as string[])

  useEffect(() => {
    if (mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId) {
      const selectionManagementId = mcaxs340InitRequest.selectionManagementId

      // 選考管理IDが設定されていない または　選考管理IDが1件
      if (selectionManagementId.length === 0) {
        setRMFList(
          initData.mcaxs340RecruitmentManagementFlagListResultDto
            ? initData.mcaxs340RecruitmentManagementFlagListResultDto
            : []
        )
        // 選考管理IDが複数件
      } else if (selectionManagementId.length >= 1) {
        setRMFList(
          initData.mcaxs340RecruitmentManagementFlagCompanyListResultDto
        )
        setUpdateCheckList(
          initData.mcaxs340RecruitmentManagementFlagUpdateCheckDto
        )
      }
      setRMFOList(initData.mcaxs340RecruitmentManagementFlagOptionListResultDto)
    }
  }, [initData])

  const RecruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  // 画面の「OK」ボタン押下
  const MCAXS340Submit = (values: any) => {
    const newData: any = {}
    const valueData: any = {}
    Object.keys(values).forEach(key => {
      valueData[key] = '' + values[key]
    })
    Object.keys(findUpdateInit).forEach(key => {
      if (findUpdateInit[key].toString() !== valueData[key].toString()) {
        newData[key] = valueData[key]
      }
      // 変更する管理項目にデータ削除した採用管理フラグ設定IDを置く
      for (let a = 0; a < selectedRFSettingId.length; a++) {
        newData[selectedRFSettingId[a]] = valueData[selectedRFSettingId[a]]
      }
    })
    let submitValues = {
      jobSeekerId: mcaxs340InitRequest.jobSeekerId.map(i => i.toString()),
      entryId: mcaxs340InitRequest.entryId.map(i => i.toString()),
      selectionManagementId: mcaxs340InitRequest.selectionManagementId.map(i =>
        i.toString()
      ),
      recruitmentManagementFlagId: [] as string[],
      recruitmentManagementFlagSettingId: [] as string[],
      recruitmentManagementFlagName: [] as string[],
      recruitmentManagementFlagOptionSettingId: [] as string[],
      recruitmentManagementFlagOption: [] as string[],
      settingNumber: [] as string[],
      settingString: [] as string[],
      type: [] as string[],
      sysVersionNumber: [] as string[],
      recruitmentManagementDivision: RecruitmentManagementDivision,
      prevScreenId: prevScreenId,
    }
    // 変更項目ありの場合、選考管理IDは初期化する
    for (const id in newData) {
      submitValues.selectionManagementId = []
      break
    }
    for (const rmfsId in newData) {
      for (
        let i = 0;
        i < mcaxs340InitRequest.selectionManagementId.length;
        i++
      ) {
        const selManagId = mcaxs340InitRequest.selectionManagementId[i]
        const mfItem = rmfList.find(
          (i: any) =>
            i.recruitmentManagementFlagSettingId.toString() ===
            rmfsId.toString()
        )
        if (mfItem) {
          let optionId = '0'
          let optionName = ''
          let settingNum = '0'
          let settingStr = ''
          if (mfItem.type === '30') {
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、採用管理フラグ選択肢設定IDを「0」設定
            if (selectedRFSettingId.indexOf(rmfsId) >= 0) {
              newData[rmfsId] = '0'
            }
            if (newData[rmfsId].length === 0) {
              return
            }
            optionId = newData[rmfsId]
            const rmfoItem = rmfoList.find(
              (i: any) =>
                i.recruitmentManagementFlagOptionSettingId == optionId &&
                i.recruitmentManagementFlagSettingId ==
                mfItem.recruitmentManagementFlagSettingId
            )
            if (rmfoItem) {
              optionName = rmfoItem.option
            }
          } else if (mfItem.type === '20') {
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、対応する数値を「''」設定
            if (selectedRFSettingId.indexOf(rmfsId) >= 0) {
              newData[rmfsId] = ''
            }
            const pattDecimal = /^-?(0|[1-9]\d*)(\.\d+)?$/
            const pattFloat = /^-?(0|[1-9]\d*)(\.\d{1,2})?$/
            if (
              (newData[rmfsId].length > 11 && newData[rmfsId] > 0) ||
              (newData[rmfsId].length > 12 && newData[rmfsId] < 0) ||
              newData[rmfsId] > 99999999.99 ||
              newData[rmfsId] < -99999999.99 ||
              !(newData[rmfsId] === '' || pattDecimal.test(newData[rmfsId])) ||
              !(newData[rmfsId] === '' || pattFloat.test(newData[rmfsId]))
            ) {
              return
            }
            if (newData[rmfsId] === '') {
              settingNum = ''
            } else {
              settingNum = newData[rmfsId]
            }
          } else if (mfItem.type === '10') {
            // データ削除した採用管理フラグ設定ID配列に変更する採用管理フラグ設定IDが含まれている場合、対応する文字列を「''」設定
            if (selectedRFSettingId.indexOf(rmfsId) >= 0) {
              newData[rmfsId] = ''
            }

            if (newData[rmfsId].length > 400) {
              return
            }
            settingStr = newData[rmfsId]
          }
          if (mcaxs340InitRequest.selectionManagementId.length >= 1) {
            const upCheckItem = updateCheckList.find(
              (i: any) =>
                i.recruitmentManagementFlagSettingId.toString() ===
                rmfsId.toString() &&
                i.selectionManagementId.toString() === selManagId.toString()
            )
            if (upCheckItem) {
              submitValues.recruitmentManagementFlagId.push(
                upCheckItem.recruitmentManagementFlagId
              )
              submitValues.sysVersionNumber.push(upCheckItem.sysVersionNumber)
            } else {
              submitValues.recruitmentManagementFlagId.push('0')
              submitValues.sysVersionNumber.push('0')
            }
          }
          submitValues.selectionManagementId.push(selManagId.toString())
          submitValues.recruitmentManagementFlagOptionSettingId.push(optionId)
          submitValues.recruitmentManagementFlagOption.push(optionName)
          submitValues.settingNumber.push(settingNum)
          submitValues.settingString.push(settingStr)
          submitValues.recruitmentManagementFlagSettingId.push(
            mfItem.recruitmentManagementFlagSettingId
          )
          submitValues.recruitmentManagementFlagName.push(
            mfItem.recruitmentManagementFlagName
          )
          submitValues.type.push(mfItem.type)
        }
      }
    }
    if (mcaxs340InitRequest && mcaxs340InitRequest.selectionManagementId) {
      const selectionManagementId = mcaxs340InitRequest.selectionManagementId
      if (selectionManagementId.length > 0) {
        dispatch(
          insertByRMF({
            request: submitValues,
            onOk: onOk,
            clearSelectedRFSettingId: clearSelectedRFSettingId,
          })
        )
      } else {
        handleConfirm()
      }
    }
  }
  const clearSelectedRFSettingId = () => {
    setSelectedRFSettingId([])
  }

  // 画面の「キャンセル」ボタン押下
  const handleGamennCancel = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmCancel = () => {
    handleConfirm()
    onCancel()
    setSelectedRFSettingId([])
  }

  // 確認ダイアログの「OK」ボタン押下
  const handleConfirm = () => {
    dispatch(setDialogOpen(false))
    setConfirmDialogOpen(false)
    dispatch(
      setRMFInfo({
        mcaxs340RecruitmentManagementFlagListResultDto: [],
        mcaxs340RecruitmentManagementFlagOptionListResultDto: [],
        mcaxs340RecruitmentManagementFlagCompanyListResultDto: [],
        mcaxs340RecruitmentManagementFlagUpdateCheckDto: [],
      })
    )
    dispatch(
      setinitRequest({
        jobSeekerId: [],
        entryId: [],
        selectionManagementId: [],
        recruitmentManagementFlagSettingId: [],
        recruitmentManagementFlagOptionSettingId: [],
        settingString: [],
        settingNumber: [],
        recruitmentManagementDivision: '',
      })
    )
  }

  // 確認ダイアログの「キャンセル」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
  }

  // 初期化時にFormの情報取得
  const getFormInitValues = () => {
    if (findUpdateInit) {
      return findUpdateInit
    }
    let initValues: any = new Object()
    if (rmfList && rmfList.length > 0) {
      rmfList.map((i: any) => {
        if (i['recruitmentManagementFlagSettingId']) {
          let key = i['recruitmentManagementFlagSettingId']
          let value = ''
          let valueFromer = i
          // 選考管理IDが1件
          if (
            mcaxs340InitRequest &&
            mcaxs340InitRequest.selectionManagementId &&
            mcaxs340InitRequest.selectionManagementId.length === 1
          ) {
            if (updateCheckList && updateCheckList.length > 0) {
              const tmpObj = updateCheckList.find(
                (i: any) => i.recruitmentManagementFlagSettingId === key
              )
              if (tmpObj) {
                valueFromer = tmpObj
              }
            }
          }
          if (i.type === '30') {
            value = valueFromer['recruitmentManagementFlagOptionSettingId']
              ? valueFromer['recruitmentManagementFlagOptionSettingId']
              : 0
          } else if (i.type === '20') {
            value =
              null != valueFromer['settingNumber']
                ? valueFromer['settingNumber']
                : ''
          } else if (i.type === '10') {
            value = valueFromer['settingString']
              ? valueFromer['settingString']
              : ''
          }
          if (i.type === '30' || i.type === '20' || i.type === '10') {
            initValues[key] = value
          }
        }
      })
      setFindUpdateInit(initValues)
    }
    return initValues
  }

  // 画面情報取得
  const getManagementFlagList = () => {
    if (
      findUpdateInit &&
      Object.keys(findUpdateInit).length > 0 &&
      rmfList &&
      rmfList.length > 0
    ) {
      const managementFlagList = rmfList.filter(
        (i: any) => null != i['recruitmentManagementFlagSettingId']
      )
      return managementFlagList
    } else {
      return []
    }
  }

  // プルダウン情報取得
  const getManagementFlagOptionList = (
    recruitmentManagementFlagSettingId: any
  ) => {
    const managementFlagOptionList = rmfoList.reduce(
      (arrOption: Array<any>, item: any) => {
        if (
          Number.parseInt(item.recruitmentManagementFlagSettingId) ===
          recruitmentManagementFlagSettingId
        ) {
          arrOption.push({
            label: item.option,
            value: item.recruitmentManagementFlagOptionSettingId,
          })
        }
        return arrOption
      },
      [
        prevScreenId === 'MCAXS260' || prevScreenId === 'MCAYS030'
          ? mcaxs340InitRequest &&
            mcaxs340InitRequest.selectionManagementId &&
            mcaxs340InitRequest.selectionManagementId.length === 1
            ? { label: '（未選択）', value: 0 }
            : selectedRFSettingId.indexOf(
              recruitmentManagementFlagSettingId.toString()
            ) !== -1
              ? { label: '項目のデータを一括削除します', value: -1 }
              : { label: '', value: 0 }
          : { label: '（未選択）', value: 0 },
      ]
    )
    return managementFlagOptionList
  }

  // データ削除のチェックボックス押下
  const handleSelectOne = (id: number) => () => {
    const selectedIndex = selectedRFSettingId.indexOf(id.toString())

    let newSelectedRFSettingId: string[] = []

    if (selectedIndex === -1) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId,
        id.toString()
      )
    } else if (selectedIndex === 0) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(1)
      )
    } else if (selectedIndex === selectedRFSettingId.length - 1) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(0, -1)
      )
    } else if (selectedIndex > 0) {
      newSelectedRFSettingId = newSelectedRFSettingId.concat(
        selectedRFSettingId.slice(0, selectedIndex),
        selectedRFSettingId.slice(selectedIndex + 1)
      )
    }
    setSelectedRFSettingId(newSelectedRFSettingId)
  }
  return (
    <>
      <ModalDialog
        className={'modal'}
        isOpen={open}
        onClose={() => setFindUpdateInit(null)}>
        {open &&
          <Formik
            onSubmit={MCAXS340Submit}
            enableReinitialize
            initialValues={getFormInitValues()}>
            <Form
              onKeyDown={onKeyDown}>
              <div className={'modal-content-black'}>
                <div className='modal-title-wrap-black'>
                  <h3 className='modal-title'>管理項目変更</h3>
                  <span className={'close-btn'}
                    onClick={handleGamennCancel}>
                    <svg width={25} height={25} viewBox={'0 0 50 50'} fill='#fff'>
                      <use xlinkHref='#form__modal_close'></use>
                    </svg>
                  </span>
                </div>
                <div className={'modal-section pt15'}>
                  <p className='modal-text__12'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getMessage(
                          prevScreenId === 'MCAXS260' ||
                            prevScreenId === 'MCAYS030'
                            ? mcaxs340InitRequest &&
                              mcaxs340InitRequest.selectionManagementId &&
                              mcaxs340InitRequest.selectionManagementId
                                .length === 1
                              ? magiContants.MESSAGECODE_MCAXS340_000
                              : magiContants.MESSAGECODE_MCAXS340_0000
                            : magiContants.MESSAGECODE_MCAXS340_000
                        ),

                      }}
                      style={{ color: (theme.palette as any).white }}
                    />
                  </p>

                  <div className={'modal-title_2 mb10'}
                    style={{
                      width: '100%',
                      display: 'inline-flex',
                      justifyContent: 'space-between',
                    }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: '管理項目一覧' }}
                    />

                    {(prevScreenId === 'MCAXS260' ||
                      prevScreenId === 'MCAYS030') &&
                      mcaxs340InitRequest &&
                      mcaxs340InitRequest.selectionManagementId &&
                      mcaxs340InitRequest.selectionManagementId.length > 1 ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: 'データ削除' }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={'modal-section__inner mt10'}>
                    {getManagementFlagList().map((item: any, index: number) => (
                      <div key={index}>
                        {item.type === '30' ? (
                          <Grid xs={12}
                            className={'mb20'}
                            style={{
                              width: '100%',
                              display: 'inline-flex',
                              justifyContent: 'flex-end',
                              position: 'relative',
                            }}>
                            <Grid xs={12}>
                              <Field
                                name={item.recruitmentManagementFlagSettingId.toString()}
                                optionList={getManagementFlagOptionList(
                                  item.recruitmentManagementFlagSettingId
                                )}
                                component={ActionSetSelect}
                                title={item.recruitmentManagementFlagName}
                                item={
                                  findUpdateInit
                                    ? findUpdateInit[
                                    item.recruitmentManagementFlagSettingId.toString()
                                    ]
                                    : null
                                }

                                // データ削除のチェックボックスの選択状態
                                clearRecruitmentManageFlag={
                                  selectedRFSettingId.indexOf(
                                    item.recruitmentManagementFlagSettingId.toString()
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                // 選考管理IDの件数
                                selectDoubleFalg={
                                  mcaxs340InitRequest &&
                                  mcaxs340InitRequest.selectionManagementId &&
                                  mcaxs340InitRequest.selectionManagementId.length >
                                  1
                                }
                                // 遷移元画面ID
                                prevScreenId={prevScreenId}
                              />
                            </Grid>
                            {
                              (prevScreenId === 'MCAXS260' ||
                                prevScreenId === 'MCAYS030') &&
                                mcaxs340InitRequest &&
                                mcaxs340InitRequest.selectionManagementId &&
                                mcaxs340InitRequest.selectionManagementId.length >
                                1 ? (
                                <Grid
                                  style={{
                                    position: 'absolute',
                                  }}>
                                  <label className={classes.checkboxLabel}>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selectedRFSettingId.indexOf(
                                          item.recruitmentManagementFlagSettingId.toString()
                                        ) !== -1
                                      }
                                      onChange={handleSelectOne(
                                        item.recruitmentManagementFlagSettingId
                                      )}
                                    />
                                    <mark className={classes.deletedBox}></mark>
                                  </label>
                                </Grid>
                              ) : (
                                ''
                              )
                            }
                          </Grid>
                        ) : item.type === '20' ? (

                          <Grid xs={12}
                            className={'mb20'}
                            style={{
                              width: '100%',
                              display: 'inline-flex',
                              justifyContent: 'flex-end',
                              position: 'relative',
                            }}>
                            <Grid xs={12}>
                              <Field
                                name={item.recruitmentManagementFlagSettingId.toString()}
                                component={InputOnlyNumber}
                                title={item.recruitmentManagementFlagName}
                                item={
                                  findUpdateInit
                                    ? findUpdateInit[
                                    item.recruitmentManagementFlagSettingId.toString()
                                    ]
                                    : null
                                }
                                validate={async (value: any) => {
                                  let flg = false
                                  let message
                                  await yup
                                    .object()
                                    .shape({
                                      [item.recruitmentManagementFlagSettingId.toString()]: yup.string(),
                                    })
                                    .validate({
                                      [item.recruitmentManagementFlagSettingId.toString()]: value,
                                    })
                                    .catch(err => {
                                      flg = true
                                      message = err.errors
                                    })
                                  if (flg) {
                                    return message
                                  } else {
                                    return ''
                                  }
                                }}
                                // データ削除のチェックボックスの選択状態
                                clearRecruitmentManageFlag={
                                  selectedRFSettingId.indexOf(
                                    item.recruitmentManagementFlagSettingId.toString()
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                // 選考管理IDの件数
                                selectDoubleFalg={
                                  mcaxs340InitRequest &&
                                  mcaxs340InitRequest.selectionManagementId &&
                                  mcaxs340InitRequest.selectionManagementId.length >
                                  1
                                }
                                // 遷移元画面ID
                                prevScreenId={prevScreenId}
                              />
                            </Grid>
                            {
                              (prevScreenId === 'MCAXS260' ||
                                prevScreenId === 'MCAYS030') &&
                                mcaxs340InitRequest &&
                                mcaxs340InitRequest.selectionManagementId &&
                                mcaxs340InitRequest.selectionManagementId.length >
                                1 ? (
                                <Grid
                                  style={{
                                    position: 'absolute',
                                  }}>
                                  <label className={classes.checkboxLabel}>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selectedRFSettingId.indexOf(
                                          item.recruitmentManagementFlagSettingId.toString()
                                        ) !== -1
                                      }
                                      onChange={handleSelectOne(
                                        item.recruitmentManagementFlagSettingId
                                      )}
                                    />
                                    <mark className={classes.deletedBox}></mark>
                                  </label>
                                </Grid>
                              ) : (
                                ''
                              )
                            }
                          </Grid>
                        ) : item.type === '10' ? (
                          <Grid xs={12}
                            className={'mb20'}
                            style={{
                              width: '100%',
                              display: 'inline-flex',
                              justifyContent: 'flex-end',
                              position: 'relative',
                            }}>
                            <Grid xs={12}>
                              <Field
                                name={item.recruitmentManagementFlagSettingId.toString()}
                                component={InputWithCounter}
                                title={item.recruitmentManagementFlagName}
                                maxAmount={400}

                                item={
                                  findUpdateInit
                                    ? findUpdateInit[
                                    item.recruitmentManagementFlagSettingId.toString()
                                    ]
                                    : null
                                }
                                validate={async (value: any) => {
                                  let flg = false
                                  let message
                                  await yup
                                    .object()
                                    .shape({
                                      [item.recruitmentManagementFlagSettingId.toString()]: yup.string(),
                                    })
                                    .validate({
                                      [item.recruitmentManagementFlagSettingId.toString()]: value,
                                    })
                                    .catch(err => {
                                      flg = true
                                      message = err.errors
                                    })
                                  if (flg) {
                                    return message
                                  } else {
                                    return ''
                                  }
                                }}
                                // データ削除のチェックボックスの選択状態
                                clearRecruitmentManageFlag={
                                  selectedRFSettingId.indexOf(
                                    item.recruitmentManagementFlagSettingId.toString()
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                // 選考管理IDの件数
                                selectDoubleFalg={
                                  mcaxs340InitRequest &&
                                  mcaxs340InitRequest.selectionManagementId &&
                                  mcaxs340InitRequest.selectionManagementId.length >
                                  1
                                }
                                // 遷移元画面ID
                                prevScreenId={prevScreenId}
                              />
                            </Grid>
                            {
                              (prevScreenId === 'MCAXS260' ||
                                prevScreenId === 'MCAYS030') &&
                                mcaxs340InitRequest &&
                                mcaxs340InitRequest.selectionManagementId &&
                                mcaxs340InitRequest.selectionManagementId.length >
                                1 ? (
                                <Grid
                                  style={{
                                    position: 'absolute',
                                  }}>
                                  <label className={classes.checkboxLabel}>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selectedRFSettingId.indexOf(
                                          item.recruitmentManagementFlagSettingId.toString()
                                        ) !== -1
                                      }
                                      onChange={handleSelectOne(
                                        item.recruitmentManagementFlagSettingId
                                      )}
                                    />
                                    <mark className={classes.deletedBox}></mark>
                                  </label>
                                </Grid>
                              ) :
                                ''
                            }
                          </Grid>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={'modal-section-btn__black'}>
                  <Button
                    onClick={handleGamennCancel}
                    variant='contained'
                    className={`${'btn__cancel'} ${classes.cancelButton}`}>
                    キャンセルする
                  </Button>
                  <Button
                    type='submit'
                    variant='contained'
                    className={`${'btn__blue_ok'} ${classes.confirmButton}`}
                    disabled={companyList.length === 0 ? true : false}>
                    OK
                  </Button>
                </div>
              </div>
            </Form>
          </Formik>
        }
      </ModalDialog>

      <Dialog open={confirmDialogOpen} disableScrollLock={true} className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>
            {getMessage(magiContants.MESSAGECODE_MCAXS340_003)}
          </div>
          <div className='dialog-section-btn'>
            <button onClick={handleCancel} className='daialogCancelBtn'>キャンセル</button>
            <button onClick={handleConfirmCancel} className='daialogConfirmBtn'>
              OK
            </button>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default MCAXM340
