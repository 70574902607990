import { Tooltip } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import candidateDateIcon from 'images/candidateDate.png'
import planned from 'images/planned.png'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { formatTemplate, unitSet } from 'utils/contants'
import { Area } from 'utils/misc'
import { CandidateDay, Reservation } from './Calendar'
import { InterviewLocation } from 'pages/MCAZS080/viewConig'
moment.tz.setDefault('Asia/Tokyo')

interface Props {
  allowedArea: Area[]
  notAllowedArea: Area[]
  reservationList: Reservation[]
  lengthLimit: number
  selected: CandidateDay[]
  setSelected: (selected: CandidateDay[]) => void
  interviewLocation: InterviewLocation[]
  date: string
  slot: string
  year: number
  handleToolTipReserved: (state: boolean) => void
  setCurrentReservationList: (state: Reservation[]) => void
  toolTipReserved: boolean
  currentReservationList: Reservation[]
}

const LightTooltip = withStyles((theme: Theme) => ({
  popper: {
    '&[x-out-of-boundaries]': {
      display: 'none',
    },
  },
  tooltip: {
    backgroundColor: (theme.palette as any).white,
    color: (theme.palette as any).black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  badge: {
    color: 'white',
    backgroundColor: 'orangered',
    padding: '1px 6px',
    borderRadius: '10px',
  },
  bgBlack: {
    backgroundColor: 'black',
  },
  event: {
    borderColor: 'transparent',
    backgroundColor: 'transparent !important',
    height: '20px',
    textAlign: 'center',
    marginRight: '0 !important',
    left: '0% !important',
    zIndex: '99 !important' as any,
  },
  eventContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipName: {
    fontWeight: 'bold',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  dayStyle: {
    backgroundColor: 'white',
  },
  allowedDayStyle: {
    backgroundColor: 'lightblue',
  },
  notPossibleDayStyle: {
    backgroundColor: '#e6e6e6',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  alreadyClicked: {
    pointerEvents: 'none',
  },
  allowedClicked: {
    pointerEvents: 'none',
    backgroundColor: 'lightblue',
  },
  bgOrangered: {
    backgroundColor: 'orangered',
  },
}))

const CalendarCell = ({
  allowedArea,
  notAllowedArea,
  reservationList,
  lengthLimit,
  selected,
  setSelected,
  interviewLocation,
  date,
  slot,
  year,
  handleToolTipReserved,
  setCurrentReservationList,
  currentReservationList,
  toolTipReserved,
}: Props) => {
  const classes = useStyles()
  // 選択済みフラグ
  const [isFlagShow, setFlagShow] = useState(false)

  //　応募者希望日
  const [isAllowed, setAllowed] = useState(false)

  //　応募者NG
  const [isNotAllowed, setNotAllowed] = useState(false)

  //　予定あり
  const [isReserved, setReserved] = useState(false)

  const [reservationCount, setReservationCount] = useState(Number)
  const [showReservationList, setShowReservationList] = useState<Reservation[]>(
    []
  )
  const [candidateCount, setCandidateCount] = useState(Number)

  const month = date.split('/')[0].padStart(2, '0')
  const day = String(date.split('/')[1])
    .split('(')[0]
    .padStart(2, '0')
  const currentDate = new Date(
    year,
    Number(month) - 1,
    Number(day),
    Number(slot.split(':')[0]),
    Number(slot.split(':')[1]),
    0,
    0
  )

  const currentDayTimeString = year + '-' + month + '-' + day + 'T' + slot

  useEffect(() => {
    //応募者希望日の表示
    setAllowed(false)
    if (allowedArea.length) {
      allowedArea.forEach(area => {
        const start =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.startDate
        const end =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.endDate
        if (start <= currentDayTimeString && end > currentDayTimeString) {
          setAllowed(true)
        }
      })
    }
  }, [allowedArea, currentDayTimeString])

  useEffect(() => {
    //応募者NG日程の表示
    setNotAllowed(false)
    if (notAllowedArea.length) {
      notAllowedArea.forEach(area => {
        const start =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.startDate
        const end =
          moment()
            .add(area.day, unitSet.days)
            .format(formatTemplate.date) +
          'T' +
          area.endDate

        if (start <= currentDayTimeString && end > currentDayTimeString) {
          setNotAllowed(true)
        }
      })
    }

    // 予定あり日程の表示
    let count = 0
    setReserved(false)
    setShowReservationList([])
    if (reservationList.length) {
      reservationList.forEach(item => {
        if (
          item.start <= currentDayTimeString &&
          item.end > currentDayTimeString
        ) {
          setReserved(true)
          setShowReservationList(showReservationList => [
            ...showReservationList,
            {
              name: item.name,
              start: item.start,
              end: item.end,
              location: item.location,
              charge: item.charge,
            },
          ])
          count = count + 1
          setReservationCount(count)
        }
      })
    }
  }, [allowedArea,currentDayTimeString])

  useEffect(() => {
    // 提示日に旗を立てる
    // 選択リストから
    let count = 0
    setFlagShow(false)
    let currentDayTimeString30min = currentDayTimeString
    if (currentDayTimeString.endsWith('00')) {
      currentDayTimeString30min = currentDayTimeString.slice(0, -2) + '30'
    }
    if (currentDayTimeString.endsWith('30')) {
      currentDayTimeString30min =
        currentDayTimeString.split('T')[0] +
        'T' +
        (Number(slot.split(':')[0]) + 1).toString() +
        ':00'
    }
    if (selected.length) {
      selected.forEach(item => {
        if (
          currentDayTimeString.split('T')[0] ===
            moment(item.interviewDate)
              .format(formatTemplate.dateWithHourMinute)
              .split('T')[0] &&
          moment(item.interviewDate)
            .format(formatTemplate.dateWithHourMinute)
            .split('T')[0] === currentDayTimeString30min.split('T')[0]
        ) {
          if (
            currentDayTimeString.split('T')[1] <=
              moment(item.interviewDate)
                .format(formatTemplate.dateWithHourMinute)
                .split('T')[1] &&
            moment(item.interviewDate)
              .format(formatTemplate.dateWithHourMinute)
              .split('T')[1] < currentDayTimeString30min.split('T')[1]
          ) {
            setFlagShow(true)
            count = count + 1
            setCandidateCount(count)
          }
        }
      })
    }
  }, [selected, currentDayTimeString])

  const handleSetFlag = () =>{
    setSelected([
      ...selected,
      {
        interviewDate: currentDate,
        interviewLocation:
          interviewLocation.length > 0 ? interviewLocation[0].name : '',
        interviewLocationId:
          interviewLocation.length > 0 ? String(interviewLocation[0].id) : '',
        charge: '',
        datePickerRef: React.createRef(),
        containerRef: React.createRef(),
        fromCalendar: true,
      },
    ])
    setFlagShow(true)
  }


  //日付のセルをクリックしたときの動作
  const handleSlotClick = () => {
    const now = new Date()
    const reservationListEqual = (currentReservationList:Reservation[],showReservationList:Reservation[]) =>{
      return JSON.stringify(currentReservationList) === JSON.stringify(showReservationList)
    }
    if(isReserved){
      if(!(selected.length < lengthLimit && !isFlagShow && now <= currentDate)){
        if (toolTipReserved && reservationListEqual(currentReservationList,showReservationList)) {
          handleToolTipReserved(false)
          setCurrentReservationList([])
        }else{
          handleToolTipReserved(true)
          setCurrentReservationList(showReservationList)
        }
      }else{
        if (toolTipReserved && reservationListEqual(currentReservationList,showReservationList)) {
          handleToolTipReserved(false)
          setCurrentReservationList([])
        }else{
          handleToolTipReserved(true)
          setCurrentReservationList(showReservationList)
        }
        if (selected.length < lengthLimit && !isFlagShow && toolTipReserved && now <= currentDate && reservationListEqual(currentReservationList,showReservationList)) {
          //候補日を提示していない日程を選択したときに候補日を追加
          //現在日時より前の日程は選択不可
          handleSetFlag()
        }
      }
    }else{
      handleToolTipReserved(false)
      setCurrentReservationList([])
      if (selected.length < lengthLimit && !isFlagShow && now <= currentDate) {
        //候補日を提示していない日程を選択したときに候補日を追加
        //現在日時より前の日程は選択不可
        handleSetFlag()
      }

    }
  }


  return (
      <td
      style={{
        background: isFlagShow
          ? isNotAllowed
            ? '#e6e6e6'
            : isAllowed
            ? '#c4dae8'
            : ''
          : isNotAllowed
          ? '#e6e6e6'
          : isAllowed
          ? '#c4dae8'
          : '',
        borderTop: slot.endsWith('00') ? '' : '1px solid #dfdfdf',
        borderBottom: slot.endsWith('00') ? '1px solid #dfdfdf' : '',
      }}
      className={
        isFlagShow
          ? isAllowed
            ? 'allowedClicked'
            : 'alreadyClicked'
          : isAllowed
          ? 'allowedDayStyle'
          : isNotAllowed
          ? 'notPossibleDayStyle'
          : 'dayStyle'
      }
      onClick={handleSlotClick}>
      {//公募日の一覧に選択した日付が入っていなければ候補日に追加
      isFlagShow ? (
            <div className={classes.eventContainer}>
              <img className='photo' src={candidateDateIcon} alt='flag' />
              {candidateCount !== 1 ? (
                <span className={`${classes.badge} ${classes.bgOrangered}`}>
                  {candidateCount}
                </span>
              ) : (
                <></>
              )}
            </div>
      ) : (
        <></>
      )}
      {isReserved ? (
          <div className={classes.eventContainer}>
            <img className='photo' src={planned} alt='flag' />
            {reservationCount !== 1 ? (
              <span className={`${classes.badge} ${classes.bgBlack}`}>
                {reservationCount}
              </span>
            ) : (
              <></>
            )}
          </div>
      ) : (
        <></>
      )}
      </td>
  )
}

export default React.memo(CalendarCell)
