import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

const useStyles = makeStyles({
  cusFormControl: {
    display: 'initial',
    margin: 'initial',
  },
  cusFormControlLabel: {
    marginLeft: '20px',
    marginRight: '20px',
  }
})

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  myChange: Function
  checked: boolean
  formName?: string
  target?: string
}

const Toggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  checked,
  formName,
  target,
}: Props) => {
  const classes = useErrorStyle()
  const cusClasses = useStyles()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const handleChange = (e: any) => {
    switch (target) {
      case 'questionCondition':
        form.setFieldValue('typeCode', e.target.value)
        form.setFieldValue('typeName', e.target.name)
        break
      case 'answerCondition':
        form.setFieldValue(formName + 'answerConditionFlag', e.target.value)
        form.setFieldValue(formName + 'answerConditionName', e.target.name)
        break
      case 'managementCondition':
        form.setFieldValue('typeCode', e.target.value)
        form.setFieldValue('typeName', e.target.name)
        break
      case 'managementItemPresence':
        form.setFieldValue(
          formName + 'managementItemPresenceFlag',
          e.target.value
        )
        form.setFieldValue(
          formName + 'managementItemPresenceName',
          e.target.name
        )
        break
      default:
        form.setFieldValue(name, e.target.value)
        break
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={`${className} ${cusClasses.cusFormControl}`}
    >
      <div className='form__search_andor'>
        <div className='form__label_side'>
          <RadioGroup row={row} value={value} onChange={handleChange}>
            {optionList.map(i => (
              <FormControlLabel
                key={i.value}
                control={
                  <Radio
                    value={i.value}
                    disabled={disabled}
                    checked={checked}
                    name={i.label}
                    className='form__radio'
                    style={{ padding: 0 }}
                    icon={
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" className='form__radio_svg_off'>
                        <use xlinkHref="#form__radio_off"></use>
                      </svg>
                    }
                    checkedIcon={
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" className='form__radio_svg_on'>
                        <use xlinkHref="#form__radio_on"></use>
                      </svg>
                    }
                  />
                }
                className={cusClasses.cusFormControlLabel}
                label={
                  <span className='form__radio_text'>{i.label}</span>
                }
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      {
        errors[name] && touched[name] ? (
          <p className={classes.helperText}>{errors[name]}</p>
        ) : null
      }
    </FormControl >
  )
}

export default Toggle
