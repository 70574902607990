import React, { useState } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorStyle } from 'components'
import { magiContants } from 'utils/contants'
import { getMessage } from './formConfig'
import { SelectionFlowList } from 'reducers/progressStatusChangeReducer'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&:disabled': {
      color: '#000000',
      border: '1px solid #000000',
      backgroundColor: '#565a61',
    },
  },
  errorBorder: {
    borderColor: '#e53935',
  }
}))

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  optionList: Option[]
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
  secondFormName?: string
  selectionStepBefore: string
  selectionFlowList: SelectionFlowList[]
}

const MySelectSelectionStepMessage = ({
  className,
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
  secondFormName,
  selectionStepBefore,
  selectionFlowList,
}: Props) => {
  const classes = useStyles()
  const errorclasses = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form

  React.useEffect(() => {
    if (defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, '')
    }
  }, [defaultValue])

  React.useEffect(() => {
  }, [])

  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e.target.value)
    }
    form.setFieldValue(name, e.target.value)
    form.setFieldTouched(name, true)
    // 進捗変更時にその他のFieldValueを空にする
    form.setFieldValue('interviewDay', '')
    form.setFieldValue('fairueReason', '')
    form.setFieldValue('declineReason', '')
    form.setFieldValue('other', '')
    // 進捗変更時の確認メッセージを設定する
    form.setFieldValue('message', getMessage(selectionStepBefore, e.target.value, selectionFlowList))
  }

  return (
    <>
      <div className={className} style={{ backgroundColor: '#565a61' }}>
        <select
          name={name}
          disabled={disabled}
          className={`${classes.root} ${errors[name] && touched[name] ? classes.errorBorder : null
            }`}
          value={value}
          onChange={handleChange}>
          {placeholder ? <option value=''>{placeholder}</option> : null}
          {optionList.map(i => (
            <option key={i.value} value={i.value}>
              {i.label}
            </option>
          ))}
        </select>
      </div>
      {errors[name] && touched[name] ? (
        <p className={errorclasses.helperText} style={{ fontFamily: "'Noto Sans JP', sans-serif" }}>{errors[name]}</p>
      ) : null}
    </>
  )
}
export default MySelectSelectionStepMessage
