import { lazy } from 'react'

import { DashboardLayout, AuthLayout } from 'sp/layouts'
import { routeList, routePermissionList } from 'routes/routes'
import { RouteConfig } from 'react-router-config'
import NetworkError from 'pages/NetworkError'
import store from 'store'

export const publicRouteList = [
  {
    component: AuthLayout,
    routes: [
      {
        path: routeList.error,
        component: lazy(() => import('pages/ErrorBoundary/Error500')),
      },
      {
        // パスワード変更 仮パスワード変更
        path: routeList.temPassword,
        component: lazy(() => import('sp/pages/MCACM020')),
        exact: true,
      },
      {
        // 利用規約への同意
        path: routeList.checkStatute,
        component: lazy(() => import('sp/pages/MCACM030')),
        exact: true,
      },
      {
        // 準備中
        path: routeList.readying,
        component: lazy(() => import('sp/pages/MCACM040')),
        exact: true,
      },
      {
        // アカウント登録
        path: routeList.newLoginId,
        component: lazy(() => import('sp/pages/MCADM030')),
        exact: true,
      },
      {
        path: routeList.loginInduction,
        component: lazy(() => import('pages/LoginInduction')),
        exact: true,
      },
      {
        path: routeList.accessError,
        component: lazy(() => import('pages/AccessError')),
      },
      {
        path: routeList.networkError,
        component: lazy(() => import('pages/NetworkError')),
        exact: true,
      },
      {
        // ログイン
        path: routeList.home,
        component: lazy(() => import('sp/pages/MCACM010')),
      },
    ],
  },
]

export const allPrivateRouteList: RouteConfig[] = [
  {
    // 共通設定(PC)
    path: routeList.commonSettingsMenu,
    component: lazy(() => import('pages/MCABS010')),
    exact: true,
  },
  {
    // パスワード変更(PC)
    path: routeList.password,
    component: lazy(() => import('pages/MCACS020')),
    exact: true,
  },
  {
    // アカウント一覧(PC)
    path: routeList.account,
    component: lazy(() => import('pages/MCADS010')),
    exact: true,
  },
  {
    // 採用グループ一覧(PC)
    path: routeList.recruitGroup,
    component: lazy(() => import('pages/MCAES010')),
    exact: true,
  },
  {
    // アラート設定(PC)
    path: routeList.alertPreferenceSettings,
    component: lazy(() => import('pages/MCAFS010')),
    exact: true,
  },
  {
    // 管理項目一覧(PC)
    path: routeList.recruitFlag,
    component: lazy(() => import('pages/MCAHS010')),
    exact: true,
  },
  {
    // 選考フロー登録(PC)
    path: routeList.selectionFlow,
    component: lazy(() => import('pages/MCAJS010')),
    exact: true,
  },
  {
    // 応募経路一覧(PC)
    path: routeList.mediaFlag,
    component: lazy(() => import('pages/MCAIS010')),
    exact: true,
  },
  {
    // タグ一覧(PC)
    path: routeList.tag,
    component: lazy(() => import('pages/MCAKS010')),
    exact: true,
  },
  {
    // CSVテンプレート一覧(PC)
    path: routeList.csvTemplate,
    component: lazy(() => import('pages/MCALS010')),
    exact: true,
  },

  {
    // メッセージテンプレート一覧(PC)
    path: routeList.messageTemplate,
    component: lazy(() => import('pages/MCAMS010')),
    exact: true,
  },
  {
    // 提出リクエストテンプレート一覧
    path: routeList.submissionReqTemplate,
    component: lazy(() => import('pages/MCBJS010')),
    exact: true,
  },
  {
    // 署名一覧(PC)
    path: routeList.messageSign,
    component: lazy(() => import('pages/MCANS010')),
    exact: true,
  },
  {
    // 面接場所一覧(PC)
    path: routeList.interviewLocation,
    component: lazy(() => import('pages/MCAOS010')),
    exact: true,
  },
  {
    // MY検索（応募管理）一覧(PC)
    path: routeList.searchCriteria,
    component: lazy(() => import('pages/MCARS010')),
    exact: true,
  },
  {
    path: routeList.searchCriteriaMcb,
    component: lazy(() => import('pages/MCBIS010')),
    exact: true,
  },
  {
    path: routeList.pdfDownloadListMcb,
    component: lazy(() => import('pages/MCBLS010')),
    exact: true,
  },
  {
    // アクション設定一覧(PC)
    path: routeList.attachmentList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  {
    // 検索条件詳細（就職）
    path: routeList.advancedSearch,
    component: lazy(() => import('pages/MCAXS351')),
    exact: true,
  },
  {
    // エラー報告
    path: routeList.errorReport,
    component: lazy(() => import('sp/pages/MCAUM010')),
    exact: true,
  },
  {
    // エラー報告 完了
    path: routeList.errorReportComplete,
    component: lazy(() => import('sp/pages/MCAUM020')),
    exact: true,
  },
  {
    // 不採用通知設定(PC)
    path: routeList.notAdoptedNotification,
    component: lazy(() => import('pages/MCAGS010')),
    exact: true,
  },
  {
    // メッセージ一覧（受信トレイ）
    path: routeList.messageInBox,
    component: lazy(() => import('sp/pages/MCAZM010')),
    exact: true,
  },
  {
    // メッセージ一覧（送信トレイ）
    path: routeList.messageOutBox,
    component: lazy(() => import('sp/pages/MCAZM020')),
    exact: true,
  },
  {
    // メッセージ一覧（送信予約）
    path: routeList.messageReservation,
    component: lazy(() => import('sp/pages/MCAZM030')),
    exact: true,
  },
  {
    // アクション設定一覧(PC)
    path: routeList.actionPreList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  {
    // CSV作成中(PC)
    path: routeList.csvLaoding,
    component: lazy(() => import('pages/MCAXS121')),
    exact: true,
  },
  {
    // CSVダウンロード(PC)
    path: routeList.csvDownload,
    component: lazy(() => import('pages/MCAXS130')),
    exact: true,
  },
  {
    // アクション設定一覧(PC)
    path: routeList.entryManageTabactionPreList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  {
    // メッセージ一覧（下書き）
    path: routeList.messageDraft,
    component: lazy(() => import('sp/pages/MCAZM040')),
    exact: true,
  },
  {
    // メッセージ一覧（ゴミ箱）
    path: routeList.messageDustBox,
    component: lazy(() => import('sp/pages/MCAZM050')),
    exact: true,
  },
  {
    // 名寄せ条件入力(PC)
    path: routeList.nameCheckInput,
    component: lazy(() => import('pages/MCAQS010')),
    exact: true,
  },
  {
    path: routeList.mcbMessageOutBox,
    component: lazy(() => import('pages/MCAZS170')),
    exact: true,
  },
  {
    path: routeList.mcbMessageReservation,
    component: lazy(() => import('pages/MCAZS180')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDraft,
    component: lazy(() => import('pages/MCAZS190')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDustBox,
    component: lazy(() => import('pages/MCAZS200')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDetail,
    component: lazy(() => import('pages/MCAZS210')),
    exact: true,
  },
  {
    // 名寄せ結果一覧(PC)
    path: routeList.nameList,
    component: lazy(() => import('pages/MCAQS020')),
    exact: true,
  },
  {
    // 応募者情報アップロード 履歴一覧
    path: routeList.entryUploadHistory,
    component: lazy(() => import('pages/MCAPS020')),
    exact: true,
  },
  {
    // 応募者情報アップロード エラー内容確認
    path: routeList.entryUploadErrorInfoComfirm,
    component: lazy(() => import('pages/MCAPS030')),
    exact: true,
  },
  {
    // 名寄せ確認
    path: routeList.entryUploadAutoMergeComfirm,
    component: lazy(() => import('pages/MCAPS040')),
    exact: true,
  },
  {
    // 応募者情報アップロード
    path: routeList.applicationUpload,
    component: lazy(() => import('pages/MCAPS010')),
    exact: true,
  },
  {
    // メッセージ詳細
    path: routeList.messageDetail,
    component: lazy(() => import('sp/pages/MCAZM070')),
  },
  {
    // エントリー一覧
    path: routeList.entryList,
    component: lazy(() => import('sp/pages/MCAXM020')),
    exact: true,
  },
  {
    // エントリー詳細
    path: routeList.entryDetail,
    component: lazy(() => import('sp/pages/MCAXM050')),
    exact: true,
  },
  {
    // 応募者詳細（転職）
    path: routeList.applicantDetailForJobChange,
    component: lazy(() => import('sp/pages/MCAXM620')),
    exact: true,
  },
  {
    path: routeList.applicantList,
    component: lazy(() => import('pages/MCAXS010')),
    exact: true,
  },
  {
    path: routeList.applicantDetailForEmplyment,
    component: lazy(() => import('pages/MCAXS040')),
    exact: true,
  },
  {
    path: routeList.login,
    component: lazy(() => import('pages/RedirectToHome')),
  },
  {
    // お知らせ
    path: routeList.newsLocation,
    component: lazy(() => import('sp/pages/MCAVM020')),
    exact: true,
  },
  {
    // 採用状況レポート
    path: routeList.recruitmentStatusReportTab,
    component: lazy(() => import('sp/pages/MCAVM010')),
    exact: true,
  },
  {
    // 面接調整一覧
    path: routeList.scheduledList,
    component: lazy(() => import('sp/pages/MCBAM020')),
    exact: true,
  },
  {
    path: routeList.home,
    component: lazy(() => import('pages/Home')),
    exact: true,
  },
  {
    // 応募者氏名入力
    path: routeList.jobSeekerNameInput,
    component: lazy(() => import('pages/MCAXS191')),
    exact: true,
  },
  {
    // 応募者情報登録
    path: routeList.jobSeekerLogIn,
    component: lazy(() => import('pages/MCAXS201')),
    exact: true,
  },
  {
    // エントリー情報登録
    path: routeList.entryInsEdit,
    component: lazy(() => import('pages/MCAXS220')),
    exact: true,
  },
  {
    // 応募者情報編集
    path: routeList.jobSeekerInfoEdit,
    component: lazy(() => import('pages/MCAXS230')),
    exact: true,
  },
  {
    // 応募者情報登録 内容確認
    path: routeList.jobSeekerInfoInsertConfirm,
    component: lazy(() => import('pages/MCAXS211')),
    exact: true,
  },
  {
    // 応募者情報編集 内容確認
    path: routeList.jobSeekerInfoEditConfirm,
    component: lazy(() => import('pages/MCAXS650')),
    exact: true,
  },
  {
    // エントリー情報編集 内容確認
    path: routeList.entryEditConfim,
    component: lazy(() => import('pages/MCAXS640')),
    exact: true,
  },
  {
    // エントリー情報編集
    path: routeList.entryEdit,
    component: lazy(() => import('pages/MCAXS240')),
    exact: true,
  },
  {
    // エントリー情報登録 内容確認
    path: routeList.entryEditConfimIns,
    component: lazy(() => import('pages/MCAXS630')),
    exact: true,
  },
  {
    // 検索条件詳細（My CareerBox）_エントリーシート／履歴書情報
    path: routeList.pdfGenerating,
    component: lazy(() => import('pages/MCAXS151')),
    exact: true,
  },
  {
    // 検索条件詳細（My CareerBox）_提出リクエスト情報
    path: routeList.pdfDownload,
    component: lazy(() => import('pages/MCAXS141')),
    exact: true,
  },
  {
    path: routeList.creatingMcbPdf,
    component: lazy(() => import('pages/MCBLS030')),
    exact: true,
  },
  {
    path: routeList.downloadMcbPdf,
    component: lazy(() => import('pages/MCBLS020')),
    exact: true,
  },
  {
    // 名寄せ結果一覧
    path: routeList.duplicateIdentification,
    component: lazy(() => import('pages/MCAQS020')),
    exact: true,
  },
  // MCAZS220_My CareerBoxメッセージ入力
  {
    path: routeList.mcbMessageSend,
    component: lazy(() => import('pages/MCAZS220')),
    exact: true,
  },
  // MCBHS090_提出リクエスト強制停止
  {
    path: routeList.forcedStopSubmissionRequest,
    component: lazy(() => import('pages/MCBHS090')),
    exact: true,
  },
  // MCBHS060_提出リクエスト締切変更
  {
    path: routeList.ChangeTermRequest,
    component: lazy(() => import('pages/MCBHS060')),
    exact: true,
  },
  {
    path: routeList.myCareerBoxDataLink,
    component: lazy(() => import('pages/MCBCS010')),
    exact: true,
  },
  // MCBCS020_My CareerBoxデータ連携完了
  {
    path: routeList.myCareerBoxDataLinkOK,
    component: lazy(() => import('pages/MCBCS020')),
    exact: true,
  },
  // MCBリプレース MCBHS040 START
  {
    path: routeList.submissionRequestManagement,
    component: lazy(() => import('pages/MCBHS040')),
    exact: true,
  },
  // MCBリプレース MCBHS040 END
  // MCBリプレース MCBHS050 START
  {
    path: routeList.submissionRequestMessageDetails,
    component: lazy(() => import('pages/MCBHS050')),
    exact: true,
  },
  // MCBリプレース MCBHS050 END
  // MCBリプレース MCBHS010 START
  {
    path: routeList.submissionRequestSend,
    component: lazy(() => import('pages/MCBHS010')),
    exact: true,
  },
  // MCBリプレース MCBHS010 END
  // [phase2] start
  // MCBCS030
  {
    path: routeList.myCareerBoxCsvLaoding,
    component: lazy(() => import('pages/MCBCS030')),
    exact: true,
  },
  {
    path: routeList.myCareerBoxcsvDownload,
    component: lazy(() => import('pages/MCBCS040')),
    exact: true,
  },
  // [phase2] end
  {
    path: routeList.redirect,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.redirect2,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.duplicateLogin,
    component: lazy(() => import('pages/DuplicateLogin')),
    exact: true,
  },
  {
    path: routeList.interruption,
    component: lazy(() => import('pages/Interruption')),
    exact: true,
  },
  {
    // アカウント登録
    path: routeList.newLoginId,
    component: lazy(() => import('sp/pages/MCADM030')),
    exact: true,
  },
  {
    path: routeList.envCharManual,
    component: lazy(() => import('pages/RedirectToManual')),
    exact: true,
  },
  {
    path: routeList.networkError,
    component: NetworkError,
    exact: true,
  },
  {
    component: lazy(() => import('pages/NotFound')),
  },
]

// 転職March #75621 START
export const allPrivateRouteListMarch: RouteConfig[] = [
  {
    // エントリー詳細
    path: routeList.entryDetail,
    component: lazy(() => import('sp/pages/MCAXM050')),
    exact: true,
  },
  {
    //
    path: routeList.commonSettingsMenu,
    component: lazy(() => import('pages/MCABS010')),
    exact: true,
  },
  {
    path: routeList.alertPreferenceSettings,
    component: lazy(() => import('pages/MCAFS010')),
    exact: true,
  },
  {
    path: routeList.recruitFlag,
    component: lazy(() => import('pages/MCAHS010')),
    exact: true,
  },
  {
    path: routeList.recruitGroup,
    component: lazy(() => import('pages/MCAES010')),
    exact: true,
  },
  {
    path: routeList.account,
    component: lazy(() => import('pages/MCADS010')),
    exact: true,
  },
  {
    path: routeList.password,
    component: lazy(() => import('pages/MCACS020')),
    exact: true,
  },
  {
    // エントリー一覧
    path: routeList.entryList,
    component: lazy(() => import('sp/pages/MCAXM020')),
    exact: true,
  },
  // 転職March #9574 start
  // 削除 MCAXS121_CSV作成中 MCAXS130_CSVダウンロード
  // {
  //   path: routeList.csvLaoding,
  //   component: lazy(() => import('pages/MCAXS121')),
  //   exact: true,
  // },
  // {
  //   path: routeList.csvDownload,
  //   component: lazy(() => import('pages/MCAXS130')),
  //   exact: true,
  // },
  // 転職March #9574 end
  {
    path: routeList.pdfGenerating,
    component: lazy(() => import('pages/MCAXS151')),
    exact: true,
  },
  {
    path: routeList.pdfDownload,
    component: lazy(() => import('pages/MCAXS141')),
    exact: true,
  },
  {
    path: routeList.redirect,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.redirect2,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.duplicateLogin,
    component: lazy(() => import('pages/DuplicateLogin')),
    exact: true,
  },
  {
    path: routeList.interruption,
    component: lazy(() => import('pages/Interruption')),
    exact: true,
  },
  {
    // アカウント登録
    path: routeList.newLoginId,
    component: lazy(() => import('sp/pages/MCADM030')),
    exact: true,
  },
  {
    path: routeList.envCharManual,
    component: lazy(() => import('pages/RedirectToManual')),
    exact: true,
  },
  {
    // エラー報告
    path: routeList.errorReport,
    component: lazy(() => import('sp/pages/MCAUM010')),
    exact: true,
  },
  {
    // エラー完了
    path: routeList.errorReportComplete,
    component: lazy(() => import('sp/pages/MCAUM020')),
    exact: true,
  },
  // 転職March #9574 start
  {
    // お知らせ
    path: routeList.newsLocation,
    component: lazy(() => import('sp/pages/MCAVM020')),
    exact: true,
  },
  // 転職March #9574 end
  {
    path: routeList.networkError,
    component: NetworkError,
    exact: true,
  },
  {
    component: lazy(() => import('pages/NotFound')),
  },
]
// 転職March #75621 END

const navigationGuard = (
  permissions: string[],
  list = store.getState().globalMenu.recruitmentManagementDivision == '2' &&
  store.getState().globalMenu.serviceMode == '1'
    ? allPrivateRouteListMarch
    : allPrivateRouteList
) => {
  if (permissions.length === 0) {
    return undefined
  }

  const authorizedList = list.map(i => {
    if (i.path === routeList.newLoginId) {
      return { ...i, component: lazy(() => import('pages/DuplicateLogin')) }
    }
    if (i.path) {
      if (
        !permissions.some(x =>
          routePermissionList[i.path as string].includes(x)
        )
      ) {
        return { ...i, component: lazy(() => import('pages/Unauthorized')) }
      }
    }
    return i
  })

  // before 404 route config
  authorizedList.splice(-1, 0, {
    path: routeList.error,
    component: lazy(() => import('pages/ErrorBoundary/Error500')),
  })

  return authorizedList
}
export const privateRouteList = (permissions: string[]) => [
  {
    component: DashboardLayout,
    routes: navigationGuard(permissions),
  },
]
