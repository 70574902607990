import { current } from '@reduxjs/toolkit'
import { RefObject, useCallback, useRef, useState } from 'react'
import React from 'react'

type ModelessState = {
  left: number // モーダルの左端の位置
  top: number // モーダルの上部の位置
  width: number // モーダルの横幅
  height: number // モーダルの縦幅
  moving: boolean // モーダルを移動中かどうか
  visible: boolean // モーダルを表示中かどうか
  modal: boolean
  minimum: boolean
}

export const useModeless = (
  $container: RefObject<HTMLDialogElement>,
  $modal: RefObject<HTMLDivElement>
) => {
  const [modelessState, setModelessState] = useState<ModelessState>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    moving: false,
    visible: false,
    modal: false,
    minimum: false,
  })

  const moveConfig = useRef({
    left: 0,
    top: 0,
    minLeft: 0,
    minTop: 0,
    maxLeft: 0,
    maxTop: 0,
    scrollX: 0,
    scrollY: 0,
    beforeLeft: 0,
    beforeTop: 0,
  })

  const open = useCallback(() => {
    if ($container.current && $modal.current) {
      const dialogElement = $container.current
      if (dialogElement.hasAttribute('open')) {
        return
      }
      dialogElement.setAttribute('open', 'true')

      // 画面サイズ-ダイアログサイズが上限　スクロールバー分横サイズを引く
      moveConfig.current.maxLeft =
        window.innerWidth - dialogElement.offsetWidth - 20
      if (moveConfig.current.maxLeft < 0) {
        moveConfig.current.maxLeft = 0
      }

      moveConfig.current.maxTop =
        window.innerHeight - dialogElement.offsetHeight
      if (moveConfig.current.maxTop < 0) {
        moveConfig.current.maxTop = 0
      }

      //　上限値を現在の値としてセット
      moveConfig.current.left = moveConfig.current.maxLeft
      moveConfig.current.top = moveConfig.current.maxTop

      setModelessState(state => ({
        ...state,
        left: moveConfig.current.maxLeft,
        top: state.top = moveConfig.current.maxTop,
        visible: true,
      }))
    }
  }, [setModelessState])

  const close = useCallback(() => {
    setModelessState(state => ({ ...state, visible: false, modal: false }))
    const dialogElement = $container.current
    if (!dialogElement) {
      return
    }
    dialogElement.close()
    if (!dialogElement.hasAttribute('open')) {
      return
    }
    dialogElement.removeAttribute('open')
  }, [setModelessState])

  const showModal = useCallback((savePosition: boolean) => {

    if ($container.current) {
      if (savePosition) {
        // 最小化モードは位置固定となるので
        // 最小化モードでない場合、現在の位置を保存
        moveConfig.current.beforeLeft = moveConfig.current.left
        moveConfig.current.beforeTop = moveConfig.current.top
      }

      // 中央に配置
      moveConfig.current.left =
        (window.innerWidth - window.innerWidth * 0.8) / 2

      if (moveConfig.current.left < 0) {
        moveConfig.current.left = 0
      }

      moveConfig.current.top =
        (window.innerHeight - window.innerHeight * 0.8) / 2
      if (moveConfig.current.top < 0) {
        moveConfig.current.top = 0
      }
    }

    setModelessState(state => ({
      ...state,
      left: moveConfig.current.left,
      top: state.top = moveConfig.current.top,
      modal: true,
    }))
  }, [setModelessState])

  const moveStartModeless = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      //サイズ再判定
      const dialogElement = $container.current
      if (!dialogElement) {
        return
      }
      if (modelessState.minimum) {
        // 最小化時　右下となるように上限値を設定
        moveConfig.current.maxLeft = window.innerWidth - 400 - 20
        if (moveConfig.current.maxLeft < 0) {
          moveConfig.current.maxLeft = 0
        }

        moveConfig.current.maxTop = window.innerHeight - 40
        if (moveConfig.current.maxTop < 0) {
          moveConfig.current.maxTop = 0
        }
      } else {
        // 現在のブラウザサイズをもとに上限値を再設定
        moveConfig.current.maxLeft =
          window.innerWidth - dialogElement.offsetWidth - 20
        if (moveConfig.current.maxLeft < 0) {
          moveConfig.current.maxLeft = 0
        }

        moveConfig.current.maxTop =
          window.innerHeight - dialogElement.offsetHeight
        if (moveConfig.current.maxTop < 0) {
          moveConfig.current.maxTop = 0
        }
      }

      moveConfig.current.scrollX = e.pageX - e.clientX
      moveConfig.current.scrollY = e.pageY - e.clientY
      moveConfig.current.left = e.pageX - modelessState.left
      moveConfig.current.top = e.pageY - modelessState.top

      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', moveEnd)

      setModelessState(state => ({ ...state, moving: true }))
    },
    [modelessState, modelessState.left, modelessState.top]
  )

  const move = useCallback(
    (e: MouseEvent) => {
      let currentLeft =
        e.pageX - moveConfig.current.left - moveConfig.current.scrollX
      let currentTop =
        e.pageY - moveConfig.current.top - moveConfig.current.scrollY

      if (currentLeft < moveConfig.current.minLeft) {
        currentLeft = moveConfig.current.minLeft
      } else if (currentLeft > moveConfig.current.maxLeft) {
        currentLeft = moveConfig.current.maxLeft
      }
      if (currentTop < moveConfig.current.minTop) {
        currentTop = moveConfig.current.minTop
      } else if (currentTop > moveConfig.current.maxTop) {
        currentTop = moveConfig.current.maxTop
      }

      if (currentLeft < 0) {
        currentLeft = 0
      }
      if (currentTop < 0) {
        currentTop = 0
      }

      setModelessState(state => ({
        ...state,
        left: currentLeft,
        top: currentTop,
      }))
    },
    [setModelessState]
  )

  const moveEnd = useCallback(() => {
    if ($container.current) {
      moveConfig.current.left =
        $container.current.offsetLeft + moveConfig.current.scrollX
      moveConfig.current.top =
        $container.current.offsetTop + moveConfig.current.scrollY
    } else {
      moveConfig.current.left = moveConfig.current.scrollX
      moveConfig.current.top = moveConfig.current.scrollY
    }

    document.removeEventListener('mousemove', move)
    document.removeEventListener('mouseup', moveEnd)

    setModelessState(state => ({ ...state, moving: false }))
  }, [setModelessState])

  const minimalaize = useCallback((savePosition: boolean) => {
    const dialogElement = $container.current
    if (!dialogElement) {
      return
    }
    if (savePosition) {
      // モーダルモードでなければ現在位置を保存
      moveConfig.current.beforeLeft = moveConfig.current.left
      moveConfig.current.beforeTop = moveConfig.current.top
    }

    // 右下になるように設定
    moveConfig.current.left = window.innerWidth - 400 - 20
    if (moveConfig.current.left < 0) {
      moveConfig.current.left = 0
    }
    moveConfig.current.top = window.innerHeight - 40
    if (moveConfig.current.top < 0) {
      moveConfig.current.top = 0
    }

    moveConfig.current.maxLeft = moveConfig.current.left
    moveConfig.current.maxTop = moveConfig.current.top

    setModelessState(state => ({
      ...state,
      left: moveConfig.current.left,
      top: state.top = moveConfig.current.top,
      minimum: true,
      modal: false,
    }))
  }, [setModelessState])

  const normalaize = useCallback(() => {
    const dialogElement = $container.current
    if (!dialogElement) {
      return
    }
    // 通常モード　前回の位置に戻す
    setModelessState(state => ({
      ...state,
      left: moveConfig.current.beforeLeft,
      top: state.top = moveConfig.current.beforeTop,
      minimum: false,
      modal: false,
    }))
  }, [setModelessState])


  return {
    modelessConfig: modelessState,
    open,
    close,
    moveStartModeless,
    showModal,
    minimalaize,
    normalaize
  }
}
