import {
  ScreenDisplayItems,
  commonHeader,
  profile,
  images,
  gab,
  opq,
  cab,
  mcb,
  entryHistory,
  question,
  Question,
  cmnContents,
  profileContents,
  entryHistoryContents,
  webContents,
  msgContents,
  mcbContents,
  mcbMsgContents,
} from 'pages/MCAXS040/viewConfig'
import {
  InitResult,
  commonHeaderInfo,
  ApplicantDetailsTargetInfo,
  applicantDetailsTargetInfo,
  applicantDataHeaderInfo,
  profileInfo,
  aptitudeTestIMAGESInfo,
  aptitudeTestGABInfo,
  aptitudeTestOPQInfo,
  aptitudeTestCABInfo,
  selectionFlow,
  ApplicantDetailsRequest,
  CommonHeaderInfo,
  ApplicantDataHeaderInfo,
  ProfileInfo,
  ScreenTransitionRequest,
  RouteScreenTransitionRequest,
  PDFScreenTransitionRequest,
  MCAXS230InitRequest,
  MCAXS170InitRequest,
  CurrentApplicantsListInfo,
  mcbSubmissionRequestInfo,
  McbSubmissionRequestInfo,
  McbMessageInfo,
} from 'pages/MCAXS040/apiConfig'
import { MCAXS040ScreenTransitionRequest } from 'types/MCAXS040/MCAXS040ScreenTransitionRequest'
import { MCAXS040RouteScreenTransitionRequest } from 'types/MCAXS040/MCAXS040RouteScreenTransitionRequest'
import { MCAXS040UpdateExcludeRequest } from 'types/MCAXS040/MCAXS040UpdateExcludeRequest'
import { MCAXS040UpdateUnExcludeRequest } from 'types/MCAXS040/MCAXS040UpdateUnExcludeRequest'
import { MCAXS040McbCheckDetailsRequest } from 'types/MCAXS040/MCAXS040McbCheckDetailsRequest'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { magiContants } from 'utils/contants'
import { message  } from 'componentsHsc/Message'
import moment from 'moment'
import {
  MessageData,
  MessageScreenTransitionRequest,
  McbMessageScreenTransitionRequest,
  EntryHistory,
  QuestionList,
  McbInfo,
} from 'utils/tableDataTransfrom'
import { dateTimeToString, isEmpty, getNdaysLater, getNhoursLater, stringToNumber, now, stringToDateTime } from 'common/generalUtil'
import { MCAXS340InitRequest } from 'pages/MCAXS340/formConfig'
import { MCAXS310InitRequest } from 'types/MCAXS310/MCAXS310InitRequest'
import { MCAXS320InitRequest } from 'types/MCAXS320/MCAXS320InitRequest'
import React from 'react'

const initResult: InitResult = {
  commonHeaderInfo: commonHeaderInfo,
  applicantDetailsTargetInfo: applicantDetailsTargetInfo,
  applicantDataHeaderInfo: applicantDataHeaderInfo,
  displayTargetListInfoList: [],
  profileInfo: profileInfo,
  entryHistoryInfoList: [],
  // [phase2] start by zhangxp
  mcbLinkHistoryInfoList: [],
  // [phase2] end by zhangxp
  // [phase2] start by jxp
  scoutInfoList: [],
  // [phase2] end by jxp
  questionnaireInfoList: [],
  aptitudeTestIMAGESInfo: aptitudeTestIMAGESInfo,
  aptitudeTestGABInfo: aptitudeTestGABInfo,
  aptitudeTestOPQInfo: aptitudeTestOPQInfo,
  aptitudeTestCABInfo: aptitudeTestCABInfo,
  messageInfoList: [],
  selectionFlow: selectionFlow,
  managementItemList: [],
  encodeCompanyId: '',
  encodeJobSeekerCompanyId: '',
  mcbSubmissionRequestInfo: [],
  mcbMessageInfoList: []
}

const applicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  directionId: '',
  displayTargetId: '',
  listIndex: 0,
}

const currentApplicantsListInfo: CurrentApplicantsListInfo = {
  applicantsListInfo: [],
  listIndex: 0,
}

const screenDisplayItems: ScreenDisplayItems = {
  jobSeekerId: 0,
  commonHeader: commonHeader,
  profile: profile,
  entryHistory: [],
  // [phase2] start by zhangxp
  mcbLinkHistory: [],
  // [phase2] end by zhangxp
  // [phase2] start by jxp
  scout: [],
  // [phase2] end by jxp
  question: [],
  myCareerBox: mcb,
  images: images,
  gab: gab,
  opq: opq,
  cab: cab,
  message: [],
  mcbMessage: [],
}

 const messageScreenTransitionRequest: MessageScreenTransitionRequest = {
  messageSendId: [],
  entryId: [],
  selectionManagementId: []
}

const mcbMessageScreenTransitionRequest: McbMessageScreenTransitionRequest = {
  screenId: '',
  messageSendId: '',
  jobSeekerByCompanyIdList: [],
  requestIdList: [],
}

/** 画面遷移リクエスト */
const screenTransitionRequest: ScreenTransitionRequest = {
  screenId: '',
  jobSeekerId: '',
  jobSeekerIdForDisplay: '',
  entryData: [],
}

/** 応募経路追加画面遷移リクエスト */
const routeScreenTransitionRequest: RouteScreenTransitionRequest = {
  screenId: '',
  functionType: '',
  jobSeekerId: '',
  entryId: '',
  selectionManagementId: '',
  jobSeekerIdForDisplay: '',
}

/** 画面遷移リクエスト(PDF) */
const pdfScreenTransitionRequest: PDFScreenTransitionRequest = {
  jobSeekerId: [],
  entryId: [],
  pdfType: '',
  prevScreenId: '',
}

/** 画面遷移リクエスト(管理項目変更) */
const mcaxs340InitRequest: MCAXS340InitRequest = {
  jobSeekerId: [],
  entryId: [],
  selectionManagementId: [],
  recruitmentManagementFlagOptionSettingId: [],
  recruitmentManagementFlagSettingId: [],
  settingNumber: [],
  settingString: [],
  recruitmentManagementDivision: '',
}

/** 画面遷移リクエスト(応募者情報編集) */
const mcaxs230InitRequest: MCAXS230InitRequest = {
  initFlag: '',
  prevDisplayId: '',
  jobSeekerId: '',
  entryId: '',
  selectionManagementId: '',
  recruitmentManagementDivision: '',
}

/** 画面遷移リクエスト(セミナーの新規予約) */
const mcaxs310InitRequest: MCAXS310InitRequest = {
  jobSeekerIdList: [],
  recruitmentManagementDivision: '',
}

/** 画面遷移リクエスト(MCAXS170) */
const mcaxs170InitRequest: MCAXS170InitRequest = {
  screenId: '',
  jobSeekerId: [],
  selectionManagementId: [],
  entryId: [],
}

/** 画面遷移リクエスト(MCAXS320) */
const mcaxs320InitRequest: MCAXS320InitRequest = {
  entryId: '',
}

const initialState: {
  screenId: string
  applicantDetailsRequest: ApplicantDetailsRequest
  initResult: InitResult
  businessCheckResult: boolean
  screenDisplayItems: ScreenDisplayItems
  screenTransitionRequest: ScreenTransitionRequest
  routeScreenTransitionRequest: RouteScreenTransitionRequest
  pdfScreenTransitionRequest: PDFScreenTransitionRequest
  messageRequest: MessageScreenTransitionRequest
  mcbMessageRequest: McbMessageScreenTransitionRequest
  doInit: boolean
  mcaxs340InitRequest: MCAXS340InitRequest
  mcaxs230InitRequest: MCAXS230InitRequest
  mcaxs310InitRequest: MCAXS310InitRequest
  mcaxs170InitRequest: MCAXS170InitRequest
  mcaxs320InitRequest: MCAXS320InitRequest
  openManageListDialog: boolean
  currentApplicantsListInfo: CurrentApplicantsListInfo
  show: boolean
} = {
  screenId: '',
  applicantDetailsRequest: applicantDetailsRequest,
  initResult: initResult,
  businessCheckResult: false,
  screenDisplayItems: screenDisplayItems,
  screenTransitionRequest: screenTransitionRequest,
  routeScreenTransitionRequest: routeScreenTransitionRequest,
  pdfScreenTransitionRequest: pdfScreenTransitionRequest,
  messageRequest: messageScreenTransitionRequest,
  mcbMessageRequest: mcbMessageScreenTransitionRequest,
  doInit: false,
  mcaxs340InitRequest: mcaxs340InitRequest,
  mcaxs230InitRequest: mcaxs230InitRequest,
  mcaxs310InitRequest: mcaxs310InitRequest,
  mcaxs170InitRequest: mcaxs170InitRequest,
  mcaxs320InitRequest: mcaxs320InitRequest,
  currentApplicantsListInfo: currentApplicantsListInfo,
  openManageListDialog: false,
  show: false
}

const applicantDetailForEmplymentSlice = createSlice({
  name: 'applicantDetailForEmplyment',
  initialState,
  reducers: {
    // 年度対応 start
    init(
      state,
      action: PayloadAction<{
        request: ApplicantDetailsRequest
        currentApplicantsListInfo: CurrentApplicantsListInfo
        searchLinkFlg?: boolean}>
    ) {
      /*----------------------------------*
       * 初期表示イベント *
       *----------------------------------*/
      console.log('reducer処理(getInit)')
      state.applicantDetailsRequest = action.payload.request
      return state
    },
    // 年度対応 end
    setInit(state, action: PayloadAction<InitResult>) {
      /*----------------------------------*
       * SVから受け取ったデータをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setInit)')
      state.initResult = action.payload
      return state
    },
    setInitDisplay(state) {
      /*----------------------------------*
       * stateを表示用に初期化 *
       *----------------------------------*/
      state.initResult = initResult
      state.screenDisplayItems = screenDisplayItems
      return state
    },
    setScreenId(state, action: PayloadAction<string>) {
      /*----------------------------------*
       * 画面IDをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setScreenId)')
      state.screenId = action.payload
      return state
    },
    setCode(state, action: PayloadAction<boolean>) {
      /*----------------------------------*
       * SVから受け取ったデータをstateに設定 *
       *----------------------------------*/
      console.log('reducer処理(setCode)')
      state.businessCheckResult = action.payload
      return state
    },
    setDoInit(state) {
      state.doInit = true
      return state
    },
    setScreenDisplayItems(state) {
      state.doInit = false

      /*-----------------------------------*
       * SVから受け取ったデータを表示用に編集 *
       *-----------------------------------*/
      console.log('reducer処理(setScreenDisplayItems)')

      const data = state.initResult

      if (state.currentApplicantsListInfo.listIndex < 0) {
        // 現在の表示対象未設定時は取得した表示対象を現在表示リストに確保する
        const displayTarget = data.displayTargetListInfoList[0]
        const info = {
          applicantsListInfo: [
            {
              jobSeekerId: displayTarget.jobSeekerId,
              entryId: displayTarget.entryId,
              selectionManagementId: displayTarget.selectionManagementId,
            },
          ],
          listIndex: data.applicantDetailsTargetInfo.listIndex,
        }
        state.currentApplicantsListInfo = info
        const detailRequest = {
          state: info,
        }
        sessionStorage.removeItem('detailRequest')
        localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
      }

      /*----------------------*
       * 求職者IDを設定 *
       *----------------------*/
      state.screenDisplayItems.jobSeekerId =
        state.initResult.displayTargetListInfoList[0].jobSeekerId

      /*----------------------*
       * 画面遷移リクエスト(screenTransitionRequest)を設定 *
       *----------------------*/
      state.screenTransitionRequest.screenId = state.screenId
      state.screenTransitionRequest.jobSeekerId = state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      state.screenTransitionRequest.jobSeekerIdForDisplay =
        state.initResult.applicantDataHeaderInfo.jobSeekerIdForDisplay
      state.screenTransitionRequest.entryData = state.initResult.displayTargetListInfoList.map(
        item => ({
          entryId: item.entryId.toString(),
          selectionManagementId: item.selectionManagementId.toString(),
        })
      )

      /*----------------------*
       * 画面遷移リクエスト(routeScreenTransitionRequest)を設定 *
       *----------------------*/
      state.routeScreenTransitionRequest.screenId = state.screenId
      state.routeScreenTransitionRequest.jobSeekerId = state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      state.routeScreenTransitionRequest.jobSeekerIdForDisplay =
        state.initResult.applicantDataHeaderInfo.jobSeekerIdForDisplay

      /*----------------------*
       * 画面遷移リクエスト(PDFscreenTransitionRequest)を設定 *
       *----------------------*/
      state.pdfScreenTransitionRequest.jobSeekerId = []
      state.pdfScreenTransitionRequest.entryId = []

      state.pdfScreenTransitionRequest.jobSeekerId.push(
        state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      )
      state.pdfScreenTransitionRequest.entryId.push(
        state.initResult.displayTargetListInfoList[0].entryId.toString()
      )
      state.pdfScreenTransitionRequest.pdfType = '0'

      /*----------------------*
       * 画面遷移リクエスト(messageRequest)を設定 *
       *----------------------*/
      state.messageRequest.entryId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.entryId.toString()
        }
      )
      state.messageRequest.selectionManagementId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.selectionManagementId.toString()
        }
      )

      /*----------------------*
       * 画面遷移リクエスト(mcaxs340InitRequest)を設定 *
       *----------------------*/
      state.mcaxs340InitRequest.jobSeekerId = [
        state.initResult.displayTargetListInfoList[0].jobSeekerId,
      ]

      /*----------------------*
       * 画面遷移リクエスト(mcaxs230InitRequest)を設定 *
       *----------------------*/
      state.mcaxs230InitRequest.initFlag = magiContants.STRING_0
      state.mcaxs230InitRequest.jobSeekerId = state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      state.mcaxs230InitRequest.entryId = cmnContents.BLANK
      state.mcaxs230InitRequest.selectionManagementId = cmnContents.BLANK
      state.mcaxs230InitRequest.prevDisplayId = cmnContents.SCREEN_ID

      /*----------------------*
       * 画面遷移リクエスト(mcaxs310InitRequest)を設定 *
       *----------------------*/
      state.mcaxs310InitRequest.jobSeekerIdList.push(String(state.initResult.displayTargetListInfoList[0].jobSeekerId))

      /*----------------------*
       * 画面遷移リクエスト(mcaxs170InitRequest)を設定 *
       *----------------------*/
      state.mcaxs170InitRequest.screenId = state.screenId
      state.mcaxs170InitRequest.jobSeekerId = []
      state.mcaxs170InitRequest.jobSeekerId.push(
        state.initResult.displayTargetListInfoList[0].jobSeekerId.toString()
      )
      state.mcaxs170InitRequest.entryId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.entryId.toString()
        }
      )
      state.mcaxs170InitRequest.selectionManagementId = state.initResult.displayTargetListInfoList.map(
        i => {
          return i.selectionManagementId.toString()
        }
      )

      /*----------------------*
       * 共通ヘッダエリアを設定 *
       *----------------------*/
      /** 共通ヘッダ情報 */
      const commonHeaderInfoDto: CommonHeaderInfo = data.commonHeaderInfo
      /** 応募者データヘッダ情報 */
      const applicantDataHeaderInfoDto: ApplicantDataHeaderInfo =
        data.applicantDataHeaderInfo
      /** 応募者詳細表示対象情報 */
      const applicantDetailsTargetInfoDto: ApplicantDetailsTargetInfo =
        data.applicantDetailsTargetInfo

      state.screenDisplayItems.commonHeader.companyName =
        commonHeaderInfoDto.companyName
      state.screenDisplayItems.commonHeader.loginUserName = commonHeaderInfoDto.fullName
      // listIndex=0：「前へ」ボタンを非活性
      state.screenDisplayItems.commonHeader.goBack =
        state.currentApplicantsListInfo.listIndex === 0 ? false : true
      // listIndex=一覧で表示した求職者数：「次へ」ボタンを非活性
      state.screenDisplayItems.commonHeader.goNext =
        state.currentApplicantsListInfo.listIndex ===
        state.currentApplicantsListInfo.applicantsListInfo.length - 1
          ? false
          : true
      state.screenDisplayItems.commonHeader.fullName = `${applicantDataHeaderInfoDto.familyName} ${applicantDataHeaderInfoDto.name}`
      state.screenDisplayItems.commonHeader.familyName = applicantDataHeaderInfoDto.familyName
      state.screenDisplayItems.commonHeader.name = applicantDataHeaderInfoDto.name
      state.screenDisplayItems.commonHeader.fullNameKana = `${applicantDataHeaderInfoDto.familyNameKana} ${applicantDataHeaderInfoDto.nameKana}`
      state.screenDisplayItems.commonHeader.familyNameKana = applicantDataHeaderInfoDto.familyNameKana
      state.screenDisplayItems.commonHeader.nameKana = applicantDataHeaderInfoDto.nameKana
      state.screenDisplayItems.commonHeader.jobSeekerIdForDisplay = `(${applicantDataHeaderInfoDto.jobSeekerIdForDisplay})`
      // 退会日時が存在する場合：退会済みバッジを表示
      if (applicantDataHeaderInfoDto.unsubscribeTime != null) {
        state.screenDisplayItems.commonHeader.UnsubscribedBadge = true
      } else {
        state.screenDisplayItems.commonHeader.UnsubscribedBadge = false
      }

      /*------------------------*
       * プロフィールエリアを設定 *
       *------------------------*/
      /** プロフィール情報 */
      const profileInfoDto: ProfileInfo = data.profileInfo

      let prefecturesName = cmnContents.BLANK
      let addressAfterCity = cmnContents.BLANK
      let birthdate = cmnContents.HYPHEN
      let sex = cmnContents.BLANK
      let schoolName = cmnContents.BLANK
      let facultyName = cmnContents.BLANK
      let subjectName = cmnContents.BLANK
      let graduationPlanTimeName = cmnContents.BLANK
      if (!isEmpty(profileInfoDto.prefecturesName)) {
        prefecturesName = profileInfoDto.prefecturesName
      }
      if (!isEmpty(profileInfoDto.addressAfterCity)) {
        addressAfterCity = profileInfoDto.addressAfterCity
      }
      if (!isEmpty(profileInfoDto.birthdate)) {
        birthdate = profileInfoDto.birthdate
      }
      if (profileContents.UNKNOWN !== profileInfoDto.sex) {
        sex = profileInfoDto.sex
      }
      if (!isEmpty(profileInfoDto.schoolName)) {
        schoolName = `${profileInfoDto.schoolName} `
      }
      if (!isEmpty(profileInfoDto.facultyName)) {
        facultyName = `${profileInfoDto.facultyName} `
      }
      if (!isEmpty(profileInfoDto.subjectName)) {
        subjectName = `${profileInfoDto.subjectName} `
      }
      if (!isEmpty(profileInfoDto.graduationPlanTimeName)) {
        graduationPlanTimeName = `${profileInfoDto.graduationPlanTimeName}`
      }

      // 値の設定
      // AWS（S3）から画像を取得
      if (profileInfoDto.facePhotoManagementId) {
        state.screenDisplayItems.profile.profileFacePhoto = `${magiContants.MCAXS040_S3_IMAGES_URL}?photoManagementId=${profileInfoDto.facePhotoManagementId}&num=${Math.random()}`
      } else {
        state.screenDisplayItems.profile.profileFacePhoto = cmnContents.BLANK
      }
      state.screenDisplayItems.profile.profileFacePhotoUploadButtonDisabledFlag = profileInfoDto.scanVirusFlag
      let profileFullNameKana = cmnContents.BLANK
      if (!isEmpty(profileInfoDto.familyNameKana) && !isEmpty(profileInfoDto.nameKana)) {
        profileFullNameKana = `(${profileInfoDto.familyNameKana} ${profileInfoDto.nameKana})`
      }
      else if (!isEmpty(profileInfoDto.familyNameKana)) {
        profileFullNameKana = `(${profileInfoDto.familyNameKana})`
      }
      else if (!isEmpty(profileInfoDto.nameKana)) {
        profileFullNameKana = `(${profileInfoDto.nameKana})`
      }
      state.screenDisplayItems.profile.profileFullName = `${profileInfoDto.familyName} ${profileInfoDto.name}${profileFullNameKana}`
      state.screenDisplayItems.profile.profilePostalCode =
        profileInfoDto.postalCode
      state.screenDisplayItems.profile.profileStreetAddress = `${prefecturesName}${addressAfterCity}`
      state.screenDisplayItems.profile.profileBirthDateSex = `${birthdate} ${sex}`
      state.screenDisplayItems.profile.profileSchoolInformation = `${schoolName}${facultyName}${subjectName}${graduationPlanTimeName}`
      state.screenDisplayItems.profile.profileEligibility = profileInfoDto.tag3
      state.screenDisplayItems.profile.profileMobilePhone =
        profileInfoDto.phoneNumber3
      state.screenDisplayItems.profile.profileFixedLinePhone =
        profileInfoDto.phoneNumber1
      state.screenDisplayItems.profile.profileMobileEmail =
        profileInfoDto.mailAddress2
      state.screenDisplayItems.profile.profileEmail =
        profileInfoDto.mailAddress1
      state.screenDisplayItems.profile.profileLanguageSkills =
        profileInfoDto.tag4
      // [phase2] start by zhangxp
      state.screenDisplayItems.profile.profileZeminar =
        profileInfoDto.zeminar
      state.screenDisplayItems.profile.profileCircle =
        profileInfoDto.circle
      state.screenDisplayItems.profile.profileMajorTheme =
        profileInfoDto.majorTheme
      // [phase2] end by zhangxp
      state.screenDisplayItems.profile.schoolTypeName =
        profileInfoDto.schoolTypeName
      state.screenDisplayItems.profile.schoolGroupName =
        profileInfoDto.schoolGroupName
      state.screenDisplayItems.profile.humanitiesAndSciencesDivisionName = 
        profileInfoDto.humanitiesAndSciencesDivisionName
      state.screenDisplayItems.profile.profileExtracurricularActivities =
        profileInfoDto.extracurricularActivities
      /*--------------------------*
       * エントリー履歴エリアを設定 *
       *--------------------------*/
      /** エントリー履歴情報 */
      const EntryHistoryInfoList = data.entryHistoryInfoList

      if (EntryHistoryInfoList.length > 0) {
        let entryHistoryItem
        const workEntryHistoryInfoDto: EntryHistory[] = []
        // 値の設定
        for (entryHistoryItem of EntryHistoryInfoList) {
          /** work */
          const workEntryHistory = Object.assign({}, entryHistory)

          if (entryHistoryItem.manualRegistrationFlag === '0') {
            switch (entryHistoryItem.entryType) {
              case cmnContents.CODE_BULK:
                workEntryHistory.entryHistoryType = cmnContents.STR_BULK
                break
              case cmnContents.CODE_INDIVIDUAL:
                workEntryHistory.entryHistoryType = cmnContents.STR_INDIVIDUAL
                break
              case cmnContents.CODE_SEMINAR:
                workEntryHistory.entryHistoryType = cmnContents.STR_SEMINAR
                break
              case cmnContents.CODE_EVENT:
                workEntryHistory.entryHistoryType = cmnContents.STR_EVENT
                break
              default:
                break
            }
            if(entryHistoryItem.entryType === cmnContents.CODE_EVENT){
              workEntryHistory.entryHistoryTitle = entryHistoryItem.eventName
            }else{
              workEntryHistory.entryHistoryTitle = entryHistoryItem.entryBoxName
            }
            workEntryHistory.entryHistoryEntryDateTime =
              entryHistoryItem.entryDate
          } else {
            workEntryHistory.entryHistoryType = cmnContents.IMPORT
            let addingOpportunityName = "";
            switch (entryHistoryItem.addingOpportunity) {
              case '0':
                addingOpportunityName = entryHistoryContents.APPLICATION_ROUTE_ADDITION
                break
              case '1':
                addingOpportunityName = entryHistoryContents.SCREEN_ENTRY
                break
              case '2':
                addingOpportunityName = entryHistoryContents.CSV_UPLOAD
                break
              default:
                addingOpportunityName = cmnContents.HYPHEN
                break
            }
            workEntryHistory.entryHistoryTitle = `${addingOpportunityName} (${entryHistoryItem.mediaRouteName})`
            workEntryHistory.entryHistoryEntryDateTime = entryHistoryItem.entryDate.slice(
              0,
              10
            )
          }
          // フェーズ2改修　start
          if (
            isEmpty(entryHistoryItem.eventDate) 
          ) {
            workEntryHistory.entryHistoryHoldDateTime = entryHistoryItem.eventDateText == null ? cmnContents.HYPHEN :entryHistoryItem.eventDateText
          } else if(isEmpty(entryHistoryItem.startTime)){
            workEntryHistory.entryHistoryHoldDateTime = entryHistoryItem.eventDate
          }
          // フェーズ2改修　end
          else {
            workEntryHistory.entryHistoryHoldDateTime = `${entryHistoryItem.eventDate} ${entryHistoryItem.startTime}`
          }

          if (isEmpty(entryHistoryItem.venueLocationName)) {
            workEntryHistory.entryHistoryDistrict = cmnContents.HYPHEN
          } else {
            workEntryHistory.entryHistoryDistrict =
              entryHistoryItem.venueLocationName
          }
          // [phase2] start by zhangxp
          if (isEmpty(entryHistoryItem.venueAreaName)) {
            workEntryHistory.entryHistoryArea = cmnContents.BLANK
          } else {
            workEntryHistory.entryHistoryArea =
              entryHistoryItem.venueAreaName
          }
          // [phase2] end by zhangxp
          if(entryHistoryItem.seminarType !== entryHistoryContents.WEB_INTERVIEW){
            if (
              entryHistoryItem.watchingStatus === null ||
              entryHistoryItem.cancelFlag === entryHistoryContents.CANCEL_FLAG_CANCEL
            ) {
              workEntryHistory.entryHistoryViewingStatus = cmnContents.HYPHEN
            } else {
              switch (entryHistoryItem.watchingStatus) {
                case '0':
                  workEntryHistory.entryHistoryViewingStatus =
                    entryHistoryContents.NOT_VIEWED
                  break
                case '1':
                  workEntryHistory.entryHistoryViewingStatus =
                    entryHistoryContents.IN_WATCHING
                  break
                case '2':
                  workEntryHistory.entryHistoryViewingStatus =
                    entryHistoryContents.WATCHED
                  break
                default:
                  workEntryHistory.entryHistoryViewingStatus = cmnContents.HYPHEN
                  break
              }
            }
          } else {
            if (
              entryHistoryItem.webInterviewParticipatingStatusId === null
            ) {
              workEntryHistory.entryHistoryViewingStatus = cmnContents.HYPHEN
            } else {
                  workEntryHistory.entryHistoryViewingStatus = entryHistoryContents.PARTICIPATION
            }
          }
          workEntryHistory.entryHistoryCancel =
            entryHistoryItem.entryType === '03' ? true : false
          workEntryHistory.entryHistoryCancelFlag = entryHistoryItem.cancelFlag
          workEntryHistory.entryHistoryCancelTime = entryHistoryItem.cancelTime
          workEntryHistory.entryHistoryCancelType = entryHistoryItem.cancelType
          workEntryHistory.entryId = entryHistoryItem.entryId
          workEntryHistory.manualRegistrationFlag2 = entryHistoryItem.manualRegistrationFlag2
          workEntryHistoryInfoDto.push(workEntryHistory)
        }

        state.screenDisplayItems.entryHistory = workEntryHistoryInfoDto.map(
          (item, index) => ({
            entryHistoryNo: index + 1,
            entryHistoryType: item.entryHistoryType,
            entryHistoryTitle: item.entryHistoryTitle,
            entryHistoryHoldDateTime: item.entryHistoryHoldDateTime,
            entryHistoryDistrict: item.entryHistoryDistrict,
	          // [phase2] start by zhangxp
            entryHistoryArea: item.entryHistoryArea,
	          // [phase2] end by zhangxp
            entryHistoryEntryDateTime: item.entryHistoryEntryDateTime,
            entryHistoryCancel: item.entryHistoryCancel,
            entryHistoryViewingStatus: item.entryHistoryViewingStatus,
            entryHistoryCancelFlag: item.entryHistoryCancelFlag,
            entryHistoryCancelTime: item.entryHistoryCancelTime,
            entryHistoryCancelType: item.entryHistoryCancelType,
            entryId: item.entryId,
            manualRegistrationFlag: item.manualRegistrationFlag,
            manualRegistrationFlag2: item.manualRegistrationFlag2,
          })
        )
      } else {
        state.screenDisplayItems.entryHistory = []
      }

      /*----------------------*
       * アンケートエリアを設定 *
       *----------------------*/
      /** アンケート情報 */
      const QuestionnaireInfoList = data.questionnaireInfoList

      if (QuestionnaireInfoList.length > 0) {
        let questionItem
        const QuestionnaireInfoDto: Question[] = []
        // 値の設定
        for (questionItem of QuestionnaireInfoList) {
          /** work_Question */
          const workQuestion = { ...question }
          switch (questionItem.entryType) {
            case cmnContents.CODE_BULK:
              workQuestion.questionType = `[${cmnContents.STR_BULK}]`
              break
            case cmnContents.CODE_INDIVIDUAL:
              workQuestion.questionType = `[${cmnContents.STR_INDIVIDUAL}]`
              break
            case cmnContents.CODE_SEMINAR:
              workQuestion.questionType = `[${cmnContents.STR_SEMINAR}]`
              break
            case cmnContents.CODE_EVENT:
              workQuestion.questionType = `[${cmnContents.STR_EVENT}]`
              break
            default:
              break
          }
          workQuestion.questionMediumBadge = questionItem.contractMediaName
          workQuestion.questionEntryBoxName = questionItem.entryBoxName
          workQuestion.questionEntryDateTime = questionItem.entryReceptionTime

          /** work_QuestionList */
          let workQuestionList: QuestionList[] = []
          // let workQuestionList[] = {...questionList}
          workQuestionList = questionItem.questionnaireList.map(
            (item, index) => ({
              questionNo: item.questionNumber,
              questionContent: item.question,
              questionRequiredFlag: item.requiredFlag === '1' ? true : false,
              questionInvisibleFlag: item.invisibleFlag === '1' ? true : false,
              questionAnswer: item.answer,
            })
          )
          workQuestion.questionList = workQuestionList

          QuestionnaireInfoDto.push(workQuestion)
        }
        state.screenDisplayItems.question = QuestionnaireInfoDto.map(
          (item, index) => ({
            questionType: item.questionType,
            questionMediumBadge: item.questionMediumBadge,
            questionEntryBoxName: item.questionEntryBoxName,
            questionEntryDateTime: item.questionEntryDateTime,
            questionList: item.questionList,
          })
        )
      } else {
        state.screenDisplayItems.question = []
      }

      /*---------------------------------*
       * 適性テスト（イメジス）エリアを設定 *
       *---------------------------------*/
      /** 適性テスト（イメジス）情報 */
      const IMAGESInfoDto = data.aptitudeTestIMAGESInfo
      if (IMAGESInfoDto === null) {
        state.screenDisplayItems.images.viewFlag = false
      } else {
        if (IMAGESInfoDto.imagesDataId === null) {
          state.screenDisplayItems.images = { ...images }
        } else {
          // 値の設定
          switch (IMAGESInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.images.imagesUpdatePath =
                webContents.MANUAL
              state.screenDisplayItems.images.imagesCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.images.imagesUpdatePath =
                webContents.AUTOMATIC
              state.screenDisplayItems.images.imagesCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.images.imagesIntelligenceDeviationSynthesis =
            IMAGESInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationCounting =
            IMAGESInfoDto.countingDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationLanguage =
            IMAGESInfoDto.languageDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceDeviationEnglish =
            IMAGESInfoDto.englishDeviationValue
          state.screenDisplayItems.images.imagesIntelligenceStandardCounting =
            IMAGESInfoDto.countingStandardPoint
          state.screenDisplayItems.images.imagesIntelligenceStandardLanguage =
            IMAGESInfoDto.languageStandardPoint
          state.screenDisplayItems.images.imagesIntelligenceStandardEnglish =
            IMAGESInfoDto.englishStandardPoint
          state.screenDisplayItems.images.imagesPersonalityI =
            IMAGESInfoDto.irealityImagination
          state.screenDisplayItems.images.imagesPersonalityM =
            IMAGESInfoDto.mflexibleMethodical
          state.screenDisplayItems.images.imagesPersonalityA =
            IMAGESInfoDto.aselfPacedEffortIntention
          state.screenDisplayItems.images.imagesPersonalityG =
            IMAGESInfoDto.gintrovertedOpened
          state.screenDisplayItems.images.imagesPersonalityE =
            IMAGESInfoDto.erelaxWorrier
          state.screenDisplayItems.images.imagesPersonalityS =
            IMAGESInfoDto.scalmSympathy
          state.screenDisplayItems.images.imagesPersonalityVitality =
            IMAGESInfoDto.evigor
          state.screenDisplayItems.images.imagesPersonalityInterpersonal =
            IMAGESInfoDto.hantipersonnel
          state.screenDisplayItems.images.imagesPersonalityEstablishment =
            IMAGESInfoDto.mfixation
          state.screenDisplayItems.images.imagesBirthday =
            IMAGESInfoDto.birthdate
          state.screenDisplayItems.images.imagesUpdateDate =
            IMAGESInfoDto.updateTime
          // Phase2 Start 
          state.screenDisplayItems.images.imagesPersonalityD =
            IMAGESInfoDto.dstaticDynamic
          state.screenDisplayItems.images.imagesPersonalityW = 
            IMAGESInfoDto.wthinkingKindness
          // Phase2 End
        }
        state.screenDisplayItems.images.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（GAB）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（GAB）情報 */
      const gabInfoDto = data.aptitudeTestGABInfo
      if (gabInfoDto === null) {
        state.screenDisplayItems.gab.viewFlag = false
      } else {
        if (gabInfoDto.webGabDataId === null) {
          state.screenDisplayItems.gab = { ...gab }
        } else {
          // 値の設定
          switch (gabInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.gab.gabUpdatePath = webContents.MANUAL
              state.screenDisplayItems.gab.gabCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.gab.gabUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.gab.gabCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.gab.gabTestLanguage = gabInfoDto.examLanguage
          state.screenDisplayItems.gab.gabExamDateCounting =
            gabInfoDto.countingExamDate
          state.screenDisplayItems.gab.gabExamDateLanguage =
            gabInfoDto.languageExamDate
          state.screenDisplayItems.gab.gabExamDateOpq = gabInfoDto.opqExamDate
          state.screenDisplayItems.gab.gabIntelligenceDeviationSynthesis =
            gabInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceDeviationCounting =
            gabInfoDto.countingDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceDeviationLanguage =
            gabInfoDto.languageDeviationValue
          state.screenDisplayItems.gab.gabIntelligenceStandardSynthesis =
            gabInfoDto.comprehensiveStandardPoint
          state.screenDisplayItems.gab.gabIntelligenceStandardCounting =
            gabInfoDto.countingStandardPoint
          state.screenDisplayItems.gab.gabIntelligenceStandardLanguage =
            gabInfoDto.languageStandardPoint
          state.screenDisplayItems.gab.gabManagementQualities =
            gabInfoDto.managementCapacity
          state.screenDisplayItems.gab.gabAbilityVitality = gabInfoDto.vitality
          state.screenDisplayItems.gab.gabAbilityPerPerson =
            gabInfoDto.perPerson
          state.screenDisplayItems.gab.gabAbilityTeamwork = gabInfoDto.teamwork
          state.screenDisplayItems.gab.gabAbilityCreativeThinking =
            gabInfoDto.creativeThinkingPower
          state.screenDisplayItems.gab.gabAbilityProblemSolving =
            gabInfoDto.problemSolvingPower
          state.screenDisplayItems.gab.gabAbilitySituationalAdaptability =
            gabInfoDto.statusAdaptationPower
          state.screenDisplayItems.gab.gabAbilityPressureResistance =
            gabInfoDto.proofStressToPressure
          state.screenDisplayItems.gab.gabAbilityOrganizingAbility =
            gabInfoDto.organizeAbility
          state.screenDisplayItems.gab.gabAbilityLeadership =
            gabInfoDto.commandPower
          state.screenDisplayItems.gab.gabJobAptitudeSales1 = gabInfoDto.salesI
          state.screenDisplayItems.gab.gabJobAptitudeSales2 = gabInfoDto.salesIi
          state.screenDisplayItems.gab.gabJobAptitudeStaff = gabInfoDto.staff
          state.screenDisplayItems.gab.gabJobAptitudeGeneralSecretary =
            gabInfoDto.generalOffice
          state.screenDisplayItems.gab.gabJobAptitudeEngineer =
            gabInfoDto.engineer
          state.screenDisplayItems.gab.gabJobAptitudeResearchDevelopment =
            gabInfoDto.researchDevelopment
          state.screenDisplayItems.gab.gabJobAptitudeCustomersCounterCallCenter =
            gabInfoDto.serviceOverTheCounterSalesCallCenter
          state.screenDisplayItems.gab.gabOpqPersuasiveness =
            gabInfoDto.persuasionPower
          state.screenDisplayItems.gab.gabOpqExtrovert = gabInfoDto.extroversion
          state.screenDisplayItems.gab.gabOpqHumility = gabInfoDto.humility
          state.screenDisplayItems.gab.gabOpqConcreteThings =
            gabInfoDto.concreteThing
          state.screenDisplayItems.gab.gabOpqHuman = gabInfoDto.human
          state.screenDisplayItems.gab.gabOpqConceptuality =
            gabInfoDto.conceptuality
          state.screenDisplayItems.gab.gabOpqElaborate = gabInfoDto.accurate
          state.screenDisplayItems.gab.gabOpqAnxiousMind = gabInfoDto.worrier
          state.screenDisplayItems.gab.gabOpqOptimistic = gabInfoDto.optimistic
          state.screenDisplayItems.gab.gabOpqCompetitive =
            gabInfoDto.competitiveness
          state.screenDisplayItems.gab.gabOpqLeadership =
            gabInfoDto.coachingPower
          state.screenDisplayItems.gab.gabOpqFriendliness =
            gabInfoDto.friendship
          state.screenDisplayItems.gab.gabOpqConsultativeness =
            gabInfoDto.consultation
          state.screenDisplayItems.gab.gabOpqData = gabInfoDto.data
          state.screenDisplayItems.gab.gabOpqOrthodox = gabInfoDto.orthodox
          state.screenDisplayItems.gab.gabOpqCreative = gabInfoDto.creative
          state.screenDisplayItems.gab.gabOpqMeticulous = gabInfoDto.methodical
          state.screenDisplayItems.gab.gabOpqTough = gabInfoDto.tough
          state.screenDisplayItems.gab.gabOpqCritical = gabInfoDto.criticism
          state.screenDisplayItems.gab.gabOpqAscension =
            gabInfoDto.upwardIntention
          state.screenDisplayItems.gab.gabOpqUniqueness = gabInfoDto.uniqueness
          state.screenDisplayItems.gab.gabOpqSocial = gabInfoDto.sociality
          state.screenDisplayItems.gab.gabOpqTrouble = gabInfoDto.toCare
          state.screenDisplayItems.gab.gabOpqAestheticValue =
            gabInfoDto.aestheticValue
          state.screenDisplayItems.gab.gabOpqChangeOriented =
            gabInfoDto.changeIntention
          state.screenDisplayItems.gab.gabOpqPlanning = gabInfoDto.planning
          state.screenDisplayItems.gab.gabOpqMargin = gabInfoDto.allowance
          state.screenDisplayItems.gab.gabOpqSuppression =
            gabInfoDto.suppression
          state.screenDisplayItems.gab.gabOpqAbilityTakeAction =
            gabInfoDto.activityPower
          state.screenDisplayItems.gab.gabOpqDetermination =
            gabInfoDto.decisionPower
          state.screenDisplayItems.gab.gabConsistency = gabInfoDto.consistency
          state.screenDisplayItems.gab.gabUpdateDate = gabInfoDto.updateTime
        }
        state.screenDisplayItems.gab.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（OPQ）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（OPQ）情報 */
      const opqInfoDto = data.aptitudeTestOPQInfo
      if (opqInfoDto === null) {
        state.screenDisplayItems.opq.viewFlag = false
      } else {
        if (opqInfoDto.webOpqDataId === null) {
          state.screenDisplayItems.opq = { ...opq }
        } else {
          // 値の設定
          switch (opqInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.opq.opqUpdatePath = webContents.MANUAL
              state.screenDisplayItems.opq.opqCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.opq.opqUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.opq.opqCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.opq.opqExamDate = opqInfoDto.examDate
          state.screenDisplayItems.opq.opqManagementQualities =
            opqInfoDto.managementCapacity
          state.screenDisplayItems.opq.opqAbilityVitality = opqInfoDto.vitality
          state.screenDisplayItems.opq.opqAbilityPerPerson =
            opqInfoDto.perPerson
          state.screenDisplayItems.opq.opqAbilityTeamwork = opqInfoDto.teamwork
          state.screenDisplayItems.opq.opqAbilityCreativeThinking =
            opqInfoDto.creativeThinkingPower
          state.screenDisplayItems.opq.opqAbilityProblemSolving =
            opqInfoDto.problemSolvingPower
          state.screenDisplayItems.opq.opqAbilitySituationalAdaptability =
            opqInfoDto.statusAdaptationPower
          state.screenDisplayItems.opq.opqAbilityPressureResistance =
            opqInfoDto.proofStressToPressure
          state.screenDisplayItems.opq.opqAbilityOrganizingAbility =
            opqInfoDto.organizeAbility
          state.screenDisplayItems.opq.opqAbilityLeadership =
            opqInfoDto.commandPower
          state.screenDisplayItems.opq.opqJobAptitudeSales1 = opqInfoDto.salesI
          state.screenDisplayItems.opq.opqJobAptitudeSales2 = opqInfoDto.salesIi
          state.screenDisplayItems.opq.opqJobAptitudeStaff = opqInfoDto.staff
          state.screenDisplayItems.opq.opqJobAptitudeGeneralSecretary =
            opqInfoDto.generalOffice
          state.screenDisplayItems.opq.opqJobAptitudeEngineer =
            opqInfoDto.engineer
          state.screenDisplayItems.opq.opqJobAptitudeResearchDevelopment =
            opqInfoDto.researchDevelopment
          state.screenDisplayItems.opq.opqJobAptitudeCustomersCounterCallCenter =
            opqInfoDto.serviceOverTheCounterSalesCallCenter
          state.screenDisplayItems.opq.opqOpqPersuasiveness =
            opqInfoDto.persuasionPower
          state.screenDisplayItems.opq.opqOpqExtrovert = opqInfoDto.extroversion
          state.screenDisplayItems.opq.opqOpqHumility = opqInfoDto.humility
          state.screenDisplayItems.opq.opqOpqConcreteThings =
            opqInfoDto.concreteThing
          state.screenDisplayItems.opq.opqOpqHuman = opqInfoDto.human
          state.screenDisplayItems.opq.opqOpqConceptuality =
            opqInfoDto.conceptuality
          state.screenDisplayItems.opq.opqOpqElaborate = opqInfoDto.accurate
          state.screenDisplayItems.opq.opqOpqAnxiousMind = opqInfoDto.worrier
          state.screenDisplayItems.opq.opqOpqOptimistic = opqInfoDto.optimistic
          state.screenDisplayItems.opq.opqOpqCompetitive =
            opqInfoDto.competitiveness
          state.screenDisplayItems.opq.opqOpqLeadership =
            opqInfoDto.coachingPower
          state.screenDisplayItems.opq.opqOpqFriendliness =
            opqInfoDto.friendship
          state.screenDisplayItems.opq.opqOpqConsultativeness =
            opqInfoDto.consultation
          state.screenDisplayItems.opq.opqOpqData = opqInfoDto.data
          state.screenDisplayItems.opq.opqOpqOrthodox = opqInfoDto.orthodox
          state.screenDisplayItems.opq.opqOpqCreative = opqInfoDto.creative
          state.screenDisplayItems.opq.opqOpqMeticulous = opqInfoDto.methodical
          state.screenDisplayItems.opq.opqOpqTough = opqInfoDto.tough
          state.screenDisplayItems.opq.opqOpqCritical = opqInfoDto.criticism
          state.screenDisplayItems.opq.opqOpqAscension =
            opqInfoDto.upwardIntention
          state.screenDisplayItems.opq.opqOpqUniqueness = opqInfoDto.uniqueness
          state.screenDisplayItems.opq.opqOpqSocial = opqInfoDto.sociality
          state.screenDisplayItems.opq.opqOpqTrouble = opqInfoDto.toCare
          state.screenDisplayItems.opq.opqOpqAestheticValue =
            opqInfoDto.aestheticValue
          state.screenDisplayItems.opq.opqOpqChangeOriented =
            opqInfoDto.changeIntention
          state.screenDisplayItems.opq.opqOpqPlanning = opqInfoDto.planning
          state.screenDisplayItems.opq.opqOpqMargin = opqInfoDto.allowance
          state.screenDisplayItems.opq.opqOpqSuppression =
            opqInfoDto.suppression
          state.screenDisplayItems.opq.opqOpqAbilityTakeAction =
            opqInfoDto.activityPower
          state.screenDisplayItems.opq.opqOpqDetermination =
            opqInfoDto.decisionPower
          state.screenDisplayItems.opq.opqConsistency = opqInfoDto.consistency
          state.screenDisplayItems.opq.opqUpdateDate = opqInfoDto.updateTime
        }
        state.screenDisplayItems.opq.viewFlag = true
      }

      /*-----------------------------*
       * 適性テスト（CAB）エリアを設定 *
       *-----------------------------*/
      /** 適性テスト（CAB）情報 */
      const cabInfoDto = data.aptitudeTestCABInfo
      if (cabInfoDto === null) {
        state.screenDisplayItems.cab.viewFlag = false
      } else {
        if (cabInfoDto.webCabDataId === null) {
          state.screenDisplayItems.cab = { ...cab }
        } else {
          // 値の設定
          switch (cabInfoDto.updateRoute) {
            case 1:
              state.screenDisplayItems.cab.cabUpdatePath = webContents.MANUAL
              state.screenDisplayItems.cab.cabCheckResultsReport = false
              break
            case 2:
              state.screenDisplayItems.cab.cabUpdatePath = webContents.AUTOMATIC
              state.screenDisplayItems.cab.cabCheckResultsReport = true
              break
            default:
              break
          }
          state.screenDisplayItems.cab.cabTestLanguage = cabInfoDto.examLanguage
          state.screenDisplayItems.cab.cabExamDateCab1 = cabInfoDto.cab1ExamDate
          state.screenDisplayItems.cab.cabExamDateCab2 = cabInfoDto.cab2ExamDate
          state.screenDisplayItems.cab.cabExamDateCab3 = cabInfoDto.cab3ExamDate
          state.screenDisplayItems.cab.cabExamDateCab4 = cabInfoDto.cab4ExamDate
          state.screenDisplayItems.cab.cabExamDateOpq = cabInfoDto.opqExamDate
          state.screenDisplayItems.cab.cabIntelligenceDeviationSynthesis =
            cabInfoDto.comprehensiveDeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab1 =
            cabInfoDto.cab1DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab2 =
            cabInfoDto.cab2DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab3 =
            cabInfoDto.cab3DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceDeviationCab4 =
            cabInfoDto.cab4DeviationValue
          state.screenDisplayItems.cab.cabIntelligenceStandardSynthesis =
            cabInfoDto.comprehensiveStandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab1 =
            cabInfoDto.cab1StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab2 =
            cabInfoDto.cab2StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab3 =
            cabInfoDto.cab3StandardPoint
          state.screenDisplayItems.cab.cabIntelligenceStandardCab4 =
            cabInfoDto.cab4StandardPoint
          state.screenDisplayItems.cab.cabComputerAptitudePg =
            cabInfoDto.pgCompetence
          state.screenDisplayItems.cab.cabComputerAptitudeSe =
            cabInfoDto.seCompetence
          state.screenDisplayItems.cab.cabComputerAptitudeCe =
            cabInfoDto.ceCompetence
          state.screenDisplayItems.cab.cabComputerAptitudePm =
            cabInfoDto.pmCompetence
          state.screenDisplayItems.cab.cabAbilityVitality = cabInfoDto.vitality
          state.screenDisplayItems.cab.cabAbilityPerPerson =
            cabInfoDto.perPerson
          state.screenDisplayItems.cab.cabAbilityTeamwork = cabInfoDto.teamwork
          state.screenDisplayItems.cab.cabAbilityCreativeThinking =
            cabInfoDto.creativeThinkingPower
          state.screenDisplayItems.cab.cabAbilityProblemSolving =
            cabInfoDto.problemSolvingPower
          state.screenDisplayItems.cab.cabAbilitySituationalAdaptability =
            cabInfoDto.statusAdaptationPower
          state.screenDisplayItems.cab.cabAbilityPressureResistance =
            cabInfoDto.proofStressToPressure
          state.screenDisplayItems.cab.cabAbilityOrganizingAbility =
            cabInfoDto.organizeAbility
          state.screenDisplayItems.cab.cabAbilityLeadership =
            cabInfoDto.commandPower
          state.screenDisplayItems.cab.cabOpqPersuasiveness =
            cabInfoDto.persuasionPower
          state.screenDisplayItems.cab.cabOpqExtrovert = cabInfoDto.extroversion
          state.screenDisplayItems.cab.cabOpqHumility = cabInfoDto.humility
          state.screenDisplayItems.cab.cabOpqConcreteThings =
            cabInfoDto.concreteThing
          state.screenDisplayItems.cab.cabOpqHuman = cabInfoDto.human
          state.screenDisplayItems.cab.cabOpqConceptuality =
            cabInfoDto.conceptuality
          state.screenDisplayItems.cab.cabOpqElaborate = cabInfoDto.accurate
          state.screenDisplayItems.cab.cabOpqAnxiousMind = cabInfoDto.worrier
          state.screenDisplayItems.cab.cabOpqOptimistic = cabInfoDto.optimistic
          state.screenDisplayItems.cab.cabOpqCompetitive =
            cabInfoDto.competitiveness
          state.screenDisplayItems.cab.cabOpqLeadership =
            cabInfoDto.coachingPower
          state.screenDisplayItems.cab.cabOpqFriendliness =
            cabInfoDto.friendship
          state.screenDisplayItems.cab.cabOpqConsultativeness =
            cabInfoDto.consultation
          state.screenDisplayItems.cab.cabOpqData = cabInfoDto.data
          state.screenDisplayItems.cab.cabOpqOrthodox = cabInfoDto.orthodox
          state.screenDisplayItems.cab.cabOpqCreative = cabInfoDto.creative
          state.screenDisplayItems.cab.cabOpqMeticulous = cabInfoDto.methodical
          state.screenDisplayItems.cab.cabOpqTough = cabInfoDto.tough
          state.screenDisplayItems.cab.cabOpqCritical = cabInfoDto.criticism
          state.screenDisplayItems.cab.cabOpqAscension =
            cabInfoDto.upwardIntention
          state.screenDisplayItems.cab.cabOpqUniqueness = cabInfoDto.uniqueness
          state.screenDisplayItems.cab.cabOpqSocial = cabInfoDto.sociality
          state.screenDisplayItems.cab.cabOpqTrouble = cabInfoDto.toCare
          state.screenDisplayItems.cab.cabOpqAestheticValue =
            cabInfoDto.aestheticValue
          state.screenDisplayItems.cab.cabOpqChangeOriented =
            cabInfoDto.changeIntention
          state.screenDisplayItems.cab.cabOpqPlanning = cabInfoDto.planning
          state.screenDisplayItems.cab.cabOpqMargin = cabInfoDto.allowance
          state.screenDisplayItems.cab.cabOpqSuppression =
            cabInfoDto.suppression
          state.screenDisplayItems.cab.cabOpqAbilityTakeAction =
            cabInfoDto.activityPower
          state.screenDisplayItems.cab.cabOpqDetermination =
            cabInfoDto.decisionPower
          state.screenDisplayItems.cab.cabConsistency = cabInfoDto.consistency
          state.screenDisplayItems.cab.cabUpdateDate = cabInfoDto.updateTime
        }
        state.screenDisplayItems.cab.viewFlag = true
      }

      /*----------------------*
       * メッセージエリアを設定 *
       *----------------------*/

      /** メッセージ情報 */
      const messageInfoList = data.messageInfoList

      let messageInfoDto
      const workMessageInfoDto: MessageData[] = []
      // 値の設定
      for (messageInfoDto of messageInfoList) {
        /** work */
        const workMessage = Object.assign({}, message)
        switch (messageInfoDto.sendAndReceiveDivision) {
          case msgContents.DIVISION_SEND:
            workMessage.sender = messageInfoDto.senderName
            switch (messageInfoDto.messageSendType) {
              case msgContents.SEND_TYPE_E_MAIL:
                workMessage.status = cmnContents.HYPHEN
              break
              default:
                switch (messageInfoDto.status) {
                  case msgContents.STATUS_SEND:
                    if (messageInfoDto.readFlag === msgContents.FLAG_READ) {
                      workMessage.status = msgContents.UNREAD
                    } else {
                      workMessage.status = msgContents.READ
                    }
                    break
                  default:
                    workMessage.status = cmnContents.HYPHEN
                    break
                }
            }
            switch (messageInfoDto.status) {
              case msgContents.STATUS_SEND:
                // phase2 start by 考
                if(messageInfoDto.convertingBatchCompleteFlag == msgContents.STRING_0 &&
                  !(messageInfoDto.messageType == msgContents.MESSAGETYPE_02 || messageInfoDto.messageType == msgContents.MESSAGETYOE_20)){
                    if(messageInfoDto.sendTimeFlag == msgContents.STRING_0){
                      workMessage.classification = msgContents.SENDING
                      workMessage.datetime = messageInfoDto.sendTime
                    }else{
                      workMessage.classification = msgContents.SEND_RESERVATION
                      workMessage.datetime = messageInfoDto.sendPlanTime
                    }
               }else{
                workMessage.classification = msgContents.SEND
                workMessage.datetime = messageInfoDto.sendTime
               }
               // phase2 end by 考
                break
              default:
                workMessage.classification = msgContents.SEND_RESERVATION
                workMessage.datetime = messageInfoDto.sendPlanTime
                break
            }
            // phase2 start by xu
            workMessage.sendingResult = messageInfoDto.sendingResult
            // phase2 end by xu
            break
          case msgContents.DIVISION_RECEIV:
            workMessage.sender = cmnContents.HYPHEN
            workMessage.classification = msgContents.RECEIVING
            if (messageInfoDto.readFlag === msgContents.FLAG_READ) {
              workMessage.status = msgContents.UNREAD
            } else {
              if (messageInfoDto.repliedFlag === msgContents.FLAG_UNREPLIED) {
                workMessage.status = msgContents.UNREPLIED
              } else {
                workMessage.status = msgContents.REPLIED
              }
            }
            workMessage.datetime = messageInfoDto.receivingTime
            break
          default:
            break
        }
        if (messageInfoDto.messageSendType === msgContents.SEND_TYPE_E_MAIL) {
          workMessage.destination = msgContents.E_MAIL
        }
        workMessage.messageId = messageInfoDto.messageId
        workMessage.messageSendId = messageInfoDto.messageSendId
        workMessage.type = messageInfoDto.messageType
        workMessage.subject = messageInfoDto.subject
        if (!isEmpty(messageInfoDto.maxSequence)) {
          workMessage.attachedFile = true
        }
        workMessage.selectionFlow = messageInfoDto.selectionName
        workMessage.sysVersionNumber = messageInfoDto.sysVersionNumber
        // phase2 start
        workMessage.messageRepliesNecessary = messageInfoDto.messageRepliesNecessary
        workMessage.messageRepliesLimit = messageInfoDto.messageRepliesLimit
        workMessage.messageScoutCharacter = messageInfoDto.messageScoutCharacter
        // phase2 end
        workMessageInfoDto.push(workMessage)
      }

      state.screenDisplayItems.message = workMessageInfoDto.map(item => ({
        classification: item.classification,
        destination: item.destination,
        status: item.status,
        messageId: item.messageId,
        messageSendId: item.messageSendId,
        type: item.type,
        entryType: '',
        subject: item.subject,
        attachedFile: item.attachedFile,
        selectionFlow: item.selectionFlow,
        sender: item.sender,
        datetime: item.datetime,
        // phase2 start
        messageRepliesNecessary: item.messageRepliesNecessary,
        messageRepliesLimit: item.messageRepliesLimit,
        messageScoutCharacter: item.messageScoutCharacter,
        sendingResult: item.sendingResult,
        // phase2 end
        sysVersionNumber: item.sysVersionNumber
      }))

      /*----------------------*
       * MCBメッセージエリアを設定 *
       *----------------------*/

      /** MCBメッセージ情報 */
      const mcbMessageInfoList: McbMessageInfo[] = data.mcbMessageInfoList
      //表示用に変換
      if (mcbMessageInfoList.length > 0) {
         state.screenDisplayItems.mcbMessage = [];
         mcbMessageInfoList.map(
          (item) => (
            state.screenDisplayItems.mcbMessage.push({
              mcbMessageBoxClassification: mcbMessageBoxClassification(item.sendAndReceiveDivision,item.status,item.convertingBatchCompleteFlag,item.sendTimeFlag),
              mcbMessageBoxStatus: mcbMessageBoxStatus(item.status,item.readFlag,item.sendingResult,item.messageType),
              mcbMessageBoxKinds : mcbMessageBoxKinds(item.messageType),
              mcbMessageBoxSubject : item.subject,
              mcbMessageBoxAttachment : item.maxAttachmentSequence != null? true:false,
              mcbMessageBoxSender : item.senderName,
              mcbMessageBoxDateTime :mcbMessageBoxDateTime(item.status,item.sendTime,item.sendPlanTime),
              mcbMessageBoxMessageTypeCode : item.messageType,
              mcbMessageBoxMessageId : item.mcbMessageId,
              mcbMessageBoxMessageSendId : item.messageSendId,
              mcbMessageBoxJobSeekerByCompanyId : item.jobSeekerByCompanyId,
              mcbMessageBoxRequestId : item.submissionRequestId,
            })
          )
        )
      } else {
        state.screenDisplayItems.mcbMessage = []
      }
      /*------------------------------*
       * アコーディオン開閉状態を設定 *
       *------------------------------*/
      //登録タグ
      const toggleArticleTag = document.getElementById(
        'toggle-article-tag'
      ) as HTMLInputElement
      if (toggleArticleTag !== null) {
        toggleArticleTag.checked = true
      }
      //イメジス
      const toggleArticleImages = document.getElementById(
        'toggle-article-21'
      ) as HTMLInputElement
      if (toggleArticleImages !== null) {
        toggleArticleImages.checked = true
      }
      //GAB
      const toggleArticleGAB = document.getElementById(
        'toggle-article-22'
      ) as HTMLInputElement
      if (toggleArticleGAB !== null) {
        toggleArticleGAB.checked = true
      }
      //OPQ
      const toggleArticleOPQ = document.getElementById(
        'toggle-article-23'
      ) as HTMLInputElement
      if (toggleArticleOPQ !== null) {
        toggleArticleOPQ.checked = true
      }
      //CAB
      const toggleArticleCAB = document.getElementById(
        'toggle-article-24'
      ) as HTMLInputElement
      if (toggleArticleCAB !== null) {
        toggleArticleCAB.checked = true
      }
      //管理項目
      data.managementItemList.forEach((mngItem, index) => {
        const toggleArticleManagementItem = document.getElementsByName(
          `toggle-article-managementItem-${index}`
        )
        toggleArticleManagementItem.forEach(
          (
            value: HTMLElement,
            key: number,
            parent: NodeListOf<HTMLElement>
          ) => {
            ;(value as HTMLInputElement).checked = mngItem.defaultChecked
          }
        )
      })

      // [phase2] start by zhangxp
      /*------------------------------*
       * マイキャリアボックエリア(2024年以前)を設定 *
       *------------------------------*/
      // MCB連携履歴情報
      const McbLinkHistoryInfoList = data.mcbLinkHistoryInfoList

      if (McbLinkHistoryInfoList.length > 0) {
         state.screenDisplayItems.mcbLinkHistory = [];
         McbLinkHistoryInfoList.map(
          (item, index) => (
            state.screenDisplayItems.mcbLinkHistory.push({
              McbLinkHistoryNo: index + 1,
              McbLinkHistoryMcbCategory: item.mcbCategory,
              McbLinkHistoryLinkDatetime: dateTimeToString(item.linkDatetime),
              McbLinkHistorySearchConditionName: item.searchConditionName,
            })
          )
        )
      } else {
        state.screenDisplayItems.mcbLinkHistory = []
      }
      // [phase2] end by zhangxp
      /*------------------------------*
       * マイキャリアボックエリア(2025年以降)を設定 *
       *------------------------------*/
      // MCB連携履歴情報
      const mcbSubmissionRequestInfoList: McbSubmissionRequestInfo[] = data.mcbSubmissionRequestInfo

      if (mcbSubmissionRequestInfoList.length > 0) {
        state.screenDisplayItems.myCareerBox.mcbstatus = (mcbSubmissionRequestInfoList.filter((i => (i.mcbUseStatus === "1" || i.mcbUseStatus === "2"))).length != 0 )? true :false
         state.screenDisplayItems.myCareerBox.mcbList = [];
         mcbSubmissionRequestInfoList.map(
          (item, index) => (
            state.screenDisplayItems.myCareerBox.mcbList.push({
              mcbNo: index + 1,
              mcbJobSeekerByCompanyId: item.jobSeekerByCompanyId,
              mcbSubmissionRequestId: item.submissionRequestId,
              mcbSubmissionContentName: item.submissionContentName,
              mcbCheckDetails : mcbCheckDetails(item.submissionStatus, item.forceStopFlag),
              mcbForceStopFlag : item.forceStopFlag,
              mcbResubmit : item.resubmitReceptionFlag,
              mcbSubRequestStatus : mcbSubRequestStatus(item.readFlag,item.sendingResult),
              mcbMemberId : item.mcbMemberId,
              mcbUseStatus : item.mcbUseStatus,
              mcbSubmissionStatus : mcbSubmissionStatus(item.submissionStatus),
              mcbSubmissionStatusCode: item.submissionStatus,
              mcbSubmission : {
                submissionEntrySheetFlag : item.submissionEntrySheetFlag,
                submissionResumeFlag : item.submissionResumeFlag ,
                submissionResearchSummaryFlag : item.submissionResearchSummaryFlag, 
                submissionOriginalQuestionFlag : item.submissionOriginalQuestionFlag, 
                submissionFileSubmissionFlag : item.submissionFileSubmissionFlag,
              },
              mcbSubmissionRequestDay : item.sendTime,
              mcbSubmissionDeadlineDay : mcbSubmissionDeadlineDay(item.msrSubmissionDeadlineKind,item.msrSubmissionDeadlineDay,item.msrSubmissionDeadlineHour,item.sendTime),
              mcbSubmissionDeadlineChangeDay : mcbSubmissionDeadlineChangeDay(item.deadlineChangeFlag,item.mssSubmissionDeadlineKind,item.mssSubmissionDeadlineDay,item.mssSubmissionDeadlineHour,item.sendPlanTime),
              mcbSubmissionRequestStatus : mcbSubmissionRequestStatus(item),
              mcbSubmissionFilingDay : item.submissionDatetime,
              mcbSubmissionRecount   : item.submissionTimes-1,
              mcbMessageSendId : item.messageSendId,
            })
          )
        )
      } else {
        state.screenDisplayItems.myCareerBox.mcbstatus = false
        state.screenDisplayItems.myCareerBox.mcbList = []
      }
      // [phase2] start by jxp
      /*------------------------------*
       * スカウト履歴エリアを設定 *
       *------------------------------*/
      // スカウト履歴情報
      const scoutInfoList = data.scoutInfoList
      if (scoutInfoList.length > 0) {
         state.screenDisplayItems.scout = [];
         scoutInfoList.map(
          (item, index) => (
            state.screenDisplayItems.scout.push({
              scoutNo: index + 1,
              scoutSendTime: item.scoutSendTime,
              scoutMedia: item.scoutMedia,
              scoutCharacter: item.scoutCharacter,
              scoutMessageStatus: item.scoutMessageStatus,
            })
          )
        )
      } else {
        state.screenDisplayItems.scout = []
      }
      // [phase2] end by jxp
      return state
    },
    doSelectionFlowAdd(
      state,
      action: PayloadAction<MCAXS040ScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowAdd)')
      return state
    },
    doSelectionFlowChange(
      state,
      action: PayloadAction<MCAXS040ScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowChange)')
      return state
    },
    doSelectionFlowRouteAdd(
      state,
      action: PayloadAction<MCAXS040RouteScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowRouteAdd)')
      return state
    },
    doSelectionFlowRouteChange(
      state,
      action: PayloadAction<MCAXS040RouteScreenTransitionRequest>
    ) {
      console.log('reducer処理(doSelectionFlowRouteChange)')
      return state
    },
    doSelectionFlowExclude(
      state,
      action: PayloadAction<MCAXS040UpdateExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowExclude)')
      return state
    },
    doSelectionFlowUnExclude(
      state,
      action: PayloadAction<MCAXS040UpdateUnExcludeRequest>
    ) {
      console.log('reducer処理(doSelectionFlowUnExclude)')
      return state
    },
    doWebTestResult(state, action: PayloadAction<string>) {
      console.log('reducer処理(doWebTestResult)')
      return state
    },
    setOpenManageListDialog(state, action: PayloadAction<boolean>) {
      state.openManageListDialog = action.payload
      return state
    },
    setCurrentApplicantsListInfo(
      state,
      action: PayloadAction<CurrentApplicantsListInfo>
    ) {
      state.currentApplicantsListInfo = action.payload
      console.log('reducer処理(setCurrentApplicantsListInfo)')
      return state
    },
    setApplicantDetailsRequest(
      state,
      action: PayloadAction<ApplicantDetailsRequest>
    ) {
      state.applicantDetailsRequest = action.payload
      console.log('reducer処理(setApplicantDetailsRequest)')
      return state
    },
    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload
      return state
    },
    setOutResult(state) {
      return state
    },
    updateStatus(state, action: PayloadAction<number>) {
      state.screenDisplayItems.message[action.payload].status = '未返信'
      return state
    },
    mcbSubmissionDownload(
      state,
      action: PayloadAction<{
          mcbInfo: McbInfo
          jobSeekerId: number
      }>
    ) {
      return state
    },
    pushMcbCheckDetails(state, action: PayloadAction<MCAXS040McbCheckDetailsRequest>) {
      return state
    },
    mcbSubmissionMesseageMake(
      state,
      action: PayloadAction<McbInfo>
    ) {
      return state
    },
    mcbSubmissionRequestChange(
      state,
      action: PayloadAction<{
        mcbInfo: McbInfo
        setRequestChangeTargetMcbInfo: React.Dispatch<React.SetStateAction<McbInfo | undefined>>
        handleModalOpen: (modalName: string, open?: boolean) => void
      }>
    ) {
      return state
    },
  },
})

export const {
  init,
  setInit,
  setInitDisplay,
  setScreenId,
  setCode,
  setDoInit,
  setScreenDisplayItems,
  doSelectionFlowAdd,
  doSelectionFlowChange,
  doSelectionFlowRouteAdd,
  doSelectionFlowRouteChange,
  doSelectionFlowExclude,
  doSelectionFlowUnExclude,
  doWebTestResult,
  setOpenManageListDialog,
  setCurrentApplicantsListInfo,
  setApplicantDetailsRequest,
  setShow,
  setOutResult,
  updateStatus,
  mcbSubmissionDownload,
  pushMcbCheckDetails,
  mcbSubmissionMesseageMake,
  mcbSubmissionRequestChange,
} = applicantDetailForEmplymentSlice.actions
export default applicantDetailForEmplymentSlice.reducer

const mcbCheckDetails = (submissionStatus: string, forceStopFlag: string) => {
  if(submissionStatus === mcbContents.SUBMITTED && forceStopFlag === mcbContents.NOT_FORCESTOP)
    return true
  else return false
}    
const mcbSubRequestStatus = (readFlag: string,sendingResult :string) => {
  if(readFlag == null) {
    if(sendingResult === mcbContents.SUCCESS)
      return <><span>未読</span></>
    else if(sendingResult == mcbContents.FAILURE) 
    {  
      return <><span>未読</span> <span style={{color:'red'}}>e-mail送信失敗</span></>
    }
  } 
  else if (readFlag != null) {
    if(sendingResult === mcbContents.SUCCESS)
      return <><span>既読</span></>     
    else if(sendingResult == mcbContents.FAILURE) 
    {  
      return <><span>既読</span> <span style={{color:'red'}}>e-mail送信失敗</span></>
    }
  }
}
const mcbSubmissionStatus = (submissionStatus: string) => {
  switch(submissionStatus){
    case mcbContents.RECEIVED_ERROR:
      return "受領エラー"
    default :
  }
}   
const mcbSubmissionDeadlineDay = (msrSubmissionDeadlineKind: string, msrSubmissionDeadlineDay: string, msrSubmissionDeadlineHour: number, sendTime: string) => {
  switch (msrSubmissionDeadlineKind){
    case "2": 
      return "随時受付終了"
    case "0": 
      return createStringDateFromDayAndHour(msrSubmissionDeadlineDay,msrSubmissionDeadlineHour)
    case "1":
      return createStringDateOfRequestedDate(msrSubmissionDeadlineDay,msrSubmissionDeadlineHour,sendTime)
    default:
  }
}
const mcbSubmissionDeadlineChangeDay = (deadlineChangeFlag: string,mssSubmissionDeadlineKind: string, mssSubmissionDeadlineDay: string, mssSubmissionDeadlineHour: number, sendPlanTime: string) => {
  if(deadlineChangeFlag === "1"){
    switch (mssSubmissionDeadlineKind){
      case "2": 
        return "変更後締切日時 " + "随時受付終了"
      case "0": 
        return "変更後締切日時 " + createStringDateFromDayAndHour(mssSubmissionDeadlineDay,mssSubmissionDeadlineHour)
      case "1":
        return "変更後締切日時 " + createStringDateOfRequestedDate(mssSubmissionDeadlineDay, mssSubmissionDeadlineHour, sendPlanTime)
      default :
    }
  }
}
const mcbSubmissionRequestStatus = (item: McbSubmissionRequestInfo) => {
  if (item.submissionStatus === mcbContents.NOT_REQUEST) {
    return '送信予約'
  } else if (item.forceStopFlag === mcbContents.FORCESTOP) {
    return '強制停止'
  } else {
    let stringDatetime: string
    let datetime: Date | null
    switch (item.mssSubmissionDeadlineKind) {
      case mcbContents.SUBMISSION_DEADLINE:
        stringDatetime = createStringDateFromDayAndHour(
          item.mssSubmissionDeadlineDay,
          item.mssSubmissionDeadlineHour
        )
        datetime = stringToDateTime(stringDatetime)
        if (datetime && datetime < now()) {
          return '受付終了'
        }
        break
      case mcbContents.FROM_DATE_OF_REQUEST:
        stringDatetime = createStringDate(
          item.mssSubmissionDeadlineDay,
          0,
          item.sendPlanTime
        )
        datetime = stringToDateTime(stringDatetime)
        if (datetime && datetime < now()) {
          return '受付終了'
        }
        break
      case mcbContents.RECEPTION_ENDS_AT_ANY_TIME:
        if (item.submissionStatus === mcbContents.SUBMITTED) {
          return '受付終了'
        } else {
          stringDatetime = createStringDateFromDayAndHour(
            item.mssSubmissionDeadlineDay,
            item.mssSubmissionDeadlineHour
          )
          datetime = stringToDateTime(stringDatetime)
          if (datetime && datetime < now()) {
            return '受付終了'
          } else {
            return '受付中'
          }
        }
      default:
        break
    }
    if (
      item.sendAndReceiveDivision === '2' &&
      item.messageType !== '54' &&
      item.status === '30' &&
      item.convertingBatchCompleteFlag === '0'
    ) {
      return '送信中'
    } else {
      return '受付中'
    }
  }
}

const mcbMessageBoxClassification = (sendAndReceiveDivision: string, status: string, convertingBatchCompleteFlag: string, sendTimeFlag: string) => {
  if(sendAndReceiveDivision === "2" && status === "20" && convertingBatchCompleteFlag == "0" && sendTimeFlag === "1"){
    return "送信予約"
  }
  if(sendAndReceiveDivision === "2" && status === "30" && convertingBatchCompleteFlag == "0" && sendTimeFlag === "0"){
    return "送信中"  
  }
  return "送信"
}

const mcbMessageBoxStatus = (status: string, readFlag: string, sendingResult :string, messageType: string) => {
  if(status === "20" || messageType == mcbMsgContents.THANKS_MAIL){
    return <>{cmnContents.HYPHEN}</>
  }
  else if (readFlag == null) {
    if(sendingResult === mcbContents.SUCCESS)
      return <><span>未読</span></>
    else if(sendingResult == mcbContents.FAILURE) 
    {  
      return <><span>未読</span> <span style={{color:'red'}}>e-mail送信失敗</span></>
    }
  } 
  else if (readFlag != null) {
    if(sendingResult === mcbContents.SUCCESS)
      return <><span>既読</span></>     
    else if(sendingResult == mcbContents.FAILURE) 
    {  
      return <><span>既読</span> <span style={{color:'red'}}>e-mail送信失敗</span></>
    }
  }
}
const mcbMessageBoxKinds = (messageType: string) => {
  switch(messageType){
    case mcbMsgContents.SUBMISSION_REQUEST:
      return "提出リクエスト"
    case mcbMsgContents.DEADLINE_CHANGE:
      return "締切変更"
    case mcbMsgContents.FORCE_STOP:
      return "強制停止"
    case mcbMsgContents.THANKS_MAIL:
      return "サンクス"
    case mcbMsgContents.MCB_MESSAGE:
      return "My CareerBoxメッセージ"
  }
}

const mcbMessageBoxDateTime = (status: string, sendTime: string, sendPlanTime: string) =>{
  switch(status){
    case mcbMsgContents.STATUS_SEND_RESERVATION:
      return sendPlanTime
    case mcbMsgContents.STATUS_SEND:
      return sendTime
  }
}

const createStringDateFromDayAndHour = (Day: string, Hour: number)=> {
  if(Day == null ||Hour == null)return ""
  const year = parseInt(Day.substring(0, 4));               
  const month = parseInt(Day.substring(4, 6));              
  const day = parseInt(Day.substring(6, 8));
  const hour = Hour;
  const min = 0;  
  return moment(new Date(year, month - 1, day, hour, min)).format('YYYY/MM/DD HH:mm')
}
const createStringDate = (DayAfter: string, Hour: number, Time: string)=>{
  if(DayAfter == null ||Hour == null || Time == null)return ""
  const DayAfterNum: number | null = stringToNumber(DayAfter)
  if(DayAfterNum != null){
    if(dateTimeToString(getNdaysLater(Time,DayAfterNum)) != null){
      const DeadlineDayAfter = dateTimeToString(getNdaysLater(Time,DayAfterNum))
      if( DeadlineDayAfter != null)
        return moment(getNhoursLater(DeadlineDayAfter,Hour)).format('YYYY/MM/DD HH:mm')
    }
  }
  return ""
}
const createStringDateOfRequestedDate = (DayAfter: string, Hour: number, Time: string)=>{
  if(DayAfter == null ||Hour == null || Time == null)return ""
  const DayAfterNum: number | null = stringToNumber(DayAfter)
  if(DayAfterNum != null){
    if(dateTimeToString(getNdaysLater(Time,DayAfterNum)) != null){
      const DeadlineDayAfter = dateTimeToString(getNdaysLater(Time,DayAfterNum))
      if( DeadlineDayAfter != null){
        const Day = DeadlineDayAfter.substring(0, 11);  
        const hour = ('0'+Hour).slice(-2);             
        return Day + hour + ":00"
      }
    }
  }
  return ""
}