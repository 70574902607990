import React from 'react'
import { Status, PropsInterface, PropsSend } from './interface'

export default function ProgressStatus(props: PropsInterface) {
  // 左進捗の選考ステップ
  const leftProgress: Status[] = props.leftProgress
  // 旗の位置
  const [flag, setFlag] = React.useState(props.flag)
  // 左進捗の選択位置
  const [leftSelect, setLeftSelect] = React.useState(props.leftSelect)
  // 右進捗の選択位置
  const [rightSelect, setRightSelect] = React.useState(props.rightSelect)
  // 左進捗のグレー表示の標識
  const [leftGrey, setLeftGrey] = React.useState(props.leftGrey)
  // 右進捗のグレー表示の標識
  const [rightGrey, setRightGrey] = React.useState(props.rightGrey)
  // 対象の標識
  const [target, setTarget] = React.useState(props.target)
  // リセットの場合、状態がリセット
  React.useEffect(() => {
    if (
      flag != props.flag ||
      leftSelect != props.leftSelect ||
      rightSelect != props.rightSelect ||
      leftGrey != props.leftGrey ||
      rightGrey != props.rightGrey ||
      target != props.target
    ) {
      setFlag(props.flag)
      setLeftSelect(props.leftSelect)
      setRightSelect(props.rightSelect)
      setLeftGrey(props.leftGrey)
      setRightGrey(props.rightGrey)
      setTarget(props.target)
    }
  }, [
    props.flag,
    props.leftSelect,
    props.rightSelect,
    props.leftGrey,
    props.rightGrey,
    props.target,
  ])

  // 左進捗のクリックイベント
  const leftClick = (event: any) => {
    if (!props.clickFlag) {
      return
    }
    // クリックした選考ステップに旗を移動する
    setFlag(event.target.id)
    // 左進捗の選択位置にクリックした選考ステップを設定
    setLeftSelect(event.target.id)
    // 右進捗の選択位置をクリア
    setRightSelect(0)
    // 左進捗がグレー表示をしません
    setLeftGrey(false)
    const propsNew: PropsSend = {
      flag: event.target.id, // 旗の位置
      leftSelect: event.target.id, // 左進捗の選択位置
      rightSelect: 0, // 右進捗の選択位置
      leftGrey: false, // 左進捗のグレー表示の標識
      rightGrey: rightGrey, // 右進捗のグレー表示の標識
      eventTarget: event.target, // クリック対象
    }
    // 選考ステップが変更後実行したメソッド
    props.handlerClick(propsNew)
  }
  // 左進捗のスタイル名の取得イベント
  const getNewLeftclassName = (statusId: number) => {
    let leftId = leftProgress[0].id
    let rightId = leftProgress.length + 1
    let className = 'modal-step__content '


    if (statusId > leftId && statusId < leftSelect) {
      if (!leftGrey) {
        if (flag < rightId) {
          className = className + 'modal-step__content__Bule '
        }
      }
      else {
        className = className + 'modal-step__content_grey '
      }
    } else if (statusId == leftId && statusId < leftSelect) {
      if (!leftGrey) {
        if (flag < rightId) {
          className = className + 'modal-step__content__Bule '
        }
      }
      else {
        className = className + 'modal-step__content_grey '
      }
    }

    return className
  }

  const getFormRadioSvgClassNames = (statusId: number, svgStatus: string) => {
    let className = ''

    if (svgStatus === 'on') {
      className = 'modal-step__radio form__radio_svg_on'
    }
    if (svgStatus === 'off') {
      className = 'modal-step__radio form__radio_svg_off'
    }

    if (statusId < leftSelect) {
      if (svgStatus === 'on') {
        className = 'modal-step__radio form__radio_svg_on__bule'
      }

      if (svgStatus === 'off') {
        className = 'modal-step__radio form__radio_svg_off__bule'
      }
    }

    return className
  }

  const getFormRadioSvgColorClassNames = () => {
    let rightId = leftProgress.length + 1
    let className = '#c3c3c3'
    if (!rightGrey) {
      if (flag < rightId) {
        className = '#0b5fcc'
      }
    }
    return className
  }

  return (
    <>
      <ul className='modal-step__list' tabIndex={-1}>
        {leftProgress.map(status => {
          let labelProps = null

          labelProps = (
            <label
              htmlFor={status.id.toString()}
              tabIndex={flag == status.id ? 0 : -1}
              className={getNewLeftclassName(status.id)}>
              <input
                name='progress'
                id={status.id.toString()}
                value={status.settingId}
                type='radio'
                disabled={!target}
                checked={status.id == leftSelect ? true : false}
                className='form__radio'
                onClick={leftClick}
              />

              {/* bule */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 48 48'
                className={getFormRadioSvgClassNames(status.id, 'on')}>
                <g transform='translate(-12159 -1434)'>
                  <circle
                    cx='24'
                    cy='24'
                    r='24'
                    transform='translate(12159 1434)'
                    fill='#fff'
                  />
                  <circle
                    cx='15.5'
                    cy='15.5'
                    r='15.5'
                    transform='translate(12167 1442)'
                    fill={getFormRadioSvgColorClassNames()}
                  />
                </g>
              </svg>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 48 48'
                className={getFormRadioSvgClassNames(status.id, 'off')}>
                <g transform='translate(-12159 -1434)'>
                  <circle
                    cx='24'
                    cy='24'
                    r='24'
                    transform='translate(12159 1434)'
                    fill='#fff'
                  />
                  <circle
                    cx='15.5'
                    cy='15.5'
                    r='15.5'
                    transform='translate(12167 1442)'
                    fill='#565a61'
                  />
                </g>
              </svg>
              {status.id == flag && (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='17'
                  height='28'
                  viewBox='0 0 34.425 56.38'
                  className='modal-step__icon_current'
                  fill='#f35116'>
                  <use xlinkHref='#entry-details-step__icon_flag'></use>
                </svg>
              )}
              <span className='modal-step__title'>{status.name}</span>
            </label>
          )

          return (
            <li className='modal-step__item'>
              <React.Fragment>{labelProps}</React.Fragment>
            </li>
          )
        })}
      </ul>

      {/* <!-- 選考ステップ --> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='17'
        height='28'
        viewBox='0 0 34.425 56.38'
        fill='none'
        display='none'>
        <defs>
          <g id='entry-details-step__icon_flag' transform='translate(0 0)'>
            <g transform='translate(0 0)'>
              <path
                d='M.7.5C.045.957-.085,7.02.045,28.733.24,52.793.37,56.38,1.219,56.38s1.042-1.891,1.369-11.41c.2-6.261.39-11.477.457-11.542s2.086-1.109,4.562-2.348c2.413-1.237,7.043-3.65,10.238-5.347,3.26-1.694,8.02-4.237,10.693-5.672,7.238-3.717,7.173-3.587,3.2-6.2a66.745,66.745,0,0,0-7.368-4.042c-2.151-1.042-7.043-3.39-10.89-5.217A54.945,54.945,0,0,0,5.522,1.282,1.838,1.838,0,0,1,4.152.63C3.7-.152,1.871-.217.7.5'
                transform='translate(0 0)'
              />
            </g>
          </g>
        </defs>
        <use xlinkHref='#entry-details-step__icon_flag'></use>
      </svg>
      {/* <!-- /選考ステップ --> */}
    </>
  )
}
