import { colors } from '@material-ui/core'

export default {
  root: {
    // borderRadius: 3,
    // overflow: 'hidden',
    borderRadius: 'inherit',
    overflow: 'inherit',
  },
  colorPrimary: {
    // backgroundColor: colors.blueGrey[50],
    backgroundColor: 'inherit',
  },
}
