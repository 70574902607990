import React from 'react'
import { getYear, getMonth } from 'date-fns'
import { ja } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { magiContants, formatTemplate } from 'utils/contants'
import { formatDay } from 'utils/misc'
import { CandidateDay } from './Calendar'

const timeIntervals = magiContants.ZS080_TIMEINTERVALS
const dayRange = 90
const startDate = formatDay(0)
const datePickerEndDate = formatDay(dayRange)
const startTime = moment()
  .set({ hour: 9, minute: 0 })
  .format(formatTemplate.dateWithHourMinuteSpace)
const endTime = moment()
  .set({ hour: 22, minute: 55 })
  .format(formatTemplate.dateWithHourMinuteSpace)

export interface CommonHandler {
  handleDateChange: (
    itemIndex: number,
    ref: React.RefObject<DatePicker>
  ) => (date: Date | null) => void
  handleRawDateChange: (
    itemIndex: number,
    ref: React.RefObject<DatePicker>
  ) => (e: any) => void
  handleKeyDown: (ref: React.RefObject<DatePicker>) => (e: any) => void
}

interface Props extends CommonHandler {
  item: CandidateDay
  index: number
}

const CustomDatePicker = ({
  item,
  index,
  handleDateChange,
  handleRawDateChange,
  handleKeyDown,
}: Props) => {
  return (
    <DatePicker
      showTimeSelect
      timeFormat={formatTemplate.time}
      timeIntervals={timeIntervals}
      dateFormat={formatTemplate.datePicker}
      locale={ja}
      placeholderText=''
      minDate={new Date(startDate)}
      maxDate={new Date(datePickerEndDate)}
      minTime={new Date(startTime)}
      maxTime={new Date(endTime)}
      selected={item.interviewDate}
      onChange={handleDateChange(index, item.datePickerRef)}
      ref={item.datePickerRef}
      timeCaption='時間'
      onChangeRaw={handleRawDateChange(index, item.datePickerRef)}
      onKeyDown={handleKeyDown(item.datePickerRef)}
      className='form__input_286w'
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div>
          <button
            type='button'
            className='react-datepicker__navigation react-datepicker__navigation--previous'
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}></button>
          <div className='react-datepicker__current-month'>
            {`${getYear(date)}年${getMonth(date) + 1}月`}
          </div>
          <button
            type='button'
            className='react-datepicker__navigation react-datepicker__navigation--next'
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}></button>
        </div>
      )}
    />
  )
}

export default React.memo(CustomDatePicker)
