import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  bottomFix: {
    fontSize: '1rem',
    backgroundColor: '#133e80',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    position: 'fixed',
    width: '100%',
    color: '#fff',
  }
}))

interface Props {
  bottomFixed?: boolean
}

const Footer = ({ bottomFixed }: Props) => {
  const classes = useStyles()

  return (
    <footer className={bottomFixed ? classes.bottomFix : 'footer'}>
      Copyright (C) Mynavi Corporation
    </footer>
  )
}

export default Footer
