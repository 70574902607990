import {
  call,
  put,
  all,
  takeEvery,
  select,
  takeLeading,
} from 'redux-saga/effects'
import {
  getInterviewScheduleList,
  setInterviewScheduleList,
  setPlaceSearchList,
  setSelectionFlowSearchList,
  setProgressList,
  setSelectionFlowFlag,
  searchInterviewScheduleList,
  checkVersionNumber,
  setMessageFlagResult,
  createInterviewWebUrl,
  cancelInterviewWebUrl,
  setIssueOverDialogOpen,
  setRefreshFlagOfInterview,
} from 'reducers/interviewScheduleReducer'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import {
  initRequest,
  searchRequest,
  checkVersionNumberRequest,
  createInterviewWebUrlRequest,
  cancelInterviewWebUrlRequest,
} from 'apis/MCBAS020Api'
import { MCBAS020SearchConditionRequest } from 'types/MCBAS020/MCBAS020SearchConditionRequest'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { routeList } from 'routes/routes'
import { getMessage } from 'common/messageUtil'
import history from 'utils/history'
import { magiContants } from 'utils/contants'
import { openMessageInputDialog } from 'reducers/messageInputDialogReducer'

// MCBAS020 画面初期表示(データ取得)
export function* getInterviewScheduleListSaga(
  action: ReturnType<typeof getInterviewScheduleList>
) {
  try {
    yield put(setInterviewScheduleList([]))
    yield put(setMessageFlagResult(-1))
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(
      getRecruitmentManagementDivision
    )

    let request: MCBAS020SearchConditionRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(initRequest, request)

    // アラートエリア
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {
      lastLogoutTime: getGlobalMenu.lastLogoutTime,
      previousLoginTime: getGlobalMenu.previousLoginTime,
      recruitmentManagementDivision:
        getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得_アラートエリア
    const dataArea = yield call(initAreaContactPassRequest, actionArea)

    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
    // 面接場所
    yield put(setPlaceSearchList(data.displayPlaceSelectionList))
    // 選考フロー
    yield put(setSelectionFlowFlag(data.selectionFlowFlag))
    yield put(setSelectionFlowSearchList(data.displaySelectionList))
    // 選考ステップ
    yield put(setProgressList(data.displayProgressList))
    // 一覧データ
    yield put(setInterviewScheduleList(data.selectInterviewList))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBAS020 検索
export function* searchInterviewScheduleListSaga(
  action: ReturnType<typeof searchInterviewScheduleList>
) {
  try {
    yield put(setInterviewScheduleList([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(searchRequest, action.payload)
    // 一覧データ
    yield put(setInterviewScheduleList(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBAS020 面接調整状態整合性チェック
export function* checkVersionNumberSaga(
  action: ReturnType<typeof checkVersionNumber>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // 面接調整状態整合性チェック
    yield call(checkVersionNumberRequest, action.payload)

    // メッセージ入力へ遷移する
    const requestParam = {
      interviewScheduleAdjustmentId:
        action.payload.interviewScheduleAdjustmentId,
      interviewScheduleId: action.payload.interviewScheduleId,
      action: action.payload.action,
      sysVersionNumber: action.payload.sysVersionNumber,
      // WEB面接対応 start
      interviewWebEnableFlag: action.payload.interviewWebEnableFlag,
      // WEB面接対応 end
    }
    localStorage.setItem('messageInputRequest', JSON.stringify(requestParam))
    yield localStorage.setItem('globalMenuInfo', JSON.stringify(globalMenuInfo))
    // メッセージ入力画面オープン
    yield put(openMessageInputDialog())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// WEB面接対応 start
// MCBAS020 WEB面接URLを発行する。
export function* createInterviewWebUrlSaga(
  action: ReturnType<typeof createInterviewWebUrl>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // WEB面接URLを発行する。
    yield call(createInterviewWebUrlRequest, action.payload)
    // 「WEB面接URL発行完了」ダイアログを表示する。
    yield put(setIssueOverDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBAS020 WEB面接URLをキャンセルする。
export function* cancelInterviewWebUrlSaga(
  action: ReturnType<typeof cancelInterviewWebUrl>
) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(
    getGlobalMenuData
  )
  try {
    // WEB面接URLをキャンセルする。
    yield call(cancelInterviewWebUrlRequest, action.payload)
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBAS020_018)))
    if (window.location.href.indexOf(routeList.scheduledList) !== -1) {
      history.replace(routeList.scheduledList)
      yield put(setRefreshFlagOfInterview())
    } else {
      history.push(routeList.scheduledList)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// WEB面接対応 end

export default function* interviewScheduleSaga() {
  yield all([
    takeEvery(getInterviewScheduleList, getInterviewScheduleListSaga),
    takeEvery(searchInterviewScheduleList, searchInterviewScheduleListSaga),
    takeEvery(checkVersionNumber, checkVersionNumberSaga),
    // WEB面接対応 start
    takeLeading(createInterviewWebUrl, createInterviewWebUrlSaga),
    takeLeading(cancelInterviewWebUrl, cancelInterviewWebUrlSaga),
    // WEB面接対応 end
  ])
}
