export default {
  gutterBottom: {
    // marginBottom: 4,
    marginBottom: 'inherit',
  },
  body1: {
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },
}
