import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { MySearch } from 'pages/MCAZS080/viewConig'
import { Form } from 'formik'
import { isEmpty } from 'common/generalUtil'

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mySearchActions: {
    backgroundColor: '#f5f5f5',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 196,
  },
  dialogTitle: {
    backgroundColor: '#133e80',
    height: 53,
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 700,
    fontSize: 18,
    cursor: 'auto!important'
  },
  comment: {
    fontSize: 14,
    padding: theme.spacing(0, 10, 0, 10),
    overflow: 'auto',
  },
  searchCriteriaName: {
    fontWeight: 600,
  },
  searchTitle: {
      marginTop:'10px',
  },
  searchName: {
      marginTop:'10px',
  },
  searchCriteria: {
      marginTop:'10px',
      backgroundColor:'white',
  },
  mySearch: {
    flexBasis: '100% !important',
    marginTop: '5px',
    marginBottom: '-3px',
  },
}))

interface Props {
  onClose: () => void
  searchCriteriaName: string
  searchCriteria: string
  mySearchCondition: string|null
  mySearch: MySearch[]|null
  detaildDialogOpen: boolean
}

const DetailDialog = ({ onClose, searchCriteriaName, searchCriteria, mySearchCondition, mySearch, detaildDialogOpen }: Props) => {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    // <Dialog maxWidth='md' fullWidth open={detaildDialogOpen} scroll='body'>
    <Dialog maxWidth='md' fullWidth open={detaildDialogOpen} className={'mainDialog'}>
      {mySearchCondition && mySearch ? 
      <>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>MY検索×MY検索条件</label>
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#f5f5f5'}}>
          <div>
            <div>MY検索間の検索形式：&nbsp;<span className={classes.searchCriteriaName}>{mySearchCondition == '0' ? '全てと一致' : 'いずれかに一致'}</span></div>
            <div className={classes.searchTitle}>
                {mySearch != null ? mySearch.map((i,index) => (
                    <div className={classes.searchName}>
                        <div>選択しているMY検索&nbsp;&nbsp;<span className={classes.searchCriteriaName}>{i.mySearchName}</span></div>
                        <div className={classes.searchCriteria} dangerouslySetInnerHTML={{ __html: i.mySearchCriteria }} />
                    </div>
                ))
                : ''}
            </div>
            <div>
                {searchCriteria != null && searchCriteria != '' && searchCriteria !== '指定条件なし'?
                    <div className={classes.searchName}>
                        <div>{isEmpty(searchCriteriaName) ? '選択している条件' : '選択しているMY検索'}<span className={classes.searchCriteriaName}>{searchCriteriaName}</span></div>
                        <div className={classes.searchCriteria} dangerouslySetInnerHTML={{ __html: searchCriteria }} />
                    </div>
                : ''}
            </div>
        </div>
        </DialogContent>
      </> :
      <>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{isEmpty(searchCriteriaName) ? '' : 'MY検索　' + searchCriteriaName}</label>
        </DialogTitle>
        <DialogContent>
          <Form className={classes.form}>
            <div className={classes.comment}>
              <div dangerouslySetInnerHTML={{ __html: searchCriteria }} />
            </div>
          </Form>
        </DialogContent>
      </> 
      }
      <DialogActions className={mySearchCondition && mySearch ? classes.mySearchActions : classes.actions}>
        <Button
          onClick={handleClose}
          variant='contained'
          className={classes.cancelButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DetailDialog
