import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { TextField as MuiTextField } from '@material-ui/core'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: '40px',
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
    '&:disabled': {
      borderColor: '#F2F2F2',
      backgroundColor: '#F2F2F2',
    },
  },
  textCounter: {
    float: 'right',
    minWidth: 120,
    textAlign: 'right',
  },
  inputWithCounter: {
    marginTop: '10px',
    width: '267px',
  },
}))

interface Props extends FieldProps {
  maxAmount?: number
  onChange?: (value: string) => void
  setError: ({ isError: boolean, message: string }: any) => void
  error: { isError: boolean; message: string }
}

const InputWithCounter = ({
  field,
  form,
  maxAmount,
  onChange,
  setError,
  error,
  ...others
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name, value } = field
  const searchInsertNameYup = yup.object().shape({
    searchInsertName: yup.string(),
  })
  const handleChange = (event: any) => {
    searchInsertNameYup
      .validate({ searchInsertName: event.target.value })
      .then()
      .catch(err => {
        setError({ isError: true, message: err.errors })
      })
    if (400 >= event.target.value.length) {
      if (onChange) {
        onChange(event.target.value)
      }
      form.setFieldValue(name, event.target.value)
    }
    setError({ isError: false, message: '' })
  }

  return (
    <React.Fragment>
      <Grid >
        <Grid item xs={12}>
          <MuiTextField
            name={name}
            value={value}
            onChange={handleChange}
            type='text'
            margin='dense'
            variant='outlined'
            fullWidth
            inputProps={{ spellCheck: false }}
            InputProps={{
              classes: { input: classes.input },
            }}
            disabled={
              form.values.selectRadio === '0' || form.values.selectRadio === '2'
                ? true
                : false
            }
            error={Boolean(errors[name] && touched[name]) || error.isError}
            helperText={
              error.isError && form.values.selectRadio === '1'
                ? error.message
                : errors[name] && touched[name]
                  ? errors[name]
                  : null
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.textCounter}>
          <Typography gutterBottom variant='h6'>
            {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InputWithCounter
