import { colors } from '@material-ui/core'

import palette from '../palette'

export default {
  root: {
    // color: palette.icon,
    color: 'inherit',
    '&:hover': {
      // backgroundColor: colors.grey[100],
      backgroundColor: 'inherit',
    },
    '&$selected': {
      // backgroundColor: colors.grey[50],
      // color: palette.primary.main,
      backgroundColor: 'inherit',
      color: 'inherit',
      '&:hover': {
        // backgroundColor: colors.grey[100],
        backgroundColor: 'inherit',
      },
    },
    '&:first-child': {
      // borderTopLeftRadius: 4,
      // borderBottomLeftRadius: 4,
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    '&:last-child': {
      // borderTopRightRadius: 4,
      // borderBottomRightRadius: 4,
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
}
