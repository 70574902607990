import React, { useEffect } from 'react'
import { useRouter } from 'providers/routerProvider'

interface Props {
  title: string
  noPadding?: boolean
}

const Page: React.FC<Props> = ({ title, noPadding = false, children }) => {
  const { location } = useRouter()

  useEffect(() => {
    document.title = `${title} - MIWS`
  }, [location.pathname])

  return <>{children}</>
}

export default Page
