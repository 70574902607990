import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, Typography } from '@material-ui/core'
import { magiContants, validationMessageSet } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import * as yup from 'yup'
const useStyles = makeStyles(theme => ({
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
    width: '250px',
  },
  textarea: {
    resize: 'none',
  },
  helperText: {
    fontSize: '9px',
    lineHeight: '1em',
    color: '#e53935',
    margin: '4px -12px',

  },
  error: {
    borderColor: '#e53935!important',
    '&:focus': {
      borderWidth: 2,
      padding: '0px 7px',
    },
  },
  cusVerticalAlign: {
    verticalAlign: 'initial',
  }

}))
const useErrorStyle = makeStyles({
  helperText: {
    fontSize: '11px',
    minHeight: 'initial !important',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    whiteSpace: 'pre-wrap',
  },
})

interface Props extends FieldProps {
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
  errorsItemIndex: number
  relatedName?: string
  onChange?: (value: string) => void
}
interface Errors {
  isError: boolean
  errorMsg: string
}
export default ({
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  multiline,
  type,
  errorsItemIndex,
  relatedName = '',
  onChange,
}: Props) => {
  interface ManagementItemError {
    managementItemTagText: string
    managementItemTagFrom: string
    managementItemFlagTo: string
    errorsMessage: string
    manageIndex: number
    manageName: string
  }
  const classes = useStyles()
  const classesEr = useErrorStyle()
  const { name, value } = field
  const [errorsName, setErrorsName] = useState('')
  const [errorsItemName] = useState(name.substring(name.lastIndexOf('.') + 1))
  const [errorsList, setErrorList] = useState([] as String[])
  const [errorss, setErrorss] = useState([] as Errors[])
  const { errors } = form
  useEffect(() => {
    if (errors['conditionList']) {
      let errorsList: any = errors['conditionList']
      errorsList = JSON.parse(JSON.stringify(errorsList))
      errorsList.forEach((item: ManagementItemError) => {
        if (item) {
          if (
            errorsItemIndex === item.manageIndex &&
            errorsItemName === item.manageName
          ) {
            if (item.errorsMessage !== '') {
              setErrorsName(item.errorsMessage)
            } else {
              setErrorsName('')
            }
          }
        }
      })
    } else {
      setErrorsName('')
    }
  }, [errors])
  const handleChange = (event: any) => {
    const errorS: Errors[] = []
    if (!(hint && event.target.value.length > 100)) {
      form.setFieldValue(name, event.target.value)
      if (relatedName) {
        if (event.target.value) {
          form.setFieldValue(relatedName, '0')
        } else {
          form.setFieldValue(relatedName, '')
        }
      }
      const pattDecimal = /^[-+]?(0|[1-9]\d*)(\.\d+)?$/
      const pattFloat = /^[-+]?(0|[1-9]\d*)(\.\d{1,2})?$/
      try {
        yup
          .object()
          .shape({ [name]: yup.string() })
          .validateSync({ [name]: event.target.value })
      } catch (err) {
        setErrorList([err.message.props.children])
        return
      }
      if (
        event.target.value.length >
        magiContants.MCAXS501_MAXLENGTH_POSITVE_NUMBER &&
        event.target.value > magiContants.MCAXS501_NUMBERR_0
      ) {
        errorS.push({
          isError: true,
          errorMsg: validationMessageSet.maxLength({
            max: magiContants.MCAXS501_MAXLENGTH_POSITVE_NUMBER,
          }),
        })
      }
      if (
        event.target.value.length >
        magiContants.MCAXS501_MAXLENGTH_NEGATIVE_NUMBER &&
        event.target.value < magiContants.MCAXS501_NUMBERR_0
      ) {
        errorS.push({
          isError: true,
          errorMsg: validationMessageSet.maxLength({
            max: magiContants.MCAXS501_MAXLENGTH_POSITVE_NUMBER,
          }),
        })
      }
      if (event.target.value > magiContants.MCAXS501_MAXVALUE) {
        errorS.push({
          isError: true,
          errorMsg: validationMessageSet.maxValue({
            max: magiContants.MCAXS501_MAXVALUE,
          }),
        })
      }
      if (event.target.value < magiContants.MCAXS501_MINVALUE) {
        errorS.push({
          isError: true,
          errorMsg: validationMessageSet.minValue({
            min: magiContants.MCAXS501_MINVALUE,
          }),
        })
      }
      if (
        !(event.target.value === '' || pattDecimal.test(event.target.value))
      ) {
        errorS.push({
          isError: true,
          errorMsg: validationMessageSet.halfWidthNumber(),
        })
      } else if (
        !(event.target.value === '' || pattFloat.test(event.target.value))
      ) {
        errorS.push({
          isError: true,
          errorMsg: getMessage('MCAXS501-002'),
        })
      }
      if (errorS.length == 0) {
        if (onChange) {
          onChange(event.target.value)
        }
      }
      setErrorss(errorS)
      const errorLabels = errorS.map(i => {
        return i.errorMsg
      })
      setErrorList(errorLabels)

      if (errors['conditionList']) {
        let errorsList: any = errors['conditionList']
        errorsList = JSON.parse(JSON.stringify(errorsList))
        errorsList.forEach((item: ManagementItemError) => {
          if (item) {
            if (
              errorsItemIndex === item.manageIndex &&
              errorsItemName === item.manageName
            ) {
              if (item.errorsMessage !== '') {
                setErrorsName(item.errorsMessage)
              } else {
                setErrorsName('')
              }
            }
          }
        })
      } else {
        setErrorsName('')
      }
    }
  }
  useEffect(() => {
    if (disabled) {
      form.setFieldValue(name, '')
      setErrorsName('')
      setErrorList([])
    }
  }, [disabled])
  return (
    <FormControl error={Boolean(errorsName !== '')}
      className={classes.cusVerticalAlign}
    >
      {
        multiline ? (
          <textarea
            value={value ? value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            className={`form__input_140w ${classes.textarea} ${errorsList && errorsList.length > 0 ? classes.error : ''
              }`}
            disabled={disabled}
            name={name}
          />
        ) : (
            <input
              type={type ? type : 'text'}
              value={value ? value : ''}
              onChange={handleChange}
              placeholder={placeholder}
              className={`form__input_140w ${errorsList && errorsList.length > 0 ? classes.error : ''
                }`}
              disabled={disabled}
              name={name}
            />
          )}
      {
        hint ? (
          <div className={classes.hint}>
            <Typography variant='caption'>{`入力${value.length} / 最大100`}</Typography>
          </div>
        ) : null
      }
      {errorsName !== '' ? (
        <p className={`form__input_140w ${classesEr.helperText}`}>{errorsName}</p>
      ) : null}
      {
        errorsList ? (
          <>
            {errorsList.map(info => (
              <p className={`form__input_140w ${classesEr.helperText}`}>{info}</p>
            ))}
          </>
        ) : null
      }
    </FormControl >
  )
}