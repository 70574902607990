import Grid from '@material-ui/core/Grid'
import { routeList } from 'routes/routes'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { dateToString, stringToDate } from 'common/generalUtil'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import history from 'utils/history'
import { magiContants as contants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import {
  globalMenuNews,
  searchRecruitmentStatusReportList,
  setItemCodeValResult,
} from 'reducers/recruitmentStatusReportReducer'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'
import { ConfirmDialog, ConfirmDialogProps } from 'sp/components/ConfirmDialog'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '20px 10px',
    textAlign: 'left',
    boxShadow: 'none',
  },
  btLeft: {
    textAlign: 'left',
    wordBreak: 'break-all',
    margin: '0px 10px 10px 10px',
    fontSize: '12px',
  },
  textDecoration: {
    textDecoration: 'underline',
  },
  marginTop: {
    marginTop: 10,
  },
  margin_dateItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#1A1A1A',
    fontSize: '12px',
    paddingRight: '10px',
  },
  disorder: {
    alignItems: 'center',
    backgroundColor: '#f5c4dc',
    display: 'inline-flex',
    fiexBasis: '135px',
    justifyContent: 'center',
    margin: '8px 0px 3px 8px',
    width: '135px',
    color: '#1A1A1A',
    fontSize: '12px',
    borderRadius: '12px',
    height: '25px',
  },
  maintenance: {
    alignItems: 'center',
    backgroundColor: '#dbc4f5',
    display: 'inline-flex',
    fiexBasis: '135px',
    justifyContent: 'center',
    margin: '8px 0px 3px 8px',
    width: '135px',
    color: '#1A1A1A',
    fontSize: '12px',
    borderRadius: '12px',
    height: '25px',
  },
  release: {
    alignItems: 'center',
    backgroundColor: '#cef0ac',
    display: 'inline-flex',
    fiexBasis: '135px',
    justifyContent: 'center',
    margin: '8px 0px 3px 8px',
    width: '135px',
    color: '#1A1A1A',
    fontSize: '12px',
    borderRadius: '12px',
    height: '25px',
  },
  information: {
    alignItems: 'center',
    backgroundColor: '#b6d3f0',
    display: 'inline-flex',
    fiexBasis: '135px',
    justifyContent: 'center',
    margin: '8px 0px 3px 8px',
    width: '135px',
    color: '#1A1A1A',
    fontSize: '12px',
    borderRadius: '12px',
    height: '25px',
  },
  labelStyle: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#1A1A1A',
    padding: '0px 0px 10px 5px',
  },
  linkButton: {
    display: 'table-cell!important',
    color: '#1950A6',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
      border: '0.2px dashed grey',
    },
  },
  linkButton_mailTheme: {
    display: 'table-cell!important',
    color: '#1950A6',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
      border: '0.2px dashed grey',
    },
  },
}))

interface Props {
  setOpen: (state: boolean) => void
}
const MCAAM020 = ({ setOpen }: Props) => {
  const classes = useStyles()
  const newsInfo = useSelector((state: RootState) => state.globalMenu.newsInfo)
  const dispatch = useDispatch()
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  // メッセージ入力ダイアログコントロール
  const { isMessageInputDialogOpen } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<ConfirmDialogProps | undefined>()

  const { urlparam1, urlparam2 } = useSelector((state: RootState) => state.recruitmentStatusReport)

  const [newWindow, setNewWindow] = useState<Window | null>(null)

  const handleClick = async () => {
    if (isMessageInputDialogOpen) {
      // 確認ダイアログ表示（メッセージ入力オープン時）
      const result = await new Promise<string>((resolve) => {
        setConfirmDialogConfig({
          onClose: resolve,
          message: getMessage(contants.MESSAGECODE_MCAXS050_019)
        })
      })
      // ダイアログを破棄
      setConfirmDialogConfig(undefined)
      if (result !== "confirm") {
        // OK以外であればメッセージ入力画面が開いているままなので処理を抜ける
        return false
      }
      // メッセージ入力画面を閉じる
      dispatch(closeMessageInputDialog())
    }

    setOpen(false)
    dispatch(setItemCodeValResult('1'))
    const detailInitialValues = {
      recruitmentManagementDivision: globalMenu.recruitmentManagementDivision, //採用管理区分
      lastLogoutTime: globalMenu.lastLogoutTime, // 最終ログアウト日時
      preLoginTime: globalMenu.previousLoginTime, // 前回ログイン日時
      lastLoginTime: globalMenu.lastLoginTime, // 最終ログイン日時
    }
    dispatch(searchRecruitmentStatusReportList(detailInitialValues))
    history.push(routeList.home)
  }
  const handle = (newsId: string) => {
    // スマホの場合、非同期処理の後のwindow.openがSafariではポップアップとみなされるため
    // 別タブを開いておく
    setNewWindow(window.open(
      'about:blank',
      '_blank'
    ))

    const initialState = {
      newsId: newsId, // お知らせID
    }
    setOpen(false)
    dispatch(
      globalMenuNews({
        param1: initialState,
        param2: globalMenu.recruitmentManagementDivision,
      })
    )
  }

  useEffect(() => {
    if (newWindow) {
      newWindow.location.href='/MCAVS020/'
        .concat(urlparam1)
        .concat(urlparam2)
    }
  }, [urlparam1, urlparam2])

  return (
    <div>
      {newsInfo.length === 0 ? (
        <Paper className={classes.paper}>
          <Grid container className={classes.labelStyle}>
            <div
              dangerouslySetInnerHTML={{
                __html: getMessage(contants.MESSAGECODE_MCAAS020_00A)
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html:
                  globalMenu.recruitmentManagementDivision ===
                    contants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
                    globalMenu.serviceMode === contants.SERVICEMODE_MARCH
                    ? ''
                    : getMessage(contants.MESSAGECODE_MCAAS020_00C).trim(),
              }}
            />
            {!(
              globalMenu.recruitmentManagementDivision ===
              contants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
              globalMenu.serviceMode === contants.SERVICEMODE_MARCH
            ) && (
                <button
                  type='button'
                  className={`${classes.linkButton} ${classes.textDecoration}`}
                  onClick={handleClick}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getMessage(contants.MESSAGECODE_MCAAS020_00B),
                    }}
                  />
                </button>
              )}
          </Grid>
        </Paper>
      ) : (
          <Paper className={classes.paper}>
            <Grid container className={classes.labelStyle}>
              <div
                dangerouslySetInnerHTML={{
                  __html: getMessage(contants.MESSAGECODE_MCAAS020_000)
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    globalMenu.recruitmentManagementDivision ===
                      contants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
                      globalMenu.serviceMode === contants.SERVICEMODE_MARCH
                      ? ''
                      : getMessage(contants.MESSAGECODE_MCAAS020_00C).trim(),
                }}
              />
              {!(
                globalMenu.recruitmentManagementDivision ===
                contants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
                globalMenu.serviceMode === contants.SERVICEMODE_MARCH
              ) && (
                  <button
                    type='button'
                    className={`${classes.linkButton} ${classes.textDecoration}`}
                    onClick={handleClick}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getMessage(contants.MESSAGECODE_MCAAS020_00B),
                      }}
                    />
                  </button>
                )}
            </Grid>
            {newsInfo.map(temp => (
              <Grid
                container
                key={temp.newsId}
                className={`${classes.marginTop}`}>
                <Grid item xs={6}>
                  {temp.category === '10' ? (
                    <span className={classes.disorder}>障害</span>
                  ) : temp.category === '20' ? (
                    <span className={classes.maintenance}>メンテナンス</span>
                  ) : temp.category === '30' ? (
                    <span className={classes.release}>リリース</span>
                  ) : temp.category === '40' ? (
                    <span className={classes.information}>
                      インフォメーション
                  </span>
                  ) : (
                            ''
                          )}
                </Grid>

                <Grid item xs={6} className={classes.margin_dateItem}>
                  {dateToString(stringToDate(temp.publicStartDate))}
                </Grid>

                <Grid item>
                  <button
                    type='button'
                    className={`${classes.linkButton_mailTheme} ${classes.btLeft} ${classes.textDecoration}`}
                    onClick={() => handle(temp.newsId)}
                    style={{ wordBreak: 'break-word' }}>
                    {temp.subject}
                  </button>
                </Grid>
              </Grid>
            ))}
          </Paper>
        )}
      {/* メッセージ有無チェック */}
      {confirmDialogConfig && <ConfirmDialog {...confirmDialogConfig} />}
    </div>
  )
}
export default MCAAM020
