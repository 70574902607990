import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  inputNotificationMailAddress,
  inputNotificationSelect,
} from 'reducers/messageSendReducer'
import { magiContants } from 'utils/contants'
import { FormHandler } from '.'
import { inputList } from './formConfig'
import Layout from './Layout'
import MessageInputToolTip from './MessageInputToolTip'
import MessageInputWithCounter from './messageInputWithCounter'

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
    color: '#fff',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    marginTop: '6px',
    marginRight: theme.spacing(1.5),
  },
  input: {},
  label: {
    marginTop: '20px',
    alignItems: 'flex-start',
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  immediate: {
    width: '150px',
  },
  notDesired: {
    width: '120px',
  },
  fullWidth: {
    width: '100%',
    '& > div > label:last-child': {
      width: '100%',
    },
    '& > div > label > span:last-child': {
      width: '100%',
    },
    '& > div > label > span:last-child > div > div': {
      width: 'calc(100% - 69px)',
    },
  },
  required: {
    flexBasis: '150px !important',
    '&:after': {
      content: '"必須"',
      backgroundColor: '#c93832',
      borderRadius: '12px',
      color: '#fff',
      display: 'inline-block',
      lineHeight: '24px',
      marginLeft: '8px',
      textAlign: 'center',
      width: '52px',
    },
    '& + div': {
      flexBasis: 'calc(100% - 150px) !important',
    },
  },
}))

export interface Props {
  form: FormHandler
}

const name = 'reminder'

const Reminder = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChangeReminder = (value: string) => {
    dispatch(inputNotificationMailAddress({ value }))
  }

  const enableInput = () => {
    const value = magiContants.NOTIFICATION_DESIRED
    form.setFieldValue('reminderSelect', value)
    dispatch(inputNotificationSelect({ value }))
  }

  const handleClick = () => {
    const value = magiContants.NOTIFICATION_NOT_DESIRED
    form.setFieldValue('reminderSelect', value)
    dispatch(inputNotificationSelect({ value }))
  }

  const tooltip1Title = useMemo(() => {
    return (
      <span>
        {' '}
        {
          '応募者（質問者）からの返信があった際にお知らせを通知するメールアドレスを指定してください。'
        }
      </span>
    )
  }, [])

  return (
    <Layout id='reminder'>
      <section>
        <h1>
          応募者（質問者）からの返信時にお知らせを希望する
          <MessageInputToolTip
            title={tooltip1Title}
          />
        </h1>
        <FormControl
          className={`${classes.root} ${classes.fullWidth}`}
          style={{ marginLeft: '10px' }}>
          <RadioGroup
            name='reminderSelect'
            defaultValue={form.values.reminderSelect}>
            <FormControlLabel
              className={classes.notDesired}
              checked={
                form.values.reminderSelect ===
                magiContants.NOTIFICATION_NOT_DESIRED
              }
              control={
                <Radio
                  color='primary'
                  value={magiContants.NOTIFICATION_NOT_DESIRED}
                  onClick={handleClick}
                />
              }
              label='希望しない'
            />
            <FormControlLabel
              className={classes.label}
              checked={
                form.values.reminderSelect === magiContants.NOTIFICATION_DESIRED
              }
              control={
                <Radio
                  color='primary'
                  value={magiContants.NOTIFICATION_DESIRED}
                  onClick={enableInput}
                />
              }
              label={
                <div className={classes.container}>
                  <Typography className={classes.text}>希望する</Typography>
                  <Field
                    name={name}
                    className={`${classes.input} ${form.values.reminderSelect !==
                      magiContants.NOTIFICATION_DESIRED
                      ? classes.disabled
                      : ''
                      }`}
                    component={MessageInputWithCounter}
                    hint
                    disabled={
                      form.values.reminderSelect !==
                      magiContants.NOTIFICATION_DESIRED
                    }
                    placeholder='xx@xxx.com 半角英数字で入力'
                    maxAmount={inputList.notifyMailAddress.max}
                    onChange={handleChangeReminder}
                  />
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      </section>
    </Layout>
  )
}

export default React.memo(Reminder)
