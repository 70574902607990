import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddOutlined, Remove } from '@material-ui/icons'
import { isEmpty } from 'common/generalUtil'
import DetailDialog from 'pages/MCAZS070/DetailDialog'
import * as viewConfig from 'pages/MCAZS091/viewConig'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { magiContants } from 'utils/contants'
import { useUtilStyles } from 'utils/styles'

interface Props {
  targetList: viewConfig.SendTarget[]
  destinationSelection: string
  recruitmentManagementDivision: string
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: viewConfig.SearchCriteriaInfo
  filterCondition: string
  mySearchCondition: string | null
  conversionMySearch: viewConfig.MySearch[]
  destinationDivision: string
}

const memberTypeNameList = new Map([
  [magiContants.MEMBERS_TYPE_NON_MEMBER, 'マイナビ非会員'],
  [magiContants.MEMBERS_TYPE_MEMBER, 'マイナビ会員'],
  [magiContants.MEMBERS_TYPE_DEACTIVATED, '退会済み'],
])

const entryTypeList = new Map([
  ['01', '一括'],
  ['02', '個別'],
  ['03', 'セミナー'],
  ['04', 'イベント'],
  ['11', '通常応募'],
  ['12', 'スカウト応募'],
  ['13', 'セミナー・面談参加申込み'],
  ['14', '質問（お問い合わせ）'],
  ['15', '匿名質問'],
  ['16', '旧イベント応募'],
  ['17', 'イベント着席'],
  ['18', 'イベント応募'],
  ['19', '他ルート応募'],
  ['90', '取込'],
])

const progressTypeList = new Map([
  ['0', '未判定'],
  ['1', '判定中'],
  ['2', '参加'],
  ['3', '合格'],
  ['4', '不参加'],
  ['5', '不合格'],
  ['6', 'その他'],
])

const useStyles = makeStyles(theme => ({
  badge: {
    margin: `${theme.spacing(0, 0)} !important`,
    display: 'inline-flex !important',
    padding: '2px 0px',
    height: 'auto',
  },
  mail: {
    '& td': {
      fontWeight: 'bold',
    }
  },
  entryTypeTh: {
    width: '220px',
  },
  entryType: {
    backgroundColor: '#b6d3f0 !important',
    border: 'none !important',
  },
  search: {
    flexBasis: '100% !important',
    marginLeft: '20px',
    fontSize: '12px',
    fontWeight: 600
  },
  searchCriteriaName: {
    fontWeight: 400,
  },
  mySearch: {
    flexBasis: '100% !important',
    marginTop: '15px',
    marginBottom: '-3px',
  },
  ellipsie: {
    width: '100%',
    backgroundColor: 'white',
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    minHeight: '105px',
    maxHeight: '105px',
    cursor: 'auto!important',
    marginTop: '5px',
    '& div': {
      margin: '0px 0px 0px 5px',
    }
  },
  btn: {
    left: 'calc(100% - (30px * 9))',
    display: 'flex',
    height: '35px',
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    paddingLeft: '15px',
    paddingRight: '15px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
  },
  // [phase2] start
  sendToNonMember: {
    minWidth: '155px',
  },
  radioGroup: {
    display: 'inlineBlock',
  },
  gridStyle: {
    minWidth: '458px',
  },
  csvDownloadButton: {
    margin: '30px 0px 0px 0px',
    width: 220,
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
    minWidth: 150,
  },
  invisible: {
    fontSize: '1.8rem !important',
    textAlign: 'left',
    padding: '12px 0 12px 24px',
    backgroundColor: '#e5e5e5',
  },
  flagIconPosition: {
    position: 'absolute',
    right: '25px',
  },
  flagIcon: {
    width: '20px',
    height: 'auto',
    color: '#595959',
  },
  accordionRoot: {
    '&::before': {
      top: 0,
      height: 'inherit',
      position: 'inherit',
      backgroundColor: 'inherit',
    },
    color: 'inherit',
    position: 'inherit',
    backgroundColor: 'inherit',
  },
  accordionRounded: {
    '&:last-child': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    '&:first-child': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },
    borderRadius: 'initial',
  },
  accordionExpanded: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '0px !important',
  },
  accordionExpandedCondition: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '5px !important',
  },
  accordionExpandedList: {
    '&:last-child': {
      marginBottom: '10px !important',
    },
    margin: '10px !important',
  },
  accordionSummaryRoot: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
    // marginTop: '5px !important',
    // marginBottom: '5px !important',
  },
  accordionSummaryRootList: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
    // marginTop: '5px !important',
  },
  accordionSummaryExpandedList: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
  accordionSummaryContent: {
    margin: 'initial',
    display: 'inherit',
    flexGrow: 'initial',
    width: '100%',
    alignItems: 'center'
  },
  accordionDetailRoot: {
    display: 'initial',
    padding: 0,
  },
}))

const senderTargetMax = 100

const SendTarget = ({
  targetList,
  recruitmentManagementDivision,
  searchCriteriaName, searchCriteria,
  mySearchCondition,
  conversionMySearch,
  destinationDivision
}: Props) => {
  const classes = useStyles()

  // 「条件を表示する」ボタン押下
  const [detailDialogOpen, setDetailDialogOpen] = useState(false)
  const closeDetailDialog = () => {
    setDetailDialogOpen(false)
  }

  const [sendOptionOpen, setSendOptionOpen] = React.useState(false)
  const handleSendOptionExpandedChange = () => {
    setSendOptionOpen(!sendOptionOpen)
  }

  const [targetExpanedList, setTargetExpanedList] = React.useState([
    { id: '', isExpanded: true },
  ])

  useEffect(() => {
    if (targetList && targetList.length > 0) {
      targetList.map(applicant =>
        setTargetExpanedList(prevState => [
          ...prevState,
          { id: applicant.entryId, isExpanded: true },
        ])
      )
    }
  }, [targetList])

  const isExpaned = (id: string) => {
    if (targetExpanedList) {
      const obj = targetExpanedList.find(item => item.id === id)
      if (obj) {
        return obj.isExpanded
      }
    }
    return false
  }

  const handleExpandedChange = (id: string) => (
    event: React.ChangeEvent<{}>
  ) => {
    setTargetExpanedList(prevState =>
      prevState.map(obj =>
        obj.id === id ? { id: obj.id, isExpanded: !obj.isExpanded } : obj
      )
    )
    event.stopPropagation()
  }

  const [senderFlg, setSenderFlg] = useState(true)

  const [sendTargetOpen, setSendTargetOpen] = React.useState(false)

  return (
    <>
      <section>
        <h2>送信先</h2>
        <div style={{ margin: '10px' }}>
          {destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
            <dd>マイナビのみ</dd>
          )}
          {destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED && (
            <dd>マイナビ優先</dd>
          )}
          {destinationDivision === magiContants.DESTINATION_DIVISION_EMAIL_ONLY && (
            <dd>e-mailのみ</dd>
          )}
        </div>
      </section>
      <section>
        <h2>{'送信対象（' + targetList.length + '件）'}</h2>
        {targetList &&
          targetList.length > 1 &&
          !isEmpty(searchCriteria) &&
          (!conversionMySearch || conversionMySearch.length == 0) && (
            <>
              <Accordion
                classes={{
                  root: classes.accordionRoot,
                  rounded: classes.accordionRounded,
                  expanded: classes.accordionExpandedCondition,
                }}
                className='box-in-order__send-list--serch-option'
                expanded={sendOptionOpen}
                //expanded={true}
                onClick={e => e.stopPropagation()}
                onChange={handleSendOptionExpandedChange}>
                <AccordionSummary
                  aria-label='Expand'
                  aria-controls='serch-option-content'
                  id='serch-option-id'
                  onClick={e => e.stopPropagation()}
                  classes={{
                    root: classes.accordionSummaryRootList,
                    expanded: classes.accordionSummaryExpandedList,
                    content: classes.accordionSummaryContent,
                    expandIcon: classes.flagIcon,
                  }}>
                  <h1 className='box-in-order__send-list--serch-option-title'>
                    検索条件
                  </h1>
                  <div className={classes.flagIconPosition}>
                    {sendOptionOpen ? (
                      <Remove className={classes.flagIcon} />
                    ) : (
                      <AddOutlined className={classes.flagIcon} />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes.accordionDetailRoot }}>
                  {!isEmpty(searchCriteriaName) ? (
                    <div className={classes.search}>
                      選択しているMY検索：
                      <span className={classes.searchCriteriaName}>
                        {searchCriteriaName}
                      </span>
                    </div>
                  ) : null}
                  <div className='box-in-order__send-list--serch-option-p'>
                    <div
                      dangerouslySetInnerHTML={{ __html: searchCriteria }}
                    />
                  </div>
                  <span style={{ fontSize: '12px' }}>
                    {magiContants.SEARCH_CRITERIA_DESCRIPTION}
                  </span>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        {targetList && targetList.length <= senderTargetMax && (
          <section aria-label='CassetteExpand'>
            {/* <h2>{'送信対象（' + targetList.length + '件）'}</h2> */}
            {targetList &&
              targetList.map(applicant =>
                applicant.readableFlag == '0' ? (
                  <div className='box-in-order__send-list--cassette'>
                    権限がないため閲覧できません（ID：{applicant.jobSeekerId}
                    ）
                  </div>
                ) : (
                  <>
                    <Accordion
                      key={applicant.entryId}
                      classes={{
                        root: classes.accordionRoot,
                        rounded: classes.accordionRounded,
                        expanded: classes.accordionExpandedList,
                      }}
                      className='box-in-order__send-list--cassette'
                      expanded={isExpaned(applicant.entryId)}
                      //expanded={true}
                      onClick={e => e.stopPropagation()}
                      onChange={handleExpandedChange(applicant.entryId)}>
                      <AccordionSummary
                        aria-label='Expand'
                        aria-controls={`send-list-cassette-panel${applicant.entryId}_content`}
                        id={`send-list-cassette-panel${applicant.entryId}_id`}
                        onClick={e => e.stopPropagation()}
                        classes={{
                          root: classes.accordionSummaryRoot,
                          expanded: classes.accordionSummaryExpanded,
                          content: classes.accordionSummaryContent,
                          expandIcon: classes.flagIcon,
                        }}>
                        <table className='box-in-order__send-list--cassette_header'>
                          {applicant.applicationType ===
                            magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION ? (
                            <>
                              <tr>
                                <td>
                                  <p className='box-in-order__send-list--cassette_name'>
                                    匿名
                                  </p>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>
                                  <p className='box-in-order__send-list--cassette_name' style={{ marginRight: '10px' }}>
                                    {applicant.fullName}
                                  </p>
                                </td>
                                <td>
                                  <p style={{ marginRight: '10px' }}>
                                    {applicant.age}歳</p>
                                </td>
                                <td>
                                  <p style={{ marginRight: '10px' }}>
                                    {applicant.progress} ( {applicant.progressType !== '1' ? ' - ' : progressTypeList.get(applicant.decisionDivision)} )
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </table>
                        <div className={classes.flagIconPosition}>
                          {isExpaned(applicant.entryId) ? (
                            <Remove className={classes.flagIcon} />
                          ) : (
                            <AddOutlined className={classes.flagIcon} />
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0px' }}>
                        <div className='box-in-order__send-list--cassette_detail'>
                          <div className='box-in-order__send-list--cassette_line'>
                            <p className='box-in-order__send-list--cassette_line_title'>
                              応募職種
                            </p>
                            <p className='box-in-order__send-list--cassette_line'>
                              {!isEmpty(applicant.jobTitle)
                                ? applicant.jobTitle
                                : '-'}
                            </p>
                          </div>
                          <div className='box-in-order__send-list--cassette_line'>
                            <p className='box-in-order__send-list--cassette_line_title'>
                              選考フロー
                            </p>
                            <p className='box-in-order__send-list--cassette_line'>
                              {!isEmpty(applicant.selectionFlow) && applicant.entryType !== magiContants.ENTRY_TYPE_QUESTION && applicant.entryType !== magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION
                                ? applicant.selectionFlow
                                : '-'}
                            </p>
                          </div>
                          <div className='box-in-order__send-list--cassette_line'>
                            <p className='box-in-order__send-list--cassette_line_title'>
                              応募日時
                            </p>
                            <p className='box-in-order__send-list--cassette_line_item'>
                              {applicant.applicationDate}
                            </p>
                            <p className='box-in-order__send-list--cassette_line_item'>
                              {(function () {
                                const rlt = memberTypeNameList.get(
                                  applicant.mynaviMemberInfo
                                )
                                if (
                                  recruitmentManagementDivision ===
                                  magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
                                  applicant.mynaviMemberInfo ===
                                  magiContants.MEMBERS_TYPE_DEACTIVATED
                                ) {
                                  // 就職かつ退会済みの場合、「退会済み」の前に「マイナビ」をつけます
                                  return 'マイナビ' + rlt
                                }
                                return rlt
                              })()}
                            </p>
                            <p className='box-in-order__send-list--cassette_line_item'>
                              <i
                                className={`badge ptn7 ${classes.badge} ${classes.entryType}`}>
                                {entryTypeList.get(applicant.applicationType)}
                              </i>
                            </p>
                          </div>
                          <div className='box-in-order__send-list--cassette_line_last'>
                            {destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ?
                              <>
                                <p className='box-in-order__send-list--cassette_line_title'>利用者番号</p>
                                <p>{applicant.jobSeekerId}</p>
                              </>
                              :
                              <>
                                <p className='box-in-order__send-list--cassette_line_title'>メールアドレス</p>
                                <p>{applicant.mailAddress}</p>
                              </>
                            }
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )
              )}
          </section>
        )}
        <DetailDialog
          onClose={closeDetailDialog}
          searchCriteriaName={searchCriteriaName}
          searchCriteria={searchCriteria}
          mySearchCondition={mySearchCondition}
          mySearch={conversionMySearch}
          detaildDialogOpen={detailDialogOpen} />
      </section>
    </>
  )
}

export default SendTarget
