import { lazy } from 'react'

import { DashboardLayout, AuthLayout } from 'layouts'
import { routeList, routePermissionList } from './routes'
import { RouteConfig } from 'react-router-config'
import NetworkError from 'pages/NetworkError'
import store from 'store'

export const publicRouteList = [
  {
    component: AuthLayout,
    routes: [
      {
        path: routeList.error,
        component: lazy(() => import('pages/ErrorBoundary/Error500')),
      },
      {
        path: routeList.temPassword,
        component: lazy(() => import('pages/MCACS020')),
        exact: true,
      },
      {
        path: routeList.checkStatute,
        component: lazy(() => import('pages/MCACS030')),
        exact: true,
      },
      {
        path: routeList.readying,
        component: lazy(() => import('pages/MCACS040')),
        exact: true,
      },
      {
        path: routeList.newLoginId,
        component: lazy(() => import('pages/MCADS030')),
        exact: true,
      },
      // #91157 就職転職分離 start
      {
        path: routeList.loginInduction,
        component: lazy(() => import('pages/LoginInduction')),
        exact: true,
      },
      // #91157 就職転職分離 end
      {
        path: routeList.accessError,
        component: lazy(() => import('pages/AccessError')),
      },
      {
        path: routeList.networkError,
        component: lazy(() => import('pages/NetworkError')),
        exact: true,
      },
      {
        path: routeList.home,
        component: lazy(() => import('pages/MCACS010')),
      },
    ],
  },
]

export const allPrivateRouteList: RouteConfig[] = [
  {
    path: routeList.commonSettingsMenu,
    component: lazy(() => import('pages/MCABS010')),
    exact: true,
  },
  {
    path: routeList.password,
    component: lazy(() => import('pages/MCACS020')),
    exact: true,
  },
  {
    path: routeList.account,
    component: lazy(() => import('pages/MCADS010')),
    exact: true,
  },
  {
    path: routeList.recruitGroup,
    component: lazy(() => import('pages/MCAES010')),
    exact: true,
  },
  {
    path: routeList.alertPreferenceSettings,
    component: lazy(() => import('pages/MCAFS010')),
    exact: true,
  },
  // {
  //   path: routeList.notAdopted,
  //   component: lazy(() => import('pages/MCAGS010')),
  //   exact: true,
  // },
  {
    path: routeList.recruitFlag,
    component: lazy(() => import('pages/MCAHS010')),
    exact: true,
  },
  {
    path: routeList.selectionFlow,
    component: lazy(() => import('pages/MCAJS010')),
    exact: true,
  },
  {
    path: routeList.mediaFlag,
    component: lazy(() => import('pages/MCAIS010')),
    exact: true,
  },
  {
    path: routeList.tag,
    component: lazy(() => import('pages/MCAKS010')),
    exact: true,
  },
  // {
  // MCALS010
  {
    path: routeList.csvTemplate,
    component: lazy(() => import('pages/MCALS010')),
    exact: true,
  },

  {
    path: routeList.messageTemplate,
    component: lazy(() => import('pages/MCAMS010')),
    exact: true,
  },
  // MCBリプレース MCBJS020 START
  {
    path: routeList.submissionReqTemplate,
    component: lazy(() => import('pages/MCBJS010')),
    exact: true,
  },
  // MCBリプレース MCBJS020 END
  {
    path: routeList.messageSign,
    component: lazy(() => import('pages/MCANS010')),
    exact: true,
  },
  {
    path: routeList.interviewLocation,
    component: lazy(() => import('pages/MCAOS010')),
    exact: true,
  },
  {
    path: routeList.searchCriteria,
    component: lazy(() => import('pages/MCARS010')),
    exact: true,
  },
  {
    path: routeList.searchCriteriaMcb,
    component: lazy(() => import('pages/MCBIS010')),
    exact: true,
  },
  {
    // MCBリプレース MCBLS010 START
    path: routeList.pdfDownloadListMcb,
    component: lazy(() => import('pages/MCBLS010')),
    exact: true,
  }, // MCBリプレース MCBLS010 End
  {
    path: routeList.attachmentList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  {
    path: routeList.advancedSearch,
    component: lazy(() => import('pages/MCAXS351')),
    exact: true,
  },
  {
    path: routeList.errorReport,
    component: lazy(() => import('pages/MCAUS010')),
    exact: true,
  },
  {
    path: routeList.errorReportComplete,
    component: lazy(() => import('pages/MCAUS020')),
    exact: true,
  },
  // {
  //   path: routeList.accountCreate,
  //   component: lazy(() => import('pages/AccountCreate')),
  //   exact: true,
  // },
  // {
  //   path: routeList.accountEdit,
  //   component: lazy(() => import('pages/AccountDetail')),
  // },
  // {
  //   path: routeList.company,
  //   component: lazy(() => import('pages/Company')),
  //   exact: true,
  // },
  // {
  //   path: routeList.application,
  //   component: lazy(() => import('pages/Application')),
  //   exact: true,
  // },
  // {
  //   path: routeList.notification,
  //   component: lazy(() => import('pages/Notification')),
  //   exact: true,
  // },
  {
    path: routeList.notAdoptedNotification,
    component: lazy(() => import('pages/MCAGS010')),
    exact: true,
  },
  {
    path: routeList.messageInBox,
    component: lazy(() => import('pages/MCAZS010')),
    exact: true,
  },
  {
    path: routeList.messageOutBox,
    component: lazy(() => import('pages/MCAZS020')),
    exact: true,
  },
  {
    path: routeList.messageReservation,
    component: lazy(() => import('pages/MCAZS030')),
    exact: true,
  },
  //MCARS050
  {
    path: routeList.actionPreList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  // MCAXS121
  {
    path: routeList.csvLaoding,
    component: lazy(() => import('pages/MCAXS121')),
    exact: true,
  },
  {
    path: routeList.csvDownload,
    component: lazy(() => import('pages/MCAXS130')),
    exact: true,
  },
  {
    path: routeList.entryManageTabactionPreList,
    component: lazy(() => import('pages/MCARS050')),
    exact: true,
  },
  {
    path: routeList.messageDraft,
    component: lazy(() => import('pages/MCAZS040')),
    exact: true,
  },
  {
    path: routeList.messageDustBox,
    component: lazy(() => import('pages/MCAZS050')),
    exact: true,
  },
  {
    path: routeList.nameCheckInput,
    component: lazy(() => import('pages/MCAQS010')),
    exact: true,
  },
  // MCB ロット３ MCAZS170~210 start
  {
    path: routeList.mcbMessageOutBox,
    component: lazy(() => import('pages/MCAZS170')),
    exact: true,
  },
  {
    path: routeList.mcbMessageReservation,
    component: lazy(() => import('pages/MCAZS180')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDraft,
    component: lazy(() => import('pages/MCAZS190')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDustBox,
    component: lazy(() => import('pages/MCAZS200')),
    exact: true,
  },
  {
    path: routeList.mcbMessageDetail,
    component: lazy(() => import('pages/MCAZS210')),
    exact: true,
  },
  // MCB ロット３ MCAZS170~210 end
  {
    path: routeList.nameList,
    component: lazy(() => import('pages/MCAQS020')),
    exact: true,
  },
  {
    path: routeList.entryUploadHistory,
    component: lazy(() => import('pages/MCAPS020')),
    exact: true,
  },
  {
    path: routeList.entryUploadErrorInfoComfirm,
    component: lazy(() => import('pages/MCAPS030')),
    exact: true,
  },
  // 次期開発12月 #73504 start
  {
    path: routeList.entryUploadAutoMergeComfirm,
    component: lazy(() => import('pages/MCAPS040')),
    exact: true,
  },
  // 次期開発12月 #73504 end
  {
    path: routeList.applicationUpload,
    component: lazy(() => import('pages/MCAPS010')),
    exact: true,
  },
  {
    path: routeList.messageDetail,
    component: lazy(() => import('pages/MCAZS070')),
  },
  {
    path: routeList.entryList,
    component: lazy(() => import('pages/MCAXS020')),
    exact: true,
  },
  {
    path: routeList.entryDetail,
    component: lazy(() => import('pages/MCAXS050')),
    exact: true,
  },
  {
    path: routeList.applicantDetailForJobChange,
    component: lazy(() => import('pages/MCAXS620')),
    exact: true,
  },
  {
    path: routeList.dummy,
    component: lazy(() => import('pages/MCAXS040_TEST')),
    exact: true,
  },
  {
    path: routeList.applicantList,
    component: lazy(() => import('pages/MCAXS010')),
    exact: true,
  },
  {
    path: routeList.applicantDetailForEmplyment,
    component: lazy(() => import('pages/MCAXS040')),
    exact: true,
  },
  {
    path: routeList.login,
    component: lazy(() => import('pages/RedirectToHome')),
  },
  {
    path: routeList.newsLocation,
    component: lazy(() => import('pages/MCAVS020')),
    exact: true,
  },
  {
    path: routeList.recruitmentStatusReportTab,
    component: lazy(() => import('pages/MCAVS010')),
    exact: true,
  },
  {
    path: routeList.scheduledList,
    component: lazy(() => import('pages/MCBAS020')),
    exact: true,
  },
  {
    path: routeList.home,
    component: lazy(() => import('pages/Home')),
    exact: true,
  },
  {
    path: routeList.jobSeekerNameInput,
    component: lazy(() => import('pages/MCAXS191')),
    exact: true,
  },
  {
    path: routeList.jobSeekerLogIn,
    component: lazy(() => import('pages/MCAXS201')),
    exact: true,
  },
  {
    path: routeList.entryInsEdit,
    component: lazy(() => import('pages/MCAXS220')),
    exact: true,
  },
  {
    path: routeList.jobSeekerInfoEdit,
    component: lazy(() => import('pages/MCAXS230')),
    exact: true,
  },
  {
    path: routeList.jobSeekerInfoInsertConfirm,
    component: lazy(() => import('pages/MCAXS211')),
    exact: true,
  },
  {
    path: routeList.jobSeekerInfoEditConfirm,
    component: lazy(() => import('pages/MCAXS650')),
    exact: true,
  },
  {
    path: routeList.entryEditConfim,
    component: lazy(() => import('pages/MCAXS640')),
    exact: true,
  },
  {
    path: routeList.entryEdit,
    component: lazy(() => import('pages/MCAXS240')),
    exact: true,
  },
  {
    path: routeList.entryEditConfimIns,
    component: lazy(() => import('pages/MCAXS630')),
    exact: true,
  },
  {
    path: routeList.pdfGenerating,
    component: lazy(() => import('pages/MCAXS151')),
    exact: true,
  },
  {
    path: routeList.pdfDownload,
    component: lazy(() => import('pages/MCAXS141')),
    exact: true,
  },
  // MCBリプレース MCBLS030 MCBLS020 START
  {
    path: routeList.creatingMcbPdf,
    component: lazy(() => import('pages/MCBLS030')),
    exact: true,
  },
  {
    path: routeList.downloadMcbPdf,
    component: lazy(() => import('pages/MCBLS020')),
    exact: true,
  },
  // MCBリプレース MCBLS030 MCBLS020 END
  {
    path: routeList.duplicateIdentification,
    component: lazy(() => import('pages/MCAQS020')),
    exact: true,
  },

  // MCAZS220_My CareerBoxメッセージ入力
  {
    path: routeList.mcbMessageSend,
    component: lazy(() => import('pages/MCAZS220')),
    exact: true,
  },
  // MCBHS090_提出リクエスト強制停止
  {
    path: routeList.forcedStopSubmissionRequest,
    component: lazy(() => import('pages/MCBHS090')),
    exact: true,
  },
  // MCBHS060_提出リクエスト締切変更
  {
    path: routeList.ChangeTermRequest,
    component: lazy(() => import('pages/MCBHS060')),
    exact: true,
  },
  {
    path: routeList.myCareerBoxDataLink,
    component: lazy(() => import('pages/MCBCS010')),
    exact: true,
  },
  // MCBCS020_My CareerBoxデータ連携完了
  {
    path: routeList.myCareerBoxDataLinkOK,
    component: lazy(() => import('pages/MCBCS020')),
    exact: true,
  },
  // MCBリプレース MCBHS040 START
  {
    path: routeList.submissionRequestManagement,
    component: lazy(() => import('pages/MCBHS040')),
    exact: true,
  },
  // MCBリプレース MCBHS040 END
  // MCBリプレース MCBHS050 START
  {
    path: routeList.submissionRequestMessageDetails,
    component: lazy(() => import('pages/MCBHS050')),
    exact: true,
  },
  // MCBリプレース MCBHS050 END
  // MCBリプレース MCBHS010 START
  {
    path: routeList.submissionRequestSend,
    component: lazy(() => import('pages/MCBHS010')),
    exact: true,
  },
  // MCBリプレース MCBHS010 END
  // [phase2] start
  // MCBCS030
  {
    path: routeList.myCareerBoxCsvLaoding,
    component: lazy(() => import('pages/MCBCS030')),
    exact: true,
  },
  {
    path: routeList.myCareerBoxcsvDownload,
    component: lazy(() => import('pages/MCBCS040')),
    exact: true,
  },
  // [phase2] end
  {
    path: routeList.redirect,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.redirect2,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.duplicateLogin,
    component: lazy(() => import('pages/DuplicateLogin')),
    exact: true,
  },
  {
    path: routeList.interruption,
    component: lazy(() => import('pages/Interruption')),
    exact: true,
  },
  {
    path: routeList.newLoginId,
    component: lazy(() => import('pages/MCADS030')),
    exact: true,
  },
  {
    path: routeList.envCharManual,
    component: lazy(() => import('pages/RedirectToManual')),
    exact: true,
  },
  {
    path: routeList.networkError,
    component: NetworkError,
    exact: true,
  },
  {
    component: lazy(() => import('pages/NotFound')),
  },
]

// 転職March #75621 START
export const allPrivateRouteListMarch: RouteConfig[] = [
  {
    path: routeList.entryDetail,
    component: lazy(() => import('pages/MCAXS050')),
    exact: true,
  },
  {
    path: routeList.commonSettingsMenu,
    component: lazy(() => import('pages/MCABS010')),
    exact: true,
  },
  {
    path: routeList.alertPreferenceSettings,
    component: lazy(() => import('pages/MCAFS010')),
    exact: true,
  },
  {
    path: routeList.recruitFlag,
    component: lazy(() => import('pages/MCAHS010')),
    exact: true,
  },
  {
    path: routeList.recruitGroup,
    component: lazy(() => import('pages/MCAES010')),
    exact: true,
  },
  {
    path: routeList.account,
    component: lazy(() => import('pages/MCADS010')),
    exact: true,
  },
  {
    path: routeList.password,
    component: lazy(() => import('pages/MCACS020')),
    exact: true,
  },
  {
    path: routeList.entryList,
    component: lazy(() => import('pages/MCAXS020')),
    exact: true,
  },
  // 転職March #9574 start
  // 削除 MCAXS121_CSV作成中 MCAXS130_CSVダウンロード
  // {
  //   path: routeList.csvLaoding,
  //   component: lazy(() => import('pages/MCAXS121')),
  //   exact: true,
  // },
  // {
  //   path: routeList.csvDownload,
  //   component: lazy(() => import('pages/MCAXS130')),
  //   exact: true,
  // },
  // 転職March #9574 end
  {
    path: routeList.pdfGenerating,
    component: lazy(() => import('pages/MCAXS151')),
    exact: true,
  },
  {
    path: routeList.pdfDownload,
    component: lazy(() => import('pages/MCAXS141')),
    exact: true,
  },
  {
    path: routeList.redirect,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.redirect2,
    component: lazy(() => import('pages/Redirect')),
    exact: true,
  },
  {
    path: routeList.duplicateLogin,
    component: lazy(() => import('pages/DuplicateLogin')),
    exact: true,
  },
  {
    path: routeList.interruption,
    component: lazy(() => import('pages/Interruption')),
    exact: true,
  },
  {
    path: routeList.newLoginId,
    component: lazy(() => import('pages/MCADS030')),
    exact: true,
  },
  {
    path: routeList.envCharManual,
    component: lazy(() => import('pages/RedirectToManual')),
    exact: true,
  },
  {
    path: routeList.errorReport,
    component: lazy(() => import('pages/MCAUS010')),
    exact: true,
  },
  {
    path: routeList.errorReportComplete,
    component: lazy(() => import('pages/MCAUS020')),
    exact: true,
  },
  // 転職March #9574 start
  {
    path: routeList.newsLocation,
    component: lazy(() => import('pages/MCAVS020')),
    exact: true,
  },
  // 転職March #9574 end
  {
    path: routeList.networkError,
    component: NetworkError,
    exact: true,
  },
  {
    component: lazy(() => import('pages/NotFound')),
  },
]
// 転職March #75621 END

const navigationGuard = (
  permissions: string[],
  list = store.getState().globalMenu.recruitmentManagementDivision == '2' &&
  store.getState().globalMenu.serviceMode == '1'
    ? allPrivateRouteListMarch
    : allPrivateRouteList
) => {
  if (permissions.length === 0) {
    return undefined
  }

  const authorizedList = list.map(i => {
    if (i.path === routeList.newLoginId) {
      return { ...i, component: lazy(() => import('pages/DuplicateLogin')) }
    }
    if (i.path) {
      if (
        !permissions.some(x =>
          routePermissionList[i.path as string].includes(x)
        )
      ) {
        return { ...i, component: lazy(() => import('pages/Unauthorized')) }
      }
    }
    return i
  })

  // before 404 route config
  authorizedList.splice(-1, 0, {
    path: routeList.error,
    component: lazy(() => import('pages/ErrorBoundary/Error500')),
  })

  return authorizedList
}
export const privateRouteList = (permissions: string[]) => [
  {
    component: DashboardLayout,
    routes: navigationGuard(permissions),
  },
]
