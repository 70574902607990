export default {
  root: {
    height: 'inherit',
    minHeight: 'inherit',
    background: 'inherit',
    position: 'unset',
    '&:hover': {
      background: 'inherit',
    },
    '&$expanded': {
      margin: '',
    },
  },
}
