import React, { useCallback, useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { useLocation } from 'react-router-dom'

import ErrorBoundary from 'pages/ErrorBoundary'
import { publicRouteList, privateRouteList } from 'sp/routes'
import { routeList } from 'routes/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import {
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  AppBar,
  Typography,
  Toolbar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { getGtmId } from 'reducers/companyLoginReducer'
import { closeMessage } from 'reducers/messageReducer'
import { sendError } from 'reducers/errorReducer'
import {
  signoutSuccess,
  setTargetYearErrorFlag,
  setTargetServiceErrorFlag,
  serviceModeChange,
  setErrorMessageForOKControl,
} from 'reducers/globalMenuReducer'
import history from 'utils/history'
import {
  getMarchModeChangeMessage2,
  caseOfToEntryListUrl,
} from 'sp/layouts/Dashboard/handleTopBarUrl'
import { listener as listenerMCACS020 } from 'pages/MCACS020/formConfig'
import { listener as listenerMCAUS010 } from 'pages/MCAUS010/formConfig'
import { listener as listenerMCAXS191 } from 'pages/MCAXS191/formConfig'
import { listener as listenerMCAXS201 } from 'pages/MCAXS201/formConfig'
import { listener as listenerMCAXS211 } from 'pages/MCAXS211/formConfig'
import { listener as listenerMCAXS220 } from 'pages/MCAXS220/formConfig'
import { listener as listenerMCAGS010 } from 'pages/MCAGS010/formConfig'
import { JudgeUrl } from 'sp/layouts/Dashboard'
import { magiContants as contants, magiContants } from 'utils/contants'
import TagManager from 'react-gtm-module'
import { getTargetYearFromToken } from 'utils/yearCheckUtil'
import { getMessage } from 'common/messageUtil'

import MessageInputDialog from 'sp/components/MessageInput/MessageInputDialog'
import { closeMessageInputDialog } from 'reducers/messageInputDialogReducer'

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-paper': {
      minWidth: '300px',
    },
  },
  lineBreak: {
    whiteSpace: 'pre-line',
  },
})

const AppSp: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const { token, gtmId } = useSelector((state: RootState) => state.companyLogin)
  // 転職March #75621 START
  const { permissions, errorMessageForOKControl } = useSelector(
    (state: RootState) => state.globalMenu
  )
  // 転職March #75621 END
  const { message, type } = useSelector((state: RootState) => state.snackbar)
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  const { errorMessage, errorStack } = useSelector(
    (state: RootState) => state.error
  )

  const snackbarOpen = Boolean(message) && type === 'snackbar'
  const modalOpen = Boolean(message) && type === 'modal'
  const modalOpenWindowClose = Boolean(message) && type === 'modalWindowClose'
  const fullpageOpen = Boolean(message) && type === 'fullpage'
  const modalLogout = Boolean(message) && type === 'modalLogout'

  const { isMessageInputDialogOpen, messageInputDialogKey } = useSelector(
    (state: RootState) => state.messageInputDialog
  )
  const isMessageInputDialogInitialized = useSelector(
    (state: RootState) => state.messageSend.isInitialized
  )
  const dispatch = useDispatch()

  // 転職March #75621 START
  // OKボタン機能カスタム用モーダル
  const marchModeChildWindowURL = [
    routeList.entryDetail,
    routeList.pdfGenerating,
    routeList.pdfDownload,
  ]
  const modalOpenForOKControl = Boolean(errorMessageForOKControl)
  const handleOK = useCallback(() => {
    if (errorMessageForOKControl == magiContants.MESSAGECODE_MCAAS010_004) {
      if (
        marchModeChildWindowURL.findIndex(
          i =>
            history.location.pathname &&
            history.location.pathname.indexOf(i) >= 0
        ) >= 0
      ) {
        dispatch(setErrorMessageForOKControl(''))
      } else {
        dispatch(
          serviceModeChange({
            targetService: '0',
            specailCaseType:
              caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0
                ? '1'
                : '0',
          })
        )
      }
    }
  }, [errorMessageForOKControl])
  // 転職March #75621 END

  const realUrl = useSelector((state: RootState) => state.url)

  const handleClose = (message: string) => async () => {
    if (isMessageInputDialogOpen && !isMessageInputDialogInitialized && !snackbarOpen) {
      // メッセージ入力ダイアログが開いており、初期化前の場合、メッセージ入力ダイアログを閉じる。
      const stickyElmList = document.querySelectorAll<HTMLDivElement>('.sticky-footer-block')
      if (stickyElmList) {
        stickyElmList.forEach(stickyElm => {
          stickyElm.style.bottom = '0px';
        })
      }
      const modalElmList = document.querySelectorAll<HTMLDivElement>(`.is-modal-show`)
      if (modalElmList.length === 1) {
        setTimeout(function () {
        }, 100)
      }
      await dispatch(closeMessageInputDialog())
    }

    dispatch(closeMessage())

    if (
      message.includes(
        '以下いずれかの事象が発生したため、ログイン状態が無効になりました。'
      )
    ) {
      dispatch(signoutSuccess(realUrl.LOGIN_URL))
    }

    if (window.location.pathname.indexOf('MCAZS030') != -1) {
      const dom = document.getElementById('mcazs030Reservation')
      if (dom) {
        if (!isMessageInputDialogOpen) {
          dom.click()
        }
      }
    }
    // 次期開発5月向#58931 start
    if (window.location.pathname.indexOf('MCAZS020') != -1) {
      const dom = document.getElementById('mcazs020Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    if (window.location.pathname.indexOf('MCAZS010') != -1) {
      const dom = document.getElementById('mcazs010Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    if (window.location.pathname.indexOf('MCAZS040') != -1) {
      const dom = document.getElementById('mcazs040Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    if (window.location.pathname.indexOf('MCAZS050') != -1) {
      const dom = document.getElementById('mcazs050Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    // MCB リプレース対応 バグ #7991対応 start
    if (window.location.pathname.indexOf('MCAZS170') != -1) {
      const dom = document.getElementById('mcazs170Reservation')
      if (
        dom &&
        (message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        ) ||
          message.includes(
            '送信中のメッセージが選択されているためゴミ箱に移動できません。'
          ) ||
          message.includes(
            'メッセージ送信に伴う処理が完了していないメッセージが含まれています。\n恐れ入りますが、時間をおいて再度実施してください。'
          ))
      ) {
        dom.click()
      }
    }
    if (window.location.pathname.indexOf('MCAZS180') != -1) {
      const dom = document.getElementById('mcazs180Reservation')
      if (dom) {
        dom.click()
      }
    }
    // MCB リプレース対応 バグ #7991対応 end
    if (window.location.pathname.indexOf('MCAZS190') != -1) {
      const dom = document.getElementById('mcazs190Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    if (window.location.pathname.indexOf('MCAZS200') != -1) {
      const dom = document.getElementById('mcazs200Reservation')
      if (
        dom &&
        message.includes(
          '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'
        )
      ) {
        dom.click()
      }
    }
    // 次期開発5月向#58931 end
    if (message.includes('アクセスのURLが不正です。')) {
      history.push(routeList.login)
    }
  }
  const handleCloseWindowClose = () => {
    dispatch(closeMessage())
    //メールでシェアされた応募者またはエントリーURLで応募者詳細の場合、(Windows.open以外)
    if (
      routeList.applicantDetailForEmplyment.includes(
        window.location.pathname
      ) &&
      window.location.search
    ) {
      history.replace(routeList.recruitmentStatusReportTab)
    } else if (
      routeList.entryDetail.includes(window.location.pathname) &&
      window.location.search
    ) {
      history.replace(routeList.recruitmentStatusReportTab)
    } else {
      window.close()
    }
  }

  useEffect(() => {
    // 初期表示時
    // SP用CSS設定
    const css = document.createElement('link')
    css.setAttribute('rel', 'stylesheet')
    css.setAttribute('type', 'text/css')
    css.setAttribute('href', '/css/style_sp.css')
    document.getElementsByTagName('head')[0].appendChild(css)

    // SP用mata
    // iPhoneで電話番号自動判別をしない
    const meta = document.createElement('meta')
    meta.setAttribute('name', 'format-detection')
    meta.setAttribute('content', 'telephone=no')
    document.getElementsByTagName('head')[0].appendChild(meta)

    // iPhoneで入力欄自動Zoomしない
    const metaSp = document.createElement('meta')
    metaSp.setAttribute('name', 'viewport')
    metaSp.setAttribute('content', 'width=device-width, initial-scale=1,maximum-scale=1.0')
    document.getElementsByTagName('head')[0].appendChild(metaSp)

    //gtmId取得
    dispatch(getGtmId())
    if (errorMessage || errorStack) {
      dispatch(sendError({ errorMessage, errorStack }))
    }
  }, [])

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [gtmId])

  const [styleType, setStyleType] = useState('')
  useEffect(() => {
    if (
      location.pathname === routeList.entryDetail ||
      location.pathname === routeList.entryList ||
      location.pathname === routeList.applicantDetailForJobChange ||
      location.pathname === routeList.applicantDetailForEmplyment ||
      location.pathname === routeList.applicantList
    ) {
      setStyleType('')
    } else {
      setStyleType('')
    }
  }, [location])

  const forbiddenBeforeunload = () => {
    if (routeList.password == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCACS020)
    } else if (routeList.jobSeekerNameInput == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS191)
    } else if (routeList.jobSeekerLogIn == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS201)
    } else if (
      routeList.jobSeekerInfoInsertConfirm == history.location.pathname
    ) {
      window.removeEventListener('beforeunload', listenerMCAXS211)
    } else if (routeList.errorReport == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAUS010)
      // 転職March #75621 START
    } else if (routeList.entryInsEdit == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAXS220)
    } else if (routeList.notAdoptedNotification == history.location.pathname) {
      window.removeEventListener('beforeunload', listenerMCAGS010)
    }
    // 転職March #75621 END
  }
  // 年度対応 end

  // 転職March #75621 START
  const onClickOfModeChangeError = async () => {
    dispatch(setTargetServiceErrorFlag(false))
    const specailCaseType =
      caseOfToEntryListUrl.indexOf(history.location.pathname) >= 0 ? '1' : '0'
    // 年度切替成功後に画面がエントリー一覧またはメッセージ一覧の場合history内の画面間用パラメターをクリア
    if (
      routeList.entryList == history.location.pathname ||
      routeList.messageInBox == history.location.pathname
    ) {
      history.replace({ state: undefined })
    }
    //直リンクからの遷移で自動的に切替／別画面で年度切替
    if (specailCaseType == '1') {
      await forbiddenBeforeunload()
      window.location.href = routeList.entryList
    } else if (
      (window.opener && window.opener !== window) ||
      JudgeUrl() ||
      routeList.newLoginId == history.location.pathname ||
      routeList.entryDetail == history.location.pathname ||
      routeList.applicantDetailForJobChange == history.location.pathname
    ) {
      // 動作なし
    } else {
      // 転職Marchはすべてエントリー一覧へ
      window.location.href = routeList.entryList
    }
  }
  // 転職March #75621 END

  const closeMsgInputDialog = () => {
    const stickyElmList = document.querySelectorAll<HTMLDivElement>('.sticky-footer-block')
    if (stickyElmList) {
      stickyElmList.forEach(stickyElm => {
        stickyElm.style.bottom = '0px';
      })
    }

    // ダイアログクローズまで0.5秒待つ
    setTimeout(function () {
      dispatch(closeMessageInputDialog())
    }, 500)
  }

  return (
    <ErrorBoundary>
      <div className={token ? styleType : ''}>
        {renderRoutes(token ? privateRouteList(permissions) : publicRouteList)}
        {isMessageInputDialogOpen && (
          <MessageInputDialog
            key={messageInputDialogKey}
            someKey={messageInputDialogKey}
            show={isMessageInputDialogOpen}
            onClose={closeMsgInputDialog}></MessageInputDialog>)}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose(message)}
        message={<span>{message}</span>}
        action={
          <IconButton color='inherit' onClick={handleClose(message)}>
            <CloseIcon />
          </IconButton>
        }
      />

      <Dialog
        open={modalOpen}
        onClose={handleClose(message)}
        className={'dialog is-dialog-show'}
        disableBackdropClick
        transitionDuration={0}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>エラー</div>
          <div className={'dialog-section'}>
            <div className={classes.lineBreak}>
              {message}
            </div>
          </div>
          <DialogActions >
            <button onClick={handleClose(message)} className='daialogOnlyConfirmBtn'>OK</button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog
        fullScreen
        open={fullpageOpen}
        onClose={handleClose(message)}
        disableBackdropClick
        transitionDuration={0}>
        <AppBar position='relative'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose(message)}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6'>エラー</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose(message)}>OK</button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalOpenWindowClose}
        onClose={handleCloseWindowClose}
        className={'dialog is-dialog-show'}
        disableBackdropClick
        transitionDuration={0}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>エラー</div>
          <div className={'dialog-section'}>
            {message}
          </div>
          <DialogActions>
            <button onClick={handleCloseWindowClose} className='daialogOnlyConfirmBtn' >OK</button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog
        open={modalLogout}
        onClose={handleClose(message)}
        className={'dialog is-dialog-show'}
        disableBackdropClick
        transitionDuration={0}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>エラー</div>
          <div className={'dialog-section'}>
            <div
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          </div>
          <DialogActions>
            <button onClick={handleClose(message)} className='daialogOnlyConfirmBtn'>OK</button>
          </DialogActions>
        </div>
      </Dialog>

      {/** 転職March #75621 START */}
      <Dialog
        open={globalMenu.targetServiceErrorFlag}
        disableBackdropClick
        style={{ zIndex: 1301 }}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>エラー</div>
          <div className={'dialog-section'}>
            <div className={classes.lineBreak}>
              {getMarchModeChangeMessage2(history.location.pathname)}
            </div>
          </div>
          <DialogActions>
            <button onClick={onClickOfModeChangeError} className='daialogOnlyConfirmBtn'>OK</button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog
        open={modalOpenForOKControl}
        disableBackdropClick
        transitionDuration={0}
        className={'dialog is-dialog-show'}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className={'dialog-section'}>エラー</div>
          <div className={'dialog-section'}>
            <div className={classes.lineBreak}>
              {marchModeChildWindowURL.findIndex(
                i =>
                  history.location.pathname &&
                  history.location.pathname.indexOf(i) >= 0
              ) < 0
                ? getMessage(errorMessageForOKControl)
                : getMessage(magiContants.MESSAGECODE_MCAAS010_005)}
            </div>
          </div>
          <DialogActions>
            <button onClick={handleOK} className='daialogOnlyConfirmBtn'>OK</button>
          </DialogActions>
        </div>
      </Dialog>
      {/** 転職March #75621 END */}
    </ErrorBoundary>
  )
}

export default AppSp
