import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues } from 'pages/MCAZS070/formConfig'
import { MCAZS070InitRequest } from 'types/MCAZS070/MCAZS070InitRequest'
import { MCAZS070DownloadFileRequest } from 'types/MCAZS070/MCAZS070DownloadFileRequest'
import { MCAZS070DownloadCsvRequest } from 'types/MCAZS070/MCAZS070DownloadCsvRequest'
import { MCAZS070ReplyMessageRequest } from 'types/MCAZS070/MCAZS070ReplyMessageRequest'
import { MCAZS150InterviewScheduleRequest } from 'types/MCAZS150/MCAZS150InterviewScheduleRequest'
import { MCBAS020InterviewScheduleRequest } from 'types/MCBAS020/MCBAS020InterviewScheduleRequest'

export interface messageDetail {
  messageId: string // メッセージID
  messageTypeCode: string // メッセージ種別（code）
  messageType: string // メッセージ種別（表示用）
  sendAndReceiveDivision: string // 送受信区分
  destinationDivision: string // 送信先区分
  sendReceivingTimeName: string // 送受信日時(表示用)
  senderCompanyName: string // 送信元会社名
  senderMailAddress: string // 送信元メールアドレス
  // phase2 start
  sendTimeFlag: string
  // phase2 end
  replyPermitFlag: string  // 返信許可フラグ（code）
  replyPermit: string  // 返信許可(表示用)
  repliesAllowAddress: string // 返信お知らせ先
  barcodeDisplayFlag: string // バーコード表示フラグ
  subject: string // 件名
  body: string // 本文
  messageReadFlag: string // 既読フラグ
  sysVersionNumber: string // sysバージョン番号
  unsubscribeFlag: string // 退会済みフラグ
  entryList: AttachmentInfo[] // エントリー
  seminarList: AttachmentInfo[] // セミナー/説明会
  homepageList: AttachmentInfo[] // ホームページ
  attachmentFileList: AttachmentInfo[] // 添付ファイル
  jobSeekersInfoCountFlag: string // 送受信者情報件数フラグ
  jobSeekersInfoList: JobSeekersInfo[] // 送受信者情報
  buttonDisableFlag: string // ボタンを活性化フラグ 0:非活性化 1:活性化
  buttonDisplayFlag: string // ボタンを表示フラグ 0:非表示 1:表示
  interviewList: InterviewInfo[]
  downloadAuthority: boolean
  subjectForSending: string // 送信用件名
  bodyForSending: string // 送信用本文
  bulkSendFlag: string // 一括送信フラグ
  convertingBatchCompleteFlag: string // 変換バッチ完了フラグ
  status: string // ステータス
  replyNecessaryFlag: string // 要返信フラグ(code)
  replyLimitTime: string // 返信期限
  // [phase2] start by zhangxp
  // 添付オプションタイトル
  attachedOptionTitle: string
  // [phase2] end by zhangxp
  searchCriteriaName: string // 検索条件名
  searchCriteria: string // 検索条件
  // #58497 2022/05/31 start
  mySearch: MySearch[] //MY検索
  mySearchCondition: number //MY検索条件指定フラグ
  // #58497 2022/05/31 end
  // 次期開発12月 #72025 start
  noPermissionOrDeletedFlag: boolean // 閲覧権限、削除済応募者チェックフラグ
  bulkReplyMessageCount: number // 一括返信チェックの件数
  bulkReplyMessageFlag: boolean // 一括返信チェックのフラグ
  // 次期開発12月 #72025 end
  // 次期開発12月 #71768 start
  replyAddress: string // 送信元メールアドレス（固定値）
  replyToMailDate: string // #71768のリリース日時
  // 次期開発12月 #71768 end
  messageDetailParam: string
}

// #58497 2022/05/31 start
export interface MySearch {
  mySearchName: string //MY検索条件名
  mySearchCriteria: string //MY検索条件
}
// #58497 2022/05/31 end

export interface AttachmentInfo {
  name: string
  value: string
}

export interface JobSeekersInfo {
  readableFlag: string // 求職者分類
  messageType: string // メッセージ種別
  messageSendType: string // メッセージ送信種別
  messageSendName: string // 送信先
  fullName: string // 氏名
  age: string // 年齢
  jobSeekerIdForDisplay: string // 表示用求職者ID
  jobSeekerId: string // 求職者ID
  entryID: string // エントリーID
  selectionManagementId: string // 選考管理ID
  browsingState: string // 閲覧状態
  readFlag: string // 未読/既読
  sendingResult: string // 送信失敗（code）
  manualRegistrationFlag: string  // マイナビ非会員（code）
  unsubscribeTime: string // 退会済み（code）
  memberStatus: string // 会員登録情報
  schoolName: string // 大学
  facultyName: string // 学部
  subjectName: string // 学科
  entryJobCategoryName: string // 応募職種
  entryJobCategoryNameShow: string // 応募職種(表示用)
  entryType: string // 応募種別
  entryReceptionTime: string // 応募日時
  entryReceptionTimeShow: string // 応募日時(表示用)
  contractMediaName: string // 応募経路
  media: string[] // 応募経路
  progressNameCode: string // 送受信時選考ステップ
  progressName: string // 送受信時選考ステップ(表示用)
  decisionDivision: string // 判定区分（code）
  decisionDivisionName: string // 送受信時合否(表示用)
  selectionName: string // 選考フロー
  labelFlag: string // 背景色
}

export interface InterviewInfo {
  interviewScheduleAdjustmentStatus: string // 面接日程調整ステータス
  interviewScheduleStatus: string // 面接日程ステータス
  interviewScheduleStatusMessage: string // 面接日程ステータス（面接日程メッセージ）
  interviewStartTime: string // 面接開始日時
  interviewStartTimeShow: string // 面接開始日時(表示用)
  displayTime: string // 表示時間
  place: string // 面接場所名
  charge: string // 担当者
  mapUrl: string // 地図URL
  interviewScheduleAdjustmentId: string // 面接日程調整ID
  interviewScheduleId: string // 面接日程ID
  sysVersionNumberAdjustment: string // sysバージョン情報_面接日程調整
  interviewScheduleVersionNumber: string // sysバージョン情報_面接日程
  buttonDisplayFlag: string
}

const initialState: messageDetail = initialValues

export interface detailRequest {
  flag: string
  request: MCAZS070InitRequest
}

export interface detailRequestEx {
  flag: string
  request: MCAZS070InitRequest
  request020: MCBAS020InterviewScheduleRequest
}

const messageDetailSlice = createSlice({
  name: 'messageDetail',
  initialState,
  reducers: {
    messageDetailInitialize(state, title) {
      return state;
    },
    initializemessageDetail(state) {
      return state;
    },
    searchMessageDetail(state, action: PayloadAction<detailRequest>) {
      return state;
    },
    searchMessageDetailInterview(state, action: PayloadAction<detailRequestEx>) {
      return state;
    },
    setMessageDetail(state, action: PayloadAction<messageDetail>) {
      state = action.payload;
      return state;
    },
    attachmentFileDownload(state, action: PayloadAction<MCAZS070DownloadFileRequest>) {
      return state;
    },
    csvFileDownload(state, action: PayloadAction<MCAZS070DownloadCsvRequest>) {
      return state;
    },
    // 次期開発12月 #72025 start
    memberStatusCheck(state, action: PayloadAction<{
      requestParam: MCAZS070ReplyMessageRequest
      bulkSendFlag: string
    }>) {
      return state;
    },
    sysVersionNumberCheck(state, action: PayloadAction<MCAZS150InterviewScheduleRequest>) {
      return state;
    },
    // 「閲覧権限、削除済応募者チェック」アラートフラグ
    setNoPermissionOrDeletedFlag(state, action: PayloadAction<boolean>) {
      state.noPermissionOrDeletedFlag = action.payload
      return state
    },
    // 「一括返信チェック」の返信件数
    setBulkReplyMessageCount(state, action: PayloadAction<number>) {
      state.bulkReplyMessageCount = action.payload
      return state
    },
    // 「一括返信チェック」アラートフラグ
    setBulkReplyMessageFlag(state, action: PayloadAction<boolean>) {
      state.bulkReplyMessageFlag = action.payload
      return state
    },
    // 次期開発12月 #72025 end
    setMessageDetailParam(state, action: PayloadAction<string>) {
      state.messageDetailParam = action.payload
      return state
    }
  },
})

export const {
  messageDetailInitialize,
  initializemessageDetail,
  searchMessageDetail,
  searchMessageDetailInterview,
  setMessageDetail,
  attachmentFileDownload,
  csvFileDownload,
  memberStatusCheck,
  sysVersionNumberCheck,
  setNoPermissionOrDeletedFlag,
  setBulkReplyMessageCount,
  setBulkReplyMessageFlag,
  setMessageDetailParam
} = messageDetailSlice.actions
export default messageDetailSlice.reducer
