import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: 'lightgrey',
  },
}))
interface Props {
  flag?: boolean
}
const SubTitle: React.FC<Props> = ({ children, flag }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {children}
      </Typography>
      {flag ? '' : <Divider className={classes.divider} />}
    </div>
  )
}

export default SubTitle
